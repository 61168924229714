import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import ToggleButton from 'app/components/CommonStyled/ToggleButton';
import { ClientIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { DetailsPageContext } from 'app/components/DetailsPages/shared/DetailsPageContext';
import { User } from 'interfaces';
import { userDataSelector } from 'store/reducers/user-reducer';

import GlobalClientDrawer from '../GlobalClient/GlobalClientDrawer';
import {
  ClientDrawerFilterOption,
  ClientDrawerPill,
  ClientDrawerPillContainer,
  ClientDrawerText,
  ClientsDrawerContainer,
  ClientsDrawerFiltersContainer,
  ClientsDrawerFiltersDescription,
  ClientsDrawerFiltersOptionsContainer,
  ClientsDrawerFiltersTitle,
  ClientsDrawerListContainer,
} from './styled';

interface ClientsDrawerProps {
  clientsQuery?: UseQueryResult<any, Error>;
  description?: string;
  title?: string;
  onlyActive?: boolean;
}

export default function ClientsDrawer({
  clientsQuery,
  description,
  title,
  onlyActive = false,
}: ClientsDrawerProps) {
  const userData: User = useSelector(userDataSelector);
  const { isLoading, isIdle, data } = clientsQuery;
  const context = useContext(DetailsPageContext);
  const [filterSelected, setFilterSelected] = useState<string>('');
  const [filterIncludeUser, setFilterIncludeUser] = useState<boolean>(false);
  const [showingClientDrawer, setShowingClientDrawer] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<boolean>(onlyActive);
  const [clientDrawerSlug, setClientDrawerSlug] = useState<string>('');
  const [allClients, setAllClients] = useState<any[]>([]);

  useEffect(() => {
    if (clientsQuery.isIdle) {
      clientsQuery.refetch();
    }
  }, [clientsQuery]);

  const [topHeight, setTopHeight] = useState(0);

  const topWrapperRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      const observer = new ResizeObserver((entries) => {
        const { height } = entries[0].target.getBoundingClientRect();
        setTopHeight(height);
      });

      observer.observe(node);
    }
  }, []);

  useEffect(() => {
    if (data) {
      let clientsTemp = [];
      Object.keys(data.clients).map((clientFilter) =>
        clientsTemp.push(...data.clients[clientFilter])
      );
      setAllClients(clientsTemp);
    }

    if (filterSelected !== '') {
      setAllClients(data.clients[filterSelected]);
    }
  }, [data, filterSelected]);

  return (
    <ClientsDrawerContainer showingClientDrawer={showingClientDrawer}>
      {showingClientDrawer ? (
        <GlobalClientDrawer
          slug={clientDrawerSlug}
          comingFromList
          backToListAction={() => setShowingClientDrawer(false)}
        />
      ) : (
        <>
          <ClientsDrawerFiltersContainer ref={topWrapperRef}>
            <ClientsDrawerFiltersTitle>
              {title ??
                `${
                  context.data.name || context.data.first_name || userData.kyu_company?.name
                }'s clients`}
            </ClientsDrawerFiltersTitle>
            {description ? (
              <ClientsDrawerFiltersDescription>{description}</ClientsDrawerFiltersDescription>
            ) : null}
            {!isLoading && !isIdle ? (
              <ClientsDrawerFiltersOptionsContainer>
                {Object.keys(data.clients).map((filterOption, index) => {
                  if (data.clients[filterOption].length > 0) {
                    return (
                      <ClientDrawerFilterOption
                        key={index}
                        selected={filterSelected === filterOption}
                        onClick={() => setFilterSelected(filterOption)}
                      >
                        {filterOption}
                      </ClientDrawerFilterOption>
                    );
                  }
                })}
              </ClientsDrawerFiltersOptionsContainer>
            ) : (
              <></>
            )}
          </ClientsDrawerFiltersContainer>
          <ClientsDrawerListContainer height={topHeight}>
            {!isLoading && !isIdle && data.any_includes_current_person && (
              <ToggleButton
                label={'Only what I share'}
                labelColor={'rgba(23, 28, 51, 0.5)'}
                toggleColor="var(--color-primary)"
                onChange={(checked) => setFilterIncludeUser(checked)}
                checked={filterIncludeUser}
              />
            )}
            {!isLoading && !isIdle ? (
              <ToggleButton
                label={'Only active'}
                labelColor={'rgba(23, 28, 51, 0.5)'}
                toggleColor="var(--color-primary)"
                onChange={(checked) => setActiveFilter(checked)}
                checked={activeFilter}
              />
            ) : null}
            {!isLoading && !isIdle ? (
              <>
                {allClients
                  .filter((client) => {
                    if (filterIncludeUser) {
                      return client.includes_current_person;
                    }

                    return true;
                  })
                  .filter((client) => {
                    if (activeFilter) {
                      return client.active;
                    }

                    return true;
                  })
                  .map((client, index) => (
                    <ClientPill
                      key={index}
                      client={client}
                      userData={userData}
                      setShowingClientDrawer={setShowingClientDrawer}
                      setClientDrawerSlug={setClientDrawerSlug}
                    />
                  ))}
              </>
            ) : (
              <></>
            )}
          </ClientsDrawerListContainer>
        </>
      )}
    </ClientsDrawerContainer>
  );
}

function ClientPill({ client, userData, setShowingClientDrawer, setClientDrawerSlug }) {
  return (
    <ClientDrawerPillContainer>
      <ClientDrawerPill
        onClick={() => {
          setClientDrawerSlug(client.slug);
          setShowingClientDrawer(true);
        }}
      >
        <ClientIcon width={13} height={13} fill={'#104D5B'} />
        <ClientDrawerText>{client.name}</ClientDrawerText>
      </ClientDrawerPill>
      {client.includes_current_person && (
        <PersonAvatar
          name={userData.first_name}
          lastName={userData.last_name}
          avatar={userData.profile_image}
          size={22}
          highlighted
        />
      )}
    </ClientDrawerPillContainer>
  );
}
