import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMapNodeData } from "app/components/Map/interfaces/map";
import { IClickedNodeAction, IMapState } from "./interfaces";

const initialState: IMapState = {
  filtersOpen: false,
  searchMapOpen: false,
  mapCtaData: null,
  loadingMap: false,
  clickedNode: null,
  mapCTAUrl: null,
  mapError: false,
  showMapCTA: false,
  clickedNodeLinks: [],
  highlightLinks: [],
  showFork: false,
  forkPositioning: {
    top: 0,
    left: 0,
  },
}

export const slice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setFiltersOpen: (state, action) => {
      state.filtersOpen = action.payload;
    },
    setSearchMapOpen: (state, action) => {
      state.searchMapOpen = action.payload;
    },
    setMapCtaData: (state, action) => {
      state.mapCtaData = action.payload;
    },
    setShowMapCTA: (state, action) => {
      state.showMapCTA = action.payload;
    },
    setLoadingMap: (state, action) => {
      state.loadingMap = action.payload;
    },
    setClickedNode: (state, action: PayloadAction<IMapNodeData>) => {
      state.clickedNode = action.payload;
    },
    setMapError: (state, action) => {
      state.mapError = action.payload;
    },
    setMapCTAUrl: (state, action) => {
      state.mapCTAUrl = action.payload;
    },
  },
});

export const {
  setFiltersOpen,
  setSearchMapOpen,
  setMapCtaData,
  setLoadingMap,
  setClickedNode,
  setMapError,
  setShowMapCTA,
  setMapCTAUrl,
} = slice.actions;

export const filtersOpenSelector = (state) => state.map.filtersOpen;
export const searchMapOpenSelector = (state) => state.map.searchMapOpen;
export const mapCtaDataSelector = (state) => state.map.mapCtaData;
export const loadingMapSelector = (state) => state.map.loadingMap;
export const mapErrorSelector = (state) => state.map.mapError;
export const showMapCTASelector = (state) => state.map.showMapCTA;
export const clickedNodeSelector = (state) => state.map.clickedNode;
export const mapSelector = (state: {map: IMapState}) => state.map;
export const mapCTAUrlSelector = (state) => state.map.mapCTAUrl;

export default slice.reducer;
