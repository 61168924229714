import { motion } from 'framer-motion';
import styled from 'styled-components';

export const NavbarMobileWrapper = styled.nav`
  height: 56px;

  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 6px 16px;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(24px);
  }

  background: ${({ theme }) => theme.backgroundMenuColor};
  ${({ theme }) =>
    !theme.isAnythingOpen &&
    `
    box-shadow: -4px 0px 20px -4px rgba(0, 0, 0, 0.25);
  `}

  z-index: 99999999;
`;

export const NavbarMobileBlurBackground = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
})`
  width: 100%;
  height: 100svh;

  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;

  backdrop-filter: blur(24px);
  background: rgba(15, 15, 15, 0.15);

  z-index: 99999997;
`;

export const NavbarMobileLeftSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  position: relative;
`;

export const NavbarMobileRightSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  position: relative;
`;

export const NavbarMobileMenuContainer = styled(motion.div).attrs({
  initial: { translateY: '100%' },
  animate: { translateY: 0 },
  exit: { translateY: '100%' },
  transition: { duration: 0.2 },
})`
  position: fixed;
  top: 45px;
  bottom: 56px;
  left: 0px;
  right: 0px;

  overflow-y: scroll;

  z-index: 99999998;

  transform-origin: bottom;
`;

export const NavbarMobileSpotlightContainer = styled(motion.div).attrs({
  initial: { translateY: '100%' },
  animate: { translateY: 0 },
  exit: { translateY: '100%' },
  transition: { duration: 0.2 },
})`
  position: fixed;
  bottom: 56px;
  left: 0px;
  right: 0px;

  z-index: 99999998;
`;

export const NavbarMobileMenuCTAContainer = styled.div`
  width: 44px;
  height: 44px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
