import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getArticleDetails } from 'services/app';

export default function useArticleDetails(
  slug: string,
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `article-${slug}`,
    async () => {
      const response = await getArticleDetails(slug);
      if (!response.ok) {
        throw new Error(response.originalError?.message);
      }

      return response.data;
    },
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
