import styled from "styled-components";
import { BREAKPOINTS } from "styles/media";

export const AuthWrapper = styled.aside`
  position: absolute;
  right: 0;
  top: 0;

  width: 100vw;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #171c33;
  background-image: url('/backgrounds/stars.svg');
`;

export const AuthContainer = styled.div`
  height: calc(100vh - 90px);
  width: 90vw;
  background: url('/backgrounds/auth-background.jpg');
  background-size: cover;
  border-radius: 10px;

  padding: 60px 90px;

  overflow: scroll;

  z-index: 999999999;

  ${BREAKPOINTS.mobile} {
    min-height: 100vh;
    height: fit-content;
    width: 100%;
    padding: 32px;
    border-radius: 0px;
  }
`;