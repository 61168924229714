import { CompanyDetailsNewFacesContainer } from 'app/components/DetailsPages/Company/styled';
import { SomeoneNewWrapper } from 'app/components/DetailsPages/shared/MeetSomeoneNew/styled';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsWayfindingColleaguesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const AboutKyuOsWayfindingColleaguesTitle = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  font-weight: 400;
  font-size: 32px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    font-size: 24px;
  }

  color: var(--color-text);
`;

export const AboutKyuOsWayfindingColleaguesDescription = styled.div`
  max-width: 900px;

  font-weight: 400;
  font-size: 18px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    display: none;
  }

  color: var(--color-text);
`;

export const AboutKyuOsWayfindingColleaguesPeopleWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    align-items: flex-start;
    gap: 29px;

    ${CompanyDetailsNewFacesContainer} {
      margin-left: 32px;
    }
  }

  gap: 10px;
`;

export const AboutKyuOsWayfindingColleaguesPeopleContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :nth-child(2) {
    margin-bottom: 24px;
  }

  ${BREAKPOINTS.tablet} {
    ${SomeoneNewWrapper} {
      margin-left: 16px;
    }
    margin-left: 32px;
  }
`;

export const AboutKyuOsWayfindingColleaguesPeopleTitle = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;

  color: var(--color-text);
`;

export const AboutKyuOsWayfindingColleaguesPeopleButton = styled.button`
  width: fit-content;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 10px;

  padding: 16px 10px 16px 24px;

  border-radius: 100px;
  border: 2px solid var(--color-text);

  transition: background 0.2s ease;
  &:hover {
    background: var(--color-primary);

    & span {
      color: #ffffff;
    }

    & path {
      fill: #ffffff;
    }
  }

  & > svg {
    min-width: 16px;
    min-height: 16px;
  }

  ${BREAKPOINTS.tablet} {
    width: 100%;
  }
`;

export const AboutKyuOsWayfindingColleaguesPeopleButtonText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  letter-spacing: 1.4px;
  text-transform: uppercase;

  color: var(--color-text);
`;
