import React from 'react';

export default function PersonPlusIcon({ width = 22, height = 22, fill = '#171C33', fillOpacity = 1 }) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.04167 6.41634C5.04167 4.89756 6.27288 3.66634 7.79167 3.66634C9.31045 3.66634 10.5417 4.89756 10.5417 6.41634C10.5417 7.93512 9.31045 9.16634 7.79167 9.16634C6.27288 9.16634 5.04167 7.93512 5.04167 6.41634ZM7.79167 1.83301C5.26036 1.83301 3.20833 3.88504 3.20833 6.41634C3.20833 8.94765 5.26036 10.9997 7.79167 10.9997C10.323 10.9997 12.375 8.94765 12.375 6.41634C12.375 3.88504 10.323 1.83301 7.79167 1.83301ZM4.58333 12.833C3.36776 12.833 2.20197 13.3159 1.34243 14.1754C0.482886 15.035 0 16.2008 0 17.4163V19.2497C0 19.7559 0.410406 20.1663 0.916667 20.1663C1.42293 20.1663 1.83333 19.7559 1.83333 19.2497V17.4163C1.83333 16.687 2.12306 15.9875 2.63879 15.4718C3.15452 14.9561 3.85399 14.6663 4.58333 14.6663H11C11.7293 14.6663 12.4288 14.9561 12.9445 15.4718C13.4603 15.9875 13.75 16.687 13.75 17.4163V19.2497C13.75 19.7559 14.1604 20.1663 14.6667 20.1663C15.1729 20.1663 15.5833 19.7559 15.5833 19.2497V17.4163C15.5833 16.2008 15.1004 15.035 14.2409 14.1754C13.3814 13.3159 12.2156 12.833 11 12.833H4.58333ZM18.3333 6.41634C18.8396 6.41634 19.25 6.82675 19.25 7.33301V9.16634H21.0833C21.5896 9.16634 22 9.57675 22 10.083C22 10.5893 21.5896 10.9997 21.0833 10.9997H19.25V12.833C19.25 13.3393 18.8396 13.7497 18.3333 13.7497C17.8271 13.7497 17.4167 13.3393 17.4167 12.833V10.9997H15.5833C15.0771 10.9997 14.6667 10.5893 14.6667 10.083C14.6667 9.57675 15.0771 9.16634 15.5833 9.16634H17.4167V7.33301C17.4167 6.82675 17.8271 6.41634 18.3333 6.41634Z" fill={fill} fillOpacity={fillOpacity}/>
</svg>

  );
}
