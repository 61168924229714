import styled from 'styled-components';

export const SearchSubMenuContainer = styled.div`
  display: flex;
  flex-direction: column;

  background: #FFFFFF;

  border-radius: 0px 0px 24px 24px;
`;

export const SearchSubMenuSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 16px 16px 8px 16px;

  & > :first-child {
    margin-bottom: 8px;
  }
`;

export const SearchSubMenuTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > :first-child {
    margin-right: 8px;
  }
`;

export const SearchSubMenuTitle = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: #8D8D90;
`;

export const SearchSubMenuDivider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--color-data-alumni-background);
`;

export const SearchSubMenuBottomContainer = styled.div`
  display: flex;
  flex-direction: column;

  background: #F1F4F8;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const SearchIndexPillsContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0px 16px 16px 16px;
`;