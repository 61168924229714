import {
  OnboardingPassionDataResponse,
  OnboardingResponse,
} from 'app/components/Onboarding/interfaces';

import { http } from './http';

export const getOnboarding = () => {
  return http.get<OnboardingResponse>(`onboarding`);
};

export const updateOnboardingStarted = (params: { onboarding_started: boolean }) => {
  return http.put(`person/update_onboarding_started`, params);
};

export const updateOnboardingProfile = (params: { onboarding_profile: boolean }) => {
  return http.put(`person/update_onboarding_profile`, params);
};

export const updateOnboarded = (params: { onboarded: boolean }) => {
  return http.put(`person/update_onboarded`, params);
};

export const getPassionData = (passion_uuid) => {
  return http.get<OnboardingPassionDataResponse>(`onboarding/passion_data`, { passion_uuid });
};

export const addPassion = (passion_uuid) => {
  return http.post(`onboarding/add_passion`, { passion_uuid });
};
