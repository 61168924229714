import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import ProfileEditDataTypePill from 'app/components/CommonStyled/ProfileEditDataTypePill';
import ProfileEditInput from 'app/components/CommonStyled/ProfileEditInput';
import { PassionIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { CloseIcon, RightArrow, Sparkle } from 'app/components/shared/icons';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import { createPassion, postPassions } from 'services/profile-editor';
import {
  profileDataSelector,
  setAllPassions,
  setPassions,
} from 'store/reducers/profile-editor/profile-editor-reducer';
import * as Yup from 'yup';

import useProfileEditorHumanAtAGlanceDrawer from '../../hooks/useProfileEditorHumanAtAGlanceDrawer';
import ProfileEditorDrawer, { ProfileEditorDrawerRefProps } from '../Drawer';
import { ProfileEditorDrawerPublishButton, ProfileEditorDrawerTitle } from '../styled';
import {
  ProfileEditorDrawerPassionsBottomText,
  ProfileEditorDrawerPassionsBottomWrapper,
  ProfileEditorDrawerPassionsFeelingStuckAllCategoriesContainer,
  ProfileEditorDrawerPassionsFeelingStuckAllCategory,
  ProfileEditorDrawerPassionsFeelingStuckAllContainer,
  ProfileEditorDrawerPassionsFeelingStuckAllPillsContainer,
  ProfileEditorDrawerPassionsFeelingStuckButton,
  ProfileEditorDrawerPassionsFeelingStuckButtonText,
  ProfileEditorDrawerPassionsFeelingStuckCloseContainer,
  ProfileEditorDrawerPassionsFeelingStuckContainer,
  ProfileEditorDrawerPassionsFeelingStuckRandomContainer,
  ProfileEditorDrawerPassionsFeelingStuckRandomPillsContainer,
  ProfileEditorDrawerPassionsFeelingStuckRandomShowMoreContainer,
  ProfileEditorDrawerPassionsFeelingStuckRandomShowMoreText,
  ProfileEditorDrawerPassionsFeelingStuckRandomTitle,
  ProfileEditorDrawerPassionsFeelingStuckTitle,
  ProfileEditorDrawerPassionsFeelingStuckWrapper,
  ProfileEditorDrawerPassionsHeaderContainer,
  ProfileEditorDrawerPassionsHeaderDescription,
  ProfileEditorDrawerPassionsHeaderWrapper,
  ProfileEditorDrawerPassionsPickedContainer,
  ProfileEditorDrawerPassionsWrapper,
} from './styled';

const MAX_PASSIONS = 15;

interface ProfileEditorDrawerPassionsProps {
  onClose: () => void;
}

export default function ProfileEditorDrawerPassions({ onClose }: ProfileEditorDrawerPassionsProps) {
  const { passions, allPassions } = useProfileEditorHumanAtAGlanceDrawer();
  const [optionsPicked, setOptionsPicked] = useState<any[]>(passions);
  const [passionsCreated, setPassionsCreated] = useState<any[]>([]);
  const drawerRef = useRef<ProfileEditorDrawerRefProps>(null);
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<any>>(null);

  const reachedMaxPassions = useMemo(() => {
    return optionsPicked.length >= MAX_PASSIONS;
  }, [optionsPicked]);

  useEffect(() => {
    setOptionsPicked(passions.map((passion) => ({ ...passion, label: passion.name })));
    formikRef.current.setFieldValue(
      'collection',
      passions.map((passion) => passion.uuid)
    );
  }, [passions]);

  const passionsValidationSchema = Yup.object().shape({
    collection: Yup.array(),
  });

  const options = useMemo(() => {
    return allPassions.map((passion) => ({
      ...passion,
      label: passion.name,
      value: passion.uuid,
    }));
  }, [allPassions]);

  const handleOptionsChanged = (options: any[]) => {
    let newlyCreatedOptions = options.filter((option) => option.isCreated);
    newlyCreatedOptions = [...newlyCreatedOptions, ...passionsCreated];
    setPassionsCreated(newlyCreatedOptions);
    const alreadyCreatedOptions = options.filter((option) => !option.isCreated);
    const allOptions = [...alreadyCreatedOptions, ...newlyCreatedOptions];
    const uniqOptions = allOptions.filter(
      (option, index, self) => index === self.findIndex((t) => t.value === option.value)
    );

    const optionsToSave = uniqOptions.map((passion) => {
      const existingPassion = passions.find((passionItem) => passionItem.uuid === passion.value);

      if (existingPassion) {
        return {
          ...existingPassion,
          ...passion,
        };
      }
      return passion;
    });

    setOptionsPicked(optionsToSave);
  };

  const removePassion = (passion) => {
    const filteredOptions = optionsPicked.filter((option) => option.value !== passion.value);
    const filteredNewOptions = passionsCreated.filter(
      (passionItem) => passionItem.value !== passion.value
    );
    setPassionsCreated(filteredNewOptions);

    formikRef.current.setFieldValue(
      'collection',
      filteredOptions.map((option) => option.value)
    );
    setOptionsPicked(filteredOptions);
  };

  const addPassion = (passion) => {
    const newOptions = [...optionsPicked, passion];
    formikRef.current.setFieldValue(
      'collection',
      newOptions.map((option) => option.value)
    );
    handleOptionsChanged(newOptions);
  };

  const handlePickedPassion = (passion) => {
    const clonedPassion = { ...passion };

    if (!clonedPassion.value && clonedPassion.uuid) {
      clonedPassion.value = clonedPassion.uuid;
    }

    const passionExists = optionsPicked.find((option) => option.value === clonedPassion.value);

    if (passionExists) {
      removePassion(clonedPassion);
    } else if (!reachedMaxPassions) {
      addPassion(clonedPassion);
    }
  };

  const handlePostPassions = async (passions: any) => {
    let newlyCreatedPassions = [];
    const response = await postPassions({ collection: passions });
    if (!response.ok) {
      throw new Error(response.originalError?.message);
    }
    if (passionsCreated.length > 0) {
      newlyCreatedPassions = await Promise.all(
        passionsCreated.map((passion) => createPassion({ name: passion.value }))
      );

      newlyCreatedPassions = newlyCreatedPassions.map((newPassion) => ({
        uuid: newPassion.data.uuid,
        value: newPassion.data.uuid,
        label: newPassion.data.name,
        name: newPassion.data.name,
        isCreated: false,
        approved: false,
      }));
    }

    const existingPassions = optionsPicked.filter((option) => !option.isCreated);

    const allAvailablePassions = [...allPassions, ...newlyCreatedPassions];
    dispatch(setAllPassions(allAvailablePassions));
    dispatch(setPassions([...existingPassions, ...newlyCreatedPassions]));
    return response.data;
  };

  const { mutate } = useMutation(handlePostPassions, drawerRef.current?.handleMutation);

  const handleSubmit = (values) => {
    mutate(values.collection);
  };

  return (
    <Formik
      initialValues={{
        collection: passions.map((passion) => passion.uuid),
      }}
      validationSchema={passionsValidationSchema}
      innerRef={formikRef}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form>
          <ProfileEditorDrawer
            header={<ProfileEditorDrawerPassionsHeader />}
            bottom={<ProfileEditorDrawerPassionsBottom optionsPicked={optionsPicked} />}
            onClose={onClose}
            needsSaving={props.dirty}
            ref={drawerRef}
          >
            <ProfileEditorDrawerPassionsWrapper>
              <ProfileEditInput
                {...props}
                name={'collection'}
                label={'What are some of the topics you’re most passionate about?'}
                tooltip={
                  "Not everything needs to be centered around work— passions help connect us through community, events and more. New passions will not show up on your profile until they've been reviewed for typos and such."
                }
                type={'select'}
                placeholder={'Start searching...'}
                options={options}
                onOptionsChanged={handleOptionsChanged}
                settings={{
                  allowCreate: true,
                }}
              />
              <ProfileEditorDrawerPassionsFeelingStuck
                handlePickedPassion={handlePickedPassion}
                optionsPicked={optionsPicked}
              />
              <ProfileEditorDrawerPassionsPickedContainer>
                {optionsPicked.map((passion) => (
                  <ProfileEditDataTypePill
                    showDeleteOnHover
                    key={passion.label}
                    type="selected"
                    label={passion.label}
                    approved={passion.approved}
                    peopleCount={passion.people_count}
                    onDelete={() => handlePickedPassion(passion)}
                  />
                ))}
              </ProfileEditorDrawerPassionsPickedContainer>
            </ProfileEditorDrawerPassionsWrapper>
          </ProfileEditorDrawer>
        </Form>
      )}
    </Formik>
  );
}

function ProfileEditorDrawerPassionsHeader() {
  return (
    <ProfileEditorDrawerPassionsHeaderWrapper>
      <ProfileEditorDrawerPassionsHeaderContainer>
        <PassionIcon width={24} height={24} fill={'var(--color-primary)'} />
        <ProfileEditorDrawerTitle>Passions</ProfileEditorDrawerTitle>
      </ProfileEditorDrawerPassionsHeaderContainer>
      <ProfileEditorDrawerPassionsHeaderDescription>
        Sharing your passions will help find common ground between you and others around the
        Collective. Your passions will also help kyu OS recommend communities, events, articles and
        highlight projects for you.
      </ProfileEditorDrawerPassionsHeaderDescription>
    </ProfileEditorDrawerPassionsHeaderWrapper>
  );
}

interface ProfileEditorDrawerPassionsBottomProps {
  optionsPicked: any[];
}

function ProfileEditorDrawerPassionsBottom({
  optionsPicked,
}: ProfileEditorDrawerPassionsBottomProps) {
  const { isValid, submitForm, isSubmitting } = useFormikContext();
  const disabled = !isValid || isSubmitting || optionsPicked.length > MAX_PASSIONS;

  const handleSubmit = async () => {
    await submitForm();
  };

  return (
    <ProfileEditorDrawerPassionsBottomWrapper>
      <ProfileEditorDrawerPassionsBottomText>
        {optionsPicked?.length} of {MAX_PASSIONS} selected
      </ProfileEditorDrawerPassionsBottomText>
      <ProfileEditorDrawerPublishButton disabled={disabled} type="submit" onClick={handleSubmit}>
        Publish
      </ProfileEditorDrawerPublishButton>
    </ProfileEditorDrawerPassionsBottomWrapper>
  );
}

interface ProfileEditorDrawerPassionsFeelingStuckProps {
  handlePickedPassion: (passion: any) => void;
  optionsPicked: any[];
}

function ProfileEditorDrawerPassionsFeelingStuck({
  handlePickedPassion,
  optionsPicked,
}: ProfileEditorDrawerPassionsFeelingStuckProps) {
  const [currentlyShowing, setCurrentlyShowing] = useState<'random' | 'all'>(null);

  if (currentlyShowing === 'random') {
    return (
      <ProfileEditorDrawerPassionsFeelingStuckRandom
        setCurrentlyShowing={setCurrentlyShowing}
        handlePickedPassion={handlePickedPassion}
        optionsPicked={optionsPicked}
      />
    );
  }

  if (currentlyShowing === 'all') {
    return (
      <ProfileEditorDrawerPassionsFeelingStuckAll
        setCurrentlyShowing={setCurrentlyShowing}
        handlePickedPassion={handlePickedPassion}
        optionsPicked={optionsPicked}
      />
    );
  }

  return (
    <ProfileEditorDrawerPassionsFeelingStuckWrapper>
      <ProfileEditorDrawerPassionsFeelingStuckTitle>
        Feeling Stuck?
      </ProfileEditorDrawerPassionsFeelingStuckTitle>
      <ProfileEditorDrawerPassionsFeelingStuckContainer>
        <ProfileEditorDrawerPassionsFeelingStuckButton
          onClick={() => setCurrentlyShowing('random')}
        >
          <Sparkle width={24} height={24} fill={'var(--color-primary)'} />
          <ProfileEditorDrawerPassionsFeelingStuckButtonText>
            Randomize
          </ProfileEditorDrawerPassionsFeelingStuckButtonText>
        </ProfileEditorDrawerPassionsFeelingStuckButton>
        <ProfileEditorDrawerPassionsFeelingStuckButton onClick={() => setCurrentlyShowing('all')}>
          <RightArrow width={12} height={12} fill={'var(--color-primary)'} />
          <ProfileEditorDrawerPassionsFeelingStuckButtonText>
            See All
          </ProfileEditorDrawerPassionsFeelingStuckButtonText>
        </ProfileEditorDrawerPassionsFeelingStuckButton>
      </ProfileEditorDrawerPassionsFeelingStuckContainer>
    </ProfileEditorDrawerPassionsFeelingStuckWrapper>
  );
}

interface ProfileEditorDrawerPassionsFeelingStuckOptionProps {
  setCurrentlyShowing: (value: 'random' | 'all') => void;
  handlePickedPassion: (passion: any) => void;
  optionsPicked: any[];
}

function ProfileEditorDrawerPassionsFeelingStuckRandom({
  handlePickedPassion,
  setCurrentlyShowing,
  optionsPicked,
}: ProfileEditorDrawerPassionsFeelingStuckOptionProps) {
  const { allPassions } = useSelector(profileDataSelector);
  const reachedMaxPassions = useMemo(() => {
    return optionsPicked.length === MAX_PASSIONS;
  }, [optionsPicked]);

  const randomPassions = useMemo(() => {
    const randomPassions = [];
    const randomPassionsIndexes = [];
    while (randomPassions.length < 7) {
      const randomIndex = Math.floor(Math.random() * allPassions.length);
      if (!randomPassionsIndexes.includes(randomIndex)) {
        randomPassionsIndexes.push(randomIndex);
        randomPassions.push(allPassions[randomIndex]);
      }
    }
    return randomPassions;
  }, [allPassions]);

  return (
    <ProfileEditorDrawerPassionsFeelingStuckRandomContainer>
      <ProfileEditorDrawerPassionsFeelingStuckCloseContainer
        onClick={() => setCurrentlyShowing(null)}
      >
        <CloseIcon width={10} height={10} fill={'var(--color-primary)'} />
      </ProfileEditorDrawerPassionsFeelingStuckCloseContainer>
      <ProfileEditorDrawerPassionsFeelingStuckRandomTitle>
        YOU MIGHT ALSO BE INTERESTED IN
      </ProfileEditorDrawerPassionsFeelingStuckRandomTitle>
      <ProfileEditorDrawerPassionsFeelingStuckRandomPillsContainer>
        {randomPassions.map((passion) => {
          const index = optionsPicked.findIndex((option) => option.uuid === passion.uuid);

          return (
            <ProfileEditDataTypePill
              key={passion.name}
              type={index !== -1 ? 'selected' : reachedMaxPassions ? 'disabled' : 'unselected'}
              label={passion.name}
              approved={passion.approved}
              peopleCount={passion.people_count}
              onClick={() => handlePickedPassion(passion)}
            />
          );
        })}
      </ProfileEditorDrawerPassionsFeelingStuckRandomPillsContainer>
      <ProfileEditorDrawerPassionsFeelingStuckRandomShowMoreContainer>
        <Sparkle width={24} height={24} fill={'var(--color-primary)'} />
        <ProfileEditorDrawerPassionsFeelingStuckRandomShowMoreText
          onClick={() => setCurrentlyShowing('all')}
        >
          SHOW MORE
        </ProfileEditorDrawerPassionsFeelingStuckRandomShowMoreText>
      </ProfileEditorDrawerPassionsFeelingStuckRandomShowMoreContainer>
    </ProfileEditorDrawerPassionsFeelingStuckRandomContainer>
  );
}

function ProfileEditorDrawerPassionsFeelingStuckAll({
  handlePickedPassion,
  setCurrentlyShowing,
  optionsPicked,
}: ProfileEditorDrawerPassionsFeelingStuckOptionProps) {
  const { allPassions } = useSelector(profileDataSelector);
  const [currentCategory, setCurrentCategory] = useState('A-C');
  const reachedMaxPassions = useMemo(() => {
    return optionsPicked.length === MAX_PASSIONS;
  }, [optionsPicked]);

  const sortedPassions = useMemo(() => {
    const categorizedObjects = {
      'A-C': [],
      'D-F': [],
      'G-I': [],
      'J-L': [],
      'M-P': [],
      'Q-S': [],
      'T-V': [],
      'W-Z': [],
    };

    for (const object of allPassions) {
      const firstLetter = object.name[0].toUpperCase();
      if (firstLetter >= 'A' && firstLetter <= 'C') {
        categorizedObjects['A-C'].push(object);
      } else if (firstLetter >= 'D' && firstLetter <= 'F') {
        categorizedObjects['D-F'].push(object);
      } else if (firstLetter >= 'G' && firstLetter <= 'I') {
        categorizedObjects['G-I'].push(object);
      } else if (firstLetter >= 'J' && firstLetter <= 'L') {
        categorizedObjects['J-L'].push(object);
      } else if (firstLetter >= 'M' && firstLetter <= 'P') {
        categorizedObjects['M-P'].push(object);
      } else if (firstLetter >= 'Q' && firstLetter <= 'S') {
        categorizedObjects['Q-S'].push(object);
      } else if (firstLetter >= 'T' && firstLetter <= 'V') {
        categorizedObjects['T-V'].push(object);
      } else if (firstLetter >= 'W' && firstLetter <= 'Z') {
        categorizedObjects['W-Z'].push(object);
      }
    }

    return categorizedObjects;
  }, [allPassions]);

  return (
    <ProfileEditorDrawerPassionsFeelingStuckAllContainer>
      <ProfileEditorDrawerPassionsFeelingStuckCloseContainer
        onClick={() => setCurrentlyShowing(null)}
      >
        <CloseIcon width={10} height={10} fill={'var(--color-primary)'} />
      </ProfileEditorDrawerPassionsFeelingStuckCloseContainer>
      <ProfileEditorDrawerPassionsFeelingStuckAllCategoriesContainer>
        {Object.keys(sortedPassions).map((key) => {
          if (sortedPassions[key].length === 0) return <></>;

          return (
            <ProfileEditorDrawerPassionsFeelingStuckAllCategory
              key={key}
              onClick={() => setCurrentCategory(key)}
              current={currentCategory === key}
            >
              {key}
            </ProfileEditorDrawerPassionsFeelingStuckAllCategory>
          );
        })}
      </ProfileEditorDrawerPassionsFeelingStuckAllCategoriesContainer>
      <ProfileEditorDrawerPassionsFeelingStuckAllPillsContainer>
        {sortedPassions[currentCategory].map((passion) => {
          const index = optionsPicked.findIndex((option) => option.uuid === passion.uuid);

          return (
            <ProfileEditDataTypePill
              key={passion.name}
              type={index !== -1 ? 'selected' : reachedMaxPassions ? 'disabled' : 'unselected'}
              label={passion.name}
              approved={passion.approved}
              peopleCount={passion.people_count}
              onClick={() => handlePickedPassion(passion)}
            />
          );
        })}
      </ProfileEditorDrawerPassionsFeelingStuckAllPillsContainer>
    </ProfileEditorDrawerPassionsFeelingStuckAllContainer>
  );
}
