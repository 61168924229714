import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const DataTypeLenWrapper = styled.div`
  width: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 8px 11px;

  & > :not(:last-child) {
    margin-bottom: 4px;
  }

  background: ${({ theme }) => theme.background};
  border-radius: 8px;
  backdrop-filter: blur(24px);

  cursor: pointer;
  z-index: 10;
`;

interface DataTypeLenCounterContainerProps {
  mode?: string;
}

export const DataTypeLenCounterContainer = styled.div<DataTypeLenCounterContainerProps>`
  display: flex;
  align-items: center;

  & > :nth-child(2) {
    margin-left: 4px;
  }

  ${BREAKPOINTS.tablet} {
    ${({ mode }) =>
      mode === 'compact' &&
      `
      flex-direction: column;
      justify-content: center;

      & > :nth-child(2) {
        margin-left: 0px;
        margin-top: 2px;
      }
    `}
  }
`;

export const DataTypeLenCounter = styled.span`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    font-size: 12px;
  }
`;

export const DataTypeLenTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    font-size: 12px;
  }
`;

// TOOLTIP
export const DataTypeLenTooltipContainer = styled.div`
  padding: 8px 16px;
  border-radius: 8px;
  background: #000000;

  z-index: 9999999999999999;
`;

export const DataTypeLenTooltipText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: #ffffff;
`;
