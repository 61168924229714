const passion_highlights = ({
  kyu_companies,
  people_count,
  kyu_companies_count,
}) => {
  let first_paragraph = passion_highlights_first_paragraph({
    kyu_companies_count,
    people_count,
  });
  let second_paragaph = kyu_companies
    ? passion_paragraph({ kyu_companies, people_count })
    : "";

  return first_paragraph + second_paragaph;
};

const passion_highlights_first_paragraph = ({
  kyu_companies_count,
  people_count,
}) => {
  let first_paragraph = `<p><span class="highlight-people">${people_count} ${
    people_count > 1 ? "people" : "person"
  }</span> \
                    from <span class="highlight-kyu-company">${kyu_companies_count} companies</span> \
                    share this passion.</p>`;

  return first_paragraph;
};

const passion_paragraph = ({ kyu_companies, people_count }) => {
  let paragraph =
    "<p>Noticeably, this passion is popular with people that work at ";

  if (people_count > 1) {
    let companies_array = kyu_companies
      .slice(0, 3)
      .map(
        (company) =>
          `<a href="/member-company/${company.slug}"><span class="highlight-kyu-company">${company.name}</span></a>`
      );
    paragraph += `${companies_array
      .slice(0, companies_array.length - 1)
      .join(", ")} and <a href="/member-company/${
      kyu_companies[kyu_companies.length - 1].slug
    }"><span class="highlight-kyu-company">${
      kyu_companies[kyu_companies.length - 1].name
    }</span></a>.</p>`;
  }

  return paragraph;
};

const passion_kyu_company_highlights = ({
  passion,
  kyu_companies,
  kyu_companies_count,
  kyu_company_people_count,
  people_count,
}) => {
  let first_paragraph = `<p><span class="highlight-people">${people_count} ${
    people_count > 1 ? "people" : "person"
  }</span> \
                    from <span class="highlight-kyu-company">${kyu_companies_count} companies</span> \
                    share this passion.</p>`;

  let people_with_passion = "";
  if (kyu_company_people_count > 1) {
    people_with_passion = `is a common passion between <span class="highlight-people">${kyu_company_people_count} people</span>.`;
  } else {
    people_with_passion = `has, for now, been added to <span class="highlight-people">1 person's</span> profile.`;
  }
  let second_paragraph = `<p>At \
                      <span class="highlight-kyu-company">${kyu_companies}</span>, \
                      <span class="highlight-passion">${passion}</span> ${people_with_passion}`;

  return first_paragraph + second_paragraph;
};

export { passion_highlights, passion_kyu_company_highlights };
