import React from 'react';

import HomeRadarActiveWorkClient from './HomeRadarActiveWorkClient';
import { HomeRadarActiveWorkContext } from './HomeRadarActiveWorkContext';
import HomeRadarActiveWorkIndustry from './HomeRadarActiveWorkIndustry';
import {
  HomeRadarActiveWorkContainer,
  HomeRadarActiveWorkTitle,
  HomeRadarActiveWorkWrapper,
} from './styled';

interface HomeRadarActiveWorkProps {
  /**
   * The number of items to display.
   */
  limit?: number | null;

  /**
   * The component inside clients list
   */
  clientComponent?: React.ReactNode;

  /**
   * The component inside industries list
   */
  industryComponent?: React.ReactNode;
}

export default function HomeRadarActiveWork({
  limit = null,
  clientComponent = null,
  industryComponent = null,
}: HomeRadarActiveWorkProps) {
  return (
    <HomeRadarActiveWorkContext.Provider value={{ limit, clientComponent, industryComponent }}>
      <HomeRadarActiveWorkWrapper>
        <HomeRadarActiveWorkTitle>Active Work</HomeRadarActiveWorkTitle>
        <HomeRadarActiveWorkContainer>
          <HomeRadarActiveWorkClient />
          <HomeRadarActiveWorkIndustry />
        </HomeRadarActiveWorkContainer>
      </HomeRadarActiveWorkWrapper>
    </HomeRadarActiveWorkContext.Provider>
  );
}
