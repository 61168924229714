import React from 'react';

export default function ActivitesIcon({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4523 1.2667C11.7781 1.40812 11.9716 1.74737 11.9276 2.09977L11.24 7.60055H17.5338C17.8442 7.60055 18.1266 7.78011 18.2582 8.06122C18.3899 8.34233 18.3471 8.67423 18.1483 8.91269L10.1483 18.5127C9.92097 18.7855 9.54097 18.8758 9.2152 18.7344C8.88942 18.593 8.69588 18.2537 8.73993 17.9013L9.42753 12.4005H3.13375C2.82334 12.4005 2.54094 12.221 2.40928 11.9399C2.27761 11.6588 2.32045 11.3269 2.51917 11.0884L10.5192 1.4884C10.7465 1.21557 11.1265 1.12529 11.4523 1.2667Z"
        fill="#FFC229"
      />
    </svg>
  );
}
