export enum DETAILS_PAGE_ACTION {
  /* Only works for Client and Industry detail pages */
  ACTIVE_PROJECTS_DRAWER_OPEN = 'ACTIVE_PROJECTS_DRAWER_OPEN',

  /* Only works for Member Company detail page*/
  ACTIVE_CLIENTS_DRAWER_OPEN = 'ACTIVE_CLIENTS_DRAWER_OPEN',

  /* Only works for Member Company detail page */
  ACTIVE_INDUSTRIES_DRAWER_OPEN = 'ACTIVE_INDUSTRIES_DRAWER_OPEN',

  /* Only works for Member Company detail page */
  PEOPLE_DRAWER_OPEN = 'PEOPLE_DRAWER_OPEN',
}
