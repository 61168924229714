import React, { useContext, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import HomeDrawer from 'app/components/CommonStyled/HomeDrawers';
import Select, { ISelectItem } from 'app/components/CommonStyled/Select';
import SelectItem from 'app/components/CommonStyled/Select/SelectItem';
import SideDrawer from 'app/components/CommonStyled/SideDrawer';
import { ClientIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import ComponentError from 'app/components/DetailsPages/shared/ComponentError/ComponentError';
import { ByClient, ByMemberCompany, OnlyNew } from 'app/components/Home/interfaces';
import { DeltaIcon, RightArrow } from 'app/components/shared/icons';
import { DETAILS_PAGE_ACTION } from 'app/pages/NewDetailsPage/actions';
import { arrayToOxford, ordinal_suffix_of } from 'utils';

import { HomeRadarActiveWorkContext, HomeRadarActiveWorkInfo } from '../HomeRadarActiveWorkContext';
import { HomeRadarActiveWorkAllClientsButtonText } from '../styled';
import useRadarActiveWork from '../useRadarActiveWork';
import {
  HomeRadarActiveWorkAllClientsButton,
  HomeRadarActiveWorkClientContainer,
  HomeRadarActiveWorkClientLogoContainer,
  HomeRadarActiveWorkClientText,
  HomeRadarActiveWorkClientTextContainer,
  HomeRadarActiveWorkClientsList,
  HomeRadarActiveWorkClientsWrapper,
  HomeRadarActiveWorkFilterContainer,
  HomeRadarActiveWorkNewClients,
  HomeRadarActiveWorkSkeletonItem,
  HomeRadarActiveWorkSkeletonList,
  HomeRadarActiveWorkTotalsClients,
  HomeRadarActiveWorkTotalsClientsContainer,
  HomeRadarActiveWorkTotalsContainer,
  HomeRadarActiveWorkTotalsRow,
  HomeRadarActiveWorkTotalsTime,
  HomeRadarActiveWorkTotalsTimeContainer,
  HomeRadarActiveWorkTotalsTitle,
} from './styled';

export default function HomeRadarActiveWorkClient() {
  const query = useRadarActiveWork();
  const { isLoading, isError, data } = query;
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<string>('by_client');
  const clientListRef = useRef<HTMLDivElement>(null);
  const { limit, clientComponent } = useContext<HomeRadarActiveWorkInfo>(
    HomeRadarActiveWorkContext
  );

  const handleDrawerClose = () => {
    setShowDrawer(false);
  };

  const totalClients = useMemo<string>(() => {
    if (isLoading) return '';
    if (data.clients.totals?.latest_clients > 0) return `+${data.clients.totals?.latest_clients}`;
    if (data.clients.totals?.latest_clients < 0) return `-${data.clients.totals?.latest_clients}`;

    return `${data.clients.totals?.latest_clients}`;
  }, [data]);

  const handleOptionChange = (option: ISelectItem<string>) => {
    setCurrentFilter(option.value);
    if (clientListRef.current) {
      clientListRef.current.scrollTop = 0;
    }
  };

  const handleAllClientsClick = () => {
    setCurrentFilter('by_client');
  };

  if (isError) return <ComponentError query={query} skeleton={<HomeRadarActiveWorkSkeleton />} />;

  if (isLoading) return <HomeRadarActiveWorkSkeleton />;

  return (
    <HomeRadarActiveWorkClientsWrapper>
      <HomeRadarActiveWorkTotalsContainer>
        <HomeRadarActiveWorkTotalsRow>
          <HomeRadarActiveWorkTotalsTitle>CLIENTS</HomeRadarActiveWorkTotalsTitle>
          <HomeRadarActiveWorkTotalsTimeContainer>
            <HomeRadarActiveWorkTotalsTime>last 30 days</HomeRadarActiveWorkTotalsTime>
            {data.clients.totals?.latest_clients > 0 ? <DeltaIcon /> : null}
          </HomeRadarActiveWorkTotalsTimeContainer>
        </HomeRadarActiveWorkTotalsRow>
        <HomeRadarActiveWorkTotalsRow>
          <HomeRadarActiveWorkTotalsClientsContainer>
            <ClientIcon width={24} height={24} fill={'var(--color-data-client-foreground)'} />
            <HomeRadarActiveWorkTotalsClients>
              {data.clients.totals?.total_clients}
            </HomeRadarActiveWorkTotalsClients>
          </HomeRadarActiveWorkTotalsClientsContainer>
          <HomeRadarActiveWorkNewClients>{totalClients}</HomeRadarActiveWorkNewClients>
        </HomeRadarActiveWorkTotalsRow>
      </HomeRadarActiveWorkTotalsContainer>
      <HomeRadarActiveWorkFilterContainer>
        <Select label="View by" onOptionChange={handleOptionChange} value={currentFilter}>
          <SelectItem value="by_client" defaultChecked>
            client
          </SelectItem>
          <SelectItem value="by_member_company">member company</SelectItem>
          <SelectItem value="only_new">only new</SelectItem>
        </Select>
      </HomeRadarActiveWorkFilterContainer>
      <HomeRadarActiveWorkClientsList ref={clientListRef}>
        {data.clients.details[currentFilter].length > 0 &&
          data.clients.details[currentFilter]
            .slice(0, limit ?? data.clients.details[currentFilter].length)
            .map((client) => {
              switch (currentFilter) {
                case 'by_client':
                  return <HomeRadarActiveWorkClientByClient key={client.slug} data={client} />;
                case 'by_member_company':
                  return (
                    <HomeRadarActiveWorkClientByMemberCompany key={client.slug} data={client} />
                  );
                case 'only_new':
                  return <HomeRadarActiveWorkClientOnlyNews key={client.slug} data={client} />;
                default:
                  return <></>;
              }
            })}
        {currentFilter === 'only_new' ? (
          <HomeRadarActiveWorkAllClientsButton onClick={handleAllClientsClick}>
            <HomeRadarActiveWorkAllClientsButtonText>
              See all active clients
            </HomeRadarActiveWorkAllClientsButtonText>
            <RightArrow width={16} height={16} fill="var(--color-primary)" />
          </HomeRadarActiveWorkAllClientsButton>
        ) : null}
        {clientComponent ?? null}
      </HomeRadarActiveWorkClientsList>
      {showDrawer ? (
        <SideDrawer closeDrawer={handleDrawerClose} withClose>
          <HomeDrawer model={'clients'} extraParams={{ withActiveFilter: true }} />
        </SideDrawer>
      ) : null}
    </HomeRadarActiveWorkClientsWrapper>
  );
}

interface HomeRadarActiveWorkClientByClientProps {
  data: ByClient;
}

function HomeRadarActiveWorkClientByClient({
  data,
}: Readonly<HomeRadarActiveWorkClientByClientProps>) {
  const { push } = useHistory();

  const handleClientClick = () => {
    push(`/client/${data.slug}`, {
      action: DETAILS_PAGE_ACTION.ACTIVE_PROJECTS_DRAWER_OPEN,
    });
  };

  const started = useMemo<boolean>(() => {
    return Object.values<any>(data.companies).some((company) => company.started);
  }, [data]);

  const singleCompanyText = useMemo<string>(() => {
    const company = Object.values<any>(data.companies)[0];
    const amountOfProjects = company.active_project_count + company.past_project_count;
    const hasNewProjects = company.started;

    if (hasNewProjects) {
      if (amountOfProjects > 1) {
        return ` and ${company.name} started their ${ordinal_suffix_of(
          amountOfProjects
        )} project together.`;
      }

      return ` and ${company.name} started working together for the first time.`;
    } else {
      if (amountOfProjects > 1) {
        return ` and ${company.name} are working on their ${ordinal_suffix_of(
          amountOfProjects
        )} project together.`;
      }

      return ` and ${company.name} are working on their first project together.`;
    }
  }, [data]);

  const multipleCompaniesText = useMemo<string>(() => {
    const companies: string[] = Object.values<any>(data.companies).map((company) => company.name);

    return ` currently has active projects with ${
      companies.length
    } member companies; ${arrayToOxford(companies)}.`;
  }, [data]);

  const fullText = useMemo<string>(() => {
    const amountOfCompanies = Object.keys(data.companies).length;

    if (amountOfCompanies === 1) return singleCompanyText;
    if (amountOfCompanies > 1) return multipleCompaniesText;
  }, [data]);

  return (
    <HomeRadarActiveWorkClientItem started={started} onClick={handleClientClick}>
      <HomeRadarActiveWorkClientText>
        <HomeRadarActiveWorkClientText highlighted>{data.name}</HomeRadarActiveWorkClientText>
        <HomeRadarActiveWorkClientText>{fullText}</HomeRadarActiveWorkClientText>
      </HomeRadarActiveWorkClientText>
    </HomeRadarActiveWorkClientItem>
  );
}

interface HomeRadarActiveWorkClientByMemberCompanyProps {
  data: ByMemberCompany;
}

function HomeRadarActiveWorkClientByMemberCompany({
  data,
}: Readonly<HomeRadarActiveWorkClientByMemberCompanyProps>) {
  const { push } = useHistory();

  const handleClientClick = () => {
    push(`/member-company/${data.slug}`, {
      action: DETAILS_PAGE_ACTION.ACTIVE_CLIENTS_DRAWER_OPEN,
    });
  };

  const singleClientText = useMemo<string>(() => {
    const client = Object.values<any>(data.companies)[0];
    const hasNewProjects = client.started;
    const totalProjectsCount = client.active_project_count + client.past_project_count;

    if (hasNewProjects) {
      if (totalProjectsCount > 1) {
        return ` and ${client.name} recently started their ${ordinal_suffix_of(
          totalProjectsCount
        )} project together.`;
      }

      return ` and ${client.name} recently started working together for the first time.`;
    } else {
      return ` is currently working on their ${ordinal_suffix_of(
        totalProjectsCount
      )} project with ${client.name}.`;
    }
  }, []);

  const multipleClientsExistingText = useMemo<string>(() => {
    const companies = Object.values<any>(data.companies).map((company) => company.name);

    return ` is currently working with ${companies.length} ${
      companies.length !== 1 ? 'clients' : 'client'
    }; ${arrayToOxford(companies)}`;
  }, [data]);

  const multipleClientsIncludingNewText = useMemo<string>(() => {
    const newestClients = Object.values<any>(data.companies).filter((company) => company.started);
    const newestClientsNames = newestClients.map((company) => company.name);
    const oldClients = Object.values<any>(data.companies).filter((company) => !company.started);
    const oldClientsNames = oldClients.map((company) => company.name);

    return `'s newest ${newestClients.length !== 1 ? 'clients are' : 'client is'} ${arrayToOxford(
      newestClientsNames
    )}. They are also currently working with ${arrayToOxford(oldClientsNames)}.`;
  }, [data]);

  const fullText = useMemo<string>(() => {
    const amountOfCompanies = Object.keys(data.companies).length;

    if (amountOfCompanies === 1) {
      return singleClientText;
    }

    if (amountOfCompanies > 1) {
      const newCompanies = Object.values<any>(data.companies).filter(
        (company) => company.started
      ).length;

      if (newCompanies > 0) return multipleClientsIncludingNewText;
      return multipleClientsExistingText;
    }
    return '';
  }, [data]);

  return (
    <HomeRadarActiveWorkClientItem onClick={handleClientClick}>
      <HomeRadarActiveWorkClientText>
        <HomeRadarActiveWorkClientText highlighted>{data.name}</HomeRadarActiveWorkClientText>
        <HomeRadarActiveWorkClientText>{fullText}</HomeRadarActiveWorkClientText>
      </HomeRadarActiveWorkClientText>
    </HomeRadarActiveWorkClientItem>
  );
}

interface HomeRadarActiveWorkClientOnlyNewsProps {
  data: OnlyNew;
}

function HomeRadarActiveWorkClientOnlyNews({
  data,
}: Readonly<HomeRadarActiveWorkClientOnlyNewsProps>) {
  const { push } = useHistory();

  const handleClientClick = () => {
    push(`/client/${data.slug}`, {
      action: DETAILS_PAGE_ACTION.ACTIVE_PROJECTS_DRAWER_OPEN,
    });
  };

  const fullText = useMemo<string>(() => {
    const company = Object.values<any>(data.companies)[0];
    const companies = Object.values<any>(data.companies).map((company) => company.name);

    if (companies.length === 1) {
      return ` and ${company.name} recently started working together for the first time.`;
    }

    return ` recently started working with ${arrayToOxford(companies)} for the first time.`;
  }, [data]);

  return (
    <HomeRadarActiveWorkClientItem onClick={handleClientClick}>
      <HomeRadarActiveWorkClientText>
        <HomeRadarActiveWorkClientText highlighted>{data.name}</HomeRadarActiveWorkClientText>
        <HomeRadarActiveWorkClientText>{fullText}</HomeRadarActiveWorkClientText>
      </HomeRadarActiveWorkClientText>
    </HomeRadarActiveWorkClientItem>
  );
}

interface HomeRadarActiveWorkClientItemProps {
  started?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

function HomeRadarActiveWorkClientItem({
  started = false,
  children,
  onClick,
}: Readonly<HomeRadarActiveWorkClientItemProps>) {
  const handleClick = () => {
    if (onClick) {
      return onClick();
    }
  };

  return (
    <HomeRadarActiveWorkClientContainer onClick={handleClick}>
      <HomeRadarActiveWorkClientLogoContainer>
        <ClientIcon fill={'var(--color-data-client-foreground)'} width={14} height={14} />
      </HomeRadarActiveWorkClientLogoContainer>
      <HomeRadarActiveWorkClientTextContainer>{children}</HomeRadarActiveWorkClientTextContainer>
      {started ? <DeltaIcon /> : null}
    </HomeRadarActiveWorkClientContainer>
  );
}

function HomeRadarActiveWorkSkeleton() {
  return (
    <HomeRadarActiveWorkSkeletonList>
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
      <HomeRadarActiveWorkSkeletonItem />
    </HomeRadarActiveWorkSkeletonList>
  );
}
