import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  CompanyIcon,
  IndustryIcon,
  ProjectIcon,
} from 'app/components/DetailsPages/shared/CommonGround/Icons';
import PersonIcon from 'app/components/shared/icons/person-icon';

import GlobalDataTypeDrawer from '../GlobalDataType/GlobalDataTypeDrawer';
import useIndustryDrawerData from '../hooks/useIndustryDrawerData';
import {
  GlobalIndustryDrawerCounter,
  GlobalIndustryDrawerCounterContainer,
  GlobalIndustryDrawerCounterIconContainer,
  GlobalIndustryDrawerCountersWrapper,
  GlobalIndustryDrawerCounterText,
  GlobalIndustryDrawerHighlightText,
  GlobalIndustryDrawerHighlightTextContainer,
  GlobalIndustryDrawerPriorDisclaimer,
} from './styled';

interface GlobalIndustryDrawerProps {
  slug: string;
  comingFromList?: boolean;
  backToListAction?: () => void;
}

export default function GlobalIndustryDrawer({
  slug,
  comingFromList = false,
  backToListAction = () => {},
}: GlobalIndustryDrawerProps) {
  const query = useIndustryDrawerData(slug);
  const { isLoading, data } = query;
  const { push } = useHistory();

  return (
    <GlobalDataTypeDrawer
      slug={slug}
      query={query}
      isLoading={isLoading}
      background={
        'linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(206, 242, 158, 0.3) 100%), #FFFFFF'
      }
      dataType={'industry'}
      title={data?.name || ''}
      includesCurrentPerson={data?.includes_current_person || ''}
      icon={<IndustryIcon fill={'#8DA76C'} />}
      catchphraseHighlightColor={'#8DA76C'}
      iconBackground={'#CEF29E'}
      buttonOnClick={() => push(`/industry/${data?.slug}`)}
      buttonBackgroundColor={'#8DA76C'}
      peopleAtCompany={data?.people_at_company || null}
      comingFromList={comingFromList}
      backToListAction={() => backToListAction()}
    >
      {data?.highlight && data?.counters.projects > 0 ? (
        <GlobalIndustryDrawerHighlightTextContainer>
          <GlobalIndustryDrawerHighlightText>
            <span className="people">{data?.highlight.people_count} people</span> from{' '}
            <span className="company">{data?.highlight.company_count} companies</span> have
            connections to this industry.
          </GlobalIndustryDrawerHighlightText>
          <GlobalIndustryDrawerHighlightText>
            {data?.highlight.active_client_count > 0 ? (
              <>
                There are{' '}
                <span className="client">
                  {data?.highlight.active_client_count} active{' '}
                  {data?.highlight.active_client_count !== 1 ? 'clients' : 'client'}
                </span>{' '}
                within this industry and, in total,
              </>
            ) : (
              <>In total,</>
            )}{' '}
            we have worked with{' '}
            <span className="client">
              {data?.highlight.total_client_count}{' '}
              {data?.highlight.total_client_count !== 1 ? 'clients' : 'client'}
            </span>{' '}
            on{' '}
            <span className="project">
              {data?.highlight.projects_count}{' '}
              {data?.highlight.projects_count !== 1 ? 'projects' : 'project'}
            </span>
            {'.'}
          </GlobalIndustryDrawerHighlightText>
          <GlobalIndustryDrawerHighlightText>
            Overall,{' '}
            {data?.highlight.company_names.map((companyName, index) => (
              <span key={index}>
                <span className="company">{companyName}</span>
                {index !== data?.highlight.company_names.length - 1
                  ? index === data?.highlight.company_names.length - 2
                    ? ' and '
                    : ', '
                  : ''}
              </span>
            ))}{' '}
            have the most projects connected to this industry.
          </GlobalIndustryDrawerHighlightText>
        </GlobalIndustryDrawerHighlightTextContainer>
      ) : (
        <GlobalIndustryDrawerHighlightTextContainer>
          <GlobalIndustryDrawerHighlightText>
            <span className="people">
              {data?.counters.people} {data?.counters.people > 1 ? 'people' : 'person'}
            </span>{' '}
            {data?.counters.people > 1 ? 'have' : 'has'} connections to this industry.
          </GlobalIndustryDrawerHighlightText>
          <GlobalIndustryDrawerPriorDisclaimer>
            There are no active clients as this industry was added by people as prior experience.
          </GlobalIndustryDrawerPriorDisclaimer>
        </GlobalIndustryDrawerHighlightTextContainer>
      )}
      <GlobalIndustryDrawerCountersWrapper>
        {data?.counters.projects > 0 && (
          <GlobalIndustryDrawerCounterContainer>
            <GlobalIndustryDrawerCounterIconContainer>
              <ProjectIcon width={24} height={24} fill={'#392B62'} />
              <GlobalIndustryDrawerCounter>{data.counters.projects}</GlobalIndustryDrawerCounter>
            </GlobalIndustryDrawerCounterIconContainer>
            <GlobalIndustryDrawerCounterText>
              {data.counters.projects > 1 ? 'projects' : 'project'}
            </GlobalIndustryDrawerCounterText>
          </GlobalIndustryDrawerCounterContainer>
        )}
        {data?.counters.companies > 0 && (
          <GlobalIndustryDrawerCounterContainer>
            <GlobalIndustryDrawerCounterIconContainer>
              <CompanyIcon width={24} height={24} />
              <GlobalIndustryDrawerCounter>{data.counters.companies}</GlobalIndustryDrawerCounter>
            </GlobalIndustryDrawerCounterIconContainer>
            <GlobalIndustryDrawerCounterText>
              {data.counters.companies > 1 ? 'companies' : 'company'}
            </GlobalIndustryDrawerCounterText>
          </GlobalIndustryDrawerCounterContainer>
        )}
        {data?.counters.people > 0 && (
          <GlobalIndustryDrawerCounterContainer>
            <GlobalIndustryDrawerCounterIconContainer>
              <PersonIcon width={24} height={24} fill={'#FFC229'} />
              <GlobalIndustryDrawerCounter>{data.counters.people}</GlobalIndustryDrawerCounter>
            </GlobalIndustryDrawerCounterIconContainer>
            <GlobalIndustryDrawerCounterText>
              {data.counters.people > 1 ? 'people' : 'person'}
            </GlobalIndustryDrawerCounterText>
          </GlobalIndustryDrawerCounterContainer>
        )}
      </GlobalIndustryDrawerCountersWrapper>
    </GlobalDataTypeDrawer>
  );
}
