import styled from 'styled-components';

export const ProfileEditInputTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

interface ProfileEditInputTextContainerProps {
  isValid: boolean;
  disabled: boolean;
  textarea: boolean;
}

export const ProfileEditInputTextContainer = styled.textarea<ProfileEditInputTextContainerProps>`
  user-select: text;
  height: 56px;
  width: 100%;

  padding: 15px 10px;

  background: rgba(173, 173, 173, 0.08);
  border-radius: 8px;

  ${({ isValid }) => !isValid && `
    border: 1px solid var(--color-error) !important;
  `}

  ${({ textarea }) => textarea && `
    height: 300px;
  `}

  font-weight: 400;
  font-size: 18px;
  line-height: 23px;

  & > :focus {
    border: 1px solid var(--color-data-company-background-hover);
  }

  ${({ disabled }) => disabled && `
    background: rgba(173, 173, 173, 0.3);
    border: 1px solid rgba(23, 28, 51, 0.5);

    & > span {
      color: #949494 !important;
    }
  `}

  position: relative;

  resize: vertical;
`;

export const ProfileEditInputTextPlaceholder = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: rgba(23, 28, 51, 0.5);

  position: absolute;
  top: 15px;
  left: 10px;

  user-select: none;
  pointer-events: none;
`;

export const ProfileEditInputTextLimit = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(23, 28, 51, 0.5);
`;

export const ProfileEditInputTextErrorContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const ProfileEditInputTextError = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-error);
`;
