import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsNetworkIntroWrapper = styled.div``;

export const AboutKyuOsNetworkIntroTitle = styled.h3`
  max-width: 880px;

  font-weight: 400;
  font-size: 24px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
  }

  color: var(--color-text);
`;

export const AboutKyuOsNetworkIntroSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 42px;
`;

export const AboutKyuOsNetworkIntroSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 50px;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    gap: 35px;
  }
`;

export const AboutKyuOsNetworkIntroSectionInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AboutKyuOsNetworkIntroSectionTitle = styled.h3`
  font-weight: 400;
  font-size: 32px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    font-size: 24px;
  }

  color: var(--color-text);
`;

export const AboutKyuOsNetworkIntroSectionDescription = styled.p`
  max-width: 517px;

  font-weight: 400;
  font-size: 18px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
  }

  color: var(--color-text);
`;

export const AboutKyuOsNetworkIntroSectionPillsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;
