export const theme = {
  start: {
    background: 'rgba(251, 175, 161, 0.4)',
    progressBackground: 'rgba(251, 175, 161, 1)',
  },
  middle: {
    background: 'rgba(255, 210, 95, 0.4)',
    progressBackground: 'rgba(255, 210, 95, 1)',
  },
  end: {
    background: 'rgba(206, 242, 158, 0.4)',
    progressBackground: 'rgba(206, 242, 158, 1)',
  },
};
