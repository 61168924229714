import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const ToolsCollectionWrapper = styled.div`
  margin-top: 300px;

  padding: 0px 3.9%;

  
  ${BREAKPOINTS.normal} {
    padding: 0 6.3%;
  }

  ${BREAKPOINTS.tablet} {
    padding: 0 3.9%;
  }

  ${BREAKPOINTS.mobile} {
    padding: 0 3.6%;
  }
`;

interface ToolsRowProps {
  alignItems?: string;
  columnGap?: number;
  gridTemplateColumns?: string;
}

export const ToolsRow = styled.div<ToolsRowProps>`
  width: 100%;
  height: fit-content;
  display: grid;
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  
  grid-template-columns: ${({ gridTemplateColumns = 'repeat(12, 1fr)' }) => gridTemplateColumns};
  column-gap: ${({ columnGap = 42 }) => `${columnGap}px`};

  ${BREAKPOINTS.normal} {
    column-gap: 36px;
  }
  
  ${BREAKPOINTS.tablet} {
    column-gap: 16px;
  }

  ${BREAKPOINTS.mobile} {
    column-gap: 8px;
  }
`;

interface ToolsColumnProps {
  size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export const ToolsColumn = styled.div<ToolsColumnProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  grid-column: span ${({ size }) => size};
`;

export const PublicationDivider = styled.div`
  width: 1px;
  height: 100%;
  grid-column: span 1;
  background-color: #000000;
`;

export const TestSquare = styled.div`
  width: 100%;
  height: 100px;
  background-color: #CECECE;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ThreeItemCollectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 25px;
  }
`;

export const FourItemCollectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 25px;
  }
`;

export const FiveItemCollectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 42px;

    ${BREAKPOINTS.normal} {
      margin-bottom: 36px;
    }
    
    ${BREAKPOINTS.tablet} {
      margin-bottom: 16px;
    }
  
    ${BREAKPOINTS.mobile} {
      margin-bottom: 8px;
    }
  }
`;

export const SixItemCollectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 80px;

    ${BREAKPOINTS.normal} {
      margin-bottom: 36px;
    }
    
    ${BREAKPOINTS.tablet} {
      margin-bottom: 16px;
    }
  
    ${BREAKPOINTS.mobile} {
      margin-bottom: 8px;
    }
  }

  & > :last-child {
    margin-top: 14px;
  }
`;

export const CollectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 8px;

  & > :not(:last-child) {
    margin-bottom: 88px;

    ${BREAKPOINTS.tablet} {
      margin-bottom: 4px;
    }
  }
`;

export const CollectionMobileFirstChildContainer = styled.div`
  padding: 0px calc(3.6% - 8px);
`;