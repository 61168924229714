import React from 'react';

import { StyledComponent } from 'styled-components';

import { SelectItemWrapper } from './styled';

export default function SelectItem(
  props: StyledComponent<'option', any, {}, never>['defaultProps']
) {
  const handleClick = () => {
    props.onClick({
      // @ts-ignore
      value: props.value,
      label: props.children,
    });
  };

  return (
    <SelectItemWrapper {...props} onClick={handleClick}>
      {props.children}
    </SelectItemWrapper>
  );
}
