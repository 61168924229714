import { SliceCaseReducers, createSelector, createSlice } from '@reduxjs/toolkit';

export interface IHistoryEntry {
  pathname: string;
  search: string;
  hash: string;
  state: any;
  key: string;
}

export interface HistoryState {
  entries: IHistoryEntry[];
}

export const slice = createSlice<HistoryState, SliceCaseReducers<HistoryState>>({
  name: 'history',
  initialState: {
    entries: [],
  },
  reducers: {
    setHistory: (state, action: { payload: IHistoryEntry[] }) => {
      return {
        ...state,
        entries: action.payload,
      };
    },
    pushHistoryEntry: (state, action: { payload: IHistoryEntry }) => {
      return {
        ...state,
        entries: [...state.entries, action.payload],
      };
    },
  },
});

export const { pushHistoryEntry, setHistory } = slice.actions;

export const historySelector = (state): IHistoryEntry[] => state.history.entries;

export const lastHistoryEntrySelector = createSelector(
  [historySelector],
  (entries: IHistoryEntry[]) => entries[entries.length - 1]
);

export default slice.reducer;
