import { createSlice } from "@reduxjs/toolkit";
import { GOOGLE } from "app/components/Auth";

export const slice = createSlice({
  name: 'auth',
  initialState: {
    currentStep: 1,
    isNewUser: false,
    isAuthOpen: false,
    authType: GOOGLE,
  },
  reducers: {
    setCurrentStep: (state, action: { payload: number }) => ({
      ...state,
      currentStep: action.payload,
    }),
    setIsNewUser: (state, action: { payload: boolean }) => ({
      ...state,
      isNewUser: action.payload,
    }),
    setIsAuthOpen: (state, action: { payload: boolean }) => ({
      ...state,
      isAuthOpen: action.payload,
    }),
    setAuthType: (state, action: { payload: string }) => ({
      ...state,
      authType: action.payload,
    }),
  },
});

export const { setCurrentStep, setIsNewUser, setIsAuthOpen, setAuthType } = slice.actions;

export const currentStepSelector = (state) => state.auth.currentStep;
export const isNewUserSelector = (state) => state.auth.isNewUser;
export const isAuthOpenSelector = (state) => state.auth.isAuthOpen;
export const authTypeSelector = (state) => state.auth.authType;

export default slice.reducer;
