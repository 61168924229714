import {
  ICommnuityMeetSomeoneNewResponse,
  ICommunityMapSnippetResponse,
  ICommunityPeopleResponse,
} from 'app/components/Communities/interfaces/community';

import { http } from './http';

export const getCommunityMap = (slug: string) => {
  return http.get(`communities/${slug}/maps`);
};

export const getCommunityMapInfo = (slug: string) => {
  return http.get(`communities/${slug}/maps/nodes`);
};

export const getCommunityMeetSomeoneNew = (slug: string) => {
  return http.get<ICommnuityMeetSomeoneNewResponse>(`communities/${slug}/details/meet_someone_new`);
};

export const getCommunityPeople = (slug: string) => {
  return http.get<ICommunityPeopleResponse>(`communities/${slug}/details/people`);
};

export const getCommunityMapSnippet = (slug: string) => {
  return http.get<ICommunityMapSnippetResponse>(`communities/${slug}/details/map_snippet`);
};

export const getCommunityPeopleDrawer = (slug: string, params) => {
  return http.get<ICommunityPeopleResponse>(`communities/${slug}/drawers/people`, params);
};
