import React from 'react';

import useArticleDetails from 'app/components/TheWell/hooks/useArticleDetails';
import Publication from 'app/components/TheWell/shared/publication';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';

import { AboutKyuOsWayfindingArticleWrapper } from './styled';

export default function AboutKyuOsWayfindingArticle() {
  const useCasesOpenedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_USECASES_OPENED);
  const { data, isLoading } = useArticleDetails('how-we-use-kyu-os');

  return (
    <AboutKyuOsWayfindingArticleWrapper>
      {!isLoading ? (
        <Publication
          publication={{ ...data, thumbnail_image: data.header_image, type: 'articles' }}
          type="article-index"
          onClickBehavioral={useCasesOpenedBehavioralFunction}
        />
      ) : null}
    </AboutKyuOsWayfindingArticleWrapper>
  );
}
