import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const StoriesHighlightsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const StoriesHighlightsTitle = styled.h3`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 300;

  font-size: 24px;
  line-height: 31px;

  color: #171c33;
`;

export const StoriesHighlightsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  overflow-x: auto;

  & > :not(:last-child) {
    margin-right: 24px;
  }

  & > div {
    min-width: 295px;
    height: fit-content;
  }

  position: relative;
  padding-bottom: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

interface ResourceProps {
  size?: 'sm' | 'lg';
  mode?: 'light' | 'dark' | 'community';
}

const backgrounds = {
  light: '#F5FAFF',
  dark: '#222A4D',
  community: 'rgba(250, 247, 247, 0.3)',
};
const hoverBackgrounds = {
  light: '#fafafa',
  dark: '#323E71',
};
const colors = {
  light: '#171C33',
  community: '#171C33',
  dark: '#fff',
};
const secondaryColors = {
  light: 'rgba(31, 32, 37, 0.5)',
  community: 'rgba(31, 32, 37, 0.5)',
  dark: 'rgba(255, 255, 255, 0.5)',
};

export const Resource = styled.div<ResourceProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  min-width: 285px;
  max-height: 321px;

  ${BREAKPOINTS.mobile} {
    width: 65vw;
    max-width: 65vw !important;
    min-width: 65vw !important;
  }

  background: ${({ mode }) => (mode ? backgrounds[mode] : '#F5FAFF')};
  cursor: pointer;

  position: relative;

  & > h3 {
    color: ${({ mode }) => (mode ? colors[mode] : '#171C33')};
  }

  & > div > span > * {
    color: ${({ mode }) => (mode ? colors[mode] : '#171C33')};
  }

  & a {
    color: ${({ mode }) => (mode ? secondaryColors[mode] : 'rgba(31, 32, 37, 0.5)')};
  }
`;

export const ResourceByKyuPill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 12px;
  background: #171c33;
  border-radius: 32px;

  position: absolute;
  right: 11px;
  top: 8px;

  & > span {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }
`;

export const ResourceImage = styled.img`
  width: 100%;
  height: 144px;
  object-fit: cover;
`;

interface ResourceTitleProps {
  createdByKyu?: boolean;
}

export const ResourceTitle = styled.h3<ResourceTitleProps>`
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #171c33;

  ${({ createdByKyu }) =>
    createdByKyu &&
    `
      max-width: calc(100% - 64px);
    `}
`;

export const ResourceDescription = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: #171c33;
  max-height: 30%;
  overflow: hidden;
`;

export const ResourceLink = styled.a`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: rgba(31, 32, 37, 0.5);
`;

export const ResourceLinkContainer = styled.div`
  display: flex;
  & > :not(:last-child) {
    margin-right: 8px;
  }

  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
`;
