const typeLabel = {
  'skills-passions': 'CONNECTIONS BETWEEN',
  'people-group': 'CONNECTIONS BETWEEN',
  client: 'Clients',
  industry: 'INDUSTRY',
  'member-company': 'Company',
  passion: 'Passions',
  person: 'People',
  project: 'Project',
  skill: 'Skills',
  discipline: 'Discipline',
};

export default typeLabel;
