import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import { EyeIcon } from 'app/components/shared/icons';
import EditPencilIcon from 'app/components/shared/icons/edit-pencil';
import useUserData from 'hooks/useUserData';
import { setIsNavbarMenuOpen } from 'store/reducers/navbar-reducer';

import {
  MobileMenuProfileInformationCompany,
  MobileMenuProfileInformationContainer,
  MobileMenuProfileInformationName,
  MobileMenuProfileRightContainer,
  MobileMenuProfileRightIconContainer,
  MobileMenuProfileWrapper,
} from './styled';

export default function MobileMenuProfile() {
  const userData = useUserData();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleProfileClick = () => {
    push(`/person/${userData?.slug}`);
    dispatch(setIsNavbarMenuOpen(false));
  };

  const handleEditProfileClick = () => {
    push('/my-profile/edit');
    dispatch(setIsNavbarMenuOpen(false));
  };

  return (
    <>
      <MobileMenuProfileWrapper>
        <PersonAvatar
          avatar={userData?.profile_image}
          name={userData?.first_name}
          lastName={userData?.last_name}
          size={36}
          extraStyle={{ border: '2px solid rgba(207, 220, 236, 0.5)' }}
        />
        <MobileMenuProfileInformationContainer>
          <MobileMenuProfileInformationName>
            {userData?.first_name} {userData?.last_name}
          </MobileMenuProfileInformationName>
          <MobileMenuProfileInformationCompany>
            {userData?.kyu_company?.name}
          </MobileMenuProfileInformationCompany>
        </MobileMenuProfileInformationContainer>
        <MobileMenuProfileRightContainer>
          <MobileMenuProfileRightIconContainer onClick={handleEditProfileClick}>
            <EditPencilIcon width={24} height={24} fill="var(--color-primary)" />
          </MobileMenuProfileRightIconContainer>
          <MobileMenuProfileRightIconContainer onClick={handleProfileClick}>
            <EyeIcon width={24} height={24} fill="var(--color-primary)" />
          </MobileMenuProfileRightIconContainer>
        </MobileMenuProfileRightContainer>
      </MobileMenuProfileWrapper>
    </>
  );
}
