import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDraggable } from 'react-use-draggable-scroll';

import StoryCard from 'app/components/CommonStyled/StoryCard';
import {
  HomeSpotlightsContainer,
  HomeSpotlightsSkeletonCard,
} from 'app/components/Home/HomeSpotlights/styled';
import useHomeSpotlights from 'app/components/Home/HomeSpotlights/useHomeSpotlights';
import { BellIcon } from 'app/components/shared/icons';
import useOnClickOutside from 'hooks/click-outside';
import {
  isSpotlightOpenSelector,
  setIsSpotlightOpen,
  setSpotlights,
  spotlightsSelector,
} from 'store/reducers/navbar-reducer';

import {
  NavbarSpotlightCTADescription,
  NavbarSpotlightCTAIconBeacon,
  NavbarSpotlightCTAIconContainer,
  NavbarSpotlightCTATitle,
  NavbarSpotlightCTATitleContainer,
  NavbarSpotlightCTAWrapper,
  NavbarSpotlightWrapper,
} from './styled';

export default function NavbarSpotlight() {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const spotlights = useSelector(spotlightsSelector);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true,
  });

  useOnClickOutside(wrapperRef, (e) => {
    const button = document.querySelector('#nav-spotlight-cta');
    if (button && button.contains(e.target as Node)) return;

    dispatch(setIsSpotlightOpen(false));
  });

  return (
    <NavbarSpotlightWrapper ref={wrapperRef}>
      <HomeSpotlightsContainer ref={ref} {...events} leftPadding={0}>
        {spotlights.length > 0
          ? spotlights.map((card) => <StoryCard key={card.uuid} card={card} />)
          : [...Array(5)].map((_, index) => <HomeSpotlightsSkeletonCard key={index} />)}
      </HomeSpotlightsContainer>
    </NavbarSpotlightWrapper>
  );
}

NavbarSpotlight.CTA = function NavbarSpotlightCTAComponent() {
  const dispatch = useDispatch();
  const { isLoading, data } = useHomeSpotlights();
  const isSpotlightOpen = useSelector(isSpotlightOpenSelector);

  useEffect(() => {
    if (data) {
      dispatch(setSpotlights(data.items));
    }
  }, [data]);

  const handleCTAClick = () => {
    dispatch(setIsSpotlightOpen(!isSpotlightOpen));
  };

  return (
    <NavbarSpotlightCTAWrapper onClick={handleCTAClick} id="nav-spotlight-cta">
      <NavbarSpotlightCTAIconContainer>
        <NavbarSpotlightCTAIconBeacon />
        <BellIcon />
      </NavbarSpotlightCTAIconContainer>
      <NavbarSpotlightCTATitleContainer>
        <NavbarSpotlightCTATitle>Spotlight</NavbarSpotlightCTATitle>
        {!isLoading && data && data.description ? (
          <NavbarSpotlightCTADescription>{data.description}</NavbarSpotlightCTADescription>
        ) : null}
      </NavbarSpotlightCTATitleContainer>
    </NavbarSpotlightCTAWrapper>
  );
};
