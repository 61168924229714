import styled from "styled-components/macro";
import mq from "styles/media";

const handlePillColor = (type) => {
  switch (type) {
    case "passions":
      return `
        --color-background-pill: #FBAFA1;
      `;
    case "kyu_skills":
      return `
        --color-background-pill: #FFA366;
      `;
    default:
      return `
        --color-background-pill: #E5F1FF;
    `;
  }
};

interface ISelectWrapper {
  type?: string;
}

export const SelectWrapper = styled.div<ISelectWrapper>`
  margin-top: 30px;
  ${({ type }) =>
    type &&
    `
    ${handlePillColor(type)};
  `}
`;

interface ISelect {
  hideOptions?: boolean;
}

export const QuestionText = styled.h4<ISelect>`
  font-size: 18px;
  margin-bottom: 28px;
  line-height: 130%;
  ${({ hideOptions }) =>
    hideOptions &&
    `
    text-align: left;
    color: #a5a6a8;
  `}
  ${mq.minSm} {
    font-size: 22px;
    margin-bottom: 22px;
    ${({ hideOptions }) =>
      hideOptions &&
      `
      font-size: 15px;
      margin-bottom: 18px;
    `}
  }
`;

export const CustomSelectWrapper = styled.div`
  position: relative;
`;

interface IOptionsWrapper {
  isProfile?: boolean;
}

export const OptionsWrapper = styled.div<IOptionsWrapper>`
  max-height: 220px;
  overflow-y: auto;
  padding: 17px 0px 13px;
  ${({ isProfile }) =>
    isProfile &&
    `
    max-height: 180px;
  `}
`;

interface IOption {
  isSelected?: boolean;
  isFocus?: boolean;
  tabIndex?: any;
}

export const Option = styled.button<IOption>`
  text-align: left;
  font-size: 15px;
  padding: 7px 16px 7px 25px;
  cursor: pointer;
  display: block;
  width: 100%;
  color: var(--color-primary);
  background: transparent;
  ${({ isSelected }) =>
    isSelected &&
    `
    display: none;
  `}
  ${({ isFocus }) =>
    isFocus &&
    `
    text-decoration: underline;
    font-weight: 700;
  `}
  &:hover {
    text-decoration: underline;
    font-weight: 700;
  }
`;

export const OptionLoading = styled.div`
  text-align: center;
  font-size: 15px;
  padding: 9px 16px 9px 16px;
  cursor: pointer;
  display: block;
  width: 100%;
  color: var(--color-primary);
  background: transparent;
`;

export const OptionCreate = styled.button<IOption>`
  text-align: center;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: var(--color-primary);
  justify-content: space-between;
  padding: 7px 16px 7px 25px;
  cursor: pointer;
  width: 100%;
  color: #bfbfbf;
  background: transparent;
`;

export const CustomSelect = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
  top: calc(100% + 1px);
  box-sizing: border-box;
  background: rgb(242, 242, 242);
`;

interface IInputWrapper {
  isOpen?: boolean;
  enabled?: boolean;
  isSingleSearch?: boolean;
}

export const InputWrapper = styled.div<IInputWrapper>`
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  transition: all 0.3s ease;
  box-shadow: 0px 1.5px 0px #5d6070;
  padding-bottom: 9px;
  input {
    color: #fff;
    padding-top: 18px;
    padding-bottom: 10px;
  }
  ${({ isOpen }) =>
    isOpen &&
    `
    box-shadow: 0px 1.5px 0px #5d6070;
  `}
  ${({ isSingleSearch }) =>
    isSingleSearch &&
    `
    box-shadow: none;
    padding: 9px 9px 9px 25px;
    flex-wrap: nowrap;
    background: #F1F4F8;
    border-radius: 34px 34px 34px 34px;
    ${mq.minSm} {
      padding: 12px 12px 12px 35px; 
      width: 570px;
    }
    input {
      flex: 1;
      min-width: 0;
      padding-top: 10px;
      padding-right: 10px;
      color: var(--color-text);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  `}
  ${({ isSingleSearch, isOpen, enabled }) =>
    isSingleSearch &&
    isOpen &&
    !enabled &&
    `
    border-radius: 34px 34px 0 0;
  `}
  ${({ isSingleSearch, enabled }) =>
    isSingleSearch &&
    enabled &&
    `
    input {
      padding-right: 36px;
    }
  `}
  ${mq.minSm} {
    padding-bottom: 13px;
  }
`;

interface IPillItem {
  isNew?: boolean;
  sectionId?: string;
  pillColor?: string;
}

const handleNewItemBackground = (sectionId) => {
  switch (sectionId) {
    case "01":
      return `
        background-color: #FFDD88;
      `;
    case "02":
      return `
        background-color: #C3E8E9;
      `;
    default:
      return `
        background-color: #FFDD88;
    `;
  }
};

export const ButtonRemoveOption = styled.button`
  background: rgba(217, 217, 217, 0.5);
  border-radius: 100%;
  height: 18px;
  position: absolute;
  right: 62px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
`;

export const PillItem = styled.div<IPillItem>`
  display: flex;
  height: 36px;
  margin: 4px 12px 4px 0;
  align-items: center;
  border-radius: 50px;
  padding: 0 17px 0 17px;
  background: var(--color-${({ pillColor }) => pillColor}, var(--color-background-pill));
  ${({ isNew, sectionId }) =>
    isNew &&
    `
    ${handleNewItemBackground(sectionId)};
  `}
  .react-select__multi-value__label {
    font-size: 13px;
    padding: 0;
    color: var(--color-primary);
    margin-right: 12px;
    ${mq.minSm} {
      font-size: 15px;
    }
  }
  ${mq.minSm} {
    height: 43px;
  }
  button {
    svg {
      path {
        stroke: var(--color-primary);
      }
    }
  }
`;

interface SearchBlockProps {
  isSingleSearch?: boolean;
}

export const SearchBlock = styled.div<SearchBlockProps>`
  background: rgb(246, 246, 246);
  left: 0;
  max-height: 300px;
  overflow: auto;
  padding: 20px 28px;
  position: absolute;
  top: calc(100% - 1px);
  width: 100%;
  z-index: 99;
  ${({ isSingleSearch }) =>
    isSingleSearch &&
    `
    background: #FFF;
    max-height: 44vh;
    box-shadow: 2px 2px 15px -4px rgba(16, 77, 91, 0.25);
    border-radius: 0 0 34px 34px;
  `}
`;

export const SearchText = styled.p`
  color: #bcbcbe;
  font-size: 18px;
  margin-bottom: 32px;
`;

export const InputSearchWrapper = styled.div`
  margin-top: 50px;
  position: relative;
  input {
    width: 100%;
    font-size: 32px;
    padding-bottom: 15px;
    border-bottom: 2px solid #bcbcbe;
    &::placeholder {
      font-size: 16px;
      color: #bcbcbe;
    }
  }
`;

interface ButtonSearchProps {
  enabled?: boolean;
}

export const ButtonSearch = styled.button<ButtonSearchProps>`
  height: 44px;
  width: 44px;
  border-radius: 100px;
  background: #adadad;
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px;
  text-align: left;
  flex-shrink: 0;
  transition: all 0.3s ease;
  p {
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    right: 14px;
    top: 14px;
    letter-spacing: -0.8px;
    position: absolute;
    transition-delay: 0.3s;
    transition: all 0.3s ease;
  }
  > svg {
    width: 23px;
    height: 23px;
  }
  ${({ enabled }) =>
    enabled &&
    `
    background: #171C33;
  `}
`;

export const RightElementWrapper = styled.div`
  position: absolute;
  right: 0px;
  bottom: 24px;
`