import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { User } from 'interfaces';
import { checkTokenStatus, getUserData } from 'services/app';
import { clearToken } from 'services/storage';
import {
  isLoggedInSelector,
  setExpirationTime,
  setIsLoggedIn,
  setToken,
  setUserData,
  userTokenSelector,
} from 'store/reducers/user-reducer';

import useUserData from './useUserData';

interface IRes {
  ok: boolean;
  data: User;
}

export default function useIsLoggedIn() {
  const dispatch = useDispatch();
  const token: string = useSelector(userTokenSelector);
  const isLoggedIn: boolean = useSelector(isLoggedInSelector);
  const userData = useUserData();

  const dispatchUserData = (userData: User) => dispatch(setUserData(userData));

  const dispatchUnauthorizedToken = () => {
    dispatch(setToken(null));
    dispatch(setIsLoggedIn(false));
  };

  const dispatchToken = (tokenExpiration: string) => {
    dispatch(setExpirationTime(tokenExpiration));
    dispatch(setIsLoggedIn(true));
  };

  useEffect(() => {
    if (!token) {
      dispatch(setIsLoggedIn(false));
    } else {
      (async () => {
        if (!userData) {
          const userDataRes = (await getUserData()) as IRes;
          if (userDataRes.ok) {
            dispatchUserData(userDataRes.data);
          }
        }
      })();

      (async () => {
        const tokenStatus = await checkTokenStatus(token);
        if (tokenStatus.status === 401) {
          clearToken();
          dispatchUnauthorizedToken();
        } else {
          dispatchToken(tokenStatus.data.expires_in);
        }
      })();
    }
  }, [token]);

  return { isLoggedIn };
}
