import React from 'react';

import { CommunityIcon, RightArrow } from 'app/components/shared/icons';

import { PublicationDefaultButton } from './styled';

interface PublicationButtonProps {
  type?: string;
  onClick?: () => void;
}

export default function PublicationButton({ type, onClick }: PublicationButtonProps) {
  const typeToButton = new Map<string, JSX.Element>([
    ['communities', <CommunityButton onClick={onClick} />],
  ]);

  if (type && typeToButton.has(type)) return typeToButton.get(type);

  return <DefaultButton onClick={onClick} />;
}

interface ButtonProps {
  onClick?: () => void;
}

function DefaultButton({ onClick }: ButtonProps) {
  return (
    <PublicationDefaultButton onClick={onClick}>
      <span>LEARN MORE</span>
      <RightArrow width={16} height={16} fill={'#000000'} />
    </PublicationDefaultButton>
  );
}

function CommunityButton({ onClick }: ButtonProps) {
  return (
    <PublicationDefaultButton onClick={onClick}>
      <span>EXPLORE COMMUNITY</span>
      <CommunityIcon width={16} height={16} fill={'#000000'} />
    </PublicationDefaultButton>
  );
}
