import React, { useState, useEffect } from "react";
import { DownArrow } from "app/components/shared/icons";

import {
  DropdownWrapper,
  DropdownButton,
  DropdownContentWrapper,
  DropdownContent,
  DropdownItem,
} from "./styled";

import { getKyuCompanies } from "services/app";

function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

const Dropdown = ({ onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [valuesSelected, setValuesSelected] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchKyuCompanies = async () => {
      const kyuCompaniesDataFetch = await getKyuCompanies();
      if (kyuCompaniesDataFetch.ok) {
        setCompanies(kyuCompaniesDataFetch.data.companies);
      }
    };

    fetchKyuCompanies();
  }, []);

  useEffect(() => {
    onChange(valuesSelected);
  }, [valuesSelected]);

  const onChangeOption = (option) => {
    if (option == "all") {
      if (valuesSelected.length > 0) {
        setValuesSelected([]);
      }
      return false;
    }

    let selected = valuesSelected;
    // instead of using indexOf, we can use findIndex to look through array of objects
    let find = selected.findIndex((item) => item.uuid === option.uuid);

    if (find > -1) {
      const valuesToSave = valuesSelected.filter(
        (item) => item.uuid != option.uuid
      );
      setValuesSelected(valuesToSave);
    } else {
      setValuesSelected([...valuesSelected, option]);
    }
  };

  return (
    <DropdownWrapper>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        Filter by company <DownArrow />
      </DropdownButton>
      {isOpen && (
        <DropdownContentWrapper>
          <DropdownContent>
            <DropdownItem>
              <label>
                <span>All companies</span>
                <input
                  onChange={() => onChangeOption("all")}
                  checked={valuesSelected.length == 0}
                  value="all"
                  type="checkbox"
                />
                <div></div>
              </label>
            </DropdownItem>
            {companies.map((company) => (
              <DropdownItem key={company.uuid}>
                <label>
                  <span>{company.name}</span>
                  <input
                    onChange={() => onChangeOption(company)}
                    checked={valuesSelected.some((val) =>
                      shallowEqual(val, company)
                    )}
                    value={company.uuid}
                    type="checkbox"
                  />
                  <div></div>
                </label>
              </DropdownItem>
            ))}
          </DropdownContent>
        </DropdownContentWrapper>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
