import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsWayfindingWrapper = styled.section`
  max-width: 1000px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 80px;

  background: rgba(255, 255, 255, 0.4);
  border-radius: 20px;

  padding: 18px 15px 72px 15px;

  ${BREAKPOINTS.tablet} {
    gap: 32px;
    padding: 0px;
    overflow-x: hidden;
  }
`;

export const AboutKyuOsWayfindingTitle = styled.h1`
  height: 83px;

  font-weight: 400;
  font-size: 64px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    height: unset;
    font-size: 38px;
    line-height: 42px;
  }

  color: var(--color-text);
`;

export const AboutKyuOsWayfindingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 121px;
`;
