import React, { useEffect, useRef, useState } from 'react';

import { ChevronUpDownIcon } from 'app/components/shared/icons';
import CheckIcon from 'app/components/shared/icons/check-icon';
import { Field } from 'formik';
import useOnClickOutside from 'hooks/click-outside';

import { ProfileEditInputProps } from '..';
import {
  ProfileEditInputSelectAddOption,
  ProfileEditInputSelectContainer,
  ProfileEditInputSelectError,
  ProfileEditInputSelectErrorContainer,
  ProfileEditInputSelectIconContainer,
  ProfileEditInputSelectLimit,
  ProfileEditInputSelectOptionContainer,
  ProfileEditInputSelectOptionContainerText,
  ProfileEditInputSelectOptionsWrapper,
  ProfileEditInputSelectWrapper,
} from './styled';

const defaultSettings = {
  allowMultiple: false,
  allowCreate: false,
};

export default function ProfileEditInputSelect(props: ProfileEditInputProps) {
  const {
    name,
    values,
    textLimit,
    options,
    settings = defaultSettings,
    onOptionPicked,
    onNewOptionPicked,
    onOptionsChanged,
    onCurrenOptionDeleted,
    keepValueOnChange,
    overrideOnOptionPicked,
  } = props;
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [optionsCreated, setOptionsCreated] = useState<any[]>([]);
  const [currentFilter, setCurrentFilter] = useState<string>('');
  const [currentOption, setCurrentOption] = useState<any>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useOnClickOutside(inputWrapperRef, () => setShowOptions(false));

  useEffect(() => {
    if (onOptionsChanged) {
      onOptionsChanged([
        ...options.filter((opt) => values[name]?.includes(opt.value)),
        ...optionsCreated,
      ]);
      setOptionsCreated([]);
    }
  }, [values[name], options]);

  useEffect(() => {
    if (
      !currentOption &&
      currentFilter.length === 0 &&
      document.activeElement === inputRef.current
    ) {
      setShowOptions(true);
    }
  }, [currentFilter, currentOption]);

  const filterData = (option: { value: any; label: string }) => {
    return option.label.toLowerCase().includes(currentFilter.toLowerCase());
  };

  const addCreateOption = () => {
    if (
      settings.allowCreate &&
      currentFilter &&
      !options.find((opt) => opt.label.toLowerCase() === currentFilter.toLowerCase())
    ) {
      const newValue = { value: currentFilter, label: currentFilter, isCreated: true };
      return [newValue];
    }
    return [];
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === '' && currentOption) {
      setCurrentOption(null);
      onCurrenOptionDeleted?.();
    }
    setCurrentFilter(e.currentTarget.value);
  };

  const resetInput = () => {
    if (!keepValueOnChange) {
      setCurrentFilter('');
    }
    setShowOptions(false);
  };

  const handleOptionPicked = (option) => {
    if (overrideOnOptionPicked) {
      setCurrentOption(option);
      setShowOptions(false);
      return overrideOnOptionPicked(option);
    }
    if (option.isCreated && onNewOptionPicked) {
      setCurrentOption(option);
      onNewOptionPicked(option);
      setShowOptions(false);
      return;
    }

    if (onOptionPicked) {
      onOptionPicked(option);
    }

    const index = values[name].findIndex((opt) => opt === option.value);

    if (index !== -1) {
      const newOptions = values[name].filter((opt) => opt !== option.value);
      if (option.isCreated) {
        setOptionsCreated(optionsCreated.filter((opt) => opt.value !== option.value));
      }
      props.setFieldValue(name, newOptions);
    } else {
      if (option.isCreated) {
        setOptionsCreated([...optionsCreated, option]);
      }
      props.setFieldValue(name, [...values[name], option.value]);
    }

    props.setFieldTouched(name, true);

    resetInput();
  };

  const handleOnClick = () => {
    if (!currentOption) {
      setShowOptions(true);
    }
  };

  return (
    <ProfileEditInputSelectWrapper ref={inputWrapperRef}>
      <Field type="hidden" />
      <ProfileEditInputSelectContainer
        ref={inputRef}
        value={currentFilter}
        onChange={handleChange}
        placeholder={props.placeholder}
        isValid={!props.getFieldMeta(name).error}
        onClick={handleOnClick}
        disabled={props.disabled}
        className={`select-input-${name}`}
      />
      {showOptions && (
        <ProfileEditInputSelectOptionsWrapper>
          {[...addCreateOption(), ...options].filter(filterData).map((option, index) => (
            <ProfileEditInputSelectOptionContainer
              key={option.value}
              onClick={() => handleOptionPicked(option)}
            >
              <ProfileEditInputSelectOption
                option={option}
                currentFilter={currentFilter}
                picked={props.values[name]?.includes(option.value)}
              />
            </ProfileEditInputSelectOptionContainer>
          ))}
        </ProfileEditInputSelectOptionsWrapper>
      )}
      {textLimit && !props.getFieldMeta(name).error ? (
        <ProfileEditInputSelectLimit>
          {values[name]?.length}/{textLimit}
        </ProfileEditInputSelectLimit>
      ) : null}
      {props.getFieldMeta(name).error ? (
        <ProfileEditInputSelectErrorContainer>
          <ProfileEditInputSelectError>
            {props.getFieldMeta(name).error}
          </ProfileEditInputSelectError>
        </ProfileEditInputSelectErrorContainer>
      ) : null}
      <ProfileEditInputSelectIconContainer>
        <ChevronUpDownIcon width={24} height={24} fill={'#000000'} />
      </ProfileEditInputSelectIconContainer>
    </ProfileEditInputSelectWrapper>
  );
}

function ProfileEditInputSelectOption({
  option,
  currentFilter,
  picked,
}: {
  option: { value: any; label: string; isCreated?: boolean };
  currentFilter: string;
  picked: boolean;
}) {
  const resultIndex = option.label.toLowerCase().indexOf(currentFilter.toLowerCase());
  const searchLength = currentFilter.length;
  const firstResultPart = option.label.substring(0, resultIndex);
  const resultPart = option.label.substring(resultIndex, resultIndex + searchLength);
  const lastResultPart = option.label.substring(resultIndex + searchLength);

  return (
    <>
      <ProfileEditInputSelectOptionContainerText>
        {firstResultPart}
      </ProfileEditInputSelectOptionContainerText>
      <ProfileEditInputSelectOptionContainerText
        highlighted
        isCreated={option.isCreated && !picked}
      >
        {resultPart}
      </ProfileEditInputSelectOptionContainerText>
      <ProfileEditInputSelectOptionContainerText>
        {lastResultPart}
      </ProfileEditInputSelectOptionContainerText>
      {picked ? (
        <CheckIcon
          width={24}
          height={24}
          background={'transparent'}
          fill={'var(--color-data-people-background-hover)'}
        />
      ) : null}
      {option.isCreated && !picked ? (
        <ProfileEditInputSelectAddOption>+ADD</ProfileEditInputSelectAddOption>
      ) : null}
    </>
  );
}
