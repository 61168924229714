import { useEffect, useState } from 'react';

import useReactionStorage from './useReactionStorage';
import useUserData from './useUserData';

export default function useIsLiked(slug: string, uuids?: string[]): boolean {
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const { storage } = useReactionStorage();
  const userData = useUserData();

  useEffect(() => {
    if (userData && storage && storage.length > 0 && storage.includes(slug)) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
  }, [userData, uuids, storage]);

  return isLiked;
}
