import React, { useCallback, useEffect, useRef, useState } from 'react';

import { DeleteIcon } from 'app/components/shared/icons';
import AlarmClock from 'app/components/shared/icons/alarm-clock';
import useOnClickOutside from 'hooks/click-outside';
import useIsMobile from 'hooks/useIsMobile';

import {
  ProfileEditDataTypePillDeleteContainer,
  ProfileEditDataTypePillDeleteText,
  ProfileEditDataTypePillName,
  ProfileEditDataTypePillPendingReviewContainer,
  ProfileEditDataTypePillPendingReviewText,
  ProfileEditDataTypePillPeopleCountText,
  ProfileEditDataTypePillWrapper,
} from './styled';

interface ProfileEditDataTypePillProps {
  label: string;
  approved: boolean;
  type?: 'selected' | 'unselected' | 'disabled';
  peopleCount?: number;
  showDeleteOnHover?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}

export default function ProfileEditDataTypePill({
  label,
  approved,
  peopleCount,
  type = 'unselected',
  onClick,
  onDelete,
  showDeleteOnHover = false,
}: ProfileEditDataTypePillProps) {
  const isMobile = useIsMobile();
  const [clickedBefore, setClickedBefore] = useState<boolean>(false);
  const pillRef = useCallback((node: HTMLDivElement) => {
    if (node !== null && showDeleteOnHover) {
      node.style.width = `${node.getBoundingClientRect().width}px`;
      node.style.height = `${node.getBoundingClientRect().height}px`;
    }
  }, []);
  const deleteRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(deleteRef, () => setClickedBefore(false));

  const handleClick = () => {
    if (onClick) {
      return onClick();
    }
  };

  const handleDeleteClick = () => {
    if (isMobile && !clickedBefore) {
      setClickedBefore(true);
      return;
    }

    if (onDelete) {
      return onDelete();
    }
  };

  return (
    <ProfileEditDataTypePillWrapper
      ref={pillRef}
      type={type}
      showDeleteOnHover={showDeleteOnHover}
      onClick={handleClick}
    >
      <ProfileEditDataTypePillName>{label}</ProfileEditDataTypePillName>
      <ProfileEditDataTypePillDeleteContainer
        className="delete"
        ref={deleteRef}
        onClick={handleDeleteClick}
      >
        <DeleteIcon height={14} width={14} fill={'var(--color-error)'} />
        <ProfileEditDataTypePillDeleteText>DELETE</ProfileEditDataTypePillDeleteText>
      </ProfileEditDataTypePillDeleteContainer>
      {!approved && type === 'selected' && (
        <ProfileEditDataTypePillPendingReviewContainer className="pending-review">
          <AlarmClock width={18} height={18} fill="var(--color-data-community-foreground)" />
          <ProfileEditDataTypePillPendingReviewText>
            Pending Review
          </ProfileEditDataTypePillPendingReviewText>
        </ProfileEditDataTypePillPendingReviewContainer>
      )}
      {approved && peopleCount && type === 'selected' ? (
        <ProfileEditDataTypePillPeopleCountText className="people-count">
          {peopleCount !== 1 ? `${peopleCount} people` : '1 person'}
        </ProfileEditDataTypePillPeopleCountText>
      ) : null}
    </ProfileEditDataTypePillWrapper>
  );
}
