import React from 'react';

export default function Inspo({
  width = 42,
  height = 37,
  fill = '#F5FAFF',
  stroke = 'var(--color-primary)',
}) {
  return (
    <svg
      className="inspo-close"
      width={width}
      height={height}
      viewBox="0 0 42 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.06641"
        y="6.45776"
        width="32"
        height="23"
        rx="3"
        fill={fill}
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M11 14H31"
        stroke={stroke}
        strokeOpacity="0.3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 19H23"
        stroke={stroke}
        strokeOpacity="0.3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
