import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import {
  ClientIcon,
  CompanyIcon,
  IndustryIcon,
  ProjectIcon,
} from 'app/components/DetailsPages/shared/CommonGround/Icons';
import PersonIcon from 'app/components/shared/icons/person-icon';
import dayjs from 'dayjs';
import { singularOrPlural } from 'utils';

import GlobalDataTypeDrawer from '../GlobalDataType/GlobalDataTypeDrawer';
import {
  GlobalPassionDrawerCounter,
  GlobalPassionDrawerCounterContainer,
  GlobalPassionDrawerCounterIconContainer,
  GlobalPassionDrawerCountersWrapper,
  GlobalPassionDrawerCounterText,
} from '../GlobalPassion/styled';
import useClientDrawerData, { ClientDrawerResponse } from '../hooks/useClientDrawerData';
import {
  GlobalClientDrawerHighlightText,
  GlobalClientDrawerHighlightTextContainer,
} from './styled';

interface GlobalClientDrawerProps {
  slug: string;
  comingFromList?: boolean;
  backToListAction?: () => void;
}

export default function GlobalClientDrawer({
  slug,
  comingFromList = false,
  backToListAction = () => {},
}: GlobalClientDrawerProps) {
  const query = useClientDrawerData(slug);
  const { isLoading, data } = query;

  const { push } = useHistory();

  const projectText = data?.highlight.projects_count !== 1 ? 'projects' : 'project';
  const personText = data?.highlight.people_count !== 1 ? 'people' : 'person';

  return (
    <GlobalDataTypeDrawer
      slug={slug}
      query={query}
      isLoading={isLoading}
      background={
        'linear-gradient(180deg, rgba(255, 255, 255, 0.3) 17.49%, rgba(150, 215, 217, 0.3) 100%), #FFFFFF;'
      }
      dataType={'client'}
      title={data?.name || ''}
      includesCurrentPerson={data?.includes_current_person || false}
      icon={<ClientIcon fill={'#104D5B'} />}
      catchphraseHighlightColor={'#104D5B'}
      iconBackground={'rgba(16, 77, 91, 0.3)'}
      buttonOnClick={() => push(`/client/${data?.slug}`)}
      buttonBackgroundColor={'#104D5B'}
      peopleAtCompany={null}
      comingFromList={comingFromList}
      backToListAction={() => backToListAction()}
    >
      {data?.highlight && (
        <>
          <GlobalClientDrawerHighlight data={data} />
          <GlobalPassionDrawerCountersWrapper>
            {data?.counters.projects_count ? (
              <GlobalPassionDrawerCounterContainer>
                <GlobalPassionDrawerCounterIconContainer>
                  <ProjectIcon fill={'#392B62'} width={24} height={24} />
                  <GlobalPassionDrawerCounter>
                    {data.counters.projects_count}
                  </GlobalPassionDrawerCounter>
                </GlobalPassionDrawerCounterIconContainer>
                <GlobalPassionDrawerCounterText>{projectText}</GlobalPassionDrawerCounterText>
              </GlobalPassionDrawerCounterContainer>
            ) : null}
            {data?.counters.industries_count ? (
              <GlobalPassionDrawerCounterContainer>
                <GlobalPassionDrawerCounterIconContainer>
                  <IndustryIcon width={24} height={24} fill={'#8DA76C'} />
                  <GlobalPassionDrawerCounter>
                    {data.counters.industries_count}
                  </GlobalPassionDrawerCounter>
                </GlobalPassionDrawerCounterIconContainer>
                <GlobalPassionDrawerCounterText>
                  {data.counters.industries_count !== 1 ? 'industries' : 'industry'}
                </GlobalPassionDrawerCounterText>
              </GlobalPassionDrawerCounterContainer>
            ) : data?.counters.companies_count ? (
              <GlobalPassionDrawerCounterContainer>
                <GlobalPassionDrawerCounterIconContainer>
                  <CompanyIcon width={24} height={24} fill={'#5478A1'} />
                  <GlobalPassionDrawerCounter>
                    {data.counters.companies_count}
                  </GlobalPassionDrawerCounter>
                </GlobalPassionDrawerCounterIconContainer>
                <GlobalPassionDrawerCounterText>
                  {data.counters.companies_count !== 1 ? 'companies' : 'company'}
                </GlobalPassionDrawerCounterText>
              </GlobalPassionDrawerCounterContainer>
            ) : null}
            {data?.counters.people_count ? (
              <GlobalPassionDrawerCounterContainer>
                <GlobalPassionDrawerCounterIconContainer>
                  <PersonIcon width={24} height={24} fill={'#FFC229'} />
                  <GlobalPassionDrawerCounter>
                    {data.counters.people_count}
                  </GlobalPassionDrawerCounter>
                </GlobalPassionDrawerCounterIconContainer>
                <GlobalPassionDrawerCounterText>{personText}</GlobalPassionDrawerCounterText>
              </GlobalPassionDrawerCounterContainer>
            ) : null}
          </GlobalPassionDrawerCountersWrapper>
        </>
      )}
    </GlobalDataTypeDrawer>
  );
}

interface GlobalClientDrawerHighlightProps {
  data: ClientDrawerResponse;
}

function GlobalClientDrawerHighlight({ data }: Readonly<GlobalClientDrawerHighlightProps>) {
  const hasCurrentPerson = useMemo(() => data?.includes_current_person, [data]);
  const onlyCurrentPerson = useMemo(
    () => data?.highlight.people_count === 0 && hasCurrentPerson,
    [data, hasCurrentPerson]
  );
  const noPeople = useMemo(() => data?.highlight.people_count === 0, [data]);

  const shouldShowLatestProjectDate = useMemo(() => {
    return (
      data?.highlight.last_finished_project?.end_date &&
      data?.highlight.active_companies.length === 0
    );
  }, [data]);

  const shouldShowActiveProjects = useMemo(() => {
    return data?.highlight.active_companies.length > 0 && data?.highlight.active_projects_count > 0;
  }, [data]);

  if (data?.highlight.projects_count === 0 && data?.highlight.people_count > 0) {
    return (
      <GlobalClientDrawerHighlightTextContainer>
        <GlobalClientDrawerHighlightText>
          <span className="people">
            {data?.highlight.people_count}{' '}
            {singularOrPlural(data?.highlight.people_count, 'person', 'people')}
          </span>{' '}
          {singularOrPlural(
            data?.highlight.people_count,
            'has added work for',
            'have added that they worked for'
          )}{' '}
          {data?.name} as prior experience.
        </GlobalClientDrawerHighlightText>
      </GlobalClientDrawerHighlightTextContainer>
    );
  }

  return (
    <GlobalClientDrawerHighlightTextContainer>
      <GlobalClientDrawerHighlightText>
        {hasCurrentPerson && data?.highlight?.person_projects_count ? (
          <span>
            You have worked on {data.highlight.person_projects_count}{' '}
            {data.highlight.person_projects_count !== 1 ? 'projects' : 'project'} with them,
            connecting you to their{' '}
            <span className="industries">
              {data.highlight.industries_count}{' '}
              {singularOrPlural(data.highlight.industries_count, 'industry', 'industries')}
            </span>{' '}
            {onlyCurrentPerson ? null : (
              <>
                and a total of{' '}
                <span className="people">
                  {data.highlight.people_count}{' '}
                  {singularOrPlural(data.highlight.people_count, 'person', 'people')}
                </span>
              </>
            )}
            .
          </span>
        ) : data?.includes_current_person ? (
          <span>
            You have worked with them prior to kyu, connecting you to their{' '}
            <span className="industries">
              {data?.highlight.industries_count}{' '}
              {singularOrPlural(data.highlight.industries_count, 'industry', 'industries')}
            </span>{' '}
            {onlyCurrentPerson ? null : (
              <>
                and a total of{' '}
                <span className="people">
                  {data?.highlight.people_count}{' '}
                  {singularOrPlural(data.highlight.people_count, 'person', 'people')}
                </span>
              </>
            )}
            .
          </span>
        ) : (
          <>
            {noPeople ? null : (
              <>
                {data?.highlight.people_count === 1 ? (
                  <>
                    {data?.highlight.companies_count === 1 && (
                      <>
                        <span className="person">{data?.highlight.person_sample.name}</span>
                        {' from '}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <span className="people">
                      {data?.highlight.people_count}{' '}
                      {singularOrPlural(data.highlight.people_count, 'person', 'people')}
                    </span>
                    {' from '}
                  </>
                )}{' '}
              </>
            )}
            {data?.highlight.companies_count === 1 ? (
              <>
                <span className="company">{data?.highlight.company_sample.name}</span>
                {singularOrPlural(data?.highlight.people_count, ' has ', ' have ')}
              </>
            ) : (
              <span className="company">
                {data?.highlight.companies_count}
                {' companies have '}
              </span>
            )}
            worked with {data?.name} on{' '}
            {data?.highlight.projects_count === 1 && data?.highlight.project_sample ? (
              <>
                <span className="project">{data?.highlight.project_sample.name}</span>.
              </>
            ) : (
              <>
                <span className="project">
                  {data?.highlight.projects_count}{' '}
                  {singularOrPlural(data?.highlight.projects_count, 'project', 'projects')}
                </span>
                .
              </>
            )}
          </>
        )}
      </GlobalClientDrawerHighlightText>
      {shouldShowLatestProjectDate ? (
        <GlobalClientDrawerHighlightText>
          Our latest project with them was in{' '}
          {dayjs(data?.highlight.last_finished_project.end_date).format('MMMM YYYY')}.
        </GlobalClientDrawerHighlightText>
      ) : null}
      {shouldShowActiveProjects ? (
        <GlobalClientDrawerHighlightText>
          We currently have{' '}
          <span className="project">
            {data?.highlight.active_projects_count} active{' '}
            {singularOrPlural(data?.highlight.active_projects_count, 'project', 'projects')} with
            them
          </span>{' '}
          at{' '}
          <span className="company">
            {data?.highlight.active_companies.length > 1 ? (
              <>
                {data?.highlight.active_companies.length}
                {' member companies'}
              </>
            ) : (
              <>{data?.highlight.active_companies[0].name}</>
            )}
          </span>
          .
        </GlobalClientDrawerHighlightText>
      ) : null}
    </GlobalClientDrawerHighlightTextContainer>
  );
}
