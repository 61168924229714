import React from 'react';

import { CompanyIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { ErrorWrapper } from 'app/pages/styled-components';
import useUserData from 'hooks/useUserData';
import { titleCase, transformSlugToTitle } from 'utils';

import GlobalDataTypeDrawer from '../GlobalDataType/GlobalDataTypeDrawer';
import { GlobalCompanyDrawerHighlightText } from './styled';

interface GlobalCompanyDrawerNoDetailPageProps {
  slug: string;
}

export default function GlobalCompanyDrawerNoDetailPage({
  slug,
}: GlobalCompanyDrawerNoDetailPageProps) {
  const userData = useUserData();
  return (
    <GlobalDataTypeDrawer
      slug={slug}
      background={
        'linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(156, 202, 255, 0.3) 100%), #FFFFFF'
      }
      isLoading={false}
      dataType="kyu company"
      includesCurrentPerson={userData.kyu_company?.slug === slug}
      title={titleCase(transformSlugToTitle(slug))}
      icon={<CompanyIcon fill={'#5478A1'} />}
      catchphraseHighlightColor={'#5478A1'}
      iconBackground={'#9CCAFF'}
      buttonBackgroundColor={'#5478A1'}
      comingFromList={false}
      hideBottomSection
    >
      <GlobalCompanyDrawerHighlightText style={{ flex: 1 }}>
        We're working on getting all the information set up for this company...
      </GlobalCompanyDrawerHighlightText>
    </GlobalDataTypeDrawer>
  );
}
