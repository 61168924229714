import React, { useContext, useState } from 'react';
import { UseQueryResult } from 'react-query';

import { OnboardingContext } from '../OnboardingContext';
import { ICurrentPassion, OnboardingResponse } from '../interfaces';

interface OnboardingControllers {
  currentStep: number;
  previousStep: number | null;
  currentPassion: ICurrentPassion | null;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  goNextStep: () => void;
  goPreviousStep: () => void;
  hideNextButton: () => void;
  hidePreviousButton: () => void;
  showNextButton: () => void;
  showPreviousButton: () => void;
  setCurrentPassion: React.Dispatch<React.SetStateAction<ICurrentPassion | null>>;
  onboardingQuery: UseQueryResult<OnboardingResponse, Error>;
}

export default function useOnboardingControls(): OnboardingControllers {
  const [previousStep, setPreviousStep] = useState<number | null>(null);
  const context = useContext(OnboardingContext);

  const goNextStep = () => {
    setPreviousStep(context.currentStep);
    context.setCurrentStep(context.currentStep + 1);
  };

  const goPreviousStep = () => {
    setPreviousStep(context.currentStep);
    context.setCurrentStep(context.currentStep - 1);
  };

  const hideNextButton = () => {
    return context.setHideControls({ ...context.hideControls, next: true });
  };

  const hidePreviousButton = () => {
    return context.setHideControls({ ...context.hideControls, previous: true });
  };

  const showNextButton = () => {
    return context.setHideControls({ ...context.hideControls, next: false });
  };

  const showPreviousButton = () => {
    return context.setHideControls({ ...context.hideControls, previous: false });
  };

  return {
    currentStep: context.currentStep,
    previousStep,
    currentPassion: context.currentPassion,
    setCurrentStep: context.setCurrentStep,
    goNextStep,
    goPreviousStep,
    hideNextButton,
    hidePreviousButton,
    showNextButton,
    showPreviousButton,
    setCurrentPassion: context.setCurrentPassion,
    onboardingQuery: context.onboardingQuery,
  };
}
