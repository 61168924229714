import { SliceCaseReducers, createSlice, nanoid } from '@reduxjs/toolkit';

export interface DetailPageState {
  isNavbarVisible: boolean;
  isNavbarMoreOpen: boolean;
}

export const slice = createSlice<DetailPageState, SliceCaseReducers<DetailPageState>>({
  name: 'detail-page',
  initialState: {
    isNavbarVisible: true,
    isNavbarMoreOpen: false,
  },
  reducers: {
    setIsNavbarVisible: (state, action: { payload: boolean }) => ({
      ...state,
      isNavbarVisible: action.payload,
    }),
    setIsNavbarMoreOpen: (state, action: { payload: boolean }) => ({
      ...state,
      isNavbarMoreOpen: action.payload,
    }),
  },
});

export const { setIsNavbarVisible, setIsNavbarMoreOpen } = slice.actions;

export const isNavbarVisibleSelector = (state) => state.detailPage.isNavbarVisible;
export const isNavbarMoreOpenSelector = (state) => state.detailPage.isNavbarMoreOpen;

export default slice.reducer;
