import React from 'react';

export default function LookupClose({ width = 24, height = 24, fill = '#ADADAD' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="9" fill="#D9D9D9" fillOpacity="0.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5354 15.5358C15.926 15.1453 15.926 14.5121 15.5354 14.1216L13.4141 12.0003L15.5353 9.87901C15.9259 9.48848 15.9259 8.85532 15.5353 8.46479C15.1448 8.07427 14.5117 8.07427 14.1211 8.46479L11.9999 10.5861L9.87859 8.46477C9.48806 8.07424 8.8549 8.07424 8.46437 8.46477C8.07385 8.85529 8.07385 9.48846 8.46437 9.87898L10.5857 12.0003L8.46428 14.1216C8.07376 14.5122 8.07376 15.1453 8.46428 15.5359C8.8548 15.9264 9.48797 15.9264 9.87849 15.5359L11.9999 13.4145L14.1212 15.5358C14.5118 15.9264 15.1449 15.9264 15.5354 15.5358Z"
        fill={fill}
      />
    </svg>
  );
}
