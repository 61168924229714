import React from 'react';

import useUserData from 'hooks/useUserData';

import PersonAvatar from '../PersonAvatar';

interface UserAvatarProps {
  size: number;
  highlighted?: boolean;
}

export default function UserAvatar({ size, highlighted = true }: UserAvatarProps) {
  const userData = useUserData();

  return (
    <PersonAvatar
      size={size}
      name={userData.first_name}
      lastName={userData.last_name}
      avatar={userData.profile_image}
      highlighted={highlighted}
      disablePropagation
    />
  );
}
