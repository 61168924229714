import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const ProfileEditorDrawerMyRoleWrapper = styled.div`
  padding: 80px 48px 0px 48px;

  & textarea {
    min-height: 272px;
  }

  ${BREAKPOINTS.tablet} {
    padding: 45px 16px;
  }
`;

export const ProfileEditorDrawerMyRoleTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;
