import { AboutKyuOsMapWrapper } from 'app/components/AboutKyuOs/AboutKyuOsMap/styled';
import { StickyNavWrapper } from 'app/components/CommonStyled/StickyNav/styled';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsOnboardingWrapper = styled(motion.div)`
  width: 100%;

  position: fixed;
  bottom: 0px;

  min-height: 100vh;
  overflow: ${({ theme }) => (theme.isAboutKyuOsOpen ? 'auto' : 'hidden')};

  border-radius: 32px 32px 0px 0px;

  z-index: 99999999;

  ${StickyNavWrapper} {
    position: sticky;
    top: 85px;
  }

  ${BREAKPOINTS.tablet} {
    ${({ theme }) =>
      theme.isAboutKyuOsOpen
        ? `
        width: 100%;
      `
        : `
        width: calc(100% - 78px - 65px);
        
        border-radius: 16px 16px 0px 0px;

        left: 78px;
        right: 65px;
      `}
  }

  scrollbar-width: thin;
  &::-webkit-scrollbar-track {
    background-color: none;
  }
`;

export const AboutKyuOsOnboardingContainer = styled.div`
  min-height: 100vh;

  background-image: url('/backgrounds/home_rectangle.webp');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #f7f7f7;

  display: flex;
  flex-direction: column;

  ${AboutKyuOsMapWrapper} {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 44px;
  }
`;

export const AboutKyuOsOnboardingBannerContainer = styled.div`
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;

  margin: 0px !important;

  z-index: 999;
`;

export const AboutKyuOsOnboardingOverlay = styled(motion.div)`
  background: rgba(23, 28, 51, 0.3);
  height: 100svh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999998;
  backdrop-filter: blur(14px);
`;

//BEACON
export const AboutKyuOsOnboardingBeaconWrapper = styled(motion.div)`
  position: fixed;
  bottom: 0px;
  right: 20px;

  transform: translateY(calc(-100% + 45px));

  padding: 12px 45px 26px 16px;

  border-radius: 24px;
  border: 2px solid var(--data-skill-background, #ffa366);
  box-shadow: 0px 0px 100px 40px #ffa366;
  background: #f7f7f7;

  &:before {
    content: '';

    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;

    width: 100%;
    height: 100%;

    backdrop-filter: blur(24px);
    border-radius: 24px;
  }

  z-index: 99999998;
`;

export const AboutKyuOsOnboardingBeaconText = styled.span`
  width: 313px;

  display: block;

  font-weight: 400;
  font-size: 18px;
  line-height: normal;

  color: var(--color-text);

  position: relative;
`;
