import styled from 'styled-components';

export const ProfileEditorDrawerYearsExperienceWrapper = styled.div`
  padding: 80px 36px 0px 36px;
`;

export const ProfileEditorDrawerYearsExperienceCustomInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

export const ProfileEditorDrawerYearsExperienceDropdownWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 10px;
  background: rgba(173, 173, 173, 0.08);
  border-radius: 8px;

  position: relative;

  cursor: pointer;
`;

export const ProfileEditorDrawerYearsExperienceDropdownIconContainer = styled.div`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 16px;
  right: 10px;
`;

interface ProfileEditorDrawerYearsExperienceDropdownTitleProps {
  hasValue: boolean;
}

export const ProfileEditorDrawerYearsExperienceDropdownTitle = styled.span<ProfileEditorDrawerYearsExperienceDropdownTitleProps>`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: ${({ hasValue }) => hasValue ? 'rgba(23, 28, 51, 1)' : 'rgba(23, 28, 51, 0.5)'};
`;

export const ProfileEditorDrawerYearsExperienceDropdownOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: #FFFFFF;
  box-shadow: 5px 0px 20px -10px rgba(50, 63, 93, 0.3);
  border-radius: 8px;

  max-height: 340px;
  width: 100%;

  position: absolute;
  left: 0px;
  top: 58px;

  overflow: scroll;
`;

export const ProfileEditorDrawerYearsExperienceDropdownOptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;

  background: #FFFFFF;
  &:hover {
    background: #F7F7F7;
  }

  cursor: pointer;
  position: relative;
`;

export const ProfileEditorDrawerYearsExperienceDropdownOptionText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`;