import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  fill?: string;
  onClick?: () => void;
}

function LocationIcon({ width = 16, height = 16, fill = 'rgb(0,0,0,1)' }: IIconProps) {
  return (
    <svg
      width={width}
      style={{ minWidth: width }}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.22893 2.89543C5.22912 1.89524 6.58568 1.33333 8.00016 1.33333C9.41465 1.33333 10.7712 1.89524 11.7714 2.89543C12.7716 3.89562 13.3335 5.25218 13.3335 6.66667C13.3335 8.73204 11.9897 10.7362 10.5162 12.2915C9.79309 13.0548 9.06772 13.6796 8.52238 14.1139C8.31942 14.2755 8.14215 14.4101 8.00016 14.5151C7.85818 14.4101 7.68091 14.2755 7.47795 14.1139C6.93261 13.6796 6.20724 13.0548 5.48413 12.2915C4.01065 10.7362 2.66683 8.73204 2.66683 6.66667C2.66683 5.25218 3.22873 3.89562 4.22893 2.89543ZM7.63 15.8878C7.63019 15.8879 7.63036 15.888 8.00016 15.3333L8.36996 15.888C8.14603 16.0373 7.85393 16.0371 7.63 15.8878ZM7.63 15.8878L8.00016 15.3333C8.36996 15.888 8.37063 15.8876 8.37082 15.8875L8.37223 15.8865L8.37667 15.8835L8.39195 15.8732C8.40493 15.8644 8.42345 15.8517 8.44712 15.8353C8.49448 15.8025 8.56252 15.7547 8.6483 15.6928C8.8198 15.5689 9.06259 15.3881 9.35295 15.1569C9.93261 14.6954 10.7072 14.0286 11.4841 13.2085C13.0107 11.5972 14.6668 9.26796 14.6668 6.66667C14.6668 4.89856 13.9644 3.20286 12.7142 1.95262C11.464 0.702379 9.76827 0 8.00016 0C6.23205 0 4.53636 0.702379 3.28612 1.95262C2.03587 3.20286 1.3335 4.89856 1.3335 6.66667C1.3335 9.26796 2.98967 11.5972 4.51619 13.2085C5.29309 14.0286 6.06772 14.6954 6.64738 15.1569C6.93774 15.3881 7.18053 15.5689 7.35202 15.6928C7.43781 15.7547 7.50585 15.8025 7.5532 15.8353C7.57688 15.8517 7.59539 15.8644 7.60838 15.8732L7.62365 15.8835L7.6281 15.8865L7.63 15.8878ZM6.66683 6.66667C6.66683 5.93029 7.26378 5.33333 8.00016 5.33333C8.73654 5.33333 9.3335 5.93029 9.3335 6.66667C9.3335 7.40305 8.73654 8 8.00016 8C7.26378 8 6.66683 7.40305 6.66683 6.66667ZM8.00016 4C6.5274 4 5.3335 5.19391 5.3335 6.66667C5.3335 8.13943 6.5274 9.33333 8.00016 9.33333C9.47292 9.33333 10.6668 8.13943 10.6668 6.66667C10.6668 5.19391 9.47292 4 8.00016 4Z"
        fill={fill}
      />
    </svg>
  );
}

function SkillIcon({ width = 16, height = 16, fill = 'rgb(0,0,0,1)' }: IIconProps) {
  return (
    <svg
      width={width}
      style={{ minWidth: width }}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99986 0.666504C8.2536 0.666504 8.48535 0.810552 8.59766 1.03809L10.5027 4.89748L14.7629 5.52018C15.014 5.55687 15.2224 5.73288 15.3007 5.97422C15.3789 6.21555 15.3134 6.48039 15.1317 6.65741L12.0495 9.65941L12.7769 13.9005C12.8198 14.1506 12.717 14.4034 12.5117 14.5525C12.3064 14.7017 12.0342 14.7213 11.8096 14.6032L7.99986 12.5997L4.19016 14.6032C3.96555 14.7213 3.69335 14.7017 3.48803 14.5525C3.28272 14.4034 3.17989 14.1506 3.22278 13.9005L3.95018 9.65942L0.868035 6.65741C0.686289 6.48039 0.620774 6.21555 0.699026 5.97422C0.777278 5.73288 0.985731 5.55687 1.23677 5.52018L5.49702 4.89748L7.40205 1.03809C7.51437 0.810552 7.74611 0.666504 7.99986 0.666504ZM7.99986 2.83934L6.53766 5.80159C6.44064 5.99815 6.25318 6.13446 6.03628 6.16616L2.76546 6.64424L5.13168 8.94893C5.28893 9.10209 5.3607 9.32285 5.3236 9.5392L4.76531 12.7943L7.68956 11.2565C7.88381 11.1543 8.1159 11.1543 8.31016 11.2565L11.2344 12.7943L10.6761 9.5392C10.639 9.32285 10.7108 9.10209 10.868 8.94893L13.2342 6.64424L9.96344 6.16616C9.74654 6.13446 9.55908 5.99815 9.46205 5.80159L7.99986 2.83934Z"
        fill={fill}
      />
    </svg>
  );
}

function PassionIcon({ width = 16, height = 16, fill = 'rgb(0,0,0,1)' }: IIconProps) {
  return (
    <svg
      width={width}
      style={{ minWidth: width }}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.64115 1.66208C10.167 1.44418 10.7307 1.33203 11.2999 1.33203C11.8691 1.33203 12.4328 1.44418 12.9587 1.66208C13.4845 1.87994 13.9622 2.19923 14.3645 2.60173C14.767 3.0041 15.0866 3.48204 15.3044 4.00782C15.5223 4.53369 15.6345 5.09735 15.6345 5.66658C15.6345 6.23582 15.5223 6.79947 15.3044 7.32535C15.0865 7.85117 14.7672 8.32893 14.3647 8.73132C14.3646 8.73136 14.3647 8.73128 14.3647 8.73132L8.47132 14.6247C8.21097 14.885 7.78886 14.885 7.52851 14.6247L1.63518 8.73132C0.82236 7.9185 0.365723 6.81608 0.365723 5.66658C0.365723 4.51708 0.82236 3.41466 1.63518 2.60184C2.448 1.78902 3.55042 1.33239 4.69992 1.33239C5.84942 1.33239 6.95184 1.78902 7.76465 2.60184L7.99992 2.83711L8.23507 2.60195C8.23511 2.60192 8.23503 2.60199 8.23507 2.60195C8.63746 2.1994 9.11532 1.87996 9.64115 1.66208ZM11.2999 2.66536C10.9058 2.66536 10.5156 2.74301 10.1515 2.89386C9.78747 3.04471 9.45669 3.26582 9.1781 3.54454L8.47132 4.25132C8.21097 4.51167 7.78886 4.51167 7.52851 4.25132L6.82185 3.54465C6.25907 2.98188 5.49579 2.66572 4.69992 2.66572C3.90404 2.66572 3.14076 2.98188 2.57799 3.54465C2.01522 4.10742 1.69906 4.8707 1.69906 5.66658C1.69906 6.46246 2.01522 7.22574 2.57799 7.78851L7.99992 13.2104L13.4218 7.78851C13.7006 7.50992 13.9218 7.17903 14.0726 6.81496C14.2235 6.45089 14.3011 6.06067 14.3011 5.66658C14.3011 5.2725 14.2235 4.88228 14.0726 4.51821C13.9218 4.15414 13.7007 3.82336 13.422 3.54476C13.1434 3.26604 12.8124 3.04471 12.4483 2.89386C12.0842 2.74301 11.694 2.66536 11.2999 2.66536Z"
        fill={fill}
      />
    </svg>
  );
}

function PeopleIcon({ width = 16, height = 16, fill = 'rgba(0,0,0,1)' }: IIconProps) {
  return (
    <svg
      width={width}
      style={{ minWidth: width }}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33317 3.33333C5.31203 3.33333 2.99984 5.49452 2.99984 8C2.99984 8.90085 3.29204 9.74572 3.80593 10.4662C3.93475 10.6468 3.96529 10.8797 3.88739 11.0874L3.37852 12.4444L5.36576 12.0469C5.50635 12.0188 5.65226 12.0368 5.78184 12.0982C6.57848 12.4754 7.44944 12.6697 8.3309 12.6667L8.33317 12.6667C11.3543 12.6667 13.6665 10.5055 13.6665 8C13.6665 5.49452 11.3543 3.33333 8.33317 3.33333ZM1.6665 8C1.6665 4.61482 4.72765 2 8.33317 2C11.9387 2 14.9998 4.61482 14.9998 8C14.9998 11.3848 11.9393 13.9995 8.33431 14H8.33317V13.3333L8.33544 14L8.33431 14C7.32958 14.0033 6.33601 13.7974 5.4165 13.3965C5.34763 13.3665 5.48451 13.4287 5.4165 13.3965L2.46391 13.9871C2.22656 14.0345 1.98211 13.9496 1.8253 13.7652C1.66849 13.5808 1.62396 13.3259 1.70895 13.0993L2.51903 10.939C2.4488 10.8406 2.58301 11.0415 2.51903 10.939C1.97868 10.0738 1.6665 9.07102 1.6665 8Z"
        fill={fill}
      />
      <path
        d="M6.96194 4.8446C6.90838 4.73622 6.79324 4.66699 6.66646 4.66699C6.53983 4.66699 6.42454 4.73622 6.37098 4.8446L4.69706 8.22915C4.66052 8.30305 4.65659 8.38767 4.68614 8.46445C4.71569 8.5411 4.77639 8.6036 4.85469 8.63807C5.01786 8.70991 5.21189 8.64329 5.28802 8.48931L5.65934 7.73838C5.66938 7.7392 5.67797 7.74387 5.68816 7.74387H7.64446C7.65465 7.74387 7.66338 7.7392 7.67328 7.73838L8.0446 8.48931H8.04489C8.12102 8.64329 8.31505 8.70991 8.47822 8.63807C8.64139 8.56623 8.71199 8.38313 8.63586 8.22915L6.96194 4.8446ZM5.9611 7.12848L6.66649 5.70215L7.37187 7.12848H5.9611Z"
        fill={fill}
      />
      <path
        d="M8.33391 10.6668C8.4095 10.6668 8.48286 10.6412 8.54192 10.5939C9.19838 10.0668 9.70191 9.37372 10.0001 8.58641C10.2983 9.37372 10.8018 10.0668 11.4582 10.5939C11.602 10.7089 11.8119 10.6857 11.927 10.5418C12.042 10.3981 12.0186 10.1881 11.8749 10.0731C11.043 9.38381 10.5031 8.405 10.3637 7.33363H11.3333C11.5173 7.33363 11.6666 7.18438 11.6666 7.00031C11.6666 6.81624 11.5173 6.66699 11.3333 6.66699H8.66689C8.48284 6.66699 8.3336 6.81624 8.3336 7.00031C8.3336 7.18438 8.48284 7.33363 8.66689 7.33363H9.6376C9.50012 8.40588 8.95924 9.38545 8.12528 10.0731C8.01458 10.1616 7.97187 10.3103 8.01889 10.444C8.06591 10.5777 8.19223 10.6671 8.33388 10.6668L8.33391 10.6668Z"
        fill={fill}
      />
    </svg>
  );
}

function IndustryIcon({ width = 16, height = 16, fill = 'rgb(0,0,0,1)' }: IIconProps) {
  return (
    <svg
      width={width}
      style={{ minWidth: width }}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99984 2.00033C4.68613 2.00033 1.99984 4.68662 1.99984 8.00033C1.99984 11.314 4.68613 14.0003 7.99984 14.0003C11.3135 14.0003 13.9998 11.314 13.9998 8.00033C13.9998 4.68662 11.3135 2.00033 7.99984 2.00033ZM0.666504 8.00033C0.666504 3.95024 3.94975 0.666992 7.99984 0.666992C12.0499 0.666992 15.3332 3.95024 15.3332 8.00033C15.3332 12.0504 12.0499 15.3337 7.99984 15.3337C3.94975 15.3337 0.666504 12.0504 0.666504 8.00033Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00016 4.66634C6.15921 4.66634 4.66683 6.15873 4.66683 7.99967C4.66683 9.84062 6.15921 11.333 8.00016 11.333C9.84111 11.333 11.3335 9.84062 11.3335 7.99967C11.3335 6.15873 9.84111 4.66634 8.00016 4.66634ZM3.3335 7.99967C3.3335 5.42235 5.42283 3.33301 8.00016 3.33301C10.5775 3.33301 12.6668 5.42235 12.6668 7.99967C12.6668 10.577 10.5775 12.6663 8.00016 12.6663C5.42283 12.6663 3.3335 10.577 3.3335 7.99967Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7.33333C7.63181 7.33333 7.33333 7.63181 7.33333 8C7.33333 8.36819 7.63181 8.66667 8 8.66667C8.36819 8.66667 8.66667 8.36819 8.66667 8C8.66667 7.63181 8.36819 7.33333 8 7.33333ZM6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8Z"
        fill={fill}
      />
    </svg>
  );
}

function ClientIcon({ width = 16, height = 16, fill = 'rgb(0,0,0,1)' }: IIconProps) {
  return (
    <svg
      width={width}
      style={{ minWidth: width }}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6665 1.33301C6.13607 1.33301 5.62736 1.54372 5.25229 1.91879C4.87722 2.29387 4.6665 2.80257 4.6665 3.33301V3.99967H2.6665C1.56193 3.99967 0.666504 4.8951 0.666504 5.99967V12.6663C0.666504 13.7709 1.56193 14.6663 2.6665 14.6663H5.33317H10.6665H13.3332C14.4377 14.6663 15.3332 13.7709 15.3332 12.6663V5.99967C15.3332 4.8951 14.4377 3.99967 13.3332 3.99967H11.3332V3.33301C11.3332 2.80257 11.1225 2.29387 10.7474 1.91879C10.3723 1.54372 9.8636 1.33301 9.33317 1.33301H6.6665ZM9.99984 3.99967V3.33301C9.99984 3.1562 9.9296 2.98663 9.80457 2.8616C9.67955 2.73658 9.50998 2.66634 9.33317 2.66634H6.6665C6.48969 2.66634 6.32012 2.73658 6.1951 2.8616C6.07008 2.98663 5.99984 3.1562 5.99984 3.33301V3.99967H9.99984ZM5.99984 5.33301H9.99984V13.333H5.99984V5.33301ZM4.6665 5.33301H2.6665C2.29831 5.33301 1.99984 5.63148 1.99984 5.99967V12.6663C1.99984 13.0345 2.29831 13.333 2.6665 13.333H4.6665V5.33301ZM11.3332 13.333V5.33301H13.3332C13.7014 5.33301 13.9998 5.63148 13.9998 5.99967V12.6663C13.9998 13.0345 13.7014 13.333 13.3332 13.333H11.3332Z"
        fill={fill}
      />
    </svg>
  );
}

function ProjectIcon({ width = 16, height = 16, fill = 'rgb(0,0,0,1)' }: IIconProps) {
  return (
    <svg
      width={width}
      style={{ minWidth: width }}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.6665 2.66634C2.48969 2.66634 2.32012 2.73658 2.1951 2.8616C2.07008 2.98663 1.99984 3.1562 1.99984 3.33301V12.6663C1.99984 12.8432 2.07007 13.0127 2.1951 13.1377C2.32012 13.2628 2.48969 13.333 2.6665 13.333H13.3332C13.51 13.333 13.6795 13.2628 13.8046 13.1377C13.9296 13.0127 13.9998 12.8432 13.9998 12.6663V5.33301C13.9998 5.1562 13.9296 4.98663 13.8046 4.8616C13.6795 4.73658 13.51 4.66634 13.3332 4.66634H7.33317C7.11027 4.66634 6.90211 4.55494 6.77847 4.36947L5.64305 2.66634H2.6665ZM1.25229 1.91879C1.62736 1.54372 2.13607 1.33301 2.6665 1.33301H5.99984C6.22274 1.33301 6.43089 1.44441 6.55454 1.62987L7.68996 3.33301H13.3332C13.8636 3.33301 14.3723 3.54372 14.7474 3.91879C15.1225 4.29387 15.3332 4.80257 15.3332 5.33301V12.6663C15.3332 13.1968 15.1225 13.7055 14.7474 14.0806C14.3723 14.4556 13.8636 14.6663 13.3332 14.6663H2.6665C2.13607 14.6663 1.62736 14.4556 1.25229 14.0806C0.877218 13.7055 0.666504 13.1968 0.666504 12.6663V3.33301C0.666504 2.80257 0.877217 2.29387 1.25229 1.91879Z"
        fill={fill}
      />
    </svg>
  );
}

function ArrowUpIcon({ width = 14, height = 8, fill = 'rgb(255,255,255,1)' }: IIconProps) {
  return (
    <svg
      width={width}
      style={{ minWidth: width }}
      height={height}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.29289 0.292893C6.68342 -0.0976311 7.31658 -0.0976311 7.70711 0.292893L13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711C13.3166 8.09763 12.6834 8.09763 12.2929 7.70711L7 2.41421L1.70711 7.70711C1.31658 8.09763 0.683417 8.09763 0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289L6.29289 0.292893Z"
        fill={fill}
      />
    </svg>
  );
}

function ArrowDownIcon({
  width = 14,
  height = 8,
  fill = 'rgb(255,255,255,1)',
  onClick = () => {},
}: IIconProps) {
  return (
    <svg
      className="arrow-down-icon"
      width={width}
      style={{ minWidth: width }}
      height={height}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
        fill={fill}
      />
    </svg>
  );
}

function CompanyIcon({ width = 24, height = 20, fill = '#5478A1' }) {
  return (
    <svg
      width={width}
      style={{ minWidth: width }}
      height={height}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.17188 0.166016C0.61959 0.166016 0.171875 0.613731 0.171875 1.16602V14.166C0.171875 15.4921 0.698659 16.7639 1.63634 17.7015C2.57402 18.6392 3.84579 19.166 5.17188 19.166H13.1719C14.498 19.166 15.7697 18.6392 16.7074 17.7015C17.6451 16.7639 18.1719 15.4921 18.1719 14.166C19.498 14.166 20.7697 13.6392 21.7074 12.7015C22.6451 11.7639 23.1719 10.4921 23.1719 9.16602C23.1719 7.83993 22.6451 6.56816 21.7074 5.63048C20.7697 4.6928 19.498 4.16602 18.1719 4.16602V1.16602C18.1719 0.613731 17.7242 0.166016 17.1719 0.166016H1.17188ZM16.1719 5.16602V2.16602H2.17188V14.166C2.17188 14.9617 2.48795 15.7247 3.05055 16.2873C3.61316 16.8499 4.37623 17.166 5.17188 17.166H13.1719C13.9675 17.166 14.7306 16.8499 15.2932 16.2873C15.8558 15.7247 16.1719 14.9617 16.1719 14.166V13.166V5.16602ZM18.1719 12.166C18.9675 12.166 19.7306 11.8499 20.2932 11.2873C20.8558 10.7247 21.1719 9.96166 21.1719 9.16602C21.1719 8.37037 20.8558 7.6073 20.2932 7.04469C19.7306 6.48209 18.9675 6.16602 18.1719 6.16602V12.166Z"
        fill={fill}
      />
    </svg>
  );
}

function DisciplineIcon({ width = 24, height = 20, fill = '#5478A1'}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99967 1.66732H10.9997C11.7361 1.66732 12.333 2.26427 12.333 3.00065V11.0007C12.333 11.4942 12.0649 11.9251 11.6663 12.1556V11.5007C11.6663 10.8376 11.3932 10.2017 10.907 9.73289C10.4208 9.26405 9.76135 9.00066 9.07375 9.00066H4.9256C4.238 9.00066 3.57856 9.26405 3.09236 9.73289C2.60615 10.2017 2.33301 10.8376 2.33301 11.5007V12.1556C1.93447 11.9251 1.66634 11.4942 1.66634 11.0007V3.00065C1.66634 2.26427 2.26329 1.66732 2.99967 1.66732ZM3.37004 12.334H10.6293V11.5007C10.6293 11.1028 10.4654 10.7213 10.1737 10.44C9.88197 10.1587 9.48631 10.0007 9.07375 10.0007H4.9256C4.51304 10.0007 4.11738 10.1587 3.82565 10.44C3.53393 10.7213 3.37004 11.1028 3.37004 11.5007V12.334ZM0.333008 3.00065C0.333008 1.52789 1.52692 0.333984 2.99967 0.333984H10.9997C12.4724 0.333984 13.6663 1.52789 13.6663 3.00065V11.0007C13.6663 12.4734 12.4724 13.6673 10.9997 13.6673H2.99967C1.52692 13.6673 0.333008 12.4734 0.333008 11.0007V3.00065ZM6.99968 4.06732C6.11602 4.06732 5.39967 4.78366 5.39967 5.66732C5.39967 6.55097 6.11602 7.26732 6.99968 7.26732C7.88333 7.26732 8.59968 6.55097 8.59968 5.66732C8.59968 4.78366 7.88333 4.06732 6.99968 4.06732ZM4.33301 5.66732C4.33301 4.19456 5.52692 3.00065 6.99968 3.00065C8.47244 3.00065 9.66634 4.19456 9.66634 5.66732C9.66634 7.14008 8.47244 8.33399 6.99968 8.33399C5.52692 8.33399 4.33301 7.14008 4.33301 5.66732Z"
        fill={fill}
      />
    </svg>
  );
}

export {
  DisciplineIcon,
  LocationIcon,
  SkillIcon,
  PassionIcon,
  PeopleIcon,
  IndustryIcon,
  ClientIcon,
  ProjectIcon,
  CompanyIcon,
  ArrowUpIcon,
  ArrowDownIcon,
};
