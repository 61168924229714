import React from 'react';

export default function EndQuoteIcon({ width = 18, height = 18, fill = '#FFC229' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.673375 8.96C1.44738 8.96 2.23938 8.798 3.06738 8.492C4.72338 7.88 5.98338 6.512 6.36138 4.928C6.86538 5.72 7.60338 6.116 8.55738 6.116C9.29538 6.116 10.0334 5.882 10.2854 5.612C10.2854 5.972 10.0514 6.422 9.60138 6.908C8.68338 7.934 6.84738 8.456 5.92938 8.456L6.28938 8.96C7.17138 8.96 8.07138 8.762 8.98938 8.366C10.8074 7.574 12.0854 5.756 12.0854 3.92C12.0854 1.616 10.7174 0.319999 8.91738 0.319999C7.54938 0.319999 6.55938 0.985999 6.10938 2.084C5.62338 0.985999 4.56138 0.319999 3.30138 0.319999C1.48338 0.319999 0.277375 1.562 0.277375 3.308C0.277375 4.856 1.37538 6.116 2.94138 6.116C3.67938 6.116 4.41738 5.882 4.66938 5.612C4.66938 5.972 4.43538 6.422 3.98537 6.908C3.06738 7.934 1.23138 8.456 0.313375 8.456L0.673375 8.96Z"
        fill={fill}
      />
    </svg>
  );
}
