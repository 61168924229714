import {
  OnboardingStepTwoKyuTeamIdentifierContainer,
  OnboardingStepTwoKyuTeamIdentifierText,
} from 'app/components/Onboarding/steps/Two/styled';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsExecutiveCommitteeWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  ${OnboardingStepTwoKyuTeamIdentifierContainer} {
    flex-direction: column-reverse;

    top: unset;
    bottom: 0px;
    left: -90px;

    svg {
      transform: rotate(154deg) translateY(16px);
      ${BREAKPOINTS.tablet} {
        transform: rotate(38deg);
      }
    }

    ${BREAKPOINTS.tablet} {
      flex-direction: column;

      top: unset;
      bottom: -60px;
      right: -15px;
      left: unset;
    }

    ${OnboardingStepTwoKyuTeamIdentifierText} {
      max-width: 160px;
      margin-left: unset;
      text-align: end;

      transform: translateX(-55%);
    }
  }
`;
