import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useIsMobile from 'hooks/useIsMobile';
import useUserData from 'hooks/useUserData';
import {
  updateOnboarded,
  updateOnboardingStarted,
  updateOnboardingProfile,
} from 'services/onboarding';
import { aboutKyuOsOpenSelector, setisOnboardingOpen } from 'store/reducers/onboarding-reducer';
import { profileCompletionSelector, setUserData } from 'store/reducers/user-reducer';
import { ThemeProvider } from 'styled-components';

import { CloseIcon, RightArrow } from '../shared/icons';
import CheckIcon from '../shared/icons/check-icon';
import { OnboardingContext } from './OnboardingContext';
import OnboardingSlider from './Slider';
import { ComponentStepBased, backgroundPositionStepBased, stepsTitles } from './constants';
import useOnboardingData from './hooks/useOnboardingData';
import {
  OnboardingBackgroundBlur,
  OnboardingBackgroundContainer,
  OnboardingCloseButton,
  OnboardingContainer,
  OnboardingNextStepButton,
  OnboardingNextStepInformationContainer,
  OnboardingPreviousStepButton,
  OnboardingPreviousStepInformationContainer,
  OnboardingStepSubtitle,
  OnboardingStepTitle,
  OnboardingWrapper,
} from './styled';

export default function Onboarding() {
  const userData = useUserData();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const profileCompletion: number = useSelector(profileCompletionSelector);
  const isAboutKyuOsOpen = useSelector(aboutKyuOsOpenSelector);
  const onboardingContainerRef = useRef<HTMLDivElement>(null);
  const [currentStep, setCurrentStep] = useState<number>(6);
  const [currentVariant, setVariant] = useState<string>('');
  const [currentPassion, setCurrentPassion] = useState<any>(null);
  const [hideControls, setHideControls] = useState<{ previous: boolean; next: boolean }>({
    previous: false,
    next: false,
  });
  const onboardingQuery = useOnboardingData();

  useEffect(() => {
    if (userData.onboarding_started && profileCompletion >= 50) {
      setCurrentStep(6);
    }
  }, []);

  const initialContextValues = {
    currentStep,
    currentPassion,
    hideControls,
    setCurrentStep,
    setHideControls,
    setCurrentPassion,
    onboardingQuery,
  };

  useEffect(() => {
    if (currentStep !== 1 && (hideControls.previous || hideControls.next)) {
      setHideControls({ previous: false, next: false });
    }

    if (currentStep === 6) {
      setHideControls({ previous: true, next: false });
    }

    if (onboardingContainerRef.current) {
      onboardingContainerRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  useEffect(() => {
    updateOnboardingStarted({ onboarding_started: true });
    dispatch(
      setUserData({
        ...userData,
        onboarding_started: true,
      })
    );
  }, []);

  const shouldShowNextControls = useMemo(() => {
    return currentStep !== 1 && !hideControls.next && !isAboutKyuOsOpen;
  }, [currentStep, hideControls, isAboutKyuOsOpen]);

  const shouldShowPreviousControls = useMemo(() => {
    return currentStep !== 1 && !hideControls.previous;
  }, [currentStep, hideControls]);

  const handleClickPreviousStep = () => {
    if (currentStep - 1 === 1) {
      return setVariant('initial');
    }

    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleClickNextStep = () => {
    const nextStep = currentStep + 1;

    if (currentStep === ComponentStepBased.size - 1) {
      updateOnboardingProfile({ onboarding_profile: true });
      dispatch(setUserData({ ...userData, onboarding_profile: true }));
      if (profileCompletion < 50) {
        push('/my-profile');
        return dispatch(setisOnboardingOpen(false));
      }
    }

    if (currentStep < ComponentStepBased.size) {
      setCurrentStep(nextStep);
    } else {
      push('/my-profile#my-reach');
      updateOnboarded({ onboarded: true });
      dispatch(setisOnboardingOpen(false));
      dispatch(setUserData({ ...userData, onboarded: true }));
    }
  };

  const handleCloseClick = () => {
    updateOnboardingProfile({ onboarding_profile: true });
    dispatch(setUserData({ ...userData, onboarding_profile: true }));
    dispatch(setisOnboardingOpen(false));
  };

  return (
    <ThemeProvider theme={{ isMobile, currentStep }}>
      <OnboardingContext.Provider value={initialContextValues}>
        {ReactDOM.createPortal(<OnboardingBackgroundBlur />, document.querySelector('#root'))}
        <OnboardingWrapper>
          <OnboardingBackgroundContainer
            backgroundPosition={backgroundPositionStepBased.get(currentStep)}
          />
          {currentVariant !== 'initial' ? (
            <>
              {userData.onboarding_started && currentStep !== 1 ? (
                <OnboardingCloseButton onClick={handleCloseClick}>
                  <CloseIcon fill="var(--color-primary)" fillOpacity="1" height={10} width={10} />
                </OnboardingCloseButton>
              ) : null}
              <OnboardingContainer ref={onboardingContainerRef}>
                {ComponentStepBased.get(currentStep)}
              </OnboardingContainer>
            </>
          ) : null}
          {shouldShowPreviousControls ? (
            <>
              <OnboardingPreviousStepButton
                onClick={handleClickPreviousStep}
                animate={currentVariant}
                onAnimationComplete={() => {
                  setCurrentStep(1);
                  setVariant('');
                }}
              >
                {currentVariant !== 'initial' ? (
                  <RightArrow fill="var(--color-primary)" width={16} height={16} />
                ) : null}
              </OnboardingPreviousStepButton>
              {currentVariant !== 'initial' ? (
                <OnboardingPreviousStepInformationContainer>
                  <OnboardingStepTitle>BACK TO</OnboardingStepTitle>
                  <OnboardingStepSubtitle>
                    {stepsTitles.get(currentStep - 1)}
                  </OnboardingStepSubtitle>
                </OnboardingPreviousStepInformationContainer>
              ) : null}
            </>
          ) : null}
          {shouldShowNextControls ? (
            <>
              <OnboardingNextStepButton
                animate={currentVariant}
                onClick={handleClickNextStep}
                mobileMarginLeft={currentStep == 6 ? 5 : 0}
              >
                {currentVariant !== 'initial' ? (
                  <>
                    {currentStep !== 6 ? (
                      <RightArrow fill="var(--color-primary)" width={16} height={16} />
                    ) : (
                      <CheckIcon
                        fill="var(--color-primary)"
                        width={isMobile ? 35 : 45}
                        height={isMobile ? 35 : 45}
                        style={{ marginLeft: '5px' }}
                        background={'rgba(0, 0, 0, 0)'}
                      />
                    )}
                  </>
                ) : null}
              </OnboardingNextStepButton>
              {currentVariant !== 'initial' ? (
                <OnboardingNextStepInformationContainer>
                  <OnboardingStepTitle>NEXT UP</OnboardingStepTitle>
                  <OnboardingStepSubtitle>
                    {stepsTitles.get(currentStep + 1)}
                  </OnboardingStepSubtitle>
                </OnboardingNextStepInformationContainer>
              ) : null}
            </>
          ) : null}
        </OnboardingWrapper>
      </OnboardingContext.Provider>
    </ThemeProvider>
  );
}
