const madlibs_highlights = ({
  companies_of_people_that_match_search_criteria,
  kyu_skills,
  kyu_skills_count,
  passions,
  passions_count,
  kyu_companies,
  people_count,
  kyu_companies_count,
  people_match_search_criteria,
}) => {
  let passions_and_skills_text = "";
  let skills_count;

  const passions_array = passions
    ? passions.map(
        (passion) => `<span class="highlight-passion">${passion}</span>`
      )
    : [];

  if (!kyu_skills) {
    kyu_skills = [];
  }

  if (passions_array.length > 0) {
    if (passions_array.length > 1 && kyu_skills.length > 0) {
      passions_and_skills_text += ` of passions in ${passions_array
        .slice(0, passions_array.length - 1)
        .join(", ")}${kyu_skills.length === 0 ? " and" : ","} ${
        passions_array[passions_array.length - 1]
      }`;
    } else if (kyu_skills.length === 0) {
      passions_and_skills_text += ` the passions ${passions_array
        .slice(0, passions_array.length - 1)
        .join(", ")}${kyu_skills.length === 0 ? " and" : ","} ${
        passions_array[passions_array.length - 1]
      }`;
    } else {
      passions_and_skills_text += ` of the passion ${passions_array[0]}`;
    }
  }

  if (kyu_skills.length === 0) {
    skills_count = 0;
  } else {
    skills_count = kyu_skills.length;
    let skills_array = kyu_skills.map(
      (skill) => `<span class="highlight-skills">${skill}</span>`
    );
    if (skills_array.length > 1 && passions_array.length > 0) {
      passions_and_skills_text += ` with skills in ${skills_array
        .slice(0, skills_array.length - 1)
        .join(", ")} and ${skills_array[skills_array.length - 1]}`;
    } else if (passions_array.length === 0) {
      passions_and_skills_text += ` the skills ${skills_array
        .slice(0, skills_array.length - 1)
        .join(", ")} and ${skills_array[skills_array.length - 1]}`;
    } else {
      passions_and_skills_text += ` and the skill ${skills_array[0]}`;
    }
  }

  passions_and_skills_text += ".";

  let result = "";

  // Get people that match all search criteria and their companies
  if (people_match_search_criteria > 0) {
    let is = "";
    people_match_search_criteria > 1 ? (is = "are") : (is = "is");

    let companies_paragraph = "";
    if (companies_of_people_that_match_search_criteria > 1) {
      companies_paragraph += `across <span class="highlight-kyu-company">${companies_of_people_that_match_search_criteria} companies </span> that meet`;
    } else {
      companies_paragraph += `at <a href="/member-company/${
        kyu_companies[0].slug
      }"><span class="highlight-kyu-company">${
        kyu_companies[0].name
      }</span></a> that ${people_match_search_criteria > 1 ? "meet" : "meet"}`;
    }

    let person = people_match_search_criteria > 1 ? "people" : "person";
    result += `<p>There ${is} <span class="highlight-people">${people_match_search_criteria} ${person}</span> \
              ${companies_paragraph} \
              all the criteria.</p>`;
  }

  let is = "is";
  people_count > 1 ? (is = "are") : (is = "is");

  let combinations;
  kyu_skills.length > 0 && passions_array.length > 0
    ? (combinations = "combinations")
    : (combinations = "");
  let person = people_count > 1 || people_count === 0 ? "people" : "person";

  let companies_paragraph = "";
  if (kyu_companies_count > 1 && kyu_companies) {
    companies_paragraph += `across <span class="highlight-kyu-company">${kyu_companies_count} companies </span>`;
  } else {
    companies_paragraph += `at <a href="/member-company/${kyu_companies[0]?.slug}"><span class="highlight-kyu-company">${kyu_companies[0]?.name}</span></a>`;
  }

  result += `<p>There ${is} <span class="highlight-people">${people_count} ${person} \
            </span> ${companies_paragraph}\
            </span> who share ${combinations} ${passions_and_skills_text}</p>`;

  return result;
};

const madlib_kyu_company_highlights = ({
  full_match_people_count,
  kyu_companies_for_filtering,
  kyu_company_name,
  kyu_skills,
  passions,
  people_count,
}) => {
  let company_name = kyu_company_name;
  let first_paragraph = "";

  let passions_array = passions
    ? passions.map(
        (passion) => `<span class="highlight-skills">${passion}</span>`
      )
    : [];
  let skills_array = kyu_skills
    ? kyu_skills.map(
        (passion) => `<span class="highlight-passion">${passion}</span>`
      )
    : [];

  let people_by_company_count = people_count;
  let fp_is = people_by_company_count > 1 ? "are" : "is";

  first_paragraph = "<p>";

  if (!kyu_companies_for_filtering) {
    first_paragraph += "Across kyu, ";
  }

  first_paragraph += `<span class="highlight-people">${people_by_company_count} ${
    people_by_company_count > 1 ? "people" : "person"
  }</span> \
                      ${fp_is} connected to <span class="highlight-kyu-company">${company_name}</span>.</p>`;

  let second_paragaph = `<p><span class="highlight-kyu-company">${company_name}</span> is most closely connected `;

  if (passions_array.length > 0) {
    if (passions_array.length > 1) {
      second_paragaph += `to the passions ${passions_array
        .slice(0, passions_array.length - 1)
        .join(", ")} `;
      second_paragaph += `${skills_array.length > 0 ? " and" : ","} ${
        passions_array[passions_array.length - 1]
      }${skills_array.length > 0 ? "." : " and "}`;
    } else {
      second_paragaph += `to the passion ${passions_array[0]}${
        skills_array.length > 0 ? " and" : "."
      }`;
    }
  }

  if (skills_array.length > 0) {
    if (skills_array.size > 1) {
      second_paragaph += `to the skills ${skills_array
        .slice(0, skills_array.length - 1)
        .join(", ")} and ${skills_array[skills_array.length - 1]}.`;
    } else {
      second_paragaph += `to the skill ${skills_array[0]}.`;
    }
  }

  second_paragaph += "</p>";

  let result = first_paragraph + second_paragaph;

  let last_sentence = "";
  let third_paragraph = "";

  if (skills_array.length > 0 && passions_array.length > 0) {
    last_sentence = "skills and passions";
  }

  if (passions_array.length === 0) {
    last_sentence = "skills";
  }

  if (skills_array.length === 0) {
    last_sentence = "passions";
  }

  let has;
  has = full_match_people_count > 1 ? "share" : "has";

  if (full_match_people_count > 0) {
    third_paragraph = `<p><span class="highlight-people">${full_match_people_count} ${
      full_match_people_count > 1 ? "people" : "person"
    }</span> \
                      ${has} all the searched ${last_sentence}.</p>`;
    result += third_paragraph;
  }

  return result;
};

const madlib_passion_highlights = ({
  companies_with_most_employees,
  kyu_companies_for_filtering,
  people_count,
  passion,
}) => {
  let first_paragraph = "";
  let second_paragraph = "";

  if (people_count === 0) {
    let kyu_companies_filter_array = kyu_companies_for_filtering.map(
      (kyu_company) =>
        `<span class="highlight-kyu-company">${kyu_company.name}</span>`
    );
    first_paragraph = `<p><span class="highlight-passion">${passion}</span> doesn't possess any connections`;

    if (kyu_companies_filter_array.length === 0) {
      second_paragraph = ".</p>";
    } else if (kyu_companies_filter_array.length > 1) {
      second_paragraph = ` within ${kyu_companies_filter_array
        .slice(0, kyu_companies_filter_array.length - 1)
        .join(", ")} and ${
        kyu_companies_filter_array[kyu_companies_filter_array.length - 1]
      }.</p>`;
    } else {
      second_paragraph = ` within ${kyu_companies_filter_array[0]}.</p>`;
    }
  } else {
    let companies_count = kyu_companies_for_filtering
      ? kyu_companies_for_filtering.length
      : 0;
    let is = people_count > 1 ? "are" : "is";

    first_paragraph = "<p>";

    if (!kyu_companies_for_filtering) {
      first_paragraph += "Across kyu, ";
    }

    first_paragraph += `<span class="highlight-people">${people_count} ${
      people_count > 1 ? "people" : "person"
    }</span> from \
                        <span class="highlight-kyu-company">${
                          companies_with_most_employees.length
                        } ${
      companies_with_most_employees.length > 1 ? "companies" : "company"
    }\
                        </span> ${is} linked to this passion.</p>`;

    let kyu_companies_array = companies_with_most_employees
      .slice(0, 3)
      .map(
        (company) => `<span class="highlight-kyu-company">${company}</span>`
      );

    second_paragraph = "<p>Based on the total size, ";
    if (kyu_companies_array.length > 1) {
      second_paragraph += `${kyu_companies_array
        .slice(0, kyu_companies_array.length - 1)
        .join(", ")} and ${
        kyu_companies_array[kyu_companies_array.length - 1]
      } `;
    } else {
      second_paragraph += `${kyu_companies_array[0]} `;
    }

    second_paragraph +=
      "have the most people per capita with this passion.</p>";
  }

  return first_paragraph + second_paragraph;
};

const madlib_kyu_skill_highlights = ({
  companies_with_most_employees,
  kyu_companies_for_filtering,
  people_count,
  name,
  related_skills_count,
}) => {
  let first_paragraph = "";
  let second_paragraph = "";

  if (people_count.length === 0) {
    let kyu_companies_filter_array = kyu_companies_for_filtering.map(
      (kyu_company) =>
        `<span class="highlight-kyu-company">${kyu_company}</span>`
    );
    first_paragraph = `<p><span class="highlight-skills">${name}</span> doesn't possess any connections`;

    if (kyu_companies_filter_array.length === 0) {
      second_paragraph = ".</p>";
    } else if (kyu_companies_filter_array.length > 1) {
      second_paragraph += ` within ${kyu_companies_filter_array
        .slice(0, kyu_companies_filter_array.length - 1)
        .join(", ")} and ${
        kyu_companies_filter_array[kyu_companies_filter_array.length - 1]
      }.</p>`;
    } else {
      second_paragraph = ` within ${kyu_companies_filter_array[0]}.</p>`;
    }
  } else {
    let companies_count = companies_with_most_employees.length;

    first_paragraph += "<p>";

    if (!kyu_companies_for_filtering) {
      first_paragraph += "Across kyu, ";
    }

    first_paragraph += `<span class="highlight-people">${people_count} ${
      people_count > 1 ? "people" : "person"
    }</span> from \
                      <span class="highlight-kyu-company">${companies_count} ${
      companies_count > 1 ? "companies" : "company"
    }\
                      </span> and <span class="highlight-skills">${related_skills_count} ${
      related_skills_count > 1 ? "skills" : "skill"
    } \
                      </span> are linked to this skill group.</p>`;

    let kyu_companies_array;
    kyu_companies_array = companies_with_most_employees
      .slice(0, 3)
      .map(
        (company) => `<span class="highlight-kyu-company">${company}</span>`
      );

    second_paragraph = "<p>";
    if (kyu_companies_array.length > 1) {
      second_paragraph += `${kyu_companies_array
        .slice(0, kyu_companies_array.length - 1)
        .join(", ")} and ${
        kyu_companies_array[kyu_companies_array.length - 1]
      } `;
    } else {
      second_paragraph += `${kyu_companies_array[0]} `;
    }

    second_paragraph += "have the most employees with this skill.</p>";
  }

  return first_paragraph + second_paragraph;
};

export {
  madlibs_highlights,
  madlib_kyu_company_highlights,
  madlib_passion_highlights,
  madlib_kyu_skill_highlights,
};
