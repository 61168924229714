import React from 'react';

import AboutKyuOsNetworkActiveWork from './AboutKyuOsNetworkActiveWork';
import AboutKyuOsNetworkIntro from './AboutKyuOsNetworkIntro';
import AboutKyuOsNetworkTools from './AboutKyuOsNetworkTools';
import { AboutKyuOsNetworkTitle, AboutKyuOsNetworkWrapper } from './styled';

export default function AboutKyuOsNetwork() {
  return (
    <AboutKyuOsNetworkWrapper id="networking" data-index="2">
      <AboutKyuOsNetworkTitle>Grow your network</AboutKyuOsNetworkTitle>
      <AboutKyuOsNetworkIntro />
      <AboutKyuOsNetworkActiveWork />
      <AboutKyuOsNetworkTools />
    </AboutKyuOsNetworkWrapper>
  );
}
