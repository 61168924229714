import React from 'react';

import Tippy from '@tippyjs/react';
import { InfoIcon } from 'app/components/shared/icons';
import useTippy from 'hooks/useTippy';
import { followCursor } from 'tippy.js';

import { InfoTooltipText, InfoTooltipTitle, InfoTooltipWrapper } from './styled';

interface InfoTooltipProps {
  title?: string;
  text: string;
  size: number;
  fill?: string;
  maxWidth?: string;
}

export default function InfoTooltip({
  title,
  text,
  size,
  fill = 'var(--color-data-company-background)',
  maxWidth,
}: InfoTooltipProps) {
  const tippyInstance = useTippy();

  return (
    <>
      <Tippy
        popperOptions={{
          placement: 'bottom',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [8, 8],
              },
            },
          ],
        }}
        zIndex={999999999999999}
        followCursor
        plugins={[followCursor]}
        placement="bottom-start"
        animation={false}
        trigger="mouseenter"
        onShow={(instance) => {
          tippyInstance.current = instance;
        }}
        onHide={(instance) => {
          tippyInstance.current = null;
        }}
        render={(attrs) => (
          <InfoTooltipWrapper maxWidth={maxWidth}>
            {title ? <InfoTooltipTitle>{title}</InfoTooltipTitle> : null}
            <InfoTooltipText>{text}</InfoTooltipText>
          </InfoTooltipWrapper>
        )}
      >
        <div style={{ display: 'flex' }}>
          <InfoIcon width={size} height={size} fill={fill} />
        </div>
      </Tippy>
    </>
  );
}
