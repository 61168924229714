import styled from 'styled-components';

export const ProfileEditorDrawerAvatarWrapper = styled.div`
  & textarea {
    height: 70px;
  }
`;

export const ProfileEditorDrawerAvatarHeaderSubTitle = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(23, 28, 51, 0.5);
  margin-top: 16px;
`;

export const ProfileEditorDrawerAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  position: relative;
`;

export const ProfileEditorDrawerAvatarDropzoneWrapper = styled.div``;

export const ProfileEditorDrawerAvatarDropzoneTitle = styled.h2`
  font-size: 24px;
  line-height: 31px;
`;

export const ProfileEditorDrawerAvatarDropzoneContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  background: rgba(255, 194, 41, 0.15);
  border: 1px dashed #ffc229;
  border-radius: 8px;
  height: 200px;
  margin-bottom: 10px;
`;

export const ProfileEditorDrawerAvatarDropzoneInput = styled.input``;
export const ProfileEditorDrawerAvatarDropzoneText = styled.p`
  margin: 10px 0px 8px 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000;
`;

export const ProfileEditorDrawerAvatarButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #171c33;
  border-radius: 100px;
  padding: 10px 30px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
`;
export const ProfileEditorDrawerAvatarDropzoneButton = styled(ProfileEditorDrawerAvatarButton)``;

export const ProfileEditorDrawerAvatarDropzoneSpecs = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: rgba(23, 28, 51, 0.5);
`;

export const ProfileEditorDrawerAvatarDropzoneErrorContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileEditorDrawerAvatarDropzoneErrorText = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #eb3d3d;
  margin-left: 5px;
`;

export const ProfileEditorDrawerAvatarCropperContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const ProfileEditorDrawerAvatarCropperTitle = styled.h2`
  font-size: 21px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 21px;
`;

export const ProfileEditorDrawerAvatarCropper = styled.div`
  position: relative;
  height: 300px;
  margin-bottom: 15px;
`;

export const ProfileEditorDrawerAvatarCropButton = styled(ProfileEditorDrawerAvatarButton)`
  width: fit-content;
  margin: 0px auto;
`;

export const ProfileEditorDrawerAvatarPostCropContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ProfileEditorDrawerAvatarPostCropTitle = styled.h2`
  font-size: 24px;
  line-height: 31px;
`;
export const ProfileEditorDrawerAvatarPostCropPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  padding: 30px 0px;
  margin-top: 20px;
  background: rgba(255, 194, 41, 0.15);
  border-radius: 8px;
`;
export const ProfileEditorDrawerAvatarPostCropPreviewImg = styled.img`
  border-radius: 50%;
  height: 200px;
  width: 200px;
`;
export const ProfileEditorDrawerAvatarPostCropDelete = styled.button`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: var(--color-text);
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 20px 0px 0px 0px;
  cursor: pointer;
  & span {
    margin-left: 8px;
  }
`;
export const ProfileEditorDrawerAvatarPostCropSpecs = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: rgba(23, 28, 51, 0.5);
  margin-top: 5px;
`;

export const ProfileEditorDrawerAvatarButtonsContainer = styled.div`
  display: flex;
`;

export const ProfileEditorDrawerAvatarPublishButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 48px;
  background: ${({ disabled }) =>
    disabled ? 'var(--color-data-alumni-background)' : 'var(--color-primary)'};

  ${({ disabled }) =>
    !disabled &&
    `
    transition: background 200ms ease-in-out;

    &:hover {
      background: var(--color-dark-theme-background-secondary);
    }
  `}
  border-radius: 100px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ disabled }) => (disabled ? 'var(--color-text)' : '#FFFFFF')};
`;

export const ProfileEditorDrawerAvatarSamplesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  justify-content: center;
`;
export const ProfileEditorDrawerAvatarSamplesTitle = styled.h2`
  font-size: 24px;
  line-height: 31px;
  color: #000000;
  margin-left: 10px;
`;

export const ProfileEditorDrawerAvatarSamplesContainer = styled.div`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
`;

export const ProfileEditorDrawerAvatarSamplesItem = styled.div`
  cursor: pointer;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 50%;
  opacity: ${({ opacity }) => opacity};
  border: 4px solid ${({ selected }) => (selected ? '#171C33' : '#FFFFFF')};
`;

export const ProfileEditorDrawerAvatarSamplesItemImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const ProfileEditorDrawerAvatarSamplesItemCheck = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: ${({ selected }) => (selected ? 'flex' : 'none')};
`;
