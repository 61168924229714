import React from 'react';

export default function WorldGlobe({ width = 16, height = 16, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9851 2.47528C4.61384 3.09272 2.81331 5.12422 2.53792 7.6154H5.20136C5.35083 5.77327 5.96736 4.00501 6.9851 2.47528ZM10.0175 2.47528C11.0352 4.00501 11.6518 5.77327 11.8012 7.6154H14.4647C14.1893 5.12422 12.3888 3.09271 10.0175 2.47528ZM10.4629 7.6154C10.2954 5.80661 9.61442 4.08335 8.5013 2.64925C7.38818 4.08335 6.70716 5.80661 6.5397 7.6154H10.4629ZM6.5397 8.94873H10.4629C10.2954 10.7575 9.61442 12.4808 8.5013 13.9149C7.38819 12.4808 6.70716 10.7575 6.5397 8.94873ZM5.20136 8.94873H2.53792C2.81331 11.4399 4.61385 13.4714 6.98512 14.0888C5.96738 12.5591 5.35084 10.7909 5.20136 8.94873ZM10.0175 14.0888C11.0352 12.5591 11.6518 10.7909 11.8012 8.94873H14.4647C14.1893 11.4399 12.3888 13.4714 10.0175 14.0888ZM8.5013 0.94873C4.45121 0.94873 1.16797 4.23198 1.16797 8.28206C1.16797 12.3322 4.45121 15.6154 8.5013 15.6154C12.5514 15.6154 15.8346 12.3322 15.8346 8.28206C15.8346 4.23198 12.5514 0.94873 8.5013 0.94873Z"
        fill={fill}
      />
    </svg>
  );
}
