import React from 'react';

export default function MiscellaneousLinkIcon({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.013 1.87751C12.0057 0.918748 13.3352 0.388231 14.7153 0.400223C16.0953 0.412216 17.4154 0.965757 18.3913 1.94163C19.3671 2.9175 19.9207 4.23761 19.9327 5.61765C19.9447 6.99768 19.4142 8.32722 18.4554 9.3199L18.4447 9.33077L15.8131 11.9623C15.2796 12.4961 14.6374 12.9089 13.9303 13.1727C13.2231 13.4365 12.4675 13.5451 11.7147 13.4912C10.9619 13.4373 10.2295 13.2221 9.56717 12.8602C8.90483 12.4983 8.32807 11.9983 7.876 11.3939C7.58581 11.0059 7.66506 10.4562 8.05301 10.166C8.44096 9.87581 8.99071 9.95506 9.2809 10.343C9.58228 10.7459 9.96679 11.0793 10.4083 11.3206C10.8499 11.5618 11.3382 11.7053 11.8401 11.7412C12.3419 11.7771 12.8457 11.7047 13.3171 11.5289C13.7885 11.353 14.2166 11.0778 14.5723 10.722L14.5724 10.7219L17.1984 8.09587C17.8345 7.43465 18.1863 6.55051 18.1783 5.63289C18.1703 4.71287 17.8013 3.83279 17.1507 3.18221C16.5001 2.53163 15.62 2.1626 14.7 2.15461C13.782 2.14663 12.8975 2.49876 12.2362 3.1353L10.7321 4.63064C10.3885 4.97222 9.83308 4.9706 9.4915 4.62703C9.14992 4.28345 9.15154 3.72803 9.49511 3.38645L11.0039 1.88639L11.013 1.87751Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.73551 6.80972C7.44265 6.54593 8.19825 6.43732 8.95107 6.49123C9.70388 6.54515 10.4363 6.76033 11.0986 7.1222C11.761 7.48406 12.3377 7.98413 12.7898 8.5885C13.08 8.97646 13.0007 9.5262 12.6128 9.81639C12.2248 10.1066 11.6751 10.0273 11.3849 9.63938C11.0835 9.23647 10.699 8.90309 10.2574 8.66184C9.81588 8.4206 9.32761 8.27714 8.82574 8.2412C8.32386 8.20526 7.82013 8.27767 7.3487 8.45353C6.87727 8.62938 6.44918 8.90457 6.09346 9.26042L3.46736 11.8865C2.83134 12.5477 2.47952 13.4319 2.4875 14.3495C2.49549 15.2695 2.86452 16.1496 3.5151 16.8002C4.16568 17.4508 5.04575 17.8198 5.96578 17.8278C6.88339 17.8358 7.76752 17.484 8.42875 16.8479L9.92313 15.3536C10.2657 15.011 10.8211 15.011 11.1637 15.3536C11.5063 15.6961 11.5063 16.2516 11.1637 16.5941L9.66366 18.0942L9.65278 18.1049C8.6601 19.0636 7.33057 19.5942 5.95053 19.5822C4.5705 19.5702 3.25038 19.0166 2.27451 18.0408C1.29864 17.0649 0.745101 15.7448 0.733109 14.3648C0.721117 12.9847 1.25163 11.6552 2.2104 10.6625L2.22108 10.6516L4.85265 8.02006C4.85261 8.0201 4.85269 8.02002 4.85265 8.02006C5.38621 7.48634 6.02842 7.07348 6.73551 6.80972Z"
        fill="#DEDEDE"
      />
    </svg>
  );
}
