import React from 'react';
import Masonry from 'react-masonry-css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CompanyLogo from 'app/components/CommonStyled/CompanyLogo';
import { HOME_DRAWER_HASH } from 'app/components/CommonStyled/GlobalHomeDrawers/constants';
import { RelatedCompaniesIcon } from 'app/components/DetailsPages/Company/CompanyDetailsSnapshot';
import Tooltip from 'app/components/Tooltip';
import {
  AbstractIcon,
  BrandAssetsIcon,
  FullBookIcon,
  FullCalendarIcon,
  PaperIcon,
  ResourceIcon,
  RightArrow,
  ThinkPiecesIcon,
} from 'app/components/shared/icons';
import UrlIcon from 'app/components/shared/icons/url-icon';
import { DETAILS_PAGE_ACTION } from 'app/pages/NewDetailsPage/actions';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import useUserData from 'hooks/useUserData';
import { setHomeDrawer } from 'store/reducers/global/global-reducer';
import { setAboutKyuOsOpen } from 'store/reducers/onboarding-reducer';
import { ThemeProvider } from 'styled-components';

import {
  AboutKyuOsWayFindingMegamenuCardIcon,
  AboutKyuOsWayFindingMegamenuCardContainer,
  AboutKyuOsWayFindingMegamenuCardText,
  AboutKyuOsWayfindingMegamenuCardWrapper,
  AboutKyuOsWayfindingMegamenuContentColumn,
  AboutKyuOsWayfindingMegamenuContentColumnContainer,
  AboutKyuOsWayfindingMegamenuSubtitle,
  AboutKyuOsWayfindingMegamenuTitle,
  AboutKyuOsWayfindingMegamenuWrapper,
  AboutKyuOsWayFindingMegamenuCardLeftContainer,
} from './styled';

export default function AboutKyuOsWayfindingMegamenu() {
  const companyClientsClickedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_COMPANY_CLIENTS_CLICKED
  );
  const peopleSectionCtaClickedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_PEOPLE_SECTION_CTA_CLICKED
  );
  const companiesDrawerOpenedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_COMPANIES_DRAWER_OPENED
  );
  const resourcesOpenedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_RESOURCES_OPENED);
  const theWellOpenedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_THE_WELL_OPENED);
  const conveningsOpenedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_CONVENINGS_OPENED);

  const { push } = useHistory();
  const dispatch = useDispatch();
  const userData = useUserData();

  const handleCompanyClientsClick = () => {
    if (userData.kyu_company?.slug === 'kyu') return;

    companyClientsClickedBehavioralFunction({
      related_object_uuid: userData?.kyu_company.slug,
    });
    push(`/member-company/${userData?.kyu_company.slug}`, {
      action: DETAILS_PAGE_ACTION.ACTIVE_CLIENTS_DRAWER_OPEN,
    });

    if (location.pathname.includes('member-company')) {
      dispatch(setAboutKyuOsOpen(false));
    }
  };

  const handleCompanyPeopleClick = () => {
    peopleSectionCtaClickedBehavioralFunction();

    if (
      location.pathname.includes('member-company') ||
      (location.pathname === '/kyu' && userData.kyu_company?.slug === 'kyu')
    ) {
      window.postMessage(DETAILS_PAGE_ACTION.PEOPLE_DRAWER_OPEN, '*');
      dispatch(setAboutKyuOsOpen(false));
    } else {
      if (userData.kyu_company?.slug === 'kyu') {
        push(`/kyu#people-section`, {
          action: DETAILS_PAGE_ACTION.PEOPLE_DRAWER_OPEN,
        });
      } else {
        push(`/member-company/${userData?.kyu_company.slug}#people-section`, {
          action: DETAILS_PAGE_ACTION.PEOPLE_DRAWER_OPEN,
        });
      }
    }
  };

  const handleBrowseCompaniesClick = () => {
    companiesDrawerOpenedBehavioralFunction();
    dispatch(setHomeDrawer({ show: true, model: HOME_DRAWER_HASH.COMPANIES }));
  };

  const handleCompaniesCredentialsClick = () => {
    resourcesOpenedBehavioralFunction({
      action_details: 'Tag Category: Company Credentials',
    });
    push('/resources?tag_category=Company+Credentials');

    if (location.pathname.includes('resources')) {
      dispatch(setAboutKyuOsOpen(false));
    }
  };

  const handleArtificialIntelligenceResourcesClick = () => {
    resourcesOpenedBehavioralFunction({
      action_details: 'Tag Category: Artificial Intelligence',
    });
    push('/resources?tag_category=Artificial+Intelligence');

    if (location.pathname.includes('resources')) {
      dispatch(setAboutKyuOsOpen(false));
    }
  };

  const handleEventRecapResourcesClick = () => {
    resourcesOpenedBehavioralFunction({
      action_details: 'Category: Event Recordings',
    });
    push('/resources?categories=event+recordings');

    if (location.pathname.includes('resources')) {
      dispatch(setAboutKyuOsOpen(false));
    }
  };

  const handleTheWellClick = () => {
    theWellOpenedBehavioralFunction();
    push('/the-well');

    if (location.pathname.includes('the-well')) {
      dispatch(setAboutKyuOsOpen(false));
    }
  };

  const handleEventsClick = () => {
    conveningsOpenedBehavioralFunction();
    push('/#upcoming-events');

    if (location.pathname === '/') {
      dispatch(setAboutKyuOsOpen(false));
    }
  };

  return (
    <AboutKyuOsWayfindingMegamenuWrapper id="get-started">
      <AboutKyuOsWayfindingMegamenuTitle>Get Started</AboutKyuOsWayfindingMegamenuTitle>
      <Masonry
        breakpointCols={{ default: 2, 610: 1 }}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <AboutKyuOsWayfindingMegamenuContentColumn>
          <AboutKyuOsWayfindingMegamenuSubtitle>
            GET TO KNOW YOUR COMPANY
          </AboutKyuOsWayfindingMegamenuSubtitle>
          <AboutKyuOsWayfindingMegamenuContentColumnContainer>
            <AboutKyuOsWayfindingMegamenu.Card
              text="Clients your company works with"
              onClick={handleCompanyClientsClick}
              fromKyu={userData.kyu_company?.slug === 'kyu'}
              tooltipOptions={{
                maxWidth: '203px',
                text: 'kyu doesn’t have clients for you to browse at the moment',
                hide: userData.kyu_company?.slug !== 'kyu',
              }}
            >
              <AboutKyuOsWayFindingMegamenuCardIcon background="var(--color-data-company-background)">
                <CompanyLogo slug={userData?.kyu_company?.slug} size={40} />
              </AboutKyuOsWayFindingMegamenuCardIcon>
            </AboutKyuOsWayfindingMegamenu.Card>
            <AboutKyuOsWayfindingMegamenu.Card
              text="Explore what you share in common with colleagues"
              onClick={handleCompanyPeopleClick}
            >
              <AboutKyuOsWayFindingMegamenuCardIcon background="#625866">
                <AbstractIcon />
              </AboutKyuOsWayFindingMegamenuCardIcon>
            </AboutKyuOsWayfindingMegamenu.Card>
          </AboutKyuOsWayfindingMegamenuContentColumnContainer>
        </AboutKyuOsWayfindingMegamenuContentColumn>
        <AboutKyuOsWayfindingMegamenuContentColumn>
          <AboutKyuOsWayfindingMegamenuSubtitle>
            GET TO KNOW THE COLLECTIVE
          </AboutKyuOsWayfindingMegamenuSubtitle>
          <AboutKyuOsWayfindingMegamenuContentColumnContainer>
            <AboutKyuOsWayfindingMegamenu.Card
              text="Browse other companies’ pages"
              onClick={handleBrowseCompaniesClick}
            >
              <AboutKyuOsWayFindingMegamenuCardIcon background="var(--color-data-company-background)">
                <RelatedCompaniesIcon
                  width={25}
                  height={23}
                  fill="var(--color-data-company-foreground)"
                  fillOpacity={1}
                />
              </AboutKyuOsWayFindingMegamenuCardIcon>
            </AboutKyuOsWayfindingMegamenu.Card>
            <AboutKyuOsWayfindingMegamenu.Card
              text="Learn more about the credentials and services offered by kyu member companies"
              onClick={handleCompaniesCredentialsClick}
            >
              <AboutKyuOsWayFindingMegamenuCardIcon background="var(--color-data-company-foreground)">
                <PaperIcon />
              </AboutKyuOsWayFindingMegamenuCardIcon>
            </AboutKyuOsWayfindingMegamenu.Card>
          </AboutKyuOsWayfindingMegamenuContentColumnContainer>
        </AboutKyuOsWayfindingMegamenuContentColumn>
        <AboutKyuOsWayfindingMegamenuContentColumn>
          <AboutKyuOsWayfindingMegamenuSubtitle>
            DIG INTO RESOURCES
          </AboutKyuOsWayfindingMegamenuSubtitle>
          <AboutKyuOsWayfindingMegamenuContentColumnContainer>
            <AboutKyuOsWayfindingMegamenu.Card
              text="Browse the Artificial Intelligence category"
              onClick={handleArtificialIntelligenceResourcesClick}
            >
              <AboutKyuOsWayFindingMegamenuCardIcon background="#323F5D">
                <BrandAssetsIcon />
              </AboutKyuOsWayFindingMegamenuCardIcon>
            </AboutKyuOsWayfindingMegamenu.Card>
            <AboutKyuOsWayfindingMegamenu.Card
              text="Find event recaps from past kyu events"
              onClick={handleEventRecapResourcesClick}
            >
              <AboutKyuOsWayFindingMegamenuCardIcon background="#323F5D">
                <FullCalendarIcon />
              </AboutKyuOsWayFindingMegamenuCardIcon>
            </AboutKyuOsWayfindingMegamenu.Card>
          </AboutKyuOsWayfindingMegamenuContentColumnContainer>
        </AboutKyuOsWayfindingMegamenuContentColumn>
        <AboutKyuOsWayfindingMegamenuContentColumn>
          <AboutKyuOsWayfindingMegamenuSubtitle>GET INSPIRED</AboutKyuOsWayfindingMegamenuSubtitle>
          <AboutKyuOsWayfindingMegamenuContentColumnContainer>
            <AboutKyuOsWayfindingMegamenu.Card
              text="Read stories from around the Collective on The Well"
              onClick={handleTheWellClick}
            >
              <AboutKyuOsWayFindingMegamenuCardIcon background="#323F5D">
                <FullBookIcon />
              </AboutKyuOsWayFindingMegamenuCardIcon>
            </AboutKyuOsWayfindingMegamenu.Card>
            <AboutKyuOsWayfindingMegamenu.Card
              text="Attend IRL and virtual meetups"
              onClick={handleEventsClick}
            >
              <AboutKyuOsWayFindingMegamenuCardIcon background="rgba(234, 188, 179, 1)">
                <FullCalendarIcon />
              </AboutKyuOsWayFindingMegamenuCardIcon>
            </AboutKyuOsWayfindingMegamenu.Card>
          </AboutKyuOsWayfindingMegamenuContentColumnContainer>
        </AboutKyuOsWayfindingMegamenuContentColumn>
      </Masonry>
    </AboutKyuOsWayfindingMegamenuWrapper>
  );
}

AboutKyuOsWayfindingMegamenu.Card = function AboutKyuOsWayfindingMegamenuCardComponent({
  children,
  text,
  onClick,
  tooltipOptions,
  fromKyu = false,
}: {
  children: React.ReactNode;
  text?: string;
  onClick?: () => void;
  tooltipOptions?: { text: string; hide: boolean; maxWidth: string };
  fromKyu?: boolean;
}) {
  const handleClick = () => {
    onClick?.();
  };

  return (
    <Tooltip
      maxWidth={tooltipOptions?.maxWidth}
      text={tooltipOptions?.text}
      hide={tooltipOptions?.hide}
    >
      <AboutKyuOsWayfindingMegamenuCardWrapper fromKyu={fromKyu} onClick={handleClick}>
        <AboutKyuOsWayFindingMegamenuCardContainer>
          <AboutKyuOsWayFindingMegamenuCardLeftContainer>
            {children}
            <AboutKyuOsWayFindingMegamenuCardText>{text}</AboutKyuOsWayFindingMegamenuCardText>
          </AboutKyuOsWayFindingMegamenuCardLeftContainer>
          <RightArrow width={16} height={16} fill="var(--color-primary)" />
        </AboutKyuOsWayFindingMegamenuCardContainer>
      </AboutKyuOsWayfindingMegamenuCardWrapper>
    </Tooltip>
  );
};
