import React, { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useOnClickOutside from 'hooks/click-outside';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import useUserData from 'hooks/useUserData';
import { updateNavOnboarded } from 'services/user';
import { setIsNavbarMenuOpen } from 'store/reducers/navbar-reducer';
import { setUserData } from 'store/reducers/user-reducer';
import { ThemeContext } from 'styled-components';

import SideDrawer from '../CommonStyled/SideDrawer';
import FeedbackDrawer from '../ContactDrawers/FeedbackDrawer';
import SubmitContentDrawer from '../ContactDrawers/SubmitContentDrawer';
import {
  BookIcon,
  CompassIcon,
  EmailIcon,
  Lookup,
  Map as MapIcon,
  PersonIcon,
  PulseIcon,
  ResourceIcon,
} from '../shared/icons';
import Calendar from '../shared/icons/calendar';
import EditPencilIcon from '../shared/icons/edit-pencil';
import NavTile from './NavTile';
import {
  NavButton,
  NavButtonWrapper,
  NavSectionsButtonsContainer,
  NavSectionsOnboardingCalloutCloseContainer,
  NavSectionsOnboardingCalloutContainer,
  NavSectionsOnboardingCalloutText,
  NavSectionsOnboardingCalloutWrapper,
  NavSectionsOnboardingWrapper,
  NavSectionsOpenContainer,
  NavSectionsOpenExploreWrapper,
  NavSectionsOpenTilesContainer,
  NavSectionsOpenTitle,
  NavSectionsOpenToolsWrapper,
  NavSectionsOpenWaysToGetInvolvedButton,
  NavSectionsOpenWaysToGetInvolvedButtonLabel,
  NavSectionsOpenWaysToGetInvolvedContainer,
  NavSectionsOpenWaysToGetInvolvedWrapper,
  NavSectionsOpenWrapper,
} from './styled';

export const typeToRoute = new Map<string, string>([
  ['passions', '/passion'],
  ['companies', '/member-company'],
  ['member_companies', '/member-company'],
  ['industries', '/industry'],
  ['kyuskills', '/skill'],
  ['kyu_skills', '/skill'],
  ['people', '/person'],
  ['convenings', '/convening'],
  ['projects', '/project'],
  ['tools', '/tool'],
  ['articles', '/articles'],
  ['clients', '/client'],
  ['industries', '/industry'],
  ['communities', '/community'],
  ['resources', '/resources'],
]);

interface IProps {
  mode?: 'dark' | 'light';
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
}

export default function NavSections({ mode, isOpen, setIsOpen }: IProps) {
  const dispatch = useDispatch();
  const userData = useUserData();
  const { push } = useHistory();
  const openMenuRef = useRef<HTMLDivElement>(null);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [showFeedbackDrawer, setShowFeedbackDrawer] = useState<boolean>(false);

  // Behavioral actions
  const aboutKyuOsBehavioral = useBehavioral(BEHAVIORALS.NAV_ABOUT_KYU_OS);
  const networkMapsBehavioral = useBehavioral(BEHAVIORALS.NAV_NETWORK_MAPS);
  const theWellBehavioral = useBehavioral(BEHAVIORALS.NAV_THE_WELL);
  const eventsBehavioral = useBehavioral(BEHAVIORALS.NAV_EVENTS);
  const resourcesBehavioral = useBehavioral(BEHAVIORALS.NAV_RESOURCES);
  const peopleBehavioral = useBehavioral(BEHAVIORALS.NAV_PEOPLE);

  useOnClickOutside(openMenuRef, () => {
    const sideDrawer = document.querySelector('[data-type="sidedrawer"]');
    if (sideDrawer) return;
    if (userData.nav_onboarding) {
      dispatch(setUserData({ ...userData, nav_onboarding: false }));
    }
    setIsClosing(true);
  });

  const updateOnboarding = (includeLocalState = true) => {
    updateNavOnboarded({ status: false });
    if (includeLocalState) {
      dispatch(setUserData({ ...userData, nav_onboarding: false }));
    }
  };

  const handleAnimationEnd = () => {
    if (isClosing) {
      setIsOpen(false);
      setIsClosing(false);

      if (userData.nav_onboarding) {
        dispatch(setUserData({ ...userData, nav_onboarding: false }));
      }
    }
  };

  return (
    <NavSectionsOpenWrapper ref={openMenuRef} onAnimationEnd={handleAnimationEnd}>
      <NavSectionsOpenContainer style={{ width: '100%' }}>
        <NavSectionsOpenTitle>EXPLORE</NavSectionsOpenTitle>
        <NavSectionsOpenExploreWrapper>
          <NavSectionsOpenTilesContainer style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
            <NavTile
              navTile={{
                icon: <CompassIcon />,
                title: 'kyu 101',
                text: 'Dive deeper into how this platform works, where the data comes from and other FAQs.',
                route: { pathname: '/kyu-101' },
              }}
              onClick={() => {
                aboutKyuOsBehavioral();
                dispatch(setIsNavbarMenuOpen(false));
              }}
            />
            <NavTile
              navTile={{
                icon: <PulseIcon />,
                title: 'Pulse',
                text: `Learn more about the Collective's newest joint marketing and experiential initiative.`,
                route: { pathname: '/pulse' },
              }}
              onClick={() => {
                dispatch(setIsNavbarMenuOpen(false));
              }}
            />
          </NavSectionsOpenTilesContainer>
          <NavSectionsOpenTilesContainer>
            <NavTile
              navTile={{
                icon: <MapIcon />,
                title: 'Network Maps',
                text: 'Visually explore connections between the companies by industries and clients, skills and passions.',
                route: { pathname: '/collective/clients-industries/map' },
              }}
              onClick={() => {
                networkMapsBehavioral();
                dispatch(setIsNavbarMenuOpen(false));
              }}
            />
            <NavTile
              navTile={{
                icon: <BookIcon />,
                title: 'The Well',
                text: 'The Well is where you can find stories from around the collective about our work.',
                route: { pathname: '/the-well' },
              }}
              onClick={() => {
                theWellBehavioral();
                dispatch(setIsNavbarMenuOpen(false));
              }}
            />
            <NavTile
              navTile={{
                icon: <Calendar />,
                title: 'Events',
                text: 'Ways to engage and connect with others in the Collective.',
                route: { pathname: '/', hash: 'upcoming-events' },
              }}
              onClick={() => {
                eventsBehavioral();
                dispatch(setIsNavbarMenuOpen(false));
              }}
            />
          </NavSectionsOpenTilesContainer>
        </NavSectionsOpenExploreWrapper>
      </NavSectionsOpenContainer>
      <NavSectionsOpenContainer>
        <NavSectionsOpenTitle>TOOLS</NavSectionsOpenTitle>
        <NavSectionsOpenToolsWrapper>
          <NavTile
            navTile={{
              icon: <ResourceIcon />,
              title: 'Resource Library',
              text: 'Explore the kyu knowledge base.',
              route: { pathname: '/resources' },
            }}
            onClick={() => {
              resourcesBehavioral();
              dispatch(setIsNavbarMenuOpen(false));
            }}
          />
          <NavTile
            navTile={{
              icon: <PersonIcon />,
              title: 'Collective Directory',
              text: 'Find and contact anyone in the Collective.',
              route: { pathname: '/people' },
            }}
            onClick={() => {
              peopleBehavioral();
              dispatch(setIsNavbarMenuOpen(false));
            }}
          />
        </NavSectionsOpenToolsWrapper>
      </NavSectionsOpenContainer>
      <NavSectionsOpenContainer>
        <NavSectionsOpenTitle>QUICK LINKS</NavSectionsOpenTitle>
        <NavSectionsOpenWaysToGetInvolvedWrapper>
          <NavSectionsOpenWaysToGetInvolvedContainer>
            <NavSectionsOpenWaysToGetInvolvedButton
              onClick={(e) => {
                e.preventDefault();
                push('/my-profile/edit');
                dispatch(setIsNavbarMenuOpen(false));
              }}
            >
              <EditPencilIcon width={21} height={21} fill="var(--color-primary)" />
              <NavSectionsOpenWaysToGetInvolvedButtonLabel>
                EDIT PROFILE
              </NavSectionsOpenWaysToGetInvolvedButtonLabel>
            </NavSectionsOpenWaysToGetInvolvedButton>
            <NavSectionsOpenWaysToGetInvolvedButton
              onClick={() => {
                setShowFeedbackDrawer(true);
              }}
              stroke
            >
              <EmailIcon width={18} height={15} fill="var(--color-primary)" />
              <NavSectionsOpenWaysToGetInvolvedButtonLabel>
                CONTACT KYU
              </NavSectionsOpenWaysToGetInvolvedButtonLabel>
            </NavSectionsOpenWaysToGetInvolvedButton>
          </NavSectionsOpenWaysToGetInvolvedContainer>
          {showFeedbackDrawer ? (
            <SideDrawer closeDrawer={() => setShowFeedbackDrawer(false)} withClose>
              <FeedbackDrawer />
            </SideDrawer>
          ) : null}
        </NavSectionsOpenWaysToGetInvolvedWrapper>
      </NavSectionsOpenContainer>
    </NavSectionsOpenWrapper>
  );
}

interface NavSectionsCTAProps {
  mode: 'dark' | 'light';
  isOpen: boolean;
  toggleNav: () => void;
  updateOnboarding: (includeLocalState?: boolean) => void;
  hideOnboarding: boolean;
  setHideOnboarding: (hideOnboarding: boolean) => void;
}

function NavSectionsCTA({
  mode,
  isOpen,
  toggleNav,
  updateOnboarding,
  hideOnboarding,
  setHideOnboarding,
}: NavSectionsCTAProps) {
  const theme = useContext(ThemeContext);
  const userData = useUserData();

  const handleCTAClick = () => {
    if (userData.nav_onboarding && !hideOnboarding) {
      setHideOnboarding(true);
      updateOnboarding(false);
    }

    toggleNav();
  };

  const handleCloseClick = () => {
    setHideOnboarding(true);
    updateOnboarding();
  };

  return (
    <NavSectionsButtonsContainer>
      <NavButtonWrapper>
        <NavButton tabIndex={0} mode={mode} onClick={handleCTAClick} isOpen={isOpen}>
          <Lookup width={16} height={16} fill={theme.color} />
          <span>Jump to</span>
        </NavButton>
        {userData.nav_onboarding && !hideOnboarding ? <NavSectionsOnboardingWrapper /> : null}
      </NavButtonWrapper>
      {userData.nav_onboarding && !hideOnboarding ? (
        <NavSectionsOnboardingCalloutWrapper>
          <NavSectionsOnboardingCalloutContainer>
            <NavSectionsOnboardingCalloutText>
              Give yourself a quick tour around kyu OS.
            </NavSectionsOnboardingCalloutText>
          </NavSectionsOnboardingCalloutContainer>
          <NavSectionsOnboardingCalloutCloseContainer onClick={handleCloseClick}>
            <NavSectionsOnboardingCalloutClose />
          </NavSectionsOnboardingCalloutCloseContainer>
        </NavSectionsOnboardingCalloutWrapper>
      ) : null}
    </NavSectionsButtonsContainer>
  );
}

export function NavSectionsOnboardingCalloutClose() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <g filter="url(#filter0_b_30614_37765)">
        <circle cx="12" cy="12.5234" r="9" fill="#EB823D" fill-opacity="0.3" />
        <circle cx="12" cy="12.5234" r="10" stroke="#FFA366" stroke-width="2" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5359 16.0593C15.9265 15.6687 15.9265 15.0356 15.5359 14.6451L13.4146 12.5237L15.5358 10.4024C15.9264 10.0119 15.9264 9.37875 15.5358 8.98823C15.1453 8.5977 14.5121 8.59771 14.1216 8.98823L12.0004 11.1095L9.87907 8.98821C9.48855 8.59768 8.85539 8.59768 8.46486 8.98821C8.07434 9.37873 8.07434 10.0119 8.46486 10.4024L10.5861 12.5237L8.46477 14.6451C8.07424 15.0356 8.07424 15.6688 8.46477 16.0593C8.85529 16.4498 9.48846 16.4498 9.87898 16.0593L12.0004 13.9379L14.1217 16.0593C14.5122 16.4498 15.1454 16.4498 15.5359 16.0593Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_30614_37765"
          x="-35"
          y="-34.4766"
          width="94"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="18" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_30614_37765"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_30614_37765"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
