import styled from 'styled-components';
import { infiniteRotationAnimation } from 'styles/animations';

export const ProfileEditorDrawerCompaniesNewCompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :first-child {
    margin-bottom: 32px;
  }

  & > :nth-child(2) {
    margin-bottom: 24px;
  }

  & > :nth-child(3) {
    margin-bottom: 32px;
  }

  textarea[name='description'] {
    height: 75px;
  }

  label {
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    color: #000;
  }

  margin-top: 32px;
`;

export const ProfileEditorDrawerCompaniesNewCompanyTitle = styled.h6`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-text);
`;

interface ProfileEditorDrawerCompaniesNewCompanyButtonProps {
  disabled: boolean;
}

export const ProfileEditorDrawerCompaniesNewCompanyButton = styled.button<ProfileEditorDrawerCompaniesNewCompanyButtonProps>`
  width: fit-content;

  display: inline-flex;
  align-items: flex-start;
  padding: 13px 24px;

  border-radius: 32px;
  background: ${({ disabled }) =>
    disabled ? `var(--color-data-alumni-background)` : `var(--color-primary)`};
  & > svg {
    ${infiniteRotationAnimation('300ms', 'ease')}
  }
`;

export const ProfileEditorDrawerCompaniesNewCompanyButtonTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: #ffffff;
`;
