import React, { useEffect, useState } from "react";

export default function useDistances(
  containerRef?: React.RefObject<HTMLDivElement>
) {
  const [currentLeft, setCurrentLeft] = useState<number>(0);
  const [currentTop, setCurrentTop] = useState<number>(0);

  const updateCurrentDistances = () => {
    const scrollTop = containerRef
      ? containerRef.current.scrollTop
      : document.body.scrollTop || document.documentElement.scrollTop;
    setCurrentTop(scrollTop);

    const scrollLeft = containerRef
      ? containerRef.current.scrollLeft
      : document.body.scrollLeft || document.documentElement.scrollLeft;
    setCurrentLeft(scrollLeft);
  };

  useEffect(() => {
    if (containerRef) {
      containerRef.current.addEventListener("scroll", updateCurrentDistances);
      return () =>
        containerRef.current.removeEventListener(
          "scroll",
          updateCurrentDistances
        );
    }
    document.addEventListener("scroll", updateCurrentDistances);
    return () => document.removeEventListener("scroll", updateCurrentDistances);
  }, [containerRef]);

  return { currentLeft, currentTop };
}
