import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const HomeSpotlightsFade = styled.div`
  width: 766px;
  height: 896px;

  position: absolute;
  top: 0;
  right: 0;

  background: radial-gradient(ellipse,rgba(255,255,255,1) 25%,rgba(255,255,255,0) 379px);
  transform: translate(50%, -25%);
  z-index: 2;

  margin-bottom: 0px !important;
`;

export const HomeSpotlightsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 32px;
  }

  ${BREAKPOINTS.ultra_large} {
    ${HomeSpotlightsFade} {
      display: none;
    }
  }

  position: relative;
`;

interface HomeSpotlightsContainerProps {
  leftPadding: number;
}

export const HomeSpotlightsContainer = styled.div<HomeSpotlightsContainerProps>`
  display: flex;
  flex-direction: row;

  padding: 0px 50px 32px ${({ leftPadding }) => leftPadding}px;

  overflow-x: auto;

  & > :not(:last-child) {
    margin-right: 32px;

    ${BREAKPOINTS.tablet} {
      margin-right: 24px;
    }

    ${BREAKPOINTS.mobile} {
      margin-right: 16px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const HomeSpotlightsTitle = styled.h2`
  color: var(--color-text);
  font-weight: 400;
  font-size: 38px;
  line-height: 42px;

  margin: 0 auto;
  max-width: 1264px;
  width: 100%;

  ${BREAKPOINTS.large_tablet_landscape} {
    max-width: 70vw;
  }

  ${BREAKPOINTS.tablet} {
    font-size: 32px;
    font-weight: 300;
    margin-left: 48px;
  }

  ${BREAKPOINTS.mobile} {
    margin-left: 24px;
  }
`;

// SKELETON
export const HomeSpotlightsSkeletonCard = styled.div`
  min-width: 240px;
  height: 420px;
  border-radius: 32px;
  background: #F0F0F0;
`;
