import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { CloseIcon, DeleteIcon, WarningIcon } from 'app/components/shared/icons';
import { useFormikContext } from 'formik';
import useOnClickOutside from 'hooks/click-outside';
import { setIsDrawerOpen } from 'store/reducers/navbar-reducer';
import { currentPageSelector } from 'store/reducers/profile-editor/profile-editor-reducer';
import { ThemeProvider } from 'styled-components';

import {
  ProfileEditorDrawerBottomWrapper,
  ProfileEditorDrawerContentWrapper,
  ProfileEditorDrawerHeaderCloseIconContainer,
  ProfileEditorDrawerHeaderWrapper,
  ProfileEditorDrawerOverlay,
  ProfileEditorDrawerPublishButton,
  ProfileEditorDrawerWarningBottom,
  ProfileEditorDrawerWarningCenterContainer,
  ProfileEditorDrawerWarningContainer,
  ProfileEditorDrawerWarningContentWrapper,
  ProfileEditorDrawerWarningDescription,
  ProfileEditorDrawerWarningDiscardButton,
  ProfileEditorDrawerWarningIconContainer,
  ProfileEditorDrawerWarningSubtitle,
  ProfileEditorDrawerWarningTitle,
  ProfileEditorDrawerWarningTitleContainer,
  ProfileEditorDrawerWarningWrapper,
  ProfileEditorDrawerWrapper,
} from './styled';
import { theme } from './theme';

interface ProfileEditorDrawerProps {
  onClose: () => void;
  children: React.ReactNode;
  header: JSX.Element;
  bottom?: JSX.Element;
  needsSaving?: boolean;
  disableSubmit?: boolean;
  alwaysShowScroll?: boolean;
}

export interface ProfileEditorDrawerRefProps {
  handleMutation: {
    onSuccess: () => void;
  };
  close: () => void;
}

const ProfileEditorDrawer = React.forwardRef<ProfileEditorDrawerRefProps, ProfileEditorDrawerProps>(
  (
    {
      onClose,
      children,
      header,
      bottom,
      needsSaving = false,
      disableSubmit = false,
      alwaysShowScroll = false,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const currentPage = useSelector(currentPageSelector);
    const [needsSavingWarning, setNeedsSavingWarning] = useState<boolean>(false);
    const [triggerClosing, setTriggerClosing] = useState<boolean>(false);
    const [bottomHeight, setBottomHeight] = useState<number>(0);
    const [topHeight, setTopHeight] = useState<number>(0);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const profileEditorDrawerRef = useRef<HTMLDivElement>(null);
    const profileEditorDrawerBottomRef = useCallback((node: HTMLDivElement) => {
      if (node) {
        setBottomHeight(node.getBoundingClientRect().height);
      }
    }, []);
    const profileEditorDrawerTopRef = useCallback((node: HTMLDivElement) => {
      if (node) {
        setTopHeight(node.getBoundingClientRect().height);
      }
    }, []);

    const handleClose = ({ ignoreSaving = false } = {}) => {
      if (needsSaving && !ignoreSaving) {
        return setNeedsSavingWarning(true);
      }
      setTriggerClosing(true);
    };

    const handleAnimationEnd = () => {
      if (triggerClosing) {
        dispatch(setIsDrawerOpen(false));
        if (onClose) {
          window.postMessage('profile_updated', '*');
          onClose();
        }
      }
    };

    useImperativeHandle(ref, () => ({
      handleMutation: {
        onMutate: () => {
          setIsSubmitting(true);
        },
        onSuccess: () => {
          setIsSubmitting(false);
          handleClose({ ignoreSaving: true });
        },
      },
      close: () => {
        handleClose({ ignoreSaving: true });
      },
    }));

    useEffect(() => {
      dispatch(setIsDrawerOpen(true));
    }, []);

    useOnClickOutside(profileEditorDrawerRef, () => handleClose());

    return ReactDOM.createPortal(
      <ThemeProvider theme={theme[currentPage]}>
        <ProfileEditorDrawerOverlay triggerClosing={triggerClosing} />
        {needsSavingWarning && (
          <ProfileEditorDrawerWarning
            triggerClosing={triggerClosing}
            setNeedsSavingWarning={setNeedsSavingWarning}
            onClose={() => handleClose({ ignoreSaving: true })}
          />
        )}
        <ProfileEditorDrawerWrapper
          ref={profileEditorDrawerRef}
          triggerClosing={triggerClosing}
          onAnimationEnd={handleAnimationEnd}
          data-type="sidedrawer"
        >
          <ProfileEditorDrawerHeaderWrapper ref={profileEditorDrawerTopRef}>
            <ProfileEditorDrawerHeaderCloseIconContainer onClick={() => handleClose()}>
              <CloseIcon width={12} height={12} fill={'rgba(23, 28, 51, 0.5)'} />
            </ProfileEditorDrawerHeaderCloseIconContainer>
            {header}
          </ProfileEditorDrawerHeaderWrapper>
          <ProfileEditorDrawerContentWrapper
            bottomHeight={bottomHeight}
            topHeight={topHeight}
            alwaysShowScroll={alwaysShowScroll}
          >
            {children}
          </ProfileEditorDrawerContentWrapper>
          {!needsSavingWarning ? (
            <ProfileEditorDrawerBottomWrapper ref={profileEditorDrawerBottomRef}>
              {bottom ? (
                bottom
              ) : (
                <ProfileEditorDrawerDefaultBottom disableSubmit={isSubmitting || disableSubmit} />
              )}
            </ProfileEditorDrawerBottomWrapper>
          ) : null}
        </ProfileEditorDrawerWrapper>
      </ThemeProvider>,
      document.querySelector('#root')
    );
  }
);

function ProfileEditorDrawerDefaultBottom({ disableSubmit = false }: { disableSubmit?: boolean }) {
  const { isValid, submitForm } = useFormikContext();

  return (
    <ProfileEditorDrawerPublishButton
      disabled={!isValid || disableSubmit}
      type="submit"
      onClick={submitForm}
    >
      Publish
    </ProfileEditorDrawerPublishButton>
  );
}

function ProfileEditorDrawerWarning({
  setNeedsSavingWarning,
  onClose,
  triggerClosing,
}: {
  setNeedsSavingWarning: React.Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  triggerClosing: boolean;
}) {
  const { isValid, isSubmitting, submitForm } = useFormikContext();
  const [closingWarning, setClosingWarning] = useState<boolean>(false);
  const [opening, setOpening] = useState<boolean>(true);

  const handleOpening = () => {
    if (!triggerClosing) {
      setOpening(false);
    }
  };

  const handleClose = () => {
    setClosingWarning(true);
  };

  const handleDiscard = () => {
    onClose();
  };

  const handleClosingWarning = () => {
    if (closingWarning) {
      setNeedsSavingWarning(false);
    }
  };

  return (
    <ProfileEditorDrawerWarningWrapper
      triggerClosing={triggerClosing}
      onAnimationEnd={handleOpening}
    >
      <ProfileEditorDrawerWarningContentWrapper>
        <ProfileEditorDrawerWarningContainer
          closingWarning={closingWarning}
          onAnimationEnd={handleClosingWarning}
        >
          <ProfileEditorDrawerHeaderCloseIconContainer onClick={handleClose}>
            <CloseIcon width={10} height={10} fill={'rgba(23, 28, 51, 0.5)'} />
          </ProfileEditorDrawerHeaderCloseIconContainer>
          <ProfileEditorDrawerWarningCenterContainer>
            <ProfileEditorDrawerWarningTitleContainer>
              <ProfileEditorDrawerWarningIconContainer>
                <WarningIcon width={44} height={38} />
              </ProfileEditorDrawerWarningIconContainer>
              <ProfileEditorDrawerWarningTitle>Leaving so soon?</ProfileEditorDrawerWarningTitle>
            </ProfileEditorDrawerWarningTitleContainer>
            <ProfileEditorDrawerWarningSubtitle>
              Don’t lose your progress!
            </ProfileEditorDrawerWarningSubtitle>
            <ProfileEditorDrawerWarningDescription>
              Publish your content to preserve it on your profile page.
            </ProfileEditorDrawerWarningDescription>
          </ProfileEditorDrawerWarningCenterContainer>
        </ProfileEditorDrawerWarningContainer>
        <ProfileEditorDrawerWarningBottom closingWarning={closingWarning} opening={opening}>
          <ProfileEditorDrawerWarningDiscardButton onClick={handleDiscard}>
            {!opening && !closingWarning ? (
              <>
                <DeleteIcon />
                <span>Discard Changes</span>
              </>
            ) : null}
          </ProfileEditorDrawerWarningDiscardButton>
          <ProfileEditorDrawerPublishButton
            disabled={!isValid || isSubmitting}
            type="submit"
            onClick={submitForm}
          >
            Publish
          </ProfileEditorDrawerPublishButton>
        </ProfileEditorDrawerWarningBottom>
      </ProfileEditorDrawerWarningContentWrapper>
    </ProfileEditorDrawerWarningWrapper>
  );
}

export default ProfileEditorDrawer;
