import React from 'react';

export default function CollapsibleOpenedIcon({ width = 60, height = 60, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.3639 30.1287C41.3678 30.7767 40.8415 31.3029 40.1936 31.299L20.247 31.1783C19.6101 31.1744 19.0947 30.659 19.0909 30.0221C19.0869 29.3741 19.6132 28.8479 20.2611 28.8518L40.2077 28.9725C40.8446 28.9764 41.36 29.4918 41.3639 30.1287Z"
        fill={fill}
      />
    </svg>
  );
}
