import React, { createContext } from 'react';
import { UseQueryResult } from 'react-query';

import { ICurrentPassion, OnboardingResponse } from './interfaces';

export interface OnboardingInfo {
  currentStep: number;
  currentPassion: ICurrentPassion | null;
  hideControls: { previous: boolean; next: boolean };
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setHideControls: React.Dispatch<React.SetStateAction<{ previous: boolean; next: boolean }>>;
  setCurrentPassion: React.Dispatch<React.SetStateAction<ICurrentPassion | null>>;
  onboardingQuery: UseQueryResult<OnboardingResponse, Error>;
}

export const OnboardingContext = createContext<OnboardingInfo>({
  currentStep: 1,
  currentPassion: null,
  hideControls: { previous: false, next: false },
  setCurrentStep: () => {},
  setHideControls: () => {},
  setCurrentPassion: () => {},
  onboardingQuery: null,
});
