import React from 'react';

export default function AddedFriendIcon({ width = 20, height = 20, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.58333 5.83366C4.58333 4.45295 5.70262 3.33366 7.08333 3.33366C8.46405 3.33366 9.58333 4.45295 9.58333 5.83366C9.58333 7.21437 8.46405 8.33366 7.08333 8.33366C5.70262 8.33366 4.58333 7.21437 4.58333 5.83366ZM7.08333 1.66699C4.78215 1.66699 2.91667 3.53247 2.91667 5.83366C2.91667 8.13485 4.78215 10.0003 7.08333 10.0003C9.38452 10.0003 11.25 8.13485 11.25 5.83366C11.25 3.53247 9.38452 1.66699 7.08333 1.66699ZM4.16667 11.667C3.0616 11.667 2.00179 12.106 1.22039 12.8874C0.438987 13.6688 0 14.7286 0 15.8337V17.5003C0 17.9606 0.373096 18.3337 0.833333 18.3337C1.29357 18.3337 1.66667 17.9606 1.66667 17.5003V15.8337C1.66667 15.1706 1.93006 14.5347 2.3989 14.0659C2.86774 13.5971 3.50363 13.3337 4.16667 13.3337H10C10.663 13.3337 11.2989 13.5971 11.7678 14.0659C12.2366 14.5347 12.5 15.1706 12.5 15.8337V17.5003C12.5 17.9606 12.8731 18.3337 13.3333 18.3337C13.7936 18.3337 14.1667 17.9606 14.1667 17.5003V15.8337C14.1667 14.7286 13.7277 13.6688 12.9463 12.8874C12.1649 12.106 11.1051 11.667 10 11.667H4.16667Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9144 7.6136C18.0251 7.7289 18.0251 7.91097 17.9144 8.02627L15.0748 10.9846C14.9199 11.146 14.6617 11.146 14.5068 10.9846L13.0301 9.44613C12.9194 9.33084 12.9194 9.14876 13.0301 9.03346C13.1474 8.91129 13.3427 8.91129 13.46 9.03346L14.7908 10.4199L17.4845 7.6136C17.6017 7.49143 17.7971 7.49143 17.9144 7.6136Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
    </svg>
  );
}
