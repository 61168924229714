import React, { useEffect } from 'react';
import { useMutation } from 'react-query';

import CompanyLogo from 'app/components/CommonStyled/CompanyLogo';
import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import { CompanyIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { AddIcon, PersonIcon, SearchIcon, Spinner } from 'app/components/shared/icons';
import CheckIcon from 'app/components/shared/icons/check-icon';
import useIsMobile from 'hooks/useIsMobile';
import { addPassion } from 'services/onboarding';

import useOnboardingControls from '../../hooks/useOnboardingControls';
import {
  OnboardingStepFiveInformationContainer,
  OnboardingStepFiveInformationDescription,
  OnboardingStepFiveInformationIconContainer,
  OnboardingStepFiveInformationTitle,
  OnboardingStepFiveInformationWrapper,
  OnboardingStepFiveMoreInformationContainer,
  OnboardingStepFiveMoreInformationText,
  OnboardingStepFivePeopleAddPassionButton,
  OnboardingStepFivePeopleAddPassionButtonText,
  OnboardingStepFivePeopleGrid,
  OnboardingStepFivePeopleHint,
  OnboardingStepFivePeoplePassionName,
  OnboardingStepFivePeoplePassionTrend,
  OnboardingStepFivePeopleTopColumn,
  OnboardingStepFivePeopleTopRow,
  OnboardingStepFivePeopleWrapper,
  OnboardingStepFivePersonContainer,
  OnboardingStepFivePersonInformationContainer,
  OnboardingStepFivePersonName,
  OnboardingStepFivePersonRole,
  OnboardingStepFivePersonRoleContainer,
  OnboardingStepFiveWrapper,
} from './styled';

export default function OnboardingStepFive() {
  return (
    <OnboardingStepFiveWrapper>
      <OnboardingStepFiveInformation />
      <OnboardingStepFivePeople />
    </OnboardingStepFiveWrapper>
  );
}

function OnboardingStepFiveInformation() {
  return (
    <OnboardingStepFiveInformationWrapper>
      <OnboardingStepFiveInformationTitle>Connections found!</OnboardingStepFiveInformationTitle>
      <OnboardingStepFiveInformationContainer>
        <OnboardingStepFiveInformationIconContainer>
          <CompanyIcon width={16} height={16} fill={'#5478A1'} />
        </OnboardingStepFiveInformationIconContainer>
        <OnboardingStepFiveInformationDescription>
          These passions can connect you to folks from other companies.
        </OnboardingStepFiveInformationDescription>
      </OnboardingStepFiveInformationContainer>
      <OnboardingStepFiveInformationContainer>
        <OnboardingStepFiveInformationIconContainer>
          <SearchIcon width={16} height={16} fill={'#1F2025'} />
        </OnboardingStepFiveInformationIconContainer>
        <OnboardingStepFiveInformationDescription>
          These topics are also searchable - where you can find related projects,
          events and communities.
        </OnboardingStepFiveInformationDescription>
      </OnboardingStepFiveInformationContainer>
      <OnboardingStepFiveInformationContainer>
        <OnboardingStepFiveInformationIconContainer>
          <PersonIcon width={16} height={16} fill={'#FFC229'} />
        </OnboardingStepFiveInformationIconContainer>
        <OnboardingStepFiveInformationDescription>
          When you build out your profile, you’re set up to get the most out of this network.
        </OnboardingStepFiveInformationDescription>
      </OnboardingStepFiveInformationContainer>
    </OnboardingStepFiveInformationWrapper>
  );
}

function OnboardingStepFivePeople() {
  const isMobile = useIsMobile();
  const { currentPassion } = useOnboardingControls();

  const handlePostPassion = async () => {
    const response = await addPassion(currentPassion.uuid);
    if (!response.ok) {
      throw new Error(response.originalError?.message);
    }

    return response.data;
  };

  const { mutate, isSuccess, isLoading, isIdle } = useMutation(handlePostPassion);

  const handleButtonClick = () => {
    if (isSuccess || currentPassion.shared) return;
    mutate();
  };

  useEffect(() => {
    if (isSuccess) {
      window.postMessage('profile_updated', '*');
    }
  }, [isSuccess]);

  return (
    <OnboardingStepFivePeopleWrapper>
      <OnboardingStepFivePeopleTopRow>
        <OnboardingStepFivePeopleTopColumn>
          <OnboardingStepFivePeopleHint>Meet people who care about</OnboardingStepFivePeopleHint>
          <OnboardingStepFivePeoplePassionName>
            {currentPassion.name}
          </OnboardingStepFivePeoplePassionName>
        </OnboardingStepFivePeopleTopColumn>
        {/*
        {currentPassion.search.times !== 0 ? (
          <OnboardingStepFivePeopleTopColumn>
            <OnboardingStepFivePeoplePassionTrend>
              <OnboardingStepFivePeoplePassionTrend className="highlight">
                {currentPassion.name}
              </OnboardingStepFivePeoplePassionTrend>{' '}
              has been searched {currentPassion.search.times}{' '}
              {currentPassion.search.times !== 1 ? 'times' : 'time'} by people in{' '}
              {currentPassion.search.companies_count}{' '}
              {currentPassion.search.companies_count !== 1 ? 'companies' : 'company'}
            </OnboardingStepFivePeoplePassionTrend>
          </OnboardingStepFivePeopleTopColumn>
        ) : null}
        */}
      </OnboardingStepFivePeopleTopRow>
      <OnboardingStepFivePeopleGrid>
        {currentPassion.people.detail.slice(0, isMobile ? 4 : 8).map((person) => (
          <OnboardingStepFivePersonContainer key={person.uuid}>
            <PersonAvatar
              size={40}
              avatar={person.profile_image}
              name={person.first_name}
              lastName={person.last_name}
              highlighted
            />
            <OnboardingStepFivePersonInformationContainer>
              <OnboardingStepFivePersonName>{person.full_name}</OnboardingStepFivePersonName>
              <OnboardingStepFivePersonRoleContainer>
                <OnboardingStepFivePersonRole>{person.role_name} at</OnboardingStepFivePersonRole>
                <CompanyLogo size={20} slug={person.company?.slug} useRounded />
                <OnboardingStepFivePersonRole>{person.company?.name}</OnboardingStepFivePersonRole>
              </OnboardingStepFivePersonRoleContainer>
            </OnboardingStepFivePersonInformationContainer>
          </OnboardingStepFivePersonContainer>
        ))}
        {currentPassion.people.remaining_count > 0 && currentPassion.people.companies_count > 0 ? (
          <OnboardingStepFiveMoreInformationContainer>
            <OnboardingStepFiveMoreInformationText>
              And{' '}
              <OnboardingStepFiveMoreInformationText className="highlight">
                {currentPassion.people.remaining_count} other{' '}
                {currentPassion.people.remaining_count !== 1 ? 'people' : 'person'}
              </OnboardingStepFiveMoreInformationText>{' '}
              from {currentPassion.people.companies_count}{' '}
              {currentPassion.people.companies_count !== 1 ? 'companies' : 'company'}
            </OnboardingStepFiveMoreInformationText>
          </OnboardingStepFiveMoreInformationContainer>
        ) : null}
      </OnboardingStepFivePeopleGrid>
      <OnboardingStepFivePeopleAddPassionButton onClick={handleButtonClick}>
        {isIdle ? (
          <>
            {currentPassion.shared ? (
              <>
                <CheckIcon
                  width={22}
                  height={22}
                  fill="var(--color-primary)"
                  background={'rgba(0, 0, 0, 0)'}
                />
                <OnboardingStepFivePeopleAddPassionButtonText>
                  {currentPassion.name} was already part of your profile
                </OnboardingStepFivePeopleAddPassionButtonText>
              </>
            ) : (
              <>
                <AddIcon width={22} height={22} fill="var(--color-primary)" />
                <OnboardingStepFivePeopleAddPassionButtonText>
                  Add{' '}
                  <OnboardingStepFivePeopleAddPassionButtonText className="highlight">
                    {currentPassion.name}
                  </OnboardingStepFivePeopleAddPassionButtonText>{' '}
                  to my profile
                </OnboardingStepFivePeopleAddPassionButtonText>
              </>
            )}
          </>
        ) : null}
        {isLoading ? <Spinner /> : null}
        {isSuccess ? (
          <>
            <CheckIcon
              width={22}
              height={22}
              fill="var(--color-primary)"
              background={'rgba(0, 0, 0, 0)'}
            />
            <OnboardingStepFivePeopleAddPassionButtonText>
              Added to your profile
            </OnboardingStepFivePeopleAddPassionButtonText>
          </>
        ) : null}
      </OnboardingStepFivePeopleAddPassionButton>
    </OnboardingStepFivePeopleWrapper>
  );
}
