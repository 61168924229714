export const theme = {
  light: {
    primaryBackground: 'linear-gradient(180deg, rgba(255, 255, 255, 0.162) 17%, rgba(255, 194, 41, 0.159) 100%), #FFFFFF',
    primaryText: 'var(--color-primary)',
    secondaryText: 'rgba(23, 28, 51, 0.5)',
  },
  dark: {
    primaryBackground: '#1C223F',
    primaryText: '#FFFFFF',
    secondaryText: 'rgba(255, 255, 255, 0.5)',
  }
}