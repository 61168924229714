import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';

import { LoadingComponent } from 'app/components';
import NavBar from 'app/components/NavBar';
import Onboarding from 'app/components/Onboarding';
import AboutKyuOsOnboarding from 'app/components/Onboarding/AboutKyuOs';
import ResourceSubmissionHandler from 'app/components/ResourceSubmissionHandler';
import ResourceVotingHandler from 'app/components/ResourceVotingHandler';
import ResourcesSubmissionSuccessPage from 'app/pages/ResourcesSubmissionSuccessPage';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import useScrollToTop from 'hooks/useScrollToTop';
import useUserData from 'hooks/useUserData';
import { clearToken } from 'services/storage';
import {
  lastHistoryEntrySelector,
  pushHistoryEntry,
  setHistory,
} from 'store/reducers/history/history-reducer';
import { isDrawerOpenSelector, setIsDrawerOpen } from 'store/reducers/navbar-reducer';
import {
  aboutKyuOsHiddenSelector,
  isOnboardingOpenSelector,
  setAboutKyuOsHidden,
  setAboutKyuOsOpen,
} from 'store/reducers/onboarding-reducer';
import { expirationTimeSelector, profileCompletionSelector } from 'store/reducers/user-reducer';
import { detailsPages, mapModels, newDetailsPages } from 'utils/constants';

import PrivateRoute from './PrivateRoute';

const TheWellReportPage = React.lazy(() => import('app/pages/TheWellReport'));
const Map = React.lazy(() => import('app/components/Map'));
const AbstractMap = React.lazy(() => import('app/components/Map/Abstract/AbstractMap'));
const AboutKyuOsPage = React.lazy(() => import('app/pages/AboutKyuOsPage'));
const Articles = React.lazy(() => import('app/pages/Articles'));
const ArticlesIndexPage = React.lazy(() => import('app/pages/ArticlesIndex'));
const AuthPage = React.lazy(() => import('app/pages/AuthPage'));
const BlogPostPage = React.lazy(() => import('app/pages/BlogPostPage'));
const CollectionDetailsPage = React.lazy(() => import('app/pages/CollectionDetailsPage'));
const CollectivePage = React.lazy(() => import('app/pages/CollectivePage'));
const CommunitiesPage = React.lazy(() => import('app/pages/CommunitiesPage'));
const CompanyResourcePage = React.lazy(() => import('app/pages/CompanyResource'));
const DetailsPage = React.lazy(() => import('app/pages/DetailsPage'));
const FinancialReportPage = React.lazy(() => import('app/pages/FinancialReport'));
const Home = React.lazy(() => import('app/pages/Home'));
const InfoPage = React.lazy(() => import('app/pages/InfoPage'));
const KinListingPage = React.lazy(() => import('app/pages/KinListingPage'));
const KinPage = React.lazy(() => import('app/pages/KinPage'));
const MicroCommunityPage = React.lazy(() => import('app/pages/MicroCommunityPage'));
const MyProfile = React.lazy(() => import('app/pages/MyProfile'));
const NewDetailsPage = React.lazy(() => import('app/pages/NewDetailsPage'));
const NotFoundPage = React.lazy(() => import('app/pages/NotFoundPage'));
const ProfileEditor = React.lazy(() => import('app/pages/ProfileEditor'));
const ReportsPage = React.lazy(() => import('app/pages/Reports'));
const ResourcesPage = React.lazy(() => import('app/pages/Resources'));
const ResourcesDetailPage = React.lazy(() => import('app/pages/ResourcesDetailPage'));
const SearchPage = React.lazy(() => import('app/pages/Search'));
const TheWellIndex = React.lazy(() => import('app/pages/TheWell'));
const Tools = React.lazy(() => import('app/pages/Tools'));
const ToolsIndexPage = React.lazy(() => import('app/pages/Tools/ToolsIndexPage'));
const PeoplePage = React.lazy(() => import('app/pages/PeoplePage'));
const TeamingPage = React.lazy(() => import('app/pages/TeamingPage'));
const KyuPulsePage = React.lazy(() => import('app/pages/KyuPulsePage'));
const ResourcesSubmissionPage = React.lazy(() => import('app/pages/ResourcesSubmissionPage'));
const ResourceVotingPage = React.lazy(() => import('app/pages/ResourceVotingPage'));

export default function Routes() {
  useScrollToTop();
  const userData = useUserData();
  const expirationTime = useSelector(expirationTimeSelector) as number;
  const { isLoggedIn } = useIsLoggedIn();
  const { push, go, location } = useHistory();
  const dispatch = useDispatch();
  const lastHistoryEntry = useSelector(lastHistoryEntrySelector);
  const isDrawerOpen = useSelector(isDrawerOpenSelector);
  const [showAboutKyuOsOnboarding, setShowAboutKyuOsOnboarding] = useState<boolean>(false);
  const aboutKyuOsOnboardingHidden = useSelector(aboutKyuOsHiddenSelector);
  const profileCompletion: number = useSelector(profileCompletionSelector);
  const isOnboardingOpen = useSelector(isOnboardingOpenSelector);

  const handleSessionTimeout = () => {
    clearToken();
    push({ pathname: location.pathname, state: { logged_out: true } });
    go(0);
  };

  useEffect(() => {
    let timeoutId = null;
    if (expirationTime && isLoggedIn) {
      timeoutId = setTimeout(handleSessionTimeout, expirationTime * 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [expirationTime, isLoggedIn]);

  const dispatchDrawerOpen = (state: boolean) => {
    dispatch(setIsDrawerOpen(state));
  };

  useEffect(() => {
    if (isDrawerOpen) {
      dispatchDrawerOpen(false);
    }
  }, [location]);

  useEffect(() => {
    if (!lastHistoryEntry) {
      dispatch(setHistory([location]));
    }

    if (lastHistoryEntry && lastHistoryEntry.pathname !== location.pathname) {
      dispatch(pushHistoryEntry(location));
    }
  }, [location, lastHistoryEntry]);

  const showOnboardedProfile = useMemo(() => {
    return !userData.onboarding_profile && profileCompletion >= 50;
  }, [profileCompletion, userData]);

  useEffect(() => {
    let timeoutId = null;

    if (
      Object.keys(userData).length > 0 &&
      !userData.onboarded &&
      location.pathname !== '/kyu-101' &&
      !userData.featured_banner
    ) {
      timeoutId = setTimeout(() => {
        dispatch(setAboutKyuOsHidden(false));
        setShowAboutKyuOsOnboarding(true);
        if (!userData.onboarding_started) {
          dispatch(setAboutKyuOsOpen(true));
        }
      }, 2000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [userData]);

  return (
    <Suspense fallback={<LoadingComponent idParticles="graph-page-particles" messages={[]} />}>
      {isLoggedIn ? <NavBar /> : null}
      {isLoggedIn ? <ResourceSubmissionHandler /> : null}
      {isLoggedIn ? <ResourceVotingHandler /> : null}
      {isLoggedIn && !aboutKyuOsOnboardingHidden && showAboutKyuOsOnboarding ? (
        <AboutKyuOsOnboarding />
      ) : null}
      {isLoggedIn && showOnboardedProfile && isOnboardingOpen ? <Onboarding /> : null}
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <PrivateRoute exact path="/my-profile">
          <MyProfile />
        </PrivateRoute>
        <PrivateRoute exact path="/my-profile/edit">
          <ProfileEditor />
        </PrivateRoute>
        <Route exact path="/auth">
          <AuthPage />
        </Route>
        <PrivateRoute exact path="/search">
          <SearchPage />
        </PrivateRoute>
        <PrivateRoute exact path="/info-page">
          <InfoPage />
        </PrivateRoute>
        <PrivateRoute path={`/:model(${mapModels.join('|')})/:slug/map/:type?`}>
          <Map />
        </PrivateRoute>
        <PrivateRoute exact path={`/best-of-2024-vote`}>
          <ResourceVotingPage />
        </PrivateRoute>
        <PrivateRoute exact path={`/best-of-2024-entry`}>
          <ResourcesSubmissionPage />
        </PrivateRoute>
        <PrivateRoute exact path={`/resources/:slug`}>
          <ResourcesDetailPage />
        </PrivateRoute>
        <PrivateRoute exact path={`/resources/:slug/embed`}>
          <CompanyResourcePage />
        </PrivateRoute>
        <PrivateRoute path={`/resources`}>
          <ResourcesPage />
        </PrivateRoute>
        <PrivateRoute path={`/people`}>
          <PeoplePage />
        </PrivateRoute>
        <PrivateRoute exact path="/teaming">
          <TeamingPage />
        </PrivateRoute>
        <PrivateRoute path={`/:model(${detailsPages.join('|')})/:slug`}>
          <DetailsPage />
        </PrivateRoute>
        <PrivateRoute path={`/:model(${newDetailsPages.join('|')})/:slug`}>
          <NewDetailsPage />
        </PrivateRoute>
        <PrivateRoute exact path="/kin">
          <KinPage />
        </PrivateRoute>
        <PrivateRoute path="/kin/:slug">
          <KinListingPage />
        </PrivateRoute>
        <PrivateRoute path="/convening/:slug">
          <KinListingPage onlyConvening />
        </PrivateRoute>
        <PrivateRoute exact path="/communities">
          <CommunitiesPage />
        </PrivateRoute>
        <PrivateRoute exact path="/community/health-and-well-being/firing-up-project-spark">
          <BlogPostPage />
        </PrivateRoute>
        <PrivateRoute path="/community/:communitySlug/:slug">
          <KinListingPage fromCommunity />
        </PrivateRoute>
        <PrivateRoute path="/community/:slug">
          <MicroCommunityPage />
        </PrivateRoute>
        <PrivateRoute exact path="/pulse/:section(clients|resources)?">
          <KyuPulsePage />
        </PrivateRoute>
        <PrivateRoute exact path="/kyu">
          <NewDetailsPage attributes={{ model: 'member-company', slug: 'kyu' }} />
        </PrivateRoute>
        <PrivateRoute exact path="/kyu-101">
          <AboutKyuOsPage />
        </PrivateRoute>
        <PrivateRoute exact path="/reports">
          <ReportsPage />
        </PrivateRoute>
        <PrivateRoute exact path="/financial-report">
          <FinancialReportPage />
        </PrivateRoute>
        <PrivateRoute exact path="/the-well-report">
          <TheWellReportPage />
        </PrivateRoute>
        <Route exact path="/collective">
          <CollectivePage />
        </Route>
        <PrivateRoute exact path="/the-well">
          <TheWellIndex />
        </PrivateRoute>
        <PrivateRoute exact path="/collection/:slug">
          <CollectionDetailsPage />
        </PrivateRoute>
        <PrivateRoute exact path="/tools">
          <ToolsIndexPage />
        </PrivateRoute>
        <PrivateRoute exact path="/tools/:slug">
          <Tools />
        </PrivateRoute>
        <PrivateRoute exact path="/articles">
          <ArticlesIndexPage />
        </PrivateRoute>
        <PrivateRoute exact path="/articles/:slug">
          <Articles />
        </PrivateRoute>
        <PrivateRoute exact path="/network-map/:slug">
          <AbstractMap slug={location.pathname.split('/')[2]} />
        </PrivateRoute>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </Suspense>
  );
}
