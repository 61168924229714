import React from 'react';

export default function Megaphone({ width = 30, height = 30, fill = '#FFFFFF' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6662 8.33339C25.6662 1.22672 25.8662 1.08005 25.0529 0.546719C24.2396 0.0133852 24.4396 0.160052 12.0529 5.66672H6.99955C5.34628 5.66506 3.75137 6.27776 2.52444 7.38588C1.29751 8.49401 0.526091 10.0185 0.359939 11.6634C0.193788 13.3083 0.644758 14.9562 1.6253 16.2873C2.60585 17.6185 4.04602 18.5377 5.66622 18.8667V25.6667C5.66622 26.7276 6.08765 27.745 6.83779 28.4951C7.58794 29.2453 8.60535 29.6667 9.66622 29.6667C10.7271 29.6667 11.7445 29.2453 12.4946 28.4951C13.2448 27.745 13.6662 26.7276 13.6662 25.6667V19.7201C24.5996 24.5734 24.2262 24.6534 25.0529 24.1201C25.8796 23.5867 25.6662 23.5067 25.6662 16.3334C26.7271 16.3334 27.7445 15.912 28.4946 15.1618C29.2448 14.4117 29.6662 13.3943 29.6662 12.3334C29.6662 11.2725 29.2448 10.2551 28.4946 9.50496C27.7445 8.75481 26.7271 8.33339 25.6662 8.33339ZM2.99955 12.3334C2.99955 11.2725 3.42098 10.2551 4.17113 9.50496C4.92127 8.75481 5.93869 8.33339 6.99955 8.33339H10.9996V16.3334H6.99955C5.93869 16.3334 4.92127 15.912 4.17113 15.1618C3.42098 14.4117 2.99955 13.3943 2.99955 12.3334ZM10.9996 25.6667C10.9996 26.0203 10.8591 26.3595 10.609 26.6095C10.359 26.8596 10.0198 27.0001 9.66622 27.0001C9.3126 27.0001 8.97346 26.8596 8.72341 26.6095C8.47336 26.3595 8.33289 26.0203 8.33289 25.6667V19.0001H10.9996V25.6667ZM22.9996 20.9467L13.6662 16.8001V7.86672L22.9996 3.72005V20.9467ZM25.6662 13.6667V11.0001C26.0198 11.0001 26.359 11.1405 26.609 11.3906C26.8591 11.6406 26.9996 11.9798 26.9996 12.3334C26.9996 12.687 26.8591 13.0261 26.609 13.2762C26.359 13.5262 26.0198 13.6667 25.6662 13.6667Z"
        fill={fill}
      />
    </svg>
  );
}
