import React from 'react';

export default function AlarmClock({ width = 18, height = 18, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_25269_2796"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_25269_2796)">
        <path
          d="M8.21562 12.4125L12.4531 8.17495L11.3844 7.1062L8.21562 10.275L6.62187 8.6812L5.55313 9.74995L8.21562 12.4125ZM9.00312 16.5C8.06562 16.5 7.18762 16.322 6.36912 15.966C5.55013 15.6095 4.83763 15.128 4.23163 14.5215C3.62513 13.9155 3.14363 13.203 2.78712 12.384C2.43113 11.5655 2.25312 10.6875 2.25312 9.74995C2.25312 8.81245 2.43113 7.9342 2.78712 7.1152C3.14363 6.2967 3.62513 5.5842 4.23163 4.9777C4.83763 4.3717 5.55013 3.8907 6.36912 3.5347C7.18762 3.1782 8.06562 2.99995 9.00312 2.99995C9.94062 2.99995 10.8189 3.1782 11.6379 3.5347C12.4564 3.8907 13.1689 4.3717 13.7754 4.9777C14.3814 5.5842 14.8626 6.2967 15.2191 7.1152C15.5751 7.9342 15.7531 8.81245 15.7531 9.74995C15.7531 10.6875 15.5751 11.5655 15.2191 12.384C14.8626 13.203 14.3814 13.9155 13.7754 14.5215C13.1689 15.128 12.4564 15.6095 11.6379 15.966C10.8189 16.322 9.94062 16.5 9.00312 16.5ZM4.20312 1.76245L5.25313 2.81245L2.06562 5.99995L1.01562 4.94995L4.20312 1.76245ZM13.8031 1.76245L16.9906 4.94995L15.9406 5.99995L12.7531 2.81245L13.8031 1.76245ZM9.00312 15C10.4656 15 11.7061 14.4905 12.7246 13.4715C13.7436 12.453 14.2531 11.2125 14.2531 9.74995C14.2531 8.28745 13.7436 7.0467 12.7246 6.0277C11.7061 5.0092 10.4656 4.49995 9.00312 4.49995C7.54062 4.49995 6.30013 5.0092 5.28163 6.0277C4.26263 7.0467 3.75313 8.28745 3.75313 9.74995C3.75313 11.2125 4.26263 12.453 5.28163 13.4715C6.30013 14.4905 7.54062 15 9.00312 15Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
