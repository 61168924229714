import React, { createContext } from 'react';

export interface HomeRadarActiveWorkInfo {
  limit?: number | null;
  clientComponent?: React.ReactNode;
  industryComponent?: React.ReactNode;
}

export const HomeRadarActiveWorkContext = createContext<HomeRadarActiveWorkInfo>({
  limit: null,
  clientComponent: null,
  industryComponent: null,
});
