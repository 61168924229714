import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { sendMapOpenBehavioral, typeLabel } from 'utils';
import { CtaToMap, CtaToMapItem, CtaToMapIcons, CtaDataWrapper } from './styled';
import ExploreBgSvg from './svgs/explore-bg';
import ExploreTextSvg from './svgs/explore-text';
import { newModelsToOld } from 'utils/constants';
import { DetailsPageContext } from '../DetailsPages/shared/DetailsPageContext';
import { useSelector } from 'react-redux';
import { mapCTAUrlSelector, showMapCTASelector } from 'store/reducers/map/map-reducers';
import { behavioralIdSelector } from 'store/reducers/user-reducer';

export default function MapCTA() {
  const history = useHistory();
  const location = useLocation();
  const showCTA = useSelector(showMapCTASelector);
  const mapCTAUrl = useSelector(mapCTAUrlSelector);
  const behavioralId = useSelector(behavioralIdSelector);
  const ctx = useContext(DetailsPageContext);
  const { model } = ctx;

  const [showMapCTA, setShowMapCTA] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname.startsWith('/person')) return setShowMapCTA(false);
    if (location.state !== 'isErrorScreen') return setShowMapCTA(true);
    return setShowMapCTA(false);
  }, [location.state, location.pathname]);

  const handleMapCTA = () => {
    if (behavioralId) {
      sendMapOpenBehavioral(behavioralId);
    }

    if (mapCTAUrl) {
      history.push(mapCTAUrl);
    } else {
      history.push(`${location.pathname}/map`);
    }
  };

  const renderIconsCta = () => {
    return (
      <>
        {ctx.model && ctx.model === 'client' ? (
          <img src={`/images/node-icon/companies.svg`} alt={`${model}`} />
        ) : (
          <img src={`/images/node-icon/${newModelsToOld[ctx.model]}.svg`} alt={`${ctx.model}`} />
        )}
      </>
    );
  };

  return showMapCTA && showCTA ? (
    <>
      <CtaToMap onClick={handleMapCTA}>
        <ExploreBgSvg />
        <ExploreTextSvg />
        <CtaToMapItem className="cta-item" model={model} onClick={handleMapCTA}>
          <CtaToMapIcons>{renderIconsCta()}</CtaToMapIcons>
          <CtaDataWrapper>
            <h4>{typeLabel[model]}</h4>
            <h3>{ctx.data.name}</h3>
          </CtaDataWrapper>
        </CtaToMapItem>
      </CtaToMap>
    </>
  ) : null;
}
