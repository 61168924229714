import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

const backgroundByType = {
  'skills-passions': 'rgba(255, 163, 102, 0.4)',
  'people-group': 'rgba(70, 65, 61, 1)',
  client: 'rgba(49, 66, 85, 1)',
  industry: '#3C474A',
  'member-company': 'rgba(50, 63, 93, 1)',
  passion: 'rgba(69, 58, 74, 1)',
  person: 'rgba(70, 65, 61, 1)',
  project: 'rgba(51, 48, 92, 1)',
  skill: '#46383E',
  discipline: '#323F5D',
};

const colorsByType = {
  'skills-passions': '#FFF',
  'people-group': '#FFF',
  client: '#FFF',
  industry: '#8DA76C',
  'member-company': '#FFF',
  passion: '#FBAFA1',
  person: '#FFF',
  project: '#FFF',
  skill: '#FFA366',
  discipline: '#FFF',
};

export const CtaToMap = styled.button`
  background: #222a4d;
  border-radius: 100%;
  height: 270px;
  position: fixed;
  right: -135px;
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  width: 270px;
  z-index: 100;
  ${BREAKPOINTS.tablet} {
    display: none;
  }
  .explore-text {
    position: absolute;
    left: 3px;
    top: 50%;
    width: 58px;
    transition: all 0.3s ease;
    transform: translate(0, -50%) rotate(0deg);
  }
  .explore-bg {
    width: 90%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
    object-fit: contain;
  }
  &:hover {
    right: -15px;
    .explore-text {
      left: 50%;
      transform: translate(-50%, -95%) rotate(90deg);
    }
    > button.cta-item {
      transform: translateX(-30px);
    }
  }
`;

interface CtaToMapItemProps {
  model?: string;
}

export const CtaToMapItem = styled.button<CtaToMapItemProps>`
  align-items: center;
  backdrop-filter: blur(50px);
  background: #3c474a;
  border-radius: 100px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  height: 66px;
  padding: 8px 32px 8px 11px;
  position: absolute;
  right: 0;
  top: calc(50% - 33px);
  transition: all 0.3s ease;
  transform: translateX(100%) translateX(-203px);
  h4 {
    text-align: left;
    font-size: 14px;
    color: #8da76c;
    font-weight: 500;
    opacity: 0.5;
    text-transform: uppercase;
    white-space: nowrap;
  }
  img {
    width: 50px;
  }
  h3 {
    font-size: 28px;
    color: #8da76c;
    text-align: left;
    white-space: nowrap;
  }
  ${({ model }) =>
    model &&
    `
    background: ${backgroundByType[model]};
    h4, h3 {
      color: ${colorsByType[model]};
    }
  `}
`;

export const CtaToMapIcons = styled.div`
  display: flex;
  img {
    margin-right: -6px;
  }
`;

export const CtaDataWrapper = styled.div`
  margin-left: 12px;
`;
