import React from 'react';
import {
  CompanyDetailsSnapshotRelatedCompaniesContainer,
  CompanyDetailsSnapshotRelatedCompaniesText,
  CompanyDetailsSnapshotRelatedCompaniesWrapper,
  CompanyDetailsSnapshotRelatedCompany,
  CompanyDetailsSnapshotText,
  CompanyDetailsSnapshotTextWrapper,
  CompanyDetailsSnapshotWrapper,
} from './styled';

export default function CompanyDetailsSnapshotSkeleton() {
  return (
    <CompanyDetailsSnapshotWrapper style={{marginTop: '60px'}}>
      <CompanyDetailsSnapshotTextWrapper>
        <CompanyDetailsSnapshotText width={461} />
        <CompanyDetailsSnapshotText width={403} />
        <CompanyDetailsSnapshotText width={495} />
      </CompanyDetailsSnapshotTextWrapper>
      <CompanyDetailsSnapshotRelatedCompaniesWrapper>
        <CompanyDetailsSnapshotRelatedCompaniesText />
        <CompanyDetailsSnapshotRelatedCompaniesContainer>
          <CompanyDetailsSnapshotRelatedCompany />
          <CompanyDetailsSnapshotRelatedCompany />
          <CompanyDetailsSnapshotRelatedCompany />
          <CompanyDetailsSnapshotRelatedCompany />
        </CompanyDetailsSnapshotRelatedCompaniesContainer>
      </CompanyDetailsSnapshotRelatedCompaniesWrapper>
    </CompanyDetailsSnapshotWrapper>
  );
}
