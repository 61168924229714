import React from 'react';

import { MouseIcon } from 'app/components/shared/icons';

import { AboutKyuOsKeepScrollingText, AboutKyuOsKeepScrollingWrapper } from './styled';

export default function AboutKyuOsKeepScrolling() {
  return (
    <AboutKyuOsKeepScrollingWrapper>
      <AboutKyuOsKeepScrollingText>KEEP SCROLLING</AboutKyuOsKeepScrollingText>
      <MouseIcon />
    </AboutKyuOsKeepScrollingWrapper>
  );
}
