import styled from "styled-components";
import { BREAKPOINTS } from "styles/media";
import { EditorialP } from "../../styled";

interface PublicationTextProps {
  fontSize?: number;
  lineHeight?: string;
}

export const PublicationDefaultDescription = styled(EditorialP)`
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: rgba(23, 28, 51, 0.5);
  word-break: break-word;

  p {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    word-break: inherit;
  }

  ${BREAKPOINTS.mobile} {
    font-size: 16px;
    line-height: 20px;
  }
`;