import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const SearchIndexPillsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;

  & > * {
    transition: 250ms all ease;
  }

  &:hover {
    & > div {
      opacity: 0.5;
    }
  }

  &:hover > *:hover {
    opacity: 1;
  }
`;
