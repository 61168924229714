import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const NavbarSpotlightWrapper = styled.div`
  padding: 24px 0px;

  border-radius: 0px 0px 48px 48px;
  background: linear-gradient(
    98deg,
    rgb(191, 199, 207) 13.18%,
    rgb(220, 222, 224) 37.23%,
    rgb(242, 242, 243) 52.23%,
    rgb(194, 198, 204) 87.97%
  );

  & > :first-child {
    position: relative;

    padding: 0px 24px;
  }

  & div {
    box-shadow: none;
  }

  ${BREAKPOINTS.tablet} {
    border-radius: 0px;
  }

  user-select: none;
  position: relative;
`;

//CTA
export const NavbarSpotlightCTAWrapper = styled.div`
  height: 37px;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0px 16px;

  gap: 8px;

  border-radius: 8px;
  &:hover {
    background: rgba(23, 28, 51, 0.1);
  }

  cursor: pointer;
  user-select: none;
`;

export const NavbarSpotlightCTATitleContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const NavbarSpotlightCTATitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;

  color: rgba(23, 28, 51, 0.5);
`;

export const NavbarSpotlightCTADescription = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: var(--color-text);
`;

export const NavbarSpotlightCTAIconContainer = styled.div`
  position: relative;
`;

export const NavbarSpotlightCTAIconBeacon = styled.div`
  width: 6px;
  height: 6px;

  position: absolute;
  top: -2px;
  right: -2px;

  border-radius: 100%;
  background: rgba(235, 61, 61, 1);
`;
