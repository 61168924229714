import React from 'react';

export default function Lookup({ width = 22, height = 22, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9ZM9.75 15.7088V13.5C9.75 13.0858 9.41421 12.75 9 12.75C8.58579 12.75 8.25 13.0858 8.25 13.5V15.7088C5.12129 15.3629 2.63706 12.8787 2.2912 9.75H4.5C4.91421 9.75 5.25 9.41421 5.25 9C5.25 8.58579 4.91421 8.25 4.5 8.25H2.2912C2.63706 5.12129 5.12129 2.63706 8.25 2.2912V4.5C8.25 4.91421 8.58579 5.25 9 5.25C9.41421 5.25 9.75 4.91421 9.75 4.5V2.2912C12.8787 2.63706 15.3629 5.12129 15.7088 8.25H13.5C13.0858 8.25 12.75 8.58579 12.75 9C12.75 9.41421 13.0858 9.75 13.5 9.75H15.7088C15.3629 12.8787 12.8787 15.3629 9.75 15.7088Z"
        fill={fill}
      />
    </svg>
  );
}
