import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getSpotlight } from 'services/app';

export default function useHomeSpotlights(
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `home-spotlights`,
    async () => {
      const response = await getSpotlight();
      if (!response.ok) {
        throw new Error(response.originalError?.message);
      }

      return response.data;
    },
    {
      ...options,
      cacheTime: 600000,
      staleTime: Infinity,
    }
  );
}
