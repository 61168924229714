import React from 'react';

export default function PaywallIcon({ width = 11, height = 10, fill = '#5478A1' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.66667 0C5.89679 0 6.08333 0.186548 6.08333 0.416667V9.58333C6.08333 9.81345 5.89679 10 5.66667 10C5.43655 10 5.25 9.81345 5.25 9.58333V0.416667C5.25 0.186548 5.43655 0 5.66667 0Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.29917 2.21519C3.65081 1.86356 4.12772 1.66602 4.625 1.66602H7.75C7.98012 1.66602 8.16667 1.85256 8.16667 2.08268C8.16667 2.3128 7.98012 2.49935 7.75 2.49935H4.625C4.34873 2.49935 4.08378 2.6091 3.88843 2.80445C3.69308 2.9998 3.58333 3.26475 3.58333 3.54102C3.58333 3.81728 3.69308 4.08223 3.88843 4.27759C4.08378 4.47294 4.34873 4.58268 4.625 4.58268H6.70833C7.20561 4.58268 7.68253 4.78023 8.03416 5.13186C8.38579 5.48349 8.58333 5.9604 8.58333 6.45768C8.58333 6.95496 8.38579 7.43188 8.03416 7.78351C7.68253 8.13514 7.20561 8.33268 6.70833 8.33268H3.16667C2.93655 8.33268 2.75 8.14613 2.75 7.91602C2.75 7.6859 2.93655 7.49935 3.16667 7.49935H6.70833C6.9846 7.49935 7.24955 7.3896 7.4449 7.19425C7.64025 6.9989 7.75 6.73395 7.75 6.45768C7.75 6.18141 7.64025 5.91646 7.4449 5.72111C7.24955 5.52576 6.9846 5.41602 6.70833 5.41602H4.625C4.12772 5.41602 3.65081 5.21847 3.29917 4.86684C2.94754 4.51521 2.75 4.0383 2.75 3.54102C2.75 3.04373 2.94754 2.56682 3.29917 2.21519Z"
        fill={fill}
      />
    </svg>
  );
}
