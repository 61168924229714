import React from 'react';
import { useSelector } from 'react-redux';

import CollectiveCompanies from 'app/components/CommonStyled/CollectiveCompanies';
import useOnboardingData from 'app/components/Onboarding/hooks/useOnboardingData';
import { KyuTeam } from 'app/components/Onboarding/steps/Two';
import useIsMobile from 'hooks/useIsMobile';
import { searchLensesSelector } from 'store/reducers/user-reducer';

import AboutKyuOsExecutiveCommittee from './AboutKyuOsExecutiveCommittee';
import {
  AboutKyuOsIntroductionKyuTeamContainer,
  AboutKyuOsIntroductionKyuTeamDescription,
  AboutKyuOsIntroductionText,
  AboutKyuOsIntroductionWrapper,
} from './styled';

export default function AboutKyuOsIntroduction() {
  const query = useOnboardingData();
  const isMobile = useIsMobile();
  const searchLenses: any = useSelector(searchLensesSelector);

  return (
    <AboutKyuOsIntroductionWrapper>
      <AboutKyuOsIntroductionText>
        The kyu Collective currently connects {searchLenses?.members} best-in-industry companies
        that offer services in design, strategy, advertising, web development, data science, talent
        recruitment, and public affairs.
      </AboutKyuOsIntroductionText>
      <CollectiveCompanies openDrawer />
      <AboutKyuOsIntroductionText>
        Within the Collective, there are lots of opportunities for growing your network, tapping
        into great resources and collaborating on exciting community and client initiatives.
      </AboutKyuOsIntroductionText>
      <AboutKyuOsIntroductionKyuTeamContainer>
        <AboutKyuOsExecutiveCommittee />
      </AboutKyuOsIntroductionKyuTeamContainer>
      <AboutKyuOsIntroductionKyuTeamContainer
        style={isMobile ? { padding: '48px 0px 80px 0px' } : {}}
      >
        <KyuTeam query={query} />
        <AboutKyuOsIntroductionKyuTeamDescription>
          There is a small kyu team that operates mainly out of New York and London and manages this
          platform
        </AboutKyuOsIntroductionKyuTeamDescription>
      </AboutKyuOsIntroductionKyuTeamContainer>
      <AboutKyuOsIntroductionText>
        Whether you’ve recently joined or you’ve been here for awhile, there’s a lot to uncover
        about the power of the kyu Collective.
      </AboutKyuOsIntroductionText>
    </AboutKyuOsIntroductionWrapper>
  );
}
