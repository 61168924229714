import React, { useEffect, useMemo, useRef } from 'react';

import CircularProgress from 'app/components/CommonStyled/CircularProgress';
import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import useUserData from 'hooks/useUserData';
import { ThemeProvider } from 'styled-components';

import useMyProfileCompletion from '../../hooks/useMyProfileCompletion';
import {
  MyProfileHeroCircularProgressAvatarContainer,
  MyProfileHeroCircularProgressBackground,
  MyProfileHeroCircularProgressContainer,
  MyProfileHeroCircularProgressNumber,
} from './styled';

interface MyProfileHeroProgressProps {
  circularProgressSize?: number;
  avatarSize?: number;
}

export default function MyProfileHeroProgress({
  circularProgressSize = 227,
  avatarSize = 91,
}: MyProfileHeroProgressProps) {
  const userData = useUserData();
  const { data, refetch } = useMyProfileCompletion();

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'profile_updated') {
        refetch();
      }
    });

    return () => {
      window.removeEventListener('message', (event) => {
        if (event.data === 'profile_updated') {
          refetch();
        }
      });
    };
  }, []);

  const percentageOfTotal = useMemo(() => {
    if (data?.person.completion_percentage) {
      return (data?.person.completion_percentage * 360) / 100;
    }

    return 0;
  }, [data?.person.completion_percentage]);

  return (
    <ThemeProvider theme={{ circularProgressSize }}>
      <MyProfileHeroCircularProgressContainer>
        <MyProfileHeroCircularProgressAvatarContainer degrees={percentageOfTotal}>
          <PersonAvatar
            name={userData.first_name}
            lastName={userData.last_name}
            avatar={userData.profile_image}
            size={avatarSize}
          />
        </MyProfileHeroCircularProgressAvatarContainer>
        <CircularProgress
          percents={data?.person.completion_percentage || 0}
          size={circularProgressSize}
          background={'transparent'}
          stroke="rgba(156, 202, 255, 1)"
          strokeWidth={15}
        />
        <MyProfileHeroCircularProgressNumber>
          {data?.person.completion_percentage || 0}%
        </MyProfileHeroCircularProgressNumber>
        <MyProfileHeroCircularProgressBackground />
      </MyProfileHeroCircularProgressContainer>
    </ThemeProvider>
  );
}
