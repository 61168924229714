import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BookIcon,
  CommunityIcon,
  InfoIcon,
  PulseIcon,
  ResourceIcon,
  SearchIcon,
  Sparkle,
} from 'app/components/shared/icons';
import Calendar from 'app/components/shared/icons/calendar';
import useBehavioral from 'hooks/useBehavioral';
import { setFocusSearch, setIsNavbarMenuOpen } from 'store/reducers/navbar-reducer';
import { searchHighlightsSelector } from 'store/reducers/user-reducer';

import { typeToRoute } from '../../NavSections';
import NavTile from '../../NavTile';
import MobileMenuSearch from '../MobileMenuSearch';
import {
  MobileMenuTilesContainer,
  MobileMenuTilesContent,
  MobileMenuTilesTitle,
  MobileMenuTilesWrapper,
} from './styled';

export default function MobileMenuTiles() {
  const dispatch = useDispatch();
  const highlightedSearches = useSelector(searchHighlightsSelector);
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const aboutKyuOsBehavioral = useBehavioral('NAV_ABOUT_KYU_OS');
  const theWellBehavioral = useBehavioral('NAV_THE_WELL');
  const communitiesBehavioral = useBehavioral('NAV_COMMUNITIES');
  const resourcesBehavioral = useBehavioral('NAV_RESOURCES');
  const eventsBehavioral = useBehavioral('NAV_EVENTS');

  const handleSearchClick = () => {
    if (location.pathname != '/') {
      setShowSearch(true);
    } else {
      dispatch(setFocusSearch(true));
      dispatch(setIsNavbarMenuOpen(false));
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <MobileMenuTilesWrapper>
      <MobileMenuTilesContent>
        <MobileMenuTilesTitle>EXPLORE</MobileMenuTilesTitle>
        <MobileMenuTilesContainer>
          <NavTile
            navTile={{
              icon: <InfoIcon />,
              title: 'kyu 101',
              text: 'Dive deeper into how this platform works, where the data comes from and other FAQs.',
              route: { pathname: '/kyu-101' },
            }}
            onClick={() => {
              aboutKyuOsBehavioral();
              dispatch(setIsNavbarMenuOpen(false));
            }}
          />
          <NavTile
            navTile={{
              icon: <PulseIcon />,
              title: 'Pulse',
              text: `Learn more about the Collective's newest joint marketing and experiential initiative.`,
              route: { pathname: '/pulse' },
            }}
            onClick={() => {
              dispatch(setIsNavbarMenuOpen(false));
            }}
          />

          <NavTile
            navTile={{
              icon: <CommunityIcon />,
              title: 'Communities',
              text: 'Ways to engage and connect with others in the Collective.',
              route: { pathname: '/communities' },
            }}
            onClick={() => {
              communitiesBehavioral();
              dispatch(setIsNavbarMenuOpen(false));
            }}
          />
          <NavTile
            navTile={{
              icon: <Calendar />,
              title: 'Events',
              text: 'Ways to engage and connect with others in the Collective.',
              route: { pathname: '/communities', hash: 'upcoming-events' },
            }}
            onClick={() => {
              eventsBehavioral();
              dispatch(setIsNavbarMenuOpen(false));
            }}
          />
          <NavTile
            navTile={{
              icon: <BookIcon />,
              title: 'The Well',
              text: 'The Well is where you can find stories from around the collective about our work.',
              route: { pathname: '/the-well' },
            }}
            onClick={() => {
              theWellBehavioral();
              dispatch(setIsNavbarMenuOpen(false));
            }}
          />
          <NavTile
            navTile={{
              icon: <SearchIcon />,
              title: 'Search',
              text: 'Explore the Collective’s growing, interactive knowledge base',
            }}
            onClick={handleSearchClick}
          />
        </MobileMenuTilesContainer>
      </MobileMenuTilesContent>
      <MobileMenuTilesContent>
        <MobileMenuTilesTitle>TOOLS</MobileMenuTilesTitle>
        <MobileMenuTilesContainer>
          <NavTile
            navTile={{
              icon: <ResourceIcon />,
              title: 'Resource Library',
              text: 'Explore the kyu knowledge base.',
              route: { pathname: '/resources' },
            }}
            onClick={() => {
              resourcesBehavioral();
              dispatch(setIsNavbarMenuOpen(false));
            }}
          />
          <NavTile
            navTile={{
              icon: <ResourceIcon />,
              title: 'Collective Directory',
              text: 'Find and contact anyone in the Collective',
              route: { pathname: '/people' },
            }}
            onClick={() => {
              resourcesBehavioral();
              dispatch(setIsNavbarMenuOpen(false));
            }}
          />
        </MobileMenuTilesContainer>
      </MobileMenuTilesContent>
      <MobileMenuTilesContainer>
        {showSearch && (
          <MobileMenuSearch
            onClose={() => setShowSearch(false)}
            closeMenu={() => dispatch(setIsNavbarMenuOpen(false))}
          />
        )}
      </MobileMenuTilesContainer>
    </MobileMenuTilesWrapper>
  );
}
