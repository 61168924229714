import styled from 'styled-components';

export const LocationsDrawerContainer = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.162) 17%, rgba(255, 194, 41, 0.159) 100%), #FFFFFF;
  padding: 32px;

  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 64px;
  }
`;

export const LocationsDrawerTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const LocationsDrawerTitle = styled.h3`
  font-weight: 300;
  font-size: 32px;
  line-height: 42px;
  color: #171C33;
`;

export const LocationsDrawerDescription = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #171C33;
`;

export const LocationsDrawerListContainer = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const LocationDrawerListItem = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #171C33;
`;
