import React, { useEffect, useMemo, useRef, useState } from 'react';

import { SortingIcon } from 'app/components/shared/icons';
import useOnClickOutside from 'hooks/click-outside';

import { SelectCurrentValue, SelectLabel, SelectOptionsContainer, SelectWrapper } from './styled';

interface SelectProps {
  label?: string;
  children?: JSX.Element[];
  onOptionChange?: (option: ISelectItem<any>) => void;
  value?: any;
}

export interface ISelectItem<T> {
  value: T;
  label: string;
}

export default function Select({ label, children, onOptionChange, value }: SelectProps) {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [currentOption, setCurrentOption] = useState<ISelectItem<any>>(null);
  const optionsContainer = useRef<HTMLDivElement>(null);

  useOnClickOutside(optionsContainer, () => {
    if (showOptions) setShowOptions(false);
  });

  const defaultSelectedChild = useMemo(() => {
    let defaultChild: JSX.Element = null;
    React.Children.forEach(children, (child) => {
      if (child.props.defaultChecked) {
        defaultChild = child;
      }
    });

    return defaultChild;
  }, []);

  useEffect(() => {
    if (value) {
      const selectedChild = children.find((child) => child.props.value === value);
      setCurrentOption({
        label: selectedChild.props.children,
        value: selectedChild.props.value,
      });
    }
  }, [value]);

  useEffect(() => {
    if (currentOption && onOptionChange) {
      onOptionChange(currentOption);
    }
  }, [currentOption]);

  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleOptionClick = (option: ISelectItem<any>) => {
    setCurrentOption(option);
    setShowOptions(false);
  };

  return (
    <SelectWrapper onClick={toggleShowOptions}>
      <SelectLabel>{label}</SelectLabel>
      <SelectCurrentValue>
        {currentOption ? currentOption.label : defaultSelectedChild?.props?.children}
      </SelectCurrentValue>
      {showOptions ? (
        <SelectOptionsContainer ref={optionsContainer}>
          {children.map((child) =>
            React.cloneElement(child, { onClick: handleOptionClick, key: child.props.value })
          )}
        </SelectOptionsContainer>
      ) : null}
      <SortingIcon width={18} height={18} fill="var(--color-primary)" />
    </SelectWrapper>
  );
}
