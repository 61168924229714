import styled from 'styled-components/macro';
import { colorsByType } from 'utils';
import { BREAKPOINTS } from 'styles/media';

export const ResultBlock = styled.div`
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 0;
  }
`
type ResultTypeType = {
  type?: string;
};

export const ResultType = styled.h3<ResultTypeType>`
  color: #8d8d90;
  font-weight: 500;
  padding-bottom: 12px;
  margin-bottom: 21px;
  border-bottom: 1px solid #8d8d90;
  text-transform: uppercase;
  position: relative;
  text-align: left;
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 4px;
    top: -4px;
    background: #EB823D;
    left: -28px;
    position: absolute;
    ${({ type }) => type && `
      background: ${colorsByType[type]};
    `
}
`

export const ResultList = styled.div`
  button {
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
  .show-more {
    color: #8d8d90;
  }
`

interface IOption {
  isSelected?: boolean;
  isFocus?: boolean;
  tabIndex?: any;
}

export const ButtonOption = styled.button<IOption>`
  width: 100%;
  ${({ isFocus }) => isFocus && `
    text-decoration: underline;
    font-weight: 700;
  `}
`

export const LabelContainer = styled.table`
  width: 100%;
  text-align: left;
`

export const LabelRow = styled.tr`
`

export const NameSpan = styled.td`
  vertical-align: middle;
  width: 70%;
  text-align: left;
`

export const InfoSpan = styled.td`
  vertical-align: middle;
  width: 30%;
  text-align: right;
  margin-top: auto;
  color: #ADADAD;
  ${BREAKPOINTS.tablet} {
      display: none;
  }
`
