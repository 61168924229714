import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getDreamProject, getYearsExperience } from 'services/profile-editor';

export function useDreamProject(
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `profile-editor-dream-project`,
    async () => {
      const [dream_project, years_experience] = await Promise.all([
        getDreamProject(),
        getYearsExperience(),
      ]);

      return {
        dream_project: dream_project.data.dream_project || '',
        dream_project_inspo: dream_project.data.inspo || [],
        years_experience: years_experience.data[0][1] || '',
      };
    },
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
