import { CompaniesArrayWrapper } from 'app/components/CommonStyled/CompaniesArray/styled';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const HomeRadarActiveWorkWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const HomeRadarActiveWorkTitle = styled.h4`
  font-weight: 400;
  font-size: 24px;
  line-height: normal;

  color: var(--color-text);
`;

export const HomeRadarActiveWorkContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  ${BREAKPOINTS.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const HomeRadarActiveWorkTotalsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`;

export const HomeRadarActiveWorkTotalsContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 24px 16px 16px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  border-radius: 8px;
  background: #ffffff;
`;

export const HomeRadarActiveWorkTotalsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HomeRadarActiveWorkTotalsTitle = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: var(--color-text);
`;

export const HomeRadarActiveWorkTotalsTimeContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 4px;
  }
`;

export const HomeRadarActiveWorkTotalsTime = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  color: var(--color-text);
  opacity: 0.5;
`;

export const HomeRadarActiveWorkTotalsClientsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  opacity: 0.5;
`;

export const HomeRadarActiveWorkTotalsClients = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: normal;
  leading-trim: both;
  text-edge: cap;
  color: var(--color-data-client-foreground);
`;

export const HomeRadarActiveWorkNewClients = styled.span`
  font-weight: 400;
  font-size: 38px;
  line-height: 42px;
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  color: var(--color-data-client-foreground);
`;

export const HomeRadarActiveWorkClientsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const HomeRadarActiveWorkClientsList = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  max-height: 350px;
  overflow-y: auto;
`;

export const HomeRadarActiveWorkClientContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 8px;
  border-radius: 8px;
  background: #ffffff;

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  & > :not(:last-child) {
    margin-right: 8px;
  }

  cursor: pointer;
`;

export const HomeRadarActiveWorkClientLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 22px;
  height: 22px;
  border-radius: 100%;

  background: var(--color-data-client-background);
`;

export const HomeRadarActiveWorkClientTextContainer = styled.div`
  display: inline-flex;
`;

interface HomeRadarActiveWorkClientTextProps {
  highlighted?: boolean;
}

export const HomeRadarActiveWorkClientText = styled.span<HomeRadarActiveWorkClientTextProps>`
  font-weight: ${({ highlighted }) => (highlighted ? 700 : 400)};
  font-size: 14px;
  line-height: normal;
  color: var(--color-text);
`;

export const HomeRadarActiveWorkAllClientsButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 8px 8px 16px;
  border-radius: 8px;
  background: var(--color-data-client-background);

  &:hover {
    background: var(--color-data-client-background-hover);
  }

  & > svg {
    transform: rotate(270deg);
  }
`;

export const HomeRadarActiveWorkAllClientsButtonText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: var(--color-text);
`;

export const HomeRadarActiveWorkContributorsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const HomeRadarActiveWorkContributorsCompaniesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const HomeRadarActiveWorkContributorsInfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  text-wrap: nowrap;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const HomeRadarActiveWorkContributorsInfo = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: var(--color-text);
  opacity: 0.3;
  white-space: nowrap;
`;

// SKELETON
export const HomeRadarActiveWorkSkeletonList = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const HomeRadarActiveWorkSkeletonItem = styled.div`
  width: 100%;
  height: 38px;
  border-radius: 8px;
  background: #f0f0f0;
`;
