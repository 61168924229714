import React from 'react';

export default function StartQuoteIcon({ width = 18, height = 18, fill = '#FFC229' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.086 8.96C5.454 8.96 6.444 8.294 6.894 7.196C7.38 8.294 8.442 8.96 9.702 8.96C11.538 8.96 12.726 7.7 12.726 5.972C12.726 4.424 11.628 3.164 10.062 3.164C9.288 3.164 8.532 3.416 8.334 3.668C8.334 3.326 8.568 2.894 9.018 2.372C9.9 1.346 11.772 0.823999 12.69 0.823999L12.33 0.319999C9.558 0.319999 7.182 1.958 6.642 4.352C6.138 3.56 5.4 3.164 4.446 3.164C3.672 3.164 2.916 3.416 2.718 3.668C2.718 3.326 2.952 2.894 3.402 2.372C4.284 1.346 6.156 0.823999 7.074 0.823999L6.714 0.319999C5.706 0.319999 4.716 0.553999 3.798 1.004C1.944 1.922 0.918 3.506 0.918 5.36C0.918 7.682 2.286 8.96 4.086 8.96Z"
        fill={fill}
      />
    </svg>
  );
}
