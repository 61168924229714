import styled from "styled-components";

interface AboutDrawerContentProps {
  color: string;
}

export const AboutDrawerContent = styled.div<AboutDrawerContentProps>`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 36px 32px;
  width: 100%;
  > div.title-container {
    align-items: center;
    display: flex;
    margin-bottom: 32px;
    > h1 {
      font-size: 32px;
      line-height: 32px;
    }
    > svg {
      height: 34px;
      margin-right: 11px;
      width: 34px;
      > path {
        fill: ${({ color }) => color};
      }
    }
  }
  > div.text-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    > p {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.1em;
      line-height: 18px;
      margin-bottom: 8px;
    }
  }
  > span {
    align-items: center;
    display: flex;
    font-size: 14px;
    line-height: 18px;
    > b {
      font-weight: 500;
      margin: 0 4px;
    }
    > button {
      height: 16px;
      width: 16px;
    }
  }
`;
