import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';
import { setIsDrawerOpen } from 'store/reducers/navbar-reducer';
import {
  setShowResourceSubmissionModal,
  showResourceSubmissionModalSelector,
} from 'store/reducers/resource-submission/resource-submission-reducer';

const ResourceSubmissionModal = lazy(() => import('../ResourceSubmissionModal'));

export default function ResourceSubmissionHandler() {
  const dispatch = useDispatch();
  const location = useLocation();
  const showResourceSubmissionModal = useSelector(showResourceSubmissionModalSelector);

  useEffect(() => {
    const hash = location.hash;

    if (hash === '#best-of-24-instructions') {
      dispatch(setShowResourceSubmissionModal(true));
    }
  }, [location]);

  useEffect(() => {
    dispatch(setIsDrawerOpen(showResourceSubmissionModal));
  }, [showResourceSubmissionModal]);

  return (
    <AnimatePresence>
      {showResourceSubmissionModal ? (
        <Suspense fallback={<></>}>
          <ResourceSubmissionModal />
        </Suspense>
      ) : null}
    </AnimatePresence>
  );
}
