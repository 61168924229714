import styled from 'styled-components';

interface PeopleAvatarWrapperProps {
  justifyContent: string;
  size: number;
}

export const PeopleAvatarWrapper = styled.div<PeopleAvatarWrapperProps>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: ${({ justifyContent }) => justifyContent};
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }

  & > div:not(.people-avatar-more-counter) {
    position: relative;
    background-color: var(--color-primary);
  }

  & > .people-avatar-more-counter {
    height: ${({ size }) => size}px;
    margin-left: 4px;
  }

  & > div:not(.people-avatar-more-counter):before {
    content: '';
    position: absolute;
    background-color: var(--color-primary);
    border-radius: 100%;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  & > :not(:first-child) {
    margin-right: ${({ size }) => (size / 3) * -1}px;
  }

  &:hover > div {
    border: 2px solid rgb(255, 194, 41, 0.5);
    > img {
      opacity: 0.5;
    }

    > span {
      opacity: 0.5;
    }
  }

  &:hover > *:hover {
    border: 2px solid rgb(255, 194, 41, 1);
    > img {
      opacity: 1;
    }

    > span {
      opacity: 1;
    }

    opacity: 1;
  }
`;

export const PeopleAvatarContainerMoreCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px 8px;
  border-radius: 1000px;

  background: rgba(255, 194, 41, 0.15);
  border: 2px solid rgba(255, 194, 41, 1);
`;

export const PeopleAvatarContainerMoreCounterText = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;

  color: rgba(255, 194, 41, 1);
`;
