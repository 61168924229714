export const theme = {
  people: {
    background: 'var(--color-data-people-background)',
  },
  passions: {
    background: 'var(--color-data-passion-background)',
  },
  skills: {
    background: 'var(--color-data-skill-background)',
  },
  companies: {
    background: 'var(--color-data-company-background)',
  },
  clients: {
    background: 'var(--color-data-client-background)',
  },
  industries: {
    background: 'var(--color-data-industry-background)',
  },
  disciplines: {
    background: 'var(--color-data-company-background)',
  },
  wildcard: {
    background:
      'linear-gradient(206deg, rgba(196, 224, 160, 0.75) 0%, rgba(156, 202, 255, 0.38) 46.35%, rgba(251, 175, 161, 0.75) 100%), #FBAFA1',
  },
};
