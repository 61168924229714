import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getCompanyAtAGlance } from 'services/app';
import { ICompanyGlanceResponse } from '../../interfaces/company';

export default function useCompanyGlance(
  slug: string,
  options?: Omit<
    UseQueryOptions<ICompanyGlanceResponse, Error, ICompanyGlanceResponse, QueryKey>,
    'queryKey'
  >
) {
  return useQuery<ICompanyGlanceResponse, Error>(
    `company-${slug}-glance`,
    () => getCompanyAtAGlance(slug).then((response) => response.data),
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
