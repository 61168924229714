import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import GlobalPersonDrawer from 'app/components/Drawers/GlobalPerson/GlobalPersonDrawer';
import { Desktop, FullArrowIcon } from 'app/components/shared/icons';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import useIsMobile from 'hooks/useIsMobile';
import { ThemeProvider } from 'styled-components';
import { limitText } from 'utils';

import CompanyLogo from '../CompanyLogo';
import DataTypePill from '../DataTypePill/DataTypePill';
import InfoTooltip from '../InfoTooltip';
import SideDrawer from '../SideDrawer';
import {
  StoryCardButton,
  StoryCardButtonText,
  StoryCardCompanyContainer,
  StoryCardContentContainer,
  StoryCardContentWrapper,
  StoryCardDescription,
  StoryCardEventHostBottomContainer,
  StoryCardEventHostInformationCompanyName,
  StoryCardEventHostInformationContainer,
  StoryCardEventHostInformationName,
  StoryCardImage,
  StoryCardImageContainer,
  StoryCardImageWrapper,
  StoryCardLatestContributionContainer,
  StoryCardLatestContributionTitle,
  StoryCardMobileMapButton,
  StoryCardMobileMapButtonText,
  StoryCardNoImageContainer,
  StoryCardNoImageText,
  StoryCardTitle,
  StoryCardType,
  StoryCardWrapper,
} from './styled';
import { theme } from './theme';

interface StoryCardProps {
  card: {
    slug: string;
    uuid?: string;
    item_type: string;
    title: string;
    description: string;
    image: string;
    cta_label?: string;
    cta_url?: string;
    company?: {
      slug: string;
      name: string;
    };
    convening?: {
      slug: string;
      title: string;
    };
    latest_convening?: {
      slug: string;
      name: string;
    };
    latest_article?: {
      slug: string;
      name: string;
    };
    latest_featured_article?: {
      slug: string;
      name: string;
    };
    event_host_data: {
      name?: string;
      first_name?: string;
      last_name?: string;
      company?: string;
    };
    latest_resource?: {
      slug: string;
      name: string;
      resource_type: string;
      link: string;
      go_to_detail_page: boolean;
    };
  };
  overrideBehavioralFunction?: (data: any) => void;
}

const defaultImagesMap = new Map<string, string>([
  ['passion', '/images/activity-feed/spotlight-passion-fallback.jpg'],
]);

const titlesMap = new Map<string, string>([
  ['passion', 'TOP OF MIND'],
  ['community', 'COMMUNITY'],
  ['person', 'HOST OF THE MONTH'],
  ['company', 'NEW MEMBER COMPANY'],
  ['convening', 'EVENT'],
  ['article', 'NOTEWORTHY'],
  ['resource', 'RESOURCE'],
  ['collection', 'COLLECTION'],
  ['map', 'NETWORK MAP'],
]);

const buttonsTextMap = new Map<string, string>([
  ['company', 'Learn More'],
  ['community', 'Learn More'],
  ['person', "View %name%'s Profile"],
  ['convening', 'Learn More'],
  ['resource', 'Learn More'],
  ['passion', 'Learn More'],
  ['article', 'Read on The Well'],
  ['collection', 'Go to the Collection'],
  ['map', 'Explore the Network'],
]);

const typeToUrlMap = new Map<string, string>([
  ['company', '/member-company'],
  ['community', '/community'],
  ['person', '/person'],
  ['event_host', '/person'],
  ['convening', '/convening'],
  ['resource', '/resources'],
  ['passion', '/passion'],
  ['article', '/articles'],
  ['collection', '/collection'],
]);

const typeToBehavioralMap = new Map<string, BEHAVIORALS>([
  ['map', 'ACTIVITY_FEED_MAP_GALLERY_CLICKED'],
  ['event_host', 'ACTIVITY_FEED_MEET_SOMEONE_NEW_CLICKED'],
]);

export default function StoryCard({ card, overrideBehavioralFunction = null }: StoryCardProps) {
  const { push } = useHistory();
  const isMobile = useIsMobile();
  const behavioralFunction = overrideBehavioralFunction
    ? overrideBehavioralFunction
    : useBehavioral(
        typeToBehavioralMap.has(card.item_type)
          ? typeToBehavioralMap.get(card.item_type)
          : 'ACTIVITY_FEED_SPOTLIGHT_CLICKED'
      );
  const [showDrawer, setShowDrawer] = useState<any>({ type: '', open: false, slug: null });

  const handleClick = () => {
    if (card.item_type === 'map' && isMobile) return;

    let behavioralParams: any = {
      object_uuid: card.uuid,
    };

    if (['map', 'resource'].includes(card.item_type)) {
      behavioralParams = {
        ...behavioralParams,
        action_details: card.title,
      };
    }

    behavioralFunction(behavioralParams);

    if (card.item_type === 'map') return (window.location.href = card.cta_url);
    if (card.cta_url) return window.open(card.cta_url, '_blank');
    if (card.item_type === 'event_host')
      return setShowDrawer({ type: 'person', slug: card.slug, open: true });

    push(`${typeToUrlMap.get(card.item_type)}/${card.slug}`);
  };

  return (
    <ThemeProvider theme={theme[card.item_type]}>
      <StoryCardWrapper
        background={
          defaultImagesMap.has(card.item_type)
            ? `url(${defaultImagesMap.get(card.item_type)})`
            : `url('${card.image}')`
        }
        onClick={handleClick}
      >
        <StoryCardHeader card={card} />
        <StoryCardContentWrapper>
          <StoryCardContentContainer>
            {titlesMap.has(card.item_type) ? (
              <StoryCardType>{titlesMap.get(card.item_type)}</StoryCardType>
            ) : null}
            <StoryCardTitle>{card.title}</StoryCardTitle>
            {card.description ? (
              <StoryCardDescription type={card.item_type}>
                {limitText(card.description, isMobile ? 130 : 209)}
              </StoryCardDescription>
            ) : null}
          </StoryCardContentContainer>
          {card.item_type === 'map' && isMobile ? (
            <StoryCardMobileMapButtonComponent />
          ) : buttonsTextMap.has(card.item_type) ? (
            <StoryCardButton>
              <StoryCardButtonText>
                {card.cta_label ||
                  buttonsTextMap.get(card.item_type).replace('%name%', card.title.split(' ')[0])}
              </StoryCardButtonText>
              <FullArrowIcon />
            </StoryCardButton>
          ) : null}
          {card.item_type === 'event_host' ? (
            <StoryCardEventHostBottomContainer>
              <StoryCardLatestContributionContainer onClick={(e) => e.stopPropagation()}>
                <StoryCardLatestContributionTitle>
                  LATEST CONTRIBUTION
                </StoryCardLatestContributionTitle>
                {card.latest_convening ? (
                  <DataTypePill
                    type="convening"
                    slug={card.latest_convening.slug}
                    title={card.latest_convening.name}
                    themeMode="high-contrast"
                  />
                ) : null}
                {!card.latest_convening && card.latest_article ? (
                  <DataTypePill
                    type="article"
                    slug={card.latest_article.slug}
                    title={card.latest_article.name}
                    themeMode="high-contrast"
                  />
                ) : null}
                {!card.latest_convening && !card.latest_article && card.latest_resource ? (
                  <DataTypePill
                    type="resource"
                    slug={card.latest_resource.slug}
                    title={card.latest_resource.name}
                    themeMode="high-contrast"
                    go_to_detail_page={card.latest_resource.go_to_detail_page}
                    link={card.latest_resource.link}
                    resource_type_name={card.latest_resource.resource_type}
                  />
                ) : null}
                {!card.latest_convening &&
                !card.latest_article &&
                !card.latest_resource &&
                card.latest_featured_article ? (
                  <DataTypePill
                    type="article"
                    slug={card.latest_featured_article.slug}
                    title={card.latest_featured_article.name}
                    themeMode="high-contrast"
                  />
                ) : null}
              </StoryCardLatestContributionContainer>
              {card.item_type === 'event_host' ? (
                <StoryCardEventHostInformationContainer>
                  <StoryCardEventHostInformationName>
                    {card.event_host_data.name}
                  </StoryCardEventHostInformationName>
                  <StoryCardEventHostInformationCompanyName>
                    {card.event_host_data.company}
                  </StoryCardEventHostInformationCompanyName>
                </StoryCardEventHostInformationContainer>
              ) : null}
            </StoryCardEventHostBottomContainer>
          ) : null}
        </StoryCardContentWrapper>
      </StoryCardWrapper>
      {showDrawer.open ? (
        <SideDrawer closeDrawer={() => setShowDrawer({ type: '', open: false })} withClose>
          {showDrawer.type === 'person' ? <GlobalPersonDrawer slug={showDrawer.slug} /> : null}
        </SideDrawer>
      ) : null}
    </ThemeProvider>
  );
}

function StoryCardHeader({ card }: StoryCardProps) {
  const initials = useMemo(() => {
    if (card.item_type === 'event_host') {
      if (card.event_host_data.last_name) {
        return `${card.event_host_data.first_name[0]}${card.event_host_data.last_name[0]}`;
      }
      return `${card.event_host_data.first_name[0]}${card.event_host_data.first_name[1]}`;
    }

    if (card.item_type === 'person') {
      const name = card.title.split(' ');
      if (name.length > 1) {
        return `${name[0][0]}${name[1][0]}`;
      }
      return `${name[0][0]}${name[0][1]}`;
    }
  }, [card]);

  return (
    <StoryCardImageWrapper>
      <StoryCardImageContainer>
        {['event_host', 'person'].includes(card.item_type) ? (
          <>
            {card.image ? (
              <StoryCardImage src={card.image} loading="lazy" />
            ) : (
              <StoryCardNoImageContainer>
                <StoryCardNoImageText>{initials}</StoryCardNoImageText>
              </StoryCardNoImageContainer>
            )}
          </>
        ) : (
          <StoryCardImage src={card.image || defaultImagesMap.get(card.item_type)} loading="lazy" />
        )}
      </StoryCardImageContainer>
      {card.company ? (
        <StoryCardCompanyContainer onClick={(e) => e.stopPropagation()}>
          <CompanyLogo openDrawer stopPropagation size={60} slug={card.company.slug} useRounded />
        </StoryCardCompanyContainer>
      ) : null}
    </StoryCardImageWrapper>
  );
}

function StoryCardMobileMapButtonComponent() {
  return (
    <StoryCardMobileMapButton>
      <Desktop width={16} height={16} fill={'#FFFFFF'} />
      <StoryCardMobileMapButtonText>Uncover more on desktop</StoryCardMobileMapButtonText>
      <InfoTooltip
        size={16}
        title="Network maps are optimized for desktop"
        text="Switch to a larger device to get the full experience"
        maxWidth="200px"
        fill="rgba(255, 255, 255, 0.5)"
      />
    </StoryCardMobileMapButton>
  );
}
