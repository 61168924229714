import React, { Suspense, useContext, useEffect, useMemo, useRef } from 'react';

import CheckboxDropdown from 'app/components/CommonStyled/CheckboxDropdown';
import ContactCTA from 'app/components/CommonStyled/ContactCTA';
import { HomeBaseDrawerWrapper } from 'app/components/CommonStyled/HomeDrawers/HomeBaseDrawer/styled';
import usePeopleDrawerList from 'app/components/DetailsPages/hooks/company/usePeopleDrawerList';
import { ICompanyPerson } from 'app/components/DetailsPages/interfaces/company';
import { DetailsPageContext } from 'app/components/DetailsPages/shared/DetailsPageContext';
import { SkeletonItem } from 'app/components/DetailsPages/shared/styled';
import PersonIcon from 'app/components/shared/icons/person-icon';
import { AnimatePresence, motion } from 'framer-motion';
import useOnClickOutside from 'hooks/click-outside';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import { ThemeProvider } from 'styled-components';
import { arrayToOxford } from 'utils';

import GlobalPersonDrawer from '../GlobalPerson/GlobalPersonDrawer';
import {
  PeopleDrawerContactCTAContainer,
  PeopleDrawerClearAll,
  PeopleDrawerContainer,
  PeopleDrawerFilterDropdownWrapper,
  PeopleDrawerFilterOptionsContainer,
  PeopleDrawerFiltersContainer,
  PeopleDrawerFiltersDescription,
  PeopleDrawerFilterSkeleton,
  PeopleDrawerFiltersTitle,
  PeopleDrawerListContainer,
  PeopleDrawerListSorting,
  PeopleDrawerListSortingContainer,
  PeopleDrawerListSortingOption,
  PeopleDrawerListSortingOptionsContainer,
  PeopleDrawerPill,
  PeopleDrawerPillText,
  PersonDrawerConnectionCount,
} from './styled';
import { theme as themeProvided } from './theme';

const ContactDrawer = React.lazy(() => import('../Contact'));

const sortingTitles = {
  by_what_you_share: 'by what you share',
  alphabetically: 'alphabetically',
};

interface PeopleDrawerProps {
  slug?: string;
  title?: string;
  companyName?: string;
  theme?: 'dark' | 'light';
  defaultOpenedFilter?: string;
}

export default function PeopleDrawer({
  slug,
  title,
  companyName,
  theme = 'light',
  defaultOpenedFilter = null,
}: PeopleDrawerProps) {
  const peopleListCollectiveDirectoryCTAClickedBehavioral = useBehavioral(
    BEHAVIORALS.PEOPLE_LIST_COLLECTIVE_DIRECTORY_CTA_CLICKED
  );
  const context = useContext(DetailsPageContext);
  const [showingPersonDrawer, setShowingPersonDrawer] = React.useState<boolean>(false);
  const [personDrawerSlug, setPersonDrawerSlug] = React.useState<string>('');
  const [showContactDrawer, setShowContactDrawer] = React.useState<boolean>(false);
  const [sorting, setSorting] = React.useState<string>('by_what_you_share');
  const [showSorting, setShowSorting] = React.useState<boolean>(false);
  const sortingContainerRef = React.useRef(null);
  const [filterHeight, setFilterHeight] = React.useState<number>(0);
  const filtersContainer = React.useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        setFilterHeight(node.clientHeight);
      }
    },
    [showingPersonDrawer]
  );

  useOnClickOutside(sortingContainerRef, () => setShowSorting(false));

  const [passions, setPassions] = React.useState<string[]>([]);
  const [roles, setRoles] = React.useState<string[]>([]);
  const [locations, setLocations] = React.useState<string[]>([]);
  const [kyuSkills, setKyuSkills] = React.useState<string[]>([]);

  const [passionsNames, setPassionsNames] = React.useState<string[]>([]);
  const [rolesNames, setRolesNames] = React.useState<string[]>([]);
  const [locationsNames, setLocationsNames] = React.useState<string[]>([]);
  const [kyuSkillsNames, setKyuSkillsNames] = React.useState<string[]>([]);

  const peopleQuery = usePeopleDrawerList(context?.slug || slug, {
    passion_uuids: passions,
    roles_uuids: roles,
    locations_uuids: locations,
    kyu_skills_uuids: kyuSkills,
  });

  const filters = {
    roles: {
      title: 'Roles',
      set: setRoles,
      selected: roles,
      setNames: setRolesNames,
    },
    locations: {
      title: 'Locations',
      set: setLocations,
      selected: locations,
      setNames: setLocationsNames,
    },
    passions: {
      title: 'Passions',
      set: setPassions,
      selected: passions,
      setNames: setPassionsNames,
    },
    kyu_skills: {
      title: 'Skills',
      set: setKyuSkills,
      selected: kyuSkills,
      setNames: setKyuSkillsNames,
    },
  };

  const clearAll = () => {
    setPassions([]);
    setRoles([]);
    setLocations([]);
    setKyuSkills([]);
  };

  const { isLoading, isIdle, data, isError } = peopleQuery;

  const allFiltersNames = useMemo(() => {
    return [...passionsNames, ...rolesNames, ...locationsNames, ...kyuSkillsNames];
  }, [passionsNames, rolesNames, locationsNames, kyuSkillsNames]);

  const peopleUuids = useMemo(() => {
    if (!data || data?.people.length === 0) return [];

    return data?.people.map((person) => person.uuid);
  }, [data]);

  const handleContactCTAClick = () => {
    peopleListCollectiveDirectoryCTAClickedBehavioral({
      related_object_type: 'Company',
      related_object_uuid: context.slug,
    });
    setShowContactDrawer(true);
  };

  if (isError) return <PeopleDrawerContainer />;

  return (
    <ThemeProvider theme={themeProvided[theme || 'light']}>
      <PeopleDrawerContainer showingPersonDrawer={showingPersonDrawer}>
        {showingPersonDrawer ? (
          <GlobalPersonDrawer
            slug={personDrawerSlug}
            comingFromList
            backToListAction={() => setShowingPersonDrawer(false)}
            theme={theme || 'light'}
          />
        ) : (
          <>
            <PeopleDrawerFiltersContainer ref={filtersContainer}>
              <PeopleDrawerFiltersTitle>
                {title ?? `People of ${context.data.name}`}
              </PeopleDrawerFiltersTitle>
              <PeopleDrawerFiltersDescription>
                People included in this list are all active employees at{' '}
                {companyName ?? context.data.name}. If you share things in common with a person,
                you’ll see a connection count next to their name.
                <br />
                <br />
                You can refine this list by using the filters below.
              </PeopleDrawerFiltersDescription>
              <PeopleDrawerFilterOptionsContainer>
                {!isLoading && !isIdle ? (
                  <>
                    {Object.keys(filters).map((filter, index) => (
                      <PeopleDrawerFilter
                        startOpen={defaultOpenedFilter === filter}
                        filter={filters[filter]}
                        key={index}
                        data={data[filter]}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    <PeopleDrawerFilterSkeleton width={55} height={18} />
                    <PeopleDrawerFilterSkeleton width={55} height={18} />
                    <PeopleDrawerFilterSkeleton width={55} height={18} />
                    <PeopleDrawerFilterSkeleton width={55} height={18} />
                  </>
                )}
                {!isLoading &&
                !isIdle &&
                (passions.length > 0 ||
                  roles.length > 0 ||
                  locations.length > 0 ||
                  kyuSkills.length > 0) ? (
                  <PeopleDrawerClearAll onClick={() => clearAll()}>Clear all</PeopleDrawerClearAll>
                ) : (
                  <></>
                )}
              </PeopleDrawerFilterOptionsContainer>
            </PeopleDrawerFiltersContainer>
            <PeopleDrawerListContainer filtersHeight={filterHeight}>
              {!isLoading && !isIdle && (
                <PeopleDrawerListSortingContainer>
                  <span style={{ marginRight: showSorting ? '145px' : '8px', opacity: '0.5' }}>
                    View people
                  </span>{' '}
                  {!showSorting && (
                    <PeopleDrawerListSorting onClick={() => setShowSorting(true)}>
                      {sortingTitles[sorting]}
                      <SortingIcon />
                    </PeopleDrawerListSorting>
                  )}
                  {showSorting && (
                    <PeopleDrawerListSortingOptionsContainer ref={sortingContainerRef}>
                      <PeopleDrawerListSortingOption
                        selected={sorting === 'by_what_you_share'}
                        onClick={() => {
                          setSorting('by_what_you_share');
                          setShowSorting(false);
                        }}
                      >
                        {sortingTitles['by_what_you_share']}
                      </PeopleDrawerListSortingOption>
                      <PeopleDrawerListSortingOption
                        selected={sorting === 'alphabetically'}
                        onClick={() => {
                          setSorting('alphabetically');
                          setShowSorting(false);
                        }}
                      >
                        {sortingTitles['alphabetically']}
                      </PeopleDrawerListSortingOption>
                    </PeopleDrawerListSortingOptionsContainer>
                  )}
                </PeopleDrawerListSortingContainer>
              )}
              {isLoading
                ? Array.from({ length: 30 }).map((_, index) => (
                    <SkeletonItem
                      animated
                      style={{
                        width: Math.floor(Math.random() * 50) + 75,
                        height: 22,
                        borderRadius: 8,
                      }}
                    />
                  ))
                : null}
              {!isLoading &&
                !isIdle &&
                data.people &&
                data.people
                  .sort((a, b) => {
                    if (sorting === 'alphabetically') {
                      return a.full_name.localeCompare(b.full_name);
                    } else {
                      return b.connection_count - a.connection_count;
                    }
                  })
                  .map((person) => (
                    <PersonPill
                      key={person.uuid}
                      person={person}
                      setPersonDrawerSlug={setPersonDrawerSlug}
                      setShowingPersonDrawer={setShowingPersonDrawer}
                    />
                  ))}
            </PeopleDrawerListContainer>
          </>
        )}
        {!isLoading && !showingPersonDrawer ? (
          <PeopleDrawerContactCTAContainer>
            <ContactCTA onClick={handleContactCTAClick} />
          </PeopleDrawerContactCTAContainer>
        ) : null}
        <AnimatePresence>
          {showContactDrawer ? (
            <motion.div
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 4,
                transform: 'translateX(100%)',
              }}
              initial={{ transform: 'translateX(100%)' }}
              animate={{ transform: 'translateX(0%)' }}
              exit={{ transform: 'translateX(100%)' }}
              transition={{ duration: 0.4 }}
            >
              <Suspense fallback={<HomeBaseDrawerWrapper />}>
                <ContactDrawer
                  backToRecipient={() => setShowContactDrawer(false)}
                  fullCriteriaUuids={peopleUuids}
                  selectedUuids={peopleUuids}
                  matches={allFiltersNames}
                />
              </Suspense>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </PeopleDrawerContainer>
    </ThemeProvider>
  );
}

export function PersonPill({
  person,
  setShowingPersonDrawer,
  setPersonDrawerSlug,
}: {
  person: ICompanyPerson;
  setShowingPersonDrawer: (value: boolean) => void;
  setPersonDrawerSlug: (value: string) => void;
}) {
  const peopleDrawerItemClickedBehavioral = useBehavioral('PEOPLE_DRAWER_ITEM_CLICKED');

  return (
    <PeopleDrawerPill
      onClick={() => {
        peopleDrawerItemClickedBehavioral({
          related_object_uuid: person.slug,
        });
        setPersonDrawerSlug(person.slug);
        setShowingPersonDrawer(true);
      }}
    >
      <PersonIcon width={13} height={13} fill={'rgba(255, 194, 41, 1)'} />
      <PeopleDrawerPillText>{person.full_name}</PeopleDrawerPillText>
      {person.connection_count !== 0 ? (
        <PersonDrawerConnectionCount>
          <span>{person.connection_count}</span>
        </PersonDrawerConnectionCount>
      ) : null}
    </PeopleDrawerPill>
  );
}

export function PeopleDrawerFilter({ filter, data, startOpen = false }) {
  const peopleDrawerFilterClickedBehavioral = useBehavioral('PEOPLE_DRAWER_FILTER_CLICKED');
  const [tempUuids, setTempUuids] = React.useState<string[]>([]);
  const [title, setTitle] = React.useState<string>(filter.title);
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>(filter.selected);

  useEffect(() => {
    setSelectedOptions(filter.selected);
  }, [filter.selected]);

  const options = data?.all.map((option) => {
    return {
      id: option.uuid,
      name: option.name,
    };
  });

  const onClickOutside = () => {
    const selectedOptions = options.filter((option) => tempUuids.includes(option.id));
    peopleDrawerFilterClickedBehavioral({
      action_details: `Filter: ${filter.title} | Values: ${arrayToOxford(
        selectedOptions.map((option) => option.name)
      )}`,
    });
    filter.set(tempUuids);
  };

  const onChange = (checkedIds) => {
    setTempUuids(checkedIds);

    if (checkedIds.length === 0) {
      setTitle(filter.title);
    } else if (checkedIds.length === 1) {
      const selectedOption = options.find((option) => option.id === checkedIds[0]);
      setTitle(selectedOption?.name);
    } else {
      setTitle(`${checkedIds.length} ${filter.title}`);
    }

    const names = options
      .filter((option) => checkedIds.includes(option.id))
      .map((option) => option.name);
    filter.setNames(names);
  };

  return (
    <PeopleDrawerFilterDropdownWrapper hasSelection={title !== filter.title}>
      {filter.selected.length > 0 && (
        <ClearIcon
          onClick={() => {
            setSelectedOptions([]);
            setTitle(filter.title);
            filter.set([]);
          }}
        />
      )}
      <CheckboxDropdown
        startOpen={startOpen}
        title={title}
        originalTitle={filter.title}
        onChange={(checkedIds) => onChange(checkedIds)}
        onClickOutside={() => onClickOutside()}
        options={options}
        checkedOptions={selectedOptions}
        includeAll={false}
        arrowFill={'#171C33'}
        availableOptions={data?.available.map((option) => option.uuid)}
      />
    </PeopleDrawerFilterDropdownWrapper>
  );
}

export function ClearIcon({ onClick = () => {} }) {
  return (
    <svg
      onClick={onClick}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
    >
      <circle cx="8" cy="8.5" r="6" fill="#EB823D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3566 10.8566C10.617 10.5962 10.617 10.1741 10.3566 9.91376L8.9424 8.49953L10.3566 7.08535C10.6169 6.825 10.6169 6.40289 10.3566 6.14254C10.0962 5.88219 9.67411 5.88219 9.41376 6.14254L7.99959 7.55672L6.5854 6.14253C6.32505 5.88218 5.90294 5.88218 5.64259 6.14253C5.38224 6.40288 5.38224 6.82499 5.64259 7.08534L7.05678 8.49953L5.64253 9.91378C5.38218 10.1741 5.38218 10.5962 5.64253 10.8566C5.90288 11.1169 6.32499 11.1169 6.58534 10.8566L7.99959 9.44234L9.41383 10.8566C9.67418 11.1169 10.0963 11.1169 10.3566 10.8566Z"
        fill="white"
      />
    </svg>
  );
}

export function SortingIcon({ width = 18, height = 18 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46967 2.46967C8.76256 2.17678 9.23744 2.17678 9.53033 2.46967L13.2803 6.21967C13.5732 6.51256 13.5732 6.98744 13.2803 7.28033C12.9874 7.57322 12.5126 7.57322 12.2197 7.28033L9 4.06066L5.78033 7.28033C5.48744 7.57322 5.01256 7.57322 4.71967 7.28033C4.42678 6.98744 4.42678 6.51256 4.71967 6.21967L8.46967 2.46967Z"
        fill="#171C33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53033 15.5303C9.23744 15.8232 8.76256 15.8232 8.46967 15.5303L4.71967 11.7803C4.42678 11.4874 4.42678 11.0126 4.71967 10.7197C5.01256 10.4268 5.48744 10.4268 5.78033 10.7197L9 13.9393L12.2197 10.7197C12.5126 10.4268 12.9874 10.4268 13.2803 10.7197C13.5732 11.0126 13.5732 11.4874 13.2803 11.7803L9.53033 15.5303Z"
        fill="#171C33"
      />
    </svg>
  );
}
