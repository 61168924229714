import React from 'react';

export default function QuestionIcon({ width = 24, height = 24, fill = '#' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2582 8.0288C11.7927 7.94895 11.314 8.03643 10.9068 8.27574C10.4996 8.51506 10.1902 8.89077 10.0335 9.33632C9.85018 9.85731 9.27926 10.1311 8.75827 9.94781C8.23728 9.76454 7.96351 9.19362 8.14678 8.67263C8.46025 7.78152 9.07898 7.03011 9.89339 6.55148C10.7078 6.07285 11.6653 5.89789 12.5964 6.05758C13.5274 6.21728 14.3719 6.70133 14.9802 7.42401C15.5884 8.14653 15.9214 9.06096 15.9201 10.0054C15.9197 11.5357 14.7851 12.5464 13.9748 13.0865C13.5943 13.3402 13.2193 13.5352 12.9201 13.674V14.5045C12.9201 15.0567 12.4724 15.5045 11.9201 15.5045C11.3678 15.5045 10.9201 15.0567 10.9201 14.5045V13.0045C10.9201 12.5744 11.1951 12.1926 11.6028 12.0561L11.6188 12.0505C11.6342 12.0451 11.6594 12.0359 11.693 12.0231C11.7605 11.9974 11.8607 11.9574 11.9827 11.9032C12.2296 11.7935 12.551 11.632 12.8654 11.4224C13.555 10.9627 13.9201 10.4736 13.9201 10.0045L13.9201 10.003C13.9208 9.53067 13.7543 9.07334 13.4502 8.71201C13.146 8.35067 12.7238 8.10864 12.2582 8.0288Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 18.0039C11 17.4516 11.4477 17.0039 12 17.0039H12.01C12.5623 17.0039 13.01 17.4516 13.01 18.0039C13.01 18.5562 12.5623 19.0039 12.01 19.0039H12C11.4477 19.0039 11 18.5562 11 18.0039Z"
        fill={fill}
      />
    </svg>
  );
}
