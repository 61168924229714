import React from 'react';

export default function ShareIcon({
  width = 24,
  height = 24,
  fill = 'rgba(23, 28, 51, 0.5)',
  className = '',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22.2806 9.96997L14.7806 2.46997C14.6757 2.36501 14.5421 2.29351 14.3967 2.26449C14.2512 2.23547 14.1004 2.25025 13.9633 2.30694C13.8262 2.36364 13.709 2.45972 13.6265 2.58302C13.5441 2.70632 13.5 2.85131 13.4999 2.99965V6.78246C11.0681 6.99059 8.38213 8.18122 6.17244 10.0553C3.51182 12.3128 1.85526 15.2218 1.50744 18.2462C1.48026 18.4813 1.52794 18.7191 1.64368 18.9255C1.75943 19.132 1.93735 19.2967 2.15212 19.3962C2.36689 19.4957 2.60757 19.5249 2.83991 19.4797C3.07225 19.4345 3.28441 19.3171 3.44619 19.1443C4.47744 18.0465 8.14682 14.575 13.4999 14.2693V17.9997C13.5 18.148 13.5441 18.293 13.6265 18.4163C13.709 18.5396 13.8262 18.6357 13.9633 18.6924C14.1004 18.7491 14.2512 18.7638 14.3967 18.7348C14.5421 18.7058 14.6757 18.6343 14.7806 18.5293L22.2806 11.0293C22.4208 10.8887 22.4996 10.6983 22.4996 10.4997C22.4996 10.3011 22.4208 10.1106 22.2806 9.96997ZM14.9999 16.1893V13.4997C14.9999 13.3007 14.9209 13.11 14.7803 12.9693C14.6396 12.8287 14.4489 12.7497 14.2499 12.7497C11.6174 12.7497 9.05338 13.4368 6.629 14.7934C5.39427 15.4874 4.24383 16.3216 3.20057 17.2797C3.74432 15.0447 5.11494 12.9193 7.14276 11.199C9.31963 9.35309 11.9765 8.24965 14.2499 8.24965C14.4489 8.24965 14.6396 8.17063 14.7803 8.02998C14.9209 7.88933 14.9999 7.69857 14.9999 7.49965V4.8109L20.6896 10.4997L14.9999 16.1893Z"
        fill={fill}
      />
    </svg>
  );
}
