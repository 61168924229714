import React, { useContext, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import ToggleButton from 'app/components/CommonStyled/ToggleButton';
import { SkillIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { DetailsPageContext } from 'app/components/DetailsPages/shared/DetailsPageContext';
import { User } from 'interfaces';
import { userDataSelector } from 'store/reducers/user-reducer';

import GlobalSkillDrawer from '../GlobalSkill/GlobalSkillDrawer';
import {
  SkillDrawerFilterOption,
  SkillDrawerPill,
  SkillDrawerText,
  SkillsDrawerContainer,
  SkillsDrawerFiltersContainer,
  SkillsDrawerFiltersDescription,
  SkillsDrawerFiltersOptionsContainer,
  SkillsDrawerFiltersTitle,
  SkillsDrawerListContainer,
} from './styled';

interface SkillsDrawerProps {
  skillsQuery?: UseQueryResult<any, Error>;
}

export default function SkillsDrawer({ skillsQuery }: SkillsDrawerProps) {
  const userData: User = useSelector(userDataSelector);
  const { isLoading, isIdle, data, isError } = skillsQuery;
  const context = useContext(DetailsPageContext);
  const [filterSelected, setFilterSelected] = useState<string>('A-C');
  const [filterIncludeUser, setFilterIncludeUser] = useState<boolean>(false);
  const [showingSkillDrawer, setShowingSkillDrawer] = useState<boolean>(false);
  const [skillDrawerSlug, setSkillDrawerSlug] = useState<string>('');

  if (isError) {
    return (
      <SkillsDrawerContainer showingSkillDrawer={false}>
        <h1>An error ocurred</h1>
      </SkillsDrawerContainer>
    );
  }

  return (
    <SkillsDrawerContainer showingSkillDrawer={showingSkillDrawer}>
      {showingSkillDrawer ? (
        <GlobalSkillDrawer
          slug={skillDrawerSlug}
          comingFromList
          backToListAction={() => setShowingSkillDrawer(false)}
        />
      ) : (
        <>
          <SkillsDrawerFiltersContainer>
            <SkillsDrawerFiltersTitle>
              Areas of Expertise at {context.data.name || userData.kyu_company.name}
            </SkillsDrawerFiltersTitle>
            <SkillsDrawerFiltersDescription>
              These areas of expertise have either been provided by{' '}
              {context.data.name || userData.kyu_company.name} or have been added by people to their
              profiles.
            </SkillsDrawerFiltersDescription>
            {!isLoading && !isIdle ? (
              <SkillsDrawerFiltersOptionsContainer>
                {Object.keys(data.kyu_skills).map((filterOption, index) => (
                  <SkillDrawerFilterOption
                    key={index}
                    selected={filterSelected === filterOption}
                    onClick={() => setFilterSelected(filterOption)}
                  >
                    {filterOption}
                  </SkillDrawerFilterOption>
                ))}
              </SkillsDrawerFiltersOptionsContainer>
            ) : (
              <></>
            )}
          </SkillsDrawerFiltersContainer>
          <SkillsDrawerListContainer>
            {!isLoading && !isIdle && data.any_includes_current_person && (
              <ToggleButton
                label={'Only what I share'}
                labelColor={'rgba(23, 28, 51, 0.5)'}
                onChange={(checked) => setFilterIncludeUser(checked)}
                checked={filterIncludeUser}
              />
            )}
            {!isLoading && !isIdle ? (
              <>
                {data.kyu_skills[filterSelected]
                  .filter((skill) => {
                    if (filterIncludeUser) {
                      return skill.includes_current_person;
                    }

                    return true;
                  })
                  .map((skill, index) => (
                    <SkillPill
                      key={index}
                      skill={skill}
                      userData={userData}
                      setShowingSkillDrawer={setShowingSkillDrawer}
                      setSkillDrawerSlug={setSkillDrawerSlug}
                    />
                  ))}
              </>
            ) : (
              <></>
            )}
          </SkillsDrawerListContainer>
        </>
      )}
    </SkillsDrawerContainer>
  );
}

interface SkillPillProps {
  skill: any;
  userData: User;
  setShowingSkillDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setSkillDrawerSlug: React.Dispatch<React.SetStateAction<string>>;
}

function SkillPill({ skill, userData, setShowingSkillDrawer, setSkillDrawerSlug }: SkillPillProps) {
  return (
    <SkillDrawerPill
      onClick={() => {
        setSkillDrawerSlug(skill.slug);
        setShowingSkillDrawer(true);
      }}
    >
      <SkillIcon width={13} height={13} fill={'#EB823D'} />
      <SkillDrawerText>{skill.name}</SkillDrawerText>
      {skill.includes_current_person ? (
        <PersonAvatar
          size={22}
          name={userData.first_name}
          lastName={userData.last_name}
          avatar={userData.profile_image}
          highlighted
          extraStyle={{
            position: 'absolute',
            right: '-30px',
          }}
        />
      ) : null}
    </SkillDrawerPill>
  );
}
