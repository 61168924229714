import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  profileDataSelector,
  setDreamProject,
  setDreamProjectInspo,
  setYearsExperience,
} from 'store/reducers/profile-editor/profile-editor-reducer';

import { useDreamProject } from './useDreamProject';

export default function useDreamProjectDrawer() {
  const dispatch = useDispatch();
  const { data, isLoading } = useDreamProject();
  const { years_experience, dream_project, dream_project_inspo } = useSelector(profileDataSelector);

  useEffect(() => {
    if (data) {
      dispatch(setYearsExperience(data.years_experience));
      if (data.dream_project) {
        dispatch(setDreamProject(data.dream_project));
      }

      dispatch(setDreamProjectInspo(data.dream_project_inspo));
    }
  }, [data]);

  return {
    years_experience,
    dream_project,
    dream_project_inspo,
    isLoading,
  };
}
