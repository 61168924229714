import React from 'react';

import OnboardingStepFive from './steps/Five';
import OnboardingStepFour from './steps/Four';
import OnboardingStepOne from './steps/One';
import OnboardingStepSix from './steps/Six';
import OnboardingStepThree from './steps/Three';
import OnboardingStepTwo from './steps/Two';

export const ComponentStepBased = new Map<number, JSX.Element>([
  [1, <OnboardingStepOne key="1" />],
  [2, <OnboardingStepTwo key="2" />],
  [3, <OnboardingStepThree key="3" />],
  [4, <OnboardingStepFour key="4" />],
  [5, <OnboardingStepFive key="5" />],
  [6, <OnboardingStepSix key="6" />],
]);

export const stepsTitles = new Map<number, string>([
  [1, 'Start here'],
  [2, 'kyu in a Nutshell'],
  [3, 'What is kyu OS?'],
  [4, 'Select a passion'],
  [5, 'Connections found'],
  [6, "Complete your profile"],
  [7, 'Show me My Reach'],
]);

export const backgroundPositionStepBased = new Map<number, string>([
  [1, '0%'],
  [2, '-737px'],
  [3, '-1737px'],
  [4, '-2737px'],
  [5, '-3737px'],
  [6, '100%'],
]);
