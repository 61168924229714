import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const JWT = cookies.get('auth-token');

export const slice = createSlice({
  name: 'user',
  initialState: {
    userData: {},
    token: JWT,
    behavioralId: null,
    isLoggedIn: undefined,
    tokenExpiresIn: null,
    searchHighlights: [],
    searchLenses: null,
    searchOnboardingStep: 1,
    profileCompletion: 0,
  },
  reducers: {
    setUserData: (state, action) => ({ ...state, userData: action.payload }),
    setToken: (state, action) => ({
      ...state,
      token: action.payload,
    }),
    setBehavioralId: (state, action) => ({
      ...state,
      behavioralId: action.payload,
    }),
    setIsLoggedIn: (state, action) => ({
      ...state,
      isLoggedIn: action.payload,
    }),
    setExpirationTime: (state, action) => ({
      ...state,
      tokenExpiresIn: action.payload,
    }),
    setSearchHighlights: (state, action) => ({
      ...state,
      searchHighlights: action.payload,
    }),
    setSearchLenses: (state, action) => ({
      ...state,
      searchLenses: action.payload,
    }),
    setSearchOnboardingStep: (state, action) => ({
      ...state,
      searchOnboardingStep: action.payload,
    }),
    setProfileCompletion: (state, action) => ({
      ...state,
      profileCompletion: action.payload,
    }),
  },
});

export const {
  setUserData,
  setIsLoggedIn,
  setToken,
  setBehavioralId,
  setExpirationTime,
  setSearchHighlights,
  setSearchLenses,
  setSearchOnboardingStep,
  setProfileCompletion,
} = slice.actions;

export const userDataSelector = (state) => state.user.userData;
export const behavioralIdSelector = (state) => state.user.behavioralId;
export const userTokenSelector = (state) => state.user.token;
export const isLoggedInSelector = (state) => state.user.isLoggedIn;
export const expirationTimeSelector = (state) => state.user.tokenExpiresIn;
export const searchHighlightsSelector = (state) => state.user.searchHighlights;
export const searchLensesSelector = (state) => state.user.searchLenses;
export const searchOnboardingStepSelector = (state) => state.user.searchOnboardingStep;
export const profileCompletionSelector = (state) => state.user.profileCompletion;

export default slice.reducer;
