import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const ProfileEditorDrawerLanguagesWrapper = styled.div`
  padding: 187px 36px;

  ${BREAKPOINTS.tablet} {
    padding: 45px 16px;
  }
`;

export const ProfileEditorDrawerLanguagesTitleContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 40px;
  }

  position: relative;
`;

export const ProfileEditorDrawerLanguagesTitleIconContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfileEditorDrawerLanguagesTitle = styled.h4`
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
`;

export const ProfileEditorDrawerLanguagesDataPointsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  margin: 24px 0px 41px 0px;
`;

export const ProfileEditorDrawerLanguagesAboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  background: linear-gradient(180deg, rgba(255, 210, 95, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;

  margin-top: 78px;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const ProfileEditorDrawerLanguagesAboutText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-text);

`;

// DATA POINT
interface ProfileEditorDrawerLanguagesDataPointWrapperProps {
  showContent: boolean;
}

export const ProfileEditorDrawerLanguagesDataPointWrapper = styled.div<ProfileEditorDrawerLanguagesDataPointWrapperProps>`
  width: 450px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 30px;
  background: #FFFFFF;

  box-shadow: 0px 0px 20px 5px rgba(50,63,93,0.1);
  border-radius: 10px;

  overflow: hidden;
  transition: max-height 200ms ease;
  max-height: ${({ showContent }) => showContent ? '221px' : '72px'};
`;

export const ProfileEditorDrawerLanguagesDataPointTopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

export const ProfileEditorDrawerLanguagesDataPointContentContainer = styled.div`
  width: 100%;
  transition: max-height 200ms ease-in-out;
  overflow: hidden;

  margin-top: 16px;
`;

export const ProfileEditorDrawerLanguagesDataPointTitle = styled.h5`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--color-text);

  & > span {
    font-weight: 700;
  }
`;

export const ProfileEditorDrawerLanguagesDataPointInputLabel = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
`;

export const ProfileEditorDrawerLanguagesDataPointInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 10px;

  width: 100%;

  background: rgba(173, 173, 173, 0.08);
  border-radius: 8px;

  margin-top: 16px;
`;

export const ProfileEditorDrawerLanguagesDataPointInputFieldContainer = styled.div`
  & > :not(:first-child) {
    margin-top: 16px;
  }
`;

export const ProfileEditorDrawerLanguagesPillsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  margin-top: 31px;
`;

export const ProfileEditorDrawerLanguagesPill = styled.div`
  width: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;

  background: var(--color-data-people-background);
  border-radius: 50px;

  transition: background 200ms ease-in;

  &:hover {
    background: var(--color-data-people-background-hover);
    padding: 8px 16px 8px 24px;
    & > div {
      display: flex;
    }
  }

  & > div {
    display: none;
  }
`;

export const ProfileEditorDrawerLanguagesPillIconContainer = styled.div`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 8px;

  cursor: pointer;
`;

export const ProfileEditorDrawerLanguagesPillText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: var(--color-text);
`;