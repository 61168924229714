import * as React from 'react';

const CopyIcon = ({ width = 22, height = 22, fill = '#171C33' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H12C12.2652 2 12.5196 2.10536 12.7071 2.29289C12.8946 2.48043 13 2.73478 13 3V4C13 4.55228 13.4477 5 14 5C14.5523 5 15 4.55228 15 4V3C15 2.20435 14.6839 1.44129 14.1213 0.87868C13.5587 0.31607 12.7956 0 12 0H3C2.20435 0 1.44129 0.31607 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3V12C0 12.7956 0.31607 13.5587 0.87868 14.1213C1.44129 14.6839 2.20435 15 3 15H4C4.55228 15 5 14.5523 5 14C5 13.4477 4.55228 13 4 13H3C2.73478 13 2.48043 12.8946 2.29289 12.7071C2.10536 12.5196 2 12.2652 2 12V3C2 2.73478 2.10536 2.48043 2.29289 2.29289ZM9 10C9 9.44771 9.44771 9 10 9H19C19.5523 9 20 9.44771 20 10V19C20 19.5523 19.5523 20 19 20H10C9.44771 20 9 19.5523 9 19V10ZM10 7C8.34315 7 7 8.34315 7 10V19C7 20.6569 8.34315 22 10 22H19C20.6569 22 22 20.6569 22 19V10C22 8.34315 20.6569 7 19 7H10Z"
        fill={fill}
      />
    </svg>
  );
};

export default CopyIcon;
