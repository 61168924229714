import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DownArrow, InfoIcon } from 'app/components/shared/icons';
import useOnClickOutside from 'hooks/click-outside';
import useCurrentSection from 'hooks/useCurrentSection';
import useDistances from 'hooks/useDistances';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { AboutDrawerData } from 'interfaces';

import AboutDrawer from '../AboutDrawer';
import SideDrawer from '../SideDrawer';
import { AboutButton, StickyNavButton, StickyNavWrapper } from './styled';

interface StickyNavSection {
  title: string;
  hash?: string;
  showIf?: (data: any) => void;
  element?: Element;
}

interface IProps {
  aboutData?: AboutDrawerData;
  containerRef?: React.RefObject<HTMLDivElement>;
  margin?: string;
  position?: 'fixed' | 'sticky';
  mode?: 'light' | 'dark';
  sections: StickyNavSection[];
  topDistance?: string;
  customDrawer?: React.ReactElement;
  useNewHook?: boolean;
  ignoreTopCondition?: boolean;
}

export default function StickyNav({
  aboutData,
  containerRef,
  margin,
  position = 'fixed',
  mode,
  sections = [],
  topDistance = '225px',
  customDrawer = null,
  useNewHook = false,
  ignoreTopCondition = false,
}: IProps) {
  const dropdownRef = useRef(null);
  const currentSection = useCurrentSection(sections.map((s) => s.hash));
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showAboutDrawer, setShowAboutDrawer] = useState<boolean>(false);
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const [elementInView, setElementInView] = useState<StickyNavSection>(sections[0]);
  const { push } = useHistory();
  const { currentTop } = useDistances(containerRef);
  const dimensions = useWindowDimensions();
  const isMobile = dimensions && dimensions.width <= 1024;

  const getElementInView = () => {
    const currentElementInView = sections.find((s) => {
      const ele = document.getElementById(s.hash);
      if (!ele) return;
      const eleRect = ele.getBoundingClientRect();
      return (
        eleRect.top >= 0 &&
        eleRect.left >= 0 &&
        eleRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        eleRect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    });
    if (currentElementInView) {
      setElementInView(currentElementInView);
    }
  };

  const getElementInContainerView = () => {
    if (!containerRef) return;
    const currentElementInView = sections.find((s) => {
      const ele = document.getElementById(s.hash);
      if (!ele) return;
      const eleRect = ele.getBoundingClientRect();
      return (
        eleRect.top >= 0 &&
        eleRect.left >= 0 &&
        eleRect.bottom <= containerRef.current.clientHeight &&
        eleRect.right <= containerRef.current.clientWidth
      );
    });
    if (currentElementInView) {
      setElementInView(currentElementInView);
    }
  };

  useEffect(() => {
    if (useNewHook) return;

    if (containerRef) {
      containerRef.current.addEventListener('scroll', getElementInContainerView);
      getElementInContainerView();
      return () => containerRef.current.removeEventListener('scroll', getElementInContainerView);
    } else {
      window.addEventListener('scroll', getElementInView);
      getElementInView();
      return () => window.removeEventListener('scroll', getElementInView);
    }
  }, [containerRef]);

  useEffect(() => {
    if (ignoreTopCondition && isMobile) return setShowMobileNav(true);

    if (560 >= currentTop && isMobile) {
      setShowMobileNav(false);
    } else setShowMobileNav(true);
  }, [currentTop, isMobile]);

  const handleClick = (hash) => {
    const element = document.getElementById(hash);
    if (element) {
      if (containerRef.current) {
        containerRef.current.scrollTo({
          top: element.getBoundingClientRect().top + containerRef.current.scrollTop - 300,
          behavior: 'smooth',
        });
      } else {
        window.scrollTo({
          top: element.getBoundingClientRect().top + window.scrollY - 100,
          behavior: 'smooth',
        });
      }
    }

    setElementInView(sections.find((s) => s.hash === hash));
    if (isMobile) return setIsOpen(false);
  };

  const currentSectionTitle = useMemo(() => {
    return sections.find((s) => s.hash === currentSection)?.title;
  }, [currentSection]);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  return isMobile && !showMobileNav ? null : (
    <>
      <StickyNavWrapper
        margin={margin}
        mode={mode}
        position={position}
        ref={dropdownRef}
        topDistance={topDistance}
      >
        {isMobile && !isOpen ? (
          <StickyNavButton
            isInView={true}
            key={useNewHook ? currentSection : elementInView.hash}
            margin="0"
            onClick={() => setIsOpen(true)}
          >
            <span>{useNewHook ? currentSectionTitle : elementInView.title}</span>
            <DownArrow />
          </StickyNavButton>
        ) : (
          <>
            {sections.map((s) => (
              <StickyNavButton
                isInView={useNewHook ? currentSection === s.hash : elementInView.hash === s.hash}
                key={s.hash}
                mode={mode}
                onClick={() => handleClick(s.hash)}
              >
                <span>{s.title}</span>
              </StickyNavButton>
            ))}
            {!!aboutData ? (
              <>
                <hr />
                <AboutButton
                  color={aboutData.buttonColor}
                  onClick={() => setShowAboutDrawer(!showAboutDrawer)}
                >
                  <span>ABOUT</span> <InfoIcon />
                </AboutButton>
              </>
            ) : null}
          </>
        )}
      </StickyNavWrapper>
      {showAboutDrawer ? (
        <SideDrawer closeDrawer={() => setShowAboutDrawer(false)} withClose>
          {customDrawer ? customDrawer : <AboutDrawer aboutData={aboutData} />}
        </SideDrawer>
      ) : null}
    </>
  );
}
