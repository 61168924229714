import React from 'react';

export default function ExpandChevronIcon({ width = 24, height = 24, fill = 'black' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2425 6.75811L17.2425 12.0008C17.2425 12.553 16.7948 13.0008 16.2425 13.0008C15.6902 13.0008 15.2425 12.553 15.2425 12.0008L15.2425 8.75811H11.9998C11.4476 8.75811 10.9998 8.3104 10.9998 7.75811C10.9998 7.20583 11.4476 6.75811 11.9998 6.75811L17.2425 6.75811Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75714 17.2425L11.9998 17.2425C12.5521 17.2425 12.9998 16.7948 12.9998 16.2425C12.9998 15.6902 12.5521 15.2425 11.9998 15.2425L8.75714 15.2425V11.9998C8.75714 11.4476 8.30942 10.9998 7.75714 10.9998C7.20485 10.9998 6.75714 11.4476 6.75714 11.9998L6.75714 17.2425Z"
        fill={fill}
      />
    </svg>
  );
}
