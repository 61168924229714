import React from 'react';

export default function OpenInNewIcon({ width = 16, height = 16, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77778 16C1.28889 16 0.870222 15.8261 0.521778 15.4782C0.173926 15.1298 0 14.7111 0 14.2222V1.77778C0 1.28889 0.173926 0.870222 0.521778 0.521778C0.870222 0.173926 1.28889 0 1.77778 0H8V1.77778H1.77778V14.2222H14.2222V8H16V14.2222C16 14.7111 15.8261 15.1298 15.4782 15.4782C15.1298 15.8261 14.7111 16 14.2222 16H1.77778ZM5.95556 11.2889L4.71111 10.0444L12.9778 1.77778H9.77778V0H16V6.22222H14.2222V3.02222L5.95556 11.2889Z"
        fill={fill}
      />
    </svg>
  );
}
