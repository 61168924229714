import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getBehavioralId } from 'services/behaviourals';
import { queryTypeSelector, setQueryType } from 'store/reducers/behavioral-reducer';
import { setBehavioralId } from 'store/reducers/user-reducer';
import { clearLocationState } from 'utils';

interface IGetBehavioralParams {
  entryPointType?: string;
  objectType?: string;
  uuid?: string;
  queryType?: string;
  enabled?: boolean;
  onBehavioralCreated?: (behavioralId: string) => void;
  ignoreAlreadyFetched?: boolean;
}

export default function useGetBehavioral(
  {
    objectType = null,
    entryPointType = null,
    uuid = null,
    queryType = null,
    enabled = true,
    onBehavioralCreated,
    ignoreAlreadyFetched = false,
  }: IGetBehavioralParams,
  dependencies: any[] = []
): Function {
  const dispatch = useDispatch();
  const alreadyFetched = useRef<boolean>(false);
  const location = useLocation<any>();
  const behavioralQueryType = useSelector(queryTypeSelector);
  const queryParams = new URLSearchParams(location.search);
  const entryPointTypeFromParams = queryParams.get('entry_point_type');

  const getBehavioralIdFunction = useCallback(
    (extraParams = {}) => {
      if (alreadyFetched.current && !ignoreAlreadyFetched) return;
      alreadyFetched.current = true;

      let finalBehavioralQueryType = behavioralQueryType;

      if (location.state && location.state.behavioralQueryType) {
        finalBehavioralQueryType = location.state.behavioralQueryType;
        clearLocationState();
      }

      if (!finalBehavioralQueryType) {
        finalBehavioralQueryType = 'direct';
        dispatch(setQueryType('click'));
      }

      getBehavioralId({
        uuid,
        object_type: objectType,
        entry_point_type: entryPointType || entryPointTypeFromParams,
        query_type: queryType || finalBehavioralQueryType,
        ...extraParams,
      }).then((response) => {
        if (response.ok && response.data && response.data.behavioral_id) {
          if (onBehavioralCreated) {
            onBehavioralCreated(response.data.behavioral_id);
          }
          dispatch(setBehavioralId(response.data.behavioral_id));
        }
      });

      if (['search', 'homepage-search', 'navbar-search'].includes(finalBehavioralQueryType)) {
        dispatch(setQueryType('click'));
      }
    },
    [dependencies]
  );

  useEffect(() => {
    if (enabled) {
      getBehavioralIdFunction();
    }
  }, []);

  return getBehavioralIdFunction;
}
