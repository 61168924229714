import { ActivityFeedActiveWorkResponse } from 'app/components/Home/interfaces';

import { http } from './http';

export const getSpotlight = () => {
  return http.get('activity_feed/spotlight');
};

export const getRadarActiveWork = () => {
  return http.get<ActivityFeedActiveWorkResponse>('activity_feed/active_work');
};

export const getRadarTrending = () => {
  return http.get('activity_feed/trending');
};

export const getMeetSomeoneNew = () => {
  return http.get('activity_feed/meet_someone_new');
};

export const getMapGallery = () => {
  return http.get('activity_feed/map_gallery');
};
