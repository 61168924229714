import React from 'react';

export default function EmailIcon({
  fill = '#171C33',
  width = 22,
  height = 18,
  onClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {},
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.1066 2.5539C2.27199 2.2269 2.61206 2 3 2H19C19.388 2 19.7281 2.22694 19.8934 2.55399L11.0001 8.77934L2.1066 2.5539ZM2 4.92059V15C2 15.5477 2.45228 16 3 16H19C19.5477 16 20 15.5477 20 15V4.92071L11.5735 10.8192C11.2292 11.0603 10.7709 11.0603 10.4266 10.8192L2 4.92059ZM22 3.01766V15C22 16.6523 20.6523 18 19 18H3C1.34772 18 0 16.6523 0 15V3C0 1.34772 1.34772 0 3 0H19C20.6443 0 21.9869 1.3347 21.9999 2.97604C22.0002 2.98992 22.0003 3.0038 22 3.01766Z"
        fill={fill}
      />
    </svg>
  );
}
