import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

interface DropdownWrapperProps {
  isOpen: boolean;
}

export const DropdownWrapper = styled.div<DropdownWrapperProps>`
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  > div.button-container {
    align-items: center;
    display: flex;
    span {
      color: var(--color-text);
      font-size: 15px;
      line-height: 20px;
      margin-right: 10px;
      white-space: nowrap;
    }
    > svg {
      > path {
        stroke: #fff;
      }
      transition: all 0.3s ease;
      width: 18px;
      ${({ isOpen }) => (isOpen ? 'transform: rotate(180deg);' : '')}
    }
  }
  > div.options-container {
    background: #fff;
    left: 0;
    max-height: 260px;
    overflow-y: auto;
    padding: 20px 16px;
    position: absolute;
    top: 25px;
    width: 150px;
    z-index: 9;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background-color: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5478a1;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #436790;
      border-radius: 20px;
    }
  }
  ${BREAKPOINTS.mobile} {
    >div.options-container {
      left: -20px;
    }
    > div.button-container {
      span {
        font-size: 14px;
        margin-right: 8px;
        text-transform: capitalize;
      }
    }
  }
`;

interface DropdownCheckboxProps {
  disabled?: boolean;
}

export const DropdownCheckbox = styled.div<DropdownCheckboxProps>`
  label {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    overflow: hidden;
    > span {
      font-size: 12px;
      line-height: 16px;
      width: 90px;
      ${({ disabled }) => (disabled ? 'color: var(--color-lightgray);' : '')}
    }
  }
  input {
    width: 1px;
    height: 1px;
    opacity: 0;
    display: none;
    &:checked + div {
      background: #ffc229;
    }
    &:checked ~ span {
      font-weight: 500;
    }
  }
  div {
    height: 15px;
    width: 15px;
    border-radius: 2px;
    border: 1px solid #c4c4c4;
  }
`;
