import React from 'react';

export default function FullHeartIcon({
  width = 24,
  height = 24,
  fill = '#D47563',
  border = '#D47563',
  className = '',
}) {
  if (fill === 'none') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6875 3C14.7516 3 13.0566 3.8325 12 5.23969C10.9434 3.8325 9.24844 3 7.3125 3C5.77146 3.00174 4.29404 3.61468 3.20436 4.70436C2.11468 5.79404 1.50174 7.27146 1.5 8.8125C1.5 15.375 11.2303 20.6869 11.6447 20.9062C11.7539 20.965 11.876 20.9958 12 20.9958C12.124 20.9958 12.2461 20.965 12.3553 20.9062C12.7697 20.6869 22.5 15.375 22.5 8.8125C22.4983 7.27146 21.8853 5.79404 20.7956 4.70436C19.706 3.61468 18.2285 3.00174 16.6875 3ZM12 19.3875C10.2881 18.39 3 13.8459 3 8.8125C3.00149 7.66921 3.45632 6.57317 4.26475 5.76475C5.07317 4.95632 6.16921 4.50149 7.3125 4.5C9.13594 4.5 10.6669 5.47125 11.3062 7.03125C11.3628 7.16881 11.4589 7.28646 11.5824 7.36926C11.7059 7.45207 11.8513 7.49627 12 7.49627C12.1487 7.49627 12.2941 7.45207 12.4176 7.36926C12.5411 7.28646 12.6372 7.16881 12.6937 7.03125C13.3331 5.46844 14.8641 4.5 16.6875 4.5C17.8308 4.50149 18.9268 4.95632 19.7353 5.76475C20.5437 6.57317 20.9985 7.66921 21 8.8125C21 13.8384 13.71 18.3891 12 19.3875Z"
          fill={border}
        />
        <path
          d="M12 19.3875C10.2881 18.39 3 13.8459 3 8.8125C3.00149 7.66921 3.45632 6.57317 4.26475 5.76475C5.07317 4.95632 6.16921 4.50149 7.3125 4.5C9.13594 4.5 10.6669 5.47125 11.3062 7.03125C11.3628 7.16881 11.4589 7.28646 11.5824 7.36926C11.7059 7.45207 11.8513 7.49627 12 7.49627C12.1487 7.49627 12.2941 7.45207 12.4176 7.36926C12.5411 7.28646 12.6372 7.16881 12.6937 7.03125C13.3331 5.46844 14.8641 4.5 16.6875 4.5C17.8308 4.50149 18.9268 4.95632 19.7353 5.76475C20.5437 6.57317 20.9985 7.66921 21 8.8125C21 13.8384 13.71 18.3891 12 19.3875Z"
          fill={fill}
        />
      </svg>
    );
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.34402 1.34961C9.15426 1.35052 9.93105 1.6728 10.504 2.24572C11.0769 2.81865 11.3992 3.59545 11.4001 4.40569C11.4001 6.11651 10.137 7.6398 8.89044 8.73148C7.63597 9.83009 6.3566 10.5278 6.24794 10.5853C6.17171 10.6261 6.08658 10.6475 6.0001 10.6475C5.91346 10.6475 5.82816 10.626 5.75182 10.5851C5.64024 10.5259 4.36258 9.82865 3.10975 8.73148C1.86319 7.6398 0.600098 6.11668 0.600098 4.40586C0.601011 3.59562 0.923283 2.81865 1.49621 2.24572C2.06914 1.6728 2.84594 1.35052 3.65618 1.34961C4.59838 1.34961 5.43802 1.72756 6.0001 2.38028C6.56217 1.72756 7.40181 1.34961 8.34402 1.34961ZM6.09648 2.49844C6.06328 2.53777 6.03114 2.57811 6.0001 2.61945C5.96906 2.57811 5.93692 2.53777 5.90371 2.49844C5.37181 1.86838 4.56744 1.49961 3.65635 1.49961C2.88583 1.50048 2.14712 1.80695 1.60228 2.35179C1.05744 2.89663 0.750966 3.63534 0.750098 4.40586C0.750098 7.68711 5.61525 10.343 5.82244 10.4527C5.87705 10.4821 5.93809 10.4975 6.0001 10.4975C6.06211 10.4975 6.12315 10.4821 6.17775 10.4527C6.38494 10.343 11.2501 7.68711 11.2501 4.40586C11.2492 3.63534 10.9428 2.89663 10.3979 2.35179C9.85308 1.80695 9.11437 1.50048 8.34385 1.49961C7.43275 1.49961 6.62838 1.86838 6.09648 2.49844Z"
        fill={border}
      />
      <path
        d="M6.09648 2.49844C6.06328 2.53777 6.03114 2.57811 6.0001 2.61945C5.96906 2.57811 5.93692 2.53777 5.90371 2.49844C5.37181 1.86838 4.56744 1.49961 3.65635 1.49961C2.88583 1.50048 2.14712 1.80695 1.60228 2.35179C1.05744 2.89663 0.750966 3.63534 0.750098 4.40586C0.750098 7.68711 5.61525 10.343 5.82244 10.4527C5.87705 10.4821 5.93809 10.4975 6.0001 10.4975C6.06211 10.4975 6.12315 10.4821 6.17775 10.4527C6.38494 10.343 11.2501 7.68711 11.2501 4.40586C11.2492 3.63534 10.9428 2.89663 10.3979 2.35179C9.85308 1.80695 9.11437 1.50048 8.34385 1.49961C7.43275 1.49961 6.62838 1.86838 6.09648 2.49844Z"
        fill={fill}
      />
    </svg>
  );
}
