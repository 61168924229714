import { useCallback, useEffect } from 'react';

export default function useWindowResizeWithRef(callback: (e?: Element) => void) {
  const ref = useCallback((node) => {
    if (node) {
      callback(node);

      window.addEventListener('resize', () => callback(node));
    }
  }, []);

  useEffect(() => {
    return () => window.removeEventListener('resize', () => callback());
  }, []);

  return ref;
}
