import React from 'react';
import { Helmet } from 'react-helmet-async';

interface IProps {
  src: string;
  title?: string;
  width?: number | string;
  height?: number | string;
}

export default function VimeoVideo({ src, title, width, height }: IProps) {
  return (
    <>
      <Helmet>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </Helmet>
      <iframe
        src={src}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title={title}
        width={width}
        height={height}
      ></iframe>
    </>
  );
}
