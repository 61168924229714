import React, { useContext } from 'react';

import { DetailsPageContext } from 'app/components/DetailsPages/shared/DetailsPageContext';
import useUserData from 'hooks/useUserData';

import {
  LanguagesDrawerDescription,
  LanguagesDrawerContainer,
  LanguagesDrawerTitle,
  LanguagesDrawerTitleContainer,
  LanguagesDrawerListContainer,
  LanguageDrawerListItem,
} from './styled';

interface LanguagesDrawerProps {
  languages: any[];
  title: string;
  description: string;
}

export default function LanguagesDrawer({ languages, title, description }: LanguagesDrawerProps) {
  const context = useContext(DetailsPageContext);
  const userData = useUserData();

  return (
    <LanguagesDrawerContainer>
      <LanguagesDrawerTitleContainer>
        <LanguagesDrawerTitle>
          {title ?? `Languages of ${context.data.name || userData.kyu_company?.name}`}
        </LanguagesDrawerTitle>
        <LanguagesDrawerDescription>
          {description ??
            `These are all the languages that people at ${
              context.data.name || userData.kyu_company?.name
            } added to their profiles.`}
        </LanguagesDrawerDescription>
      </LanguagesDrawerTitleContainer>
      <LanguagesDrawerListContainer>
        {languages.map((language, index) => (
          <LanguageDrawerListItem key={index}>{language.name}</LanguageDrawerListItem>
        ))}
      </LanguagesDrawerListContainer>
    </LanguagesDrawerContainer>
  );
}
