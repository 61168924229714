import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "behavioral",
  initialState: {
    queryType: null,
  },
  reducers: {
    setQueryType: (state, action: { payload: string }) => ({
      ...state,
      queryType: action.payload,
    }),
  },
});

export const { setQueryType } = slice.actions;

export const queryTypeSelector = (state) => state.behavioral.queryType;

export default slice.reducer;
