import React from 'react';

export default function RemoveIcon({
  width = 12,
  height = 13,
  fill = '#171C33',
  fillOpacity = '0.5',
  hideBorder = false,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!hideBorder ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2C3.51472 2 1.5 4.01472 1.5 6.5C1.5 8.98528 3.51472 11 6 11C8.48528 11 10.5 8.98528 10.5 6.5C10.5 4.01472 8.48528 2 6 2ZM0.5 6.5C0.5 3.46243 2.96243 1 6 1C9.03757 1 11.5 3.46243 11.5 6.5C11.5 9.53757 9.03757 12 6 12C2.96243 12 0.5 9.53757 0.5 6.5Z"
          fill={fill}
          fillOpacity={fillOpacity}
        />
      ) : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6.5C3.5 6.22386 3.72386 6 4 6H8C8.27614 6 8.5 6.22386 8.5 6.5C8.5 6.77614 8.27614 7 8 7H4C3.72386 7 3.5 6.77614 3.5 6.5Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}
