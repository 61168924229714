import React from 'react';

export default function Calendar({ width = 16, height = 16, fill = '#171C33' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16">
      <path
        className="calendar-path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.333 1.33341C11.333 0.965225 11.0345 0.666748 10.6663 0.666748C10.2982 0.666748 9.99967 0.965225 9.99967 1.33341V2.00008H5.99967V1.33341C5.99967 0.965225 5.7012 0.666748 5.33301 0.666748C4.96482 0.666748 4.66634 0.965225 4.66634 1.33341V2.00008H3.33301C2.22844 2.00008 1.33301 2.89551 1.33301 4.00008V6.66675V13.3334C1.33301 14.438 2.22844 15.3334 3.33301 15.3334H12.6663C13.7709 15.3334 14.6663 14.438 14.6663 13.3334V6.66675V4.00008C14.6663 2.89551 13.7709 2.00008 12.6663 2.00008H11.333V1.33341ZM13.333 6.00008V4.00008C13.333 3.63189 13.0345 3.33341 12.6663 3.33341H11.333V4.00008C11.333 4.36827 11.0345 4.66675 10.6663 4.66675C10.2982 4.66675 9.99967 4.36827 9.99967 4.00008V3.33341H5.99967V4.00008C5.99967 4.36827 5.7012 4.66675 5.33301 4.66675C4.96482 4.66675 4.66634 4.36827 4.66634 4.00008V3.33341H3.33301C2.96482 3.33341 2.66634 3.63189 2.66634 4.00008V6.00008H13.333ZM2.66634 7.33342H13.333V13.3334C13.333 13.7016 13.0345 14.0001 12.6663 14.0001H3.33301C2.96482 14.0001 2.66634 13.7016 2.66634 13.3334V7.33342Z"
        fill={fill}
      />
    </svg>
  );
}
