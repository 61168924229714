export const theme = {
  human: {
    headerBackground: 'linear-gradient(180deg, rgba(255, 194, 41, 0.5) -42.47%, rgba(255, 194, 41, 0.085) 100%), #FFFFFF',
    headerBottomBorder: 'var(--color-data-people-background)',
    bottomBackground: 'linear-gradient(180deg, rgba(255, 210, 95, 0) 0%, rgba(255, 210, 95, 0.5) 100%), rgba(255, 255, 255, 0.3)',
  },
  professional: {
    headerBackground: 'linear-gradient(180deg, rgba(156, 202, 255, 0.5) 0%, rgba(156, 202, 255, 0) 107.27%), #FFFFFF',
    headerBottomBorder: 'var(--color-data-company-background)',
    bottomBackground: 'linear-gradient(180deg, rgba(156, 202, 255, 0) 0%, rgba(156, 202, 255, 0.5) 100%), rgba(255, 255, 255, 0.3)',
  }
}