import React from 'react';

import {
  AboutKyuOsHeroContainer,
  AboutKyuOsHeroSubtitle,
  AboutKyuOsHeroTitle,
  AboutKyuOsHeroWrapper,
  AboutKyuOsHeroContentContainer,
  AboutKyuOsHeroImage,
} from './styled';

export default function AboutKyuOsHero() {
  return (
    <AboutKyuOsHeroWrapper id="snapshot">
      <AboutKyuOsHeroContentContainer>
        <AboutKyuOsHeroContainer>
          <AboutKyuOsHeroTitle>kyu 101</AboutKyuOsHeroTitle>
          <AboutKyuOsHeroSubtitle>Make the most out of the kyu network</AboutKyuOsHeroSubtitle>
        </AboutKyuOsHeroContainer>
        <AboutKyuOsHeroImage alt="about" src="/images/about-kyu-os/hero-image.svg?t=123" />
      </AboutKyuOsHeroContentContainer>
    </AboutKyuOsHeroWrapper>
  );
}
