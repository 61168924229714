import { TotalWithSideIconTitle } from 'app/components/CommonStyled/Total/styled';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

import { GlobalDrawerContainer } from '../styled';

interface GlobalPersonDrawerContainerProps {
  isAlumni?: boolean;
}

export const GlobalPersonDrawerContainer = styled(
  GlobalDrawerContainer
)<GlobalPersonDrawerContainerProps>`
  background: ${({ isAlumni, theme }) =>
    isAlumni
      ? `linear-gradient(180deg, rgba(255, 255, 255, 0.108) 0%, rgba(135, 135, 135, 0.2) 100%), #FFFFFF`
      : theme.primaryBackground};
  justify-content: unset;

  ${({ theme }) =>
    theme.hasChildren &&
    `
    padding-top: 0px !important;
    overflow-x: hidden;
  `}

  ${BREAKPOINTS.mobile} {
    padding: 32px 16px;
  }
`;

export const GlobalPersonDrawerTopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-bottom: 17px;
  }
  margin-bottom: 43px;
`;

export const GlobalPersonDrawerBasicInformationContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0px;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const GlobalPersonDrawerProfileImage = styled.img`
  border: 2px solid #ffc229;
  object-fit: cover;
  width: 88px;
  height: 88px;
  border-radius: 100%;
`;

export const GlobalPersonDrawerDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const GlobalPersonDrawerFullName = styled.h3`
  font-weight: 300;
  font-size: 32px;
  line-height: 42px;
  color: ${({ theme }) => theme.primaryText};
`;

interface GlobalPersonDrawerRoleProps {
  isAlumni?: boolean;
}

export const GlobalPersonDrawerRole = styled.h4<GlobalPersonDrawerRoleProps>`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;
  & > span {
    color: ${({ theme }) => theme.primaryText};
  }
  display: flex;

  ${({ isAlumni }) =>
    isAlumni &&
    `
    opacity: 0.5;
    & > span {
      display: flex;
      
      & > img {
        margin-right: 8px;
      }
    }
  `}

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const GlobalPersonDrawerCompanyLogo = styled.img`
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: 2px solid #5478a1;
  border-radius: 1000px;

  transform: translateY(7px);
`;

export const GlobalPersonDrawerEmail = styled.a`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryText};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
`;

interface GlobalPersonDrawerDetailsContainerProps {
  amountOfDetails: number;
}

export const GlobalPersonDrawerDetailsContainer = styled.div<GlobalPersonDrawerDetailsContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 32px;
  }
`;

export const GlobalPersonDrawerDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const GlobalPersonDrawerParagraph = styled.p`
  font-weight: 300;
  font-size: 14px;
  color: ${({ theme }) => theme.primaryText};
`;

export const GlobalPersonDrawerDetailText = styled(GlobalPersonDrawerParagraph)`
  line-height: 18px;
`;

export const GlobalPersonDrawerMidSection = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 27px;
  }
`;

export const GlobalPersonDrawerInformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 27px;
  }
`;

export const GlobalPersonDrawerBio = styled(GlobalPersonDrawerParagraph)`
  line-height: 23px;
  font-size: 18px;
`;

export const GlobalPersonDrawerEmptyProfileText = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: var(--color-primary);
  opacity: 0.5;
`;

export const GlobalPersonDrawerDreamProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 24px 16px;
  & > :not(:last-child) {
    margin-bottom: 8px;
  }
  background: #ffc229;
`;

export const GlobalPersonDrawerDreamProjectTitle = styled(GlobalPersonDrawerParagraph)`
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(23, 28, 51, 0.5);
`;

export const GlobalPersonDrawerDreamProjectDescription = styled(GlobalPersonDrawerParagraph)`
  line-height: 18px;
  word-break: break-word;
`;

export const GlobalPersonDrawerDataTypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 20px;
  }
`;

export const GlobalPersonDrawerDataType = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const GlobalPersonDrawerDataTypeCounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const GlobalPersonDrawerDataTypeCounter = styled.span`
  font-weight: 300;
  font-size: 38px;
  line-height: 42px;
  color: ${({ theme }) => theme.primaryText};
`;

export const GlobalPersonDrawerDataTypeLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(23, 28, 51, 0.5);
`;

export const GlobalPersonDrawerBottomSection = styled.div`
  height: 100%;
  max-width: 426px;

  display: flex;
  flex-direction: row;
  gap: 16px;

  margin-top: 48px;
  align-items: flex-end;
`;

export const GlobalPersonDrawerCatchPhrase = styled.h3`
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: rgba(23, 28, 51, 0.5);
`;

export const GlobalPersonDrawerProfileButton = styled.button`
  flex: 1;
  height: 48px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 16px 48px;

  border-radius: 100px;
  border: 2px solid var(--color-primary);

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.4px;
  color: var(--color-primary);
  white-space: nowrap;

  transition: background 200ms ease;
  &:hover {
    background: var(--color-data-people-background-secondary);
  }

  ${BREAKPOINTS.mobile} {
    padding: 16px 24px;
  }
`;

export const GlobalPersonDrawerContactButton = styled.button`
  flex: 1;
  height: 48px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  padding: 16px 48px;

  border-radius: 100px;
  background: var(--color-data-people-background);
  transition: background 200ms ease;

  &:hover {
    background: var(--color-data-people-background-hover);
  }


  ${BREAKPOINTS.mobile} {
    padding: 16px 24px;
  }
`;

export const GlobalPersonDrawerContactButtonLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-primary);
  letter-spacing: 1.4px;
`;

export const GlobalPersonDrawerSharedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 34px;
  }
  padding: 17px 32px;
  background: rgba(255, 194, 41, 0.15);
  border-radius: 8px;
`;

export const GlobalPersonDrawerSharedTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const GlobalPersonDrawerSharedTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const GlobalPersonDrawerSharedAvatarsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  & > *:not(:last-child) {
    margin-left: -8px;
  }
`;

// GET IN TOUCH
export const GlobalPersonDrawerGetInTouchWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 16px;

  background: var(--color-data-people-background);
  border-radius: 8px;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const GlobalPersonDrawerGetInTouchTitle = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: ${({ theme }) => theme.getInTouchText};
`;

export const GlobalPersonDrawerGetInTouchButton = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid ${({ theme }) => theme.getInTouchText};
  border-radius: 100px;

  padding: 16px 40px;

  max-height: 48px;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  cursor: pointer;
`;

export const GlobalPersonDrawerGetInTouchButtonText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.getInTouchText};
`;

// THINGS IN COMMON
export const GlobalPersonDrawerCommonThingsWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.thingsInCommonBackground};
  border-radius: 8px;

  padding: 16px 0px;

  & > :not(:last-child) {
    margin-bottom: 34px;
  }

  transition: background 200ms ease;

  &:hover {
    background: ${({ theme }) => theme.thingsInCommonBackgroundHover};

    & > button {
      background: rgba(255, 194, 41, 0.45);
    }
  }
`;

export const GlobalPersonDrawerCommonThingsTopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

interface GlobalPersonDrawerCommonThingsSparkleContainerProps {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

export const GlobalPersonDrawerCommonThingsSparkleContainer = styled.div<GlobalPersonDrawerCommonThingsSparkleContainerProps>`
  position: absolute;
  ${({ top, right, bottom, left }) => `
    ${top && `top: ${top}px;`}
    ${right && `right: ${right}px;`}
    ${bottom && `bottom: ${bottom}px;`}
    ${left && `left: ${left}px;`}
  `}
`;

export const GlobalPersonDrawerCommonThingsTitle = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.thingsInCommonText};
`;

export const GlobalPersonDrawerCommonThingsPeopleAvatarsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > :not(:last-child) {
    margin-right: -8px;
  }
`;

export const GlobalPersonDrawerCommonThingsBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  & ${TotalWithSideIconTitle} {
    max-width: 100px;
  }
`;

export const GlobalPersonDrawerCommonThingsSeeMoreButton = styled.button`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;

  transition: background 200ms ease;

  border-radius: 0px 0px 8px 8px;
  background: ${({ theme }) => theme.thingsInCommonBackground};
`;

export const GlobalPersonDrawerCommonThingsSeeMoreButtonText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  text-align: center;

  color: var(--color-text);
`;

interface GlobalPersonDrawerCopiedSignProps {
  top: number;
  left: number;
}

export const GlobalPersonDrawerCopiedSign = styled.span<GlobalPersonDrawerCopiedSignProps>`
  background: var(--color-text);
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  ${({ top, left }) => `
    left: ${left}px;
    top: ${top}px;
  `}
  line-height: 18px;
  opacity: 0.85;
  padding: 8px 12px;
  position: absolute;
  z-index: 9999999999999;
`;

// CHILDREN
export const GlobalPersonDrawerChildrenContainer = styled.div`
  width: calc(100% + 96px);

  margin-bottom: 32px;

  transform: translateX(-48px);

  ${BREAKPOINTS.mobile} {
    width: calc(100% + 32px);
    transform: translateX(-16px);
  }
`;
