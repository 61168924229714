import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsMapWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
`;

export const AboutKyuOsMapImage = styled.img`
  width: 1609px;
  height: 633px;

  ${BREAKPOINTS.tablet} {
    width: 453px;
    height: 178px;
  }
`;
