import React from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

import { currentStepSelector } from 'store/reducers/auth-reducer';

import { AuthContainer, AuthWrapper } from './styled';

const StepOne = React.lazy(() => import('./steps/one'));
const StepTwo = React.lazy(() => import('./steps/two'));

export const MAGIC_LINK = 'MAGIC_LINK';
export const GOOGLE = 'GOOGLE';

export default function Auth({ data }) {
  const currentStep = useSelector(currentStepSelector);

  const ComponentStepBased = new Map<number, JSX.Element>([
    [1, <StepOne data={data} key="1" />],
    [2, <StepTwo data={data} key="2" />],
  ]);

  return ReactDOM.createPortal(
    <AuthWrapper>
      <AuthContainer>{ComponentStepBased.get(currentStep)}</AuthContainer>
    </AuthWrapper>,
    document.querySelector('#root')
  );
}
