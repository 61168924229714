import styled from 'styled-components';

export const GlobalSkeletonDrawerWrapper = styled.div`
  & > :first-child {
    margin-bottom: 65px;
  }

  & > :nth-child(2) {
    margin-bottom: 243px;
  }
`;

export const GlobalSkeletonDrawerTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const GlobalSkeletonDrawerTopContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const GlobalSkeletonDrawerMiddleContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 212px;
  }
`;

export const GlobalSkeletonDrawerMiddleListContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const GlobalSkeletonDrawerMiddleCounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const GlobalSkeletonDrawerMiddleCounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const GlobalSkeletonDrawerBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;
