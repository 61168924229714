import { ForwardRefComponent, HTMLMotionProps, motion } from 'framer-motion';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const OnboardingStepOneWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OnboardingStepOneContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 181px 192px 305px 189px;

  ${BREAKPOINTS.mobile} {
    padding: 136px 24px 32px 24px;
  }

  & > :first-child {
    margin-bottom: 75px;
  }

  & > :last-child {
    margin-top: 16px;
  }
`;

export const OnboardingStepOneKyuLogoContainer = styled(motion.div).attrs(
  (props): HTMLMotionProps<'button'> => {
    const isMobile = props.theme.isMobile;

    const desktopVariant: HTMLMotionProps<'button'> = {
      variants: {
        next: {
          position: 'absolute',
          top: '50%',
          left: '88px',
          width: '80px',
          height: '80px',
          transition: {
            duration: 0.3,
            ease: 'easeInOut',
            bounce: 0,
          },
        },
      },
    };

    const mobileVariant: HTMLMotionProps<'button'> = {
      variants: {
        next: {
          position: 'absolute',
          top: '50%',
          left: '-20px',
          width: '60px',
          height: '60px',
          transition: {
            duration: 0.3,
            ease: 'easeInOut',
            bounce: 0,
          },
        },
      },
    };

    return isMobile ? mobileVariant : desktopVariant;
  }
)`
  position: absolute;
  top: -29px;
  right: 50%;
  left: calc(50% - 97px);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 194px;
  height: 194px;

  border-radius: 100%;
  background: #ffffff;

  svg {
    margin-top: 10%;
  }

  ${BREAKPOINTS.mobile} {
    width: 136px;
    height: 136px;
    left: calc(50% - 68px);

    svg {
      width: 92px;
      height: 29px;
    }
  }
`;

export const OnboardingStepOneKyuLogoDescription = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  text-align: center;

  color: #323f5d;
  max-width: 300px;

  ${BREAKPOINTS.mobile} {
    font-size: 14px;
  }
`;

export const OnboardingStepOneTitle = styled.h1`
  width: 100%;

  font-weight: 400;
  font-size: 64px;
  line-height: normal;
  text-align: center;
  color: var(--color-text);

  ${BREAKPOINTS.mobile} {
    font-size: 38px;
  }
`;

export const OnboardingStepOneSubtitle = styled.h2`
  font-weight: 400;
  font-size: 24px;
  line-height: normal;
  text-align: center;
  color: var(--color-text);

  ${BREAKPOINTS.mobile} {
    font-size: 20px;
  }
`;

export const OnboardingStepOneNextButton = styled(motion.button).attrs(
  (props): HTMLMotionProps<'button'> => {
    const isMobile = props.theme.isMobile;

    const desktopVariant: HTMLMotionProps<'button'> = {
      variants: {
        next: {
          position: 'absolute',
          top: '50%',
          right: '88px',
          width: '80px',
          height: '80px',
          transform: 'unset',
          transition: {
            duration: 0.3,
            ease: 'easeInOut',
            bounce: 0,
          },
        },
      },
    };

    const mobileVariant: HTMLMotionProps<'button'> = {
      variants: {
        next: {
          position: 'absolute',
          top: '50%',
          right: '-20px',
          width: '60px',
          height: '60px',
          transform: 'unset',
          padding: 'unset',
          transition: {
            duration: 0.3,
            ease: 'easeInOut',
            bounce: 0,
          },
        },
      },
    };

    return isMobile ? mobileVariant : desktopVariant;
  }
)`
  width: max-content;

  position: absolute;
  bottom: 88px;
  right: 50%;
  transform: translateX(50%);

  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 16px 32px 16px 48px;

  border-radius: 100px;
  background: #ffffff;
  transition: 0.3s background ease;

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const OnboardingStepOneNextButtonText = styled.span`
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: var(--color-text);
`;
