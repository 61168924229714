import styled from 'styled-components';

export const ProfileEditInputSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  position: relative;
`;

interface ProfileEditInputSelectContainerProps {
  isValid: boolean;
}

export const ProfileEditInputSelectContainer = styled.input<ProfileEditInputSelectContainerProps>`
  user-select: text;
  height: 56px;
  width: 100%;

  background: rgba(173, 173, 173, 0.08);
  border-radius: 8px;

  padding: 15px 10px;

  ${({ isValid }) =>
    !isValid &&
    `
    border: 1px solid var(--color-error) !important;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    background: rgba(173, 173, 173, 0.3);
    border: 1px solid rgba(23, 28, 51, 0.5);
  `}

  font-weight: 400;
  font-size: 18px;
  line-height: 23px;

  & > :focus {
    border: 1px solid var(--color-data-company-background-hover);
  }

  position: relative;

  cursor: pointer;
`;

export const ProfileEditInputSelectIconContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 10px;

  width: 16px;
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProfileEditInputSelectOptionsWrapper = styled.div`
  position: absolute;
  top: calc(56px + 4px);
  left: 0px;
  right: 0px;

  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 4px;

  background: #ffffff;
  box-shadow: 5px 0px 20px -10px rgba(50, 63, 93, 0.3);
  border-radius: 8px;

  overflow-y: scroll;

  max-height: 226px;

  z-index: 1000;

  & > svg {
    margin-left: 10px;
  }
`;

export const ProfileEditInputSelectOptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;

  &:hover {
    background: #f7f7f7;
  }

  cursor: pointer;
`;

interface ProfileEditInputSelectOptionContainerTextProps {
  highlighted?: boolean;
  isCreated?: boolean;
}

export const ProfileEditInputSelectOptionContainerText = styled.span<ProfileEditInputSelectOptionContainerTextProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  ${({ highlighted }) => highlighted && `background: rgba(255, 210, 95, 0.4);`}
  ${({ isCreated }) =>
    isCreated &&
    `
    max-width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    direction: rtl;
    white-space: pre;
  `}

  white-space: pre-wrap;
`;

export const ProfileEditInputSelectPlaceholder = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: rgba(23, 28, 51, 0.5);

  position: absolute;
  top: 15px;
  left: 10px;

  user-select: none;
  pointer-events: none;
`;

export const ProfileEditInputSelectLimit = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(23, 28, 51, 0.5);
`;

export const ProfileEditInputSelectErrorContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const ProfileEditInputSelectError = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-error);
`;

export const ProfileEditInputSelectAddOption = styled.span`
  position: absolute;
  top: 8px;
  right: 20px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #000000;
`;
