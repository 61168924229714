import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "banner",
  initialState: {
    isBannerOpen: false,
  },
  reducers: {
    setIsBannerOpen: (state, action: { payload: boolean }) => ({
      ...state,
      isBannerOpen: action.payload,
    }),
  },
});

export const { setIsBannerOpen } = slice.actions;

export const isBannerOpenSelector = (state) => state.banner.isBannerOpen;

export default slice.reducer;
