import React from 'react';
import { useHistory } from 'react-router-dom';

import { limitText } from 'utils';

import {
  NavTileDiscoverContainer,
  NavTileDiscoverText,
  NavTileIconAndTitleContainer,
  NavTileIconContainer,
  NavTileText,
  NavTileTextContainer,
  NavTileTitle,
  NavTileWrapper,
} from './styled';

interface INavTile {
  title: string;
  icon: JSX.Element;
  text: string;
  route?: {
    pathname: string;
    state?: any;
    hash?: string;
  };
}

interface NavTileProps {
  navTile: INavTile;
  isDiscover?: boolean;
  onClick?: (navTile?: INavTile) => void;
}

export default function NavTile({ navTile, isDiscover = false, onClick }: NavTileProps) {
  const { push } = useHistory();

  const handleClick = () => {
    if (onClick) {
      onClick(navTile);
    }

    if (navTile.route) {
      push(navTile.route);
    }
  };

  return (
    <NavTileWrapper isDiscover={isDiscover} onClick={handleClick}>
      <NavTileIconAndTitleContainer>
        <NavTileIconContainer className="navtile-icon-container">
          {React.cloneElement(navTile.icon, {
            width: 16,
            height: 16,
            fill: 'var(--color-primary)',
          })}
        </NavTileIconContainer>
        <NavTileTitle>{navTile.title}</NavTileTitle>
      </NavTileIconAndTitleContainer>
      <NavTileTextContainer className="navtile-text">
        <NavTileText isDiscover={isDiscover}>{navTile.text}</NavTileText>
      </NavTileTextContainer>
      {isDiscover ? (
        <NavTileDiscoverContainer>
          <NavTileDiscoverText>DISCOVER</NavTileDiscoverText>
        </NavTileDiscoverContainer>
      ) : null}
    </NavTileWrapper>
  );
}
