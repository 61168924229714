import React from 'react';

export default function PaperPlaneIcon({ width = 21, height = 20, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2593 2.24083C18.1021 2.08375 17.9059 1.97141 17.6908 1.91538C17.4758 1.85935 17.2497 1.86167 17.0358 1.92208H17.0241L2.02879 6.47208C1.78469 6.54228 1.56777 6.68517 1.40691 6.88173C1.24605 7.07829 1.14889 7.31919 1.12836 7.57235C1.10783 7.82551 1.1649 8.07892 1.29199 8.29883C1.41907 8.51874 1.61013 8.69472 1.83973 8.80333L8.52879 11.9713L11.6968 18.6604C11.7968 18.8746 11.956 19.0557 12.1556 19.1824C12.3552 19.309 12.5869 19.3759 12.8233 19.3752C12.8593 19.3752 12.8952 19.3736 12.9311 19.3705C13.1834 19.3501 13.4234 19.253 13.6189 19.0923C13.8144 18.9315 13.9561 18.7149 14.0249 18.4713L18.5718 3.47599V3.46427C18.633 3.25096 18.6363 3.0252 18.5813 2.81019C18.5264 2.59518 18.4153 2.39866 18.2593 2.24083ZM12.8304 18.1135L12.8264 18.1244L9.75145 11.633L13.4421 7.94161C13.5543 7.82345 13.616 7.66611 13.6139 7.50313C13.6118 7.34015 13.5462 7.18444 13.4309 7.06919C13.3157 6.95394 13.1599 6.88827 12.997 6.88618C12.834 6.8841 12.6766 6.94576 12.5585 7.05802L8.86707 10.7486L2.37488 7.67365H2.38582L17.3749 3.12521L12.8304 18.1135Z"
        fill={fill}
      />
    </svg>
  );
}
