import styled from "styled-components";
import { BREAKPOINTS } from "styles/media";

interface StickyNavWrapperProps {
  margin?: string;
  mode: string;
  position: "fixed" | "sticky";
  topDistance: string;
}

const hrColors = {
  light: "rgba(0, 0, 0, 0.05)",
  dark: "rgba(255, 255, 255, 0.15)",
};

export const StickyNavWrapper = styled.nav<StickyNavWrapperProps>`
  display: flex;
  flex-direction: column;
  left: 50px;
  margin: ${({ margin }) => margin};
  position: ${({ position }) => position};
  top: ${({ topDistance }) => topDistance};
  width: 12.5%;
  z-index: 10;
  > hr {
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin: 4px 0 10px;
    width: 100%;
  }
  ${BREAKPOINTS.tablet} {
    width: calc(100% - 96px);

    position: fixed;
    top: 10px;
    left: 48px;

    margin: 0px;
    padding: 16px;

    background: #ffffff;
    border-radius: 30px;
    box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.25);
  }
  ${BREAKPOINTS.mobile} {
    background: #ffffff;
    border-radius: 30px;
    box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.25);
    left: calc(50% - 170px);
    padding: 11px 24px;
    top: 10px;
    width: 340px;
  }
`;
interface StickyNavButtonProps {
  isInView?: boolean;
  margin?: string;
  mode?: "light" | "dark";
}

const colors = {
  light: "var(--color-text)",
  dark: "#fff",
};

export const StickyNavButton = styled.button<StickyNavButtonProps>`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: ${({ margin = "0 0 6px 0" }) => margin};
  opacity: ${({ isInView }) => (isInView ? "1" : "0.5")};
  padding: 0 2px;
  > span {
    color: ${({ mode = "light" }) => colors[mode]};
    font-size: 18px;
    line-height: 23px;
    text-align: left;
  }
  &:hover {
    opacity: 1;
  }
  ${BREAKPOINTS.tablet} {
    > span {
      color: ${colors.light};
    }
  }
  ${BREAKPOINTS.mobile} {
    > span {
      color: ${colors.light};
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

interface AboutButtonProps {
  color: string;
}

export const AboutButton = styled.button<AboutButtonProps>`
  align-items: center;
  display: flex;
  > span {
    color: ${({ color }) => color};
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1em;
    line-height: 18px;
    text-align: left;
  }
  > svg {
    height: 18px;
    width: 18px;
    margin-left: 8px;
    > path {
      stroke: ${({ color }) => color};
    }
  }
`;
