import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const NavTileIconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.5);

  transition: all 200ms ease-in-out;
`;

export const NavTileIconAndTitleContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > :first-child {
    margin-bottom: 8px;
  }

  transition: all 200ms ease-in-out;
`;

export const NavTileTitle = styled.span`
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: var(--color-text);

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const NavTileTextContainer = styled.div`
  height: 100%;
  opacity: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0px;
  left: 0px;

  padding: 70px 8px 0px 8px;
  transition: all 200ms ease-in-out;
`;

interface NavTileTextProps {
  isDiscover?: boolean;
}

export const NavTileText = styled.span<NavTileTextProps>`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: rgba(23, 28, 51, 0.5);

  ${BREAKPOINTS.tablet} {
    ${({ isDiscover }) =>
      isDiscover &&
      `
      font-size: 12px;
    `}
  }

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NavTileDiscoverContainer = styled.div`
  position: absolute;
  top: 0px;

  padding: 2px 13px;

  border-radius: 0px 0px 8px 8px;
  background: linear-gradient(135deg, #c8f295 10.24%, #b89ef1 46.35%, #f5a496 81.6%);
  backdrop-filter: blur(24px);
`;

export const NavTileDiscoverText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #ffffff;
`;

interface NavTileWrapperProps {
  isDiscover?: boolean;
}

export const NavTileWrapper = styled.div<NavTileWrapperProps>`
  height: 150px;
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  border-radius: 8px;
  background: rgba(255, 255, 255, 0.4);

  transition: background 200ms ease-in-out;
  &:hover {
    background: rgba(255, 255, 255, 0.75);

    & ${NavTileTitle} {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    & > :first-child {
      transform: translateY(-43px);
    }

    & > .navtile-text {
      opacity: 1;
    }

    & .navtile-icon-container {
      background: #ffffff;

      ${({ isDiscover }) =>
        isDiscover &&
        `
        opacity: 0;
      `}
    }
  }

  ${({ isDiscover }) =>
    isDiscover &&
    `
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      background: linear-gradient(rgba(206, 242, 158, 1), rgba(191, 171, 244, 1), rgba(251, 175, 161, 1));
      clip-path: polygon(0% 0%,0% 100%,2% 98%,2% 2%,98% 2%,98% 98%,2% 98%,0 100%,100% 100%,100% 0%);
      width: 100%;
      height: 100%;
      border-radius: 8px;

      transition: clip-path 200ms ease-in-out;
    }

    &:hover {
      &:before {
        clip-path: polygon(0% 0%,0% 100%,3% 98%,3% 5%,97% 5%,97% 96%,3% 96%,0 100%,100% 100%,100% 0%);
      }
    }
  `}

  position: relative;
  cursor: pointer;
`;
