import React from 'react';

export default function FullBookIcon({ width = 40, height = 39 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3096_14573)">
        <path
          d="M10.8359 11.571C10.8359 11.0877 11.209 10.696 11.6693 10.696H16.6693C17.7743 10.696 18.8341 11.1569 19.6155 11.9774C20.397 12.7978 20.8359 13.9106 20.8359 15.071V27.321C20.8359 27.8042 20.4628 28.196 20.0026 28.196C19.5424 28.196 19.1693 27.8042 19.1693 27.321C19.1693 26.8568 18.9937 26.4117 18.6811 26.0835C18.3686 25.7553 17.9446 25.571 17.5026 25.571H11.6693C11.209 25.571 10.8359 25.1792 10.8359 24.696V11.571Z"
          fill="#DEDEDE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3923 11.9774C21.1737 11.1569 22.2335 10.696 23.3385 10.696H28.3385C28.7988 10.696 29.1719 11.0877 29.1719 11.571V24.696C29.1719 25.1792 28.7988 25.571 28.3385 25.571H22.5052C22.0632 25.571 21.6393 25.7553 21.3267 26.0835C21.0141 26.4117 20.8385 26.8568 20.8385 27.321C20.8385 27.8042 20.4654 28.196 20.0052 28.196C19.545 28.196 19.1719 27.8042 19.1719 27.321V15.071C19.1719 13.9106 19.6109 12.7978 20.3923 11.9774ZM20.8385 24.2899C21.341 23.9853 21.915 23.821 22.5052 23.821H27.5052V12.446H23.3385C22.6755 12.446 22.0396 12.7225 21.5708 13.2148C21.1019 13.7071 20.8385 14.3748 20.8385 15.071V24.2899Z"
          fill="#F7F7F7"
        />
        <path
          d="M22.5052 23.821C21.915 23.821 21.341 23.9853 20.8385 24.2899V15.071C20.8385 14.3748 21.1019 13.7071 21.5708 13.2148C22.0396 12.7225 22.6755 12.446 23.3385 12.446H27.5052V23.821H22.5052Z"
          fill="#F7F7F7"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3096_14573"
          x="0.835938"
          y="0.695953"
          width="38.3359"
          height="37.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3096_14573" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3096_14573"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
