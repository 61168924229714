import { SliceCaseReducers, createSelector, createSlice } from '@reduxjs/toolkit';
import { stat } from 'fs';

export const PULSE_SECTIONS = {
  OVERVIEW: 'overview',
  WIKI: 'wiki',
  RESOURCES_AND_FAQS: 'resources_and_faqs',
};

export type PULSE_SECTIONS = (typeof PULSE_SECTIONS)[keyof typeof PULSE_SECTIONS];

export interface PulseState {
  currentSection: PULSE_SECTIONS;
}

export const slice = createSlice<PulseState, SliceCaseReducers<PulseState>>({
  name: 'pulse',
  initialState: {
    currentSection: PULSE_SECTIONS.OVERVIEW,
  },
  reducers: {
    setCurrentSection: (state, action: { payload: PULSE_SECTIONS }) => {
      return {
        ...state,
        currentSection: action.payload,
      };
    },
  },
});

export const { setCurrentSection } = slice.actions;

export const currentSectionSelector = (state): PULSE_SECTIONS => state.pulse.currentSection;

export default slice.reducer;
