import { useEffect, useState } from 'react';

export default function useReactionStorage() {
  const [storage, setStorage] = useState<string[]>(() => {
    const storedValue = localStorage.getItem('reactions');
    try {
      const parsedValue = JSON.parse(storedValue);
      return parsedValue ?? [];
    } catch (error) {
      return [];
    }
  });

  const handleStorageChange = (event: StorageEvent) => {
    if (!event.isTrusted) return;

    if (event.key === 'reactions') {
      setStorage(JSON.parse(event.newValue));
    }
  };

  const addToStorage = (value: string) => {
    if (storage) {
      const updatedStorage = [...storage, value];
      localStorage.setItem('reactions', JSON.stringify(updatedStorage));
      setStorage(updatedStorage);
    } else {
      localStorage.setItem('reactions', JSON.stringify([value]));
      setStorage([value]);
    }
  };

  const removeFromStorage = (value: string) => {
    if (storage && storage.includes(value)) {
      const updatedStorage = storage.filter((item) => item !== value);
      localStorage.setItem('reactions', JSON.stringify(updatedStorage));
      setStorage(updatedStorage);
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return { storage, addToStorage, removeFromStorage };
}
