import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import CollectiveDirectoryPill from 'app/components/CommonStyled/CollectiveDirectoryPill';
import {
  ClientIcon,
  PassionIcon,
  SkillIcon,
} from 'app/components/DetailsPages/shared/CommonGround/Icons';
import usePeopleFilters from 'app/components/People/hooks/usePeopleFilters';
import { DisciplineIcon, SearchIconSmall } from 'app/components/shared/icons';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import { setAboutKyuOsOpen } from 'store/reducers/onboarding-reducer';
import {
  IPeopleFilter,
  IPeopleFilters,
  PEOPLE_FILTER_TYPE,
} from 'store/reducers/people/people-reducer';
import { arrayToOxford } from 'utils';

import useKyu101 from '../../hooks/useKyu101';
import {
  AboutKyuOsNetworkIntroSectionContainer,
  AboutKyuOsNetworkIntroSectionDescription,
  AboutKyuOsNetworkIntroSectionInformationContainer,
  AboutKyuOsNetworkIntroSectionPillsContainer,
  AboutKyuOsNetworkIntroSectionsWrapper,
  AboutKyuOsNetworkIntroSectionTitle,
  AboutKyuOsNetworkIntroTitle,
  AboutKyuOsNetworkIntroWrapper,
} from './styled';

const correctTypesMap = new Map<string, string>([['cities', 'locations']]);

export default function AboutKyuOsNetworkIntro() {
  const { data, isLoading } = usePeopleFilters<any>();
  const { data: pillsData } = useKyu101();
  const dispatch = useDispatch();

  const casualIntentionCollectiveDirectoryOpenedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_CASUAL_INTENTION_COLLECTIVE_DIRECTORY_OPENED
  );
  const expertiseIntentionCollectiveDirectoryOpenedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_EXPERTISE_INTENTION_COLLECTIVE_DIRECTORY_OPENED
  );
  const conveneIntentionCollectiveDirectoryOpenedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_CONVENE_INTENTION_COLLECTIVE_DIRECTORY_OPENED
  );

  const filtersToDetails = (filters: IPeopleFilters) => {
    return Object.keys(filters)
      .reduce((acc, key) => {
        if (filters[key].length) {
          acc.push({
            title: key,
            values: filters[key],
          });
        }

        return acc;
      }, [])
      .map((filter) => `${filter.title}: ${arrayToOxford(filter.values.map((v) => v.name))}`)
      .join(' | ');
  };

  const handlePillBeforeClick = () => {
    dispatch(setAboutKyuOsOpen(false));
  };

  const handleCasualIntentionBeforeClick = (filters: IPeopleFilters) => {
    handlePillBeforeClick();
    casualIntentionCollectiveDirectoryOpenedBehavioralFunction({
      action_details: filtersToDetails(filters),
    });
  };

  const handleExpertiseIntentionBeforeClick = (filters: IPeopleFilters) => {
    handlePillBeforeClick();
    expertiseIntentionCollectiveDirectoryOpenedBehavioralFunction({
      action_details: filtersToDetails(filters),
    });
  };

  const handleConveneIntentionBeforeClick = (filters: IPeopleFilters) => {
    handlePillBeforeClick();
    conveneIntentionCollectiveDirectoryOpenedBehavioralFunction({
      action_details: filtersToDetails(filters),
    });
  };

  const getPillFilters = (pill) => {
    return pill.items.reduce((acc, item) => {
      acc[correctTypesMap.has(item.type) ? correctTypesMap.get(item.type) : item.type] = [
        ...(acc[correctTypesMap.has(item.type) ? correctTypesMap.get(item.type) : item.type] || []),
        item,
      ];
      return acc;
    }, {});
  };

  const beIntentionalPills = useMemo(() => {
    if (!pillsData) return [];

    return pillsData?.kyu_101.filter((pill) => pill.section === 'be_intentional');
  }, [pillsData]);

  const convenePeoplePills = useMemo(() => {
    if (!pillsData) return [];

    return pillsData?.kyu_101.filter((pill) => pill.section === 'convene_people');
  }, [pillsData]);

  return (
    <AboutKyuOsNetworkIntroWrapper>
      <AboutKyuOsNetworkIntroTitle>
        The Collective is full of diverse people from all over the globe. Connecting to folks at
        other companies can sometimes feel daunting, so here are our top three tips for getting
        started:
      </AboutKyuOsNetworkIntroTitle>
      <AboutKyuOsNetworkIntroSectionsWrapper>
        <AboutKyuOsNetworkIntroSectionContainer>
          <img src="/images/about-kyu-os/sections/coffee-chat.svg" />
          <AboutKyuOsNetworkIntroSectionInformationContainer>
            <AboutKyuOsNetworkIntroSectionTitle>
              Start with a coffee chat
            </AboutKyuOsNetworkIntroSectionTitle>
            <AboutKyuOsNetworkIntroSectionDescription>
              Looking for advice or a new perspective on a work challenge? Consider reaching out for
              a low-stakes chat. Try it out:
            </AboutKyuOsNetworkIntroSectionDescription>
            <AboutKyuOsNetworkIntroSectionPillsContainer>
              {data?.disciplines?.length > 0 ? (
                <CollectiveDirectoryPill
                  filters={{ [PEOPLE_FILTER_TYPE.DISCIPLINES]: data.disciplines }}
                  label="People in my discipline"
                  beforeClick={handleCasualIntentionBeforeClick}
                >
                  <DisciplineIcon />
                </CollectiveDirectoryPill>
              ) : null}
              {data?.passions?.length > 0 ? (
                <CollectiveDirectoryPill
                  filters={{ [PEOPLE_FILTER_TYPE.PASSIONS]: data?.passions }}
                  label="People who share my passions"
                  beforeClick={handleCasualIntentionBeforeClick}
                >
                  <PassionIcon />
                </CollectiveDirectoryPill>
              ) : null}
              {data?.kyu_skills?.length > 0 ? (
                <CollectiveDirectoryPill
                  filters={{ [PEOPLE_FILTER_TYPE.SKILLS]: data?.kyu_skills }}
                  label="People who share my skills"
                  beforeClick={handleCasualIntentionBeforeClick}
                >
                  <SkillIcon />
                </CollectiveDirectoryPill>
              ) : null}
              {data?.clients?.length > 0 ? (
                <CollectiveDirectoryPill
                  filters={{ [PEOPLE_FILTER_TYPE.CLIENTS]: data?.clients }}
                  label="People who have worked with the same clients "
                  beforeClick={handleCasualIntentionBeforeClick}
                >
                  <ClientIcon />
                </CollectiveDirectoryPill>
              ) : null}
              {data?.city?.length > 0 ? (
                <CollectiveDirectoryPill
                  filters={{ [PEOPLE_FILTER_TYPE.LOCATIONS]: data?.city }}
                  label="People in my city"
                  beforeClick={handleCasualIntentionBeforeClick}
                >
                  <ClientIcon />
                </CollectiveDirectoryPill>
              ) : null}
            </AboutKyuOsNetworkIntroSectionPillsContainer>
          </AboutKyuOsNetworkIntroSectionInformationContainer>
        </AboutKyuOsNetworkIntroSectionContainer>
        <AboutKyuOsNetworkIntroSectionContainer>
          <img src="/images/about-kyu-os/sections/intentional.svg" />
          <AboutKyuOsNetworkIntroSectionInformationContainer>
            <AboutKyuOsNetworkIntroSectionTitle>Be intentional</AboutKyuOsNetworkIntroSectionTitle>
            <AboutKyuOsNetworkIntroSectionDescription>
              Slack is super useful, but finding the right channel can be a challenge. Put in your
              search criteria into the Collective Directory to target you ask to the right people.
              Try out one of the filters below:
            </AboutKyuOsNetworkIntroSectionDescription>
            <AboutKyuOsNetworkIntroSectionPillsContainer>
              {beIntentionalPills.map((pill) => (
                <CollectiveDirectoryPill
                  filters={getPillFilters(pill)}
                  label={pill.name}
                  beforeClick={handleExpertiseIntentionBeforeClick}
                >
                  <SearchIconSmall />
                </CollectiveDirectoryPill>
              ))}
            </AboutKyuOsNetworkIntroSectionPillsContainer>
          </AboutKyuOsNetworkIntroSectionInformationContainer>
        </AboutKyuOsNetworkIntroSectionContainer>
        <AboutKyuOsNetworkIntroSectionContainer>
          <img src="/images/about-kyu-os/sections/convene-people.svg" />
          <AboutKyuOsNetworkIntroSectionInformationContainer>
            <AboutKyuOsNetworkIntroSectionTitle>Convene people</AboutKyuOsNetworkIntroSectionTitle>
            <AboutKyuOsNetworkIntroSectionDescription>
              You don’t need to wait for kyu. Find out who’s doing similar work as you and convene
              them. Take the #collective-ai group for example - it’s a fully self-organized group of
              AI enthusiasts from al the companies. Try out one of the filters below:
            </AboutKyuOsNetworkIntroSectionDescription>
            <AboutKyuOsNetworkIntroSectionPillsContainer>
              {convenePeoplePills.map((pill) => (
                <CollectiveDirectoryPill
                  filters={getPillFilters(pill)}
                  label={pill.name}
                  beforeClick={handleConveneIntentionBeforeClick}
                >
                  <SearchIconSmall />
                </CollectiveDirectoryPill>
              ))}
            </AboutKyuOsNetworkIntroSectionPillsContainer>
          </AboutKyuOsNetworkIntroSectionInformationContainer>
        </AboutKyuOsNetworkIntroSectionContainer>
      </AboutKyuOsNetworkIntroSectionsWrapper>
    </AboutKyuOsNetworkIntroWrapper>
  );
}
