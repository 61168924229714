import React from 'react';

import PersonAvatar from '../PersonAvatar';
import {
  PeopleAvatarContainerMoreCounter,
  PeopleAvatarContainerMoreCounterText,
  PeopleAvatarWrapper,
} from './styled';

interface IPeopleAvatar {
  avatar?: string;
  highlighted?: boolean;
  lastName?: string;
  margin?: string;
  name?: string;
  onClick?: () => void;
  text?: string;
  role?: string;
  openDrawer?: boolean;
  slug?: string;
}

interface PeopleAvatarContainerProps {
  people: IPeopleAvatar[];
  size: number;
  justifyContent?: string;
  backgroundColor?: string;
  hideTooltip?: boolean;
  children?: React.ReactNode;
  drawerBehavioralAction?: (params?: any) => void;
}

export default function PeopleAvatarContainer({
  people,
  size,
  justifyContent,
  hideTooltip = false,
  backgroundColor,
  children,
  drawerBehavioralAction = () => {},
}: PeopleAvatarContainerProps) {
  const reversed = [...people].reverse();

  return (
    <PeopleAvatarWrapper justifyContent={justifyContent || 'flex-end'} size={size}>
      {children}
      {reversed.map((person, index) => (
        <PersonAvatar
          key={index}
          avatar={person.avatar}
          highlighted
          name={person.name}
          lastName={person.lastName}
          size={size}
          role={person.role}
          hideTooltip={hideTooltip}
          openDrawer={person.openDrawer}
          onClick={person.onClick}
          slug={person.slug}
          drawerBehavioralAction={() => drawerBehavioralAction(person)}
        />
      ))}
    </PeopleAvatarWrapper>
  );
}

PeopleAvatarContainer.MoreCounter = function PeopleAvatarContainerMoreCounterComponent({
  children,
}) {
  return (
    <PeopleAvatarContainerMoreCounter className="people-avatar-more-counter">
      <PeopleAvatarContainerMoreCounterText>{children}</PeopleAvatarContainerMoreCounterText>
    </PeopleAvatarContainerMoreCounter>
  );
};
