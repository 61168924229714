import React, { useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';

import useBehavioral from 'hooks/useBehavioral';

import GlobalSkeletonDrawer from '../GlobalSkeletonDrawer';
import {
  GlobalDrawerBasicInformationContainer,
  GlobalDrawerBottomSection,
  GlobalDrawerButton,
  GlobalDrawerCatchphrase,
  GlobalDrawerContainer,
  GlobalDrawerDataName,
  GlobalDrawerDataType,
  GlobalDrawerDetailsContainer,
  GlobalDrawerIconContainer,
  GlobalDrawerIncludesPerson,
  GlobalDrawerMidSection,
  GlobalDrawerPeopleAtCompanyContainer,
  GlobalDrawerPeopleAtCompanyText,
  GlobalDrawerPeopleAvatarsContainer,
  GlobalDrawerPersonAtCompanyAvatar,
  GlobalDrawerPersonAtCompanyCounter,
  GlobalDrawerTitleContainer,
  GlobalDrawerTopSection,
} from './styled';

interface GlobalDataTypeDrawerProps {
  slug: string;
  isLoading: boolean;
  dataType: string;
  title: string;
  includesCurrentPerson: boolean;
  background?: string;
  catchphraseHighlightColor?: string;
  icon?: JSX.Element;
  iconBackground?: string;
  buttonOnClick?: () => void;
  buttonBackgroundColor?: string;
  peopleAtCompany?: {
    company_name: string;
    employees_avatar: string[];
    total_count: number;
  };
  comingFromList?: boolean;
  backToListAction?: () => void;
  drawerDetails?: React.ReactNode;
  isError?: boolean;
  query?: UseQueryResult<any, Error> | null;
  hideBottomSection?: boolean;
}

export default function GlobalDataTypeDrawer({
  slug,
  isLoading = false,
  dataType = '',
  title = '',
  includesCurrentPerson = false,
  background = '#FFFFFF',
  catchphraseHighlightColor = '#000000',
  icon = null,
  iconBackground = '',
  buttonOnClick = () => {},
  buttonBackgroundColor = '#FFFFFF',
  children = null,
  peopleAtCompany = null,
  comingFromList = false,
  backToListAction = () => {},
  drawerDetails = null,
  isError = false,
  query = null,
  hideBottomSection = false,
}: React.PropsWithChildren<GlobalDataTypeDrawerProps>) {
  const globalDrawerCTAClickedBehavioral = useBehavioral('GLOBAL_DRAWER_CTA_CLICKED');
  const [internalIncludeCurrentPerson, setInternalIncludeCurrentPerson] =
    useState<boolean>(includesCurrentPerson);
  const [triggerClosing, setTriggerClosing] = useState<boolean>(false);

  const catchphrases = {
    skill: `Check out who's connected to <span>${title}</span> and more`,
    passion: `Check out who's connected to <span>${title}</span> and more`,
    industry: `Learn about the work done in <span>${title}</span>`,
    project: 'Learn about the team and the impact of this work.',
    client: `Learn about the work done with <span>${title}<span>`,
    discipline: `Check out who's in the <span>${title}</span> discipline`,
    'kyu company': `Learn about the people at <span>${title}<span>`,
    'your company': `Learn about the people at <span>${title}<span>`,
  };

  const dataTypeToObjectType = new Map<string, string>([
    ['kyu company', 'Company'],
    ['client', 'Company'],
    ['passion', 'Passion'],
    ['skill', 'Skill'],
    ['industry', 'Industry'],
    ['discipline', 'Discipline'],
    ['project', 'Project'],
  ]);

  const handleClick = () => {
    globalDrawerCTAClickedBehavioral({
      related_object_type: dataTypeToObjectType.get(dataType),
      related_object_uuid: slug,
    });
    buttonOnClick();
    setTriggerClosing(true);
  };

  useEffect(() => {
    setInternalIncludeCurrentPerson(includesCurrentPerson);
  }, [includesCurrentPerson]);

  if (isLoading && !comingFromList)
    return (
      <GlobalDrawerContainer background={background}>
        <GlobalSkeletonDrawer />
      </GlobalDrawerContainer>
    );

  if (query && query.isError)
    return (
      <GlobalDrawerContainer background={background}>
        <GlobalDrawerDataName>An error ocurred</GlobalDrawerDataName>
        <GlobalDrawerButton backgroundColor={buttonBackgroundColor} onClick={() => query.refetch()}>
          Refresh
        </GlobalDrawerButton>
      </GlobalDrawerContainer>
    );

  return (
    <GlobalDrawerContainer
      background={background}
      comingFromList={comingFromList}
      triggerClosing={triggerClosing}
    >
      {isLoading ? (
        <GlobalSkeletonDrawer />
      ) : (
        <>
          <GlobalDrawerTopSection>
            <GlobalDrawerBasicInformationContainer>
              <GlobalDrawerTitleContainer>
                <GlobalDrawerDataType>
                  {comingFromList && (
                    <BackArrow
                      style={{ marginRight: '8px' }}
                      onClick={() => {
                        setTriggerClosing(true);
                        setTimeout(() => {
                          backToListAction();
                        }, 500);
                      }}
                    />
                  )}
                  {dataType}
                </GlobalDrawerDataType>
                <GlobalDrawerDataName>{title}</GlobalDrawerDataName>

                {!['project', 'kyu company', 'your company'].includes(dataType) && (
                  <GlobalDrawerIncludesPerson>
                    <span>
                      {internalIncludeCurrentPerson ? 'you share this' : "you don't share this"}
                    </span>
                    {/* <GlobalDrawerIncludesPersonContainer
                  onMouseEnter={() => setShowIncludes(true)}
                  onMouseLeave={() => setShowIncludes(false)}
                >
                  <GlobalDrawerIncludesPersonActionAvatar
                    includes={internalIncludeCurrentPerson}
                    src={userData.profile_image}
                    alt={'Your profile image'}
                  />
                </GlobalDrawerIncludesPersonContainer> */}
                  </GlobalDrawerIncludesPerson>
                )}
              </GlobalDrawerTitleContainer>
              <GlobalDrawerIconContainer background={iconBackground}>
                {React.cloneElement(icon, { width: 37, height: 37 })}
              </GlobalDrawerIconContainer>
            </GlobalDrawerBasicInformationContainer>
            {drawerDetails && (
              <GlobalDrawerDetailsContainer>{drawerDetails}</GlobalDrawerDetailsContainer>
            )}
          </GlobalDrawerTopSection>
          {peopleAtCompany && (
            <GlobalDrawerPeopleAtCompanyContainer>
              <GlobalDrawerPeopleAvatarsContainer>
                <GlobalDrawerPersonAtCompanyCounter>
                  <span>{peopleAtCompany.total_count}</span>
                </GlobalDrawerPersonAtCompanyCounter>
                {peopleAtCompany.employees_avatar &&
                  peopleAtCompany.employees_avatar.map((avatar, index) => (
                    <GlobalDrawerPersonAtCompanyAvatar key={index} src={avatar} />
                  ))}
              </GlobalDrawerPeopleAvatarsContainer>
              <GlobalDrawerPeopleAtCompanyText>
                people at {peopleAtCompany.company_name} share this
              </GlobalDrawerPeopleAtCompanyText>
            </GlobalDrawerPeopleAtCompanyContainer>
          )}
          <GlobalDrawerMidSection>{children}</GlobalDrawerMidSection>
          {!hideBottomSection ? (
            <GlobalDrawerBottomSection type={dataType}>
              <GlobalDrawerCatchphrase
                highlightColor={catchphraseHighlightColor}
                dangerouslySetInnerHTML={{ __html: catchphrases[dataType] }}
              />
              <GlobalDrawerButton
                backgroundColor={buttonBackgroundColor}
                onClick={() => handleClick()}
              >
                Take me there
              </GlobalDrawerButton>
            </GlobalDrawerBottomSection>
          ) : null}
        </>
      )}
    </GlobalDrawerContainer>
  );
}

function AddIcon({ width = 12, height = 13, fill = '#5478A1' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C3.51472 2 1.5 4.01472 1.5 6.5C1.5 8.98528 3.51472 11 6 11C8.48528 11 10.5 8.98528 10.5 6.5C10.5 4.01472 8.48528 2 6 2ZM0.5 6.5C0.5 3.46243 2.96243 1 6 1C9.03757 1 11.5 3.46243 11.5 6.5C11.5 9.53757 9.03757 12 6 12C2.96243 12 0.5 9.53757 0.5 6.5Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4C6.27614 4 6.5 4.22386 6.5 4.5V8.5C6.5 8.77614 6.27614 9 6 9C5.72386 9 5.5 8.77614 5.5 8.5V4.5C5.5 4.22386 5.72386 4 6 4Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6.5C3.5 6.22386 3.72386 6 4 6H8C8.27614 6 8.5 6.22386 8.5 6.5C8.5 6.77614 8.27614 7 8 7H4C3.72386 7 3.5 6.77614 3.5 6.5Z"
        fill={fill}
      />
    </svg>
  );
}

function RemoveIcon({ width = 12, height = 13, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C3.51472 2 1.5 4.01472 1.5 6.5C1.5 8.98528 3.51472 11 6 11C8.48528 11 10.5 8.98528 10.5 6.5C10.5 4.01472 8.48528 2 6 2ZM0.5 6.5C0.5 3.46243 2.96243 1 6 1C9.03757 1 11.5 3.46243 11.5 6.5C11.5 9.53757 9.03757 12 6 12C2.96243 12 0.5 9.53757 0.5 6.5Z"
        fill={fill}
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6.5C3.5 6.22386 3.72386 6 4 6H8C8.27614 6 8.5 6.22386 8.5 6.5C8.5 6.77614 8.27614 7 8 7H4C3.72386 7 3.5 6.77614 3.5 6.5Z"
        fill={fill}
        fillOpacity="0.5"
      />
    </svg>
  );
}

function BackArrow({ onClick, style = {} }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ cursor: 'pointer', ...style }}
    >
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill="#D9D9D9"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.55806 14.8169C9.80214 15.061 10.1979 15.061 10.4419 14.8169C10.686 14.5729 10.686 14.1771 10.4419 13.9331L7.13388 10.625L14.375 10.625C14.7202 10.625 15 10.3452 15 10C15 9.65482 14.7202 9.375 14.375 9.375L7.13388 9.375L10.4419 6.06694C10.686 5.82286 10.686 5.42714 10.4419 5.18306C10.1979 4.93898 9.80214 4.93898 9.55806 5.18306L5.18382 9.55729C5.18118 9.55992 5.17857 9.56258 5.17598 9.56525C5.05866 9.68634 5 9.84317 5 10C5 10.0847 5.01687 10.1655 5.04743 10.2392C5.07671 10.31 5.11956 10.3765 5.17598 10.4347C5.17854 10.4374 5.18113 10.44 5.18374 10.4426M9.55806 14.8169L5.18374 10.4426L9.55806 14.8169Z"
        fill="#1F2025"
      />
    </svg>
  );
}
