import React, { createContext } from 'react';

import { IDetailsPageNavbar } from './NavBar/DetailsPageNavBar';

export interface DetailsPageInfo<T> {
  model: string;
  slug: string;
  data: T;
  setData: React.Dispatch<React.SetStateAction<T>>;
  navbarOptions: IDetailsPageNavbar;
  setNavbarOptions: React.Dispatch<React.SetStateAction<IDetailsPageNavbar>>;
  navbarLoading: boolean;
  setNavbarLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setShowErrorPage: React.Dispatch<React.SetStateAction<boolean>>;
  showErrorPage: boolean;
  background?: string;
  setBackground?: React.Dispatch<React.SetStateAction<string>>;
  extraStyle?: string;
  setExtraStyle?: React.Dispatch<React.SetStateAction<string>>;
  goToSection?: (sectionName: string) => void;
  routeAction?: string;
}

export const DetailsPageContext = createContext<DetailsPageInfo<any>>({
  model: '',
  slug: '',
  data: {},
  setData: () => {},
  navbarOptions: { items: [] },
  navbarLoading: true,
  setNavbarLoading: () => {},
  setNavbarOptions: () => {},
  setShowErrorPage: () => {},
  showErrorPage: false,
  goToSection: (sectionName: string) => {},
  routeAction: '',
});
