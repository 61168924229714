import React, { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { DeleteIcon } from 'app/components/shared/icons';
import AddImageIcon from 'app/components/shared/icons/add-image';
import CheckIcon from 'app/components/shared/icons/check-icon';
import WarningFullIcon from 'app/components/shared/icons/warning-full';
import useUserData from 'hooks/useUserData';
import { getUserData } from 'services/app';
import { postAvatar } from 'services/profile-editor';
import { setUserData } from 'store/reducers/user-reducer';

import ProfileEditorDrawer, { ProfileEditorDrawerRefProps } from '../Drawer';
import { ProfileEditorDrawerTitle } from '../styled';
import {
  ProfileEditorDrawerAvatarButtonsContainer,
  ProfileEditorDrawerAvatarContainer,
  ProfileEditorDrawerAvatarCropButton,
  ProfileEditorDrawerAvatarCropper,
  ProfileEditorDrawerAvatarCropperContainer,
  ProfileEditorDrawerAvatarCropperTitle,
  ProfileEditorDrawerAvatarDropzoneButton,
  ProfileEditorDrawerAvatarDropzoneContainer,
  ProfileEditorDrawerAvatarDropzoneErrorContainer,
  ProfileEditorDrawerAvatarDropzoneErrorText,
  ProfileEditorDrawerAvatarDropzoneInput,
  ProfileEditorDrawerAvatarDropzoneSpecs,
  ProfileEditorDrawerAvatarDropzoneText,
  ProfileEditorDrawerAvatarDropzoneTitle,
  ProfileEditorDrawerAvatarDropzoneWrapper,
  ProfileEditorDrawerAvatarHeaderSubTitle,
  ProfileEditorDrawerAvatarPostCropContainer,
  ProfileEditorDrawerAvatarPostCropDelete,
  ProfileEditorDrawerAvatarPostCropPreview,
  ProfileEditorDrawerAvatarPostCropPreviewImg,
  ProfileEditorDrawerAvatarPostCropSpecs,
  ProfileEditorDrawerAvatarPostCropTitle,
  ProfileEditorDrawerAvatarPublishButton,
  ProfileEditorDrawerAvatarSamplesContainer,
  ProfileEditorDrawerAvatarSamplesItem,
  ProfileEditorDrawerAvatarSamplesItemCheck,
  ProfileEditorDrawerAvatarSamplesItemImg,
  ProfileEditorDrawerAvatarSamplesTitle,
  ProfileEditorDrawerAvatarSamplesWrapper,
  ProfileEditorDrawerAvatarWrapper,
} from './styled';
import { getCroppedImage } from './utils';

interface ICustomBottomProps {
  finalSrc?: string;
  handleSubmit?: () => void;
  handleCrop?: () => void;
  deleteCropSelection?: () => void;
  src?: string;
}

interface ProfileEditorDrawerAvatarProps {
  onClose: () => void;
  customBottom?: React.ReactElement<ICustomBottomProps>;
}

export default function ProfileEditorDrawerAvatar({
  onClose,
  customBottom,
}: ProfileEditorDrawerAvatarProps) {
  const dispatch = useDispatch();
  const drawerRef = useRef<ProfileEditorDrawerRefProps>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const userData = useUserData();

  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedSelection, setCroppedSelection] = useState(null);
  const [finalSrc, setFinalSrc] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const handlePostAvatar = async (params: any) => {
    const response = await postAvatar(params);
    if (!response.ok) {
      throw new Error(response.originalError?.message);
    }

    (async () => {
      const userRes = await getUserData();
      if (userRes.ok) {
        return dispatch(setUserData(userRes.data));
      }
    })();

    return response.data;
  };

  const { mutate } = useMutation(handlePostAvatar, drawerRef.current?.handleMutation);

  const handleSubmit = () => {
    if (finalSrc) {
      mutate({ value: finalSrc });
    }
  };

  const onCropComplete = useCallback(
    async (croppedArea: Area, croppedAreaPixels: Area) => {
      const croppedImage = await getCroppedImage(src, croppedAreaPixels);
      setCroppedSelection(croppedImage);
    },
    [src]
  );

  const handleCrop = () => {
    setFinalSrc(croppedSelection);
  };

  const deleteCropSelection = () => {
    setFinalSrc(null);
    setSrc(null);
    setCroppedSelection(null);
  };

  return (
    <ProfileEditorDrawer
      ref={drawerRef}
      header={<ProfileEditorDrawerAvatarHeader />}
      bottom={
        customBottom ? (
          React.cloneElement<ICustomBottomProps>(customBottom, {
            finalSrc,
            src,
            handleSubmit,
            handleCrop,
            deleteCropSelection,
          })
        ) : (
          <ProfileEditorDrawerAvatarBottom finalSrc={finalSrc} handleSubmit={handleSubmit} />
        )
      }
      onClose={onClose}
      alwaysShowScroll
    >
      <ProfileEditorDrawerAvatarWrapper>
        <ProfileEditorDrawerAvatarContainer>
          {!src && (
            <ProfileEditorDrawerAvatarDropzone
              setSrc={setSrc}
              setFinalSrc={setFinalSrc}
              setSelectedAvatar={setSelectedAvatar}
            />
          )}
          {src && !finalSrc && (
            <ProfileEditorDrawerAvatarCropperContainer>
              <ProfileEditorDrawerAvatarCropperTitle>
                {"Adjust the position and zoom-level of your photo and confirm when you're ready."}
              </ProfileEditorDrawerAvatarCropperTitle>
              <ProfileEditorDrawerAvatarCropper>
                <Cropper
                  image={src}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropShape="round"
                />
              </ProfileEditorDrawerAvatarCropper>
              <ProfileEditorDrawerAvatarCropButton onClick={handleCrop}>
                Confirm
              </ProfileEditorDrawerAvatarCropButton>
            </ProfileEditorDrawerAvatarCropperContainer>
          )}
          {src && finalSrc && (
            <ProfileEditorDrawerAvatarPostCropContainer>
              <ProfileEditorDrawerAvatarPostCropTitle>
                {'Amazing!'}
              </ProfileEditorDrawerAvatarPostCropTitle>
              <ProfileEditorDrawerAvatarPostCropPreview>
                <ProfileEditorDrawerAvatarPostCropPreviewImg src={finalSrc} />
                <ProfileEditorDrawerAvatarPostCropDelete onClick={deleteCropSelection}>
                  <DeleteIcon height={15} />
                  <span>Delete</span>
                </ProfileEditorDrawerAvatarPostCropDelete>
              </ProfileEditorDrawerAvatarPostCropPreview>
              <ProfileEditorDrawerAvatarPostCropSpecs>
                <CheckIcon height={15} background={'transparent'} fill={'#8DA76C'} />
                {'You’re looking wonderful!'}
              </ProfileEditorDrawerAvatarPostCropSpecs>
            </ProfileEditorDrawerAvatarPostCropContainer>
          )}
          {!src && (
            <ProfileEditorDrawerAvatarSamples
              setFinalSrc={setFinalSrc}
              selectedAvatar={selectedAvatar}
              setSelectedAvatar={setSelectedAvatar}
            />
          )}
        </ProfileEditorDrawerAvatarContainer>
      </ProfileEditorDrawerAvatarWrapper>
    </ProfileEditorDrawer>
  );
}

function ProfileEditorDrawerAvatarHeader() {
  return (
    <>
      <ProfileEditorDrawerTitle>Profile Image</ProfileEditorDrawerTitle>
      <ProfileEditorDrawerAvatarHeaderSubTitle>
        "A smile is the universal welcome."
      </ProfileEditorDrawerAvatarHeaderSubTitle>
      <ProfileEditorDrawerAvatarHeaderSubTitle>
        {
          'Your photo will appear throughout kyu OS, including searches, company and project pages, and your profile. It won’t be visible to anyone who isn’t logged in.'
        }
      </ProfileEditorDrawerAvatarHeaderSubTitle>
    </>
  );
}

function ProfileEditorDrawerAvatarDropzone({ setSrc, setFinalSrc, setSelectedAvatar }) {
  const [rejectedFile, setRejectedFile] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedAvatar(null);
    setFinalSrc(false);
    if (acceptedFiles.length === 0) {
      setRejectedFile(true);
      setSrc(null);
    } else {
      setRejectedFile(false);
      const file = acceptedFiles[0];
      if (file instanceof File) {
        const reader = new FileReader();
        reader.onload = () => {
          setSrc(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg', '.png'],
    },
    maxSize: 10 * 1024 * 1024,
  });

  return (
    <ProfileEditorDrawerAvatarDropzoneWrapper>
      <ProfileEditorDrawerAvatarDropzoneTitle>
        Upload a photo
      </ProfileEditorDrawerAvatarDropzoneTitle>
      <ProfileEditorDrawerAvatarDropzoneContainer {...getRootProps()}>
        <ProfileEditorDrawerAvatarDropzoneInput {...getInputProps()} />
        <AddImageIcon />
        <ProfileEditorDrawerAvatarDropzoneText>
          Drag and drop, or
        </ProfileEditorDrawerAvatarDropzoneText>
        <ProfileEditorDrawerAvatarDropzoneButton>
          Upload a File
        </ProfileEditorDrawerAvatarDropzoneButton>
      </ProfileEditorDrawerAvatarDropzoneContainer>
      {!rejectedFile ? (
        <ProfileEditorDrawerAvatarDropzoneSpecs>
          {'.png, .jpg, .jpeg (up to 10 MB)'}
        </ProfileEditorDrawerAvatarDropzoneSpecs>
      ) : (
        <ProfileEditorDrawerAvatarDropzoneErrorContainer>
          <WarningFullIcon />
          <ProfileEditorDrawerAvatarDropzoneErrorText>
            {
              'The file is not the right format. Choose an image that is a .png, .jpg or .jpeg and no larger than 10 MB.'
            }
          </ProfileEditorDrawerAvatarDropzoneErrorText>
        </ProfileEditorDrawerAvatarDropzoneErrorContainer>
      )}
    </ProfileEditorDrawerAvatarDropzoneWrapper>
  );
}

function ProfileEditorDrawerAvatarBottom({ finalSrc, handleSubmit }) {
  const [disabled, setDisabled] = useState(false);
  const [buttonText, setButtonText] = useState('Publish');
  const handlePublish = () => {
    handleSubmit();
    setDisabled(true);
    setButtonText('Uploading...');
  };
  return (
    <ProfileEditorDrawerAvatarButtonsContainer>
      <ProfileEditorDrawerAvatarPublishButton
        disabled={!finalSrc || disabled}
        onClick={handlePublish}
      >
        {buttonText}
      </ProfileEditorDrawerAvatarPublishButton>
    </ProfileEditorDrawerAvatarButtonsContainer>
  );
}

function ProfileEditorDrawerAvatarSamples({ setFinalSrc, selectedAvatar, setSelectedAvatar }) {
  const maxAvatars = 6;

  const handleSelection = async (i) => {
    if (selectedAvatar !== i) {
      setSelectedAvatar(i);
      const response = await fetch(`/images/avatar-samples/${i}.png`);
      const blob = await response.blob();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const imgUrl = URL.createObjectURL(blob);
      let img = new Image();
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const jpegImg = canvas.toDataURL('image/jpeg', 1);
        setFinalSrc(jpegImg);
      };
      img.src = imgUrl;
    } else {
      setSelectedAvatar(null);
      setFinalSrc(null);
    }
  };

  return (
    <ProfileEditorDrawerAvatarSamplesWrapper>
      <ProfileEditorDrawerAvatarSamplesTitle>
        {'Or choose a pre-made avatar:'}
      </ProfileEditorDrawerAvatarSamplesTitle>
      <ProfileEditorDrawerAvatarSamplesContainer>
        {Array.from(Array(maxAvatars).keys()).map((i) => (
          <ProfileEditorDrawerAvatarSamplesItem
            key={i}
            selected={selectedAvatar === i}
            opacity={selectedAvatar !== null && selectedAvatar !== i ? 0.5 : 1}
            onClick={() => handleSelection(i)}
          >
            <ProfileEditorDrawerAvatarSamplesItemCheck selected={selectedAvatar === i}>
              <CheckIcon height="24" width="24" background={'#171C33'} fill={'#FFFFFF'} />
            </ProfileEditorDrawerAvatarSamplesItemCheck>
            <ProfileEditorDrawerAvatarSamplesItemImg src={`/images/avatar-samples/${i}.png`} />
          </ProfileEditorDrawerAvatarSamplesItem>
        ))}
      </ProfileEditorDrawerAvatarSamplesContainer>
    </ProfileEditorDrawerAvatarSamplesWrapper>
  );
}
