import React from 'react';

export default function LinkedInIcon({
  width = 19,
  height = 18,
  fill = '#171C33',
  onClick = (e?: UIEvent) => {},
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => onClick(e)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.86719 0C1.53696 0 0.666664 0.873192 0.666664 2.02043C0.666664 3.14332 1.51173 4.04174 2.81615 4.04174H2.84138C4.19771 4.04174 5.04103 3.14332 5.04103 2.02043C5.01522 0.873192 4.19771 0 2.86719 0ZM18.8056 10.6262V17.3333H14.917V11.0757C14.917 9.50448 14.3556 8.43148 12.9476 8.43148C11.8732 8.43148 11.2349 9.15387 10.953 9.85335C10.8506 10.1033 10.8242 10.4505 10.8242 10.8011V17.333H6.93532C6.93532 17.333 6.98752 6.73468 6.93532 5.63761H10.8245V7.29496C10.8208 7.30108 10.8165 7.30714 10.8121 7.31313C10.8072 7.31992 10.8024 7.32662 10.7987 7.33324H10.8245V7.29496C11.3413 6.49978 12.2629 5.36298 14.3292 5.36298C16.8876 5.36298 18.8056 7.03454 18.8056 10.6262ZM4.78525 17.3333H0.897795V5.63761H4.78525V17.3333Z"
        fill={fill}
      />
    </svg>
  );
}
