import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

import { EditorialH6, EditorialP } from '../../styled';

type PublicationType =
  | 'full-size'
  | 'half-size-small'
  | 'half-size-big'
  | 'horizontal'
  | 'vertical'
  | 'mobile'
  | 'mobile-full-size'
  | 'article-index';
interface PublicationWrapperProps {
  type: PublicationType;
  hasContributors?: boolean;
}

const PublicationWrapperFullSize = `
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 42px;
  }
`;

const PublicationWrapperHalfSizeSmall = `
  flex-direction: column;

  & > :not(:last-child) {
    margin-right: 29px;
  }
`;

const PublicationWrapperHalfSizeBig = `
  flex-direction: column;

  & > :not(:last-child) {
    margin-right: 37px;
  }
`;

const PublicationWrapperHorizontal = `
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 37px;
  }
`;

const PublicationWrapperMobileFullSize = `
  flex-direction: column;

  & .publication-type {
    margin-bottom: 4px;
  }

  & .publication-title {
    margin-bottom: 4px;
  }

  & .publication-contributors {
    margin-top: 8px;
  }

  & > :last-child {
    margin-top: 8px !important;
  }
`;

const PublicationWrapperMobile = `
  flex-direction: row;
`;

const PublicationArticleIndexWrapper = `
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 32px;

  & > :first-child {
    grid-column: span 5;
  }

  & > :last-child {
    grid-column: span 7;
    transition: padding 300ms ease-out;
  }

  &:hover {
    & > :last-child {
      padding-left: 12px;
    }
  }

  ${BREAKPOINTS.mobile} {
    display: flex;
    flex-direction: row;
    grid-column-gap: 0px;

    & > :first-child {
      width: 156px;
      margin-right: 28px;
    }

    .information-container > :not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

const mapPublicationType = new Map<PublicationType, string>([
  ['full-size', PublicationWrapperFullSize],
  ['half-size-small', PublicationWrapperHalfSizeSmall],
  ['half-size-big', PublicationWrapperHalfSizeBig],
  ['horizontal', PublicationWrapperHorizontal],
  ['mobile', PublicationWrapperMobile],
  ['mobile-full-size', PublicationWrapperMobileFullSize],
  ['article-index', PublicationArticleIndexWrapper],
]);

export const PublicationWrapper = styled.div<PublicationWrapperProps>`
  width: 100%;
  display: flex;
  ${({ type }) => mapPublicationType.get(type)}
  cursor: pointer;

  & > p,
  h6,
  h3 {
    transition: all 250ms ease-out;
  }

  &:hover {
    p,
    h6,
    h3 {
      color: #a26565;
      opacity: 1;
    }

    button {
      background-color: #000000;
    }

    button > span {
      color: #ffffff;
    }

    button > svg > path {
      fill: #ffffff;
      stroke: #ffffff;
    }

    ${({ hasContributors = true }) =>
      hasContributors &&
      `
      #publication-image {
        filter: blur(8px);
        transform: scale(1.2);
      }
    `}

    #publication-image+div {
      visibility: visible;
    }
  }
`;

interface PublicationImageContainerProps {
  height?: string;
}

export const PublicationImageContainer = styled.div<PublicationImageContainerProps>`
  width: 100%;
  ${({ height }) => height && `height: ${height};`}
  overflow: hidden;
  transition: all 250ms ease-out;

  position: relative;
`;

interface PublicationImageProps {
  maxHeight?: number;
  height?: string;
}

export const PublicationImage = styled.img<PublicationImageProps>`
  width: 100%;
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px;`}
  ${({ height }) => height && `height: ${height};`}
  object-fit: cover;
  transition: all 250ms ease-out;
`;

export const PublicationImagePeopleContainer = styled.div`
  position: absolute;
  bottom: 31px;
  left: 31px;

  display: flex;
  flex-direction: row-reverse;

  transition: visibility 250ms ease-out;

  visibility: hidden;

  & > :not(:first-child) {
    margin-right: -24px;
  }
  z-index: 100;
`;

interface PublicationInformationContainerProps {
  padding: string;
  spaceBetween?: boolean;
}

export const PublicationInformationContainer = styled.div<PublicationInformationContainerProps>`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ padding }) => padding};

  ${BREAKPOINTS.mobile} {
    ${({ spaceBetween }) =>
      spaceBetween &&
      `
      & > :nth-child(2) {
        margin-bottom: 16px;
      }
    `}
  }
`;

export const PublicationType = styled(EditorialP)`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(23, 28, 51, 0.5);
  margin-bottom: 9px;

  ${BREAKPOINTS.mobile} {
    font-size: 12px;
    line-height: 14px;
  }
`;

interface PublicationTextProps {
  fontSize?: number;
  lineHeight?: string;
}

export const PublicationTitle = styled(EditorialH6)<PublicationTextProps>`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '32px')};
  line-height: ${({ lineHeight }) => lineHeight ?? '140%'};
  color: var(--color-text);
  word-break: break-word;

  ${BREAKPOINTS.mobile} {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const PublicationDescription = styled.p<PublicationTextProps>`
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : ' 16px')};
  line-height: ${({ lineHeight }) => lineHeight ?? '180%'};
  color: var(--color-text);
  opacity: 0.5;
  word-break: break-word;

  ${BREAKPOINTS.mobile} {
    font-size: 16px;
    line-height: 160%;
  }
`;

export const PublicationButton = styled.button`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;

  margin-top: 32px;

  border: 1px solid var(--color-primary);
  transition: all 250ms ease-out;

  & > :not(:last-child) {
    margin-right: 18px;
  }

  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--color-text);

    transition: all 250ms ease-out;
  }
`;

export const PublicationContributorsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  & > :not(:first-child) {
    margin-right: -8px;
  }
`;

// ARTICLE INDEX
export const ArticleIndexPublicationTopInformationContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-end;
`;

export const ArticleIndexPublicationCollectionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > :not(:last-child) {
    margin-right: 24px;

    ${BREAKPOINTS.mobile} {
      margin-right: 0px;
    }
  }

  ${BREAKPOINTS.mobile} {
    flex-direction: column;
  }
`;

export const ArticleIndexPublicationCollectionName = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: rgba(23, 28, 51, 0.5);

  ${BREAKPOINTS.mobile} {
    line-height: 18px;
  }

  transition: color 200ms ease-out;
  &:hover {
    color: var(--color-text);
  }
`;

//LIKES
export const PublicationLikesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 2px;
  }

  padding: 2px 4px;
`;

export const PublicationLikesText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #323f5d;
`;
