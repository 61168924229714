import styled from 'styled-components/macro';
import { BREAKPOINTS } from 'styles/media';

export const LoadingWrapper = styled.div`
  align-items: center;
  background: #171c33;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999999999999999999;
  .particles-div {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  img {
    top: 50%;
    z-index: 1;
    max-height: 92%;
    position: absolute;
    transform: translateY(-50%);
    &:first-of-type {
      left: 50px;
    }
    &:last-of-type {
      right: 50px;
    }
  }
  p {
    color: #fff;
    font-size: 42px;
    line-height: 135%;
    margin-bottom: 25px;
    max-width: 700px;
    text-align: center;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  ${BREAKPOINTS.tablet} {
    p {
      font-size: 32px;
      line-height: 42px;
      margin: 0 auto 25px;
      width: calc(100% - 64px);
    }
  }
`;

export const Loading = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
