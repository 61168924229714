import styled from 'styled-components';

interface TooltipWrapperProps {
  maxWidth?: string;
}

export const TooltipWrapper = styled.div<TooltipWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  background: #000000;
  border-radius: 8px;

  max-width: ${({ maxWidth }) => maxWidth || '300px'};
  z-index: 99999999;
`;

export const TooltipText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
`;

export const TooltipTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
`;
