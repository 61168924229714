import React from 'react';

export default function BrandAssetsIcon({ width = 25, height = 22 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3618 2.2002C12.0812 2.2002 11.8121 2.3161 11.6137 2.52238C11.4153 2.72867 11.3038 3.00846 11.3038 3.30019V18.7C11.3038 18.9918 11.4153 19.2716 11.6137 19.4778C11.8121 19.6841 12.0812 19.8 12.3618 19.8H20.826C21.1066 19.8 21.3757 19.6841 21.5741 19.4778C21.7725 19.2716 21.884 18.9918 21.884 18.7V6.60016C21.884 6.30842 21.7725 6.02864 21.5741 5.82235C21.3757 5.61606 21.1066 5.50017 20.826 5.50017H19.7679C19.4142 5.50017 19.0838 5.31636 18.8876 5.01034L17.0857 2.2002H12.3618ZM8.12925 0C8.12925 0 11.52 0.000226427 12.3618 0.000226427H17.6519C18.0057 0.000226427 18.336 0.184036 18.5322 0.490051L20.3342 3.30019H20.826C21.6678 3.30019 22.4751 3.64787 23.0703 4.26673C23.6656 4.88559 24 5.72495 24 6.60016V18.7C24 19.5752 23.6656 20.4146 23.0703 21.0335C22.4751 21.6523 21.6678 22 20.826 22H12.3618C11.52 22 10.7127 21.6523 10.1175 21.0335C9.52221 20.4146 9.18781 19.5752 9.18781 18.7V3.30019C9.18781 2.42499 7.53401 0.618864 8.12925 0Z"
        fill="#5478A1"
      />
      <path
        d="M11.6137 2.52238C11.8121 2.3161 12.0812 2.2002 12.3618 2.2002H17.0857L18.8876 5.01034C19.0838 5.31636 19.4142 5.50017 19.7679 5.50017H20.826C21.1066 5.50017 21.3757 5.61606 21.5741 5.82235C21.7725 6.02864 21.884 6.30842 21.884 6.60016V18.7C21.884 18.9918 21.7725 19.2716 21.5741 19.4778C21.3757 19.6841 21.1066 19.8 20.826 19.8H12.3618C12.0812 19.8 11.8121 19.6841 11.6137 19.4778C11.4153 19.2716 11.3038 18.9918 11.3038 18.7V3.30019C11.3038 3.00846 11.4153 2.72867 11.6137 2.52238Z"
        fill="#5478A1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.95856 0.966548C1.57232 0.347677 2.40475 0 3.27273 0H8.72727C9.09202 0 9.43264 0.183811 9.63496 0.48983L11.4929 3.3H20.7273C21.5953 3.3 22.4277 3.64768 23.0414 4.26655C23.6552 4.88542 24 5.72479 24 6.6V18.7C24 19.5752 23.6552 20.4146 23.0414 21.0335C22.4277 21.6523 21.5953 22 20.7273 22H3.27273C2.40475 22 1.57231 21.6523 0.95856 21.0335C0.344804 20.4146 0 19.5752 0 18.7V3.3C0 2.42479 0.344804 1.58542 0.95856 0.966548Z"
        fill="#9CCAFF"
      />
    </svg>
  );
}
