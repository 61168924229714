import React from 'react';

export default function CheckboxCheckIcon({ width = 13, height = 9, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2929 0.792893C12.6834 1.18342 12.6834 1.81658 12.2929 2.20711L5.92893 8.57107C5.53841 8.96159 4.90524 8.96159 4.51472 8.57107L1.27208 5.32843C0.881554 4.9379 0.881554 4.30474 1.27208 3.91421C1.6626 3.52369 2.29577 3.52369 2.68629 3.91421L5.22183 6.44975L10.8787 0.792893C11.2692 0.402368 11.9024 0.402369 12.2929 0.792893Z"
        fill={fill}
      />
    </svg>
  );
}
