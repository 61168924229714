import React from 'react';

export default function FriendIcon({ width = 20, height = 21, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.58333 6.33317C4.58333 4.95246 5.70262 3.83317 7.08333 3.83317C8.46405 3.83317 9.58333 4.95246 9.58333 6.33317C9.58333 7.71388 8.46405 8.83317 7.08333 8.83317C5.70262 8.83317 4.58333 7.71388 4.58333 6.33317ZM7.08333 2.1665C4.78215 2.1665 2.91667 4.03198 2.91667 6.33317C2.91667 8.63436 4.78215 10.4998 7.08333 10.4998C9.38452 10.4998 11.25 8.63436 11.25 6.33317C11.25 4.03198 9.38452 2.1665 7.08333 2.1665ZM4.16667 12.1665C3.0616 12.1665 2.00179 12.6055 1.22039 13.3869C0.438987 14.1683 0 15.2281 0 16.3332V17.9998C0 18.4601 0.373096 18.8332 0.833333 18.8332C1.29357 18.8332 1.66667 18.4601 1.66667 17.9998V16.3332C1.66667 15.6701 1.93006 15.0342 2.3989 14.5654C2.86774 14.0966 3.50363 13.8332 4.16667 13.8332H10C10.663 13.8332 11.2989 14.0966 11.7678 14.5654C12.2366 15.0342 12.5 15.6701 12.5 16.3332V17.9998C12.5 18.4601 12.8731 18.8332 13.3333 18.8332C13.7936 18.8332 14.1667 18.4601 14.1667 17.9998V16.3332C14.1667 15.2281 13.7277 14.1683 12.9463 13.3869C12.1649 12.6055 11.1051 12.1665 10 12.1665H4.16667ZM16.6667 6.33317C17.1269 6.33317 17.5 6.70627 17.5 7.1665V8.83317H19.1667C19.6269 8.83317 20 9.20627 20 9.6665C20 10.1267 19.6269 10.4998 19.1667 10.4998H17.5V12.1665C17.5 12.6267 17.1269 12.9998 16.6667 12.9998C16.2064 12.9998 15.8333 12.6267 15.8333 12.1665V10.4998H14.1667C13.7064 10.4998 13.3333 10.1267 13.3333 9.6665C13.3333 9.20627 13.7064 8.83317 14.1667 8.83317H15.8333V7.1665C15.8333 6.70627 16.2064 6.33317 16.6667 6.33317Z"
        fill={fill}
      />
    </svg>
  );
}
