import { useHistory } from 'react-router-dom';

import { ResourceType } from '../ResourceCard';

interface IResourceClickParams {
  slug: string;
  go_to_detail_page: boolean;
  resource_type_name: string;
  link: string;
  behavioralAction?: () => void;
}

export const useResourceClick = () => {
  const { push } = useHistory();

  const handleResourceClick = ({
    go_to_detail_page,
    resource_type_name,
    slug,
    link,
    behavioralAction,
  }: IResourceClickParams) => {
    if (behavioralAction) behavioralAction();

    if (go_to_detail_page) {
      return push({
        pathname: `/resources/${slug}`,
        state: { resourcesURL: `${window.location.pathname}${window.location.search}` },
      });
    }

    if (
      [
        ResourceType.VIMEO,
        ResourceType.IMAGE,
        ResourceType.GOOGLE_SLIDES,
        ResourceType.GOOGLE_STUDIO,
        ResourceType.READYMAG,
        ResourceType.PDF,
      ].includes(resource_type_name as ResourceType)
    ) {
      return push({
        pathname: `/resources/${slug}/embed`,
        state: { resourcesURL: `${window.location.pathname}${window.location.search}` },
      });
    }

    return window.open(link, '_blank');
  };

  return handleResourceClick;
};
