import styled from 'styled-components';
import { expandAnimation } from 'styles/animations';
import { BREAKPOINTS } from 'styles/media';

interface SearchWrapperProps {
  isOpen: boolean;
  expanded?: boolean;
  isSubmenuOpen: boolean;
}

export const SearchWrapper = styled.div<SearchWrapperProps>`
  padding: 10px 16px;
  border-radius: ${({ isSubmenuOpen }) => (isSubmenuOpen ? '34px 34px 0px 0px' : '100px')};
  background: ${({ isSubmenuOpen }) => (isSubmenuOpen ? '#F1F4F8' : 'rgba(255, 255, 255, 0.30)')};

  display: flex;
  align-items: center;
  max-width: 640px;

  position: relative;
  cursor: ${({ isOpen }) => (isOpen ? 'default' : 'pointer')};

  ${BREAKPOINTS.tablet} {
    background: #f1f4f8;
  }

  ${({ isOpen, expanded }) => {
    if (expanded) {
      return `
        width: 100%;
        max-width: unset;
        background: #F1F4F8;
      `;
    }
    return isOpen
      ? expandAnimation({ startingWidth: '229px', finalWidth: '100%', duration: '450ms' })
      : expandAnimation({ finalWidth: '229px', startingWidth: '100%', duration: '450ms' });
  }}
`;

export const SearchTitleContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const SearchTitle = styled.span`
  font-size: 16px;
  line-height: 20px;
  color: var(--color-text);
`;

export const SearchIconContainer = styled.div`
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

interface SearchSubMenuWrapperProps {
  isOpen?: boolean;
}

export const SearchSubMenuWrapper = styled.div<SearchSubMenuWrapperProps>`
  position: absolute;
  top: 64px;
  left: 0px;

  overflow: hidden;

  border-radius: 0px 0px 24px 24px;
  box-shadow: 2px 2px 15px -4px rgba(16, 77, 91, 0.25);
  max-height: ${({ isOpen }) => (isOpen ? '1000px' : '0px')};
  transition: max-height 200ms ease-in-out;
  width: 100%;
  z-index: 100;

  ${BREAKPOINTS.tablet} {
    top: 54px;
  }
`;

// ONBOARDING
export const SearchOnboardingFrame = styled.div`
  border-radius: 36px;
  border: 2px solid var(--color-data-skill-background);
  background: rgba(255, 255, 255, 0.01);
  box-shadow: 0px 0px 100px 0px #ffa366;

  z-index: 9999;

  pointer-events: none;
`;

export const SearchOnboardingFirstStepFrame = styled(SearchOnboardingFrame)`
  width: calc(100% + 16px);
  height: 278px;

  position: absolute;
  left: -8px;
  top: -8px;
`;

export const SearchOnboardingSecondStepFrame = styled(SearchOnboardingFrame)`
  width: calc(100% + 16px);
  height: 146px;

  @media (max-width: 1441px) {
    height: 208px;
  }
  @media (max-width: 1185px) {
    height: 255px;
    top: 280px;
  }

  position: absolute;
  top: 252px;
  left: -8px;
`;

export const SearchOnboardingCalloutWrappera = styled.div`
  width: 287px;
  height: 72px;

  border-radius: 16px;
  border: 2px solid var(--data-skill-background, #ffa366);
  background: var(--data-skill-background-secondary, rgba(235, 130, 61, 0.3));
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  transform: translateY(100%) translateX(-100%);

  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(18px);
    z-index: 1;
  }
`;

export const SearchOnboardingCalloutTriangleWrapper = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid rgba(235, 130, 61, 0.3);
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
`;

export const SearchOnboardingCalloutTriangleContent = styled.div`
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid rgba(235, 130, 61, 0.3);
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
`;

interface SearchOnboardingCalloutWrapperProps {
  bottom: string;
  left: string;
  right: string;
}

export const SearchOnboardingCalloutWrapper = styled.div<SearchOnboardingCalloutWrapperProps>`
  position: absolute;
  left: ${({ left }) => left};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right};

  transform: translateY(100%) translateX(-100%);

  @media (max-width: 1480px) {
    transform: translateY(100%) translateX(-100%) scaleX(-1);

    & > span {
      transform: scaleX(-1);
    }
  }

  &:before {
    content: '';
    height: calc(100% - 11px);
    width: calc(100% - 26px);

    position: absolute;
    top: 0;
    left: 5px;

    border-radius: 16px;
    backdrop-filter: blur(18px);
    z-index: -1;
  }
`;

export const SearchOnboardingCalloutCloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -6px;
  right: 15px;

  & > svg {
    z-index: 1;
  }

  &:before {
    content: '';
    width: 23px;
    height: 18px;

    position: absolute;

    backdrop-filter: blur(16px);
    border-radius: 100%;

    z-index: 0;
  }

  cursor: pointer;
`;

export const SearchOnboardingCalloutText = styled.span`
  max-width: 255px;

  position: absolute;
  top: 16px;
  left: 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  & > span.highlight {
    font-weight: 700;
    font-size: inherit;
    line-height: inherit;
    color: #ffffff;
  }

  color: #ffffff;
`;
