import styled from 'styled-components';
import { appearAnimation, disappearAnimation, expandAnimation, slideFromRightAnimation, slideFromTopAnimation, slideToRightAnimation, slideToTopAnimation } from 'styles/animations';
import { BREAKPOINTS } from 'styles/media';

interface ProfileEditorDrawerOverlayProps {
  triggerClosing: boolean;
}

export const ProfileEditorDrawerOverlay = styled.div<ProfileEditorDrawerOverlayProps>`
  ${appearAnimation('1')}
  background: rgba(84, 120, 161, 0.8);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999998;

  ${({ triggerClosing }) => (triggerClosing ? disappearAnimation('1') : ``)}
`;

interface ProfileEditorDrawerWrapperProps {
  triggerClosing: boolean;
}

export const ProfileEditorDrawerWrapper = styled.div<ProfileEditorDrawerWrapperProps>`
  ${slideFromRightAnimation}
  position: fixed;
  top: 0px;
  right: 0px;

  height: 100%;
  min-height: 100vh;
  width: 522px;
  max-width: 522px;
  min-height: 522px;
  z-index: 99999999;

  background: #FFFFFF;
  
  ${({ triggerClosing }) => (triggerClosing ? slideToRightAnimation : ``)}

  ${BREAKPOINTS.tablet} {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
  }
`;

export const ProfileEditorDrawerHeaderWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.headerBackground};
  border-bottom: 1px solid ${({ theme }) => theme.headerBottomBorder};

  padding: 54px 48px 32px;

  ${BREAKPOINTS.tablet} {
    padding: 32px;
  }
`;

export const ProfileEditorDrawerHeaderCloseIconContainer = styled.div`
  width: 24px;
  height: 24px;

  position: absolute;
  top: 32px;
  right: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  ${BREAKPOINTS.tablet} {
    top: 16px;
    right: 16px;
  }
`;

interface ProfileEditorDrawerContentWrapperProps {
  bottomHeight: number;
  topHeight: number;
  alwaysShowScroll: boolean;
}

export const ProfileEditorDrawerContentWrapper = styled.div<ProfileEditorDrawerContentWrapperProps>`
  height: calc(100% - ${({ bottomHeight }) => bottomHeight}px - ${({ topHeight }) => topHeight}px);
  width: 100%;
  background: #FFFFFF;

  position: relative;

  padding-bottom: 41px;

  overflow-y: scroll;

  ${({alwaysShowScroll}) => alwaysShowScroll && `
    ${BREAKPOINTS.tablet} {
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
  
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
    }
  `}
`;

export const ProfileEditorDrawerBottomWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.bottomBackground};
  backdrop-filter: blur(8px);
  padding: 20px 10px;

  position: sticky;
  right: 0px;
  bottom: 0px;
`;

interface ProfileEditorDrawerWarningWrapperProps {
  triggerClosing: boolean;
}

export const ProfileEditorDrawerWarningWrapper = styled.div<ProfileEditorDrawerWarningWrapperProps>`
  position: fixed;
  top: 0px;
  right: 0px;

  min-height: 100vh;
  width: 522px;
  max-width: 522px;
  min-width: 522px;

  z-index: 9999999999;
  ${({ triggerClosing }) => (triggerClosing ? slideToRightAnimation : ``)}

  ${BREAKPOINTS.tablet} {
    width: 100%;
    max-width: 100%;
    min-width: unset;
  }
`;

export const ProfileEditorDrawerWarningContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

interface ProfileEditorDrawerWarningContainerProps {
  closingWarning: boolean;
}

export const ProfileEditorDrawerWarningContainer = styled.div<ProfileEditorDrawerWarningContainerProps>`
  ${slideFromTopAnimation}
  height: 100vh;

  position: relative;

  background: linear-gradient(180deg, rgba(212, 117, 99, 0.5) -42.47%, rgba(212, 117, 99, 0.085) 100%), #FFFFFF;
  border-bottom: 1px solid var(--color-data-passion-foreground);
  backdrop-filter: blur(50px);
  z-index: 99999999991;

  padding: 54px 36px;

  display: flex;
  flex-direction: column;

  ${({ closingWarning }) => (closingWarning ? slideToTopAnimation : ``)}
`;

interface ProfileEditorDrawerWarningBottomProps {
  closingWarning: boolean;
  opening: boolean;
}

export const ProfileEditorDrawerWarningBottom = styled.div<ProfileEditorDrawerWarningBottomProps>`
  display: flex;
  flex-direction: row;

  padding: 20px 10px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 99999999992;
  gap: 10px;

  ${BREAKPOINTS.mobile} {
    bottom: 100px;
  }

  & > :not(:first-child) {
    ${expandAnimation({startingWidth: '100%', finalWidth: '50%', duration: '450ms'})}
    ${({ closingWarning }) => (closingWarning ? expandAnimation({startingWidth: '50%', finalWidth: '100%', duration: '450ms'}) : ``)}
  }

  & > :first-child {
    ${({ closingWarning }) => (closingWarning && expandAnimation({startingWidth: '50%', finalWidth: '0px', duration: '450ms'}))}
    ${({ closingWarning, opening }) => ((closingWarning || opening) ? 'padding: 16px 0px;' : 'padding: 16px 48px 16px 40px;')}
  }
`;

export const ProfileEditorDrawerWarningDiscardButton = styled.button`
  ${expandAnimation({startingWidth: '0px', finalWidth: '50%', duration: '450ms'})}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 2px solid var(--color-primary);
  border-radius: 100px;

  & > :not(:last-child) {
    margin-right: 14px;
  }

  & > span {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-text);
  }
`;

export const ProfileEditorDrawerWarningTitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ProfileEditorDrawerWarningIconContainer = styled.div`
  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfileEditorDrawerWarningTitle = styled.h2`
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  color: var(--color-text);
`;

export const ProfileEditorDrawerWarningCenterContainer = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  justify-content: center;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ProfileEditorDrawerWarningSubtitle = styled.h4`
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  color: var(--color-text);
`;

export const ProfileEditorDrawerWarningDescription = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: var(--color-text);
`;

// SHARED PROFILE EDITOR DRAWERS' COMPONENTS

export const ProfileEditorDrawerTitle = styled.span`
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  color: var(--color-text);
`;

interface ProfileEditorDrawerPublishButtonProps {
  disabled?: boolean;
}

export const ProfileEditorDrawerPublishButton = styled.button<ProfileEditorDrawerPublishButtonProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 16px 48px;

  background: ${({ disabled }) => disabled ? 'var(--color-data-alumni-background)' : 'var(--color-primary)'};

  ${({ disabled }) => !disabled && `
    transition: background 200ms ease-in-out;

    &:hover {
      background: var(--color-dark-theme-background-secondary);
    }
  `}

  border-radius: 100px;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ disabled }) => disabled ? 'var(--color-text)' : '#FFFFFF'};
`;
