import styled from "styled-components";

export const GlobalClientDrawerCountersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 20px;
  }
`;

export const GlobalClientDrawerCounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const GlobalClientDrawerCounterIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const GlobalClientDrawerCounter = styled.span`
  font-weight: 300;
  font-size: 38px;
  line-height: 42px;
  text-align: center;
  color: #171C33;
`

export const GlobalClientDrawerCounterText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(23, 28, 51, 0.5);
`;

export const GlobalClientDrawerHighlightText = styled.h4`
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;

  & .people {
    color: #FFC229;
  }

  & .company {
    color: #5478A1;
  }

  & .skill {
    color: #EB823D;
  }

  & .client {
    color: #104D5B;
  }

  & .project {
    color: #392B62;
  }

  & .industries {
    color: #8DA76C;
  }
`;

export const GlobalClientDrawerHighlightTextContainer = styled.div`
  & > h4:not(:first-child) {
    margin-top: 10px;
  }
`;