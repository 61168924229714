import styled from 'styled-components/macro';

interface ISelectWrapper {
  type?: string;
}

export const DropdownWrapper = styled.div`
  min-width: 181px;
  position: relative;
  border-bottom: 1px solid #7b7d87;
`;

export const DropdownButton = styled.button`
  font-size: 15px;
  color: #7b7d87;
  width: 100%;
  text-align: left;
  margin-top: 7px;
  padding-bottom: 14px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 7px;
    width: 41px;
    height: 13px;
    path {
      stroke: #FFF;
    }
  }
`;

export const DropdownContentWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  right: 0;
  max-height: 300px;
  overflow: auto;
  padding: 18px 19px;
  background: #FFF;
`;

export const DropdownContent = styled.div`

`;

export const DropdownItem = styled.div`
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  span {
    font-size: 13px;
    margin-right: auto;
  }
  input {
    width: 1px;
    height: 1px;
    opacity: 0;
    display: none;
    &:checked + div {
      background: #FFC229;
    }
    &:checked ~ span {
      font-weight: 500;
    }
  }
  div {
    height: 15px;
    width: 15px;
    border-radius: 2px;
    border: 1px solid #C4C4C4;
  }
`;