import React, { useEffect, useMemo, useRef, useState } from 'react';

import { DownArrow } from 'app/components/shared/icons';
import useOnClickOutside from 'hooks/click-outside';

import { DropdownCheckbox, DropdownWrapper } from './styled';

interface Option {
  name: string;
  id: string;
  count?: number;
}

interface FinalOption {
  name: string;
  id: string[];
}

interface IProps {
  onChange: (options: string[]) => void;
  options: Option[];
  title: string | React.ReactElement;
  originalTitle?: string;
  closeOnPick?: boolean;
  checkedOptions?: string[];
  includeAll?: boolean;
  arrowFill?: string;
  onClickOutside?: () => void;
  availableOptions?: string[];
  startOpen?: boolean;
}

export default function CheckboxDropdown({
  onChange,
  options,
  title,
  originalTitle,
  closeOnPick = false,
  checkedOptions = null,
  includeAll = true,
  arrowFill = 'none',
  onClickOutside = () => {},
  availableOptions = [],
  startOpen = false,
}: IProps) {
  const [isOpen, setIsOpen] = useState<boolean>(startOpen);
  const [checkedIds, setCheckedIds] = useState<string[]>(checkedOptions || []);
  const [checkedNames, setCheckedNames] = useState<string[]>([]);
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => {
    if (onClickOutside && isOpen) {
      onClickOutside();
    }
    setIsOpen(false);
  });

  const finalOptions = useMemo(() => {
    const groupedOptions = options.reduce((acc, opt) => {
      if (acc[opt.name]) {
        acc[opt.name].push(opt.id);
      } else {
        acc[opt.name] = [opt.id];
      }
      return acc;
    }, {});

    return Object.keys(groupedOptions).map((name) => {
      return {
        name,
        id: groupedOptions[name],
      };
    });
  }, [options, availableOptions]);

  const finalTitle = useMemo<string>(() => {
    if (checkedNames.length === 0) return originalTitle;
    if (checkedNames.length === 1) return checkedNames[0];
    return `${checkedNames.length} ${originalTitle}`;
  }, [checkedNames]);

  useEffect(() => {
    if (checkedOptions) setCheckedIds(checkedOptions);
  }, [checkedOptions]);

  const handleChange = (opt?: FinalOption) => {
    if (closeOnPick) {
      setIsOpen(false);
    }

    if (!opt) {
      if (checkedIds && finalOptions.every((o) => checkedOptionsIncludeAny(o.id))) {
        return setCheckedIds([]);
      }
    }
    if (!checkedIds) {
      return setCheckedIds(opt.id.map((i) => i));
    }

    if (checkedOptionsIncludeAll(opt.id)) {
      return setCheckedIds(checkedIds.filter((i) => !opt.id.includes(i)));
    }

    return setCheckedIds(checkedIds.concat(opt.id));
  };

  const availableOptionsIncludeAny = (ids: string[]) => {
    return availableOptions.length > 0 ? ids.some((id) => availableOptions.includes(id)) : true;
  };

  const checkedOptionsIncludeAny = (ids: string[]) => {
    return checkedIds.length > 0 ? ids.some((id) => checkedIds.includes(id)) : false;
  };

  const checkedOptionsIncludeAll = (ids: string[]) => {
    return checkedIds.length > 0 ? ids.every((id) => checkedIds.includes(id)) : false;
  };

  useEffect(() => {
    if (!checkedIds) return;
    onChange(checkedIds);
  }, [checkedIds]);

  useEffect(() => {
    if (!checkedOptions || checkedOptions.length === 0) return setCheckedNames([]);

    const names = finalOptions
      .filter((opt) =>
        checkedOptions.length > 0 ? opt.id.some((id) => checkedOptions.includes(id)) : false
      )
      .map((opt) => opt.name);

    setCheckedNames(names);
  }, [checkedOptions]);

  const handleOptionClick = (opt: FinalOption) => {
    if (!checkedNames.includes(opt.name)) return setCheckedNames(checkedNames.concat(opt.name));

    if (checkedNames.includes(opt.name))
      setCheckedNames(checkedNames.filter((name) => name !== opt.name));
  };

  return (
    <DropdownWrapper ref={dropdownRef} isOpen={isOpen}>
      <div className="button-container" onClick={() => setIsOpen(!isOpen)}>
        <span className="dropdown-title">{finalTitle}</span>
        <DownArrow fill={arrowFill} />
      </div>
      {isOpen ? (
        <div className="options-container">
          {includeAll && (
            <DropdownCheckbox>
              <label>
                <span>All</span>
                <input
                  value=""
                  onChange={() => handleChange()}
                  checked={options
                    .map((o) => o.id)
                    .every((id) => checkedIds && checkedIds.includes(id))}
                  type="checkbox"
                />
                <div></div>
              </label>
            </DropdownCheckbox>
          )}
          {finalOptions.map((opt) => (
            <DropdownCheckbox
              key={opt.id}
              disabled={availableOptions.length > 0 ? !availableOptionsIncludeAny(opt.id) : false}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleOptionClick(opt);
                handleChange(opt);
              }}
            >
              <label>
                <span>{opt.name}</span>
                <input
                  value=""
                  checked={checkedIds && checkedOptionsIncludeAny(opt.id)}
                  data-name={opt.name}
                  type="checkbox"
                  disabled={
                    availableOptions.length > 0 ? !availableOptionsIncludeAny(opt.id) : false
                  }
                />
                <div></div>
              </label>
            </DropdownCheckbox>
          ))}
        </div>
      ) : null}
    </DropdownWrapper>
  );
}
