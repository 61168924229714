import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import CheckboxDropdown from 'app/components/CommonStyled/CheckboxDropdown';
import ToggleButton from 'app/components/CommonStyled/ToggleButton';
import useProjectsDrawerList from 'app/components/DetailsPages/hooks/company/useProjectsDrawerList';
import { ProjectIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { DetailsPageContext } from 'app/components/DetailsPages/shared/DetailsPageContext';
import useIsMobile from 'hooks/useIsMobile';
import { User } from 'interfaces';
import { userDataSelector } from 'store/reducers/user-reducer';
import { limitText } from 'utils';

import GlobalProjectDrawer from '../GlobalProject/GlobalProjectDrawer';
import {
  ProjectClient,
  ProjectContainer,
  ProjectDrawerFilterOption,
  ProjectDrawerFilterOptionSkeleton,
  ProjectDrawerPill,
  ProjectDrawerText,
  ProjectsDrawerContainer,
  ProjectsDrawerFilterDropdownWrapper,
  ProjectsDrawerFiltersContainer,
  ProjectsDrawerFiltersDescription,
  ProjectsDrawerFiltersOptionsContainer,
  ProjectsDrawerFiltersTitle,
  ProjectsDrawerListContainer,
  ProjectsDrawerListWrapper,
  ProjectSkeleton,
  ProjectUserAvatar,
} from './styled';

export default function ProjectsDrawer() {
  const [filterIncludeUser, setFilterIncludeUser] = useState<boolean>(false);
  const [projects, setProjects] = useState<any[]>([]);
  const [yearFilter, setYearFilter] = useState<string>('all');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(50);
  const [clientsSlugs, setClientsSlugs] = useState<string[]>([]);
  const [showingProjectDrawer, setShowingProjectDrawer] = useState<boolean>(false);
  const [projectDrawerSlug, setProjectDrawerSlug] = useState<string>('');
  const listContainerRef = useRef(null);

  const context = useContext(DetailsPageContext);
  const userData: User = useSelector(userDataSelector);

  const projectsQuery = useProjectsDrawerList(context.slug || userData.kyu_company?.slug, {
    filter_by: yearFilter,
    current_person: filterIncludeUser,
    sort_dir: 'desc',
    filter_slugs_list: clientsSlugs,
    per_page: perPage,
    page: currentPage,
  });

  const { isLoading, isIdle, data } = projectsQuery;

  useEffect(() => {
    setCurrentPage(1);
  }, [clientsSlugs]);

  const observer = useRef(null);
  const lastProject = useCallback(
    (node) => {
      if (isLoading) return;

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && data.meta.next_page) {
          setCurrentPage((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading]
  );

  useEffect(() => {
    if (data) {
      if (currentPage === 1) {
        setProjects(data.projects);
      } else {
        setProjects([...projects, ...data.projects]);
      }
    } else {
      if (currentPage === 1) {
        setProjects([]);
      }
    }
  }, [data]);

  return (
    <ProjectsDrawerContainer showingProjectDrawer={showingProjectDrawer}>
      {showingProjectDrawer ? (
        <GlobalProjectDrawer
          slug={projectDrawerSlug}
          comingFromList
          backToListAction={() => setShowingProjectDrawer(false)}
        />
      ) : (
        <>
          <ProjectsDrawerFiltersContainer>
            <ProjectsDrawerFiltersTitle>
              Projects of {context.data.name || userData.kyu_company?.name}{' '}
            </ProjectsDrawerFiltersTitle>
            <ProjectsDrawerFiltersDescription>
              This list of projects has been provided by{' '}
              {context.data.name || userData.kyu_company?.name} directly and gets updated
              frequently.
              <br />
              <br />
              You can refine this list using the filters below.
            </ProjectsDrawerFiltersDescription>
            {!isLoading && !isIdle ? (
              <ProjectYearsFilters
                filters={data.projects_filters.years}
                setYearFilter={setYearFilter}
                currentFilter={yearFilter}
                setCurrentPage={setCurrentPage}
              />
            ) : (
              <ProyectYearsFilterSkeleton />
            )}
          </ProjectsDrawerFiltersContainer>
          {!isLoading && !isIdle ? (
            <ProjectClientFilter
              clients={data.slug_list}
              clientsSlugs={clientsSlugs}
              setClientsSlugs={setClientsSlugs}
            />
          ) : (
            <ProjectDrawerFilterOptionSkeleton />
          )}
          <ProjectsDrawerListWrapper>
            <ProjectsDrawerListContainer ref={listContainerRef}>
              {!isLoading && !isIdle && data.any_includes_current_person && (
                <ToggleButton
                  label={'Only clients I share'}
                  labelColor={'rgba(23, 28, 51, 0.5)'}
                  onChange={(checked) => {
                    setCurrentPage(1);
                    setFilterIncludeUser(checked);
                  }}
                  checked={filterIncludeUser}
                />
              )}
              {projects.length > 0 ? (
                <>
                  {projects.map((project, index) => {
                    if (projects.length === index + 1) {
                      return (
                        <ProjectPill
                          ref={lastProject}
                          project={project}
                          userData={userData}
                          key={index}
                          setShowingProjectDrawer={setShowingProjectDrawer}
                          setProjectDrawerSlug={setProjectDrawerSlug}
                        />
                      );
                    }
                    return (
                      <ProjectPill
                        project={project}
                        userData={userData}
                        key={index}
                        setShowingProjectDrawer={setShowingProjectDrawer}
                        setProjectDrawerSlug={setProjectDrawerSlug}
                      />
                    );
                  })}
                </>
              ) : (
                <ProjectsListSkeleton />
              )}
            </ProjectsDrawerListContainer>
          </ProjectsDrawerListWrapper>
        </>
      )}
    </ProjectsDrawerContainer>
  );
}

interface ProjectPillProps {
  project: any;
  userData: User;
  setShowingProjectDrawer: (value: boolean) => void;
  setProjectDrawerSlug: (value: string) => void;
}

const ProjectPill = React.forwardRef<HTMLDivElement, ProjectPillProps>(
  ({ project, userData, setProjectDrawerSlug, setShowingProjectDrawer }, ref) => {
    const isMobile = useIsMobile();
    return (
      <ProjectContainer
        ref={ref}
        onClick={() => {
          setProjectDrawerSlug(project.slug);
          setShowingProjectDrawer(true);
        }}
      >
        <ProjectDrawerPill>
          <ProjectIcon width={13} height={13} fill={'#392B62'} />
          <ProjectDrawerText>{limitText(project.name, isMobile ? 15 : 20)}</ProjectDrawerText>
          {project.includes_current_person && <ProjectUserAvatar src={userData.profile_image} />}
        </ProjectDrawerPill>
        <ProjectClient>{limitText(project.client_name, isMobile ? 15 : 20)}</ProjectClient>
      </ProjectContainer>
    );
  }
);

function ProjectsListSkeleton() {
  return (
    <>
      <ProjectSkeleton width={298} />
      <ProjectSkeleton width={190} />
      <ProjectSkeleton width={199} />
      <ProjectSkeleton width={321} />
      <ProjectSkeleton width={243} />
      <ProjectSkeleton width={243} />
      <ProjectSkeleton width={176} />
      <ProjectSkeleton width={321} />
      <ProjectSkeleton width={176} />
    </>
  );
}

function ProjectClientFilter({
  clients,
  clientsSlugs,
  setClientsSlugs,
}: {
  clients: any[];
  clientsSlugs: string[];
  setClientsSlugs: (value: string[]) => void;
}) {
  const [tempClientsSlugs, setTempClientsSlugs] = useState<string[]>(clientsSlugs);
  const [title, setTitle] = useState<string>('Clients');
  const options = clients.map((filterOption) => {
    return {
      id: filterOption.slug,
      name: filterOption.name,
    };
  });

  const onClickOutside = () => {
    setClientsSlugs(tempClientsSlugs);
  };

  const onChange = (checkedIds) => {
    setTempClientsSlugs(checkedIds);

    if (checkedIds.length === 0) {
      setTitle('Clients');
    } else if (checkedIds.length === 1) {
      setTitle(clients.find((client) => client.slug === checkedIds[0]).name);
    } else {
      setTitle(`${checkedIds.length} clients`);
    }
  };

  return (
    <ProjectsDrawerFilterDropdownWrapper hasSelection={title !== 'Clients'}>
      <CheckboxDropdown
        onChange={(checkedIds) => onChange(checkedIds)}
        options={options}
        title={title}
        checkedOptions={clientsSlugs}
        includeAll={false}
        arrowFill={'#171C33'}
        onClickOutside={onClickOutside}
      />
    </ProjectsDrawerFilterDropdownWrapper>
  );
}

function ProjectYearsFilters({
  filters,
  currentFilter,
  setYearFilter,
  setCurrentPage,
}: {
  filters: string[];
  currentFilter: string;
  setYearFilter: (year: string) => void;
  setCurrentPage: (page: number) => void;
}) {
  return (
    <ProjectsDrawerFiltersOptionsContainer>
      {filters.map((filter, index) => (
        <ProjectDrawerFilterOption
          key={index}
          selected={currentFilter === filter}
          onClick={() => {
            setCurrentPage(1);
            setYearFilter(filter);
          }}
        >
          {filter}
        </ProjectDrawerFilterOption>
      ))}
    </ProjectsDrawerFiltersOptionsContainer>
  );
}

function ProyectYearsFilterSkeleton() {
  return (
    <ProjectsDrawerFiltersOptionsContainer>
      <ProjectDrawerFilterOptionSkeleton />
      <ProjectDrawerFilterOptionSkeleton />
      <ProjectDrawerFilterOptionSkeleton />
      <ProjectDrawerFilterOptionSkeleton />
    </ProjectsDrawerFiltersOptionsContainer>
  );
}
