import styled from 'styled-components';
import mq, { BREAKPOINTS } from 'styles/media';

export const FeedbackBlockContent = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 56px 48px 50px;
  position: relative;
  width: 100%;
  ${BREAKPOINTS.mobile} {
    padding: 24px 16px 60px;
  }
`;

export const FeedbackBlockIntro = styled.div`
  margin-bottom: 32px;
  h4 {
    font-size: 30px;
    margin-bottom: 22px;
  }
  p {
    font-size: 17px;
    line-height: 142%;
  }
`;

interface FeedbackBlockFormProps {
  showError?: boolean;
}

export const FeedbackBlockForm = styled.div<FeedbackBlockFormProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  textarea {
    background: rgba(196, 196, 196, 0.1);
    border-radius: 4px;
    border: none;
    height: 300px;
    outline: none;
    padding: 18px 24px;
    resize: none;
    width: 100%;
  }
  > span.error-msg {
    color: var(--color-error);
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
    opacity: ${({ showError }) => (showError ? '1' : '0')};
    text-align: center;
  }
`;

export const FeedbackBlockCheckbox = styled.div`
  margin-bottom: 10px;
  .checkbox {
    margin-top: 20px;
    display: flex;
    align-items: center;
    color: var(--color);

    &--disabled {
      color: var(--disabled);
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &:hover {
      .checkbox__control {
        background: #fff;
      }
    }
  }

  .checkbox__control {
    width: 15px;
    height: 15px;
    display: block;
    border-radius: 2px;
    transition: all 0.2s ease;
    background-color: #fff;
    border: 1px solid var(--color-text);
  }
  .radio__label {
    font-size: 15px;
    font-weight: 500;
    margin-left: 8px;
    text-align: left;
    line-height: 130%;
  }
  .checkbox__input {
    display: block;
    input {
      opacity: 0;
      width: 1px;
      height: 1px;
      display: none;
      visibility: hidden;
      &:hover {
        background: var(--color-text);
      }

      &:focus + .checkbox__control {
      }

      &:checked + .checkbox__control {
        background: var(--color-text);
      }

      &:disabled + .checkbox__control {
        color: var(--disabled);
      }
    }
  }
`;

export const FeedbackSubmitButton = styled.button`
  background: #ffc229;
  border-radius: 100px;
  font-size: 15px;
  font-weight: bold;
  height: 50px;
  margin-top: auto;
  padding: 17px 48px 16px;
  position: relative;
  text-align: center;
  transition: opacity 0.2s ease;
  width: 100%;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

export const FeedbackSentSection = styled.section`
  padding: 0 10px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  ${mq.minSm} {
    padding: 0 30px;
  }
  svg {
    max-width: 90%;
    margin-bottom: 50px;
    ${mq.minSm} {
      max-width: 100%;
    }
  }
  h3 {
    font-size: 46px;
    margin-bottom: 20px;
    ${mq.minSm} {
      font-size: 64px;
    }
  }
  p {
    padding: 0 25px;
    font-size: 15px;
    line-height: 145%;
  }
`;

export const SubmitContentWrapper = styled.div`
  height: 100%;
  padding: 64px 48px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  justify-content: space-between;

  position: relative;

  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.3) 0.01%,
      rgba(122, 122, 122, 0.12) 99.98%,
      rgba(251, 175, 161, 0.3) 99.99%,
      rgba(206, 206, 206, 0.12) 100%
    ),
    #ffffff;

  & > :not(:last-child) {
    margin-bottom: 48px;
  }
`;

export const SubmitContentTopTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  margin-bottom: 48px;
`;

export const SubmitContentLitleTitle = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(23, 28, 51, 0.5);
`;

export const SubmitContentTitle = styled.h3`
  font-weight: 300;
  font-size: 32px;
  line-height: 42px;
  color: var(--color-text);
`;

export const SubmitContentCenterContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 48px;
  }
`;

export const SubmitContentInputContainer = styled.div``;

interface SubmitContentInputLabelProps {
  for: string;
}

export const SubmitContentInputLabel = styled.label<SubmitContentInputLabelProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-text);
`;

export const SubmitContentInput = styled.input`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 4px;

  border-bottom: 1px solid var(--color-primary);

  font-weight: 300;
  font-size: 18px;
  line-height: 23px;

  margin-top: 20px;

  &::placeholder {
    color: var(--color-text);
    opacity: 0.3;
  }
`;

export const SubmitContentSelect = styled.select`
  width: 100%;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;

  border-bottom: 1px solid var(--color-primary);

  margin-top: 20px;

  overflow: hidden;
`;

export const SubmitContentBottomContainer = styled.div`
  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const SubmitContentButtonTitle = styled.h4`
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: rgba(23, 28, 51, 0.5);
`;

export const SubmitContentButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 48px;

  background: ${({ disabled }) => (disabled ? 'var(--color-lightgray)' : 'var(--color-primary)')};
  border-radius: 100px;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
`;
