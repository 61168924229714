import styled from "styled-components";
import { BREAKPOINTS } from "styles/media";

// index
interface InputWrapperProps {
  isOptionsOpened: boolean;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  align-items: center;
  background: #f1f4f8;
  border-radius: 34px;
  display: flex;
  height: 70px;
  justify-content: center;
  transition: all 0.4s ease;
  padding: 12px;
  position: relative;
  width: 100%;
  > div.horizontal-wrapper {
    align-items: center;
    border-radius: 34px;
    display: flex;
    height: 100%;
    overflow-y: hidden;
    width: 100%;
    &::-webkit-scrollbar {
      height: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #202954;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #313a65;
    }
  }
  ${({ isOptionsOpened }) =>
    isOptionsOpened ? `border-radius: 34px 34px 0 0;` : ""}
  > button.remove-options-button {
    align-items: center;
    background: rgba(217, 217, 217, 0.5);
    border-radius: 100%;
    display: flex;
    height: 18px;
    justify-content: center;
    margin-right: 8px;
    min-height: 18px;
    min-width: 18px;
    position: sticky;
    right: 56px;
    width: 18px;
  }
  ${BREAKPOINTS.tablet} {
    width: 370px;
  }
  ${BREAKPOINTS.mobile} {
    height: 50px;
    padding: 10px 12px;
    width: 310px;
    > button.remove-options-button {
      margin-right: 11px;
    }
  }
`;

export const Input = styled.input`
  &::placeholder {
    color: #adadad;
    font-weight: 500;
  }
  color: #171c33;
  flex-grow: 1;
  font-size: 15px;
  font-weight: bold;
  line-height: 28px;
  width: 100%;
  padding-left: 23px;
  ${BREAKPOINTS.mobile} {
    font-size: 14px;
  }
`;

export const Button = styled.button`
  align-items: center;
  background: var(--color-primary);
  border-radius: 100%;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 44px;
  min-width: 44px;
  position: sticky;
  right: 0;
  > svg {
    height: 23px;
    width: 23px;
  }
  &:disabled {
    background: #adadad;
    cursor: not-allowed;
  }
  ${BREAKPOINTS.mobile} {
    min-height: 34px;
    min-width: 34px;
  }
`;

// SelectedPills
export const CurrentPillsContainer = styled.div`
  align-items: center;
  border-radius: 34px;
  display: flex;
`;

interface PillProps {
  bgColor: string;
  focusColor: string;
  isFocused: boolean;
}

export const Pill = styled.div<PillProps>`
  ${({ isFocused, bgColor, focusColor }) => `
    background: ${isFocused ? focusColor : bgColor};
  `}
  align-items: center;
  border-radius: 50px;
  cursor: default;
  display: flex;
  height: 44px;
  justify-content: space-between;
  line-height: 28px;
  margin-right: 4px;
  padding: 8px 24px;
  transition: all 0.2s ease;
  > span {
    color: var(--color-text);
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
  }
  > button.remove-button {
    background: none;
    border: 0;
    border-radius: 100%;
    cursor: pointer;
    display: none;
    height: 10px;
    margin-left: 15px;
    padding: 12px;
    width: 10px;
    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  }
  &:hover {
    > button.remove-button {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  ${BREAKPOINTS.mobile} {
    height: 32px;
    padding: 7px 12px;
  }
`;

// InputOptions
interface OptionsWrapperProps {
  optionsMaxHeight?: string;
}

export const OptionsWrapper = styled.div<OptionsWrapperProps>`
  align-items: center;
  background: rgb(246, 246, 246);
  border-radius: 0 0 34px 34px;
  box-shadow: 2px 2px 15px -4px rgba(16, 77, 91, 0.25);
  display: flex;
  flex-direction: column;
  left: 0;
  max-height: ${({ optionsMaxHeight = "320px" }) => optionsMaxHeight};
  overflow: auto;
  padding: 20px 28px;
  position: absolute;
  top: 100%;
  transition: all 0.4s ease;
  width: 100%;
  z-index: 99;
  > span {
    margin: 5px 0;
  }
  > span.not-found-message {
    color: var(--color-lightgray);
  }
  ${BREAKPOINTS.mobile} {
    max-height: 260px;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: 100%;
  > button.show-more-button {
    color: var(--color-lightgray);
    text-align: left;
    width: 100%;
    &:hover {
      text-decoration: underline;
    }
  }
`;

interface OptionsTitleProps {
  bgColor: string;
}

export const OptionsTitle = styled.h3<OptionsTitleProps>`
  border-bottom: 1px solid var(--color-lightgray);
  color: var(--color-lightgray);
  font-weight: 500;
  margin-bottom: 21px;
  padding-bottom: 12px;
  position: relative;
  width: 100%;
  &:after {
    background: ${({ bgColor }) => bgColor};
    content: "";
    height: 100%;
    left: -28px;
    position: absolute;
    top: -4px;
    width: 4px;
  }
`;

interface OptionButtonProps {
  isFocused: boolean;
}

export const OptionButton = styled.button<OptionButtonProps>`
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  ${({ isFocused }) =>
    isFocused
      ? `
    font-weight: bold;
    text-decoration: underline;
  `
      : ``}
  &:hover {
    > span {
      text-decoration: underline;
    }
  }
  > span.option-name {
    font-size: 16px;
    text-align: left;
  }
  > span.option-info {
    color: #adadad;
    text-align: right;
  }
  ${BREAKPOINTS.mobile} {
    > span {
      font-size: 14px;
    }
  }
`;
