import { RequestTransform, create } from 'apisauce';
import { behavioralObjectTypeCorrectionMap } from 'utils/constants';

import { getSignUpToken, getToken, setSignUpToken, setToken } from './storage';

const getHttpWithToken = (token: string) => {
  return create({
    baseURL: '/api',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    timeout: 120000,
  });
};

const getHttpNoApiWithToken = (token: string) => {
  return create({
    baseURL: '/',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    timeout: 120000,
  });
};

const httpNewUser = create({
  baseURL: '/api',
  headers: {
    Authorization: `Bearer ${getSignUpToken()}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 120000,
});

const http = create({
  baseURL: '/api',
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 120000,
});

const httpNoApi = create(
  getToken()
    ? {
        baseURL: '/',
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        timeout: 120000,
      }
    : {
        baseURL: '/',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        timeout: 120000,
      }
);

export const uploader = create({
  baseURL: '/api',
  headers: {
    Authorization: `Bearer false`,
    'Content-Type': 'multipart/form-data',
    Accept: 'multipart/form-data',
  },
  timeout: 60000,
});

const navMonitor = async (response) => {
  const isHome = response.config.url === '/graph';

  if (response.status === 404 && !isHome) {
    //window.location.href = '/not-found';
  }

  if (response.status === 400) {
    //window.location.href = '/not-found';
  }

  if (response.status === 500) {
    //window.location.href = '/server-error';
  }
};

const handleBehavioralTypeRequestTransform: RequestTransform = (request) => {
  if (request.url.includes('behaviorals') && request.data && request.data?.object_type) {
    request.data = {
      ...request.data,
      object_type: behavioralObjectTypeCorrectionMap.has(request.data.object_type)
        ? behavioralObjectTypeCorrectionMap.get(request.data.object_type)
        : request.data.object_type,
    };
  }

  return request;
};

http.addAsyncResponseTransform(navMonitor);
http.addRequestTransform(handleBehavioralTypeRequestTransform);

export const setAuthorization = (token, expiresIn = 14400) => {
  http.setHeader('Authorization', `Bearer ${token}`);
  httpNoApi.setHeader('Authorization', `Bearer ${token}`);
  PEClient.setHeader('Authorization', `Bearer ${token}`);
  uploader.setHeader('Authorization', `Bearer ${token}`);
  setToken(token, expiresIn);
};

export const setNewUserAuthorization = (token) => {
  httpNewUser.setHeader('Authorization', `Bearer ${token}`);
  setSignUpToken(token);
};

const PEClient = create({
  baseURL: '/api/my_profile',
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 120000,
});

export { http, getHttpWithToken, getHttpNoApiWithToken, httpNoApi, httpNewUser, PEClient };
