import React from 'react';

export default function PaperIcon({ width = 18, height = 22 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.24822 1.16547C1.77232 0.623816 2.48315 0.319519 3.22434 0.319519H9.74518C9.99225 0.319519 10.2292 0.420951 10.4039 0.601502L16.9247 7.34075C17.0994 7.5213 17.1976 7.76618 17.1976 8.02152V18.6118C17.1976 19.3778 16.9031 20.1124 16.379 20.6541C15.8549 21.1957 15.1441 21.5 14.4029 21.5H3.22434C2.48315 21.5 1.77232 21.1957 1.24822 20.6541C0.724123 20.1124 0.429688 19.3778 0.429688 18.6118V3.20777C0.429688 2.44176 0.724123 1.70712 1.24822 1.16547ZM3.22434 2.24502C2.97727 2.24502 2.74033 2.34645 2.56563 2.527C2.39093 2.70755 2.29279 2.95243 2.29279 3.20777V18.6118C2.29279 18.8671 2.39093 19.112 2.56563 19.2925C2.74033 19.4731 2.97727 19.5745 3.22434 19.5745H14.4029C14.65 19.5745 14.8869 19.4731 15.0616 19.2925C15.2363 19.112 15.3345 18.8671 15.3345 18.6118V8.4203L9.35932 2.24502H3.22434Z"
        fill="white"
      />
      <path
        d="M2.56563 2.527C2.74033 2.34645 2.97727 2.24502 3.22434 2.24502H9.35932L15.3345 8.4203V18.6118C15.3345 18.8671 15.2363 19.112 15.0616 19.2925C14.8869 19.4731 14.65 19.5745 14.4029 19.5745H3.22434C2.97727 19.5745 2.74033 19.4731 2.56563 19.2925C2.39093 19.112 2.29279 18.8671 2.29279 18.6118V3.20777C2.29279 2.95243 2.39093 2.70755 2.56563 2.527Z"
        fill="white"
      />
      <path
        d="M9.74405 0.319519C10.2585 0.319519 10.4484 0.483799 10.9937 1.04736L13.7202 3.86516L16.4467 6.68297C16.992 7.24653 17.1964 7.48981 17.1964 8.02152C17.1964 8.55323 16.7794 8.98427 16.2649 8.98427H9.74405C9.22957 8.98427 8.8125 8.55323 8.8125 8.02152V1.28227C8.8125 0.750557 9.22957 0.319519 9.74405 0.319519Z"
        fill="#DEDEDE"
      />
    </svg>
  );
}
