import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ClientIcon,
  CompanyIcon,
  IndustryIcon,
  PassionIcon,
  ProjectIcon,
  SkillIcon,
} from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { colorsLabels, focusedColors } from 'app/components/SearchInput/constants';
import { Pill } from 'app/components/SearchInput/styled';
import {
  BlinkingCursorIcon,
  CommunityIcon,
  PersonIcon,
  SearchIcon,
  SearchIconSmall,
} from 'app/components/shared/icons';
import Calendar from 'app/components/shared/icons/calendar';
import useDebounce from 'hooks/useDebounce';
import useIsMobile from 'hooks/useIsMobile';
import { focusSearchSelector, setFocusSearch } from 'store/reducers/navbar-reducer';
import { oldModelsToNew } from 'utils/constants';

import { SearchContext } from '../SearchContext';
import {
  Input,
  InputPlaceholder,
  InputPlaceholderContainer,
  InputPlaceholderText,
  InputPlaceholderTextNoAnimation,
  SearchInputIconContainer,
  SearchInputWrapper,
} from './styled';

export interface SearchInputMethods {
  /**
   * Focuses the input
   **/
  focus: () => void;
}

export const SearchInput = React.forwardRef<SearchInputMethods>(({}, ref) => {
  const context = useContext(SearchContext);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const isSearchFocused = useSelector(focusSearchSelector);
  const [currentInputValue, setCurrentInputValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedValue = useDebounce(currentInputValue);

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current.focus(),
  }));

  const shouldShowPlaceholder = useMemo(() => {
    return isFocused && currentInputValue === '';
  }, [currentInputValue, isFocused]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentInputValue(e.target.value);
  };

  useEffect(() => {
    context.setInputValue(debouncedValue);
  }, [debouncedValue]);

  const hasPill = useMemo(() => {
    return !!context.pill;
  }, [context.pill]);

  useEffect(() => {
    if (isSearchFocused) {
      inputRef.current.focus();
    }
  }, [isSearchFocused]);

  if (hasPill) return <SearchInputWithPill />;

  return (
    <SearchInputWrapper>
      <Input
        placeholder={!isFocused ? 'Search for' : null}
        name="search-input"
        autoComplete="off"
        ref={inputRef}
        value={currentInputValue}
        onChange={handleOnChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          dispatch(setFocusSearch(false));
          setIsFocused(false);
        }}
        hideCaret={shouldShowPlaceholder}
      />
      {shouldShowPlaceholder ? <SearchInputPlaceholder /> : null}
      <SearchInputIconContainer>
        <SearchIconSmall width={isMobile ? 15 : 24} height={isMobile ? 15 : 24} fill="#FFFFFF" />
      </SearchInputIconContainer>
    </SearchInputWrapper>
  );
});

function SearchInputPlaceholder() {
  const [index, setIndex] = useState<number>(0);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [showText, setShowText] = useState<boolean>(false);

  const placeholdersIcons = useMemo(
    () => [
      <IndustryIcon />,
      <SkillIcon />,
      <ClientIcon />,
      <PersonIcon />,
      <PassionIcon />,
      <ProjectIcon />,
      <CommunityIcon />,
      <CompanyIcon />,
      <Calendar />,
    ],
    []
  );

  const placeholdersText = useMemo(
    () => [
      'industries',
      'skills',
      'clients',
      'people',
      'passions',
      'projects',
      'communities',
      'companies',
      'events',
    ],
    []
  );

  const handleAnimationEnd = () => {
    setTimeout(() => {
      setShowIcon(false);
      setShowText(false);
      setIndex((index) => (index + 1) % placeholdersIcons.length);
    }, 1000);
  };

  useEffect(() => {
    const iconTimeout = setTimeout(() => {
      setShowIcon(true);
    }, 250);
    const textTimeout = setTimeout(() => {
      setShowText(true);
    }, 500);

    return () => {
      clearTimeout(iconTimeout);
      clearTimeout(textTimeout);
    };
  }, [index]);

  return (
    <InputPlaceholder>
      <InputPlaceholderTextNoAnimation>Search for</InputPlaceholderTextNoAnimation>
      <InputPlaceholderContainer>
        {showText ? (
          <InputPlaceholderText onAnimationEnd={handleAnimationEnd}>
            {React.cloneElement(placeholdersIcons[index], {
              width: 14,
              height: 14,
              fill: 'rgba(23, 28, 51, 0.3)',
            })}
            {placeholdersText[index]}
          </InputPlaceholderText>
        ) : null}
        <BlinkingCursorIcon fill={'rgba(23, 28, 51, 0.3)'} />
      </InputPlaceholderContainer>
    </InputPlaceholder>
  );
}

function SearchInputWithPill() {
  const { pill, setPill } = useContext(SearchContext);
  const { push } = useHistory();
  const isMobile = useIsMobile();

  const handleSearchClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (pill.slug === 'kyu') return push('/kyu');

    if (pill) {
      push(`/${oldModelsToNew[pill.model]}/${pill.slug}`);
    }
  };

  return (
    <SearchInputWrapper>
      <Pill
        bgColor={colorsLabels[pill.model]}
        focusColor={focusedColors[pill.model]}
        id={pill.uuid}
        isFocused={false}
        key={pill.uuid}
      >
        <span>{pill.name}</span>
        <button
          aria-label="close"
          className="remove-button"
          onClick={(e) => {
            e.stopPropagation();
            setPill(null);
          }}
        >
          <img alt="close" src="/icons/search-input/close-icon.svg" />
        </button>
      </Pill>
      <SearchInputIconContainer onClick={handleSearchClick}>
        <SearchIcon width={isMobile ? 15 : 24} height={isMobile ? 15 : 24} fill="#FFFFFF" />
      </SearchInputIconContainer>
    </SearchInputWrapper>
  );
}
