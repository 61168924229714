import React from 'react';

export default function SlackColorIcon({ width = 18, height = 18 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_40507_154151)">
        <path
          d="M7.11409 4.89581C6.72332 4.89443 6.34897 4.73853 6.07272 4.46215C5.79648 4.18576 5.64077 3.81133 5.63959 3.42056C5.63959 2.60831 6.30259 1.94531 7.11484 1.94531C7.92709 1.94531 8.59083 2.60831 8.59083 3.42056V4.89656L7.11409 4.89581ZM7.11409 5.63981C7.92634 5.63981 8.59009 6.30281 8.59009 7.11506C8.5891 7.50622 8.43327 7.88107 8.15668 8.15766C7.88009 8.43425 7.50524 8.59007 7.11409 8.59106H3.41959C3.02856 8.58987 2.6539 8.43396 2.37747 8.15739C2.10105 7.88083 1.94533 7.50609 1.94434 7.11506C1.94434 6.30281 2.60734 5.63981 3.41959 5.63981H7.11484H7.11409Z"
          fill="#36C5F0"
        />
        <path
          d="M14.5804 5.63981C13.7682 5.63981 13.1044 6.30281 13.1044 7.11506V8.59106H14.5804C14.9715 8.58987 15.3461 8.43396 15.6225 8.15739C15.899 7.88083 16.0547 7.50609 16.0557 7.11506C16.0557 6.30281 15.3927 5.63981 14.5804 5.63981Z"
          fill="#2EB67D"
        />
        <path
          d="M11.9273 8.15739C12.2037 7.88083 12.3594 7.50609 12.3604 7.11506V3.42056C12.3604 2.60831 11.6974 1.94531 10.8852 1.94531C10.0729 1.94531 9.40918 2.60831 9.40918 3.42056V7.11506C9.41017 7.50622 9.566 7.88107 9.84258 8.15766C10.1192 8.43425 10.494 8.59007 10.8852 8.59106C11.2762 8.58987 11.6509 8.43396 11.9273 8.15739Z"
          fill="#2EB67D"
        />
        <path
          d="M12.3604 14.5814C12.3604 13.7692 11.6974 13.1054 10.8852 13.1054H9.40918V14.5814C9.41037 14.9724 9.56628 15.3471 9.84285 15.6235C10.1194 15.8999 10.4942 16.0557 10.8852 16.0567C11.2761 16.0555 11.6506 15.8997 11.927 15.6233C12.2034 15.3468 12.3592 14.9723 12.3604 14.5814Z"
          fill="#ECB22E"
        />
        <path
          d="M9.84285 11.9283C10.1194 12.2047 10.4942 12.3604 10.8852 12.3614H14.5804C14.9713 12.3602 15.3459 12.2044 15.6223 11.928C15.8987 11.6516 16.0545 11.2771 16.0557 10.8862C16.0557 10.0739 15.3927 9.41016 14.5804 9.41016H10.8852C10.0729 9.41016 9.40918 10.0739 9.40918 10.8862C9.41037 11.2772 9.56628 11.6518 9.84285 11.9283Z"
          fill="#ECB22E"
        />
        <path
          d="M4.46117 11.9263C4.73755 11.6501 4.89345 11.2757 4.89484 10.885L4.89559 10.8842V9.4082H3.41959C2.60734 9.4082 1.94434 10.072 1.94434 10.8842C1.94552 11.2751 2.10133 11.6496 2.37774 11.9261C2.65414 12.2025 3.02869 12.3583 3.41959 12.3595C3.81035 12.3583 4.18479 12.2026 4.46117 11.9263Z"
          fill="#E01E5A"
        />
        <path
          d="M7.11409 9.40895C6.30184 9.40895 5.63884 10.0727 5.63884 10.885V14.5802C5.64002 14.9711 5.79583 15.3456 6.07224 15.6221C6.34864 15.8985 6.72319 16.0543 7.11409 16.0555C7.50511 16.0545 7.87985 15.8987 8.15642 15.6223C8.43299 15.3459 8.5889 14.9712 8.59009 14.5802V10.885C8.59009 10.0727 7.92634 9.40895 7.11409 9.40895Z"
          fill="#E01E5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_40507_154151">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
