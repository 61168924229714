import { PEClient, http } from './http';

// GENERAL
export const getAboutYou = () => {
  return PEClient.get('about_you');
};

export interface IAboutYouParams {
  preferred_name: string;
  pronunciation: string;
  pronouns: string;
}

export const postAboutYou = (params: IAboutYouParams) => {
  return PEClient.post('about_you', params);
};

export const getLinkedin = () => {
  return PEClient.get<{ linkedin: string }>('linkedin');
};

export const postLinkedin = (params: { value: string }) => {
  return PEClient.post('linkedin', params);
};

export const getSpotify = () => {
  return PEClient.get<{ spotify: string }>('spotify');
};

export const postSpotify = (params: { value: string }) => {
  return PEClient.post('spotify', params);
};

export const postAvatar = (params: { value: string }) => {
  return PEClient.post('avatar', params);
};

export const sendProfileInfoEmail = (params: {}) => {
  return http.post('/mailer/profile_info', params);
};

export const sendNoProjectsEmail = (params: {}) => {
  return http.post('/mailer/no_projects', params);
};

// HUMAN
export interface ICollectionParams {
  collection: string[];
}

export const getCountries = () => {
  return PEClient.get<{ all: any[]; countries: any[] }>('countries');
};

export const postCountries = (params: ICollectionParams) => {
  return PEClient.post('countries', params);
};

export const getLanguages = () => {
  return PEClient.get<{ languages: any[] }>('languages');
};

export const postLanguages = (params: ICollectionParams) => {
  return PEClient.post('languages', params);
};

export const getPassions = () => {
  return PEClient.get<{ passions: any[] }>('passions');
};

export const postPassions = (params: ICollectionParams) => {
  return PEClient.post('passions', params);
};

export const createPassion = (params: { name: string }) => {
  return PEClient.post<{ uuid: string; name: string }>('new_passion', params);
};

export const postPassionDescription = (params: { uuid: string; description: string }) => {
  return PEClient.post('passion_description', params);
};

export const getBio = () => {
  return PEClient.get<{ bio: string }>('bio');
};

export const postBio = (params: { value: string }) => {
  return PEClient.post('bio', params);
};

export const getOutsideWork = () => {
  return PEClient.get<{ outside_work: string; inspo: any[] }>('outside_work');
};

export const postOutsideWork = (params: { value: string }) => {
  return PEClient.post('outside_work', params);
};

//PROFESSIONAL
export const getYearsExperience = () => {
  return PEClient.get<{ date_for_work_experience_calc: string }>('years_experience');
};

export const postYearsExperience = (params: { value: string }) => {
  return PEClient.post('years_experience', params);
};

export const getDreamProject = () => {
  return PEClient.get<{ dream_project: string }>('dream_project');
};

export const postDreamProject = (params: { value: string }) => {
  return PEClient.post('dream_project', params);
};

export const getRoleDescription = () => {
  return PEClient.get<{ role_description: string }>('role_description');
};

export const postRoleDescription = (params: { value: string }) => {
  return PEClient.post('role_description', params);
};

export const getCompanies = () => {
  return PEClient.get<{ companies: any[] }>('companies');
};

export const postCompanies = (params: ICollectionParams) => {
  return PEClient.post('companies', params);
};

export const createCompany = (params: { name: string; description: string; url: string }) => {
  return PEClient.post('new_company', params);
};

export const getClients = () => {
  return PEClient.get<{ clients: any[] }>('clients');
};

export const postClients = (params: ICollectionParams) => {
  return PEClient.post('clients', params);
};

export const createClient = (params: { name: string; description: string; url: string }) => {
  return PEClient.post('new_client', params);
};

export const getIndustries = () => {
  return PEClient.get<{ industries: any[] }>('industries');
};

export const postIndustries = (params: ICollectionParams) => {
  return PEClient.post('industries', params);
};

export const createIndustry = (params: { name: string }) => {
  return PEClient.post('new_industry', params);
};

export const getSkills = () => {
  return PEClient.get<{ skills: any[]; all: any[] }>('skills');
};

export const postSkills = (params: ICollectionParams) => {
  return PEClient.post('skills', params);
};

export const createSkill = (params: { name: string }) => {
  return PEClient.post('new_skill', params);
};

// general
export const removeAssociation = (params: { type: string; slug: string }) => {
  return PEClient.post('remove_association', params);
};
