import * as React from 'react';

const CloseIcon = ({ width = 12, height = 12, fill = '#ADADAD', fillOpacity = '0.5' }) => {
  return (
    <svg
      className="close-icon"
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.42706 0.461563C1.16823 0.205658 0.751727 0.205501 0.492708 0.461211C0.229216 0.721336 0.229056 1.14662 0.492352 1.40694L4.12078 4.99441L0.478794 8.59256C0.215402 8.85279 0.215402 9.27806 0.478795 9.53829C0.737716 9.79409 1.15422 9.79409 1.41314 9.53829L5.06622 5.92917L8.49931 9.32351C8.75814 9.57941 9.17464 9.57957 9.43366 9.32386C9.69715 9.06374 9.69731 8.63846 9.43402 8.37813L6.01201 4.99476L9.52155 1.52745C9.78495 1.26723 9.78495 0.841949 9.52155 0.581726C9.26263 0.32592 8.84613 0.325919 8.58721 0.581725L5.06657 4.05999L1.42706 0.461563Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export default CloseIcon;
