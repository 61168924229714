import React from 'react';

import { ForwardRefComponent, HTMLMotionProps, motion } from 'framer-motion';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const OnboardingStepSixWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 108px 226px 49px 226px;

  ${BREAKPOINTS.mobile} {
    padding: 36px 24px 32px 24px;
  }
`;

export const OnboardingStepSixTitle = styled.h2`
  font-size: 38px;
  line-height: 42px;
  margin-bottom: 16px;

  ${BREAKPOINTS.mobile} {
    text-align: center;
    font-size: 32px;
  }
`;

export const OnboardingStepSixSubtitle = styled.p`
  font-size: 18px;
  margin-bottom: 16px;
  line-height: normal;
  text-align: center;

  ${BREAKPOINTS.mobile} {
    text-align: center;
    font-size: 16px;
  }
`;

export const OnboardingStepSixPeopleContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-bottom: 42px;

  & > :not(:first-child) {
    margin-right: -8px;
  }

  & > :first-child {
    margin-left: 2px;
  }
`;

export const OnboardingStepSixPeopleCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  border: 2px solid #ffc229;
  font-size: 16px;
  height: 32px;
  min-width: 32px;
  padding: 3px;
`;

export const OnboardingStepSixProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const OnboardingStepSixProgressBarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 64px;
  border-radius: 48px;
  z-index: 1;
`;

interface OnboardingStepSixProgressBarProps {
  percentage: number;
  width: number;
}

export const OnboardingStepSixProgressBar = styled(motion.div).attrs(
  (): HTMLMotionProps<'div'> => ({
    variants: {
      final: {
        width: '100%',
        transition: {
          duration: 1,
          ease: 'easeInOut',
          bounce: 0,
        },
      },
    },
  })
)<OnboardingStepSixProgressBarProps>`
  width: ${(props) => props.width}%;
  height: 100%;
  max-width: ${(props) => props.percentage}%;
  background: rgba(156, 202, 255, 0.4);
  z-index: 1;
`;

export const OnboardingStepSixProgressChart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 355px;
  height: 64px;
  border-radius: 48px;
  border: 2px solid #9ccaff;
  background: rgba(255, 255, 255, 0.2);
  padding: 8px 24px 8px 7px;
  z-index: 2;
`;

export const OnboardingStepSixProgressTotalPeopleContainer = styled.div`
  display: flex;
  font-size: 24px;
  span {
    color: rgba(23, 28, 51, 0.5);
    margin-left: 8px;
  }
`;

export const OnboardingStepSixProgressOSPeopleContainer = styled.div`
  display: flex;
  font-size: 24px;
  span {
    color: rgba(23, 28, 51, 0.5);
    margin-left: 8px;
  }
`;

export const OnboardingStepSixProgressPercentageContainer = styled.div`
  diaplay: flex;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
`;
export const OnboardingStepSixThatsYouContainer = styled(motion.div).attrs(
  (): HTMLMotionProps<'div'> => ({
    variants: {
      final: {
        marginRight: '-190px',
        transition: {
          duration: 1,
          ease: 'easeInOut',
          bounce: 0,
        },
      },
    },
  })
)`
  display: flex;
  flex-direction: row;
  margin-top: -13px;
`;

export const OnboardingStepSixThatsYouArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

export const OnboardingStepSixThatsYouTextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 18px;
  color: rgba(23, 28, 51, 0.5);
  line-height: normal;
  ${BREAKPOINTS.mobile} {
    font-size: 16px;
    margin-left: -10px;
  }
`;

export const OnboardingStepSixCongratsContainer = styled.div`
  display: flex;
  width: 500px;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 100px;
  line-height: normal;

  ${BREAKPOINTS.mobile} {
    width: 320px;
    font-size: 16px;
    margin-top: 125px;
  }
`;

export const OnboardingStepSixChartPendingArrowContainer = styled.div`
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  top: 55px;
  left: -30px;
  z-index: 2;

  p {
    margin-top: 40px;
    line-height: normal;
    color: rgba(23, 28, 51, 0.5);
    max-width: 130px;
  }

  ${BREAKPOINTS.mobile} {
    top: 45px;
    font-size: 16px;
    left: 30px;
    svg {
      margin-left: -60px;
    }
  }
`;

export const OnboardingStepSixChartOSArrowContainer = styled.div`
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  top: 58px;
  right: -20px;
  z-index: 2;
  color: rgba(23, 28, 51, 0.5);

  p {
    line-height: normal;
    margin-left: -25px;
    margin-top: 55px;
    max-width: 115px;
    color: rgba(23, 28, 51, 0.5);
  }

  span {
    color: #171c33;
  }

  ${BREAKPOINTS.mobile} {
    font-size: 16px;
    right: 0px;
  }
`;
