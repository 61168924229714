import { logoutService } from "services/app";
import { clearToken } from "services/storage";

export const handleSignOut = async () => {
  const logoutResponse = await logoutService();
  if (logoutResponse.ok) {
    clearToken();
    window.location.href = "/";
  }
};
