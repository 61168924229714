import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  CompanyIcon,
  ProjectIcon,
  SkillIcon,
} from 'app/components/DetailsPages/shared/CommonGround/Icons';
import PersonIcon from 'app/components/shared/icons/person-icon';

import GlobalDataTypeDrawer from '../GlobalDataType/GlobalDataTypeDrawer';
import useKyuSkillDrawerData from '../hooks/useKyuSkillDrawerData';
import {
  GlobalSkillDrawerCounter,
  GlobalSkillDrawerCounterContainer,
  GlobalSkillDrawerCounterIconContainer,
  GlobalSkillDrawerCountersWrapper,
  GlobalSkillDrawerCounterText,
  GlobalSkillDrawerHighlightText,
  GlobalSkillDrawerHighlightTextContainer,
} from './styled';

interface GlobalSkillDrawerProps {
  slug: string;
  comingFromList?: boolean;
  backToListAction?: () => void;
  fromSkill?: boolean;
}

export default function GlobalSkillDrawer({
  slug,
  comingFromList = false,
  backToListAction = () => {},
}: GlobalSkillDrawerProps) {
  let query = null;

  query = useKyuSkillDrawerData(slug);

  const { isLoading, isError, data } = query;

  const { push } = useHistory();

  return (
    <GlobalDataTypeDrawer
      slug={slug}
      isLoading={isLoading}
      background={
        'linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0.01%, rgba(235, 130, 61, 0.3) 99.98%, rgba(255, 163, 102, 0.3) 99.99%), #FFFFFF'
      }
      dataType={'skill'}
      title={data?.name || ''}
      includesCurrentPerson={data?.includes_current_person || ''}
      icon={<SkillIcon fill={'#EB823D'} />}
      catchphraseHighlightColor={'#EB823D'}
      iconBackground={'#FFA366'}
      buttonOnClick={() => push(`/skill/${slug}`)}
      buttonBackgroundColor={'#EB823D'}
      peopleAtCompany={data?.people_at_company || null}
      comingFromList={comingFromList}
      backToListAction={() => backToListAction()}
      isError={isError}
      query={query}
    >
      {data?.highlight && (
        <GlobalSkillDrawerHighlightTextContainer>
          <GlobalSkillDrawerHighlightText>
            <span className="people">
              {data?.highlight.people_count != 1 ? (
                <>{data?.highlight.people_count} people</>
              ) : (
                <>{data?.highlight.people_sample}</>
              )}
            </span>
            {' from '}
            <span className="company">
              {data?.highlight.company_count != 1 ? (
                <>{data?.highlight.company_count} companies</>
              ) : (
                <>{data?.highlight.company_names[0]}</>
              )}
            </span>{' '}
            {data?.highlight.people_count != 1 ? (
              <>added this skill group to their profiles.</>
            ) : (
              <>shares this skill group.</>
            )}
          </GlobalSkillDrawerHighlightText>
          {data?.highlight.people_count > 1 && (
            <GlobalSkillDrawerHighlightText>
              Noticeably, this skill group is popular with people that work at{' '}
              {data?.highlight.company_names.map((companyName, index) => (
                <React.Fragment key={index}>
                  <span className="company">{companyName}</span>
                  {index !== data?.highlight.company_names.length - 1
                    ? index === data?.highlight.company_names.length - 2
                      ? ' and '
                      : ', '
                    : ''}
                </React.Fragment>
              ))}
              {'.'}
            </GlobalSkillDrawerHighlightText>
          )}
          {data?.highlight.related_skill_count > 1 && (
            <GlobalSkillDrawerHighlightText>
              Specifically within {data?.name}, there{' '}
              {data?.highlight.related_skills_count != 1 ? 'are' : 'is'}{' '}
              <span className="skill">
                {data?.highlight.related_skills_count} related{' '}
                {data?.highlight.related_skills_count != 1 ? 'skills' : 'skill'}
              </span>{' '}
              from the different companies.
            </GlobalSkillDrawerHighlightText>
          )}
        </GlobalSkillDrawerHighlightTextContainer>
      )}
      <GlobalSkillDrawerCountersWrapper>
        {data?.counters.projects && (
          <GlobalSkillDrawerCounterContainer>
            <GlobalSkillDrawerCounterIconContainer>
              <ProjectIcon width={24} height={24} fill={'#392B62'} />
              <GlobalSkillDrawerCounter>{data.counters.projects}</GlobalSkillDrawerCounter>
            </GlobalSkillDrawerCounterIconContainer>
            <GlobalSkillDrawerCounterText>
              {data.counters.projects > 1 ? 'projects' : 'project'}
            </GlobalSkillDrawerCounterText>
          </GlobalSkillDrawerCounterContainer>
        )}
        {data?.counters.companies && (
          <GlobalSkillDrawerCounterContainer>
            <GlobalSkillDrawerCounterIconContainer>
              <CompanyIcon width={24} height={24} />
              <GlobalSkillDrawerCounter>{data.counters.companies}</GlobalSkillDrawerCounter>
            </GlobalSkillDrawerCounterIconContainer>
            <GlobalSkillDrawerCounterText>
              {data.counters.companies > 1 ? 'companies' : 'company'}
            </GlobalSkillDrawerCounterText>
          </GlobalSkillDrawerCounterContainer>
        )}
        {data?.counters.people && (
          <GlobalSkillDrawerCounterContainer>
            <GlobalSkillDrawerCounterIconContainer>
              <PersonIcon width={24} height={24} fill={'#A26565'} />
              <GlobalSkillDrawerCounter>{data.counters.people}</GlobalSkillDrawerCounter>
            </GlobalSkillDrawerCounterIconContainer>
            <GlobalSkillDrawerCounterText>
              {data.counters.people > 1 ? 'people' : 'person'}
            </GlobalSkillDrawerCounterText>
          </GlobalSkillDrawerCounterContainer>
        )}
      </GlobalSkillDrawerCountersWrapper>
    </GlobalDataTypeDrawer>
  );
}
