import React from 'react';

export default function DeltaIcon({ width = 8, height = 7, fill = '#8DA76C' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.01828 0.423444C3.41668 -0.141148 4.25399 -0.141148 4.65239 0.423443L7.48598 4.43901C7.95342 5.10144 7.47967 6.01556 6.66892 6.01556H1.00175C0.191001 6.01556 -0.282751 5.10144 0.184692 4.43901L3.01828 0.423444Z"
        fill={fill}
      />
    </svg>
  );
}
