import React from 'react';

import { KyuTeam } from 'app/components/Onboarding/steps/Two';

import useKyuExecutiveCommittee from '../../hooks/useKyuExecutiveCommittee';
import { AboutKyuOsIntroductionKyuTeamDescription } from '../styled';
import { AboutKyuOsExecutiveCommitteeWrapper } from './styled';

export default function AboutKyuOsExecutiveCommittee() {
  const query = useKyuExecutiveCommittee();
  return (
    <AboutKyuOsExecutiveCommitteeWrapper>
      <AboutKyuOsIntroductionKyuTeamDescription>
        This is our kyu Executive Committee. It's made up of leaders throughout the kyu Collective.
      </AboutKyuOsIntroductionKyuTeamDescription>
      <KyuTeam query={query} identifier="Our executive committee" />
    </AboutKyuOsExecutiveCommitteeWrapper>
  );
}
