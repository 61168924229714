import styled from 'styled-components';

export const HomeRadarActiveWorkTotalsContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 24px 16px 16px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  border-radius: 8px;
  background: #ffffff;
`;

export const HomeRadarActiveWorkFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const HomeRadarActiveWorkTotalsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HomeRadarActiveWorkTotalsTitle = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: var(--color-text);
`;

export const HomeRadarActiveWorkTotalsTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 4px;
  }

  & > svg {
    margin-top: 3px;
  }
`;

export const HomeRadarActiveWorkTotalsTime = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  color: var(--color-text);
  opacity: 0.5;
`;

export const HomeRadarActiveWorkTotalsIndustriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  opacity: 0.5;
`;

export const HomeRadarActiveWorkTotalsIndustries = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: normal;
  leading-trim: both;
  text-edge: cap;
  color: var(--color-data-industry-foreground);
`;

export const HomeRadarActiveWorkNewIndustries = styled.span`
  font-weight: 400;
  font-size: 38px;
  line-height: 42px;
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  color: var(--color-data-industry-foreground);
`;

export const HomeRadarActiveWorkIndustriesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const HomeRadarActiveWorkIndustriesList = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  max-height: 350px;
  overflow-y: auto;
`;

export const HomeRadarActiveWorkIndustryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 8px;
  border-radius: 8px;
  background: #ffffff;

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  & > :not(:last-child) {
    margin-right: 8px;
  }

  cursor: pointer;
`;

export const HomeRadarActiveWorkIndustryLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 22px;
  min-height: 22px;
  border-radius: 100%;

  background: var(--color-data-industry-background);
`;

export const HomeRadarActiveWorkIndustryTextContainer = styled.div`
  display: inline-flex;
`;

interface HomeRadarActiveWorkIndustryTextProps {
  highlighted?: boolean;
}

export const HomeRadarActiveWorkIndustryText = styled.span<HomeRadarActiveWorkIndustryTextProps>`
  font-weight: ${({ highlighted }) => (highlighted ? 700 : 400)};
  font-size: 14px;
  line-height: normal;
  color: var(--color-text);
`;

export const HomeRadarActiveWorkAllIndustriesButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px;
  border-radius: 8px;
  background: var(--color-data-industry-background);

  &:hover {
    background: var(--color-data-industry-background-hover);
  }
`;

export const HomeRadarActiveWorkAllIndustriesButtonText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: var(--color-text);
`;

// SKELETON
export const HomeRadarActiveWorkSkeletonList = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const HomeRadarActiveWorkSkeletonItem = styled.div`
  width: 100%;
  height: 38px;
  border-radius: 8px;
  background: #f0f0f0;
`;
