import React, { useState } from 'react';

import { CollapsibleClosedIcon, CollapsibleOpenedIcon } from 'app/components/shared/icons';

import {
  CollapsibleContentContainer,
  CollapsibleIconContainer,
  CollapsibleTitle,
  CollapsibleTopContainer,
  CollapsibleWrapper,
} from './styled';

interface CollapsibleProps {
  title: string;
  children: React.ReactNode;
  behavioralOnExpand?: (title?: string) => void;
}

export default function Collapsible({ title, children, behavioralOnExpand }: CollapsibleProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    if (!isOpen) {
      behavioralOnExpand?.(title);
    }

    setIsOpen(!isOpen);
  };

  return (
    <CollapsibleWrapper onClick={handleClick}>
      <CollapsibleTopContainer isOpen={isOpen}>
        <CollapsibleTitle>{title}</CollapsibleTitle>
        <CollapsibleIconContainer className="icon-container">
          {isOpen ? (
            <CollapsibleOpenedIcon width={42} height={42} fill="var(--color-primary)" />
          ) : (
            <CollapsibleClosedIcon width={42} height={42} fill="var(--color-primary)" />
          )}
        </CollapsibleIconContainer>
      </CollapsibleTopContainer>
      <CollapsibleContentContainer isOpen={isOpen}>{children}</CollapsibleContentContainer>
    </CollapsibleWrapper>
  );
}
