import React from 'react';

export default function KyuLogoIcon({ width = 51, height = 16, fill = '#171C33' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 51 16"
      fill="none"
    >
      <path
        d="M0 11.52V0H1.344V6.928L4.592 3.584H6.24L2.72 7.168L6.688 11.52H4.976L1.344 7.408V11.52H0Z"
        fill={fill}
      />
      <path
        d="M8.9265 15.04L10.8945 10.752H10.4305L7.2785 3.584H8.7345L11.3585 9.76L14.1425 3.584H15.5345L10.3345 15.04H8.9265Z"
        fill={fill}
      />
      <path
        d="M19.8491 11.712C18.9105 11.712 18.1638 11.4293 17.6091 10.864C17.0545 10.288 16.7771 9.408 16.7771 8.224V3.584H18.1211V8.08C18.1211 9.73333 18.7985 10.56 20.1531 10.56C20.8465 10.56 21.4171 10.3147 21.8651 9.824C22.3238 9.32267 22.5531 8.61333 22.5531 7.696V3.584H23.8971V11.52H22.6811L22.5851 10.096C22.3398 10.5973 21.9718 10.992 21.4811 11.28C21.0011 11.568 20.4571 11.712 19.8491 11.712Z"
        fill={fill}
      />
      <path
        d="M35.5757 11.712C34.4557 11.712 33.4744 11.4667 32.6317 10.976C31.7997 10.4853 31.1437 9.808 30.6637 8.944C30.1944 8.06933 29.9597 7.06133 29.9597 5.92C29.9597 4.77867 30.1944 3.776 30.6637 2.912C31.1437 2.03733 31.7997 1.35467 32.6317 0.864C33.4744 0.373334 34.4557 0.128 35.5757 0.128C36.6851 0.128 37.6611 0.373334 38.5037 0.864C39.3464 1.35467 40.0024 2.03733 40.4718 2.912C40.9411 3.776 41.1758 4.77867 41.1758 5.92C41.1758 7.06133 40.9411 8.06933 40.4718 8.944C40.0024 9.808 39.3464 10.4853 38.5037 10.976C37.6611 11.4667 36.6851 11.712 35.5757 11.712ZM35.5757 9.872C36.6424 9.872 37.4904 9.52 38.1198 8.816C38.7598 8.112 39.0798 7.14667 39.0798 5.92C39.0798 4.69333 38.7598 3.728 38.1198 3.024C37.4904 2.32 36.6424 1.968 35.5757 1.968C34.5091 1.968 33.6558 2.32 33.0158 3.024C32.3758 3.728 32.0558 4.69333 32.0558 5.92C32.0558 7.14667 32.3758 8.112 33.0158 8.816C33.6558 9.52 34.5091 9.872 35.5757 9.872Z"
        fill={fill}
      />
      <path
        d="M46.808 11.712C45.9867 11.712 45.2613 11.5733 44.632 11.296C44.0027 11.008 43.5067 10.6027 43.144 10.08C42.7813 9.54667 42.5947 8.90133 42.584 8.144H44.744C44.7653 8.66667 44.952 9.10933 45.304 9.472C45.6667 9.824 46.1627 10 46.792 10C47.336 10 47.768 9.872 48.088 9.616C48.408 9.34933 48.568 8.99733 48.568 8.56C48.568 8.10133 48.424 7.744 48.136 7.488C47.8587 7.232 47.4853 7.024 47.016 6.864C46.5467 6.704 46.0453 6.53333 45.512 6.352C44.648 6.05333 43.9867 5.66933 43.528 5.2C43.08 4.73067 42.856 4.10667 42.856 3.328C42.8453 2.66667 43 2.10133 43.32 1.632C43.6507 1.152 44.0987 0.784 44.664 0.528C45.2293 0.261333 45.88 0.128 46.616 0.128C47.3627 0.128 48.0187 0.261333 48.584 0.528C49.16 0.794666 49.608 1.168 49.928 1.648C50.2587 2.128 50.4347 2.69867 50.456 3.36H48.264C48.2533 2.96533 48.0987 2.61867 47.8 2.32C47.512 2.01067 47.1067 1.856 46.584 1.856C46.136 1.84533 45.7573 1.95733 45.448 2.192C45.1493 2.416 45 2.74667 45 3.184C45 3.55733 45.1173 3.856 45.352 4.08C45.5867 4.29333 45.9067 4.47467 46.312 4.624C46.7173 4.77333 47.1813 4.93333 47.704 5.104C48.2587 5.296 48.7653 5.52 49.224 5.776C49.6827 6.032 50.0507 6.37333 50.328 6.8C50.6053 7.216 50.744 7.75467 50.744 8.416C50.744 9.00267 50.5947 9.54667 50.296 10.048C49.9973 10.5493 49.5547 10.9547 48.968 11.264C48.3813 11.5627 47.6613 11.712 46.808 11.712Z"
        fill={fill}
      />
    </svg>
  );
}
