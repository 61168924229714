import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  profileDataSelector,
  setRoleDescription,
} from 'store/reducers/profile-editor/profile-editor-reducer';

import { useRoleDescription } from './useRoleDescription';

export default function useRoleDescriptionDrawer() {
  const dispatch = useDispatch();
  const { role_description } = useSelector(profileDataSelector);
  const { isLoading, data } = useRoleDescription();

  useEffect(() => {
    if (data) {
      dispatch(setRoleDescription(data.role_description));
    }
  }, [data]);

  return {
    role_description,
    isLoading,
  };
}
