import { useState, useEffect } from 'react';

type SectionId = string;

export default function useCurrentSection(sectionIds: SectionId[]): SectionId | null {
  const [currentSection, setCurrentSection] = useState<SectionId | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = sectionIds.map((id) => document.getElementById(id)!);
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const current = sections.findIndex((section) => {
        const top = section?.offsetTop;
        const height = section?.offsetHeight;
        return scrollTop >= top && scrollTop < top + height / 2;
      });
      const documentHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const topPosition = scrollTop === 0;
      const bottomPosition = scrollTop + windowHeight === documentHeight;
      const newSection = topPosition
        ? sectionIds[0]
        : bottomPosition
        ? sectionIds[sectionIds.length - 1]
        : current >= 0
        ? sectionIds[current]
        : null;

      if (newSection !== null) {
        setCurrentSection(newSection);
      }
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [sectionIds]);

  return currentSection;
}
