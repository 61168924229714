import React from 'react';

import { EditorialP } from '../../styled';
import { PublicationDefaultDescription } from './styled';

interface PublicationDescriptionProps {
  type?: string;
  description: string;
  fontSize?: number;
  lineHeight?: string;
}

export default function PublicationDescription({
  type,
  description,
  fontSize,
  lineHeight,
}: PublicationDescriptionProps) {
  const typeToButton = new Map<string, JSX.Element>([
    [
      'resources',
      <ResourcePublicationDescription
        key={1}
        description={description}
        fontSize={fontSize}
        lineHeight={lineHeight}
      />,
    ],
    [
      'convenings',
      <ResourcePublicationDescription
        key={2}
        description={description}
        fontSize={fontSize}
        lineHeight={lineHeight}
      />,
    ],
  ]);

  if (type && typeToButton.has(type)) return typeToButton.get(type);

  return (
    <DefaultPublicationDescription
      description={description}
      fontSize={fontSize}
      lineHeight={lineHeight}
    />
  );
}

function DefaultPublicationDescription({
  description,
  fontSize,
  lineHeight,
}: PublicationDescriptionProps) {
  return <PublicationDefaultDescription>{description}</PublicationDefaultDescription>;
}

function ResourcePublicationDescription({
  description,
  fontSize,
  lineHeight,
}: PublicationDescriptionProps) {
  return <PublicationDefaultDescription dangerouslySetInnerHTML={{ __html: description }} />;
}
