import React from 'react';

export default function PromptIcon({ width = 25, height = 25, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.9368 5.32769L6.11176 1L5.28673 5.32769L1.5 6.27059L5.28673 7.21349L6.11176 11.5412L6.9368 7.21349L10.7235 6.27059L6.9368 5.32769ZM16.9 3.14102C15.3536 3.14102 14.1 4.39462 14.1 5.94102V8.74102C14.1 10.2874 15.3536 11.541 16.9 11.541H19.7C21.2464 11.541 22.5 10.2874 22.5 8.74102V5.94102C22.5 4.39462 21.2464 3.14102 19.7 3.14102H16.9ZM16.9 14.341C15.3536 14.341 14.1 15.5946 14.1 17.141V19.941C14.1 21.4874 15.3536 22.741 16.9 22.741H19.7C21.2464 22.741 22.5 21.4874 22.5 19.941V17.141C22.5 15.5946 21.2464 14.341 19.7 14.341H16.9ZM2.9 17.141C2.9 15.5946 4.1536 14.341 5.7 14.341H8.5C10.0464 14.341 11.3 15.5946 11.3 17.141V19.941C11.3 21.4874 10.0464 22.741 8.5 22.741H5.7C4.1536 22.741 2.9 21.4874 2.9 19.941V17.141Z"
        fill={fill}
      />
    </svg>
  );
}
