import { CollectiveCompaniesWrapper } from 'app/components/CommonStyled/CollectiveCompanies/styled';
import {
  OnboardingStepTwoKyuTeamContainer,
  OnboardingStepTwoKyuTeamWrapper,
} from 'app/components/Onboarding/steps/Two/styled';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsIntroductionWrapper = styled.section`
  max-width: 970px;

  display: flex;
  flex-direction: column;

  gap: 32px;

  ${CollectiveCompaniesWrapper} {
    margin-bottom: 45px;
  }

  ${BREAKPOINTS.tablet} {
    overflow-x: hidden;
  }
`;

export const AboutKyuOsIntroductionText = styled.h3`
  font-weight: 400;
  font-size: 24px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    font-size: 20px;
  }

  color: var(--color-text);
`;

export const AboutKyuOsIntroductionKyuTeamContainer = styled.div`
  width: calc(100% + 52px);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transform: translateX(-52px);
  padding: 48px 0px;

  ${OnboardingStepTwoKyuTeamWrapper} {
    width: 100%;
    max-width: 510px;
    max-height: unset;
  }

  ${OnboardingStepTwoKyuTeamContainer} {
    max-height: unset;

    &::-webkit-scrollbar {
      display: none;
    }

    ${BREAKPOINTS.tablet} {
      max-height: unset;
    }
  }

  ${BREAKPOINTS.tablet} {
    width: 100%;
    flex-direction: column-reverse;
    gap: 32px;
    transform: unset;
  }
`;

export const AboutKyuOsIntroductionKyuTeamDescription = styled.span`
  max-width: 398px;

  font-weight: 400;
  font-size: 24px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    font-size: 20px;
    max-width: unset;
  }

  color: var(--color-text);
`;
