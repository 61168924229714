import { createSlice } from '@reduxjs/toolkit';
import { OnboardingCounters } from 'interfaces';

type OnboardingThemes = 'light' | 'dark';

export const slice = createSlice({
  name: 'onboarding',
  initialState: {
    counters: undefined,
    currentScroll: 0,
    currentStep: 1,
    isOnboardingOpen: false,
    seenFinalStep: false,
    triggerButtonAnimation: false,
    triggerOnboarding: false,
    onboardingTheme: 'dark',
    isAboutKyuOsOpen: false,
    isAboutKyuOsHidden: true,
  },
  reducers: {
    setAboutKyuOsOpen: (state, action: { payload: boolean }) => ({
      ...state,
      isAboutKyuOsOpen: action.payload,
    }),
    setAboutKyuOsHidden: (state, action: { payload: boolean }) => {
      return {
        ...state,
        isAboutKyuOsHidden: action.payload,
      };
    },
    setisOnboardingOpen: (state, action: { payload: boolean }) => ({
      ...state,
      isOnboardingOpen: action.payload,
    }),
    setOnboardingCounters: (state, action: { payload: OnboardingCounters }) => ({
      ...state,
      counters: action.payload,
    }),
    setOnboardingScroll: (state, action: { payload: number }) => ({
      ...state,
      currentScroll: action.payload,
    }),
    setOnboardingStep: (state, action: { payload: number }) => ({
      ...state,
      currentStep: action.payload,
    }),
    setSeenFinalStep: (state, action: { payload: boolean }) => ({
      ...state,
      seenFinalStep: action.payload,
    }),
    setTriggerOnboarding: (state, action: { payload: boolean }) => ({
      ...state,
      triggerOnboarding: action.payload,
    }),
    setTriggerButtonAnimation: (state, action: { payload: boolean }) => ({
      ...state,
      triggerButtonAnimation: action.payload,
    }),
    setOnboardingTheme: (state, action: { payload: OnboardingThemes }) => ({
      ...state,
      onboardingTheme: action.payload,
    }),
    setNextStep: (state, action: { payload: number }) => ({
      ...state,
      currentStep: state.currentStep + 1,
    }),
  },
});

export const {
  setisOnboardingOpen,
  setOnboardingCounters,
  setOnboardingScroll,
  setOnboardingStep,
  setSeenFinalStep,
  setTriggerOnboarding,
  setTriggerButtonAnimation,
  setOnboardingTheme,
  setNextStep,
  setAboutKyuOsOpen,
  setAboutKyuOsHidden,
} = slice.actions;

export const onboardingCountersSelector = ({ onboarding }) => onboarding.counters;
export const isOnboardingOpenSelector = ({ onboarding }) => onboarding.isOnboardingOpen;
export const onboardingScrollSelector = ({ onboarding }) => onboarding.currentScroll;
export const onboardingSeenFinalStep = ({ onboarding }) => onboarding.seenFinalStep;
export const onboardingStepSelector = ({ onboarding }) => onboarding.currentStep;
export const onboardingTriggerSelector = ({ onboarding }) => onboarding.triggerOnboarding;
export const onboardingButtonAnimation = ({ onboarding }) => onboarding.triggerButtonAnimation;
export const onboardingThemeSelector = ({ onboarding }) => onboarding.onboardingTheme;
export const aboutKyuOsOpenSelector = ({ onboarding }) => onboarding.isAboutKyuOsOpen;
export const aboutKyuOsHiddenSelector = ({ onboarding }) => onboarding.isAboutKyuOsHidden;

export default slice.reducer;
