import styled from "styled-components";
import { BREAKPOINTS } from "styles/media";

export const ScreenSizeAlertOverlay = styled.main`
  background: rgba(23, 28, 51, 0.99);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(23, 28, 51, 0.85);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
  }
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999999999;
`;

export const ScreenSizeAlertContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 600px;
  min-height: 300px;
  padding: 20px;
  text-align: center;
  width: 100%;
  > h1 {
    font-size: 64px;
    line-height: 83px;
    margin-bottom: 15px;
  }
  > p {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 15px;
    > b {
      font-weight: bold;
    }
  }
  > h1,
  p,
  p > b {
    color: #fff;
  }
  ${BREAKPOINTS.mobile} {
    > h1 {
      font-size: 48px;
      line-height: 60px;
    }
    > p {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
