import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { NewFaces } from 'app/components/DetailsPages/Company/CompanyDetailsNewPeople';
import useCompanyNewPeople from 'app/components/DetailsPages/hooks/company/useCompanyNewPeople';
import { MeetSomeoneNew } from 'app/components/DetailsPages/shared/MeetSomeoneNew/MeetSomeoneNew';
import { RightArrow, Sparkle } from 'app/components/shared/icons';
import { DETAILS_PAGE_ACTION } from 'app/pages/NewDetailsPage/actions';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import useIsMobile from 'hooks/useIsMobile';
import useUserData from 'hooks/useUserData';
import { setAboutKyuOsOpen } from 'store/reducers/onboarding-reducer';
import { shuffleArray } from 'utils';

import {
  AboutKyuOsWayfindingColleaguesDescription,
  AboutKyuOsWayfindingColleaguesPeopleButton,
  AboutKyuOsWayfindingColleaguesPeopleButtonText,
  AboutKyuOsWayfindingColleaguesPeopleContainer,
  AboutKyuOsWayfindingColleaguesPeopleTitle,
  AboutKyuOsWayfindingColleaguesPeopleWrapper,
  AboutKyuOsWayfindingColleaguesTitle,
  AboutKyuOsWayfindingColleaguesWrapper,
} from './styled';

export default function AboutKyuOsWayfindingColleagues() {
  const peopleSectionCtaClickedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_PEOPLE_SECTION_CTA_CLICKED
  );
  const dispatch = useDispatch();
  const { push } = useHistory();
  const isMobile = useIsMobile();
  const userData = useUserData();
  const query = useCompanyNewPeople(userData.kyu_company?.slug, {
    enabled: false,
  });

  const { data, isStale } = query;

  useEffect(() => {
    if (userData && userData.kyu_company && userData.kyu_company.slug) {
      query.refetch();
    }
  }, [userData]);

  const newFaces = useMemo(() => {
    if (!isStale && data && data.new_faces) {
      return shuffleArray(data?.new_faces?.people).slice(0, 4);
    }
  }, [data, isStale]);

  const handleMostInCommonWithClick = () => {
    peopleSectionCtaClickedBehavioralFunction();
    push(`/member-company/${userData?.kyu_company.slug}#people-section`, {
      action: DETAILS_PAGE_ACTION.PEOPLE_DRAWER_OPEN,
    });

    if (location.pathname.includes('member-company')) {
      dispatch(setAboutKyuOsOpen(false));
    }
  };

  return (
    <AboutKyuOsWayfindingColleaguesWrapper>
      <AboutKyuOsWayfindingColleaguesTitle>
        <Sparkle width={40} height={40} fill="var(--color-primary)" />
        Get to Know: Your Colleagues
      </AboutKyuOsWayfindingColleaguesTitle>
      <AboutKyuOsWayfindingColleaguesDescription>
        Everyone in the Collective has a kyu OS profile. It’s a great place to start when getting to
        know your colleagues.
      </AboutKyuOsWayfindingColleaguesDescription>
      <AboutKyuOsWayfindingColleaguesPeopleWrapper>
        {!isStale && data.new_faces && (
          <NewFaces people={newFaces} companyName={userData.kyu_company?.name} />
        )}
        {!isStale && data.meet_someone_new && (
          <AboutKyuOsWayfindingColleaguesPeopleContainer>
            <AboutKyuOsWayfindingColleaguesPeopleTitle>
              MEET SOMEONE NEW
            </AboutKyuOsWayfindingColleaguesPeopleTitle>
            <MeetSomeoneNew people={data.meet_someone_new.people} />
            {!isMobile ? (
              <AboutKyuOsWayfindingColleaguesPeopleButton onClick={handleMostInCommonWithClick}>
                <AboutKyuOsWayfindingColleaguesPeopleButtonText>
                  SEE WHO YOU HAVE THE MOST IN COMMON WITH
                </AboutKyuOsWayfindingColleaguesPeopleButtonText>
                <RightArrow fill="var(--color-primary)" />
              </AboutKyuOsWayfindingColleaguesPeopleButton>
            ) : null}
          </AboutKyuOsWayfindingColleaguesPeopleContainer>
        )}
        {isMobile ? (
          <AboutKyuOsWayfindingColleaguesPeopleButton onClick={handleMostInCommonWithClick}>
            <AboutKyuOsWayfindingColleaguesPeopleButtonText>
              VIEW CONNECTIONS
            </AboutKyuOsWayfindingColleaguesPeopleButtonText>
            <RightArrow fill="var(--color-primary)" />
          </AboutKyuOsWayfindingColleaguesPeopleButton>
        ) : null}
      </AboutKyuOsWayfindingColleaguesPeopleWrapper>
    </AboutKyuOsWayfindingColleaguesWrapper>
  );
}
