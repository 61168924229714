import React from 'react';

export default function MugIcon({ width = 16, height = 14, fill = '#171C33' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.11466 0.61084C0.746475 0.61084 0.447998 0.909317 0.447998 1.27751V9.94417C0.447998 10.8282 0.799188 11.6761 1.42431 12.3012C2.04943 12.9263 2.89728 13.2775 3.78133 13.2775H9.11467C9.99872 13.2775 10.8466 12.9263 11.4717 12.3012C12.0968 11.6761 12.448 10.8282 12.448 9.94417C13.3321 9.94417 14.1799 9.59298 14.805 8.96786C15.4301 8.34274 15.7813 7.49489 15.7813 6.61084C15.7813 5.72678 15.4301 4.87894 14.805 4.25382C14.1799 3.6287 13.3321 3.27751 12.448 3.27751V1.27751C12.448 0.909317 12.1495 0.61084 11.7813 0.61084H1.11466ZM11.1147 3.94417V1.94417H1.78133V9.94417C1.78133 10.4746 1.99205 10.9833 2.36712 11.3584C2.74219 11.7335 3.2509 11.9442 3.78133 11.9442H9.11467C9.6451 11.9442 10.1538 11.7335 10.5289 11.3584C10.904 10.9833 11.1147 10.4746 11.1147 9.94417V9.27751V3.94417ZM12.448 8.61084C12.9784 8.61084 13.4871 8.40013 13.8622 8.02505C14.2373 7.64998 14.448 7.14127 14.448 6.61084C14.448 6.08041 14.2373 5.5717 13.8622 5.19663C13.4871 4.82155 12.9784 4.61084 12.448 4.61084V8.61084Z"
        fill={fill}
      />
    </svg>
  );
}
