import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsFAQWrapper = styled.section`
  max-width: 1000px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AboutKyuOsFAQTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
`;

export const AboutKyuOsFAQTitle = styled.h2`
  font-weight: 400;
  font-size: 38px;
  line-height: 42px;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const AboutKyuOsFAQParagraph = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: var(--color-text);

  b {
    font-weight: 700;
  }

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
  }
`;

export const AboutKyuOsFAQLink = styled.a`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-decoration-line: underline;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
  }
`;

// AM I BEING TRACKED?
export const AboutKyuOsTrackWrapper = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 16px;
    ${BREAKPOINTS.tablet} {
      margin-right: 0px;
      margin-bottom: 16px;
    }
  }

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
  }
`;

export const AboutKyuOsTrackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 24px;

  border-radius: 8px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  max-width: 50%;
  height: fit-content;

  ${BREAKPOINTS.tablet} {
    max-width: 100%;
    width: 100%;
  }
`;

export const AboutKyuOsTrackPersonIconContainer = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;

  background: rgba(23, 28, 51, 0.5);
`;

export const AboutKyuOsTrackTitle = styled.h5`
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: var(--color-text);
`;

export const AboutKyuOsTrackListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;

  & > :not(:last-child) {
    margin-right: 16px;

    ${BREAKPOINTS.tablet} {
      margin-right: 8px;
    }
  }

  & > svg {
    min-width: 24px;
    min-height: 24px;

    ${BREAKPOINTS.tablet} {
      min-width: 16px;
      min-height: 16px;
    }
  }
`;

interface AboutKyuOsTrackListItemTextProps {
  crossed?: boolean;
}

export const AboutKyuOsTrackListItemText = styled.span<AboutKyuOsTrackListItemTextProps>`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: var(--color-text);

  ${({ crossed }) =>
    crossed &&
    `
    text-decoration: line-through;
  `}

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
  }
`;

// IS KYU OS AVAILABLE EXTERNALLY?

export const AboutKyuOsExternalWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;

  ${BREAKPOINTS.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const AboutKyuOsExternalContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const AboutKyuOsExternalTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const AboutKyuOsExternalTitle = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: var(--color-text);
`;

interface AboutKyuOsExternalDotProps {
  color: string;
}

export const AboutKyuOsExternalDot = styled.div<AboutKyuOsExternalDotProps>`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${({ color }) => color};
`;

// WHO WORKS ON KYU OS?

export const AboutKyuOsTeamWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 36px;

  padding: 24px;

  ${BREAKPOINTS.tablet} {
    grid-template-columns: 1fr;
    grid-gap: 16px;
    padding: 0px;
  }
`;

export const AboutKyuOsTeamMemberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

export const AboutKyuOsTeamMemberInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AboutKyuOsTeamMemberName = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: var(--color-text);
`;

export const AboutKyuOsTeamMemberRole = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: rgba(23, 28, 51, 0.5);
`;

export const AboutKyuOsTeamMemberTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: rgba(23, 28, 51, 0.5);
`;
