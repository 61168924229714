import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getProjectsDrawerList } from 'services/app';

interface ProjectsDrawerParams {
  sort_dir?: string;
  filter_by?: string;
  filter_slugs_list?: string[];
  current_person?: boolean;
  page?: number;
  per_page?: number;
}

export default function useProjectsDrawerList(
  companySlug: string,
  params: ProjectsDrawerParams = {
    sort_dir: 'desc',
    filter_by: '',
    filter_slugs_list: [],
    current_person: false,
    page: 1,
    per_page: 50,
  },
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    [`company-${companySlug}-projects-drawer-list`, params],
    () => getProjectsDrawerList(companySlug, params).then((response) => response.data),
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
