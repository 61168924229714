import React from 'react';

export default function EditPencilIcon({ width = 16, height = 16, fill = '#171C33' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1938 1.46598C13.0178 1.46598 12.8435 1.50064 12.6809 1.56799C12.5183 1.63535 12.3706 1.73407 12.2461 1.85852L2.48843 11.6162L1.77767 14.2223L4.38378 13.5116L14.1415 3.75387C14.2659 3.62942 14.3647 3.48167 14.432 3.31907C14.4994 3.15647 14.534 2.98219 14.534 2.80619C14.534 2.63019 14.4994 2.45591 14.432 2.29331C14.3647 2.13071 14.2659 1.98297 14.1415 1.85852C14.017 1.73407 13.8693 1.63535 13.7067 1.56799C13.5441 1.50064 13.3698 1.46598 13.1938 1.46598ZM12.1199 0.213609C12.4604 0.0725841 12.8253 0 13.1938 0C13.5623 0 13.9272 0.0725842 14.2677 0.213609C14.6082 0.354633 14.9175 0.561336 15.1781 0.821915C15.4387 1.08249 15.6454 1.39185 15.7864 1.73231C15.9274 2.07277 16 2.43768 16 2.80619C16 3.17471 15.9274 3.53961 15.7864 3.88008C15.6454 4.22054 15.4387 4.52989 15.1781 4.79047L5.28274 14.6858C5.19254 14.776 5.08037 14.8411 4.9573 14.8747L0.925867 15.9742C0.672098 16.0434 0.4007 15.9713 0.214704 15.7853C0.0287084 15.5993 -0.0433651 15.3279 0.0258447 15.0741L1.12533 11.0427C1.15889 10.9196 1.22399 10.8075 1.31419 10.7173L11.2095 0.821915C11.4701 0.561336 11.7795 0.354633 12.1199 0.213609Z"
        fill={fill}
      />
    </svg>
  );
}
