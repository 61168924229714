import { MyProfileHeroCircularProgressNumber } from 'app/components/MyProfile/Hero/Progress/styled';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsWayfindingActivateProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
export const AboutKyuOsWayfindingActiveProfileInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 20px;

  ${MyProfileHeroCircularProgressNumber} {
    color: var(--color-text);
  }

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
  }
`;

export const AboutKyuOsWayfindingActiveProfileInformationTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 33px;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    align-items: center;
    gap: 22px;

    & > :nth-child(2) {
      margin-bottom: 32px;
    }
  }
`;

export const AboutKyuOsWayfindingActivateProfileTitle = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  font-weight: 400;
  font-size: 32px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    font-size: 20px;
  }

  color: var(--color-text);
`;

export const AboutKyuOsWayfindingActivateProfileSubtitle = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;

  color: var(--dark-theme-background-primary, #171c33);
`;

export const AboutKyuOsWayfindingActivateProfileDescription = styled.div`
  max-width: 572px;

  font-weight: 400;
  font-size: 18px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
  }

  color: var(--color-text);
`;

export const AboutKyuOsWayfindingActivateProfileMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

//BUTTONS
export const AboutKyuOsWayfindingActivateProfileButtonsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 16px;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

export const AboutKyuOsWayfindingActivateProfileButton = styled.button`
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  padding: 16px 48px 16px 40px;

  border-radius: 100px;
  border: 2px solid
    ${({ disabled }) => (disabled ? `rgba(23, 28, 51, 0.5)` : `var(--color-primary)`)};

  ${({ disabled }) =>
    disabled &&
    `
    & > span {
      color: rgba(23, 28, 51, 0.50);
    }

    & > svg > path {
      fill: rgba(23, 28, 51, 0.50);
    }

    cursor: default;
  `}

  ${({ disabled }) =>
    !disabled &&
    `
    transition: background 0.2s ease;
    &:hover {
      background: var(--color-primary);

      & span {
        color: #FFFFFF;
      }

      & path {
        fill: #FFFFFF;
      }
    }
  `}

  ${BREAKPOINTS.tablet} {
    width: 100%;
  }
`;

export const AboutKyyuOsWayfindingActivateProfileButtonText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;

  color: var(--color-text);
`;
