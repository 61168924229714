import React from 'react';

export default function OnboardingArrowIcon({}) {
  return (
    <svg width="98" height="89" viewBox="0 0 98 89" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M75.0652 81.1184C70.3582 81.7798 65.6544 82.322 61.0833 82.1601C56.2201 81.9877 51.8863 79.9635 47.7371 77.8613C41.1158 74.5067 35.5341 67.8152 36.6376 57.1282C37.667 47.1597 47.3278 46.1338 51.1545 50.9209C52.4991 52.603 51.8725 55.2893 50.2587 57.5274C47.706 61.0676 41.7679 61.9417 38.9978 61.3945C34.6933 60.5441 30.664 59.622 27.7368 55.6438C24.8096 51.6657 23.7545 47.6408 22.902 41.9683C21.3161 31.4154 24.2842 17.7938 29.1442 6.67933C29.7147 5.37474 31.4784 1.48384 30.8948 2.78272C30.0159 4.73923 31.0237 7.21429 31.3392 8.85979C31.4921 9.65711 32.169 17.1402 31.3241 15.0762" stroke="white" stroke-opacity="0.5" stroke-width="3" stroke-linecap="round"/>
<path d="M30.2732 3.2865C27.6388 6.44106 23.5456 8.217 20.5492 10.7985" stroke="white" stroke-opacity="0.5" stroke-width="3" stroke-linecap="round"/>
</svg>

  );
}
