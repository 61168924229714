import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import useUserData from 'hooks/useUserData';
import useWindowDimensions from 'hooks/useWindowDimensions';

import CompanyLogo from '../CompanyLogo';
import { desktopCompanies, mobileCompanies } from './constants';
import { CollectiveCompaniesWrapper } from './styled';

interface CollectiveCompaniesProps {
  overrideSize?: number;
  disableClick?: boolean;
  openDrawer?: boolean;
}

const CollectiveCompanies = memo(
  ({ overrideSize, disableClick = false, openDrawer = false }: CollectiveCompaniesProps) => {
    const userData = useUserData();
    const { push } = useHistory();
    const dimensions = useWindowDimensions();
    const isMobile = dimensions && dimensions.width <= 820;
    const isTablet = dimensions && dimensions.width <= 1024 && dimensions.width > 820;

    const companies = isMobile ? mobileCompanies : desktopCompanies;

    const handleCompanyClick = (slug: string) => {
      if (disableClick) return;
      push(`/member-company/${slug}`);
    };

    const handleSize = (): number => {
      if (overrideSize) return overrideSize;
      if (isTablet) return 68;
      if (isMobile) return 48;

      return 88;
    };

    return (
      <CollectiveCompaniesWrapper disableClick={disableClick}>
        {companies.map((company, index) => (
          <CompanyLogo
            key={index}
            size={handleSize()}
            slug={company.slug}
            borderColor={userData.kyu_company?.slug === company.slug ? '#FFC229' : '#5478A1'}
            additionalStyle={{
              gridColumnStart: company.gridColumnStart,
              gridColumnEnd: company.gridColumnEnd,
              borderWidth: 3,
            }}
            onClick={() => handleCompanyClick(company.slug)}
            useRounded
            openDrawer={openDrawer}
          />
        ))}
      </CollectiveCompaniesWrapper>
    );
  }
);

export default CollectiveCompanies;
