import React, { Suspense, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';

import { CloseIcon } from 'app/components/shared/icons';
import useOnClickOutside from 'hooks/click-outside';
import { setIsDrawerOpen } from 'store/reducers/navbar-reducer';

import { SideDrawerContext, SideDrawerRef } from './SideDrawerContext';
import {
  DrawerConfirmDialogButton,
  DrawerConfirmDialogButtonCancel,
  DrawerConfirmDialogButtonsContainer,
  DrawerConfirmDialogButtonText,
  DrawerConfirmDialogContainer,
  DrawerConfirmDialogText,
  DrawerContentWrapper,
  DrawerOverlay,
} from './styled';

interface IProps {
  children: JSX.Element;
  closeDrawer: () => void | null;
  withClose?: boolean;
  confirmBeforeClosing?: string;
}

export default function SideDrawer({
  children,
  closeDrawer,
  withClose,
  confirmBeforeClosing = null,
}: IProps) {
  const drawerContentRef = useRef(null);
  const confirmRef = useRef<HTMLDialogElement>(null);
  const [triggerClosing, setTriggerClosing] = useState(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const sideDrawerRef = useRef<SideDrawerRef>({
    onClose: () => {},
    setConfirmBeforeClose: (message: string) => {},
    onCancelClose: () => {},
    onConfirmClose: () => {},
    onCloseAttempt: () => {},
  });
  const [shouldConfirmBeforeClose, setShouldConfirmBeforeClose] = useState(confirmBeforeClosing);
  const dispatch = useDispatch();

  sideDrawerRef.current.setConfirmBeforeClose = (message: string) => {
    setShouldConfirmBeforeClose(message);
  };

  const handleClose = () => {
    if (shouldConfirmBeforeClose !== null) {
      sideDrawerRef.current.onCloseAttempt?.();
      setShowConfirm(true);
    } else if (shouldConfirmBeforeClose === null) {
      setTriggerClosing(true);
    }
  };

  const handleConfirmClose = () => {
    setShowConfirm(false);
    sideDrawerRef.current.onClose?.();
    setTriggerClosing(true);
  };

  const handleConfirmCancelClose = () => {
    setShowConfirm(false);
    sideDrawerRef.current.onCancelClose?.();
  };

  useOnClickOutside(confirmRef, handleConfirmCancelClose);
  useOnClickOutside(drawerContentRef, handleClose);

  useEffect(() => {
    dispatch(setIsDrawerOpen(true));
    window.addEventListener('message', (event) => {
      if (event.data === 'drawers:close') {
        setTriggerClosing(true);
      }
    });

    return () => {
      window.removeEventListener('message', (event) => {
        if (event.data === 'drawers:close') {
          setTriggerClosing(true);
        }
      });
    };
  }, []);

  useEffect(() => {
    if (triggerClosing) {
      setTimeout(() => {
        if (closeDrawer !== null) {
          sideDrawerRef.current?.onClose();
          dispatch(setIsDrawerOpen(false));
          closeDrawer();
        }
      }, 500);
    }
  }, [triggerClosing]);

  return ReactDOM.createPortal(
    <>
      <SideDrawerContext.Provider value={{ sideDrawerRef }}>
        <DrawerOverlay triggerClosing={triggerClosing} />
        {showConfirm ? (
          <DrawerConfirmDialogContainer ref={confirmRef}>
            <DrawerConfirmDialogText>{shouldConfirmBeforeClose}</DrawerConfirmDialogText>
            <DrawerConfirmDialogButtonsContainer>
              <DrawerConfirmDialogButton onClick={handleConfirmClose}>
                <DrawerConfirmDialogButtonText>Yes</DrawerConfirmDialogButtonText>
              </DrawerConfirmDialogButton>
              <DrawerConfirmDialogButtonCancel onClick={handleConfirmCancelClose}>
                <DrawerConfirmDialogButtonText>No</DrawerConfirmDialogButtonText>
              </DrawerConfirmDialogButtonCancel>
            </DrawerConfirmDialogButtonsContainer>
          </DrawerConfirmDialogContainer>
        ) : null}
        <DrawerContentWrapper
          ref={drawerContentRef}
          triggerClosing={triggerClosing}
          data-type="sidedrawer"
        >
          {withClose ? (
            <button className="close-button" aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </button>
          ) : null}
          <Suspense
            fallback={
              <div style={{ minHeight: '100svh', width: '100%', background: 'white' }}></div>
            }
          >
            {children}
          </Suspense>
        </DrawerContentWrapper>
      </SideDrawerContext.Provider>
    </>,
    document.getElementById('root')
  );
}
