import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsContactWrapper = styled.section`
  max-width: 1000px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 32px;

  padding: 18px 15px;
`;

export const AboutKyuOsContactInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AboutKyuOsContactTitle = styled.h2`
  font-weight: 400;
  font-size: 38px;
  line-height: 42px;

  color: var(--color-text);
`;

export const AboutKyuOsContactDescription = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: var(--color-text);
`;

// CARDS
export const AboutKyuOsContactCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 24px;

  ${BREAKPOINTS.tablet} {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 24px;
  }
`;

export const AboutKyuOsContactCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 10px;

  border-radius: 20px;
  background: rgba(162, 101, 101, 0.3);
`;

export const AboutKyuOsContactCardTitle = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;

  color: var(--color-text);
`;

export const AboutKyuOsContactCardSeparator = styled.div`
  width: 100%;
  height: 1px;

  background: rgba(84, 120, 161, 0.3);
`;

export const AboutKyuOsContactCardBottomContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
`;

export const AboutKyuOsContactCardDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;

  color: rgba(23, 28, 51, 0.5);
`;

export const AboutKyuOsContactCardCTA = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-decoration: none;

  & > b {
    color: rgba(23, 28, 51, 0.5);
  }

  color: var(--color-text);
  cursor: pointer;
`;

export const AboutKyuOsContactReportButton = styled.button`
  width: fit-content;
  height: 54px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 10px;

  padding: 16px 48px;

  border-radius: 100px;
  background: var(--color-primary);

  transition: background 0.2s ease;
  &:hover {
    background: #323f5d;
  }

  ${BREAKPOINTS.tablet} {
    width: 100%;
  }
`;

export const AboutKyuOsContactReportButtonText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;

  color: #ffffff;
`;
