import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

import { AboutKyuOsContactWrapper } from './AboutKyuOsContact/styled';
import { AboutKyuOsIntroductionWrapper } from './AboutKyuOsIntroduction/styled';
import { AboutKyuOsNetworkWrapper } from './AboutKyuOsNetwork/styled';
import { AboutKyuOsWayfindingWrapper } from './AboutKyuOsWayfinding/styled';

interface AboutKyuOsTextProps {
  margin?: string;
}

//COMMON
export const AboutKyuOsMainContainer = styled.main`
  max-width: 1000px;
  width: 100%;

  display: flex;
  padding-bottom: 110px;
  justify-content: center;

  nav {
    height: fit-content;

    position: sticky;
    top: 184px;
    left: 0px;

    margin-top: 100px;
    padding: 0px 24px;
    width: 15vw;
  }

  margin: 0 auto;

  position: relative;

  ${BREAKPOINTS.tablet} {
    padding-right: 0px;
  }
`;

export const AboutKyuOsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 70vw;
  max-width: 1440px;
  align-items: center;

  margin-top: 62px;

  ${AboutKyuOsIntroductionWrapper} {
    margin-bottom: 74px;
  }

  & > iframe {
    margin-bottom: 74px;
  }

  ${AboutKyuOsWayfindingWrapper} {
    margin-bottom: 132px;
  }

  ${AboutKyuOsContactWrapper} {
    margin-bottom: 90px;
  }

  ${AboutKyuOsNetworkWrapper} {
    margin-bottom: 135px;
  }

  & > :not(:last-child) {
    ${BREAKPOINTS.tablet} {
      margin-bottom: 88px;
    }
  }

  ${BREAKPOINTS.tablet} {
    padding: 0px 16px;
    width: 100%;
  }
`;

export const AboutKyuOsSection = styled.section``;

export const AboutKyuOsSectionTitle = styled.h2`
  color: var(--color-text);
  cursor: pointer;
  font-size: 38px;
  line-height: 42px;
  margin-bottom: 30px;

  ${BREAKPOINTS.tablet} {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const AboutKyuOsText = styled.span<AboutKyuOsTextProps>`
  &,
  a,
  em {
    color: var(--color-text);
    font-size: 18px;
    line-height: 23px;
  }
  > em {
    font-style: italic;
  }
  margin-bottom: 10px;
  display: block;
  margin: ${({ margin = '0 0 24px' }) => margin};
  ${BREAKPOINTS.mobile} {
    &,
    a,
    em {
      font-size: 14px;
      line-height: 18px;
    }
    margin: 0 0 18px;
  }
  span {
    font-weight: 700;
  }
`;

//AboutKyuOsHero components
export const AboutKyuOsHeroWrapper = styled.header`
  width: 100%;
  height: fit-content;

  display: flex;
  justify-content: center;

  ${BREAKPOINTS.tablet} {
    overflow: hidden;
  }
`;

export const AboutKyuOsHeroContentContainer = styled.div`
  width: 100%;
  max-width: 1440px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 146px 62px 62px 146px;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    height: fit-content;
    padding: 96px 32px 56px 32px;

    & > :not(:last-child) {
      margin-bottom: 49px;
    }
  }

  position: relative;
`;

export const AboutKyuOsHeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${BREAKPOINTS.tablet} {
    & > :not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const AboutKyuOsHeroImage = styled.img`
  position: absolute;
  right: 0px;
  top: 0px;

  ${BREAKPOINTS.tablet} {
    max-height: 179px;
    max-width: 741px;
    position: relative;
    object-position: -75px;
  }
`;

export const AboutKyuOsHeroTitle = styled.h1`
  font-weight: 400;
  font-size: 64px;
  line-height: 83px;
  color: var(--color-primary);
  ${BREAKPOINTS.mobile} {
    font-size: 38px;
    line-height: 42px;
  }
`;

export const AboutKyuOsHeroSubtitle = styled.h2`
  max-width: 529px;
  font-size: 38px;
  line-height: 42px;
  margin: 28px 0 47px;
  font-weight: 400;
  color: var(--color-primary);
  ${BREAKPOINTS.mobile} {
    font-size: 32px;
    line-height: 36px;
    margin: 24px 0;
  }
`;

export const AboutKyuOsHeroButton = styled.button`
  width: fit-content;

  font-size: 24px;
  line-height: 28px;
  text-align: left;
  color: var(--color-text);

  img {
    margin-bottom -9px;
    cursor: pointer;
  }
    
  ${BREAKPOINTS.tablet} {
    margin-top: 36px;
    font-size: 20px;
    line-height: 24px;
  }

  position: relative;
`;

export const AboutKyuOsHeroStory = styled.div`
  display: flex;
  color: #ffc229;
  font-size: 14px;
  position: absolute;
  line-height: 18px;
  top: 5px;
  right: -145px;
  text-transform: uppercase;
`;

//Where we started
export const AboutKyuOsBeginningContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  padding: 0 16px;
`;

export const AboutKyuOsWhereWeStartedMapContainer = styled.div`
  width: 100%;
  img {
    width: 100%;
    border-radius: 16px;
    border: 1px solid var(--dark-theme-background-secondary, #323f5d);
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const AboutKyuOsWhereWeStartedTitle = styled.h3`
  font-size: 32px;
  line-height: 42px;
  margin-top: 100px;
  margin-bottom: 49px;

  ${BREAKPOINTS.tablet} {
    margin-top: 88px;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: normal;
  }
`;

//Whats on kyu OS
export const AboutKyuOsContentsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 72px;
  flex-direction: column;
  align-items: center;
  gap: 88px;
  align-self: stretch;
  border-radius: 8px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);

  max-width: 1000px;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    padding: 24px 16px 16px 16px;
    gap: 48px;
    box-shadow: none;
  }
`;

export const AboutKyuOsContentsTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  ${BREAKPOINTS.tablet} {
    padding: 0 16px;
  }
`;

export const AboutKyuOsContentsTitle = styled.h3`
  color: var(--color-primary);
  font-size: 32px;
  margin-right: 5px;
  line-height: 42px;
  position: relative;
  svg:first-child {
    position: absolute;
    top: -10px;
    left: -40px;
    ${BREAKPOINTS.tablet} {
      left: -30px;
    }
  }
  svg:last-child {
    margin-left: 35px;
    margin-bottom: -10px;
  }
  span {
    font-weight: 700;
  }

  ${BREAKPOINTS.tablet} {
    font-size: 24px;
    line-height: 32px;
  }
`;
export const AboutKyuOsContentsSubtitle = styled.h4`
  color: var(--color-primary);
  font-size: 24px;
  font-weight: 400;
  margin-top: 24px;

  ${BREAKPOINTS.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const AboutKyuOsContents = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;
export const AboutKyuOsContentsRow = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  :not(:last-child) {
    margin-bottom: 100px;
  }

  & > * {
    justify-self: center;
  }

  ${BREAKPOINTS.mobile} {
    grid-template-columns: 1fr;
    :not(:last-child) {
      margin-bottom: 50px;
    }
  }
`;

export const AboutKyuOsContentsRowTileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const AboutKyuOsContentsRowInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-primary);
  font-weight: 400;
  ${BREAKPOINTS.tablet} {
    width: 100%;
    margin-bottom: 32px;
  }
`;

export const AboutKyuOsContentsRowInfoTitle = styled.h5`
  font-weight: 400;
  font-size: 32px;
  line-height: normal;
  color: var(--color-text);

  margin-bottom: 16px;

  ${BREAKPOINTS.tablet} {
    font-size: 24px;
  }
`;

export const AboutKyuOsContentsRowInfoText = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
  }
`;
export const AboutKyuOsContentsRowTileContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 150px;
    height: 150px;
    margin-bottom: 16px;
    transition: all 200ms ease-in-out;

    ${BREAKPOINTS.mobile} {
      width: 116px;
      height: 116px;
      margin-bottom: 10px;
    }
  }
  div {
    transition: all 200ms ease-in-out;
  }
  :hover {
    div {
      background: rgba(255, 255, 255, 0.5);
    }
    img {
      box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
    }
  }

  ${BREAKPOINTS.tablet} {
    padding: 16px;
    justify-content: flex-start;
    text-align: center;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.2);
  }

  ${BREAKPOINTS.mobile} {
    width: 150px;
  }
`;
export const AboutKyuOsContentsRowTileTextContainer = styled.div`
  display: flex;
  padding: 8px 16px;
  border-radius: 80px;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  span {
    font-size: 16px;
    line-height: 20px;
    margin-left: 10px;
    ${BREAKPOINTS.tablet} {
      font-weight: 500;
      margin-left: 0px;
    }
  }

  ${BREAKPOINTS.tablet} {
    width: 100%;
    padding: 0px;
    justify-content: center;
    align-items: center;
    background: none;
    border-radius: 0px;
  }
`;

export const AboutKyuOsContentsRowCommonGround = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2 span;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  ${BREAKPOINTS.mobile} {
    grid-column: 1 span;
    order: -1;
    width: 100%;
    margin-bottom: 32px;
  }
`;
