import React, { useEffect, useState } from 'react';

import useBehavioral from 'hooks/useBehavioral';
import { ThemeProvider } from 'styled-components';

import CommonGroundSkeleton from './CommonGroundSkeleton';
import { ArrowDownIcon, ArrowUpIcon } from './Icons';
import {
  CommonGroundArrowDown,
  CommonGroundArrowUp,
  CommonGroundContainer,
  CommonGroundIcon,
  CommonGroundIconsContainer,
} from './styled';

export type CommonGroundType =
  | 'languages'
  | 'locations'
  | 'passions'
  | 'industries'
  | 'kyu_skills'
  | 'clients'
  | 'projects'
  | 'passion_companies'
  | 'roles';

interface CommonGroundData {
  type?: CommonGroundType;
  index?: number;
  icon?: React.ReactElement<any>;
  component?: React.ReactElement<any>;
  data?: any;
}

interface CommonGroundProps {
  isLoading: boolean;
  data?: CommonGroundData[];
  isDisabled?: boolean;
  height?: number;
  noPadding?: boolean;
  forceMobile?: boolean;
}

export default function CommonGround({
  isLoading,
  data,
  height,
  isDisabled = false,
  noPadding = false,
  forceMobile = false,
}: CommonGroundProps) {
  if (isLoading) return <CommonGroundSkeleton />;
  const commonGroundCarouselClickedBehavioral = useBehavioral('COMMON_GROUND_CAROUSEL_CLICKED');
  const [index, setIndex] = useState<number>(0);
  const [currentGround, setCurrentGround] = useState<CommonGroundData>(data[0]);

  useEffect(() => {
    if (!isLoading && data) {
      setCurrentGround(data[index]);
    }
  }, [data, index]);

  const handleNext = () => {
    if (isDisabled) return;
    commonGroundCarouselClickedBehavioral();
    setIndex((index + 1) % data.length);
  };

  const handlePrevious = () => {
    if (isDisabled) return;
    commonGroundCarouselClickedBehavioral();
    index === 0 ? setIndex(data.length - 1) : setIndex(index - 1);
  };

  return (
    <ThemeProvider theme={{ forceMobile, type: currentGround?.type }}>
      <CommonGroundContainer type={currentGround?.type} height={height} noPadding={noPadding}>
        <CommonGroundIconsContainer>
          {data.map(({ icon, index: itemIndex }, key) => (
            <CommonGroundIcon
              key={key}
              onClick={() => {
                if (isDisabled) return;
                setIndex(key);
              }}
            >
              {React.cloneElement(icon, {
                fill: itemIndex === index ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.3)',
              })}
            </CommonGroundIcon>
          ))}
        </CommonGroundIconsContainer>
        <CommonGroundArrowUp type={currentGround?.type} onClick={handlePrevious}>
          <ArrowUpIcon
            fill={
              currentGround && currentGround.type === 'industries'
                ? 'rgba(23, 28, 51, 0.5)'
                : 'rgba(255, 255, 255, 1)'
            }
          />
        </CommonGroundArrowUp>
        <CommonGroundArrowDown type={currentGround?.type} onClick={handleNext}>
          <ArrowDownIcon
            fill={
              currentGround && currentGround.type === 'industries'
                ? 'rgba(23, 28, 51, 0.5)'
                : 'rgba(255, 255, 255, 1)'
            }
          />
        </CommonGroundArrowDown>
        {data[index]?.component &&
          React.cloneElement(data[index]?.component, {
            data: data[index]?.data,
            type: data[index]?.type,
            icon: data[index]?.icon,
          })}
      </CommonGroundContainer>
    </ThemeProvider>
  );
}
