import React, { createContext } from 'react';

type Model =
  | 'people'
  | 'passions'
  | 'skills'
  | 'companies'
  | 'clients'
  | 'industries'
  | 'disciplines'
  | 'wildcard';

interface HomeDrawerContextInfo {
  model: Model | null;
  setModel: React.Dispatch<React.SetStateAction<Model>>;
  setGlobalDrawer: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      model: Model | null;
      slug: string | null;
    }>
  >;
}

export const HomeDrawerContext = createContext<HomeDrawerContextInfo>({
  model: null,
  setModel: () => {},
  setGlobalDrawer: () => {},
});
