import React from 'react';
import { useHistory } from 'react-router-dom';

import { CompanyIcon, PassionIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import CommunitiesIcon from 'app/components/shared/icons/communities-icon';
import PersonIcon from 'app/components/shared/icons/person-icon';

import GlobalDataTypeDrawer from '../GlobalDataType/GlobalDataTypeDrawer';
import usePassionDrawerData from '../hooks/usePassionDrawerData';
import {
  GlobalPassionDrawerCounter,
  GlobalPassionDrawerCounterContainer,
  GlobalPassionDrawerCounterIconContainer,
  GlobalPassionDrawerCountersWrapper,
  GlobalPassionDrawerCounterText,
  GlobalPassionDrawerHighlightText,
  GlobalPassionDrawerHighlightTextContainer,
} from './styled';

interface GlobalPassionDrawerProps {
  slug: string;
  comingFromList?: boolean;
  backToListAction?: () => void;
}

export default function GlobalPassionDrawer({
  slug,
  comingFromList = false,
  backToListAction = () => {},
}: GlobalPassionDrawerProps) {
  const query = usePassionDrawerData(slug);
  const { isLoading, data } = query;
  const { push } = useHistory();

  return (
    <GlobalDataTypeDrawer
      slug={slug}
      query={query}
      isLoading={isLoading}
      background={
        'linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(249, 237, 234, 0.3) 0.01%, rgba(251, 175, 161, 0.3) 99.99%, rgba(251, 175, 161, 0.12) 100%), #FFFFFF'
      }
      dataType={'passion'}
      title={data?.name || ''}
      includesCurrentPerson={data?.includes_current_person || false}
      icon={<PassionIcon fill={'#D47563'} />}
      catchphraseHighlightColor={'#D47563'}
      iconBackground={'#FBAFA1'}
      buttonOnClick={() => push(`/passion/${slug}`)}
      buttonBackgroundColor={'#D47563'}
      peopleAtCompany={data?.people_at_company || null}
      comingFromList={comingFromList}
      backToListAction={() => backToListAction()}
    >
      {data?.highlight && (
        <GlobalPassionDrawerHighlightTextContainer>
          <GlobalPassionDrawerHighlightText>
            <span className="people">
              {data?.highlight.people_count != 1 ? (
                <>{data?.highlight.people_count} people</>
              ) : (
                <>{data?.highlight.people_sample}</>
              )}
            </span>
            {' from '}
            <span className="company">
              {data?.highlight.company_count != 1 ? (
                <>{data?.highlight.company_count} companies</>
              ) : (
                <>{data?.highlight.company_names[0]}</>
              )}
            </span>{' '}
            {data?.highlight.people_count != 1 ? (
              <>added this passion to their profiles.</>
            ) : (
              <>shares this passion.</>
            )}{' '}
          </GlobalPassionDrawerHighlightText>
          {data?.highlight.company_names.length > 1 && (
            <GlobalPassionDrawerHighlightText>
              Noticeably, this passion is popular with people that work at{' '}
              {data?.highlight.company_names.map((companyName, index) => (
                <React.Fragment key={index}>
                  <span className="company">{companyName}</span>
                  {index !== data?.highlight.company_names.length - 1
                    ? index === data?.highlight.company_names.length - 2
                      ? ' and '
                      : ', '
                    : ''}
                </React.Fragment>
              ))}
            </GlobalPassionDrawerHighlightText>
          )}
        </GlobalPassionDrawerHighlightTextContainer>
      )}
      <GlobalPassionDrawerCountersWrapper>
        {data?.counters.people ? (
          <GlobalPassionDrawerCounterContainer>
            <GlobalPassionDrawerCounterIconContainer>
              <PersonIcon width={24} height={24} fill={'#FFC229'} />
              <GlobalPassionDrawerCounter>{data.counters.people}</GlobalPassionDrawerCounter>
            </GlobalPassionDrawerCounterIconContainer>
            <GlobalPassionDrawerCounterText>
              {data.counters.people > 1 ? 'people' : 'person'}
            </GlobalPassionDrawerCounterText>
          </GlobalPassionDrawerCounterContainer>
        ) : null}
        {data?.counters.companies ? (
          <GlobalPassionDrawerCounterContainer>
            <GlobalPassionDrawerCounterIconContainer>
              <CompanyIcon width={24} height={24} />
              <GlobalPassionDrawerCounter>{data.counters.companies}</GlobalPassionDrawerCounter>
            </GlobalPassionDrawerCounterIconContainer>
            <GlobalPassionDrawerCounterText>
              {data.counters.companies > 1 ? 'companies' : 'company'}
            </GlobalPassionDrawerCounterText>
          </GlobalPassionDrawerCounterContainer>
        ) : null}
        {data?.counters.communities ? (
          <GlobalPassionDrawerCounterContainer>
            <GlobalPassionDrawerCounterIconContainer>
              <CommunitiesIcon width={24} height={24} fill={'#A26565'} />
              <GlobalPassionDrawerCounter>{data.counters.communities}</GlobalPassionDrawerCounter>
            </GlobalPassionDrawerCounterIconContainer>
            <GlobalPassionDrawerCounterText>
              {data.counters.communities > 1 ? 'communities' : 'community'}
            </GlobalPassionDrawerCounterText>
          </GlobalPassionDrawerCounterContainer>
        ) : null}
      </GlobalPassionDrawerCountersWrapper>
    </GlobalDataTypeDrawer>
  );
}
