import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { IPersonGlobalDrawerResponse } from 'app/components/DetailsPages/interfaces/person';
import { getPersonDrawerData } from 'services/app';

export default function usePersonDrawerData(
  personSlug: string,
  options?: Omit<
    UseQueryOptions<IPersonGlobalDrawerResponse, Error, IPersonGlobalDrawerResponse, QueryKey>,
    'queryKey'
  >
) {
  return useQuery<IPersonGlobalDrawerResponse, Error>(
    [`person-drawer-data`, personSlug],
    () =>
      getPersonDrawerData(personSlug).then((response) => {
        if (!response.ok) {
          throw new Error(response.originalError?.message);
        }
        return response.data;
      }),
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
