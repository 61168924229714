import React from "react";

export default function first() {
  return (
    <svg
      width="69"
      className="explore-text"
      height="227"
      viewBox="0 0 69 227"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.1453 209.397L50.7898 220.78L43.7483 213.137L44.9662 212.015L50.6417 218.175L54.9484 214.207L49.7608 208.577L50.9611 207.471L56.1487 213.102L60.5613 209.036L54.8859 202.876L56.1038 201.754L63.1453 209.397Z"
        fill="#FFC229"
      />
      <path
        d="M53.5259 198.508L46.2854 198.716L43.9429 205.57L42.6472 203.812L44.6289 198.208L38.6749 198.422L37.3934 196.683L44.6339 196.475L46.9764 189.621L48.2578 191.36L46.2904 196.983L52.2302 196.75L53.5259 198.508Z"
        fill="#FFC229"
      />
      <path
        d="M49.2872 183.401L34.3713 191.933L33.4656 190.35L35.0785 189.206C34.3878 189.159 33.6664 188.945 32.914 188.564C32.1538 188.17 31.4995 187.493 30.9514 186.535C30.3634 185.507 30.0871 184.476 30.1223 183.442C30.1576 182.408 30.4657 181.449 31.0468 180.563C31.6199 179.664 32.4273 178.917 33.4689 178.321C34.5105 177.725 35.5642 177.408 36.6298 177.37C37.6737 177.325 38.6542 177.557 39.5713 178.065C40.4666 178.566 41.2081 179.331 41.796 180.359C42.2807 181.206 42.5401 182.062 42.5742 182.928C42.6004 183.779 42.4003 184.557 41.974 185.262L48.2862 181.651L49.2872 183.401ZM40.3825 181.416C39.9853 180.722 39.476 180.202 38.8547 179.857C38.2195 179.52 37.5228 179.366 36.7647 179.394C36.0067 179.422 35.2387 179.658 34.461 180.103C33.6833 180.548 33.0901 181.09 32.6816 181.73C32.2731 182.369 32.0601 183.044 32.0426 183.754C32.0111 184.472 32.194 185.179 32.5913 185.873C32.9885 186.568 33.5047 187.083 34.14 187.421C34.7613 187.766 35.451 187.924 36.2091 187.896C36.9672 187.868 37.7351 187.631 38.5128 187.186C39.2906 186.741 39.8837 186.199 40.2922 185.56C40.7007 184.921 40.9207 184.242 40.9521 183.524C40.9696 182.813 40.7798 182.111 40.3825 181.416Z"
        fill="#FFC229"
      />
      <path
        d="M36.4368 170.45L20.6754 177.534L19.849 175.695L35.6104 168.611L36.4368 170.45Z"
        fill="#FFC229"
      />
      <path
        d="M31.4061 156.938C31.7671 157.998 31.8496 159.035 31.6537 160.048C31.4579 161.062 31.0058 161.968 30.2975 162.767C29.5689 163.555 28.6063 164.154 27.4098 164.561C26.2132 164.968 25.0906 165.072 24.042 164.871C22.9782 164.676 22.0595 164.236 21.2861 163.553C20.5074 162.855 19.9376 161.975 19.5766 160.915C19.2156 159.855 19.1331 158.818 19.329 157.805C19.5248 156.792 19.9871 155.891 20.7157 155.102C21.424 154.303 22.3764 153.7 23.5729 153.292C24.7695 152.885 25.8996 152.779 26.9634 152.975C28.0121 153.175 28.9257 153.625 29.7044 154.323C30.4778 155.006 31.0451 155.878 31.4061 156.938ZM29.7703 157.495C29.5485 156.844 29.1832 156.292 28.6743 155.84C28.1655 155.388 27.5336 155.096 26.7789 154.964C26.0241 154.832 25.1772 154.926 24.2382 155.246C23.2991 155.566 22.5708 156.008 22.0533 156.573C21.5306 157.123 21.2057 157.732 21.0784 158.401C20.9511 159.07 20.9958 159.722 21.2124 160.358C21.4342 161.01 21.7995 161.561 22.3084 162.013C22.8172 162.465 23.4491 162.757 24.2038 162.889C24.9586 163.021 25.8055 162.927 26.7445 162.607C27.6836 162.287 28.4119 161.845 28.9294 161.28C29.4469 160.715 29.7719 160.106 29.9043 159.452C30.0316 158.784 29.9869 158.131 29.7703 157.495Z"
        fill="#FFC229"
      />
      <path
        d="M27.9636 146.167L16.3593 148.821L15.9526 147.043L18.1377 146.371C17.2914 146.187 16.5582 145.781 15.9382 145.151C15.3182 144.521 14.8852 143.668 14.639 142.592L16.6978 142.121L16.8209 142.659C16.9779 143.346 17.2471 143.949 17.6287 144.469C17.9947 144.992 18.5054 145.36 19.161 145.571C19.8166 145.782 20.6513 145.772 21.6651 145.54L27.5141 144.202L27.9636 146.167Z"
        fill="#FFC229"
      />
      <path
        d="M25.2458 129.485C25.3869 130.612 25.2582 131.644 24.8595 132.581C24.4449 133.52 23.8091 134.301 22.9519 134.924C22.0927 135.532 21.036 135.914 19.7818 136.071C18.5435 136.226 17.4331 136.115 16.4507 135.739C15.4523 135.364 14.6445 134.772 14.0271 133.962C13.3919 133.139 13.0017 132.147 12.8565 130.988C12.7134 129.845 12.8521 128.828 13.2726 127.937C13.6752 127.032 14.2694 126.305 15.0552 125.755C15.841 125.205 16.718 124.869 17.6865 124.748C17.8611 124.726 18.0367 124.712 18.2134 124.706C18.388 124.684 18.5865 124.659 18.8087 124.632L19.9867 134.038C20.8857 133.877 21.614 133.576 22.1715 133.136C22.7112 132.681 23.0971 132.157 23.3292 131.564C23.5592 130.954 23.6345 130.332 23.555 129.697C23.4516 128.871 23.1746 128.205 22.724 127.697C22.2734 127.189 21.7067 126.849 21.024 126.676L20.7764 124.7C21.9116 124.88 22.901 125.377 23.7447 126.19C24.5704 126.99 25.0708 128.088 25.2458 129.485ZM14.5533 130.824C14.6726 131.777 15.0726 132.589 15.7535 133.262C16.4165 133.921 17.3106 134.236 18.4358 134.208L17.5054 126.778C16.4476 126.959 15.6599 127.428 15.1421 128.187C14.6243 128.945 14.428 129.824 14.5533 130.824Z"
        fill="#FFC229"
      />
      <path
        d="M24.3047 104.347C24.2485 105.433 23.9407 106.274 23.3811 106.87C22.8216 107.466 21.8548 107.729 20.4806 107.658L13.9374 107.319L13.8308 109.381L12.1291 109.293L12.2356 107.231L9.39707 106.82L9.48754 105.071L12.3397 105.218L12.5207 101.719L14.2224 101.807L14.0415 105.306L20.5847 105.644C21.3357 105.683 21.8549 105.558 22.1422 105.268C22.4136 104.978 22.5691 104.449 22.6087 103.682L22.6732 102.436L24.3989 102.525L24.3047 104.347Z"
        fill="#FFC229"
      />
      <path
        d="M24.9804 96.6542L7.90752 93.987L8.2187 91.9952L15.5458 93.1399C14.8804 92.6311 14.4069 91.9822 14.1253 91.1933C13.8279 90.4019 13.7458 89.5794 13.8792 88.7257C14.0916 87.3662 14.6888 86.3421 15.6709 85.6535C16.6371 84.9623 17.9976 84.7538 19.7523 85.0279L26.6289 86.1022L26.3215 88.0704L19.6583 87.0294C17.208 86.6466 15.8285 87.4432 15.5198 89.4193C15.3593 90.4468 15.5962 91.3664 16.2305 92.178C16.849 92.9872 17.838 93.498 19.1975 93.7104L25.2916 94.6624L24.9804 96.6542Z"
        fill="#FFC229"
      />
      <path
        d="M29.5291 76.0167C29.2257 77.1114 28.7097 78.0144 27.9812 78.7256C27.2373 79.4326 26.3498 79.9088 25.3187 80.1542C24.292 80.3843 23.1695 80.3305 21.9515 79.9928C20.7488 79.6594 19.7666 79.1297 19.0049 78.4038C18.2277 77.6736 17.71 76.8162 17.4517 75.8314C17.1822 74.8269 17.2035 73.7619 17.5156 72.6363C17.8233 71.5262 18.3428 70.6408 19.0739 69.9801C19.7938 69.2997 20.6222 68.8569 21.5591 68.6517C22.4959 68.4466 23.4346 68.4743 24.3751 68.7351C24.5448 68.7821 24.7122 68.8368 24.8775 68.8993C25.0471 68.9463 25.2399 68.9997 25.4557 69.0596L22.9231 78.195C23.8148 78.3924 24.6027 78.395 25.2869 78.2028C25.96 77.9909 26.5178 77.6558 26.9604 77.1973C27.4072 76.7235 27.7161 76.1782 27.8871 75.5615C28.1094 74.7597 28.1103 74.0377 27.8898 73.3955C27.6694 72.7532 27.2773 72.2211 26.7135 71.7992L27.2457 69.8796C28.2241 70.4829 28.9461 71.3223 29.4117 72.3978C29.8662 73.4536 29.9053 74.6599 29.5291 76.0167ZM19.1448 73.1378C18.8884 74.0629 18.9449 74.9669 19.3145 75.8497C19.6729 76.7128 20.3768 77.348 21.4262 77.7551L23.4267 70.5393C22.3808 70.2991 21.473 70.4294 20.7033 70.9299C19.9336 71.4305 19.4141 72.1664 19.1448 73.1378Z"
        fill="#FFC229"
      />
      <path
        d="M36.0471 56.7789L25.5057 51.2487L26.3531 49.6335L27.9502 50.3087C27.5618 49.6714 27.3604 48.9875 27.3461 48.2573C27.3317 47.527 27.5103 46.8077 27.882 46.0992C28.3205 45.2633 28.8659 44.637 29.5182 44.2203C30.1778 43.7894 30.9403 43.5932 31.8055 43.6315C31.3612 42.9286 31.1221 42.1618 31.0882 41.3309C31.0617 40.4859 31.2455 39.6879 31.6394 38.937C32.301 37.676 33.2113 36.8707 34.3703 36.5212C35.5152 36.1642 36.874 36.3982 38.4467 37.2233L44.6101 40.4567L43.6846 42.2207L37.7126 39.0877C36.6216 38.5153 35.6846 38.3038 34.9016 38.4531C34.1186 38.6025 33.5115 39.088 33.0804 39.9098C32.6344 40.7599 32.5957 41.643 32.9643 42.5591C33.3262 43.4536 34.1164 44.2205 35.3349 44.8597L40.7969 47.7252L39.8603 49.5105L33.8882 46.3774C32.7973 45.805 31.8603 45.5935 31.0773 45.7429C30.2943 45.8922 29.6872 46.3778 29.2561 47.1995C28.8175 48.0355 28.7825 48.9115 29.1511 49.8276C29.513 50.7221 30.3032 51.489 31.5217 52.1282L36.9837 54.9937L36.0471 56.7789Z"
        fill="#FFC229"
      />
      <path
        d="M49.7328 34.0565C49.1565 34.8639 48.5411 35.437 47.8865 35.7757C47.2319 36.1145 46.5837 36.2514 45.942 36.1866C45.3003 36.1217 44.7125 35.8987 44.1785 35.5176C43.1888 34.8112 42.6965 33.8897 42.7017 32.7533C42.7068 31.6169 43.1742 30.3975 44.1037 29.0952L45.972 26.4775L45.8548 26.3939C45.0083 25.7897 44.2122 25.5556 43.4664 25.6917C42.7075 25.8184 42.0632 26.253 41.5334 26.9953C41.0779 27.6334 40.8457 28.3031 40.8366 29.0043C40.8239 29.6832 41.0817 30.2997 41.6101 30.8538L40.411 32.5337C39.7543 31.9667 39.329 31.3093 39.1353 30.5617C38.9509 29.801 38.9534 29.0264 39.1429 28.2377C39.3193 27.4397 39.6538 26.6956 40.1464 26.0054C41.1131 24.651 42.2028 23.8954 43.4154 23.7387C44.6244 23.5598 45.8279 23.8978 47.026 24.753L52.9841 29.0055L51.9384 30.4706L50.1301 29.3274C50.4818 29.9913 50.6625 30.7296 50.6721 31.5424C50.678 32.3329 50.3649 33.1709 49.7328 34.0565ZM48.5271 32.8126C48.9733 32.1875 49.1916 31.5373 49.182 30.8622C49.1818 30.174 49.0049 29.5268 48.6512 28.9206C48.2976 28.3144 47.8343 27.8068 47.2613 27.3978L47.2418 27.3838L45.471 29.8647C44.7832 30.8284 44.4645 31.633 44.515 32.2784C44.5617 32.9014 44.8325 33.3896 45.3274 33.7428C45.8353 34.1053 46.3756 34.2158 46.9484 34.0742C47.5175 33.9103 48.0437 33.4898 48.5271 32.8126Z"
        fill="#FFC229"
      />
      <path
        d="M60.053 28.7104L47.3447 17.1437L48.5724 15.7948L50.1571 16.9775C49.9896 16.3058 49.9712 15.5535 50.1019 14.7205C50.2434 13.8757 50.6857 13.0451 51.4288 12.2286C52.2257 11.353 53.1215 10.7729 54.1162 10.4882C55.1108 10.2036 56.1187 10.2015 57.14 10.4819C58.172 10.7505 59.1317 11.2887 60.0191 12.0964C60.9066 12.9041 61.5325 13.8091 61.8967 14.8113C62.2599 15.7909 62.3412 16.7951 62.1404 17.824C61.9385 18.8302 61.4391 19.7711 60.6422 20.6467C59.9852 21.3685 59.2504 21.8788 58.4376 22.1775C57.6356 22.4644 56.8337 22.5134 56.032 22.3246L61.41 27.2194L60.053 28.7104ZM59.2011 19.6272C59.7396 19.0355 60.0774 18.391 60.2145 17.6937C60.3397 16.9856 60.2723 16.2752 60.0123 15.5625C59.7522 14.8499 59.2909 14.192 58.6283 13.5889C57.9656 12.9858 57.2674 12.5883 56.5334 12.3963C55.7995 12.2042 55.0919 12.2092 54.4105 12.4112C53.7172 12.6023 53.1014 12.9937 52.5629 13.5854C52.0244 14.177 51.6925 14.8269 51.5673 15.535C51.4302 16.2324 51.4917 16.9374 51.7517 17.65C52.0118 18.3626 52.4731 19.0205 53.1357 19.6236C53.7984 20.2267 54.4966 20.6243 55.2305 20.8163C55.9645 21.0083 56.678 21.0087 57.3713 20.8175C58.0527 20.6156 58.6626 20.2188 59.2011 19.6272Z"
        fill="#FFC229"
      />
    </svg>
  );
}
