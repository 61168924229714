import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import MyProfileHeroProgress from 'app/components/MyProfile/Hero/Progress';
import Tooltip from 'app/components/Tooltip';
import { PromptIcon, Sparkle, WorldGlobe } from 'app/components/shared/icons';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import useIsMobile from 'hooks/useIsMobile';
import useUserData from 'hooks/useUserData';

import AboutKyuOsWayfindingActivateProfileMenu from './AboutKyuOsWayfindingActivateProfileMenu';
import {
  AboutKyuOsWayfindingActivateProfileButton,
  AboutKyuOsWayfindingActivateProfileButtonsContainer,
  AboutKyuOsWayfindingActivateProfileDescription,
  AboutKyuOsWayfindingActivateProfileMenuContainer,
  AboutKyuOsWayfindingActivateProfileSubtitle,
  AboutKyuOsWayfindingActivateProfileTitle,
  AboutKyuOsWayfindingActivateProfileWrapper,
  AboutKyuOsWayfindingActiveProfileInformationContainer,
  AboutKyuOsWayfindingActiveProfileInformationTextContainer,
  AboutKyyuOsWayfindingActivateProfileButtonText,
} from './styled';

export default function AboutKyuOsWayfindingActivateProfile() {
  const publicProfileCtaClickedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_PUBLIC_PROFILE_CTA_CLICKED
  );
  const reachCtaClickedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_REACH_CTA_CLICKED);
  const { push } = useHistory();
  const isMobile = useIsMobile();
  const userData = useUserData();

  const handleWhatOtherSeeClick = () => {
    publicProfileCtaClickedBehavioralFunction({
      related_object_uuid: userData.uuid,
    });
    push(`/person/${userData.slug}`);
  };

  const handleViewYourConnectionsClick = () => {
    reachCtaClickedBehavioralFunction();
    push(`/my-profile#my-reach`);
  };

  return (
    <AboutKyuOsWayfindingActivateProfileWrapper>
      <AboutKyuOsWayfindingActiveProfileInformationContainer>
        <AboutKyuOsWayfindingActiveProfileInformationTextContainer>
          <AboutKyuOsWayfindingActivateProfileTitle>
            <Sparkle width={40} height={40} fill="var(--color-primary)" />
            Start by activating your kyu OS Profile.
          </AboutKyuOsWayfindingActivateProfileTitle>
          {isMobile ? <MyProfileHeroProgress circularProgressSize={188} avatarSize={75} /> : null}
          <AboutKyuOsWayfindingActivateProfileDescription>
            Your starter profile includes basic information about you: your name, company and job
            title. You need to add richness to your profile through the questions below. When your
            profile is 50% or more completed, it becomes activated.
          </AboutKyuOsWayfindingActivateProfileDescription>
          <AboutKyuOsWayfindingActivateProfileSubtitle>
            PLEASE START BUILDING YOUR PROFILE NOW
          </AboutKyuOsWayfindingActivateProfileSubtitle>
        </AboutKyuOsWayfindingActiveProfileInformationTextContainer>
        {!isMobile ? <MyProfileHeroProgress circularProgressSize={188} avatarSize={75} /> : null}
      </AboutKyuOsWayfindingActiveProfileInformationContainer>
      <AboutKyuOsWayfindingActivateProfileMenuContainer>
        <AboutKyuOsWayfindingActivateProfileMenu />
      </AboutKyuOsWayfindingActivateProfileMenuContainer>
      <AboutKyuOsWayfindingActivateProfileButtonsContainer>
        <AboutKyuOsWayfindingActivateProfileButton onClick={handleWhatOtherSeeClick}>
          <WorldGlobe />
          <AboutKyyuOsWayfindingActivateProfileButtonText>
            WHAT OTHERS SEE
          </AboutKyyuOsWayfindingActivateProfileButtonText>
        </AboutKyuOsWayfindingActivateProfileButton>
        <AboutKyuOsWayfindingActivateProfileButton onClick={handleViewYourConnectionsClick}>
          <PromptIcon />
          <AboutKyyuOsWayfindingActivateProfileButtonText>
            VIEW YOUR CONNECTIONS
          </AboutKyyuOsWayfindingActivateProfileButtonText>
        </AboutKyuOsWayfindingActivateProfileButton>
      </AboutKyuOsWayfindingActivateProfileButtonsContainer>
    </AboutKyuOsWayfindingActivateProfileWrapper>
  );
}
