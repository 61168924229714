import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: 'highlight',
  initialState: {
    highlighted: [],
  },
  reducers: {
    setAllHighlighted: (state, action: { payload: string[] }) => {
      return {
        ...state,
        highlighted: action.payload,
      };
    },
    setHighlighted: (state, action: { payload: string }) => {
      const newHighlighted = state.highlighted.concat([action.payload])
      return {
        ...state,
        highlighted: newHighlighted,
      }
    },
  },
});

export const { setAllHighlighted } = slice.actions;

export const highlightedSelector = (state) => state.highlight.highlighted;

export default slice.reducer;
