import React from 'react';

export default function Map({ width = 16, height = 16, fill = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33333 0.667969C5.20875 0.667969 5.09215 0.702141 4.99239 0.761623L0.335907 3.42247C0.128191 3.54117 0 3.76206 0 4.0013V14.668C0 14.9056 0.126506 15.1253 0.332049 15.2446C0.537592 15.3639 0.791091 15.3647 0.997426 15.2468L5.35431 12.7571L10.3564 15.2582C10.443 15.3038 10.5408 15.3309 10.6446 15.3343C10.652 15.3345 10.6593 15.3346 10.6667 15.3346C10.7913 15.3346 10.9079 15.3005 11.0076 15.241L15.6641 12.5801C15.8718 12.4614 16 12.2405 16 12.0013V1.33464C16 1.09699 15.8735 0.87731 15.668 0.758029C15.4624 0.638748 15.2089 0.637901 15.0026 0.755807L10.6457 3.24546L5.64347 0.744346C5.55083 0.695573 5.4453 0.667969 5.33333 0.667969ZM11.3333 13.5192L14.6667 11.6144V2.48342L11.3333 4.38818V13.5192ZM10 4.41332L6 2.41332V11.5893L10 13.5893V4.41332ZM1.33333 4.38818L4.66667 2.48342V11.6144L1.33333 13.5192V4.38818Z"
        fill={fill}
      />
    </svg>
  );
}
