import {
  IAbstractMapResponse,
  IMapIndustryNodesResponse,
  IMapMemberCompanyNodesResponse,
  IMapPassionNodesResponse,
  IMapResponse,
} from 'app/components/Map/interfaces/map';

import { http } from './http';

export const getCompanyMapDetails = (slug: string) => {
  return http.get<IMapResponse>(`/member_company/${slug}/maps`);
};

export const getCompanyMapDetailsInfo = (slug: string) => {
  return http.get<IMapMemberCompanyNodesResponse>(`/member_company/${slug}/maps/nodes`);
};

export const getCompanyMapStressDetails = (slug: string, params: any) => {
  return http.get<IMapResponse>(`/member_company/${slug}/maps/stress_test`, params);
};

export const getCompanyMapSkillsAndPassions = (slug: string) => {
  return http.get<IMapResponse>(`/member_company/${slug}/maps/skills_passions`);
};

export const getCompanyMapSkillsAndPassionsInfo = (slug: string) => {
  return http.get<any>(`/member_company/${slug}/maps/skills_passions/nodes`);
};

export const getClientDefaultMap = (slug: string) => {
  return http.get<IMapResponse>(`/client/${slug}/maps`);
};

export const getClientDefaultMapInfo = (slug: string) => {
  return http.get<any>(`/client/${slug}/maps/nodes`);
};

export const getAbstractMap = (slug: string, communitySlug?: string) => {
  if (communitySlug) {
    return http.get<IAbstractMapResponse>(
      `/communities/${communitySlug}/maps/abstract_maps/${slug}`
    );
  } else {
    return http.get<IAbstractMapResponse>(`/abstract_maps/${slug}`);
  }
};

export const getAbstractMapInfo = (communitySlug: string, slug: string) => {
  return http.get<any>(`/communities/${communitySlug}/maps/${slug}/nodes`);
};

export const getPassionDefaultMap = (slug: string) => {
  return http.get<IMapResponse>(`/passion/${slug}/maps`);
};

export const getPassionMapInfo = (slug: string) => {
  return http.get<IMapPassionNodesResponse>(`/passion/${slug}/maps/nodes`);
};

export const getPassionDefaultMapPrimaryDrawer = (slug: string) => {
  return http.get<IMapResponse>(`/passion/${slug}/maps/nodes`);
};

export const getKyuSkillDefaultMap = (slug: string) => {
  return http.get<IMapResponse>(`/kyu_skill/${slug}/maps`);
};

export const getKyuSkillDefaultMapInfo = (slug: string) => {
  return http.get<any>(`/kyu_skill/${slug}/maps/nodes`);
};

export const getKyuSkillDefaultMapPrimaryDrawer = (slug: string) => {
  return http.get<IMapResponse>(`/kyu_skill/${slug}/maps/nodes`);
};

export const getProjectDefaultMap = (slug: string) => {
  return http.get<IMapResponse>(`/project/${slug}/maps`);
};

export const getProjectDefaultMapInfo = (slug: string) => {
  return http.get<any>(`/project/${slug}/maps/nodes`);
};

export const getIndustryDefaultMap = (slug: string) => {
  return http.get<IMapResponse>(`/industry/${slug}/maps`);
};

export const getIndustryDefaultMapInfo = (slug: string) => {
  return http.get<IMapIndustryNodesResponse>(`/industry/${slug}/maps/nodes`);
};

export const getPersonDefaultMap = (slug: string) => {
  return http.get<IMapResponse>(`/person/${slug}/maps`);
};

export const getPersonDefaultMapInfo = (slug: string) => {
  return http.get<any>(`/person/${slug}/maps/nodes`);
};

export const getGlobalClientsIndustriesMap = () => {
  return http.get<IMapResponse>(`/global_maps/clients_industries`);
};

export const getGlobalClientsIndustriesMapInfo = () => {
  return http.get<any>(`/global_maps/clients_industries/nodes`);
};

export const getGlobalKyuSkillsMap = () => {
  return http.get<IMapResponse>(`/global_maps/kyu_skills`);
};

export const getGlobalKyuSkillsMapInfo = () => {
  return http.get<any>(`/global_maps/kyu_skills/nodes`);
};

export const getGlobalPassionsMap = () => {
  return http.get<IMapResponse>(`/global_maps/passions`);
};

export const getGlobalPassionsMapInfo = () => {
  return http.get<any>(`/global_maps/passions/nodes`);
};

export const getGlobalMapsCounters = () => {
  return http.get<any>(`/global_maps/counters`);
};
