import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const CollapsibleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 24px;
  
  border-radius: 8px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.10) 100%);
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(20px);

  transition: all 200ms ease-in-out;
  &:hover {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.30) 100%);

    & .icon-container {
      background: rgba(217, 217, 217, 0.5);
    }
  }

  cursor: pointer;
`;

interface CollapsibleTopContainerProps {
  isOpen: boolean;
}

export const CollapsibleTopContainer = styled.div<CollapsibleTopContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ isOpen }) => !isOpen && `
    & .close-icon {
      transform: rotate(45deg);
    }
  `}
`;

export const CollapsibleIconContainer = styled.div`
  width: 42px;
  height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border-radius: 100%;

  transition: all 200ms ease-in-out;
`;

export const CollapsibleTitle = styled.h4`
  font-weight: 400;
  font-size: 24px;
  line-height: normal;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    font-size: 20px;
  }
`;

interface CollapsibleContentContainerProps {
  isOpen: boolean;
}

export const CollapsibleContentContainer = styled.div<CollapsibleContentContainerProps>`
  width: 100%;
  max-height: 0px;
  overflow: hidden;
  transition: all 200ms ease-in-out;

  ${({ isOpen }) => isOpen && `
    max-height: 1000px;
  `}

  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  & > :first-child {
    margin-top: 16px;
  }
`;