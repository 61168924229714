import React from 'react';

import { AboutKyuOsMapImage, AboutKyuOsMapWrapper } from './styled';

export default function AboutKyuOsMap() {
  return (
    <AboutKyuOsMapWrapper>
      <AboutKyuOsMapImage src="/images/about-kyu-os/map.svg" />
    </AboutKyuOsMapWrapper>
  );
}
