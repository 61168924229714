import styled from 'styled-components';
import { unblurAnimation } from 'styles/animations';

export const ComponentErrorWrapper = styled.div`
  position: relative;
`;

interface ComponentErrorContainerProps {
  isBlurred: boolean;
  triggerAnimation?: boolean;
}

export const ComponentErrorContainer = styled.div<ComponentErrorContainerProps>`
  &:before {
    ${({ triggerAnimation }) => triggerAnimation && unblurAnimation}
  }
  ${({ isBlurred }) =>
    isBlurred ?
    `
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(20px);
      }
    ` : ``
    }
`;

export const ComponentErrorOverlay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;

  z-index: 1;

  & > :not(:last-child) {
    margin-bottom: 33px;
  }
`;

export const ComponentErrorOverlayTitle = styled.h3`
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: var(--color-text);
  opacity: 0.5;
`;

export const ComponentErrorOverlayButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 48px;

  border: 2px solid #9CCAFF;
  border-radius: 100px;

  color: #9CCAFF;
`;