import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AboutKyuOsTOC from 'app/components/AboutKyuOs/AboutKyuOsTOC';
import { SortingIcon } from 'app/components/Drawers/People/PeopleDrawer';
import { CloseIcon } from 'app/components/shared/icons';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import useIsMobile from 'hooks/useIsMobile';
import useUserData from 'hooks/useUserData';
import { updateOnboarded } from 'services/onboarding';
import { setIsDrawerOpen } from 'store/reducers/navbar-reducer';
import {
  aboutKyuOsOpenSelector,
  setAboutKyuOsHidden,
  setAboutKyuOsOpen,
} from 'store/reducers/onboarding-reducer';

import {
  AboutKyuOsOnboardingBannerCloseContainer,
  AboutKyuOsOnboardingBannerIconsContainer,
  AboutKyuOsOnboardingBannerSortingContainer,
  AboutKyuOsOnboardingBannerTitle,
  AboutKyuOsOnboardingBannerWrapper,
} from './styled';

export default function AboutKyuOsOnboardingBanner() {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const userData = useUserData();
  const isAboutKyuOsOpen = useSelector(aboutKyuOsOpenSelector);
  const modalDismissedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_MODAL_DISMISSED);
  const modalExpandedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_MODAL_EXPANDED);
  const modalMinimizedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_MODAL_MINIMIZED);

  const navSections = [
    { title: 'NEW TO KYU', hash: 'new-to-kyu' },
    { title: 'CONTACT KYU', hash: 'contact' },
    { title: 'NETWORKING', hash: 'networking' },
    { title: 'FAQ', hash: 'faq' },
  ];

  const handleKyuOsBannerTriggerClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (isAboutKyuOsOpen) {
      modalMinimizedBehavioralFunction();
    } else {
      modalExpandedBehavioralFunction();
    }
    dispatch(setAboutKyuOsOpen(!isAboutKyuOsOpen));
  };

  const handleKyuOsBannerCloseClick = () => {
    modalDismissedBehavioralFunction();
    updateOnboarded({ onboarded: true });
    dispatch(setIsDrawerOpen(false));
    dispatch(setAboutKyuOsHidden(true));
  };

  return (
    <AboutKyuOsOnboardingBannerWrapper onClick={handleKyuOsBannerTriggerClick}>
      <AboutKyuOsOnboardingBannerTitle>KYU 101</AboutKyuOsOnboardingBannerTitle>
      {!isMobile ? (
        <AboutKyuOsTOC
          sections={navSections}
          container={document.querySelector('#about-kyu-os-onboarding')}
          fromOnboarding
        />
      ) : null}
      <AboutKyuOsOnboardingBannerIconsContainer>
        <AboutKyuOsOnboardingBannerSortingContainer onClick={handleKyuOsBannerTriggerClick}>
          <SortingIcon width={24} height={24} />
        </AboutKyuOsOnboardingBannerSortingContainer>
        {!isAboutKyuOsOpen ? (
          <AboutKyuOsOnboardingBannerCloseContainer onClick={handleKyuOsBannerCloseClick}>
            <CloseIcon width={15} height={15} fillOpacity="1" fill="var(--color-primary)" />
          </AboutKyuOsOnboardingBannerCloseContainer>
        ) : null}
      </AboutKyuOsOnboardingBannerIconsContainer>
    </AboutKyuOsOnboardingBannerWrapper>
  );
}
