import React from "react";

const PersonPlaceholder = () => {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="62" height="62" rx="31" fill="#FFC229" />
      <mask
        id="mask0_1930:1599"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="62"
        height="62"
      >
        <rect width="62" height="62" rx="31" fill="#FFC229" />
      </mask>
      <g mask="url(#mask0_1930:1599)"></g>
      <g opacity="0.2">
        <mask
          id="mask1_1930:1599"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="62"
          height="62"
        >
          <mask
            id="mask2_1930:1599"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="62"
            height="62"
          >
            <rect width="62" height="62" rx="31" fill="#FFC229" />
          </mask>
          <g mask="url(#mask2_1930:1599)">
            <rect width="62" height="62" rx="31" fill="#FFC229" />
          </g>
        </mask>
        <g mask="url(#mask1_1930:1599)">
          <path
            d="M30.9931 29.1767C36.0287 29.1767 40.1108 25.0946 40.1108 20.0591C40.1108 15.0235 36.0287 10.9414 30.9931 10.9414C25.9576 10.9414 21.8755 15.0235 21.8755 20.0591C21.8755 25.0946 25.9576 29.1767 30.9931 29.1767Z"
            fill="#1F2025"
          />
          <path
            d="M2.42725 61.9996C2.42725 45.2315 15.5064 31.6074 31.6037 31.6074C47.7011 31.6074 60.7802 45.2315 60.7802 61.9996"
            fill="#1F2025"
          />
          <path d="M4.24609 58.3535L28.5598 82.6672" stroke="black" />
        </g>
      </g>
    </svg>
  );
};

export default PersonPlaceholder;
