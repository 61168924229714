import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import ProfileEditDataTypePill from 'app/components/CommonStyled/ProfileEditDataTypePill';
import ProfileEditInput from 'app/components/CommonStyled/ProfileEditInput';
import { SkillIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { CloseIcon, RightArrow, Sparkle } from 'app/components/shared/icons';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import { createSkill, postSkills } from 'services/profile-editor';
import {
  profileDataSelector,
  setAllSkills,
  setSkills,
} from 'store/reducers/profile-editor/profile-editor-reducer';
import * as Yup from 'yup';

import useProfileEditorSkillsDrawer from '../../hooks/useProfileEditorSkillsDrawer';
import ProfileEditorDrawer, { ProfileEditorDrawerRefProps } from '../Drawer';
import { ProfileEditorDrawerPublishButton, ProfileEditorDrawerTitle } from '../styled';
import {
  ProfileEditorDrawerSkillsBottomText,
  ProfileEditorDrawerSkillsBottomWrapper,
  ProfileEditorDrawerSkillsFeelingStuckAllCategoriesContainer,
  ProfileEditorDrawerSkillsFeelingStuckAllCategory,
  ProfileEditorDrawerSkillsFeelingStuckAllContainer,
  ProfileEditorDrawerSkillsFeelingStuckAllPillsContainer,
  ProfileEditorDrawerSkillsFeelingStuckButton,
  ProfileEditorDrawerSkillsFeelingStuckButtonText,
  ProfileEditorDrawerSkillsFeelingStuckCloseContainer,
  ProfileEditorDrawerSkillsFeelingStuckContainer,
  ProfileEditorDrawerSkillsFeelingStuckRandomContainer,
  ProfileEditorDrawerSkillsFeelingStuckRandomPillsContainer,
  ProfileEditorDrawerSkillsFeelingStuckRandomShowMoreContainer,
  ProfileEditorDrawerSkillsFeelingStuckRandomShowMoreText,
  ProfileEditorDrawerSkillsFeelingStuckRandomTitle,
  ProfileEditorDrawerSkillsFeelingStuckTitle,
  ProfileEditorDrawerSkillsFeelingStuckWrapper,
  ProfileEditorDrawerSkillsHeaderContainer,
  ProfileEditorDrawerSkillsHeaderDescription,
  ProfileEditorDrawerSkillsHeaderWrapper,
  ProfileEditorDrawerSkillsPickedContainer,
  ProfileEditorDrawerSkillsWrapper,
} from './styled';

const MAX_SKILLS = 15;

interface ProfileEditorDrawerSkillsProps {
  onClose: () => void;
}

export default function ProfileEditorDrawerSkills({ onClose }: ProfileEditorDrawerSkillsProps) {
  const { skills, allSkills } = useProfileEditorSkillsDrawer();
  const [optionsPicked, setOptionsPicked] = useState<any[]>(skills);
  const [skillsCreated, setSkillsCreated] = useState<any[]>([]);
  const drawerRef = useRef<ProfileEditorDrawerRefProps>(null);
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<any>>(null);

  const reachedMaxSkills = useMemo(() => {
    return optionsPicked.length === MAX_SKILLS;
  }, [optionsPicked]);

  useEffect(() => {
    setOptionsPicked(skills.map((skill) => ({ ...skill, label: skill.name })));
    formikRef.current.setFieldValue(
      'collection',
      skills.map((skill) => skill.uuid)
    );
  }, [skills]);

  const skillsValidationSchema = Yup.object().shape({
    collection: Yup.array(),
  });

  const options = useMemo(() => {
    return allSkills.map((skill) => ({
      ...skill,
      label: skill.name,
      value: skill.uuid,
    }));
  }, [allSkills]);

  const handleOptionsChanged = (options: any[]) => {
    let newlyCreatedOptions = options.filter((option) => option.isCreated);
    newlyCreatedOptions = [...newlyCreatedOptions, ...skillsCreated];
    setSkillsCreated(newlyCreatedOptions);
    const alreadyCreatedOptions = options.filter((option) => !option.isCreated);
    const allOptions = [...alreadyCreatedOptions, ...newlyCreatedOptions];
    const uniqOptions = allOptions.filter(
      (option, index, self) => index === self.findIndex((t) => t.value === option.value)
    );

    const optionsToSave = uniqOptions.map((skill) => {
      const existingSkill = skills.find((skillItem) => skillItem.uuid === skill.value);

      if (existingSkill) {
        return {
          ...existingSkill,
          ...skill,
        };
      }
      return skill;
    });

    setOptionsPicked(optionsToSave);
  };

  const removeSkill = (skill) => {
    const filteredOptions = optionsPicked.filter((option) => option.value !== skill.value);
    const filteredNewOptions = skillsCreated.filter((skillItem) => skillItem.value !== skill.value);
    setSkillsCreated(filteredNewOptions);

    formikRef.current.setFieldValue(
      'collection',
      filteredOptions.map((option) => option.value)
    );
    setOptionsPicked(filteredOptions);
  };

  const addSkill = (skill) => {
    const newOptions = [...optionsPicked, skill];
    formikRef.current.setFieldValue(
      'collection',
      newOptions.map((option) => option.value)
    );
    handleOptionsChanged(newOptions);
  };

  const handlePickedSkill = (skill) => {
    const clonedSkill = { ...skill };

    if (!clonedSkill.value && clonedSkill.uuid) {
      clonedSkill.value = clonedSkill.uuid;
    }

    const skillExists = optionsPicked.find((option) => option.value === clonedSkill.value);

    if (skillExists) {
      removeSkill(clonedSkill);
    } else if (!reachedMaxSkills) {
      addSkill(clonedSkill);
    }
  };

  const handlePostSkills = async (skills: any) => {
    let newlyCreatedSkills = [];
    const response = await postSkills({ collection: skills });
    if (!response.ok) {
      throw new Error(response.originalError?.message);
    }
    if (skillsCreated.length > 0) {
      newlyCreatedSkills = await Promise.all(
        skillsCreated.map((skill) => createSkill({ name: skill.value }))
      );

      newlyCreatedSkills = newlyCreatedSkills.map((skill) => ({
        uuid: skill.data.uuid,
        value: skill.data.uuid,
        label: skill.data.name,
        name: skill.data.name,
        isCreated: false,
        approved: false,
      }));
    }

    const existingSkills = optionsPicked.filter((option) => !option.isCreated);

    const allAvailableSkills = [...allSkills, ...newlyCreatedSkills];
    dispatch(setAllSkills(allAvailableSkills));
    dispatch(setSkills([...existingSkills, ...newlyCreatedSkills]));
    return response.data;
  };

  const { mutate } = useMutation(handlePostSkills, drawerRef.current?.handleMutation);

  const handleSubmit = (values) => {
    if (formikRef.current.dirty) {
      mutate(values.collection);
    } else {
      drawerRef.current.close();
    }
  };

  return (
    <Formik
      initialValues={{
        collection: skills.map((skill) => skill.uuid),
      }}
      validationSchema={skillsValidationSchema}
      innerRef={formikRef}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form>
          <ProfileEditorDrawer
            header={<ProfileEditorDrawerSkillsHeader />}
            bottom={<ProfileEditorDrawerSkillsBottom optionsPicked={optionsPicked} />}
            onClose={onClose}
            needsSaving={props.dirty}
            ref={drawerRef}
          >
            <ProfileEditorDrawerSkillsWrapper>
              <ProfileEditInput
                {...props}
                name={'collection'}
                label={'Which skillsets do you use the most and/or want to be known for having?'}
                tooltip={
                  "You can search and select skills from the dropdown menu. If you can’t find the skill you’re looking for, you can create a skill and add it to kyu OS. New skills are reviewed for typos and similarities by the Creative Intelligence team and they'll display on your profile once approved."
                }
                disabled={reachedMaxSkills}
                type={'select'}
                placeholder={'Start searching...'}
                options={options}
                onOptionsChanged={handleOptionsChanged}
                settings={{
                  allowCreate: true,
                }}
              />
              <ProfileEditorDrawerSkillsFeelingStuck
                handlePickedSkill={handlePickedSkill}
                optionsPicked={optionsPicked}
              />
              <ProfileEditorDrawerSkillsPickedContainer>
                {optionsPicked.map((skill, index) => (
                  <ProfileEditDataTypePill
                    showDeleteOnHover
                    key={`${skill.label}-${index}`}
                    type="selected"
                    label={skill.label}
                    approved={skill.approved}
                    peopleCount={skill.people_count}
                    onClick={() => handlePickedSkill(skill)}
                  />
                ))}
              </ProfileEditorDrawerSkillsPickedContainer>
            </ProfileEditorDrawerSkillsWrapper>
          </ProfileEditorDrawer>
        </Form>
      )}
    </Formik>
  );
}

function ProfileEditorDrawerSkillsHeader() {
  return (
    <ProfileEditorDrawerSkillsHeaderWrapper>
      <ProfileEditorDrawerSkillsHeaderContainer>
        <SkillIcon width={24} height={24} fill={'var(--color-primary)'} />
        <ProfileEditorDrawerTitle>Skills</ProfileEditorDrawerTitle>
      </ProfileEditorDrawerSkillsHeaderContainer>
      <ProfileEditorDrawerSkillsHeaderDescription>
        Sharing your skills will help find common ground between you and others around the
        Collective. Your skills will also help kyu OS recommend communities, events, articles and
        highlight projects for you. When your profile is viewed, these skills will be grouped into
        categories that people across the Collective also share.
      </ProfileEditorDrawerSkillsHeaderDescription>
    </ProfileEditorDrawerSkillsHeaderWrapper>
  );
}

interface ProfileEditorDrawerSkillsBottomProps {
  optionsPicked: any[];
}

function ProfileEditorDrawerSkillsBottom({ optionsPicked }: ProfileEditorDrawerSkillsBottomProps) {
  const { isValid, submitForm, isSubmitting } = useFormikContext();

  return (
    <ProfileEditorDrawerSkillsBottomWrapper>
      <ProfileEditorDrawerSkillsBottomText reachedMax={optionsPicked?.length > MAX_SKILLS}>
        {optionsPicked?.length} of {MAX_SKILLS} selected
      </ProfileEditorDrawerSkillsBottomText>
      <ProfileEditorDrawerPublishButton
        disabled={!isValid || isSubmitting}
        type="submit"
        onClick={submitForm}
      >
        Publish
      </ProfileEditorDrawerPublishButton>
    </ProfileEditorDrawerSkillsBottomWrapper>
  );
}

interface ProfileEditorDrawerSkillsFeelingStuckProps {
  handlePickedSkill: (skill: any) => void;
  optionsPicked: any[];
}

function ProfileEditorDrawerSkillsFeelingStuck({
  handlePickedSkill,
  optionsPicked,
}: ProfileEditorDrawerSkillsFeelingStuckProps) {
  const [currentlyShowing, setCurrentlyShowing] = useState<'random' | 'all'>(null);

  if (currentlyShowing === 'random') {
    return (
      <ProfileEditorDrawerSkillsFeelingStuckRandom
        setCurrentlyShowing={setCurrentlyShowing}
        handlePickedSkill={handlePickedSkill}
        optionsPicked={optionsPicked}
      />
    );
  }

  if (currentlyShowing === 'all') {
    return (
      <ProfileEditorDrawerSkillsFeelingStuckAll
        setCurrentlyShowing={setCurrentlyShowing}
        handlePickedSkill={handlePickedSkill}
        optionsPicked={optionsPicked}
      />
    );
  }

  return (
    <ProfileEditorDrawerSkillsFeelingStuckWrapper>
      <ProfileEditorDrawerSkillsFeelingStuckTitle>
        Feeling Stuck?
      </ProfileEditorDrawerSkillsFeelingStuckTitle>
      <ProfileEditorDrawerSkillsFeelingStuckContainer>
        <ProfileEditorDrawerSkillsFeelingStuckButton onClick={() => setCurrentlyShowing('random')}>
          <Sparkle width={24} height={24} fill={'var(--color-primary)'} />
          <ProfileEditorDrawerSkillsFeelingStuckButtonText>
            Randomize
          </ProfileEditorDrawerSkillsFeelingStuckButtonText>
        </ProfileEditorDrawerSkillsFeelingStuckButton>
        <ProfileEditorDrawerSkillsFeelingStuckButton onClick={() => setCurrentlyShowing('all')}>
          <RightArrow width={12} height={12} fill={'var(--color-primary)'} />
          <ProfileEditorDrawerSkillsFeelingStuckButtonText>
            See All
          </ProfileEditorDrawerSkillsFeelingStuckButtonText>
        </ProfileEditorDrawerSkillsFeelingStuckButton>
      </ProfileEditorDrawerSkillsFeelingStuckContainer>
    </ProfileEditorDrawerSkillsFeelingStuckWrapper>
  );
}

interface ProfileEditorDrawerSkillsFeelingStuckOptionProps {
  setCurrentlyShowing: (value: 'random' | 'all') => void;
  handlePickedSkill: (skill: any) => void;
  optionsPicked: any[];
}

function ProfileEditorDrawerSkillsFeelingStuckRandom({
  handlePickedSkill,
  setCurrentlyShowing,
  optionsPicked,
}: ProfileEditorDrawerSkillsFeelingStuckOptionProps) {
  const { allSkills } = useSelector(profileDataSelector);
  const containerRef = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);
  const reachedMaxSkills = useMemo(() => {
    return optionsPicked.length === MAX_SKILLS;
  }, [optionsPicked]);

  const randomSkills = useMemo(() => {
    const randomSkills = [];
    const randomSkillsIndexes = [];
    while (randomSkills.length < 7 || randomSkills.length === allSkills.length) {
      const randomIndex = Math.floor(Math.random() * allSkills.length);
      if (!randomSkillsIndexes.includes(randomIndex)) {
        randomSkillsIndexes.push(randomIndex);
        randomSkills.push(allSkills[randomIndex]);
      }
    }
    return randomSkills;
  }, [allSkills]);

  return (
    <ProfileEditorDrawerSkillsFeelingStuckRandomContainer ref={containerRef}>
      <ProfileEditorDrawerSkillsFeelingStuckCloseContainer
        onClick={() => setCurrentlyShowing(null)}
      >
        <CloseIcon width={10} height={10} fill={'var(--color-primary)'} />
      </ProfileEditorDrawerSkillsFeelingStuckCloseContainer>
      <ProfileEditorDrawerSkillsFeelingStuckRandomTitle>
        YOU MIGHT ALSO BE INTERESTED IN
      </ProfileEditorDrawerSkillsFeelingStuckRandomTitle>
      <ProfileEditorDrawerSkillsFeelingStuckRandomPillsContainer>
        {randomSkills.map((skill, i) => {
          const index = optionsPicked.findIndex((option) => option.uuid === skill.uuid);

          return (
            <ProfileEditDataTypePill
              key={`${skill.name}-${i}`}
              type={index !== -1 ? 'selected' : reachedMaxSkills ? 'disabled' : 'unselected'}
              label={skill.name}
              approved={skill.approved}
              peopleCount={skill.people_count}
              onClick={() => handlePickedSkill(skill)}
            />
          );
        })}
      </ProfileEditorDrawerSkillsFeelingStuckRandomPillsContainer>
      <ProfileEditorDrawerSkillsFeelingStuckRandomShowMoreContainer>
        <Sparkle width={24} height={24} fill={'var(--color-primary)'} />
        <ProfileEditorDrawerSkillsFeelingStuckRandomShowMoreText
          onClick={() => setCurrentlyShowing('all')}
        >
          SHOW MORE
        </ProfileEditorDrawerSkillsFeelingStuckRandomShowMoreText>
      </ProfileEditorDrawerSkillsFeelingStuckRandomShowMoreContainer>
    </ProfileEditorDrawerSkillsFeelingStuckRandomContainer>
  );
}

function ProfileEditorDrawerSkillsFeelingStuckAll({
  handlePickedSkill,
  setCurrentlyShowing,
  optionsPicked,
}: ProfileEditorDrawerSkillsFeelingStuckOptionProps) {
  const { allSkills } = useSelector(profileDataSelector);
  const [currentCategory, setCurrentCategory] = useState('A-C');
  const containerRef = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);
  const reachedMaxSkills = useMemo(() => {
    return optionsPicked.length === MAX_SKILLS;
  }, [optionsPicked]);

  const sortedSkills = useMemo(() => {
    const categorizedObjects = {
      'A-C': [],
      'D-F': [],
      'G-I': [],
      'J-L': [],
      'M-P': [],
      'Q-S': [],
      'T-V': [],
      'W-Z': [],
    };

    for (const object of allSkills) {
      const firstLetter = object.name[0].toUpperCase();
      if (firstLetter >= 'A' && firstLetter <= 'C') {
        categorizedObjects['A-C'].push(object);
      } else if (firstLetter >= 'D' && firstLetter <= 'F') {
        categorizedObjects['D-F'].push(object);
      } else if (firstLetter >= 'G' && firstLetter <= 'I') {
        categorizedObjects['G-I'].push(object);
      } else if (firstLetter >= 'J' && firstLetter <= 'L') {
        categorizedObjects['J-L'].push(object);
      } else if (firstLetter >= 'M' && firstLetter <= 'P') {
        categorizedObjects['M-P'].push(object);
      } else if (firstLetter >= 'Q' && firstLetter <= 'S') {
        categorizedObjects['Q-S'].push(object);
      } else if (firstLetter >= 'T' && firstLetter <= 'V') {
        categorizedObjects['T-V'].push(object);
      } else if (firstLetter >= 'W' && firstLetter <= 'Z') {
        categorizedObjects['W-Z'].push(object);
      }
    }

    return categorizedObjects;
  }, [allSkills]);

  return (
    <ProfileEditorDrawerSkillsFeelingStuckAllContainer ref={containerRef}>
      <ProfileEditorDrawerSkillsFeelingStuckCloseContainer
        onClick={() => setCurrentlyShowing(null)}
      >
        <CloseIcon width={10} height={10} fill={'var(--color-primary)'} />
      </ProfileEditorDrawerSkillsFeelingStuckCloseContainer>
      <ProfileEditorDrawerSkillsFeelingStuckAllCategoriesContainer>
        {Object.keys(sortedSkills).map((key, index) => {
          if (sortedSkills[key].length === 0) return <></>;

          return (
            <ProfileEditorDrawerSkillsFeelingStuckAllCategory
              key={`${key}-${index}`}
              onClick={() => setCurrentCategory(key)}
              current={currentCategory === key}
            >
              {key}
            </ProfileEditorDrawerSkillsFeelingStuckAllCategory>
          );
        })}
      </ProfileEditorDrawerSkillsFeelingStuckAllCategoriesContainer>
      <ProfileEditorDrawerSkillsFeelingStuckAllPillsContainer>
        {sortedSkills[currentCategory].map((skill, i) => {
          const index = optionsPicked.findIndex((option) => option.uuid === skill.uuid);

          return (
            <ProfileEditDataTypePill
              key={`${skill.name}-${i}`}
              type={index !== -1 ? 'selected' : reachedMaxSkills ? 'disabled' : 'unselected'}
              label={skill.name}
              approved={skill.approved}
              peopleCount={skill.people_count}
              onClick={() => handlePickedSkill(skill)}
            />
          );
        })}
      </ProfileEditorDrawerSkillsFeelingStuckAllPillsContainer>
    </ProfileEditorDrawerSkillsFeelingStuckAllContainer>
  );
}
