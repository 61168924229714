import React, { useContext, useMemo } from 'react';

import useWindowResizeWithRef from 'hooks/useWindowReiszeWithRef';

import { SearchContext } from '../SearchContext';
import SearchIndexPIlls from '../SearchIndexPIlls';
import SearchList from '../SearchList';
import {
  SearchIndexPillsContainer,
  SearchSubMenuBottomContainer,
  SearchSubMenuContainer,
  SearchSubMenuDivider,
  SearchSubMenuSubHeader,
  SearchSubMenuTitle,
  SearchSubMenuTitleContainer,
} from './styled';

export default function SearchSubmenu() {
  const context = useContext(SearchContext);

  const pillsContainerRef = useWindowResizeWithRef((node) => {
    if (!node) return;

    const secondStepOnboardingElement = document.querySelector(
      `#search-onboarding-second-step-frame-${context.context}`
    );

    if (secondStepOnboardingElement) {
      const pillsContainerRect = node.getBoundingClientRect();

      secondStepOnboardingElement.setAttribute(
        'style',
        `top: calc(${pillsContainerRect.top}px - 8px); left: -8px; width: calc(${pillsContainerRect.width}px + 16px); height: calc(${pillsContainerRect.height}px + 8px);`
      );
    }
  });

  const inputHasValue = useMemo(() => {
    return context.inputValue && context.inputValue.length > 0;
  }, [context.inputValue]);

  return (
    <SearchSubMenuContainer>
      {!inputHasValue ? (
        <SearchSubMenuSubHeader>
          <SearchSubMenuTitleContainer>
            <SearchSubMenuTitle>HIGHLIGHTED SEARCHES</SearchSubMenuTitle>
          </SearchSubMenuTitleContainer>
          <SearchSubMenuDivider />
        </SearchSubMenuSubHeader>
      ) : !context.isLoading ? (
        <SearchSubMenuSubHeader>
          <SearchSubMenuTitleContainer>
            <SearchSubMenuTitle>
              {context.searchResultsAmount}{' '}
              {context.searchResultsAmount !== 1 ? 'RESULTS' : 'RESULT'}
            </SearchSubMenuTitle>
          </SearchSubMenuTitleContainer>
          <SearchSubMenuDivider />
        </SearchSubMenuSubHeader>
      ) : null}
      <SearchList />
      <SearchSubMenuBottomContainer ref={pillsContainerRef}>
        <SearchSubMenuSubHeader>
          <SearchSubMenuTitle>VIEW ALL</SearchSubMenuTitle>
          <SearchSubMenuDivider />
        </SearchSubMenuSubHeader>
        <SearchIndexPillsContainer>
          <SearchIndexPIlls />
        </SearchIndexPillsContainer>
      </SearchSubMenuBottomContainer>
    </SearchSubMenuContainer>
  );
}
