import styled from 'styled-components';

export const SelectWrapper = styled.div`
  width: fit-content;

  display: flex;
  flex-direction: row;

  & > :first-child {
    margin-right: 8px;
  }

  & > :not(:last-child):not(:first-child) {
    margin-right: 4px;
  }

  cursor: pointer;
  position: relative;
`;

export const SelectLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: rgba(23, 28, 51, 0.5);
`;

export const SelectCurrentValue = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: var(--color-text);
`;

export const SelectOptionsContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  width: max-content;

  display: flex;
  flex-direction: column;

  & > :first-child {
    padding: 8px 8px 4px 8px;
    border-radius: 8px 8px 0px 0px;
  }

  & > :not(:first-child) {
    padding: 4px 8px;
  }

  & > :last-child {
    padding: 4px 8px 8px 8px;
    border-radius: 0px 0px 8px 8px;
  }

  margin: 0px !important;
`;
