import React from 'react';

export default function DataHealthIcon({ width = 22, height = 22, fill = '#FFFFFF' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.375 2.25C8.75163 2.25 9.086 2.491 9.2051 2.8483L13.625 16.108L15.4199 10.7233C15.539 10.366 15.8734 10.125 16.25 10.125H19.75C20.2332 10.125 20.625 10.5168 20.625 11C20.625 11.4832 20.2332 11.875 19.75 11.875H16.8807L14.4551 19.1517C14.336 19.509 14.0016 19.75 13.625 19.75C13.2484 19.75 12.914 19.509 12.7949 19.1517L8.375 5.89199L6.5801 11.2767C6.461 11.634 6.12663 11.875 5.75 11.875H2.25C1.76675 11.875 1.375 11.4832 1.375 11C1.375 10.5168 1.76675 10.125 2.25 10.125H5.11934L7.5449 2.8483C7.664 2.491 7.99837 2.25 8.375 2.25Z" fill={fill}/>
    </svg>

  );
}
