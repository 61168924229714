import React from 'react';

export default function ChevronUpDownIcon({ width = 24, height = 24, fill = '#000000' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 9L12 5L16 9" stroke={fill} strokeWidth="2" strokeLinecap="round" />
      <path d="M8 15L12 19L16 15" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
