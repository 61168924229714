import React, { useMemo, useState } from 'react';

import CompaniesArray from 'app/components/CommonStyled/CompaniesArray';
import CompanyLogo from 'app/components/CommonStyled/CompanyLogo';
import DataTypePill from 'app/components/CommonStyled/DataTypePill/DataTypePill';
import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import SideDrawer from 'app/components/CommonStyled/SideDrawer';
import GlobalPersonDrawer from 'app/components/Drawers/GlobalPerson/GlobalPersonDrawer';
import Tooltip from 'app/components/Tooltip';
import { FullHeartIcon, PaywallIcon } from 'app/components/shared/icons';
import dayjs from 'dayjs';
import useBehavioral from 'hooks/useBehavioral';
import useIsLiked from 'hooks/useIsLiked';
import { arrayToOxford, htmlToPlainString } from 'utils';

import { QuickFilterType, typeToIcon, typeToTitle } from '../constants';
import { useResourceClick } from '../hooks/useResourceClick';
import {
  ResourceCardCompaniesContainer,
  ResourceCardContainer,
  ResourceCardDescription,
  ResourceCardExtraInformationCreatedBy,
  ResourceCardExtraInformationDateLabel,
  ResourceCardExtraInformationRow,
  ResourceCardExtraInformationWrapper,
  ResourceCardIconContainer,
  ResourceCardImage,
  ResourceCardImageContainer,
  ResourceCardImageLikesContainer,
  ResourceCardImageLikesText,
  ResourceCardLikesContainer,
  ResourceCardLink,
  ResourceCardLinkAndPaywallContainer,
  ResourceCardLinkContainer,
  ResourceCardLinkFavicon,
  ResourceCardPaywallContent,
  ResourceCardPeopleContainer,
  ResourceCardPeopleCounter,
  ResourceCardPeopleCounterContainer,
  ResourceCardPeopleSinglePersonContainer,
  ResourceCardPeopleSinglePersonName,
  ResourceCardTag,
  ResourceCardTagsContainer,
  ResourceCardTitle,
  ResourceCardWrapper,
} from './styled';

export interface IResourceCompany {
  name: string;
  slug: string;
}

export interface IResourcePerson {
  slug: string;
  name: string;
  avatar: string;
  role?: string;
  company?: IResourceCompany;
  is_alumni?: boolean;
  hide_alumni_label?: boolean;
}

export interface IResourceContributor {
  people: IResourcePerson[];
  companies: IResourceCompany[];
  communities: IResourceCommunity[];
}

export interface IResourceCommunity {
  slug: string;
  name: string;
}

export enum ResourceType {
  LINK = 'News / Blog / Misc.',
  VIMEO = 'Vimeo / Youtube',
  IMAGE = 'Image',
  PDF = 'PDF',
  GOOGLE_STUDIO = 'Google Studio',
  GOOGLE_SLIDES = 'Google Slides',
  READYMAG = 'Readymag',
  KYUOS_LINK = 'kyu OS',
}

export type IResourceType = `${ResourceType}`;

export interface IResourceLikes {
  count: number;
  people: {
    samples: IResourcePerson[];
    uuids: string[];
  };
}

export interface IResourceSubmissionCategory {
  name: string;
  slug: string;
}

export interface IResource {
  uuid: string;
  slug: string;
  title: string;
  submitted_at?: string;
  has_paywall: boolean;
  short_description?: string;
  resource_type_name?: string;
  resource_category?: QuickFilterType;
  resource_type?: IResourceType;
  long_description?: string;
  image?: string;
  link?: string;
  link_text?: string;
  tags: string[];
  contributors: IResourceContributor;
  submitter: any;
  header_size?: 'small' | 'medium' | 'large';
  url?: string;
  related_resources?: IResource[];
  related_pages?: any[];
  go_to_detail_page?: boolean;
  likes?: IResourceLikes;
  liked_by_you?: boolean;
  voted?: boolean;
  available_to_vote?: boolean;
  resource_submission_category: IResourceSubmissionCategory;
}

interface ResourceCardProps {
  resource: IResource;
  onTagClick?: (tag: string) => void;
  onCategoryClick?: (category: string) => void;
  prioritizedTags?: string[];
  behavioralOnClick?: () => void;
  overrideResourceClick?: (resource: IResource) => void;
}

export default function ResourceCard({
  resource,
  onTagClick,
  onCategoryClick,
  prioritizedTags,
  behavioralOnClick,
  overrideResourceClick,
}: ResourceCardProps) {
  const resourceDetailClickedFunction = useBehavioral('RESOURCE_DETAIL_CLICKED');
  const resourceOpenFunction = useBehavioral('RESOURCE_OPENED');
  const isLiked = useIsLiked(resource.slug, resource.likes?.people.uuids);

  const handleResourceClick = useResourceClick();

  const handleTagClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, tag: string) => {
    e.stopPropagation();
    onTagClick?.(tag);
    window.scrollTo(0, 0);
  };

  const handleCategoryClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    category: string
  ) => {
    e.stopPropagation();
    onCategoryClick?.(category);
    window.scrollTo(0, 0);
  };

  const handleResourceCardClick = () => {
    if (overrideResourceClick) {
      return overrideResourceClick(resource);
    }

    const behavioralFunction = () => {
      if (
        [
          ResourceType.VIMEO,
          ResourceType.IMAGE,
          ResourceType.GOOGLE_SLIDES,
          ResourceType.GOOGLE_STUDIO,
          ResourceType.READYMAG,
        ].includes(resource.resource_type_name as ResourceType) ||
        !resource.go_to_detail_page
      ) {
        return resourceOpenFunction({
          resource_id: resource.slug,
        });
      }

      return resourceDetailClickedFunction({
        resource_id: resource.slug,
      });
    };

    handleResourceClick({
      go_to_detail_page: resource.go_to_detail_page,
      resource_type_name: resource.resource_type_name,
      slug: resource.slug,
      link: resource.link,
      behavioralAction: behavioralOnClick || behavioralFunction,
    });
  };

  const orderedTags = useMemo(() => {
    if (!prioritizedTags) return resource.tags;

    const prioritizedTagsSet = new Set(prioritizedTags);
    const orderedTagsTemp = resource.tags.sort((a, b) => {
      if (prioritizedTagsSet.has(a) && prioritizedTagsSet.has(b)) {
        return prioritizedTags.indexOf(a) - prioritizedTags.indexOf(b);
      }

      if (prioritizedTagsSet.has(a)) {
        return -1;
      }

      if (prioritizedTagsSet.has(b)) {
        return 1;
      }

      return 0;
    });

    return orderedTagsTemp;
  }, [prioritizedTags, resource.tags]);

  const hasContributors = useMemo<boolean>(() => {
    return (
      resource.contributors.companies.length > 0 ||
      resource.contributors.people.length > 0 ||
      resource.contributors.communities.length > 0
    );
  }, [resource]);

  return (
    <ResourceCardWrapper onClick={handleResourceCardClick} data-slug={resource?.slug}>
      <ResourceCardContainer
        withLikes={resource.likes?.count > 0 && !resource.image}
        data-id="resource-card-container"
      >
        {resource.resource_category ? (
          <Tooltip
            title="Click to filter by this category"
            text={typeToTitle.get(resource.resource_category.toLowerCase() as QuickFilterType)}
          >
            <ResourceCardIconContainer
              onClick={(e) => handleCategoryClick(e, resource.resource_category.toLowerCase())}
              data-id="resource-card-category-icon"
            >
              {React.cloneElement(
                typeToIcon.get(resource.resource_category.toLowerCase() as QuickFilterType),
                {
                  width: 22,
                  height: 22,
                }
              )}
            </ResourceCardIconContainer>
          </Tooltip>
        ) : null}
        {resource.image ? (
          <ResourceCardImageContainer>
            {resource.likes && resource.likes?.count > 0 ? (
              <ResourceCardImageLikesContainer data-id="resource-card-likes">
                <FullHeartIcon
                  fill={isLiked ? '#D47563' : 'none'}
                  border={isLiked ? '#D47563' : 'rgba(23, 28, 51, 0.5)'}
                />
                <ResourceCardImageLikesText>{resource.likes?.count}</ResourceCardImageLikesText>
              </ResourceCardImageLikesContainer>
            ) : null}
            <ResourceCardImage src={resource.image} loading="lazy" />
          </ResourceCardImageContainer>
        ) : (resource.likes && resource.likes?.count > 0) ||
          (resource.likes?.count === 0 && isLiked) ? (
          <ResourceCardLikesContainer data-id="resource-card-likes">
            <FullHeartIcon
              fill={isLiked ? '#D47563' : 'none'}
              border={isLiked ? '#D47563' : 'rgba(23, 28, 51, 0.5)'}
            />
            <ResourceCardImageLikesText>
              {resource.likes?.count === 0 && isLiked ? 1 : resource.likes?.count}
            </ResourceCardImageLikesText>
          </ResourceCardLikesContainer>
        ) : null}
        <ResourceCardTitle>{resource.title}</ResourceCardTitle>
        <ResourceCardDescription>
          {htmlToPlainString(resource.short_description)}
        </ResourceCardDescription>
        <ResourceCardTagsContainer data-id="resource-card-tags">
          {orderedTags?.slice(0, 2).map((tag) => (
            <Tooltip key={tag} text="Click to filter by this tag">
              <ResourceCardTag onClick={(e) => handleTagClick(e, tag)}>{tag}</ResourceCardTag>
            </Tooltip>
          ))}
          {orderedTags?.length - 2 > 0 ? (
            <Tooltip text={arrayToOxford(orderedTags?.slice(2))}>
              <ResourceCardTag>{orderedTags?.length - 2}</ResourceCardTag>
            </Tooltip>
          ) : null}
        </ResourceCardTagsContainer>
        <ResourceCardLinkAndPaywallContainer>
          {resource.link ? <ResourceCardExternalLink resource={resource} /> : null}
          {resource.has_paywall ? (
            <Tooltip text="There may be a paywall to access this resource. Contact the resource submitter or creator(s) for more information.">
              <ResourceCardPaywallContent>
                <PaywallIcon />
              </ResourceCardPaywallContent>
            </Tooltip>
          ) : null}
        </ResourceCardLinkAndPaywallContainer>
      </ResourceCardContainer>
      <ResourceCardExtraInformationWrapper data-id="resource-card-extra-information">
        {hasContributors || resource.submitter ? (
          <ResourceCardExtraInformationRow>
            <ResourceCardExtraInformationCreatedBy>
              {resource.submitter && !hasContributors ? 'Submitted by' : 'Created by'}
            </ResourceCardExtraInformationCreatedBy>
            {dayjs(resource.submitted_at).isValid() ? (
              <ResourceCardExtraInformationDateLabel data-id="resource-card-extra-information-date">
                {dayjs(resource.submitted_at).format('MMM DD, YYYY')}
              </ResourceCardExtraInformationDateLabel>
            ) : null}
          </ResourceCardExtraInformationRow>
        ) : (
          <ResourceCardExtraInformationRow>
            <ResourceCardExtraInformationCreatedBy>
              Submitted on
            </ResourceCardExtraInformationCreatedBy>
            {dayjs(resource.submitted_at).isValid() ? (
              <ResourceCardExtraInformationDateLabel data-id="resource-card-extra-information-date">
                {dayjs(resource.submitted_at).format('MMM DD, YYYY')}
              </ResourceCardExtraInformationDateLabel>
            ) : null}
          </ResourceCardExtraInformationRow>
        )}
        <ResourceCardExtraInformationRow onClick={(e) => e.stopPropagation()}>
          <ResourceCardPeople resource={resource} />
          <ResourceCardCompaniesOrCommunity resource={resource} />
        </ResourceCardExtraInformationRow>
      </ResourceCardExtraInformationWrapper>
    </ResourceCardWrapper>
  );
}

interface ResourceCardExternalLinkProps {
  resource: IResource;
}

function ResourceCardExternalLink({ resource }: ResourceCardExternalLinkProps) {
  const finalLink = useMemo(() => {
    return resource.link
      .replace('http://', '')
      .replace('https://', '')
      .replace('www.', '')
      .split(/[/?#]/)[0];
  }, [resource.link]);

  const favicon = useMemo(
    () =>
      resource.link &&
      (resource.link.indexOf('http://') == 0 || resource.link.indexOf('https://') == 0)
        ? 'https://s2.googleusercontent.com/s2/favicons?domain=' +
          resource.link.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
        : '/icons/kyu.ico',
    [resource.link]
  );

  return (
    <ResourceCardLinkContainer>
      <ResourceCardLinkFavicon src={favicon} />
      <ResourceCardLink>{finalLink}</ResourceCardLink>
    </ResourceCardLinkContainer>
  );
}

interface ResourceCardPeopleProps {
  resource: IResource;
}

function ResourceCardPeople({ resource }: ResourceCardPeopleProps) {
  const resourceCardSubmitterClickedBehavioral = useBehavioral('RESOURCE_CARD_SUBMITTER_CLICKED');
  const reosurceCardCreatorClickedBehavioral = useBehavioral('RESOURCE_CARD_CREATOR_CLICKED');
  const [drawer, setDrawer] = useState({ open: false, slug: null });
  const {
    submitter,
    contributors: { people, communities, companies },
  } = resource;

  const noContributors = useMemo(() => {
    return people.length === 0 && communities.length === 0 && companies.length === 0;
  }, [resource]);

  const remainingPeopleNames = useMemo(() => {
    if (!people) return [];
    return people.slice(3).map((person) => person.name);
  }, [resource]);

  const handleOpenDrawer = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, slug: string) => {
    e.stopPropagation();
    setDrawer({ open: true, slug });
  };

  const handleCloseDrawer = () => {
    setDrawer({ open: false, slug: null });
  };

  if (noContributors && submitter)
    return (
      <ResourceCardPeopleSinglePersonContainer onClick={(e) => handleOpenDrawer(e, submitter.slug)}>
        <PersonAvatar
          slug={submitter.slug}
          avatar={submitter.avatar}
          size={22}
          name={submitter.name.split(' ')[0]}
          lastName={submitter.name.substring(submitter.name.indexOf(' ') + 1)}
          hideTooltip={false}
          highlighted
          openDrawer
          drawerBehavioralAction={() =>
            resourceCardSubmitterClickedBehavioral({ related_object_uuid: submitter.slug })
          }
        />
        <ResourceCardPeopleSinglePersonName>{submitter.name}</ResourceCardPeopleSinglePersonName>
        {drawer.open ? (
          <SideDrawer closeDrawer={handleCloseDrawer} withClose>
            <GlobalPersonDrawer slug={drawer.slug} />
          </SideDrawer>
        ) : null}
      </ResourceCardPeopleSinglePersonContainer>
    );

  if (noContributors) return <></>;

  if (!people.length) return <></>;

  if (people.length === 1) {
    return (
      <ResourceCardPeopleSinglePersonContainer onClick={(e) => handleOpenDrawer(e, people[0].slug)}>
        <PersonAvatar
          slug={people[0].slug}
          avatar={people[0].avatar}
          size={22}
          name={people[0].name.split(' ')[0]}
          lastName={people[0].name.substring(people[0].name.indexOf(' ') + 1)}
          hideTooltip={false}
          openDrawer
          highlighted
          drawerBehavioralAction={() =>
            reosurceCardCreatorClickedBehavioral({ related_object_uuid: people[0].slug })
          }
        />
        <ResourceCardPeopleSinglePersonName>{people[0].name}</ResourceCardPeopleSinglePersonName>
        {drawer.open ? (
          <SideDrawer closeDrawer={handleCloseDrawer} withClose>
            <GlobalPersonDrawer slug={drawer.slug} />
          </SideDrawer>
        ) : null}
      </ResourceCardPeopleSinglePersonContainer>
    );
  }

  return (
    <ResourceCardPeopleContainer hasMore={resource.contributors.people?.length > 3}>
      {resource.contributors.people?.length > 3 ? (
        <Tooltip text={arrayToOxford(remainingPeopleNames)}>
          <ResourceCardPeopleCounterContainer>
            <ResourceCardPeopleCounter>
              {resource.contributors.people?.length - 3}
            </ResourceCardPeopleCounter>
          </ResourceCardPeopleCounterContainer>
        </Tooltip>
      ) : null}
      {people.slice(0, 3).map((person, index) => (
        <PersonAvatar
          slug={person.slug}
          key={`${person.slug}-${index}`}
          avatar={person.avatar}
          size={22}
          name={person.name.split(' ')[0]}
          lastName={person.name.substring(person.name.indexOf(' ') + 1)}
          hideTooltip={false}
          highlighted
          openDrawer
          drawerBehavioralAction={() =>
            reosurceCardCreatorClickedBehavioral({ related_object_uuid: person.slug })
          }
        />
      ))}
    </ResourceCardPeopleContainer>
  );
}

interface ResourceCardCompaniesOrCommunityProps {
  resource: IResource;
}

function ResourceCardCompaniesOrCommunity({ resource }: ResourceCardCompaniesOrCommunityProps) {
  const noPeople = useMemo<boolean>(() => {
    return resource.contributors.people?.length === 0;
  }, [resource]);

  const isKyu = useMemo(() => {
    return resource.contributors.companies.some((company) => company.slug === 'kyu');
  }, [resource]);

  const companies = useMemo(() => {
    if (!resource.contributors.companies) return [];
    return resource.contributors.companies.slice(0, 3);
  }, [resource]);

  if (resource.contributors.communities?.length > 0) {
    return (
      <DataTypePill
        type="community"
        title={resource.contributors.communities[0]?.name}
        themeMode="high-contrast"
        style={{ zIndex: 0 }}
      />
    );
  }

  return (
    <ResourceCardCompaniesContainer>
      {companies.map((company) => (
        <CompanyLogo key={company.slug} size={22} slug={company.slug} openDrawer useRounded />
      ))}
    </ResourceCardCompaniesContainer>
  );
}
