import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

interface CollectiveCompaniesWrapperProps {
  disableClick?: boolean;
}

export const CollectiveCompaniesWrapper = styled.div<CollectiveCompaniesWrapperProps>`
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(11, 78px);
  grid-auto-rows: 45px;

  ${BREAKPOINTS.tablet} {
    grid-template-columns: repeat(11, 59px);
    grid-auto-rows: 34px;
  }

  ${BREAKPOINTS.small_tablet} {
    grid-template-columns: repeat(7, 46px);
    grid-auto-rows: 24px;
  }

  margin: auto;

  & > * {
    position: relative;
    transition: 250ms all ease;
  }

  & > img:before {
    content: '';
    position: absolute;
    background-color: #ffffff;
    border-radius: 100%;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  ${({ disableClick }) =>
    disableClick
      ? `
      & img {
        cursor: default;
      }
    `
      : `
      &:hover {
        & > img {
          opacity: 0.5;
        }
      }
    
      &:hover > *:hover {
        opacity: 1;
      }
    `}
`;
