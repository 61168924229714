import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NavSectionsOnboardingCalloutClose } from 'app/components/NavBar/NavSections';
import { SearchIconSmall } from 'app/components/shared/icons';
import useOnClickOutside from 'hooks/click-outside';
import useIsMobile from 'hooks/useIsMobile';
import useUserData from 'hooks/useUserData';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { updateSearchOnboarded } from 'services/user';
import { focusSearchSelector } from 'store/reducers/navbar-reducer';
import { searchOnboardingStepSelector, setSearchOnboardingStep } from 'store/reducers/user-reducer';

import { ISearchPill, SearchContext, SearchInfo } from './SearchContext';
import { SearchInput, SearchInputMethods } from './SearchInput';
import SearchSubmenu from './SearchSubmenu';
import {
  SearchIconContainer,
  SearchOnboardingCalloutCloseButton,
  SearchOnboardingCalloutText,
  SearchOnboardingCalloutWrapper,
  SearchOnboardingFirstStepFrame,
  SearchOnboardingSecondStepFrame,
  SearchSubMenuWrapper,
  SearchTitle,
  SearchTitleContainer,
  SearchWrapper,
} from './styled';

interface SearchProps {
  alreadyOpen?: boolean;
  expanded?: boolean;
  pill?: ISearchPill | null;
  /**
   * Context from where we are searching. Used in behavioral query types.
   * @default navbar-search
   * */
  context?: string;
  title?: string;
  onOpen?: () => void;
  onPillChange?: (pill: ISearchPill | null) => void;
  onResultClick?: (item: any) => void;
}

export default function Search({
  alreadyOpen = false,
  expanded = false,
  pill = null,
  context = 'navbar-search',
  title = 'Search the Collective',
  onPillChange,
  onOpen,
  onResultClick,
}: SearchProps) {
  const userData = useUserData();
  const isMobile = useIsMobile();
  const dimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const inputRef = useRef<SearchInputMethods>(null);
  const searchOnboardingStep = useSelector(searchOnboardingStepSelector);
  const isSearchFocused = useSelector(focusSearchSelector);
  const [isOpen, setIsOpen] = useState<boolean>(alreadyOpen);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState<boolean>(false);
  const searchWrapperRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [searchResultsAmount, setSearchResultsAmount] = useState<number | null>(null);
  const [currentPill, setCurrentPill] = useState<ISearchPill | null>(pill);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'drawers:close') {
        setIsOpen(false);
      }
    });

    return () => {
      window.removeEventListener('message', (event) => {
        if (event.data === 'drawers:close') {
          setIsOpen(false);
        }
      });
    };
  }, []);

  useEffect(() => {
    setCurrentPill(pill);
  }, [pill]);

  useEffect(() => {
    if (onPillChange) {
      onPillChange(currentPill);
    }
  }, [currentPill]);

  useEffect(() => {
    if (isSearchFocused) {
      setIsOpen(true);
    }
  }, [isSearchFocused]);

  const initialContextValue: SearchInfo = useMemo(
    () => ({
      inputValue,
      setInputValue,
      searchResultsAmount,
      setSearchResultsAmount,
      isLoading,
      setIsLoading,
      context,
      pill: currentPill,
      setPill: setCurrentPill,
      onResultClick,
      setIsSubmenuOpen,
    }),
    [inputValue, searchResultsAmount, isLoading, currentPill, pill, isSubmenuOpen]
  );

  useOnClickOutside(searchWrapperRef, (e) => {
    const sideDrawer = document.querySelector('[data-type="sidedrawer"]');
    if (sideDrawer) return;
    setIsSubmenuOpen(false);
  });

  const handleWrapperAnimationEnd = () => {
    if (isOpen) {
      setIsSubmenuOpen(true);
    }
  };

  const handleWrapperAnimationStart = () => {
    if (!isOpen) {
      setIsSubmenuOpen(false);
    }
  };

  useEffect(() => {
    if (expanded && isOpen) {
      setIsSubmenuOpen(true);
    }

    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const handleCloseOnboaredingCallout = () => {
    if (searchOnboardingStep === 1) {
      dispatch(setSearchOnboardingStep(2));
    } else {
      dispatch(setSearchOnboardingStep(0));
      updateSearchOnboarded({ status: false });
    }
  };

  const canShowOnboarding = useMemo(() => {
    return (
      !isMobile &&
      isSubmenuOpen &&
      userData &&
      userData.search_onboarding &&
      context === 'navbar-search'
    );
  }, [isSubmenuOpen, userData, context]);

  return (
    <SearchContext.Provider value={initialContextValue}>
      <SearchWrapper
        ref={searchWrapperRef}
        isOpen={isOpen}
        isSubmenuOpen={isSubmenuOpen}
        expanded={expanded}
        onClick={() => {
          onOpen?.();
          setIsOpen(true);
        }}
        onAnimationStart={handleWrapperAnimationStart}
        onAnimationEnd={handleWrapperAnimationEnd}
      >
        {canShowOnboarding && searchOnboardingStep === 1 ? (
          <>
            <SearchOnboardingFirstStepFrame />
            <SearchOnboardingCallout
              bottom={'-27px'}
              left={dimensions?.width > 1480 ? '-35px' : 'unset'}
              right={dimensions?.width > 1480 ? 'unset' : '-670px'}
              onClose={handleCloseOnboaredingCallout}
            >
              <SearchOnboardingCalloutText>
                Highlighted Searches are based on what others are searching for.
              </SearchOnboardingCalloutText>
            </SearchOnboardingCallout>
          </>
        ) : null}
        {canShowOnboarding && searchOnboardingStep === 2 ? (
          <>
            <SearchOnboardingSecondStepFrame
              id={`search-onboarding-second-step-frame-${context}`}
            />
            <SearchOnboardingSecondCallout
              bottom={'-225px'}
              left={dimensions?.width > 1480 ? '-35px' : 'unset'}
              right={dimensions?.width > 1480 ? 'unset' : '-670px'}
              onClose={handleCloseOnboaredingCallout}
            >
              <SearchOnboardingCalloutText>
                You can find a master list of all the available things to search using these lenses.
              </SearchOnboardingCalloutText>
            </SearchOnboardingSecondCallout>
          </>
        ) : null}
        {expanded ? (
          <SearchInput ref={inputRef} />
        ) : !isOpen || !isSubmenuOpen ? (
          <SearchTitleContainer>
            <SearchTitle>{title}</SearchTitle>
            <SearchIconContainer>
              <SearchIconSmall
                width={isMobile ? 15 : 24}
                height={isMobile ? 15 : 24}
                fill={'var(--color-primary)'}
              />
            </SearchIconContainer>
          </SearchTitleContainer>
        ) : (
          <SearchInput ref={inputRef} />
        )}
        <SearchSubMenuWrapper
          isOpen={isSubmenuOpen}
          onTransitionEnd={() => {
            if (!isSubmenuOpen) setIsOpen(false);
          }}
        >
          <SearchSubmenu />
        </SearchSubMenuWrapper>
      </SearchWrapper>
    </SearchContext.Provider>
  );
}

interface SearchOnboardingCalloutProps {
  bottom: string;
  left: string;
  right: string;
  onClose?: () => void;
  children?: React.ReactNode;
}

function SearchOnboardingCallout({
  bottom,
  left,
  right,
  onClose,
  children,
}: SearchOnboardingCalloutProps) {
  return (
    <SearchOnboardingCalloutWrapper bottom={bottom} left={left} right={right}>
      <SearchOnboardingCalloutCloseButton onClick={onClose}>
        <NavSectionsOnboardingCalloutClose />
      </SearchOnboardingCalloutCloseButton>
      {children}
      <svg
        width="316"
        height="85"
        viewBox="0 0 316 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_bd_32298_58453)">
          <mask
            id="path-1-outside-1_32298_58453"
            maskUnits="userSpaceOnUse"
            x="4"
            y="0.53125"
            width="308"
            height="76"
            fill="black"
          >
            <rect fill="white" x="4" y="0.53125" width="308" height="76" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 2.53125C13.1634 2.53125 6 9.69469 6 18.5312V58.5312C6 67.3678 13.1634 74.5312 22 74.5312H277C285.837 74.5312 293 67.3678 293 58.5312V46.0234L308.422 38.3123C309.896 37.5752 309.896 35.4716 308.422 34.7346L293 27.0234V18.5312C293 9.69469 285.837 2.53125 277 2.53125H22Z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 2.53125C13.1634 2.53125 6 9.69469 6 18.5312V58.5312C6 67.3678 13.1634 74.5312 22 74.5312H277C285.837 74.5312 293 67.3678 293 58.5312V46.0234L308.422 38.3123C309.896 37.5752 309.896 35.4716 308.422 34.7346L293 27.0234V18.5312C293 9.69469 285.837 2.53125 277 2.53125H22Z"
            fill="#EB823D"
            fillOpacity="0.3"
            shapeRendering="crispEdges"
          />
          <path
            d="M293 46.0234L292.106 44.2346L291 44.7874V46.0234H293ZM308.422 38.3123L309.317 40.1012V40.1011L308.422 38.3123ZM308.422 34.7346L309.317 32.9457V32.9457L308.422 34.7346ZM293 27.0234H291V28.2595L292.106 28.8123L293 27.0234ZM8 18.5312C8 10.7993 14.268 4.53125 22 4.53125V0.53125C12.0589 0.53125 4 8.59012 4 18.5312H8ZM8 58.5312V18.5312H4V58.5312H8ZM22 72.5312C14.268 72.5312 8 66.2632 8 58.5312H4C4 68.4724 12.0589 76.5312 22 76.5312V72.5312ZM277 72.5312H22V76.5312H277V72.5312ZM291 58.5312C291 66.2632 284.732 72.5312 277 72.5312V76.5312C286.941 76.5312 295 68.4724 295 58.5312H291ZM291 46.0234V58.5312H295V46.0234H291ZM293.894 47.8123L309.317 40.1012L307.528 36.5234L292.106 44.2346L293.894 47.8123ZM309.317 40.1011C312.265 38.627 312.265 34.4198 309.317 32.9457L307.528 36.5234V36.5234L309.317 40.1011ZM309.317 32.9457L293.894 25.2346L292.106 28.8123L307.528 36.5234L309.317 32.9457ZM291 18.5312V27.0234H295V18.5312H291ZM277 4.53125C284.732 4.53125 291 10.7993 291 18.5312H295C295 8.59012 286.941 0.53125 277 0.53125V4.53125ZM22 4.53125H277V0.53125H22V4.53125Z"
            fill="#FFA366"
            mask="url(#path-1-outside-1_32298_58453)"
          />
        </g>
        <defs>
          <filter
            id="filter0_bd_32298_58453"
            x="-32"
            y="-35.4688"
            width="379.527"
            height="148"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="18" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_32298_58453"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend
              mode="normal"
              in2="effect1_backgroundBlur_32298_58453"
              result="effect2_dropShadow_32298_58453"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_32298_58453"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </SearchOnboardingCalloutWrapper>
  );
}

function SearchOnboardingSecondCallout({
  bottom,
  left,
  right,
  onClose,
  children,
}: SearchOnboardingCalloutProps) {
  return (
    <SearchOnboardingCalloutWrapper bottom={bottom} left={left} right={right}>
      <SearchOnboardingCalloutCloseButton onClick={onClose}>
        <NavSectionsOnboardingCalloutClose />
      </SearchOnboardingCalloutCloseButton>
      {children}
      <svg
        width="316"
        height="98"
        viewBox="0 0 316 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_bd_32490_22723)">
          <mask
            id="path-1-outside-1_32490_22723"
            maskUnits="userSpaceOnUse"
            x="4"
            y="0.53125"
            width="308"
            height="89"
            fill="black"
          >
            <rect fill="white" x="4" y="0.53125" width="308" height="89" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 2.53125C13.1634 2.53125 6 9.69469 6 18.5312V71.5312C6 80.3678 13.1634 87.5312 22 87.5312H277C285.837 87.5312 293 80.3678 293 71.5312V53.0234L308.422 45.3123C309.896 44.5752 309.896 42.4716 308.422 41.7346L293 34.0234V18.5312C293 9.69469 285.837 2.53125 277 2.53125H22Z"
            />
          </mask>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 2.53125C13.1634 2.53125 6 9.69469 6 18.5312V71.5312C6 80.3678 13.1634 87.5312 22 87.5312H277C285.837 87.5312 293 80.3678 293 71.5312V53.0234L308.422 45.3123C309.896 44.5752 309.896 42.4716 308.422 41.7346L293 34.0234V18.5312C293 9.69469 285.837 2.53125 277 2.53125H22Z"
            fill="#EB823D"
            fillOpacity="0.3"
            shapeRendering="crispEdges"
          />
          <path
            d="M293 53.0234L292.106 51.2346L291 51.7874V53.0234H293ZM308.422 45.3123L309.317 47.1012V47.1011L308.422 45.3123ZM308.422 41.7346L309.317 39.9457V39.9457L308.422 41.7346ZM293 34.0234H291V35.2595L292.106 35.8123L293 34.0234ZM8 18.5312C8 10.7993 14.268 4.53125 22 4.53125V0.53125C12.0589 0.53125 4 8.59012 4 18.5312H8ZM8 71.5312V18.5312H4V71.5312H8ZM22 85.5312C14.268 85.5312 8 79.2632 8 71.5312H4C4 81.4724 12.0589 89.5312 22 89.5312V85.5312ZM277 85.5312H22V89.5312H277V85.5312ZM291 71.5312C291 79.2632 284.732 85.5312 277 85.5312V89.5312C286.941 89.5312 295 81.4724 295 71.5312H291ZM291 53.0234V71.5312H295V53.0234H291ZM293.894 54.8123L309.317 47.1012L307.528 43.5234L292.106 51.2346L293.894 54.8123ZM309.317 47.1011C312.265 45.627 312.265 41.4198 309.317 39.9457L307.528 43.5234V43.5234L309.317 47.1011ZM309.317 39.9457L293.894 32.2346L292.106 35.8123L307.528 43.5234L309.317 39.9457ZM291 18.5312V34.0234H295V18.5312H291ZM277 4.53125C284.732 4.53125 291 10.7993 291 18.5312H295C295 8.59012 286.941 0.53125 277 0.53125V4.53125ZM22 4.53125H277V0.53125H22V4.53125Z"
            fill="#FFA366"
            mask="url(#path-1-outside-1_32490_22723)"
          />
        </g>
        <defs>
          <filter
            id="filter0_bd_32490_22723"
            x="-32"
            y="-35.4688"
            width="379.527"
            height="161"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="18" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_32490_22723"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend
              mode="normal"
              in2="effect1_backgroundBlur_32490_22723"
              result="effect2_dropShadow_32490_22723"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_32490_22723"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </SearchOnboardingCalloutWrapper>
  );
}
