import React from 'react';

export default function ConnectorIcon({
  width = 41,
  height = 37,
  fill = '#5478A1',
  fillOpacity = 0.3,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9046 20.2917H11.5366C10.7211 22.5994 8.52229 24.25 5.9375 24.25C2.65802 24.25 0 21.592 0 18.3125C0 15.033 2.65802 12.375 5.9375 12.375C8.52229 12.375 10.7231 14.0276 11.5385 16.3333H15.9046L22.7604 4.45833H29.0344C29.8498 2.1526 32.0506 0.5 34.6354 0.5C37.9149 0.5 40.5729 3.15802 40.5729 6.4375C40.5729 9.71698 37.9149 12.375 34.6354 12.375C32.0506 12.375 29.8518 10.7244 29.0364 8.41667H25.0444L19.3325 18.3105L25.0464 28.2083H29.0364C29.8498 25.9026 32.0506 24.25 34.6354 24.25C37.9149 24.25 40.5729 26.908 40.5729 30.1875C40.5729 33.467 37.9149 36.125 34.6354 36.125C32.0506 36.125 29.8518 34.4744 29.0364 32.1667H22.7604L15.9046 20.2917ZM34.6354 28.2083C33.5429 28.2083 32.6563 29.095 32.6563 30.1875C32.6563 31.28 33.5429 32.1667 34.6354 32.1667C35.7279 32.1667 36.6146 31.28 36.6146 30.1875C36.6146 29.095 35.7279 28.2083 34.6354 28.2083ZM5.9375 16.3333C4.845 16.3333 3.95833 17.22 3.95833 18.3125C3.95833 19.405 4.845 20.2917 5.9375 20.2917C7.03 20.2917 7.91667 19.405 7.91667 18.3125C7.91667 17.22 7.03 16.3333 5.9375 16.3333ZM34.6354 4.45833C33.5429 4.45833 32.6563 5.345 32.6563 6.4375C32.6563 7.53 33.5429 8.41667 34.6354 8.41667C35.7279 8.41667 36.6146 7.53 36.6146 6.4375C36.6146 5.345 35.7279 4.45833 34.6354 4.45833Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}
