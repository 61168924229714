import React from 'react';

export default function Lock({ width = 30, height = 29, fill = '#392B62' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.90342 14.5564C6.24523 14.5564 5.71166 15.09 5.71166 15.7481V24.0905C5.71166 24.7487 6.24523 25.2823 6.90342 25.2823H23.5881C24.2463 25.2823 24.7799 24.7487 24.7799 24.0905V15.7481C24.7799 15.09 24.2463 14.5564 23.5881 14.5564H6.90342ZM3.32812 15.7481C3.32812 13.7736 4.92884 12.1729 6.90342 12.1729H23.5881C25.5627 12.1729 27.1634 13.7736 27.1634 15.7481V24.0905C27.1634 26.0651 25.5627 27.6658 23.5881 27.6658H6.90342C4.92884 27.6658 3.32812 26.0651 3.32812 24.0905V15.7481Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2483 3.83031C13.9839 3.83031 12.7714 4.33255 11.8774 5.22655C10.9834 6.12055 10.4812 7.33307 10.4812 8.59737V13.3644C10.4812 14.0226 9.94762 14.5562 9.28942 14.5562C8.63123 14.5562 8.09766 14.0226 8.09766 13.3644V8.59737C8.09766 6.70092 8.85102 4.88213 10.192 3.54114C11.533 2.20014 13.3518 1.44678 15.2483 1.44678C17.1447 1.44678 18.9635 2.20014 20.3045 3.54114C21.6455 4.88213 22.3988 6.70092 22.3988 8.59737V13.3644C22.3988 14.0226 21.8653 14.5562 21.2071 14.5562C20.5489 14.5562 20.0153 14.0226 20.0153 13.3644V8.59737C20.0153 7.33307 19.5131 6.12055 18.6191 5.22655C17.7251 4.33255 16.5126 3.83031 15.2483 3.83031Z"
        fill={fill}
      />
    </svg>
  );
}
