import React, { useMemo, useState } from 'react';

import CollectiveCompanies from 'app/components/CommonStyled/CollectiveCompanies';
import { desktopCompanies } from 'app/components/CommonStyled/CollectiveCompanies/constants';
import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import SideDrawer from 'app/components/CommonStyled/SideDrawer';
import GlobalPersonDrawer from 'app/components/Drawers/GlobalPerson/GlobalPersonDrawer';
import useIsMobile from 'hooks/useIsMobile';
import useUserData from 'hooks/userData';

import useOnboardingControls from '../../hooks/useOnboardingControls';
import {
  OnboardingStepTwoBottomRow,
  OnboardingStepTwoBottomRowParagraphContainer,
  OnboardingStepTwoCollectiveContainer,
  OnboardingStepTwoKyuTeamContainer,
  OnboardingStepTwoKyuTeamIdentifierContainer,
  OnboardingStepTwoKyuTeamIdentifierText,
  OnboardingStepTwoKyuTeamMemberContainer,
  OnboardingStepTwoKyuTeamMemberName,
  OnboardingStepTwoKyuTeamMemberRole,
  OnboardingStepTwoKyuTeamMemberWrapper,
  OnboardingStepTwoKyuTeamWrapper,
  OnboardingStepTwoParagraph,
  OnboardingStepTwoTitle,
  OnboardingStepTwoTopRow,
  OnboardingStepTwoWrapper,
} from './styled';

export default function OnboardingStepTwo() {
  const isMobile = useIsMobile();
  const { onboardingQuery } = useOnboardingControls();
  const companyData = onboardingQuery.data?.company;
  return (
    <OnboardingStepTwoWrapper>
      <OnboardingStepTwoTitle>kyu in a Nutshell</OnboardingStepTwoTitle>
      <OnboardingStepTwoTopRow>
        <div>
          <OnboardingStepTwoParagraph style={{ marginBottom: '16px' }}>
            Just to be sure, you’re a part of the kyu Collective.
          </OnboardingStepTwoParagraph>
          <OnboardingStepTwoParagraph>
            We are a global network of change-making creative firms that started in 2014. There are{' '}
            {companyData?.member_companies_count} member companies to date - {companyData?.name}{' '}
            being one of them.
          </OnboardingStepTwoParagraph>
        </div>
        <OnboardingStepTwoCollectiveContainer>
          <CollectiveCompanies overrideSize={isMobile ? 48 : 35} disableClick />
        </OnboardingStepTwoCollectiveContainer>
      </OnboardingStepTwoTopRow>
      <OnboardingStepTwoBottomRow>
        <KyuTeam />
        <OnboardingStepTwoBottomRowParagraphContainer>
          <OnboardingStepTwoParagraph>
            There's a small kyu team that operates mainly out of New York and London and manages
            this platform.
          </OnboardingStepTwoParagraph>
          <OnboardingStepTwoParagraph>
            The Collective’s main cities include New York, Tokyo, San Francisco, Montreal, Dubai,
            London, Paris, Copenhagen and Taipei… and in the same vein, you can think of kyu OS as
            our virtual hub.
          </OnboardingStepTwoParagraph>
        </OnboardingStepTwoBottomRowParagraphContainer>
      </OnboardingStepTwoBottomRow>
    </OnboardingStepTwoWrapper>
  );
}

export function KyuTeam({ query = null, identifier = 'We’re the kyu team!' }) {
  const { onboardingQuery: contextQuery } = useOnboardingControls();
  const [personDrawer, setPersonDrawer] = useState<{ show: boolean; slug: string }>({
    show: false,
    slug: null,
  });

  const handleClick = (slug: string) => {
    setPersonDrawer({ show: true, slug });
  };

  const handlePersonDrawerClose = () => {
    setPersonDrawer({ show: false, slug: null });
  };

  const finalQuery = useMemo(() => {
    return query || contextQuery;
  }, [query, contextQuery]);

  return (
    <OnboardingStepTwoKyuTeamWrapper>
      <OnboardingStepTwoKyuTeamContainer>
        {finalQuery.data?.people?.map((member, index) => (
          <OnboardingStepTwoKyuTeamMemberWrapper
            key={member.slug}
            onClick={() => handleClick(member.slug)}
          >
            <PersonAvatar
              avatar={member.profile_image}
              name={member.first_name}
              lastName={member.last_name}
              size={57}
              highlighted
              openDrawer
              disablePropagation
            />
            <OnboardingStepTwoKyuTeamMemberContainer>
              <OnboardingStepTwoKyuTeamMemberName>
                {member.first_name} {member.last_name}
              </OnboardingStepTwoKyuTeamMemberName>
              <OnboardingStepTwoKyuTeamMemberRole>
                {member?.role_name}
              </OnboardingStepTwoKyuTeamMemberRole>
            </OnboardingStepTwoKyuTeamMemberContainer>
          </OnboardingStepTwoKyuTeamMemberWrapper>
        ))}
        {personDrawer.show && (
          <SideDrawer closeDrawer={handlePersonDrawerClose} withClose>
            <GlobalPersonDrawer slug={personDrawer.slug} />
          </SideDrawer>
        )}
      </OnboardingStepTwoKyuTeamContainer>
      {!finalQuery.isLoading && finalQuery.data.people.length > 0 ? (
        <KyuTeamIdentifier text={identifier} />
      ) : null}
    </OnboardingStepTwoKyuTeamWrapper>
  );
}

interface KyuTeamIdentifierProps {
  text?: string;
}

export function KyuTeamIdentifier({ text = 'We’re the kyu team!' }: KyuTeamIdentifierProps) {
  return (
    <OnboardingStepTwoKyuTeamIdentifierContainer>
      <svg
        width="81"
        height="92"
        viewBox="0 0 81 92"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M72.1419 78.028C73.5468 73.4872 74.8335 68.9304 75.4039 64.3922C76.0107 59.5639 74.7048 54.9623 73.2924 50.5306C71.0387 43.4584 65.3248 36.8794 54.5988 36.2615C44.5939 35.6851 42.0378 45.058 46.1521 49.6003C47.5977 51.1964 50.3496 51.007 52.8168 49.7714C56.7194 47.8171 58.5308 42.0948 58.4332 39.2729C58.2814 34.8879 58.0149 30.763 54.5554 27.2378C51.096 23.7126 47.2914 22.0282 41.8279 20.2804C31.6639 17.029 17.743 17.7829 5.99491 20.8049C4.61594 21.1597 0.49326 22.2791 1.86867 21.9106C3.94048 21.3554 6.22274 22.7457 7.79671 23.32C8.55937 23.5983 15.8382 25.462 13.9357 24.2982"
          stroke="rgba(84, 120, 161, 0.3)"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M2.46569 21.3762C6.00058 19.2796 8.40762 15.5226 11.4347 12.9772"
          stroke="rgba(84, 120, 161, 0.3)"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
      <OnboardingStepTwoKyuTeamIdentifierText>{text}</OnboardingStepTwoKyuTeamIdentifierText>
    </OnboardingStepTwoKyuTeamIdentifierContainer>
  );
}
