import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getPeopleDrawerList } from 'services/app';

interface PeopleDrawerParams {
  passion_uuids?: string[];
  roles_uuids?: string[];
  locations_uuids?: string[];
  kyu_skills_uuids?: string[];
}

export default function usePeopleDrawerList(
  companySlug: string,
  params: PeopleDrawerParams = {
    passion_uuids: [],
    roles_uuids: [],
    locations_uuids: [],
    kyu_skills_uuids: [],
  },
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    [`company-${companySlug}-people-drawer-list`, params],
    () => getPeopleDrawerList(companySlug, params).then((response) => response.data),
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
