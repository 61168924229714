import { HomeRadarActiveWorkTitle } from 'app/components/Home/HomeRadar/HomeRadarActiveWork/styled';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsNetworkActiveWorkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${HomeRadarActiveWorkTitle} {
    display: none;
  }
`;

export const AboutKyuOsNetworkActiveWorkDescription = styled.h3`
  max-width: 943px;

  font-weight: 400;
  font-size: 32px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    font-size: 24px;
  }

  color: var(--color-text);
`;
