import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { highlightedSelector } from 'store/reducers/highlight-reducer';

import { WithHighlightWrapper } from './styled';

export const withHighlight = <P extends {}>(EnhancedComponent, id) => {
  const HighlightedComponent: React.FC<P> = (props) => {
    const highlighted = useSelector(highlightedSelector);

    const ref = useCallback((node) => {
      if (node) {
      }
    }, []);

    const isHighlighted = useMemo(() => {
      return highlighted.includes(id);
    }, [highlighted]);

    if (!isHighlighted) return <EnhancedComponent {...props} />;

    return (
      <WithHighlightWrapper ref={ref} isHighlighted={isHighlighted}>
        <EnhancedComponent {...props} />
      </WithHighlightWrapper>
    );
  };

  return HighlightedComponent;
};
