import {
  AboutKyuOsTOCContainer,
  AboutKyuOsTOCWrapper,
} from 'app/components/AboutKyuOs/AboutKyuOsTOC/styled';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsOnboardingBannerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 16px 58px;

  position: relative;

  border-radius: 32px 32px 0px 0px;

  &:before {
    content: '';

    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(24px);
  }

  ${({ theme }) =>
    !theme.isAboutKyuOsOpen &&
    `
    background-image: url('/backgrounds/home_rectangle.webp');
    background-repeat: no-repeat;
    background-size: 100vw;
    background-color: #f7f7f7;

  `}

  ${AboutKyuOsTOCWrapper} {
    margin-bottom: 0px !important;
  }

  ${AboutKyuOsTOCContainer} {
    flex: 1;
    max-width: unset;
    padding: 0px;
    margin: 0px auto;
  }

  ${BREAKPOINTS.tablet} {
    padding: 16px;
  }

  cursor: pointer;
`;

export const AboutKyuOsOnboardingBannerTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;

  text-wrap: nowrap;

  color: rgba(23, 28, 51, 0.5);

  position: relative;
`;

export const AboutKyuOsOnboardingBannerIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 32px;

  ${BREAKPOINTS.tablet} {
    gap: 8px;
  }

  position: relative;
`;

export const AboutKyuOsOnboardingBannerSortingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px;

  cursor: pointer;
`;

export const AboutKyuOsOnboardingBannerCloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  cursor: pointer;
`;
