import styled from 'styled-components';

export const AboutKyuOsWayfindingMegamenuWrapper = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 20px;

  border-radius: 20px;
  background: var(--color-primary);

  & > .my-masonry-grid {
    width: auto;
    display: flex;
    justify-content: center;
  }

  & > .my-masonry-grid > .my-masonry-grid_column {
    max-width: 300px;
    display: flex;
    flex-direction: column;
  }

  & > .my-masonry-grid > .my-masonry-grid_column > div {
    margin-bottom: 16px;
    margin-right: 16px;
  }

  & > .my-masonry-grid > .my-masonry-grid_column:last-child > div {
    margin-right: 0px;
  }
`;

export const AboutKyuOsWayfindingMegamenuTitle = styled.h3`
  font-weight: 400;
  font-size: 32px;
  line-height: normal;
  text-align: center;

  color: #ffffff;
`;

export const AboutKyuOsWayfindingMegamenuContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 24px 0px 16px 0px;
`;

export const AboutKyuOsWayfindingMegamenuContentColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AboutKyuOsWayfindingMegamenuSubtitle = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  text-align: center;

  color: #ffffff;
`;

//CARD
interface AboutKyuOsWayfindingMegamenuCardWrapperProps {
  fromKyu: boolean;
}

export const AboutKyuOsWayfindingMegamenuCardWrapper = styled.div<AboutKyuOsWayfindingMegamenuCardWrapperProps>`
  height: 99px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;

  border-radius: 16px;

  ${({ fromKyu }) =>
    fromKyu
      ? `
    border: 1px #000;
    background: rgba(255, 255, 255, 0.25);  
      `
      : `
    background: rgba(255, 255, 255, 0.75);

    transition: background 200ms ease;
    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  `}

  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);

  cursor: ${({ fromKyu }) => (fromKyu ? 'default' : 'pointer')};
`;

export const AboutKyuOsWayFindingMegamenuCardContainer = styled.div`
  width: 100%;
  max-width: 285px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  & > svg {
    min-width: 16px;
    min-height: 16px;
  }
`;

export const AboutKyuOsWayFindingMegamenuCardLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const AboutKyuOsWayFindingMegamenuCardText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;

  color: var(--color-text);
`;

interface AboutKyuOsWayFindingMegamenuCardIconProps {
  background: string;
}

export const AboutKyuOsWayFindingMegamenuCardIcon = styled.div<AboutKyuOsWayFindingMegamenuCardIconProps>`
  width: 40px;
  height: 40px;

  min-width: 40px;
  min-height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ background }) => background};
  border-radius: 100%;
`;

export const AboutKyuOsWayFindingMegamenuCardPersonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AboutKyuOsWayFindingMegamenuCardPersonSeparator = styled.div`
  width: 10px;
  height: 1px;
  border-top: 1px dotted var(--color-data-people-foreground);
`;

export const AboutKyuOsWayFindingMegamenuCardCounter = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;

  background: #ffffff;
  border: 2px solid var(--color-data-people-foreground);

  font-weight: 700;
  font-size: 16px;
  line-height: 12px;
  text-align: center;
  color: var(--color-data-people-foreground);
`;
