import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import ProfileEditInput from 'app/components/CommonStyled/ProfileEditInput';
import { CloseIcon } from 'app/components/shared/icons';
import { Form, Formik, FormikProps } from 'formik';
import { ICollectionParams, postLanguages } from 'services/profile-editor';
import {
  profileDataSelector,
  setLanguages,
} from 'store/reducers/profile-editor/profile-editor-reducer';
import * as Yup from 'yup';

import { useGetLanguages, usePostLanguages } from '../../hooks/useLanguages';
import useProfileEditorHumanAtAGlanceDrawer from '../../hooks/useProfileEditorHumanAtAGlanceDrawer';
import ProfileEditorDrawer, { ProfileEditorDrawerRefProps } from '../Drawer';
import { ProfileEditorDrawerTitle } from '../styled';
import {
  ProfileEditorDrawerLanguagesPill,
  ProfileEditorDrawerLanguagesPillIconContainer,
  ProfileEditorDrawerLanguagesPillsContainer,
  ProfileEditorDrawerLanguagesPillText,
  ProfileEditorDrawerLanguagesWrapper,
} from './styled';

interface ProfileEditorDrawerLanguagesProps {
  onClose: () => void;
}

export default function ProfileEditorDrawerLanguages({
  onClose,
}: ProfileEditorDrawerLanguagesProps) {
  const dispatch = useDispatch();
  const { languages, allLanguages } = useProfileEditorHumanAtAGlanceDrawer();
  const formikRef = useRef<FormikProps<any>>(null);
  const [optionsPicked, setOptionsPicked] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);
  const drawerRef = useRef<ProfileEditorDrawerRefProps>(null);

  useEffect(() => {
    setOptionsPicked(languages);
    formikRef.current.setFieldValue(
      'collection',
      languages.map((language) => language.uuid)
    );
  }, [languages]);

  useEffect(() => {
    if (allLanguages && allLanguages.length > 0) {
      setOptions(allLanguages.map((language) => ({ value: language.uuid, label: language.name })));
    }
  }, [allLanguages]);

  useEffect(() => {
    if (languages && languages.length > 0) {
      setOptionsPicked(
        languages.map((language) => ({ value: language.uuid, label: language.name }))
      );
    }
  }, [languages]);

  const handlePostLanguages = async (params: ICollectionParams) => {
    const response = await postLanguages(params);
    if (!response.ok) {
      throw new Error(response.originalError?.message);
    }

    dispatch(setLanguages(optionsPicked.map((opt) => ({ uuid: opt.value, name: opt.label }))));

    return response.data;
  };

  const { mutate } = useMutation(handlePostLanguages, drawerRef.current?.handleMutation);

  const languagesValidationSchema = Yup.object().shape({
    collection: Yup.array(),
  });

  const handleOptionsChanged = (options: any[]) => {
    setOptionsPicked(options);
  };

  const handleSubmit = (values) => {
    mutate({ collection: values.collection });
  };

  const handleRemoveLanguage = (option) => {
    const newOptions = optionsPicked.filter((opt) => opt.value !== option.value);
    formikRef.current.setFieldValue(
      'collection',
      newOptions.map((opt) => opt.value)
    );
    setOptionsPicked(newOptions);
  };

  return (
    <Formik
      initialValues={{
        collection: languages.map((language) => language.uuid),
      }}
      validationSchema={languagesValidationSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {(props) => (
        <Form>
          <ProfileEditorDrawer
            ref={drawerRef}
            header={<ProfileEditorDrawerLanguagesHeader />}
            needsSaving={props.dirty}
            onClose={onClose}
          >
            <ProfileEditorDrawerLanguagesWrapper>
              <ProfileEditInput
                {...props}
                name={'collection'}
                label={'What languages do you speak?'}
                tooltip={
                  'Include any language that you can casually converse in, even if you can not read or write it. '
                }
                type={'select'}
                placeholder={'Select a language...'}
                options={options}
                onOptionsChanged={handleOptionsChanged}
              />
              <ProfileEditorDrawerLanguagesPillsContainer>
                {optionsPicked &&
                  optionsPicked.length > 0 &&
                  optionsPicked.map((option, index) => (
                    <ProfileEditorDrawerLanguagesPill key={option.value}>
                      <ProfileEditorDrawerLanguagesPillText>
                        {option.label}
                      </ProfileEditorDrawerLanguagesPillText>
                      <ProfileEditorDrawerLanguagesPillIconContainer
                        onClick={() => handleRemoveLanguage(option)}
                      >
                        <CloseIcon width={9} height={9} fill={'var(--color-primary)'} />
                      </ProfileEditorDrawerLanguagesPillIconContainer>
                    </ProfileEditorDrawerLanguagesPill>
                  ))}
              </ProfileEditorDrawerLanguagesPillsContainer>
            </ProfileEditorDrawerLanguagesWrapper>
          </ProfileEditorDrawer>
        </Form>
      )}
    </Formik>
  );
}

function ProfileEditorDrawerLanguagesHeader() {
  return <ProfileEditorDrawerTitle>Languages</ProfileEditorDrawerTitle>;
}
