export const getValuesFromQuery = (
  query,
  key,
  splitValues = false,
  defaultValue = undefined
) => {
  const queryValue = query.get(key);
  if (queryValue) {
    return splitValues
      ? queryValue.replace("/map", "").split(",")
      : queryValue.replace("/map", "");
  }
  return defaultValue;
};
