import React from 'react';

export default function InstagramIcon({ width = 30, height = 30, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33203 3.00016C5.38651 3.00016 2.9987 5.38798 2.9987 8.3335V21.6668C2.9987 24.6123 5.38651 27.0002 8.33203 27.0002H21.6654C24.6109 27.0002 26.9987 24.6123 26.9987 21.6668V8.3335C26.9987 5.38798 24.6109 3.00016 21.6654 3.00016H8.33203ZM0.332031 8.3335C0.332031 3.91522 3.91375 0.333496 8.33203 0.333496H21.6654C26.0836 0.333496 29.6654 3.91522 29.6654 8.3335V21.6668C29.6654 26.0851 26.0836 29.6668 21.6654 29.6668H8.33203C3.91375 29.6668 0.332031 26.0851 0.332031 21.6668V8.3335ZM15.6431 10.9857C14.8109 10.8623 13.9609 11.0045 13.2141 11.392C12.4673 11.7795 11.8617 12.3926 11.4834 13.1441C11.1052 13.8956 10.9735 14.7473 11.1072 15.578C11.2408 16.4086 11.633 17.176 12.228 17.7709C12.8229 18.3658 13.5902 18.758 14.4209 18.8917C15.2516 19.0253 16.1032 18.8937 16.8547 18.5154C17.6063 18.1371 18.2194 17.5316 18.6069 16.7848C18.9944 16.038 19.1365 15.188 19.0131 14.3557C18.8872 13.5068 18.4917 12.7209 17.8848 12.114C17.278 11.5072 16.492 11.1116 15.6431 10.9857ZM11.9859 9.02498C13.2306 8.37915 14.6472 8.14223 16.0343 8.34791C17.4491 8.55772 18.759 9.21702 19.7704 10.2284C20.7818 11.2398 21.4411 12.5497 21.6509 13.9646C21.8566 15.3517 21.6197 16.7683 20.9739 18.0129C20.328 19.2576 19.3062 20.2669 18.0536 20.8974C16.8011 21.5278 15.3817 21.7472 13.9973 21.5245C12.6128 21.3017 11.3339 20.6481 10.3423 19.6565C9.3508 18.665 8.69716 17.386 8.47439 16.0016C8.25161 14.6172 8.47105 13.1977 9.10148 11.9452C9.73192 10.6927 10.7413 9.67082 11.9859 9.02498ZM22.332 6.3335C21.5957 6.3335 20.9987 6.93045 20.9987 7.66683C20.9987 8.40321 21.5957 9.00016 22.332 9.00016H22.3454C23.0817 9.00016 23.6787 8.40321 23.6787 7.66683C23.6787 6.93045 23.0817 6.3335 22.3454 6.3335H22.332Z"
        fill={fill}
      />
    </svg>
  );
}
