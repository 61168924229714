import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { getCompanies, getClients, getIndustries} from 'services/profile-editor';

export function useProfessionalPriorExperience(
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `profile-editor-professional-prior-experience`,
    async () => {
      const [companies, clients, industries] = await Promise.all([
        getCompanies(),
        getClients(),
        getIndustries(),
      ]);

      return {
        companies: companies.data.companies || [],
        allCompanies: companies.data.all || [],
        clients: clients.data.clients || [],
        allClients: clients.data.all || [],
        industries: industries.data.industries || [],
        allIndustries: industries.data.all || [],
      };
    },
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
