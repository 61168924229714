import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  profileDataSelector,
  setAllSkills,
  setSkills,
  setSkillsInspo,
} from 'store/reducers/profile-editor/profile-editor-reducer';

import { useProfessionalAtAGlance } from './useProfessionalAtAGlance';

export default function useProfileEditorSkillsDrawer() {
  const dispatch = useDispatch();
  const { data, refetch } = useProfessionalAtAGlance({ enabled: false });
  const { skills, allSkills } = useSelector(profileDataSelector);

  useEffect(() => {
    if (allSkills.length === 0) {
      refetch();
    }
  }, [allSkills]);

  useEffect(() => {
    if (data && allSkills.length === 0) {
      dispatch(setSkills(data.skills));
      dispatch(setSkillsInspo(data.skills_inspo));
      dispatch(setAllSkills(data.allSkills));
    }
  }, [data, allSkills]);

  return {
    skills,
    allSkills,
  };
}
