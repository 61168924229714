import React from 'react';

export default function HamburgerIcon({ width = 24, height = 24, fill = '#171C33' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 11.9984C3.5 11.529 3.78542 11.1484 4.1375 11.1484H15.6125C15.9646 11.1484 16.25 11.529 16.25 11.9984C16.25 12.4679 15.9646 12.8484 15.6125 12.8484H4.1375C3.78542 12.8484 3.5 12.4679 3.5 11.9984Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6.90078C3.5 6.43134 3.88056 6.05078 4.35 6.05078H19.65C20.1194 6.05078 20.5 6.43134 20.5 6.90078C20.5 7.37022 20.1194 7.75078 19.65 7.75078H4.35C3.88056 7.75078 3.5 7.37022 3.5 6.90078Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 17.1C3.5 16.6306 3.88056 16.25 4.35 16.25H19.65C20.1194 16.25 20.5 16.6306 20.5 17.1C20.5 17.5694 20.1194 17.95 19.65 17.95H4.35C3.88056 17.95 3.5 17.5694 3.5 17.1Z"
        fill={fill}
      />
    </svg>
  );
}
