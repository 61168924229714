import React from 'react';

export default function index({
  width = 16,
  height = 16,
  background = '#EB823D',
  fill = '#ffffff',
}) {
  return (
    <svg width={width} height={height}>
      <circle cx={8} cy={8} r={6} fill={background} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.357 10.357a.667.667 0 0 0 0-.943L8.942 8l1.415-1.414a.667.667 0 0 0-.943-.943L8 7.057 6.585 5.643a.667.667 0 0 0-.942.943L7.057 8 5.643 9.414a.667.667 0 0 0 .942.943L8 8.943l1.414 1.414c.26.26.682.26.943 0Z"
        fill={fill}
      />
    </svg>
  );
}
