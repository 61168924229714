import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

import { SkeletonBackground, SkeletonItem } from '../styled';
import { CommonGroundType } from './CommonGround';

const commonGroundColors = {
  languages: 'rgba(255, 194, 41, 0.15)',
  locations: 'rgba(255, 194, 41, 0.15)',
  passions: 'rgba(212, 117, 99, 0.3)',
  industries: 'rgba(141, 167, 108, 0.3)',
  kyu_skills: 'rgba(235, 130, 61, 0.3)',
  clients: 'rgba(16, 77, 91, 0.3)',
  projects: 'rgba(57, 43, 98, 0.3)',
  passion_companies: 'rgba(208, 178, 178, 0.1)',
  roles: 'rgba(84, 120, 161, 0.3)',
};

interface CommonGroundContainerProps {
  type: CommonGroundType;
  height?: number;
  noPadding?: boolean;
}

export const CommonGroundContainer = styled.div<CommonGroundContainerProps>`
  background-color: ${({ type }) => commonGroundColors[type]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ noPadding }) => (noPadding ? '0px' : '25px')} 88px;
  position: relative;
  width: 100%;

  min-height: 300px;

  ${({ height }) => height && `height: ${height}px;`}

  border-radius: 8px;

  ${({ theme }) =>
    theme.forceMobile &&
    `
    padding: 0px;
    min-height: fit-content;
    height: fit-content;
  `}

  ${BREAKPOINTS.mobile} {
    padding: 0px;
    min-height: fit-content;
    height: fit-content;
  }
`;

const commonGroundArrowColors = {
  languages: '#FFD25F',
  locations: '#FFD25F',
  passions: '#FBAFA1',
  industries: '#CEF29E',
  kyu_skills: '#FFA366',
  clients: '#96D7D9',
  projects: '#9F7CFB',
  passion_companies: '#5478A1',
  roles: '#5478A1',
};

interface CommonGroundArrowsProps {
  type: CommonGroundType;
}

const CommonGroundArrow = styled.div<CommonGroundArrowsProps>`
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 64px;
  background-color: ${({ type }) => commonGroundArrowColors[type]};

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  cursor: pointer;
  z-index: 1;

  ${({ theme }) =>
    theme.forceMobile &&
    `
    display: none;
  `}

  ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

export const CommonGroundArrowUp = styled(CommonGroundArrow)`
  left: 48px;
  top: -20px;
`;

export const CommonGroundArrowDown = styled(CommonGroundArrow)`
  left: 48px;
  bottom: -20px;
`;

export const CommonGroundIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  & > :not(:last-child) {
    margin-bottom: 24px;
  }
  left: 16px;
  position: absolute;
  z-index: 1;

  ${({ theme }) =>
    theme.forceMobile &&
    `
    width: fit-content;

    top: 16px;
    left: 0px;
    right: 0px;

    margin: 0 auto;

    flex-direction: row;
    justify-content: center;

    padding: 5px;
    border-radius: 10px;
    background: ${commonGroundColors[theme.type]};

    & > :not(:last-child) {
      margin-right: 24px;
      margin-bottom: 0px;
    }
  `}

  ${BREAKPOINTS.mobile} {
    width: fit-content;

    top: 16px;
    left: 0px;
    right: 0px;

    margin: 0 auto;

    flex-direction: row;
    justify-content: center;

    padding: 5px;
    border-radius: 10px;
    background: ${({ theme }) => commonGroundColors[theme.type]};

    & > :not(:last-child) {
      margin-right: 24px;
      margin-bottom: 0px;
    }
  }
`;

export const CommonGroundIcon = styled.span`
  cursor: pointer;
`;

export const CommonGroundComponentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

export const CommonGroundSkeletonContainer = styled(SkeletonBackground)`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const CommonGroundSkeletonTotal = styled(SkeletonItem)`
  height: 100px;
  width: 100px;
  border-radius: 1000px;
`;

export const CommonGroundSkeletonTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const CommonGroundSkeletonTextLine = styled.div`
  display: flex;
  flex-direction: row;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

interface CommonGroundSkeletonTextProps {
  width?: number;
}

export const CommonGroundSkeletonText = styled(SkeletonItem)<CommonGroundSkeletonTextProps>`
  height: 24px;
  width: ${({ width }) => width || 125}px;
  border-radius: 1000px;
`;
