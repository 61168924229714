import React from 'react';

export default function Switch({ width = 18, height = 15, fill = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.46561 7.65235H0.363103C0.0568898 7.65235 -0.106477 7.30638 0.0772569 7.06221L0.281496 6.77729C0.81233 6.02429 1.42487 5.31193 2.05783 4.64027L2.0782 4.61997C2.28244 4.37578 2.62953 4.37578 2.83363 4.61997C3.48709 5.29159 4.07914 6.00385 4.63046 6.75699L4.8347 7.04191C5.01843 7.28609 4.85507 7.63205 4.54886 7.63205H3.44635C3.5076 10.6238 5.97823 13.0457 9.00008 13.0457C10.0822 13.0457 11.0828 12.7405 11.9403 12.2113C12.3283 11.9671 12.8182 12.0484 13.145 12.3538C13.5942 12.8016 13.5124 13.5545 12.9612 13.8802C11.8178 14.5926 10.4498 14.9996 9.00011 14.9996C4.89607 15.0402 1.5475 11.7432 1.46583 7.65247L1.46561 7.65235ZM4.85505 2.68641C5.18177 3.01208 5.6717 3.07314 6.05968 2.82895C6.91724 2.29982 7.91777 1.99447 8.99992 1.99447C12.0218 1.99447 14.4924 4.41642 14.5536 7.40816L13.4511 7.40816C13.1449 7.40816 12.9816 7.75413 13.1653 7.9983L13.3695 8.28323C13.9209 9.03622 14.5129 9.74859 15.1664 10.4202C15.3706 10.6644 15.7177 10.6644 15.9218 10.4202L15.9422 10.3999C16.5956 9.72832 17.1877 9.01607 17.7185 8.26292L17.9227 7.978C18.1065 7.73382 17.9431 7.38786 17.6369 7.38786H16.5344C16.4732 3.29706 13.1246 0 9.00011 0C7.55036 0 6.18238 0.407008 5.039 1.11941C4.50806 1.48568 4.40593 2.23867 4.85513 2.68642L4.85505 2.68641Z"
        fill={fill}
      />
    </svg>
  );
}
