import { CopyIcon } from "app/components/shared/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AboutDrawerData } from "interfaces";
import React from "react";
import { AboutDrawerContent } from "./styled";
import MugIcon from "app/components/shared/icons/mug-icon";

interface IProps {
  aboutData: AboutDrawerData;
}

export default function AboutDrawer({ aboutData }: IProps) {
  return (
    <AboutDrawerContent color={aboutData.buttonColor}>
      <div className="title-container">
        <MugIcon /> <h1>{aboutData.title}</h1>
      </div>
      <div className="text-container">
        <p>DESCRIPTION</p>
        <span>{aboutData.description}</span>
      </div>
      <div className="text-container">
        <p>WHERE COMPANY DATA COMES FROM</p>
        <span>{aboutData.whereDataComes}</span>
      </div>
      <div className="text-container">
        <p>THE FUTURE OF COMPANY DATA</p>
        <span>{aboutData.future}</span>
      </div>
      <span>
        Email us at <b>graph@kyu.com</b>{" "}
        <CopyToClipboard text={"graph@kyu.com"} onCopy={() => {}}>
          <button>
            <CopyIcon />
          </button>
        </CopyToClipboard>
      </span>
    </AboutDrawerContent>
  );
}
