import styled from 'styled-components';
import { rotateAnimation, slideToLeftAndRightCardAnimation, slideToLeftAndRightExtraCardAnimation, slideToRightAndLeftCardAnimation, slideToRightAndLeftExtraCardAnimation } from 'styles/animations';
import { BREAKPOINTS } from 'styles/media';

interface SomeoneNewWrapperProps {
  fromPassion?: boolean;
  triggerNext?: boolean;
  triggerPrevious?: boolean;
}

export const SomeoneNewWrapper = styled.div<SomeoneNewWrapperProps>`
  position: relative;
  align-self: center;
  width: fit-content;
  margin: 30px 0px 32px 0px;

  ${BREAKPOINTS.tablet} {
    margin-right: 0px;
  }
`;

export const SomeoneNewBackgroundCard = styled.div`
  position: absolute;
  width: 523px;
  height: 255px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  
  background: #F5FAFF;
  box-shadow: 5px 0px 20px -10px rgba(50, 63, 93, 0.1);
  border-radius: 4px;

  & > :not(:last-child) {
    ${BREAKPOINTS.tablet} {
      margin-bottom: 47px;
    }
  }

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    align-items: flex-start;
    width: 230px;
    height: 290px;
  }

  z-index: 2;
`;

interface SomeoneNewBackgroundCardOneProps {
  triggerNext?: boolean;
  triggerPrevious?: boolean;
}

export const SomeoneNewBackgroundCardOne = styled(SomeoneNewBackgroundCard)<SomeoneNewBackgroundCardOneProps>`
  transform: rotate(5deg);

  ${({ triggerPrevious }) => (triggerPrevious ? slideToRightAndLeftExtraCardAnimation : "")}
  ${({ triggerNext }) => (triggerNext ? slideToLeftAndRightExtraCardAnimation : "")}
`;

interface SomeoneNewBackgroundCardTwoProps {
  triggerChange?: boolean;
}

export const SomeoneNewBackgroundCardTwo = styled(SomeoneNewBackgroundCard)<SomeoneNewBackgroundCardTwoProps>`
  z-index: 1;

  ${({ triggerChange }) => (triggerChange ? rotateAnimation(5, 1) : "")}
`;

export const SomeoneNewBackgroundCardPersonAvatar = styled.div`
  width: 64px;
  height: 64px;

  background: rgba(0, 0, 0, 0.1);
  border: 2px solid #ADADAD;

  border-radius: 1000px;
`;

export const SomeoneNewBackgroundCardQuoteSkeletonContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 4px;
  }

  ${BREAKPOINTS.tablet} {
    width: 100%;
  }
`;

interface SomeoneNewBackgroundCardQuoteSkeletonProps {
  width: number;
}

export const SomeoneNewBackgroundCardQuoteSkeleton = styled.div<SomeoneNewBackgroundCardQuoteSkeletonProps>`
  width: ${({ width }) => width}%;
  height: 14px;
  background: #D9D9D9;
  border-radius: 100px;
`;

interface SomeoneNewContainerProps {
  triggerNext?: boolean;
  triggerPrevious?: boolean;
}

export const SomeoneNewContainer = styled.div<SomeoneNewContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 48px;

  & > :not(:last-child) {
    margin-right: 26px;
    ${BREAKPOINTS.tablet} {
      margin-right: 0px;
      margin-bottom: 24px;
    }
  }

  width: 523px;
  height: 255px;

  background: #F5FAFF;
  box-shadow: 5px 0px 20px -10px rgba(50, 63, 93, 0.1);
  border-radius: 4px;
  position: relative;

  ${BREAKPOINTS.tablet} {
    width: 230px;
    height: 290px;

    flex-direction: column;
    padding: 24px;
    align-items: flex-start;
  }

  z-index: 3;

  ${({ triggerPrevious }) => (triggerPrevious ? slideToRightAndLeftCardAnimation : "")}
  ${({ triggerNext }) => (triggerNext ? slideToLeftAndRightCardAnimation : "")}
  cursor: pointer;
`;

export const SomeoneNewWildcardContainer = styled.div`
  position: absolute;
  width: 121px;
  height: 122px;
  top: -53px;
  right: -53px;
  z-index: 4;

  ${BREAKPOINTS.tablet} {
    display: none;
  }
`;

export const SomeoneNewStartQuoteContainer = styled.div`
  position: absolute;
  top: -20px;
  left: -15px;
  z-index: 1;
`;

export const SomeoneNewEndQuoteContainer = styled.div`
  position: absolute;
  bottom: -15px;
  right: -10px;
  z-index: 1;
`;

export const SomeoneNewTextContainer = styled.div`
  position: relative;
`;

export const SomeoneNewLearnMore = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #FFC229;

  position: absolute;
  bottom: 24px;
  right: 50%;
  transform: translate(50%);
`;

export const SomeoneNewImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${BREAKPOINTS.tablet} {
    align-items: flex-start;
  }
`;

export const SomeoneNewImage = styled.img`
  width: 64px;
  height: 64px;
  border: 2px solid #FFC229;

  border-radius: 1000px;
  object-fit: cover;
`;

export const SomeoneNewBio = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #171C33;
  word-break: break-word;
  margin-top: 25px;
`;

export const SomeoneNewAuthor = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: rgba(23, 28, 51, 0.5);
`;

export const SomeoneNewPrevious = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  left: -16px;
  top: calc(50% - 16px);

  background: #FFFFFF;
  box-shadow: 0px 0px 10px -10px rgba(50, 63, 93, 0.15);
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 4;
  user-select: none;
  cursor: pointer;
`;

export const SomeoneNewNext = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  right: -16px;
  top: calc(50% - 16px);

  background: #FFFFFF;
  box-shadow: 0px 0px 10px -10px rgba(50, 63, 93, 0.15);
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 4;
  user-select: none;
  cursor: pointer;
`;
