import React, { useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';

import ProfileEditInput from 'app/components/CommonStyled/ProfileEditInput';
import { Spinner } from 'app/components/shared/icons';
import { Form, Formik, FormikProps } from 'formik';
import { createClient } from 'services/profile-editor';
import * as Yup from 'yup';

import {
  ProfileEditorDrawerClientsNewClientButton,
  ProfileEditorDrawerClientsNewClientButtonTitle,
  ProfileEditorDrawerClientsNewClientTitle,
  ProfileEditorDrawerClientsNewClientWrapper,
} from './styled';

interface ProfileEditorDrawerClientsNewClientProps {
  onNewClientSubmitted: (client: any) => void;
}

export default function ProfileEditorDrawerClientsNewClient({
  onNewClientSubmitted,
}: Readonly<ProfileEditorDrawerClientsNewClientProps>) {
  const formikRef = useRef<FormikProps<any>>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleCreateClient = async (params) => {
    const name = document.querySelector<HTMLInputElement>('.select-input-collection').value;
    const response = await createClient({ ...params, name });
    return response.data;
  };

  const { mutate } = useMutation(handleCreateClient, {
    onMutate: () => setIsSubmitting(true),
    onSuccess: (data) => {
      setIsSubmitting(false);
      onNewClientSubmitted(data);
    },
  });

  const handleSubmit = (values) => {
    if (isSubmitting) return;
    mutate(values);
  };

  const newClientValidationSchema = Yup.object().shape({
    description: Yup.string().required('Client description is required'),
    url: Yup.string().url('Invalid URL').required('Client website is required'),
  });

  return (
    <Formik
      initialValues={{
        description: null,
        url: null,
      }}
      validationSchema={newClientValidationSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {(props) => (
        <Form>
          <ProfileEditorDrawerClientsNewClientWrapper>
            <ProfileEditorDrawerClientsNewClientTitle>
              You’re the first one to add this client to their profile. The kyu team will need to
              review it before it’s shown on kyu OS.
            </ProfileEditorDrawerClientsNewClientTitle>
            <ProfileEditInput
              name="description"
              type="text"
              label="Describe this business in a few words."
              placeholder="Dunder Mifflin Paper Company, Inc. is a paper and office supplies wholesale company."
              customSublabel="This is only for review purposes; it will not be shown on kyu OS."
              {...props}
            />
            <ProfileEditInput
              name="url"
              type="text"
              label="Website"
              placeholder="https://www.client.com"
              {...props}
            />
            <ProfileEditorDrawerClientsNewClientButton
              disabled={
                !props.isValid ||
                !props.values['description'] ||
                !props.values['url'] ||
                isSubmitting
              }
              type="submit"
              onClick={props.submitForm}
            >
              {!isSubmitting ? (
                <ProfileEditorDrawerClientsNewClientButtonTitle>
                  Submit for review
                </ProfileEditorDrawerClientsNewClientButtonTitle>
              ) : (
                <Spinner />
              )}
            </ProfileEditorDrawerClientsNewClientButton>
          </ProfileEditorDrawerClientsNewClientWrapper>
        </Form>
      )}
    </Formik>
  );
}
