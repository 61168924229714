import styled from 'styled-components';

export const MyProfileHeroCircularProgressContainer = styled.div`
  width: fit-content;
  position: relative;
`;

interface MyProfileHeroCircularProgressAvatarContainerProps {
  degrees: number;
}

export const MyProfileHeroCircularProgressAvatarContainer = styled.div<MyProfileHeroCircularProgressAvatarContainerProps>`
  width: 100%;
  height: 100%;

  position: absolute;
  z-index: 2;

  transform: rotate(${({ degrees }) => degrees + 225}deg);
  transition: transform 3s ease;
  transition-delay: 0.5s;

  & > div {
    transform: rotate(-${({ degrees }) => degrees + 225}deg);
    transition: transform 3s ease;
    transition-delay: 0.5s;
  }
`;

export const MyProfileHeroCircularProgressBackground = styled.div`
  width: ${({ theme }) => theme.circularProgressSize}px;
  height: ${({ theme }) => theme.circularProgressSize}px;

  position: absolute;
  top: 0;
  left: 0;

  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(156, 202, 255, 0) 0%,
      rgba(156, 202, 255, 0.4) 100%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  border-radius: 100%;
`;

export const MyProfileHeroCircularProgressNumber = styled.h3`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;

  color: #ffffff;
`;
