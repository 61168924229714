import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getPersonDetailsCommonGroundDrawer } from 'services/app';

export default function usePersonDetailsCommonGroundDrawer(
  slug: string,
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `person-${slug}-common-ground-drawer`,
    () =>
      getPersonDetailsCommonGroundDrawer(slug).then((response) => {
        if (!response.ok) {
          throw new Error(response.originalError.message);
        }
        return response.data;
      }),
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
