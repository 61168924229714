import React from 'react';

export default function CheckIcon({
  width = 16,
  height = 17,
  background = '#8DA76C',
  fill = '#FFFFFF',
  style = {},
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 1.86364C4.11098 1.86364 1.36364 4.61098 1.36364 8C1.36364 11.389 4.11098 14.1364 7.5 14.1364C10.889 14.1364 13.6364 11.389 13.6364 8C13.6364 4.61098 10.889 1.86364 7.5 1.86364ZM0 8C0 3.85786 3.35786 0.5 7.5 0.5C11.6421 0.5 15 3.85786 15 8C15 12.1421 11.6421 15.5 7.5 15.5C3.35786 15.5 0 12.1421 0 8Z"
        fill={background}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2306 5.19933C11.3986 5.37435 11.3986 5.65074 11.2306 5.82575L6.92017 10.3165C6.68496 10.5615 6.29308 10.5615 6.05788 10.3165L3.81626 7.9811C3.64827 7.80608 3.64827 7.52969 3.81626 7.35467C3.99428 7.16922 4.29087 7.16922 4.46888 7.35467L6.48902 9.45929L10.578 5.19933C10.756 5.01387 11.0526 5.01387 11.2306 5.19933Z"
        fill={fill}
      />
    </svg>
  );
}
