import { SliceCaseReducers, createSelector, createSlice } from '@reduxjs/toolkit';
import { IResource, IResourcePerson } from 'app/components/Resources/ResourceCard';
import { stringToSlug } from 'utils';

export const ResourceVotingCategoriesURLFriendly = {
  APPS_WEBSITES_AND_DIGITAL_EXPERIENCES: 'apps-websites-and-digital-experiences',
  AI_AND_EMERGING_TECH: 'ai-and-emerging-tech',
  MEDIA_AND_ADVERTISING: 'media-and-advertising',
  CREATIVE_AND_SPATIAL_DESIGN: 'creative-and-spatial-design',
  STRATEGY_BRAND_AND_COMMUNICATION: 'strategy-brand-and-communication',
  CULTURE_AND_INCLUSION: 'culture-and-inclusion',
  ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY: 'environmental-and-social-sustainability',
  PUBLIC_AND_SOCIETAL_IMPACT: 'public-and-societal-impact',
  OPERATIONAL_INNOVATION: 'operational-innovation',
  MY_VOTES: 'my-votes',
} as const;

export type ResourceVotingCategoriesURLFriendly =
  (typeof ResourceVotingCategoriesURLFriendly)[keyof typeof ResourceVotingCategoriesURLFriendly];

export const ResourceVotingCategories = {
  APPS_WEBSITES_AND_DIGITAL_EXPERIENCES: 'Apps, Websites & Digital Experiences',
  AI_AND_EMERGING_TECH: 'AI & Emerging Tech',
  MEDIA_AND_ADVERTISING: 'Media & Advertising',
  CREATIVE_AND_SPATIAL_DESIGN: 'Creative & Spatial Design',
  STRATEGY_BRAND_AND_COMMUNICATION: 'Strategy, Brand & Communication',
  CULTURE_AND_INCLUSION: 'Culture & Inclusion',
  ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY: 'Environmental & Social Sustainability',
  PUBLIC_AND_SOCIETAL_IMPACT: 'Public & Societal Impact',
  OPERATIONAL_INNOVATION: 'Operational Innovation',
} as const;

export type ResourceVotingCategories =
  (typeof ResourceVotingCategories)[keyof typeof ResourceVotingCategories];

export const ResourceVotingCategoriesURLFriendlyToCategory = new Map<
  ResourceVotingCategoriesURLFriendly,
  ResourceVotingCategories
>([
  [
    ResourceVotingCategoriesURLFriendly.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
    ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
  ],
  [
    ResourceVotingCategoriesURLFriendly.AI_AND_EMERGING_TECH,
    ResourceVotingCategories.AI_AND_EMERGING_TECH,
  ],
  [
    ResourceVotingCategoriesURLFriendly.MEDIA_AND_ADVERTISING,
    ResourceVotingCategories.MEDIA_AND_ADVERTISING,
  ],
  [
    ResourceVotingCategoriesURLFriendly.CREATIVE_AND_SPATIAL_DESIGN,
    ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN,
  ],
  [
    ResourceVotingCategoriesURLFriendly.STRATEGY_BRAND_AND_COMMUNICATION,
    ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION,
  ],
  [
    ResourceVotingCategoriesURLFriendly.CULTURE_AND_INCLUSION,
    ResourceVotingCategories.CULTURE_AND_INCLUSION,
  ],
  [
    ResourceVotingCategoriesURLFriendly.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
    ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
  ],
  [
    ResourceVotingCategoriesURLFriendly.PUBLIC_AND_SOCIETAL_IMPACT,
    ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT,
  ],
  [
    ResourceVotingCategoriesURLFriendly.OPERATIONAL_INNOVATION,
    ResourceVotingCategories.OPERATIONAL_INNOVATION,
  ],
  [ResourceVotingCategoriesURLFriendly.MY_VOTES, null],
]);

export const ResourceVotingCategoriesToURLFriendly = new Map<
  ResourceVotingCategories,
  ResourceVotingCategoriesURLFriendly
>([
  [
    ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
    ResourceVotingCategoriesURLFriendly.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
  ],
  [
    ResourceVotingCategories.AI_AND_EMERGING_TECH,
    ResourceVotingCategoriesURLFriendly.AI_AND_EMERGING_TECH,
  ],
  [
    ResourceVotingCategories.MEDIA_AND_ADVERTISING,
    ResourceVotingCategoriesURLFriendly.MEDIA_AND_ADVERTISING,
  ],
  [
    ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN,
    ResourceVotingCategoriesURLFriendly.CREATIVE_AND_SPATIAL_DESIGN,
  ],
  [
    ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION,
    ResourceVotingCategoriesURLFriendly.STRATEGY_BRAND_AND_COMMUNICATION,
  ],
  [
    ResourceVotingCategories.CULTURE_AND_INCLUSION,
    ResourceVotingCategoriesURLFriendly.CULTURE_AND_INCLUSION,
  ],
  [
    ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
    ResourceVotingCategoriesURLFriendly.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
  ],
  [
    ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT,
    ResourceVotingCategoriesURLFriendly.PUBLIC_AND_SOCIETAL_IMPACT,
  ],
  [
    ResourceVotingCategories.OPERATIONAL_INNOVATION,
    ResourceVotingCategoriesURLFriendly.OPERATIONAL_INNOVATION,
  ],
  [null, ResourceVotingCategoriesURLFriendly.MY_VOTES],
]);

export interface IResourceVotingModal {
  slug: string;
  show: boolean;
}

export interface ResourceVotingState {
  categories: ResourceVotingCategories[];
  currentCategory: ResourceVotingCategories;
  resources: {
    [key in ResourceVotingCategories]: IResource[];
  };
  votedResources: {
    [key in ResourceVotingCategories]: IResource;
  };
  resourcesByCompany: {
    [key in ResourceVotingCategories]: {
      [key: string]: IResource[];
    };
  };
  modal: IResourceVotingModal;
  showInformationModal: boolean;
}

export const slice = createSlice<ResourceVotingState, SliceCaseReducers<ResourceVotingState>>({
  name: 'resourceVoting',
  initialState: {
    categories: [
      ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
      ResourceVotingCategories.AI_AND_EMERGING_TECH,
      ResourceVotingCategories.MEDIA_AND_ADVERTISING,
      ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN,
      ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION,
      ResourceVotingCategories.CULTURE_AND_INCLUSION,
      ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY,
      ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT,
      ResourceVotingCategories.OPERATIONAL_INNOVATION,
    ],
    currentCategory: ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES,
    resources: {
      [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: [],
      [ResourceVotingCategories.AI_AND_EMERGING_TECH]: [],
      [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: [],
      [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: [],
      [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: [],
      [ResourceVotingCategories.CULTURE_AND_INCLUSION]: [],
      [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: [],
      [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: [],
      [ResourceVotingCategories.OPERATIONAL_INNOVATION]: [],
    },
    votedResources: {
      [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: null,
      [ResourceVotingCategories.AI_AND_EMERGING_TECH]: null,
      [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: null,
      [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: null,
      [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: null,
      [ResourceVotingCategories.CULTURE_AND_INCLUSION]: null,
      [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: null,
      [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: null,
      [ResourceVotingCategories.OPERATIONAL_INNOVATION]: null,
    },
    resourcesByCompany: {
      [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: {},
      [ResourceVotingCategories.AI_AND_EMERGING_TECH]: {},
      [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: {},
      [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: {},
      [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: {},
      [ResourceVotingCategories.CULTURE_AND_INCLUSION]: {},
      [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: {},
      [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: {},
      [ResourceVotingCategories.OPERATIONAL_INNOVATION]: {},
    },
    modal: {
      slug: null,
      show: false,
    },
    showInformationModal: false,
  },
  reducers: {
    setCurrentCategory: (state, action: { payload: ResourceVotingCategories }) => ({
      ...state,
      currentCategory: action.payload,
    }),
    setResources: (
      state,
      action: {
        payload: {
          [key in ResourceVotingCategories]: IResource[];
        };
      }
    ) => {
      const resourcesByCompany = {
        [ResourceVotingCategories.APPS_WEBSITES_AND_DIGITAL_EXPERIENCES]: {},
        [ResourceVotingCategories.AI_AND_EMERGING_TECH]: {},
        [ResourceVotingCategories.MEDIA_AND_ADVERTISING]: {},
        [ResourceVotingCategories.CREATIVE_AND_SPATIAL_DESIGN]: {},
        [ResourceVotingCategories.STRATEGY_BRAND_AND_COMMUNICATION]: {},
        [ResourceVotingCategories.CULTURE_AND_INCLUSION]: {},
        [ResourceVotingCategories.ENVIRONMENTAL_AND_SOCIAL_SUSTAINABILITY]: {},
        [ResourceVotingCategories.PUBLIC_AND_SOCIETAL_IMPACT]: {},
        [ResourceVotingCategories.OPERATIONAL_INNOVATION]: {},
      };

      Object.keys(action.payload).forEach((category) => {
        const resources: IResource[] = action.payload[category];

        resourcesByCompany[category] = resources.reduce((acc, resource) => {
          resource.contributors.companies.forEach((company) => {
            if (!acc[company.slug]) {
              acc[company.slug] = [];
            }

            acc[company.slug].push(resource);
          });

          resource.contributors.people.forEach((person: any) => {
            if (!acc[stringToSlug(person.company_slug)]) {
              acc[stringToSlug(person.company_slug)] = [];
            }

            if (!acc[stringToSlug(person.company_slug)].find((r) => r.slug === resource.slug)) {
              acc[stringToSlug(person.company_slug)].push(resource);
            }
          });

          return acc;
        }, {});
      });

      return {
        ...state,
        resources: action.payload,
        resourcesByCompany,
      };
    },
    setVotedCategory: (
      state,
      action: {
        payload: {
          category: ResourceVotingCategories;
          resource: IResource;
        };
      }
    ) => ({
      ...state,
      votedResources: {
        ...state.votedResources,
        [action.payload.category]: action.payload.resource,
      },
    }),
    setVotedResources: (
      state,
      action: {
        payload: {
          [key in ResourceVotingCategories]: IResource;
        };
      }
    ) => ({
      ...state,
      votedResources: action.payload,
    }),
    pushVotedResource: (
      state,
      action: {
        payload: {
          category: ResourceVotingCategories;
          resource: IResource;
        };
      }
    ) => ({
      ...state,
      votedResources: {
        ...state.votedResources,
        [action.payload.category]: action.payload.resource,
      },
    }),
    setModal: (
      state,
      action: {
        payload: {
          slug: string;
          show: boolean;
        };
      }
    ) => ({
      ...state,
      modal: action.payload,
    }),
    setShowInformationModal: (state, action: { payload: boolean }) => ({
      ...state,
      showInformationModal: action.payload,
    }),
  },
});

export const {
  setCurrentCategory,
  setResources,
  setVotedCategory,
  setVotedResources,
  pushVotedResource,
  setModal,
  setShowInformationModal,
} = slice.actions;

export const categoriesSelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.categories;

export const currentCategorySelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.currentCategory;

export const resourcesSelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.resources;

export const votedResourcesSelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.votedResources;

export const modalSelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.modal;

export const showInformationModalSelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.showInformationModal;

export const resourcesByCompanySelector = (state: { resourceVoting: ResourceVotingState }) =>
  state.resourceVoting.resourcesByCompany;

export const filledResourcesSelector = createSelector(
  votedResourcesSelector,
  (votedResources) =>
    Object.keys(votedResources).filter(
      (category) => votedResources[category]
    ) as ResourceVotingCategories[]
);

export default slice.reducer;
