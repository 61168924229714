import { formatDate } from "utils/date-format";

// group highlight result page
const known_group_highlights = ({
  shared_clients_count,
  shared_passions_count,
  shared_skills_count,
  uniq_clients_count,
  uniq_passions_count,
  uniq_skills_count,
}) => {
  const shared_skills =
    shared_skills_count > 0
      ? `<span class="highlight-skills">${shared_skills_count} ${
          shared_skills_count > 1 ? "skills" : "skill"
        }</span>`
      : "";
  const shared_passions =
    shared_passions_count > 0
      ? `<span class="highlight-passion">${shared_passions_count} ${
          shared_passions_count > 1 ? "passions" : "passion"
        }</span>`
      : "";
  const shared_clients =
    shared_clients_count > 0
      ? `<span class="highlight-kyu-company">${shared_clients_count} ${
          shared_clients_count > 1 ? "clients" : "client"
        }</span>`
      : "";

  const unique_skills =
    uniq_skills_count > 0
      ? `<span class="highlight-skills">${uniq_skills_count} ${
          uniq_skills_count > 1 ? "skills" : "skill"
        }</span>`
      : "";
  const unique_passions =
    uniq_passions_count > 0
      ? `<span class="highlight-passion">${uniq_passions_count} ${
          uniq_passions_count > 1 ? "passions" : "passion"
        }</span>`
      : "";
  const unique_clients =
    uniq_clients_count > 0
      ? `<span class="highlight-kyu-company">${uniq_clients_count} ${
          uniq_clients_count > 1 ? "clients" : "client"
        }</span>`
      : "";

  const shared_array = [shared_skills, shared_passions, shared_clients].filter(
    (n) => n
  );
  const unique_array = [unique_skills, unique_passions, unique_clients].filter(
    (n) => n
  );

  let first_section = "";
  let second_section = "";

  if (shared_array.length === 0) {
    first_section =
      "This group doesn't share any Skills, Passions or Clients, ";
  } else if (shared_array.length === 1) {
    first_section = `This group shares ${shared_array[0]}, `;
  } else {
    first_section = `This group shares ${shared_array
      .slice(0, shared_array.length - 1)
      .join(", ")} and ${shared_array[shared_array.length - 1]}, `;
  }

  //unique_array[0].insert(unique_array[0].rindex(/\d+/) + 1, " unique") if !unique_array.blank?

  if (unique_array.length === 0) {
    second_section =
      "and doesn't bring any unique Skills, Passions or Clients.";
  } else if (unique_array.length === 1) {
    second_section = `however as individuals, they bring ${unique_array[0]} to the table.`;
  } else {
    second_section = `however as individuals, they bring ${unique_array
      .slice(0, unique_array.length - 1)
      .join(", ")} and ${unique_array[unique_array.length - 1]} to the table.`;
  }

  let response;
  response = "<p>";
  if (unique_array.length === 0 && shared_array.length === 0) {
    response +=
      "There's no relevant common or unique Skills, Passions or Clients for this group.";
  } else {
    response += first_section + second_section;
  }
  response += "</p>";

  return response;
};

const known_group_kyu_skill_highlights = ({
  kyu_companies_count,
  people_with_skill,
  related_people_count,
  related_skills_count,
}) => {
  const first_paragraph = `<p>Across kyu, <span class="highlight-people">${related_people_count} people</span> from <span class="highlight-kyu-company"> \
                    ${kyu_companies_count} companies</span> and <span class="highlight-skills"> \
                    ${related_skills_count} skills</span> are linked to this skill group.</p>`;

  let second_paragraph = "";

  const people_search_count = people_with_skill.length;

  if (related_people_count === people_search_count) {
    second_paragraph +=
      '<p>In this map, <span class="highlight-people">everyone</span> in the group have noted this as a skill in common.</p>';
  } else if (people_with_skill.length > 1) {
    const people_array = people_with_skill.map(
      (person) => `<span class="highlight-people">${person.first_name}</span>`
    );
    second_paragraph += "<p>";
    second_paragraph += `${people_array
      .slice(0, people_array.length - 1)
      .join(", ")} and ${
      people_array[people_array.length - 1]
    } have noted this as a skill in common.`;
    second_paragraph += "</p>";
  } else {
    second_paragraph += `<p><span class="highlight-people">${people_with_skill[0].first_name} ${people_with_skill[0].last_name}</span> is the only one in the group that has noted this skill.</p>`;
  }

  return first_paragraph + second_paragraph;
};

const known_group_passions_highlights = ({
  kyu_companies_count,
  people_with_passion,
  people_with_passion_count,
  related_people_count,
  passion_name,
  searched_people_count,
}) => {
  const first_paragraph = `<p>Across kyu, <span class="highlight-people">${related_people_count} people</span> from <span class="highlight-kyu-company"> \
                    ${kyu_companies_count} companies</span> are linked to this passion.</p>`;

  let second_paragraph = "";

  const people_search_count = people_with_passion_count;

  if (searched_people_count === people_search_count) {
    second_paragraph += `<p>In this map, <span class="highlight-people">everyone</span> in the group have noted ${passion_name} as a passion.</p>`;
  } else if (people_with_passion_count > 1) {
    let people_array = people_with_passion.map(
      (person) => `<span class="highlight-people">${person.first_name}</span>`
    );
    let last_person = people_array.pop();
    second_paragraph += "<p>";
    second_paragraph += `${people_array.join(
      ", "
    )} and <span class="highlight-people">${last_person}</span> have noted ${passion_name} in common.`;
    second_paragraph += "</p>";
  } else {
    const person = people_with_passion[0];
    second_paragraph += `<p><span class="highlight-people">${person.first_name} ${person.last_name}</span> is the only one in the group that has noted this passion.</p>`;
  }

  return first_paragraph + second_paragraph;
};

const known_group_kyu_company_highlights = ({
  kyu_company_name,
  people_with_kyu_company,
  people_with_kyu_company_count,
  related_people_count,
  searched_people_count,
}) => {
  const first_paragraph = `<p>Across kyu, <span class="highlight-people">${related_people_count} people</span> are connected \
                    to <span class="highlight-kyu-company">${kyu_company_name}</span>.</p>`;

  let second_paragraph = "";

  if (people_with_kyu_company_count === searched_people_count) {
    second_paragraph += `<p>In this map, <span class="highlight-people">everyone</span> in the group share \
                        <span class="highlight-kyu-company">${kyu_company_name}</span>.</p>`;
  } else if (people_with_kyu_company_count > 1) {
    const people_array = people_with_kyu_company.map(
      (person) => `<span class="highlight-people">${person.first_name}</span>`
    );
    second_paragraph += `<p>`;
    second_paragraph += `${people_array
      .slice(0, people_array.length - 1)
      .join(", ")} `;
    second_paragraph += `and ${
      people_array[people_array.length - 1]
    } have noted ${kyu_company_name} in common.`;
    second_paragraph += `</p>`;
  } else {
    const person = people_with_kyu_company[0];
    second_paragraph += `<p><span class="highlight-people">${person.first_name} ${person.last_name}</span> is the only one in the group that has noted this company.</p>`;
  }

  return first_paragraph + second_paragraph;
};

const known_group_project_highlights = ({
  client_company_name,
  end_date,
  people_with_project,
  people_with_project_count,
  searched_people_count,
  start_date,
  project_name,
}) => {
  let first_paragraph = "<p>In this map, ";

  if (!start_date) {
    start_date = {};
  }

  if (!end_date) {
    end_date = {};
  }

  let people_search_count = searched_people_count;

  if (people_with_project_count === people_search_count) {
    first_paragraph += `<span class="highlight-people">everyone</span> in the group `;
  } else if (people_with_project_count > 1) {
    const people_array = people_with_project.map(
      (person) => `<span class="highlight-people">${person.first_name}</span>`
    );
    first_paragraph +=
      `${people_array
        .slice(0, people_array[people_array.length - 1])
        .join(", ")} ` + `and ${people_array[people_array.length - 1]} `;
  } else {
    const person = people_with_project[0];
    first_paragraph += `<span class="highlight-people">${person.first_name} ${person.last_name}</span> is the only one in the group that has `;
  }

  first_paragraph += `worked on <span class="highlight-project">${project_name}</span> for the client <span class="highlight-kyu-company">${client_company_name}</span>. `;

  let start_date_text;
  if (start_date.length == 0) {
    start_date_text = null;
  } else {
    start_date_text = formatDate(start_date);
  }

  let end_date_text;

  if (end_date.length == 0) {
    end_date_text = "is ongoing.";
  } else {
    end_date_text = `it was finished on ${formatDate(end_date)}`;
  }

  const project_dates =
    start_date.length === 0
      ? "The project is ongoing.</p>"
      : `The project start date was ${start_date_text} and ${end_date_text}.</p>`;

  return first_paragraph + project_dates;
};

export {
  known_group_highlights,
  known_group_passions_highlights,
  known_group_kyu_skill_highlights,
  known_group_kyu_company_highlights,
  known_group_project_highlights,
};
