import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

const ProfileEditDataTypePillBackgroundMap = new Map<'selected' | 'unselected' | 'disabled', string>([
  ['selected', 'var(--color-data-people-background)'],
  ['unselected', 'none'],
  ['disabled', 'var(--color-data-alumni-dark-theme-background)']
])

const ProfileEditDataTypePillBackgroundHoverMap = new Map<'selected' | 'unselected' | 'disabled', string>([
  ['selected', 'var(--color-data-people-background-hover)'],
  ['unselected', 'none'],
  ['disabled', 'var(--color-data-alumni-dark-theme-background)']
])

const ProfileEditDataTypePillBorderMap = new Map<'selected' | 'unselected' | 'disabled', string>([
  ['selected', 'unset'],
  ['unselected', '1px solid rgba(23, 28, 51, 0.3)'],
  ['disabled', '1px solid rgba(173, 173, 173, 0.3)']
])

interface ProfileEditDataTypePillWrapperProps {
  type: 'selected' | 'unselected' | 'disabled';
  showDeleteOnHover?: boolean;
}

export const ProfileEditDataTypePillWrapper = styled.div<ProfileEditDataTypePillWrapperProps>`
  width: fit-content;
  min-height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 13px 24px;
  background: ${({ type }) => ProfileEditDataTypePillBackgroundMap.get(type)};
  border: ${({ type }) => ProfileEditDataTypePillBorderMap.get(type)};

  ${({ type }) => type === 'selected' && `
    padding: 5px 24px;
  `}

  &:hover {
    background: ${({ type }) => ProfileEditDataTypePillBackgroundHoverMap.get(type)};
  }

  border-radius: 50px;
  cursor: ${({ type }) => type === 'disabled' ? 'default' : 'pointer'};
  ${({ showDeleteOnHover }) => showDeleteOnHover && `
    &:hover {
      & > .delete {
        display: flex;
      }

      & > .pending-review, .people-count {
        display: none;
      }
    }
  `}
`;

export const ProfileEditDataTypePillDeleteContainer = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 4px;
  }
`;

export const ProfileEditDataTypePillDeleteText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  letter-spacing: 0.07em;
  color: var(--color-error);
`;

export const ProfileEditDataTypePillName = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: left;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const ProfileEditDataTypePillPendingReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 5px;
  }
`;

export const ProfileEditDataTypePillPendingReviewText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-data-community-foreground);
`;

export const ProfileEditDataTypePillPeopleCountText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: rgba(23, 28, 51, 0.5);
`;