import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import CompanyDetailsCommonGround from 'app/components/DetailsPages/Company/CompanyDetailsCommonGround';
import useCompanyGlance from 'app/components/DetailsPages/hooks/company/useCompanyGlance';
import { KyuTeamIdentifier } from 'app/components/Onboarding/steps/Two';
import { RightArrow, Sparkle } from 'app/components/shared/icons';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import useUserData from 'hooks/useUserData';

import {
  AboutKyuOsWayfindingCompanyInformationContainer,
  AboutKyuOsWayfindingCompanyContainer,
  AboutKyuOsWayfindingCompanyTitle,
  AboutKyuOsWayfindingCompanyWrapper,
  AboutKyuOsWayfindingCompanyInformationText,
  AboutKyuOsWayfindingCompanyInformationButton,
  AboutKyuOsWayfindingCompanyInformationButtonText,
  AboutKyuOsWayfindingCompanyCommonGroundContainer,
} from './styled';

export default function AboutKyuOsWayfindingCompany() {
  const companyPageCtaClickedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_COMPANY_PAGE_CTA_CLICKED
  );
  const { push } = useHistory();
  const userData = useUserData();
  const query = useCompanyGlance(userData.kyu_company?.slug, { enabled: false });

  useEffect(() => {
    if (userData && userData.kyu_company && userData.kyu_company.slug) {
      query.refetch();
    }
  }, [userData]);

  const handleButtonClick = () => {
    companyPageCtaClickedBehavioralFunction({
      related_object_uuid: userData.kyu_company?.slug,
    });
    push(`/member-company/${userData.kyu_company?.slug}`);
  };

  return (
    <AboutKyuOsWayfindingCompanyWrapper>
      <AboutKyuOsWayfindingCompanyTitle>
        <Sparkle width={40} height={40} fill="var(--color-primary)" />
        Get to Know: Your Company
      </AboutKyuOsWayfindingCompanyTitle>
      <AboutKyuOsWayfindingCompanyContainer>
        <AboutKyuOsWayfindingCompanyCommonGroundContainer>
          <CompanyDetailsCommonGround query={query} forceMobile />
          <KyuTeamIdentifier text="Get the birds-eye view" />
        </AboutKyuOsWayfindingCompanyCommonGroundContainer>
        <AboutKyuOsWayfindingCompanyInformationContainer>
          <AboutKyuOsWayfindingCompanyInformationText>
            Think of kyu OS as a hub: every company updates their space on kyu OS with information
            about people joining, projects started and more.
            <br />
            <br />
            Stay up to date on the clients, projects and more connected to your company.
          </AboutKyuOsWayfindingCompanyInformationText>
          <AboutKyuOsWayfindingCompanyInformationButton onClick={handleButtonClick}>
            <AboutKyuOsWayfindingCompanyInformationButtonText>
              GO TO YOUR COMPANY PAGE
            </AboutKyuOsWayfindingCompanyInformationButtonText>
            <RightArrow width={16} height={16} fill="var(--color-primary)" />
          </AboutKyuOsWayfindingCompanyInformationButton>
        </AboutKyuOsWayfindingCompanyInformationContainer>
      </AboutKyuOsWayfindingCompanyContainer>
    </AboutKyuOsWayfindingCompanyWrapper>
  );
}
