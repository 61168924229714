import { PersonDetailsReactionsResponse } from 'app/components/DetailsPages/hooks/person/usePersonDetailsLikes';
import {
  IPersonDetailsCommonGroundResponse,
  IPersonDetailsCommunitiesResponse,
  IPersonDetailsConveningsResponse,
  IPersonDetailsHumanAtAGlanceResponse,
  IPersonDetailsPassionsResponse,
  IPersonDetailsProfessionalAtAGlanceResponse,
  IPersonDetailsProjectsResponse,
  IPersonDetailsResponse,
  IPersonDetailsSkillsResponse,
  IPersonDetailsStoriesAndHighlightsResponse,
} from 'app/components/DetailsPages/interfaces/person';

import { http } from './http';

export const getPersonDetails = (slug: string) => {
  return http.get<IPersonDetailsResponse>(`person/${slug}/details`);
};

export const getPersonDetailsHumanAtAGlance = (slug: string) => {
  return http.get<IPersonDetailsHumanAtAGlanceResponse>(`person/${slug}/details/human_at_a_glance`);
};

export const getPersonDetailsProfessionalAtAGlance = (slug: string) => {
  return http.get<IPersonDetailsProfessionalAtAGlanceResponse>(
    `person/${slug}/details/professional_at_a_glance`
  );
};

export const getPersonDetailsPassions = (slug: string) => {
  return http.get<IPersonDetailsPassionsResponse>(`person/${slug}/details/passions`);
};

export const getPersonDetailsSkills = (slug: string) => {
  return http.get<IPersonDetailsSkillsResponse>(`person/${slug}/details/kyu_skills`);
};

export const getPersonDetailsProjects = (slug: string) => {
  return http.get<IPersonDetailsProjectsResponse>(`person/${slug}/details/projects`);
};

export const getPersonDetailsConvenings = (slug: string) => {
  return http.get<IPersonDetailsConveningsResponse>(`person/${slug}/details/convenings`);
};

export const getPersonDetailsCommunities = (slug: string) => {
  return http.get<IPersonDetailsCommunitiesResponse>(`person/${slug}/details/communities`);
};

export const getPersonDetailsStoriesAndHighlights = (slug: string) => {
  return http.get<IPersonDetailsStoriesAndHighlightsResponse>(
    `person/${slug}/details/stories_and_highlights`
  );
};

export const getPersonDetailsCommonGround = (slug: string) => {
  return http.get<IPersonDetailsCommonGroundResponse>(`person/${slug}/details/common_ground`);
};

export const getPersonDetailsLikes = (slug: string) => {
  return http.get<PersonDetailsReactionsResponse>(`reactions/person`, { uuid: slug });
};

export const getPersonDetailsLikesDrawer = (slug: string) => {
  //return http.get<any>(`person/${slug}/likes/drawer`);
  return new Promise<{ data: any }>((resolve) => {
    setTimeout(() => {
      resolve({
        data: {
          resources: [
            {
              uuid: 'bfda772c-085a-41c2-b973-03e50de9ff71',
              slug: 'customer-acquisition-and-retention-using-behavioral-science-to-close-the-gap',
              title:
                'Customer Acquisition \u0026 Retention: Using Behavioral Science to Close the Gap',
              submitted_at: '2024-02-21',
              has_paywall: false,
              go_to_detail_page: true,
              short_description:
                "Customer acquisition and retention are ongoing challenges, but it's time to think differently about the solutions today. \r\n",
              resource_type_name: 'Google Slides',
              resource_category: 'Thought Leadership',
              image:
                'https://lh7-us.googleusercontent.com/docs/AHkbwyIDe0jNOByuVyN6JRa04duoFQkdWbKQB489vFdM8wG6ygEAWXLDXsuSrJnVeswImB5_DxUmaMJLc1ieqE-_da12TNHG7LT2NqxVNYpfbRDhIg=w1200-h630-p',
              link: 'https://docs.google.com/presentation/d/e/2PACX-1vTGAsblGX1VM33BGO-ApuMTCzJI_83QHzvBL2RiRd4pMtAT0dab2k2wFhZb7CcrK-7ES2MYSo3y4ZxC/embed?start=false\u0026loop=false\u0026delayms=3000',
              tags: ['Behavioural Science'],
              contributors: {
                people: [],
                companies: [
                  {
                    name: 'BEworks',
                    slug: 'beworks',
                    uuid: '879212f7-c5bd-4edf-8495-7745188cd068',
                  },
                ],
                communities: [],
              },
              submitter: null,
              likes: 12,
            },
            {
              uuid: '65904a57-8aa5-43fa-b614-3c7e8cfe8646',
              slug: 'the-marketer-pov',
              title: 'The Marketer POV',
              submitted_at: '2024-02-21',
              has_paywall: false,
              go_to_detail_page: false,
              short_description:
                'Rich Talent Group shares themes from conversations with CMOs.\r\n',
              resource_type_name: 'Link',
              resource_category: 'Thought Leadership',
              image: null,
              link: 'https://www.linkedin.com/pulse/marketer-pov-themes-from-our-conversations-cmos-rich-talent-group%3FtrackingId=oci47XRGSROu%252Fhf%252F%252BO1vhA%253D%253D/?trackingId=oci47XRGSROu%2Fhf%2F%2BO1vhA%3D%3D',
              tags: ['Marketing'],
              contributors: {
                people: [],
                companies: [
                  {
                    name: 'Rich Talent Group',
                    slug: 'rich-talent-group',
                    uuid: '548494a3-59e2-4854-8d5a-6ba05754e7bb',
                  },
                ],
                communities: [],
              },
              submitter: null,
              likes: 1,
            },
            {
              uuid: 'da13b613-1afc-4089-946a-4bb8372970ee',
              slug: 'building-tomorrow-s-boardroom-today',
              title: "Building Tomorrow's Boardroom, Today",
              submitted_at: '2024-02-21',
              has_paywall: false,
              go_to_detail_page: false,
              short_description:
                "RTG's Jana Rich on how to increase access and impact of Latinx and Hispanic leaders in the boardroom\r\n",
              resource_type_name: 'Link',
              resource_category: 'Thought Leadership',
              image:
                'https://media.licdn.com/dms/image/C5612AQHzXZ745tvNIg/article-cover_image-shrink_720_1280/0/1635280785634?e=2147483647\u0026v=beta\u0026t=MaVWTVPMMxy-WVPfBXinn1QdfvwG2ye6CnxskGXg35g',
              link: 'https://www.linkedin.com/pulse/building-tomorrows-boardroom-today-part-iii-jana-rich/',
              tags: ['Rich Talent Group'],
              contributors: {
                people: [
                  {
                    name: 'Shavon Schimmel',
                    slug: 'shavon-schimmel',
                    uuid: '1c771c7d-dbfe-4e4f-983d-695bb858ca6e',
                    role: 'Founder and CEO',
                    company: 'Rich Talent Group',
                    avatar:
                      'https://kyu-dw-dev1.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBakFOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d15be9b56d9fe4bfc63a8e3659635320733e9238/profile_image_13605.png',
                    is_alumni: false,
                    hide_alumni_label: false,
                  },
                ],
                companies: [],
                communities: [],
              },
              submitter: null,
              likes: 9,
            },
          ],
          events: [
            {
              name: 'kyu Share-Out Series: Meet Gehl and Rich Talent Group (RTG) 395 Hudson ',
              uuid: 'fff9ae40-686a-4a1b-8871-891a92818109',
              slug: 'kyu-share-out-series-meet-gehl-and-rich-talent-group-rtg-395-hudson',
              all_day: false,
              draft: false,
              type: null,
              link: null,
              timezone: 'London (GMT +00:00)',
              start_date: 1709813700000,
              end_date: 1709817300000,
              past: true,
              speakers: null,
              kin_convening: false,
              community_convening: true,
              community_slug: '395-hudson-ny',
              community_name: '395 Hudson NY',
            },
            {
              name: 'Black History Month Celebration: Trivia Event ',
              uuid: 'd0e83f26-1c7d-4641-999d-b76c2a0cc33f',
              slug: 'black-history-month-celebration-trivia-event',
              all_day: false,
              draft: false,
              type: null,
              link: null,
              timezone: 'Eastern Time (US \u0026 Canada) (GMT -05:00)',
              start_date: 1709242200000,
              end_date: 1709244900000,
              past: true,
              speakers: [
                {
                  slug: 'cb-medhurst',
                  name: 'CB Medhurst',
                  avatar:
                    'https://kyu-dw-dev1.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBakFOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d15be9b56d9fe4bfc63a8e3659635320733e9238/profile_image_13605.png',
                  company_name: 'SYPartners',
                },
              ],
              kin_convening: false,
              community_convening: true,
              community_slug: '395-hudson-ny',
              community_name: '395 Hudson NY',
            },
          ],
          articles: [
            {
              slug: 'the-big-climate-question',
              uuid: 'fd067781-5c76-430a-9804-634f0d91fa94',
              name: 'The Big Climate Question',
              type: 'articles',
              header_image: null,
              thumbnail_image: null,
              short_description:
                'When it comes to climate action, we’re all a part of the solution. We asked people from around the collective how they’re contributing on a personal level. ',
              contributors: [],
              contributors_more_count: null,
            },
            {
              slug: 'how-we-use-kyu-os',
              uuid: '95907bc6-007d-4dc2-9c94-5270d9ed3151',
              name: 'How We Use kyu OS',
              type: 'articles',
              header_image: null,
              thumbnail_image: null,
              short_description:
                'See some of the ways people around the collective engage with the platform.',
              contributors: [],
              contributors_more_count: null,
            },
            {
              slug: '3-lessons-for-unforgettable-storytelling',
              uuid: '2b64adc8-821d-4245-9003-2dba7afeec11',
              name: '3 Lessons for Unforgettable Storytelling',
              type: 'articles',
              header_image: null,
              thumbnail_image: null,
              short_description:
                'Looking back on the ‘Riveted’ podcast’s first season, series co-creator, Mary Melton, shares three takeaways from her experience.',
              contributors: [
                {
                  first_name: 'Pedro Keebler',
                  last_name: 'Wunsch',
                  full_name: 'Pedro Keebler Wunsch',
                  uuid: '197f7871-43eb-42a4-a7cc-ea463e5cbebd',
                  slug: 'pedro-keebler-wunsch',
                  company_name: 'Godfrey Dadich Partners',
                  role_name: 'Editorial Director',
                  hide_alumni_label: false,
                  avatar:
                    'https://kyu-dw-dev1.herokuapp.com//rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBakFOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d15be9b56d9fe4bfc63a8e3659635320733e9238/profile_image_13605.png',
                  is_alumni: false,
                },
              ],
              contributors_more_count: null,
            },
          ],
        },
      });
    }, 1000);
  });
};

//DRAWERS
export const getPersonDetailsClientsDrawer = (slug: string) => {
  return http.get<any>(`person/${slug}/drawers/clients`);
};

export const getPersonDetailsLanguagesDrawer = (slug: string) => {
  return http.get<any>(`person/${slug}/drawers/languages`);
};

export const getPersonDetailsPassionsDrawer = (slug: string) => {
  return http.get<any>(`person/${slug}/drawers/passions`);
};

export const getPersonDetailsCommonGroundDrawer = (slug: string) => {
  return http.get<any>(`person/${slug}/drawers/common_ground`);
};

export const getPersonDetailsIndustriesDrawer = (slug: string) => {
  return http.get<any>(`person/${slug}/drawers/industries`);
};

export const getPersonDetailsProjectsDrawer = (slug: string) => {
  return http.get<any>(`person/${slug}/drawers/projects`);
};

export const getPersonDetailsSkillsDrawer = (slug: string) => {
  return http.get<any>(`person/${slug}/drawers/kyu_skills`);
};

export const getPersonDetailsLocationsDrawer = (slug: string) => {
  return http.get<any>(`person/${slug}/drawers/locations`);
};
