import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getPersonProfileCompletion } from 'services/user';

export default function useMyProfileCompletion(
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `my-profile-completion`,
    () =>
      getPersonProfileCompletion().then((response) => {
        if (!response.ok) {
          throw new Error(response.originalError?.message);
        }

        return response.data;
      }),
    {
      ...options,
      cacheTime: 600000,
      staleTime: Infinity,
    }
  );
}
