import React, { Suspense, useEffect, useMemo, useState } from 'react';

import { HomeBaseDrawerGlobalDrawersWrapper, HomeBaseDrawerWrapper } from './HomeBaseDrawer/styled';
import { HomeDrawerContext } from './HomeDrawerContext';

const GlobalClientDrawer = React.lazy(
  () => import('app/components/Drawers/GlobalClient/GlobalClientDrawer')
);
const GlobalCompanyDrawer = React.lazy(
  () => import('app/components/Drawers/GlobalCompany/GlobalCompanyDrawer')
);
const GlobalDisciplineDrawer = React.lazy(
  () => import('app/components/Drawers/GlobalDiscipline/GlobalDisciplineDrawer')
);
const GlobalIndustryDrawer = React.lazy(
  () => import('app/components/Drawers/GlobalIndustry/GlobalIndustryDrawer')
);
const GlobalPassionDrawer = React.lazy(
  () => import('app/components/Drawers/GlobalPassion/GlobalPassionDrawer')
);
const GlobalPersonDrawer = React.lazy(
  () => import('app/components/Drawers/GlobalPerson/GlobalPersonDrawer')
);
const GlobalSkillDrawer = React.lazy(
  () => import('app/components/Drawers/GlobalSkill/GlobalSkillDrawer')
);
const HomeClientsDrawer = React.lazy(() => import('./HomeClientsDrawer'));
const HomeCompaniesDrawer = React.lazy(() => import('./HomeCompaniesDrawer'));
const HomeDisciplinesDrawer = React.lazy(() => import('./HomeDisciplinesDrawer'));
const HomeIndustriesDrawer = React.lazy(() => import('./HomeIndustriesDrawer'));
const HomePassionsDrawer = React.lazy(() => import('./HomePassionsDrawer'));
const HomePeopleDrawer = React.lazy(() => import('./HomePeopleDrawer'));
const HomeSkillsDrawer = React.lazy(() => import('./HomeSkillsDrawer'));

interface HomeDrawerProps {
  model:
    | 'people'
    | 'passions'
    | 'skills'
    | 'companies'
    | 'clients'
    | 'industries'
    | 'disciplines'
    | 'wildcard';
  extraParams?: any;
}

export default function HomeDrawer({ model, extraParams }: HomeDrawerProps) {
  const [currentModel, setCurrentModel] = useState<HomeDrawerProps['model']>(model);
  const [globalDrawer, setGlobalDrawer] = useState({
    show: false,
    model: null,
    slug: null,
  });

  useEffect(() => {
    setCurrentModel(model);
  }, [model]);

  const initialValue = useMemo(
    () => ({
      model: currentModel,
      setModel: setCurrentModel,
      setGlobalDrawer,
    }),
    [currentModel, globalDrawer]
  );

  return (
    <HomeDrawerContext.Provider value={initialValue}>
      <Suspense fallback={<HomeBaseDrawerWrapper />}>
        {currentModel === 'people' ? <HomePeopleDrawer {...extraParams} /> : null}
        {currentModel === 'passions' ? <HomePassionsDrawer {...extraParams} /> : null}
        {currentModel === 'skills' ? <HomeSkillsDrawer {...extraParams} /> : null}
        {currentModel === 'companies' ? <HomeCompaniesDrawer {...extraParams} /> : null}
        {currentModel === 'clients' ? <HomeClientsDrawer {...extraParams} /> : null}
        {currentModel === 'industries' ? <HomeIndustriesDrawer {...extraParams} /> : null}
        {currentModel === 'disciplines' ? <HomeDisciplinesDrawer {...extraParams} /> : null}
      </Suspense>
      {globalDrawer.show ? (
        <HomeGlobalDrawers globalDrawer={globalDrawer} setGlobalDrawer={setGlobalDrawer} />
      ) : null}
    </HomeDrawerContext.Provider>
  );
}

function HomeGlobalDrawers({ globalDrawer, setGlobalDrawer }) {
  const handleBackToListAction = () => {
    setGlobalDrawer({ show: false, model: null, slug: null });
  };

  return (
    <HomeBaseDrawerGlobalDrawersWrapper>
      <Suspense fallback={<></>}>
        {globalDrawer.model === 'people' ? (
          <GlobalPersonDrawer
            slug={globalDrawer.slug}
            comingFromList
            backToListAction={handleBackToListAction}
          />
        ) : null}
        {globalDrawer.model === 'passions' ? (
          <GlobalPassionDrawer
            slug={globalDrawer.slug}
            comingFromList
            backToListAction={handleBackToListAction}
          />
        ) : null}
        {globalDrawer.model === 'skills' ? (
          <GlobalSkillDrawer
            slug={globalDrawer.slug}
            comingFromList
            backToListAction={handleBackToListAction}
          />
        ) : null}
        {globalDrawer.model === 'companies' ? (
          <GlobalCompanyDrawer
            slug={globalDrawer.slug}
            comingFromList
            backToListAction={handleBackToListAction}
          />
        ) : null}
        {globalDrawer.model === 'clients' ? (
          <GlobalClientDrawer
            slug={globalDrawer.slug}
            comingFromList
            backToListAction={handleBackToListAction}
          />
        ) : null}
        {globalDrawer.model === 'disciplines' ? (
          <GlobalDisciplineDrawer
            slug={globalDrawer.slug}
            comingFromList
            backToListAction={handleBackToListAction}
          />
        ) : null}
        {globalDrawer.model === 'industries' ? (
          <GlobalIndustryDrawer
            slug={globalDrawer.slug}
            comingFromList
            backToListAction={handleBackToListAction}
          />
        ) : null}
      </Suspense>
    </HomeBaseDrawerGlobalDrawersWrapper>
  );
}
