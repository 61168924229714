import React, { useContext, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

import SideDrawer from 'app/components/CommonStyled/SideDrawer';
import ToggleButton from 'app/components/CommonStyled/ToggleButton';
import {
  IndustryIcon,
  PassionIcon,
  ProjectIcon,
} from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { DetailsPageContext } from 'app/components/DetailsPages/shared/DetailsPageContext';
import PersonIcon from 'app/components/shared/icons/person-icon';
import { User } from 'interfaces';
import { userDataSelector } from 'store/reducers/user-reducer';

import GlobalIndustryDrawer from '../GlobalIndustry/GlobalIndustryDrawer';
import {
  IndustryDrawerPill,
  IndustryDrawerText,
  IndustriesDrawerContainer,
  IndustriesDrawerFiltersContainer,
  IndustriesDrawerFiltersDescription,
  IndustriesDrawerFiltersTitle,
  IndustriesDrawerListContainer,
  IndustryUserAvatar,
  IndustryPercentagesContainer,
  IndustryPercentage,
  IndustryPercentageText,
  IndustriesDrawerSortingContainer,
} from './styled';

interface IndustriesDrawerProps {
  industriesQuery?: UseQueryResult<any, Error>;
  onlyActive?: boolean;
}

type Sorting = 'ASC' | 'DESC';

export default function IndustriesDrawer({
  industriesQuery,
  onlyActive = false,
}: IndustriesDrawerProps) {
  const userData: User = useSelector(userDataSelector);
  const { isLoading, isIdle, data } = industriesQuery;
  const context = useContext(DetailsPageContext);
  const [filterIncludeUser, setFilterIncludeUser] = useState<boolean>(false);
  const [activeFiler, setActiveFilter] = useState<boolean>(onlyActive);
  const [showingIndustryDrawer, setShowingIndustryDrawer] = useState<boolean>(false);
  const [industryDrawerSlug, setIndustryDrawerSlug] = useState<string>('');
  const [sort, setSort] = useState<Sorting>('ASC');

  useEffect(() => {
    if (industriesQuery.isIdle) {
      industriesQuery.refetch();
    }
  }, [industriesQuery]);

  return (
    <IndustriesDrawerContainer showingIndustryDrawer={showingIndustryDrawer}>
      {showingIndustryDrawer ? (
        <GlobalIndustryDrawer
          slug={industryDrawerSlug}
          comingFromList
          backToListAction={() => setShowingIndustryDrawer(false)}
        />
      ) : (
        <>
          <IndustriesDrawerFiltersContainer>
            <IndustriesDrawerFiltersTitle>
              Industries That {context.data.name || userData.kyu_company?.name} Is Connected To
            </IndustriesDrawerFiltersTitle>
            <IndustriesDrawerFiltersDescription>
              {context.data.name || userData.kyu_company?.name}’s impact has been felt in a total of{' '}
              {data?.industries.length} industries through it’s portfolio of client work. Clients
              often fit in more than one industry.
              <br />
              <br />
              You can refine this search using the filters below.
            </IndustriesDrawerFiltersDescription>
          </IndustriesDrawerFiltersContainer>
          <IndustriesDrawerListContainer>
            {!isLoading && !isIdle && data.any_includes_current_person && (
              <ToggleButton
                label={'Only what I share'}
                labelColor={'rgba(23, 28, 51, 0.5)'}
                toggleColor="var(--color-primary)"
                onChange={(checked) => setFilterIncludeUser(checked)}
                checked={filterIncludeUser}
              />
            )}
            {!isLoading && !isIdle ? (
              <ToggleButton
                label={'Only active'}
                labelColor={'rgba(23, 28, 51, 0.5)'}
                toggleColor="var(--color-primary)"
                onChange={(checked) => setActiveFilter(checked)}
                checked={activeFiler}
              />
            ) : null}
            {!isLoading && !isIdle && (
              <IndustriesDrawerSortingContainer style={{ marginBottom: '16px' }}>
                <span>View by</span>
                <span
                  className="sorting"
                  onClick={() => {
                    if (sort === 'ASC') {
                      setSort('DESC');
                    } else {
                      setSort('ASC');
                    }
                  }}
                >
                  Projects
                  <SortingIcon />
                </span>
              </IndustriesDrawerSortingContainer>
            )}
            {!isLoading && !isIdle ? (
              <>
                {data.industries
                  .filter((industry) => {
                    if (filterIncludeUser) {
                      return industry.includes_current_person;
                    }

                    return true;
                  })
                  .filter((industry) => {
                    if (activeFiler) {
                      return industry.active;
                    }

                    return true;
                  })
                  .sort((a, b) => {
                    if (sort === 'ASC') {
                      return a.project_percentage - b.project_percentage;
                    } else {
                      return b.project_percentage - a.project_percentage;
                    }
                  })
                  .map((industry, index) => (
                    <IndustryPill
                      industry={industry}
                      userData={userData}
                      key={index}
                      setIndustryDrawerSlug={(slug) => setIndustryDrawerSlug(slug)}
                      setShowingIndustryDrawer={(show) => setShowingIndustryDrawer(show)}
                    />
                  ))}
              </>
            ) : (
              <></>
            )}
          </IndustriesDrawerListContainer>
        </>
      )}
    </IndustriesDrawerContainer>
  );
}

function IndustryPill({ industry, userData, setIndustryDrawerSlug, setShowingIndustryDrawer }) {
  return (
    <IndustryDrawerPill
      onClick={() => {
        setIndustryDrawerSlug(industry.slug);
        setShowingIndustryDrawer(true);
      }}
    >
      <IndustryIcon width={13} height={13} fill={'#8DA76C'} />
      <IndustryDrawerText>{industry.name}</IndustryDrawerText>
      {industry.includes_current_person && <IndustryUserAvatar src={userData.profile_image} />}
      <IndustryPercentagesContainer>
        <IndustryPercentage>
          <PersonIcon width={14} height={14} fill={'#FFC229'} />
          <IndustryPercentageText color={'#FFC229'}>
            {industry.people_percentage}%
          </IndustryPercentageText>
        </IndustryPercentage>
        <IndustryPercentage>
          <ProjectIcon width={14} height={14} fill={'#392B62'} />
          <IndustryPercentageText color={'#392B62'}>
            {industry.project_percentage}%
          </IndustryPercentageText>
        </IndustryPercentage>
      </IndustryPercentagesContainer>
    </IndustryDrawerPill>
  );
}

function SortingIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46967 2.46967C8.76256 2.17678 9.23744 2.17678 9.53033 2.46967L13.2803 6.21967C13.5732 6.51256 13.5732 6.98744 13.2803 7.28033C12.9874 7.57322 12.5126 7.57322 12.2197 7.28033L9 4.06066L5.78033 7.28033C5.48744 7.57322 5.01256 7.57322 4.71967 7.28033C4.42678 6.98744 4.42678 6.51256 4.71967 6.21967L8.46967 2.46967Z"
        fill="#171C33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53033 15.5303C9.23744 15.8232 8.76256 15.8232 8.46967 15.5303L4.71967 11.7803C4.42678 11.4874 4.42678 11.0126 4.71967 10.7197C5.01256 10.4268 5.48744 10.4268 5.78033 10.7197L9 13.9393L12.2197 10.7197C12.5126 10.4268 12.9874 10.4268 13.2803 10.7197C13.5732 11.0126 13.5732 11.4874 13.2803 11.7803L9.53033 15.5303Z"
        fill="#171C33"
      />
    </svg>
  );
}
