import { IToolDetailsResponse } from "app/components/TheWell/interfaces/tools"
import { http } from "./http"

export const getTheWell = () => {
  return http.get(`the_well`);
}

export const getTools = () => {
  return http.get(`tools`);
}

export const getToolDetails = (slug: string) => {
  return http.get<IToolDetailsResponse>(`tools/${slug}`)
}

export const getArticleDetails = (slug: string) => {
  return http.get(`articles/${slug}`);
}

export const getArticles = (params = {}) => {
  return http.get(`articles`, params);
}