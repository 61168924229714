import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getRoleDescription } from 'services/profile-editor';

export function useRoleDescription(
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `profile-editor-role-description`,
    async () => {
      const [role_description] = await Promise.all([getRoleDescription()]);

      return {
        role_description: role_description.data.role_description || '',
        role_inspo: role_description.data.inspo || [],
      };
    },
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
