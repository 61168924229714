import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const ProfileEditorDrawerPassionsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 73px 48px 0px 39px;

  ${BREAKPOINTS.tablet} {
    padding: 16px;
  }
`;

export const ProfileEditorDrawerPassionsPickedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
`;

//HEADER
export const ProfileEditorDrawerPassionsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ProfileEditorDrawerPassionsHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

export const ProfileEditorDrawerPassionsHeaderDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(23, 28, 51, 0.5);
`;

//FEELING STUCK
export const ProfileEditorDrawerPassionsFeelingStuckWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 22.5px;

  margin: 51px 0px 102px 0px;

  background: var(--color-data-people-background-secondary);
  border-radius: 10px;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  ${BREAKPOINTS.tablet} {
    padding: 30px 13px;
    margin: 32px 0px 32px 0px;
  }
`;

export const ProfileEditorDrawerPassionsFeelingStuckTitle = styled.h4`
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;

  color: #000000;
`;

export const ProfileEditorDrawerPassionsFeelingStuckContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

export const ProfileEditorDrawerPassionsFeelingStuckButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 48px 12px 40px;

  border: 2px solid var(--color-primary);
  border-radius: 100px;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  transition: background 0.2s ease-in-out;
  &:hover {
    background: var(--color-data-people-background-secondary);
  }

  ${BREAKPOINTS.tablet} {
    padding: 12px 32px 12px 24px
  }
`;

export const ProfileEditorDrawerPassionsFeelingStuckButtonText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: var(--color-text);
`;

export const ProfileEditorDrawerPassionsFeelingStuckCloseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  position: absolute;
  top: 10px;
  right: 10px;

  cursor: pointer;
`;

// FEELING STUCK - RANDOM

export const ProfileEditorDrawerPassionsFeelingStuckRandomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 30px;

  margin: 19px 0px 32px 0px;

  background: #FFFFFF;

  box-shadow: 5px 0px 20px 10px rgba(50,63,93,0.1);
  border-radius: 10px;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }

  position: relative;

  ${BREAKPOINTS.tablet} {
    min-width: unset;
    width: 100%;
    padding: 40px 8px 24px 8px;
  }
`;

export const ProfileEditorDrawerPassionsFeelingStuckRandomTitle = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #000000;
`;

export const ProfileEditorDrawerPassionsFeelingStuckRandomPillsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
`;

export const ProfileEditorDrawerPassionsFeelingStuckRandomShowMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  margin: 0 auto;

  & > :not(:last-child) {
    margin-right: 17px;
  }
`;

export const ProfileEditorDrawerPassionsFeelingStuckRandomShowMoreText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #000000;

  cursor: pointer;
`;

// FEELING STUCK - ALL
export const ProfileEditorDrawerPassionsFeelingStuckAllContainer = styled.div`
  min-width: 426px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 15px;

  position: relative;

  margin: 19px 0px 32px 0px;

  background: #FFFFFF;

  box-shadow: 5px 0px 20px 10px rgba(50,63,93,0.1);
  border-radius: 8px;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }

  ${BREAKPOINTS.tablet} {
    min-width: unset;
    width: 100%;
    padding: 40px 8px;
  }
`;

export const ProfileEditorDrawerPassionsFeelingStuckAllCategoriesContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 13px;

    ${BREAKPOINTS.tablet} {
      margin-right: 8.5px;
    }
  }
`;

interface ProfileEditorDrawerPassionsFeelingStuckAllCategoryProps {
  current: boolean;
}

export const ProfileEditorDrawerPassionsFeelingStuckAllCategory = styled.span<ProfileEditorDrawerPassionsFeelingStuckAllCategoryProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ current }) => current ? 'var(--color-text)' : 'var(--color-data-community-background-hover)'};
  cursor: pointer;

  ${BREAKPOINTS.tablet} {
    font-size: 15px;
  }
`;

export const ProfileEditorDrawerPassionsFeelingStuckAllPillsContainer = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
gap: 8px;
`;

// BOTTOM
export const ProfileEditorDrawerPassionsBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const ProfileEditorDrawerPassionsBottomText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #000000;
`;