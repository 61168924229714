export const theme = {
  light: {
    pill: {
      kyu_skill: 'rgba(235, 130, 61, 0.3)',
      client: 'rgba(16, 77, 91, 0.3)',
      industry: 'rgba(141, 167, 108, 0.3)',
      company: 'rgba(84, 120, 161, 0.3)',
      project: 'rgba(57, 43, 98, 0.3)',
      passion: 'rgba(212, 117, 99, 0.3)',
      community: 'rgba(162, 101, 101, 0.3)',
      locations: 'rgba(0,0,0,0)',
      country: 'rgba(255, 194, 41, 0.15)',
      city: 'rgba(255, 194, 41, 0.15)',
      language: 'rgba(255, 194, 41, 0.15)',
      category: 'rgba(57, 43, 98, 0.3)',
      category_secondary: '#F7F7F7',
      person: 'rgba(255,194,41,0.15)',
      convening: 'rgba(162, 101, 101, 0.3)',
      more: '#FFFFFF',
    },
    pillHover: {},
    text: {
      kyu_skill: '#EB823D',
      client: '#104D5B',
      industry: '#8DA76C',
      company: '#5478A1',
      project: '#392B62',
      passion: '#D47563',
      community: '#A26565',
      locations: 'var(--color-text)',
      country: 'rgba(23, 28, 51, 0.5)',
      city: 'rgba(23, 28, 51, 0.5)',
      language: 'rgba(23, 28, 51, 0.5)',
      more: 'var(--color-text)',
      category: '#392B62',
      category_secondary: 'rgba(23, 28, 51, 0.5)',
      person: '#FFC229',
      convening: '#A26565',
    },
    icon: {
      company: '#5478A1',
      client: '#104D5B',
      project: '#392B62',
      industry: '#8DA76C',
      kyu_skill: '#EB823D',
      country: null,
      passion: '#D47563',
      community: '#A26565',
      person: 'rgba(255, 194, 41, 1)',
      locations: 'rgba(255, 194, 41, 1)',
      language: 'rgba(255, 194, 41, 1)',
      city: 'rgba(255, 194, 41, 1)',
      category: null,
      convening: '#A26565',
    },
  },
  'dark-transparent': {
    pill: {
      kyu_skill: 'rgba(255, 163, 102, 0.4)',
      client: 'rgba(150, 215, 217, 0.4)',
      industry: 'rgba(206, 242, 158, 0.4)',
      company: 'rgba(156, 202, 255, 0.4)',
      project: 'rgba(159, 124, 251, 0.4)',
      passion: 'rgba(251, 175, 161, 0.4)',
      community: 'rgba(209, 178, 178, 0.4)',
      locations: 'rgba(255, 210, 95, 0.4)',
      country: 'rgba(255, 210, 95, 0.4)',
      city: 'rgba(255, 210, 95, 0.4)',
      language: 'rgba(255, 210, 95, 0.4)',
      category: 'rgba(57, 43, 98, 0.3)',
      category_secondary: '#F7F7F7',
      person: 'rgba(255, 210, 95, 0.4)',
      convening: 'rgba(209, 178, 178, 0.4)',
      more: 'rgba(255, 255, 255, 0.5)',
    },
    pillHover: {},
    text: {
      kyu_skill: '#FFA366',
      client: '#96D7D9',
      industry: '#CEF29E',
      company: '#9CCAFF',
      project: '#9F7CFB',
      passion: '#FBAFA1',
      community: '#D1B2B2',
      locations: '#FFD25F',
      country: '#FFD25F',
      city: '#FFD25F',
      language: '#FFD25F',
      more: 'rgba(255, 255, 255, 0.5)',
      category: '#392B62',
      category_secondary: 'rgba(23, 28, 51, 0.5)',
      person: '#FFD25F',
      convening: '#D1B2B2',
    },
    icon: {
      company: '#9CCAFF',
      client: '#96D7D9',
      project: '#392B62',
      industry: '#CEF29E',
      kyu_skill: '#FFA366',
      country: null,
      passion: '#FBAFA1',
      community: '#A26565',
      person: 'rgba(255, 194, 41, 1)',
      locations: null,
      language: '#FFD25F',
      city: '#FFD25F',
      category: null,
      convening: '#A26565',
    },
  },
  dark: {
    pill: {
      kyu_skill: '#745247',
      client: '#4A6775',
      industry: '#60725E',
      company: '#4C6285',
      project: '#4D4283',
      passion: '#72575F',
      community: '#615866',
      locations: '#746545',
      country: '#746545',
      city: '#746545',
      language: '#746545',
      category: 'rgba(57, 43, 98, 0.3)',
      category_secondary: '#F7F7F7',
      person: '#746545',
      convening: '#615866',
      more: 'rgba(255, 255, 255, 0.5)',
    },
    pillHover: {},
    text: {
      kyu_skill: '#FFA366',
      client: '#96D7D9',
      industry: '#CEF29E',
      company: '#9CCAFF',
      project: '#9F7CFB',
      passion: '#FBAFA1',
      community: '#D1B2B2',
      locations: '#FFD25F',
      country: '#FFD25F',
      city: '#FFD25F',
      language: '#FFD25F',
      more: 'rgba(255, 255, 255, 0.5)',
      category: '#392B62',
      category_secondary: 'rgba(23, 28, 51, 0.5)',
      person: '#FFD25F',
      convening: '#D1B2B2',
    },
    icon: {
      company: '#9CCAFF',
      client: '#96D7D9',
      project: '#9F7CFB',
      industry: '#CEF29E',
      kyu_skill: '#FFA366',
      country: '#FFD25F',
      passion: '#FBAFA1',
      community: '#A26565',
      person: '#FFD25F',
      locations: '#FFD25F',
      language: '#FFD25F',
      city: '#FFD25F',
      category: null,
      convening: '#A26565',
    },
  },
  'high-contrast': {
    pill: {
      kyu_skill: '#EB823D',
      client: '#104D5B',
      project: '#392B62',
      industry: '#8DA76C',
      country: '#FFC229',
      company: '#5478A1',
      passion: '#D47563',
      community: '#A26565',
      person: '#FFC229',
      locations: '#FFC229',
      language: '#FFC229',
      city: '#FFC229',
      convening: '#A26565',
      article: 'var(--color-primary)',
      tool: 'var(--color-primary)',
      resource: 'var(--color-primary)',
      discipline: '#5478A1',
      more: 'rgba(23, 28, 51, 0.20)',
    },
    pillHover: {
      kyu_skill: '#DE6617',
      passion: '#C9523B',
      industry: '#748C54',
      client: '#082830',
      community: '#865151',
      convening: '#865151',
      article: 'var(--color-primary)',
      tool: 'var(--color-primary)',
      resource: 'var(--color-primary)',
      discipline: '#425F7F',
      more: 'rgba(23, 28, 51, 0.20)',
    },
    text: {
      kyu_skill: 'var(--color-text)',
      client: '#FFFFFF',
      industry: 'var(--color-text)',
      company: '#FFFFFF',
      project: '#FFFFFF',
      passion: 'var(--color-text)',
      community: '#FFFFFF',
      locations: '#FFFFFF',
      country: '#FFFFFF',
      city: '#FFFFFF',
      language: '#FFFFFF',
      more: '#FFFFFF',
      category: '#FFFFFF',
      category_secondary: '#FFFFFF',
      person: 'var(--color-text)',
      convening: '#FFFFFF',
      article: '#FFFFFF',
      tool: '#FFFFFF',
      resource: '#FFFFFF',
      discipline: '#FFFFFF',
      more: 'var(--color-primary)',
    },
    icon: {
      company: '#FFFFFF',
      client: '#FFFFFF',
      project: '#FFFFFF',
      industry: 'var(--color-primary)',
      kyu_skill: 'var(--color-primary)',
      country: '#FFFFFF',
      passion: 'var(--color-primary)',
      community: '#FFFFFF',
      person: 'var(--color-primary)',
      locations: '#FFFFFF',
      language: '#FFFFFF',
      city: '#FFFFFF',
      category: null,
      convening: '#FFFFFF',
      article: '#FFFFFF',
      tool: '#FFFFFF',
      resource: '#FFFFFF',
      discipline: '#FFFFFF',
    },
  },
};
