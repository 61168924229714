import React from 'react';
import { CountBubbleNumber, CountBubbleWrapper } from './styled';

interface CountBubbleProps {
  size: number;
  number: string;
  backgroundColor?: string;
  borderColor?: string;
  borderSize?: number;
  color?: string;
  onClick?: () => void;
}

export default function CountBubble({
  size,
  number,
  backgroundColor = 'rgba(255, 194, 41, 0.15)',
  borderColor = '#FFC229',
  borderSize = 2,
  color = '#FFC229',
  onClick,
}: CountBubbleProps) {
  return (
    <CountBubbleWrapper
      borderColor={borderColor}
      borderSize={borderSize}
      size={size}
      backgroundColor={backgroundColor}
      onClick={onClick}
    >
      <CountBubbleNumber color={color}>{number}</CountBubbleNumber>
    </CountBubbleWrapper>
  );
}
