import React from 'react';

export default function ResourceIcon({ width = 17, height = 16, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75229 1.25278C4.12736 0.877706 4.63607 0.666992 5.1665 0.666992H9.83317C10.01 0.666992 10.1796 0.73723 10.3046 0.862254L14.9712 5.52892C15.0963 5.65394 15.1665 5.82351 15.1665 6.00033V13.3337C15.1665 13.8641 14.9558 14.3728 14.5807 14.7479C14.2056 15.1229 13.6969 15.3337 13.1665 15.3337H5.1665C4.63607 15.3337 4.12736 15.1229 3.75229 14.7479C3.37722 14.3728 3.1665 13.8641 3.1665 13.3337V2.66699C3.1665 2.13656 3.37722 1.62785 3.75229 1.25278ZM5.1665 2.00033C4.98969 2.00033 4.82012 2.07056 4.6951 2.19559C4.57008 2.32061 4.49984 2.49018 4.49984 2.66699V13.3337C4.49984 13.5105 4.57008 13.68 4.6951 13.8051C4.82012 13.9301 4.98969 14.0003 5.1665 14.0003H13.1665C13.3433 14.0003 13.5129 13.9301 13.6379 13.8051C13.7629 13.68 13.8332 13.5105 13.8332 13.3337V6.27647L9.55703 2.00033H5.1665Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83317 0.666992C10.2014 0.666992 10.4998 0.965469 10.4998 1.33366V5.33366H14.4998C14.868 5.33366 15.1665 5.63214 15.1665 6.00033C15.1665 6.36852 14.868 6.66699 14.4998 6.66699H9.83317C9.46498 6.66699 9.1665 6.36852 9.1665 6.00033V1.33366C9.1665 0.965469 9.46498 0.666992 9.83317 0.666992Z"
        fill={fill}
      />
    </svg>
  );
}
