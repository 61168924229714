import styled from 'styled-components';

export const ProfileEditInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ProfileEditInputLabelContainer = styled.div`
  display: flex;
  flex-direction: row;

  position: relative;

  & > :not(:last-child) {
    margin-right: 40px;
  }
`;

export const ProfileEditInputLabelTooltipIconContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const ProfileEditInputLabel = styled.label`
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;

  color: #000000;
`;

export const ProfileEditInputLabelTooltipContainer = styled.div`
  max-width: 199px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;

  background: #000000;

  border-radius: 8px;
`;

export const ProfileEditInputLabelTooltipText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
`;
