import React from 'react';

import { SkeletonItem } from 'app/components/DetailsPages/shared/styled';

import {
  GlobalSkeletonDrawerBottomContainer,
  GlobalSkeletonDrawerMiddleContainer,
  GlobalSkeletonDrawerMiddleCounterContainer,
  GlobalSkeletonDrawerMiddleCounterWrapper,
  GlobalSkeletonDrawerMiddleListContainer,
  GlobalSkeletonDrawerTopContainer,
  GlobalSkeletonDrawerTopWrapper,
  GlobalSkeletonDrawerWrapper,
} from './styled';

export default function GlobalSkeletonDrawer() {
  return (
    <GlobalSkeletonDrawerWrapper>
      <GlobalSkeletonDrawerTopWrapper>
        <GlobalSkeletonDrawerTopContainer>
          <SkeletonItem style={{ width: '136px', height: '16px', borderRadius: '100px' }} />
          <SkeletonItem style={{ width: '259px', height: '32px', borderRadius: '100px' }} />
          <SkeletonItem style={{ width: '185px', height: '20px', borderRadius: '100px' }} />
        </GlobalSkeletonDrawerTopContainer>
        <SkeletonItem style={{ width: '56px', height: '56px', borderRadius: '100%' }} />
      </GlobalSkeletonDrawerTopWrapper>
      <GlobalSkeletonDrawerMiddleContainer>
        <GlobalSkeletonDrawerMiddleListContainer>
          <SkeletonItem style={{ width: '426px', height: '24px', borderRadius: '100px' }} />
          <SkeletonItem style={{ width: '373px', height: '24px', borderRadius: '100px' }} />
          <SkeletonItem style={{ width: '426px', height: '24px', borderRadius: '100px' }} />
          <SkeletonItem style={{ width: '373px', height: '24px', borderRadius: '100px' }} />
          <SkeletonItem style={{ width: '426px', height: '24px', borderRadius: '100px' }} />
          <SkeletonItem style={{ width: '373px', height: '24px', borderRadius: '100px' }} />
        </GlobalSkeletonDrawerMiddleListContainer>
        <GlobalSkeletonDrawerMiddleCounterWrapper>
          {[...Array(3)].map((_, index) => (
            <GlobalSkeletonDrawerMiddleCounterContainer key={index}>
              <SkeletonItem style={{ width: '51px', height: '24px', borderRadius: '100px' }} />
              <SkeletonItem style={{ width: '83px', height: '14px', borderRadius: '100px' }} />
            </GlobalSkeletonDrawerMiddleCounterContainer>
          ))}
        </GlobalSkeletonDrawerMiddleCounterWrapper>
      </GlobalSkeletonDrawerMiddleContainer>
      <GlobalSkeletonDrawerBottomContainer>
        <SkeletonItem style={{ width: '205px', height: '54px', borderRadius: '100px' }} />
        <SkeletonItem style={{ width: '205px', height: '54px', borderRadius: '100px' }} />
      </GlobalSkeletonDrawerBottomContainer>
    </GlobalSkeletonDrawerWrapper>
  );
}
