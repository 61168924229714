import styled from 'styled-components';

export const SearchListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0px 24px 24px 18px;

  & > :not(:last-child) {
    margin-bottom: 6px;
  }

  max-height: 476px;
  overflow-y: auto;

  z-index: 1000;
`;

export const SearchListItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SearchListItem = styled.span`
  color: #000;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }

  & > span {
    color: #000;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    text-align: left;
  }

  & > span > span.highlighted {
    font-weight: 700;
  }

  cursor: pointer;
`;

export const SearchListItemInfo = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: #ADADAD;
  text-align: right;
  max-width: 50%;
`;