import { PayloadAction, SliceCaseReducers, createSlice, nanoid } from '@reduxjs/toolkit';

export const FREELANCERS_TEAM = 'Freelancers';

export interface ITeamingContactEmail {
  subject: string;
  message: string;
}

export interface TeamingState {
  emails: { [key: string]: ITeamingContactEmail };
  cc: string[];
}

export const slice = createSlice<TeamingState, SliceCaseReducers<TeamingState>>({
  name: 'teaming',
  initialState: {
    emails: {},
    cc: [],
  },
  reducers: {
    resetEmails: (state) => {
      state.emails = {};
    },
    setEmails: (state, action: PayloadAction<{ emails: { [key: string]: ITeamingContactEmail } }>) => {
      state.emails = action.payload.emails;
    },
    setEmail: (
      state,
      action: PayloadAction<{ company: string; subject: string; message: string }>
    ) => {
      state.emails[action.payload.company] = {
        subject: action.payload.subject,
        message: action.payload.message,
      };
    },
    updateCompanyEmail: (
      state,
      action: PayloadAction<{ company: string; subject: string; message: string }>
    ) => {
      if (!state.emails[action.payload.company]) {
        state.emails[action.payload.company] = {
          subject: '',
          message: '',
        };
      }

      state.emails[action.payload.company].subject = action.payload.subject;
      state.emails[action.payload.company].message = action.payload.message;
    },
    setCC: (state, action: PayloadAction<string[]>) => {
      state.cc = action.payload;
    },
    pushCC: (state, action: PayloadAction<string>) => {
      state.cc.push(action.payload);
    },
  },
});

export const { resetEmails, setEmails, setEmail, updateCompanyEmail, setCC, pushCC } = slice.actions;

export const emailsSelector = (state: { teaming: TeamingState }) => state.teaming.emails;
export const CCSelector = (state: { teaming: TeamingState }) => state.teaming.cc;

export default slice.reducer;
