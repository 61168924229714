import { motion } from 'framer-motion';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const PeopleBannerWrapper = styled.div`
  position: sticky;
  bottom: calc(21px + ${({ theme }) => (!theme.isAboutKyuOsOnboardingHidden ? '78px' : '0px')});
  left: 0px;
  right: 0px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 21px;

  padding: 16px 24px;

  border-radius: 8px;
  background: var(--color-data-skill-background);
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: 1320px) {
    flex-wrap: wrap;
    gap: 16px;
  }

  ${BREAKPOINTS.tablet} {
    left: 16px;
    right: unset;
    bottom: calc(16px + 56px);

    width: calc(100vw - 16px);

    margin-bottom: 0px;

    transform: translateX(-8px);

    padding: 16px;
  }
`;

export const PeopleBannerTitle = styled.h4`
  max-width: 119px;

  font-weight: 400;
  font-size: 24px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    max-width: 98px;
    font-size: 20px;
  }

  color: var(--color-text);
`;

export const PeopleBannerButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1320px) {
    width: 100%;
  }

  gap: 8px;
`;

export const PeopleBannerRefineResultsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  padding: 16px 48px;

  border-radius: 100px;
  border: 2px solid var(--color-primary);

  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  text-align: center;

  color: var(--color-text);

  @media (max-width: 1320px) {
    flex: 1;
  }

  ${BREAKPOINTS.tablet} {
    padding: 8px;

    text-transform: uppercase;
  }
`;

export const PeopleBannerSelectRecipientsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  padding: 16px 48px;

  border-radius: 100px;
  background: var(--color-primary);
  border: 2px solid var(--color-primary);

  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  text-align: center;

  color: #ffffff;

  @media (max-width: 1320px) {
    flex: 1;
  }

  ${BREAKPOINTS.tablet} {
    padding: 8px;

    text-transform: uppercase;
  }
`;

//STEPPERS
export const PeopleBannerSteppersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PeopleBannerSteppersTopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PeopleBannerSteppersBottomRow = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${BREAKPOINTS.tablet} {
    & > span {
      width: 60px;
    }

    & > span:not(:last-child) {
      margin-right: 16.5px !important;
    }

    & > span:last-child {
      margin-right: 0px !important;
    }
  }
`;

export const PeopleBannerStepperSeparator = styled.div`
  width: 94px;
  height: 1px;
  background: rgba(23, 28, 51, 0.5);

  ${BREAKPOINTS.tablet} {
    width: 53px;
  }
`;

interface PeopleBannerStepperIconContainerProps {
  active?: boolean;
}

export const PeopleBannerStepperIconContainer = styled.div<PeopleBannerStepperIconContainerProps>`
  width: 36px;
  height: 36px;

  ${BREAKPOINTS.tablet} {
    width: 24px;
    height: 24px;

    & > svg {
      min-width: 13px;
      min-height: 13px;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;

  border-radius: 100%;

  ${({ active }) =>
    active
      ? `
    background: var(--color-primary);
  `
      : `
    border: 1px solid rgba(23, 28, 51, 0.50);
  `}
`;

interface PeopleBannerStepperTitleProps {
  highlight?: boolean;
}

export const PeopleBannerStepperTitle = styled.span<PeopleBannerStepperTitleProps>`
  font-weight: 700;
  font-size: 12px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    text-align: center;
  }

  color: ${({ highlight }) => (highlight ? `var(--color-text)` : `rgba(23, 28, 51, 0.5)`)};
`;

//BEACON
export const PeopleBannerBeaconWrapper = styled(motion.div)`
  width: calc(100% + 20px);
  height: calc(100% + 18px);

  position: absolute;
  top: -9px;
  left: -10px;

  border-radius: 23px;
  border: 2px solid var(--data-company-background-hover, #7cace1);
  background: rgba(255, 255, 255, 0.01);
  box-shadow: 0px 0px 100px 0px #7cace1;
`;

export const PeopleBannerBeaconContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 50%;

  & > svg {
    position: relative;
  }

  &:before {
    content: '';

    width: calc(100% - 10px);
    height: calc(100% - 30px);

    position: absolute;
    top: 0px;
    left: 0px;

    border-radius: 16px;
    backdrop-filter: blur(20px);
    transform: translate(5px, 0px);
  }

  transform: translate(-50%, -100%);
  z-index: 2;
`;

export const PeopleBannerBeaconLabel = styled.span`
  width: 255px;

  position: absolute;
  top: 16px;
  left: 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--color-text);

  z-index: 4;
`;

export const PeopleBannerBeaconNext = styled.div`
  position: absolute;
  top: -5px;
  right: 0px;

  & > svg {
    position: relative;
  }

  &:before {
    content: '';

    width: 24px;
    height: 25px;

    position: absolute;
    top: 0px;
    left: 0px;

    border-radius: 100%;
    backdrop-filter: blur(20px);
  }

  z-index: 4;

  cursor: pointer;
`;
