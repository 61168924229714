import { ApiResponse } from 'apisauce';
import { TeamingEmailParams } from 'app/components/Teaming/hooks/useTeamingEmail';
import { TeamingLeadersResponse } from 'app/components/Teaming/hooks/useTeamingLeaders';
import { ICollectiveDirectoryPersonDrawerResponse } from 'interfaces';
import { IPeopleFilter, IPeopleOnboarding } from 'store/reducers/people/people-reducer';

import { http } from './http';

export const getPeople = (filters = null, per_page, teaming = null) => {
  return http.post('/collective_index', { ...filters, samples: per_page, teaming });
};

export const getPersonDrawer = (uuid: string) => {
  return http.get<ICollectiveDirectoryPersonDrawerResponse>(`/collective_index/person_drawer`, {
    uuid,
  });
};

export const getSamples = (uuids: string[]): Promise<ApiResponse<unknown, unknown>> => {
  if (uuids.length === 0) throw new Error('uuids is empty');
  return http.post('/collective_index/samples', { uuids });
};

export const getFilters = (type = null) => {
  return http.get<IPeopleFilter[]>('/collective_index/filters', { type });
};

export const getSearch = (term: string, teaming?: boolean, exclude_uuids?: string[]) => {
  if (!teaming) return http.get('/collective_index/search', { term, exclude_uuids });
  return http.get('/collective_index/search', { term, teaming, exclude_uuids});
};

export const postContactEmail = (data: { subject: string; message: string; uuids: string[] }) => {
  return http.post('/mailer/contact', data);
};

export const getPeopleOnboarding = () => {
  return http.get<IPeopleOnboarding>('/collective_index/onboarding');
};

export const getTeamingLeaders = (uuids: string[] = []) => {
  return http.post<TeamingLeadersResponse>('/collective_index/teaming_leaders', { uuids });
};

export const postTeamingEmail = (params: TeamingEmailParams) => {
  return http.post('/mailer/teaming_contact', params);
};
