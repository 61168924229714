import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getClientDrawerData } from 'services/app';

export interface ClientDrawerResponse {
  name: string;
  slug: string;
  uuid: string;
  includes_current_person: boolean;
  highlight: Highlight;
  counters: Counters;
}

export interface Highlight {
  companies_count: number;
  company_sample: any;
  projects_count: number;
  project_sample: any;
  people_count: number;
  person_sample: PersonSample;
  active_projects_count: number;
  active_project_sample: any;
  active_companies: any[];
  last_finished_project: any;
  industries_count?: number;
  person_projects_count?: number;
}

export interface PersonSample {
  name: string;
  slug: string;
  uuid: string;
  type: string;
  shared: boolean;
}

export interface Counters {
  people_count: number;
  companies_count: number;
  projects_count: number;
  industries_count?: number;
}

export default function useClientDrawerData(
  clientSlug: string,
  options?: Omit<
    UseQueryOptions<ClientDrawerResponse, Error, ClientDrawerResponse, QueryKey>,
    'queryKey'
  >
) {
  return useQuery<ClientDrawerResponse, Error>(
    `client-${clientSlug}-drawer-data`,
    () =>
      getClientDrawerData(clientSlug).then((response) => {
        if (!response.ok) {
          throw new Error(response.originalError.message);
        }
        return response.data;
      }),
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
