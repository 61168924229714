import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ListChecksIcon, PaperPlaneIcon, PencilSimpleLineIcon } from 'app/components/shared/icons';
import useIsMobile from 'hooks/useIsMobile';
import { setForceDropdownFilterOpen } from 'store/reducers/people/people-reducer';

import {
  ContactCTAStepperIconContainer,
  ContactCTASteppersBottomRow,
  ContactCTAStepperSeparator,
  ContactCTASteppersTopRow,
  ContactCTASteppersWrapper,
  ContactCTAWrapper,
  ContactCTAStepperTitle,
  ContactCTAInformationContainer,
  ContactCTAInformationTitle,
  ContactCTAInformationWrapper,
  ContactCTAInformationDescription,
  ContactCTAButton,
  ContactCTAButtonText,
} from './styled';

interface ContactCTAProps {
  description?: string;
  onClick?: () => void;
  behavioralFunction?: () => void;
}

export default function ContactCTA({
  description = 'Need to get in touch with several people here?',
  behavioralFunction,
  onClick,
}: ContactCTAProps) {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const handleButtonClick = () => {
    behavioralFunction?.();
    if (onClick) return onClick();

    dispatch(setForceDropdownFilterOpen(true));
    push('/people');
  };

  return (
    <ContactCTAWrapper>
      <ContactCTAInformationWrapper>
        <ContactCTAInformationContainer>
          <ContactCTAInformationTitle>COLLECTIVE DIRECTORY</ContactCTAInformationTitle>
          <ContactCTAInformationDescription>{description}</ContactCTAInformationDescription>
        </ContactCTAInformationContainer>
        <ContactCTASteppersWrapper>
          <ContactCTASteppersTopRow>
            <ContactCTAStepperIconContainer active>
              <ListChecksIcon width={13} height={13} />
            </ContactCTAStepperIconContainer>
            <ContactCTAStepperSeparator />
            <ContactCTAStepperIconContainer>
              <PencilSimpleLineIcon width={13} height={13} fill="rgba(23, 28, 51, 0.5)" />
            </ContactCTAStepperIconContainer>
            <ContactCTAStepperSeparator />
            <ContactCTAStepperIconContainer>
              <PaperPlaneIcon width={13} height={13} fill="rgba(23, 28, 51, 0.5)" />
            </ContactCTAStepperIconContainer>
          </ContactCTASteppersTopRow>
          {!isMobile ? (
            <ContactCTASteppersBottomRow>
              <ContactCTAStepperTitle highlight>Select recipients</ContactCTAStepperTitle>
              <ContactCTAStepperTitle>Write your message</ContactCTAStepperTitle>
              <ContactCTAStepperTitle>Send it out!</ContactCTAStepperTitle>
            </ContactCTASteppersBottomRow>
          ) : null}
        </ContactCTASteppersWrapper>
      </ContactCTAInformationWrapper>
      <ContactCTAButton onClick={handleButtonClick}>
        <ContactCTAButtonText>GET STARTED</ContactCTAButtonText>
      </ContactCTAButton>
    </ContactCTAWrapper>
  );
}
