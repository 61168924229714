import React, { useContext, useEffect, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';

import Tippy from '@tippyjs/react';
import SideDrawer from 'app/components/CommonStyled/SideDrawer';
import ClientsDrawer from 'app/components/Drawers/Clients/ClientsDrawer';
import GlobalClientDrawer from 'app/components/Drawers/GlobalClient/GlobalClientDrawer';
import GlobalIndustryDrawer from 'app/components/Drawers/GlobalIndustry/GlobalIndustryDrawer';
import GlobalPassionDrawer from 'app/components/Drawers/GlobalPassion/GlobalPassionDrawer';
import GlobalProjectDrawer from 'app/components/Drawers/GlobalProject/GlobalProjectDrawer';
import GlobalSkillDrawer from 'app/components/Drawers/GlobalSkill/GlobalSkillDrawer';
import IndustriesDrawer from 'app/components/Drawers/Industries/IndustriesDrawer';
import LanguagesDrawer from 'app/components/Drawers/Languages/LanguagesDrawer';
import LocationsDrawer from 'app/components/Drawers/Locations/LocationsDrawer';
import PassionsDrawer from 'app/components/Drawers/Passions/PassionsDrawer';
import ProjectsDrawer from 'app/components/Drawers/Projects/ProjectsDrawer';
import SkillsDrawer from 'app/components/Drawers/Skills/SkillsDrawer';
import { DETAILS_PAGE_ACTION } from 'app/pages/NewDetailsPage/actions';
import useUserData from 'hooks/useUserData';
import { ThemeProvider } from 'styled-components';
import { followCursor } from 'tippy.js';
import { countryCodeEmoji } from 'utils';

import useClientsDrawerList from '../hooks/company/useClientsDrawerList';
import useIndustriesDrawerList from '../hooks/company/useIndustriesDrawerList';
import usePassionsDrawerList from '../hooks/company/usePassionsDrawerList';
import useSkillsDrawerList from '../hooks/company/useSkillsDrawerList';
import { ICompanyDetailsResponse, ICompanyGlanceResponse } from '../interfaces/company';
import CommonGround from '../shared/CommonGround/CommonGround';
import {
  PeopleIcon,
  ProjectIcon,
  ClientIcon,
  IndustryIcon,
  LocationIcon,
  SkillIcon,
  PassionIcon,
} from '../shared/CommonGround/Icons';
import { DetailsPageContext, DetailsPageInfo } from '../shared/DetailsPageContext';
import {
  CommonGroundContainer,
  CommonGroundCounter,
  CommonGroundData,
  CommonGroundDataWrapper,
  CommonGroundPeopleTooltipAvatar,
  CommonGroundPeopleTooltipContainer,
  CommonGroundPeopleTooltipMorePeople,
  CommonGroundPeopleTooltipMorePeopleText,
  CommonGroundTextWrapper,
  CommonGroundTitle,
  CommonGroundWrapper,
} from './styled';

interface CompanyDetailsCommonGroundProps {
  query?: UseQueryResult<ICompanyGlanceResponse, Error>;
  forceMobile?: boolean;
}

const commonGroundsMap = {
  locations: {
    icon: <LocationIcon />,
    component: <CommonGroundLocations data={{}} type={''} />,
  },
  languages: {
    icon: <PeopleIcon />,
    component: <CommonGroundLanguages data={{}} type={''} />,
  },
  kyu_skills: {
    icon: <SkillIcon />,
    component: <CommonGroundSkills data={{}} type={''} icon={{}} />,
  },
  clients: {
    icon: <ClientIcon />,
    component: <CommonGroundClients data={{}} type={''} icon={{}} />,
  },
  industries: {
    icon: <IndustryIcon />,
    component: <CommonGroundIndustries data={{}} type={''} icon={{}} />,
  },
  projects: {
    icon: <ProjectIcon />,
    component: <CommonGroundProjects data={{}} type={''} icon={{}} />,
  },
  passions: {
    icon: <PassionIcon />,
    component: <CommonGroundPassions data={{}} type={''} icon={{}} />,
  },
};

export default function CompanyDetailsCommonGround({
  query,
  forceMobile = false,
}: CompanyDetailsCommonGroundProps) {
  const { isLoading, isIdle, data } = query;
  const [commonGroundData, setCommonGroundData] = useState([]);

  useEffect(() => {
    if (data) {
      const sectionKeys = Object.keys(data.common_ground);
      let index = 0;

      const commonGroundDataTemp = sectionKeys.reduce<any[]>((prevValue, sectionKey) => {
        if (
          ['locations', 'languages'].includes(sectionKey) &&
          data.common_ground[sectionKey].length > 0
        ) {
          prevValue.push({
            icon: commonGroundsMap[sectionKey].icon,
            type: sectionKey,
            index,
            component: commonGroundsMap[sectionKey].component,
            data: data.common_ground[sectionKey],
          });
          index++;
        }

        if (
          !['locations', 'languages'].includes(sectionKey) &&
          data.common_ground[sectionKey] !== null
        ) {
          prevValue.push({
            icon: commonGroundsMap[sectionKey].icon,
            type: sectionKey,
            index,
            component: commonGroundsMap[sectionKey].component,
            data: data.common_ground[sectionKey],
          });
          index++;
        }

        return prevValue;
      }, []);

      setCommonGroundData(commonGroundDataTemp);
    }
  }, [data]);

  return (
    <ThemeProvider theme={{ forceMobile }}>
      <CommonGroundWrapper>
        <CommonGround
          isLoading={isLoading || isIdle}
          data={commonGroundData}
          forceMobile={forceMobile}
        />
      </CommonGroundWrapper>
    </ThemeProvider>
  );
}

export function PeopleTooltip({
  employees_count,
  employees_avatar,
}: {
  employees_count: number;
  employees_avatar: string[];
}) {
  return (
    <CommonGroundPeopleTooltipContainer>
      <CommonGroundPeopleTooltipMorePeople>
        <CommonGroundPeopleTooltipMorePeopleText>
          {employees_count}
        </CommonGroundPeopleTooltipMorePeopleText>
      </CommonGroundPeopleTooltipMorePeople>
      {employees_avatar.slice(0, 3).map((avatar, index) => (
        <CommonGroundPeopleTooltipAvatar src={avatar} key={index} />
      ))}
    </CommonGroundPeopleTooltipContainer>
  );
}

function CommonGroundLocations({ data, type }) {
  const [showMoreDrawer, setShowMoreDrawer] = useState<boolean>(false);
  const maxAmountOfItems = 12;
  const tippyInstance = useRef<any>();

  const handleScroll = () => {
    if (tippyInstance.current) {
      tippyInstance.current.hide();
      tippyInstance.current = null;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <CommonGroundContainer>
      <CommonGroundTextWrapper>
        <CommonGroundTitle>{data?.length !== 1 ? 'Locations' : 'Location'}</CommonGroundTitle>
        <CommonGroundCounter type={type}>{data?.length}</CommonGroundCounter>
      </CommonGroundTextWrapper>
      <CommonGroundDataWrapper gap={20}>
        {data.slice(0, maxAmountOfItems).map((location, index) => {
          return (
            <Tippy
              key={index}
              followCursor
              plugins={[followCursor]}
              animation={false}
              trigger="mouseenter"
              onShow={(instance) => {
                tippyInstance.current = instance;
              }}
              onHide={(instance) => {
                tippyInstance.current = null;
              }}
              render={(attrs, content) => {
                return (
                  <PeopleTooltip
                    employees_count={location.snippet.employees_count}
                    employees_avatar={location.snippet.employees_avatar}
                  />
                );
              }}
            >
              <CommonGroundData key={index} type={type}>
                {location.code ? countryCodeEmoji(location.code) : ''}&nbsp;
                {location.name}
              </CommonGroundData>
            </Tippy>
          );
        })}
        {data?.length > maxAmountOfItems && (
          <CommonGroundData type={'more'} onClick={() => setShowMoreDrawer(true)}>
            <span>{data?.length - maxAmountOfItems} more...</span>
            {showMoreDrawer && (
              <SideDrawer closeDrawer={() => setShowMoreDrawer(false)} withClose>
                <LocationsDrawer locations={data} />
              </SideDrawer>
            )}
          </CommonGroundData>
        )}
      </CommonGroundDataWrapper>
    </CommonGroundContainer>
  );
}

function CommonGroundLanguages({ data, type }) {
  const [showMoreDrawer, setShowMoreDrawer] = useState<boolean>(false);
  const maxAmountOfItems = 12;
  const tippyInstance = useRef<any>();

  const handleScroll = () => {
    if (tippyInstance.current) {
      tippyInstance.current.hide();
      tippyInstance.current = null;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <CommonGroundContainer>
      <CommonGroundTextWrapper>
        <CommonGroundTitle>Languages</CommonGroundTitle>
        <CommonGroundCounter type={type}>{data?.length}</CommonGroundCounter>
      </CommonGroundTextWrapper>
      <CommonGroundDataWrapper>
        {data.slice(0, maxAmountOfItems).map((language, index) => (
          <Tippy
            key={index}
            followCursor
            plugins={[followCursor]}
            animation={false}
            trigger="mouseenter"
            onShow={(instance) => {
              tippyInstance.current = instance;
            }}
            onHide={(instance) => {
              tippyInstance.current = null;
            }}
            render={(attrs, content) => {
              return (
                <PeopleTooltip
                  employees_count={language.snippet.employees_count}
                  employees_avatar={language.snippet.employees_avatar}
                />
              );
            }}
          >
            <CommonGroundData key={index} type={type}>
              {language.name}
            </CommonGroundData>
          </Tippy>
        ))}
        {data?.length > maxAmountOfItems && (
          <CommonGroundData type={'more'} onClick={() => setShowMoreDrawer(true)}>
            <span>{data?.length - maxAmountOfItems} more...</span>
            {showMoreDrawer && (
              <SideDrawer closeDrawer={() => setShowMoreDrawer(false)} withClose>
                <LanguagesDrawer languages={data} />
              </SideDrawer>
            )}
          </CommonGroundData>
        )}
      </CommonGroundDataWrapper>
    </CommonGroundContainer>
  );
}

function CommonGroundSkills({ data, type, icon }) {
  const [showMoreDrawer, setShowMoreDrawer] = useState<boolean>(false);
  const { slug } = useContext<DetailsPageInfo<ICompanyDetailsResponse>>(DetailsPageContext);
  const userData = useUserData();
  const skillsQuery = useSkillsDrawerList(slug !== undefined ? slug : userData.kyu_company?.slug);

  return (
    <CommonGroundContainer>
      <CommonGroundTextWrapper>
        <CommonGroundTitle>Skills</CommonGroundTitle>
        <CommonGroundCounter type={type}>{data.total_count}</CommonGroundCounter>
      </CommonGroundTextWrapper>
      <CommonGroundDataWrapper>
        {data.kyu_skills.map((skill, index) => (
          <CommonGroundDataPill
            key={index}
            type={type}
            drawer={<GlobalSkillDrawer slug={skill.slug} />}
            icon={icon}
            title={skill.name}
            fill={'#EB823D'}
          />
        ))}
        {data?.more_count > 0 && (
          <CommonGroundData type={'more'} onClick={() => setShowMoreDrawer(true)}>
            <span>{data?.more_count} more...</span>
            {showMoreDrawer && (
              <SideDrawer closeDrawer={() => setShowMoreDrawer(false)} withClose>
                <SkillsDrawer skillsQuery={skillsQuery} />
              </SideDrawer>
            )}
          </CommonGroundData>
        )}
      </CommonGroundDataWrapper>
    </CommonGroundContainer>
  );
}

function CommonGroundClients({ data, type, icon }) {
  const [showDrawer, setShowDrawer] = useState(false);
  const { slug } = useContext<DetailsPageInfo<ICompanyDetailsResponse>>(DetailsPageContext);
  const userData = useUserData();
  const clientsQuery = useClientsDrawerList(slug !== undefined ? slug : userData.kyu_company?.slug);

  return (
    <CommonGroundContainer>
      <CommonGroundTextWrapper>
        <CommonGroundTitle>Clients</CommonGroundTitle>
        <CommonGroundCounter type={type}>{data.total_count}</CommonGroundCounter>
      </CommonGroundTextWrapper>
      <CommonGroundDataWrapper>
        {data.clients.map((client, index) => (
          <CommonGroundDataPill
            key={index}
            type={type}
            drawer={<GlobalClientDrawer slug={client.slug} />}
            icon={icon}
            title={client.name}
            fill={'#104D5B'}
          />
        ))}
        {data?.more_count > 0 && (
          <CommonGroundData type={'more'} onClick={() => setShowDrawer(true)}>
            <span>{data?.more_count} more...</span>
          </CommonGroundData>
        )}
      </CommonGroundDataWrapper>
      {showDrawer && (
        <SideDrawer closeDrawer={() => setShowDrawer(false)} withClose>
          <ClientsDrawer clientsQuery={clientsQuery} />
        </SideDrawer>
      )}
    </CommonGroundContainer>
  );
}

function CommonGroundIndustries({ data, type, icon }) {
  const [showDrawer, setShowDrawer] = useState(false);
  const { slug } = useContext<DetailsPageInfo<ICompanyDetailsResponse>>(DetailsPageContext);
  const userData = useUserData();
  const industriesQuery = useIndustriesDrawerList(
    slug !== undefined ? slug : userData.kyu_company?.slug
  );

  return (
    <CommonGroundContainer>
      <CommonGroundTextWrapper>
        <CommonGroundTitle>Industries</CommonGroundTitle>
        <CommonGroundCounter type={type}>{data.total_count}</CommonGroundCounter>
      </CommonGroundTextWrapper>
      <CommonGroundDataWrapper>
        {data.industries.map((industry, index) => (
          <CommonGroundDataPill
            key={index}
            type={type}
            drawer={<GlobalIndustryDrawer slug={industry.slug} />}
            icon={icon}
            title={industry.name}
            fill={'#8DA76C'}
          />
        ))}
        {data?.more_count > 0 && (
          <CommonGroundData type={'more'} onClick={() => setShowDrawer(true)}>
            <span>{data?.more_count} more...</span>
          </CommonGroundData>
        )}
      </CommonGroundDataWrapper>
      {showDrawer && (
        <SideDrawer closeDrawer={() => setShowDrawer(false)} withClose>
          <IndustriesDrawer industriesQuery={industriesQuery} />
        </SideDrawer>
      )}
    </CommonGroundContainer>
  );
}

function CommonGroundProjects({ data, type, icon }) {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <CommonGroundContainer>
      <CommonGroundTextWrapper>
        <CommonGroundTitle>Projects</CommonGroundTitle>
        <CommonGroundCounter type={type}>{data.total_count}</CommonGroundCounter>
      </CommonGroundTextWrapper>
      <CommonGroundDataWrapper>
        {data.projects.map((project, index) => (
          <CommonGroundDataPill
            key={index}
            type={type}
            drawer={<GlobalProjectDrawer slug={project.slug} />}
            icon={icon}
            title={project.name}
            fill={'#392B62'}
          />
        ))}
        {data?.more_count > 0 && (
          <CommonGroundData type={'more'} onClick={() => setShowDrawer(true)}>
            <span>{data?.more_count} more...</span>
          </CommonGroundData>
        )}
      </CommonGroundDataWrapper>
      {showDrawer && (
        <SideDrawer closeDrawer={() => setShowDrawer(false)} withClose>
          <ProjectsDrawer />
        </SideDrawer>
      )}
    </CommonGroundContainer>
  );
}

function CommonGroundPassions({ data, type, icon }) {
  const [showDrawer, setShowDrawer] = useState(false);
  const { slug } = useContext<DetailsPageInfo<ICompanyDetailsResponse>>(DetailsPageContext);
  const userData = useUserData();
  const passionsQuery = usePassionsDrawerList(
    slug !== undefined ? slug : userData.kyu_company?.slug
  );

  return (
    <CommonGroundContainer>
      <CommonGroundTextWrapper>
        <CommonGroundTitle>Passions</CommonGroundTitle>
        <CommonGroundCounter type={type}>{data.total_count}</CommonGroundCounter>
      </CommonGroundTextWrapper>
      <CommonGroundDataWrapper>
        {data.passions.map((passion, index) => (
          <CommonGroundDataPill
            key={index}
            type={type}
            drawer={<GlobalPassionDrawer slug={passion.slug} />}
            icon={icon}
            title={passion.name}
            fill={'#D47563'}
          />
        ))}
        {data?.more_count > 0 && (
          <CommonGroundData type={'more'} onClick={() => setShowDrawer(true)}>
            <span>{data?.more_count} more...</span>
          </CommonGroundData>
        )}
      </CommonGroundDataWrapper>
      {showDrawer && (
        <SideDrawer closeDrawer={() => setShowDrawer(false)} withClose>
          <PassionsDrawer passionsQuery={passionsQuery} />
        </SideDrawer>
      )}
    </CommonGroundContainer>
  );
}

export function CommonGroundDataPill({
  type,
  icon,
  title,
  fill,
  drawer,
}: {
  type: any;
  icon: JSX.Element;
  title: string;
  fill: string;
  drawer: JSX.Element;
}) {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <CommonGroundData type={type} onClick={() => setShowDrawer(true)}>
      {React.cloneElement(icon, { width: 14, height: 13, fill })}
      <span>{title}</span>
      {showDrawer && (
        <SideDrawer closeDrawer={() => setShowDrawer(false)} withClose>
          {drawer}
        </SideDrawer>
      )}
    </CommonGroundData>
  );
}
