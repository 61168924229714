import React from 'react';

export default function ResetIcon({
  width = 14,
  height = 14,
  fill = '#171C33',
  fillOpacity = '0.5',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.60494 6.79722H1.96181C1.78319 6.79722 1.68789 6.5954 1.79507 6.45297L1.91421 6.28676C2.22386 5.84751 2.58118 5.43197 2.9504 5.04017L2.96228 5.02832C3.08142 4.88588 3.28389 4.88588 3.40295 5.02832C3.78413 5.4201 4.1295 5.83559 4.4511 6.27492L4.57024 6.44112C4.67742 6.58357 4.58212 6.78537 4.4035 6.78537H3.76037C3.7961 8.53058 5.2373 9.94336 7.00004 9.94336C7.6313 9.94336 8.21494 9.76531 8.71519 9.45658C8.94151 9.31414 9.2273 9.3616 9.41789 9.53973C9.67992 9.80092 9.63224 10.2402 9.31071 10.4301C8.64374 10.8457 7.84574 11.0831 7.00006 11.0831C4.60604 11.1068 2.65271 9.18354 2.60507 6.79729L2.60494 6.79722ZM4.58211 3.90042C4.7727 4.09039 5.05849 4.12601 5.28481 3.98356C5.78506 3.67491 6.3687 3.49678 6.99996 3.49678C8.7627 3.49678 10.2039 4.90959 10.2396 6.65477H9.5965C9.41788 6.65477 9.32258 6.85659 9.42976 6.99902L9.5489 7.16523C9.8705 7.60447 10.2159 8.02002 10.597 8.41182C10.7162 8.55426 10.9187 8.55426 11.0377 8.41182L11.0496 8.39998C11.4308 8.0082 11.7761 7.59272 12.0858 7.15338L12.2049 6.98718C12.3121 6.84474 12.2168 6.64293 12.0382 6.64293H11.3951C11.3593 4.25663 9.406 2.33334 7.00007 2.33334C6.15437 2.33334 5.35639 2.57077 4.68942 2.98633C4.3797 3.19999 4.32013 3.63924 4.58216 3.90042L4.58211 3.90042Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}
