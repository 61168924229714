import React from 'react';

import Tippy from '@tippyjs/react';
import {
  ClientIcon,
  CompanyIcon,
  DisciplineIcon,
  IndustryIcon,
  PassionIcon,
  SkillIcon,
} from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { Sparkle } from 'app/components/shared/icons';
import PersonIcon from 'app/components/shared/icons/person-icon';
import useIsMobile from 'hooks/useIsMobile';
import useTippy from 'hooks/useTippy';
import { ThemeProvider } from 'styled-components';

import {
  DataTypeLenCounter,
  DataTypeLenCounterContainer,
  DataTypeLenTitle,
  DataTypeLenTooltipContainer,
  DataTypeLenTooltipText,
  DataTypeLenWrapper,
} from './styled';
import { theme } from './theme';

interface DataTypeLenProps {
  mode?: 'full' | 'compact';
  type:
    | 'people'
    | 'passions'
    | 'skills'
    | 'companies'
    | 'clients'
    | 'industries'
    | 'disciplines'
    | 'wildcard';
  counter?: number;
  onClick?: () => void;
}

export default function DataTypeLen({ mode = 'full', type, counter, onClick }: DataTypeLenProps) {
  const tippyInstance = useTippy();
  const isMobile = useIsMobile();

  const typeToTitle = new Map<string, string>([
    ['people', 'People'],
    ['passions', 'Passions'],
    ['skills', 'Skills'],
    ['companies', 'Companies'],
    ['clients', 'Clients'],
    ['industries', 'Industries'],
    ['disciplines', 'Disciplines'],
    ['wildcard', 'Wildcard'],
  ]);

  const typeToIcon = new Map<string, JSX.Element>([
    [
      'people',
      <PersonIcon
        width={isMobile ? 12 : 16}
        height={isMobile ? 12 : 16}
        fill="var(--color-primary)"
        key={0}
      />,
    ],
    [
      'passions',
      <PassionIcon
        width={isMobile ? 12 : 16}
        height={isMobile ? 12 : 16}
        fill="var(--color-primary)"
        key={1}
      />,
    ],
    [
      'skills',
      <SkillIcon
        width={isMobile ? 12 : 16}
        height={isMobile ? 12 : 16}
        fill="var(--color-primary)"
        key={2}
      />,
    ],
    [
      'companies',
      <CompanyIcon
        width={isMobile ? 12 : 16}
        height={isMobile ? 12 : 16}
        fill="var(--color-primary)"
        key={3}
      />,
    ],
    [
      'clients',
      <ClientIcon
        width={isMobile ? 12 : 16}
        height={isMobile ? 12 : 16}
        fill="var(--color-primary)"
        key={4}
      />,
    ],
    [
      'industries',
      <IndustryIcon
        width={isMobile ? 12 : 16}
        height={isMobile ? 12 : 16}
        fill="var(--color-primary)"
        key={5}
      />,
    ],
    [
      'disciplines',
      <DisciplineIcon
        width={isMobile ? 12 : 16}
        height={isMobile ? 12 : 16}
        fill="var(--color-primary)"
        key={6}
      />,
    ],
    [
      'wildcard',
      <Sparkle
        width={isMobile ? 12 : 16}
        height={isMobile ? 12 : 16}
        fill="var(--color-primary)"
        key={6}
      />,
    ],
  ]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (isMobile)
    return (
      <ThemeProvider theme={theme[type]}>
        <DataTypeLenWrapper onClick={handleClick} id={`data-type-len-${type}`}>
          <DataTypeLenCounterContainer mode={mode}>
            {typeToIcon.get(type)}
            {counter ? (
              <DataTypeLenCounter>
                {counter.toLocaleString('en', { useGrouping: true })}
              </DataTypeLenCounter>
            ) : null}
          </DataTypeLenCounterContainer>
        </DataTypeLenWrapper>
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={theme[type]}>
      <Tippy
        zIndex={999999999}
        placement="top"
        animation={false}
        onShow={(instance) => {
          tippyInstance.current = instance;
        }}
        onHide={(instance) => {
          tippyInstance.current = null;
        }}
        render={(attrs) => {
          if (mode !== 'compact') return null;

          return (
            <DataTypeLenTooltipContainer>
              <DataTypeLenTooltipText>{typeToTitle.get(type)}</DataTypeLenTooltipText>
            </DataTypeLenTooltipContainer>
          );
        }}
      >
        <DataTypeLenWrapper tabIndex={0} onClick={handleClick} id={`data-type-len-${type}`}>
          <DataTypeLenCounterContainer mode={mode}>
            {typeToIcon.get(type)}
            {counter ? (
              <DataTypeLenCounter>
                {counter.toLocaleString('en', { useGrouping: true })}
              </DataTypeLenCounter>
            ) : null}
          </DataTypeLenCounterContainer>
          {mode === 'full' ? <DataTypeLenTitle>{typeToTitle.get(type)}</DataTypeLenTitle> : null}
        </DataTypeLenWrapper>
      </Tippy>
    </ThemeProvider>
  );
}
