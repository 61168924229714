import React, { useContext } from 'react';

import { DetailsPageContext } from 'app/components/DetailsPages/shared/DetailsPageContext';
import useUserData from 'hooks/useUserData';

import {
  LocationsDrawerDescription,
  LocationsDrawerContainer,
  LocationsDrawerTitle,
  LocationsDrawerTitleContainer,
  LocationsDrawerListContainer,
  LocationDrawerListItem,
} from './styled';

interface LocationsDrawerProps {
  locations: any[];
}

export default function LocationsDrawer({ locations }: LocationsDrawerProps) {
  const context = useContext(DetailsPageContext);
  const userData = useUserData();

  return (
    <LocationsDrawerContainer>
      <LocationsDrawerTitleContainer>
        <LocationsDrawerTitle>
          {context.data.name || userData.kyu_company?.name} Locations
        </LocationsDrawerTitle>
        <LocationsDrawerDescription>
          These are the locations that the people report into at{' '}
          {context.data.name || userData.kyu_company?.name}.
        </LocationsDrawerDescription>
      </LocationsDrawerTitleContainer>
      <LocationsDrawerListContainer>
        {locations.map((location, index) => (
          <LocationDrawerListItem key={index}>{location.name}</LocationDrawerListItem>
        ))}
      </LocationsDrawerListContainer>
    </LocationsDrawerContainer>
  );
}
