import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  profileDataSelector,
  setAllCountries,
  setAllLanguages,
  setAllPassions,
  setBio,
  setCountries,
  setLanguages,
  setPassions,
  setPassionsInspo,
} from 'store/reducers/profile-editor/profile-editor-reducer';

import { useHumanAtAGlance } from './useHumanAtAGlance';

export default function useProfileEditorHumanAtAGlanceDrawer() {
  const dispatch = useDispatch();
  const { data, refetch } = useHumanAtAGlance({ enabled: false });
  const { passions, allPassions, languages, allLanguages, countries, allCountries, bio } =
    useSelector(profileDataSelector);

  useEffect(() => {
    if (allPassions.length === 0) {
      refetch();
    }
  }, [allPassions]);

  useEffect(() => {
    if (data) {
      dispatch(setPassions(data.passions));
      dispatch(setAllPassions(data.allPassions));
      dispatch(setPassionsInspo(data.passions_inspo));

      dispatch(setLanguages(data.languages));
      dispatch(setAllLanguages(data.allLanguages));

      dispatch(setCountries(data.countries));
      dispatch(setAllCountries(data.allCountries));

      dispatch(setBio(data.bio));
    }
  }, [data]);

  return {
    passions,
    allPassions,
    languages,
    allLanguages,
    countries,
    allCountries,
    bio,
  };
}
