import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit';

export interface IGlobalStateHomeDrawer {
  show: boolean;
  model: 'people' | 'passions' | 'skills' | 'companies' | 'clients' | 'industries' | 'disciplines';
}

export interface GlobalState {
  homeDrawer: IGlobalStateHomeDrawer;
}

export const slice = createSlice<GlobalState, SliceCaseReducers<GlobalState>>({
  name: 'global',
  initialState: {
    homeDrawer: {
      show: false,
      model: null,
    },
  },
  reducers: {
    setHomeDrawer: (state, action: { payload: IGlobalStateHomeDrawer }) => ({
      ...state,
      homeDrawer: action.payload,
    }),
    closeDrawer: (state) => ({
      ...state,
      homeDrawer: {
        model: null,
        show: false,
      },
    }),
  },
});

export const { setHomeDrawer, closeDrawer } = slice.actions;

export const homeDrawerSelector = (state): IGlobalStateHomeDrawer => state.global.homeDrawer;

export default slice.reducer;
