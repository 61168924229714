import React from 'react';

export default function FullArrowIcon({ width = 32, height = 32, fill = '#A26565' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2071 16.7071C24.5976 16.3166 24.5976 15.6834 24.2071 15.2929C23.8166 14.9024 23.1834 14.9024 22.7929 15.2929L17.5 20.5858L17.5 9C17.5 8.44771 17.0523 8 16.5 8C15.9477 8 15.5 8.44771 15.5 9L15.5 20.5858L10.2071 15.2929C9.81658 14.9024 9.18342 14.9024 8.79289 15.2929C8.40237 15.6834 8.40237 16.3166 8.79289 16.7071L15.7929 23.7071C15.9874 23.9016 16.2421 23.9992 16.497 24C16.498 24 16.499 24 16.5 24C16.501 24 16.502 24 16.503 24C16.6375 23.9996 16.7657 23.9727 16.8828 23.9241C16.998 23.8764 17.1062 23.8063 17.2005 23.7136C17.2031 23.7111 17.2056 23.7086 17.2081 23.7061L24.2071 16.7071Z"
        fill={fill}
      />
    </svg>
  );
}
