import { CommonGroundWrapper } from 'app/components/DetailsPages/Company/styled';
import { CommonGroundContainer } from 'app/components/DetailsPages/shared/CommonGround/styled';
import { OnboardingStepTwoKyuTeamIdentifierContainer } from 'app/components/Onboarding/steps/Two/styled';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsWayfindingCompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;

  ${CommonGroundContainer} {
    width: 311px;
  }

  ${BREAKPOINTS.tablet} {
    gap: 88px;
  }

  overflow-x: hidden;
`;

export const AboutKyuOsWayfindingCompanyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 152px;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    gap: 44px;
  }
`;

export const AboutKyuOsWayfindingCompanyTitle = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  font-weight: 400;
  font-size: 32px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    max-width: 294px;
    font-size: 24px;
  }

  color: var(--color-text);
`;

export const AboutKyuOsWayfindingCompanyCommonGroundContainer = styled.div`
  position: relative;

  ${OnboardingStepTwoKyuTeamIdentifierContainer} {
    width: fit-content;
    height: fit-content;

    top: 6px;
    right: -155px;

    ${BREAKPOINTS.tablet} {
      top: -35px;
      left: 49%;
      right: unset;

      transform: translateY(-50%) rotate(-90deg);

      & span {
        transform: translateY(50%) rotate(90deg);
      }

      & svg {
        transform: rotate(20deg);
      }
    }
  }
`;

export const AboutKyuOsWayfindingCompanyInformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 72px;
`;

export const AboutKyuOsWayfindingCompanyInformationText = styled.span`
  max-width: 475px;

  font-weight: 400;
  font-size: 18px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
  }

  color: var(--color-text);
`;

export const AboutKyuOsWayfindingCompanyInformationButton = styled.button`
  width: fit-content;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  padding: 16px 32px 16px 48px;

  transition: background 0.2s ease;
  &:hover {
    background: var(--color-primary);

    & span {
      color: #ffffff;
    }

    & path {
      fill: #ffffff;
    }
  }

  ${BREAKPOINTS.tablet} {
    width: 100%;
  }

  border-radius: 100px;
  border: 2px solid var(--color-text);
`;

export const AboutKyuOsWayfindingCompanyInformationButtonText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;

  color: var(--color-text);
`;
