import React, { useEffect, useState } from 'react';

//Hooks
import CompanyLogo from 'app/components/CommonStyled/CompanyLogo';
//Icons
import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import Tooltip from 'app/components/Tooltip';
import OnboardingArrow from 'app/components/shared/icons/onboarding-arrow';
import PersonOkIcon from 'app/components/shared/icons/person-ok';
import PersonPlusIcon from 'app/components/shared/icons/person-plus';
import useUserData from 'hooks/useUserData';

import useOnboardingControls from '../../hooks/useOnboardingControls';
import {
  OnboardingStepSixCongratsContainer,
  OnboardingStepSixPeopleContainer,
  OnboardingStepSixPeopleCounter,
  OnboardingStepSixProgressBar,
  OnboardingStepSixProgressBarContainer,
  OnboardingStepSixProgressChart,
  OnboardingStepSixProgressContainer,
  OnboardingStepSixProgressOSPeopleContainer,
  OnboardingStepSixProgressPercentageContainer,
  OnboardingStepSixProgressTotalPeopleContainer,
  OnboardingStepSixSubtitle,
  OnboardingStepSixThatsYouArrowContainer,
  OnboardingStepSixThatsYouContainer,
  OnboardingStepSixThatsYouTextContainer,
  OnboardingStepSixTitle,
  OnboardingStepSixWrapper,
} from './styled';

export default function OnboardingStepSix() {
  const { onboardingQuery } = useOnboardingControls();
  const companyData = onboardingQuery.data?.company;
  const userData = useUserData();
  const [companyPeople, setCompanyPeople] = useState(companyData?.people);
  const [currentVariant, setCurrentVariant] = useState('initial');
  const [showRemainingPeopleValue, setShowRemainingPeopleValue] = useState('-');
  const [osPeopleValue, setOsPeopleValue] = useState('-');
  const [percentageValue, setPercentageValue] = useState('-');

  useEffect(() => {
    if (companyData) {
      let cp = companyData?.people;
      setCompanyPeople(cp);
      let rpv = cp?.profiles_count - cp?.engaged_profiles_count;
      setShowRemainingPeopleValue(rpv + 1);
      setOsPeopleValue(cp?.engaged_profiles_count);

      setPercentageValue(Math.round((cp?.engaged_profiles_count / (cp?.profiles_count + 1)) * 100));

      const timer = setTimeout(() => {
        setCurrentVariant('final');
        setShowRemainingPeopleValue(rpv);
        setOsPeopleValue(cp?.engaged_profiles_count + 1);
        setPercentageValue(
          Math.round(((cp?.engaged_profiles_count + 1) / cp?.profiles_count) * 100)
        );
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [companyData]);

  return (
    <OnboardingStepSixWrapper>
      <OnboardingStepSixTitle>{`Kudos! Your profile is looking great!`}</OnboardingStepSixTitle>
      <OnboardingStepSixSubtitle>
        {companyPeople?.engaged_profiles_count == 0 ? (
          <>
            Awesome! You're the first person from {companyData?.name} to activate your profile on
            kyu OS.
          </>
        ) : (
          <>
            You're now part of the {companyPeople ? companyPeople?.engaged_profiles_count + 1 : '-'}{' '}
            people from <span>{companyData?.name}</span> who have activated their profiles on kyu
            OS.
          </>
        )}
      </OnboardingStepSixSubtitle>
      <OnboardingStepSixPeopleContainer>
        {companyPeople?.profiles_count - companyPeople?.samples.length > 0 ? (
          <OnboardingStepSixPeopleCounter>
            {companyPeople?.engaged_profiles_count + 1}
          </OnboardingStepSixPeopleCounter>
        ) : null}
        {companyPeople?.samples.map((person, index) => (
          <PersonAvatar
            key={index}
            highlighted
            avatar={person.profile_image}
            name={person.first_name}
            lastName={person.last_name}
            size={32}
          />
        ))}
      </OnboardingStepSixPeopleContainer>
      <OnboardingStepSixProgressContainer>
        {/* CHART */}
        <OnboardingStepSixProgressChart>
          {companyData?.slug ? (
            <CompanyLogo size={48} slug={companyData?.slug} borderColor={'#FFC229'} />
          ) : null}
          <Tooltip text="People left to go">
            <OnboardingStepSixProgressTotalPeopleContainer>
              <PersonPlusIcon fillOpacity={0.5} /> <span>{showRemainingPeopleValue}</span>
            </OnboardingStepSixProgressTotalPeopleContainer>
          </Tooltip>
          <Tooltip text="People who have activated their profiles">
            <OnboardingStepSixProgressOSPeopleContainer>
              <PersonOkIcon width={22} height={22} fillOpacity={0.5} /> <span>{osPeopleValue}</span>
            </OnboardingStepSixProgressOSPeopleContainer>
          </Tooltip>
          <OnboardingStepSixProgressPercentageContainer>
            {percentageValue}%
          </OnboardingStepSixProgressPercentageContainer>
        </OnboardingStepSixProgressChart>

        {/*PROGRESS*/}
        <OnboardingStepSixProgressBarContainer>
          <OnboardingStepSixProgressBar
            animate={currentVariant}
            percentage={percentageValue}
            width={0}
          />
        </OnboardingStepSixProgressBarContainer>

        {/* THATS YOU */}
        <OnboardingStepSixThatsYouContainer animate={currentVariant}>
          <OnboardingStepSixThatsYouArrowContainer>
            <PersonAvatar
              avatar={userData?.profile_image}
              name={userData?.first_name}
              lastName={userData?.last_name}
              size={22}
              margin={'0 0 9px 20px'}
            />
            <OnboardingArrow />
          </OnboardingStepSixThatsYouArrowContainer>
          <OnboardingStepSixThatsYouTextContainer>
            That’s you!
          </OnboardingStepSixThatsYouTextContainer>
        </OnboardingStepSixThatsYouContainer>
      </OnboardingStepSixProgressContainer>

      {/*KUDOS*/}
      <OnboardingStepSixCongratsContainer>
        {
          'Use kyu OS to expand your network. You can explore who you share things in common with through My Reach on your profile homepage.'
        }
      </OnboardingStepSixCongratsContainer>
    </OnboardingStepSixWrapper>
  );
}
