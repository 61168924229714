import React from 'react';

import { motion } from 'framer-motion';

export default function CircularProgress({
  percents,
  stroke = 'var(--color-primary)',
  background = 'transparent',
  backgroundOpacity = 0.25,
  emptyStroke = stroke,
  emptyStrokeOpacity = 0.25,
  duration = 3,
  delay = 0.5,
  size = 100,
  strokeWidth = 6,
}) {
  const radius = size / 2 - strokeWidth / 2;
  const circumference = Math.ceil(2 * Math.PI * radius);
  const fillPercents = Math.abs(Math.ceil((circumference / 100) * (percents - 100)));

  const transition = {
    duration: duration,
    delay: delay,
    ease: 'easeIn',
  };

  const variants = {
    hidden: {
      strokeDashoffset: circumference,
      transition,
    },
    show: {
      strokeDashoffset: fillPercents,
      transition,
    },
  };

  return (
    <motion.svg
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      style={{
        overflow: 'visible',
        transform: 'rotate(-90deg)',
      }}
    >
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        className="circle"
        strokeWidth={strokeWidth}
        stroke={background}
        fill={background}
        style={{
          opacity: backgroundOpacity,
        }}
      />
      <motion.circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={strokeWidth}
        stroke={stroke}
        fill="transparent"
        strokeDashoffset={fillPercents}
        strokeDasharray={circumference}
        variants={variants}
        strokeLinecap={'round'}
        initial="hidden"
        animate={'show'}
      />
    </motion.svg>
  );
}
