import React from 'react';

export default function File({ width = 24, height = 24, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.001 14C14.001 14.5523 13.5533 15 13.001 15H9.00098C8.44869 15 8.00098 14.5523 8.00098 14C8.00098 13.4477 8.44869 13 9.00098 13H13.001C13.5533 13 14.001 13.4477 14.001 14Z"
        fill={fill}
      />
      <path
        d="M16.001 10C16.001 10.5523 15.5533 11 15.001 11H9.00098C8.44869 11 8.00098 10.5523 8.00098 10C8.00098 9.44771 8.44869 9 9.00098 9H15.001C15.5533 9 16.001 9.44771 16.001 10Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4152 6.41422C19.7903 6.78929 20.001 7.298 20.001 7.82843V20C20.001 21.1046 19.1056 22 18.001 22H6.00098C4.89641 22 4.00098 21.1046 4.00098 20V4C4.00098 2.89543 4.89641 2 6.00098 2H14.1726C14.703 2 15.2117 2.21071 15.5868 2.58578L19.4152 6.41422ZM18.001 7.82843V20H6.00098V4H14.1726L18.001 7.82843Z"
        fill={fill}
      />
    </svg>
  );
}
