import React, { createContext } from 'react';

export interface ISearchPill {
  id: string;
  slug: string;
  name: string;
  model: string;
  uuid: string;
}
export interface SearchInfo {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string | null>>;
  searchResultsAmount: number | null;
  setSearchResultsAmount: React.Dispatch<React.SetStateAction<number | null>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  context: string;
  pill: ISearchPill | null;
  setPill?: React.Dispatch<React.SetStateAction<ISearchPill | null>>;
  onResultClick?: (item: any) => void;
  setIsSubmenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchContext = createContext<SearchInfo>({
  inputValue: null,
  setInputValue: () => {},
  searchResultsAmount: null,
  setSearchResultsAmount: () => {},
  isLoading: false,
  setIsLoading: () => {},
  context: 'navbar-search',
  pill: null,
  setPill: () => {},
  onResultClick: () => {},
  setIsSubmenuOpen: () => {},
});
