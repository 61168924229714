import React from 'react';

export default function CommunitiesIcon({ width = 24, height = 24, fill = '#171C33' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <path
        className="comm-path"
        d="M2.71387 13.1429C2.71387 8.97317 5.5052 5.45538 9.3212 4.3562L9.69181 6.33402C6.80527 7.25099 4.71387 9.95271 4.71387 13.1429C4.71387 13.7325 4.7853 14.3054 4.91997 14.8534L2.93015 15.1288C2.78854 14.4894 2.71387 13.8249 2.71387 13.1429Z"
        fill={fill}
      />
      <path
        className="comm-path"
        d="M15.0948 4.59002C18.546 5.89733 20.9996 9.23359 20.9996 13.1429C20.9996 13.8249 20.9249 14.4894 20.7833 15.1288L18.7557 15.0002C18.9148 14.408 18.9996 13.7854 18.9996 13.1429C18.9996 10.127 17.1304 7.54761 14.4873 6.50005L15.0948 4.59002Z"
        fill={fill}
      />
      <path
        className="comm-path"
        d="M11.8567 20.2858C13.694 20.2858 15.3693 19.5921 16.635 18.4523L17.8386 20.0575C16.2353 21.4458 14.1441 22.2858 11.8567 22.2858C9.62843 22.2858 7.58631 21.4886 6 20.1639L7.14787 18.514C8.40505 19.617 10.0528 20.2858 11.8567 20.2858Z"
        fill={fill}
      />
      <path
        className="comm-path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7ZM12 9C14.2091 9 16 7.20914 16 5C16 2.79086 14.2091 1 12 1C9.79086 1 8 2.79086 8 5C8 7.20914 9.79086 9 12 9ZM20 20C21.1046 20 22 19.1046 22 18C22 16.8954 21.1046 16 20 16C18.8954 16 18 16.8954 18 18C18 19.1046 18.8954 20 20 20ZM20 22C22.2091 22 24 20.2091 24 18C24 15.7909 22.2091 14 20 14C17.7909 14 16 15.7909 16 18C16 20.2091 17.7909 22 20 22ZM6 18C6 19.1046 5.10457 20 4 20C2.89543 20 2 19.1046 2 18C2 16.8954 2.89543 16 4 16C5.10457 16 6 16.8954 6 18ZM8 18C8 20.2091 6.20914 22 4 22C1.79086 22 0 20.2091 0 18C0 15.7909 1.79086 14 4 14C6.20914 14 8 15.7909 8 18Z"
        fill={fill}
      />
    </svg>
  );
}
