import React from 'react';

export default function CommunityIcon({ width = 34, height = 34, fill = '#A26565' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8333 7.08366C19.8333 8.64847 18.5648 9.91699 17 9.91699C15.4352 9.91699 14.1667 8.64847 14.1667 7.08366C14.1667 5.51885 15.4352 4.25033 17 4.25033C18.5648 4.25033 19.8333 5.51885 19.8333 7.08366ZM21.9226 9.8926C20.9463 11.5998 19.1075 12.7503 17 12.7503C14.8212 12.7503 12.9296 11.5207 11.9813 9.71748C8.82278 11.4298 6.67798 14.7741 6.67798 18.6194C6.67798 19.0669 6.70703 19.5076 6.76334 19.9397C9.36812 20.4505 11.3333 22.7459 11.3333 25.5003C11.3333 26.0212 11.263 26.5257 11.1314 27.0049C12.748 28.0992 14.6979 28.7384 16.797 28.7384C19.0477 28.7384 21.1267 28.0036 22.8074 26.7609C22.7153 26.3555 22.6667 25.9336 22.6667 25.5003C22.6667 22.8958 24.4238 20.7017 26.8173 20.0387C26.8824 19.5749 26.9161 19.1011 26.9161 18.6194C26.9161 14.9012 24.9107 11.6514 21.9226 9.8926ZM22.6667 7.07019C22.6594 3.94677 20.1251 1.41699 17 1.41699C13.9418 1.41699 11.4493 3.83966 11.3373 6.87051C6.91217 8.93037 3.84465 13.4166 3.84465 18.6194C3.84465 19.1218 3.87325 19.6175 3.92893 20.1051C1.64941 20.8387 0 22.9769 0 25.5003C0 28.6299 2.53705 31.167 5.66667 31.167C7.25249 31.167 8.68618 30.5156 9.71472 29.4657C11.7501 30.7975 14.1831 31.5717 16.797 31.5717C19.5183 31.5717 22.0436 30.7325 24.1282 29.2988C25.1652 30.4461 26.6651 31.167 28.3333 31.167C31.4629 31.167 34 28.6299 34 25.5003C34 22.8338 32.1582 20.5974 29.6773 19.994C29.725 19.5423 29.7494 19.0837 29.7494 18.6194C29.7494 13.5788 26.8702 9.21079 22.6667 7.07019ZM31.1667 25.5003C31.1667 27.0651 29.8981 28.3337 28.3333 28.3337C26.7685 28.3337 25.5 27.0651 25.5 25.5003C25.5 23.9355 26.7685 22.667 28.3333 22.667C29.8981 22.667 31.1667 23.9355 31.1667 25.5003ZM5.66667 28.3337C7.23147 28.3337 8.5 27.0651 8.5 25.5003C8.5 23.9355 7.23147 22.667 5.66667 22.667C4.10186 22.667 2.83333 23.9355 2.83333 25.5003C2.83333 27.0651 4.10186 28.3337 5.66667 28.3337Z"
        fill={fill}
      />
    </svg>
  );
}
