import { useEffect, useRef } from 'react';

export default function useTippy() {
  const tippyInstance = useRef<any>(null);

  const handleScroll = () => {
    if (tippyInstance.current) {
      tippyInstance.current.hide();
      tippyInstance.current = null;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return tippyInstance;
}
