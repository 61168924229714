import { http } from './http';

//Details
export const getIndustryDetails = (slug: string) => {
  return http.get(`industry/${slug}/details`);
};

export const getIndustrySnapshot = (slug: string) => {
  return http.get(`industry/${slug}/details/snapshot`);
};

export const getIndustryExpertise = (slug: string) => {
  return http.get(`industry/${slug}/details/expertise`);
};

export const getIndustryClients = (slug: string) => {
  return http.get(`industry/${slug}/details/clients`);
};

export const getIndustryProjects = (slug: string, params: IIndustryProjectsParams) => {
  return http.get(`industry/${slug}/details/projects`, params);
};

export const getIndustryMapSnippet = (slug: string) => {
  return http.get(`industry/${slug}/details/map_snippet`);
};

export const getIndustryStoriesAndHighlights = (slug: string) => {
  return http.get(`industry/${slug}/details/stories_and_highlights`);
};

export const getIndustryMeetSomeoneNew = (slug: string) => {
  return http.get(`industry/${slug}/details/meet_someone_new`);
};

export const getIndustryPeople = (slug: string) => {
  return http.get(`industry/${slug}/details/people`);
};

export const getIndustryRelatedIndustries = (slug: string) => {
  return http.get(`industry/${slug}/details/related_industries`);
};

export const getIndustryPriorExperiencePeople = (slug: string) => {
  return http.get(`industry/${slug}/details/prior_experience_people`);
};

export const getIndustryConvenings = (slug: string) => {
  return http.get(`industry/${slug}/details/convenings`);
};

//Drawers
export const getIndustryProjectsDrawer = (slug: string, params: IIndustryProjectsDrawerParams) => {
  return http.get(`industry/${slug}/drawers/projects`, params);
};

export const getIndustryPeopleDrawer = (slug: string, params: any) => {
  return http.get<any>(`industry/${slug}/drawers/people`, params);
};

export const getIndustryClientsDrawer = (slug: string, params) => {
  return http.get(`industry/${slug}/drawers/clients`, params);
};

export const getIndustryCompaniesDrawer = (slug: string) => {
  return http.get<any>(`industry/${slug}/drawers/companies`);
};
