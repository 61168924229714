import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import Search from 'app/components/CommonStyled/Search';
import { CloseIcon } from 'app/components/shared/icons';

import {
  MobileMenuSearchCloseContainer,
  MobileMenuSearchTitle,
  MobileMenuSearchTitleContainer,
  MobileMenuSearchWrapper,
} from './styled';

interface MobileMenuSearchProps {
  onClose: () => void;
  closeMenu?: () => void;
}

export default function MobileMenuSearch({ onClose, closeMenu }: MobileMenuSearchProps) {
  const handleResultClick = () => {
    closeMenu?.();
  };

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'drawers:close') {
        onClose();
      }
    });

    return () => {
      window.removeEventListener('message', (event) => {
        if (event.data === 'drawers:close') {
          onClose();
        }
      });
    };
  }, []);

  return ReactDOM.createPortal(
    <MobileMenuSearchWrapper id="mobile-search-menu">
      <MobileMenuSearchTitleContainer>
        <MobileMenuSearchTitle>What are you curious about?</MobileMenuSearchTitle>
        <MobileMenuSearchCloseContainer onClick={onClose}>
          <CloseIcon width={10} height={10} fill={'#FFFFFF'} />
        </MobileMenuSearchCloseContainer>
      </MobileMenuSearchTitleContainer>
      <Search alreadyOpen onResultClick={handleResultClick} />
    </MobileMenuSearchWrapper>,
    document.querySelector('#root')
  );
}
