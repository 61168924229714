import { SliceCaseReducers, createSlice, nanoid } from '@reduxjs/toolkit';

export type ToastType = 'success' | 'error' | 'info';

export interface Toast {
  id: string;
  message: string | JSX.Element;
  type: ToastType;
  autoHideDuration?: number;
  onClose?: () => void;
}

export interface ToastState {
  toasts: Toast[];
}

export const slice = createSlice<ToastState, SliceCaseReducers<ToastState>>({
  name: 'toast',
  initialState: {
    toasts: [],
  },
  reducers: {
    addToast: (state, action: { payload: Omit<Toast, 'id'> }) => {
      state.toasts.push({
        id: nanoid(),
        autoHideDuration: action.payload.autoHideDuration || 5000,
        ...action.payload,
      });
    },
    removeToast: (state, action) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
    },
  },
});

export const { addToast, removeToast } = slice.actions;

export const toastsSelector = (state: { toast: ToastState }) => state.toast.toasts;

export default slice.reducer;
