// Kyu company node card highlights in client graph
const client_company_kyu_company_highlights = ({
  client_company,
  kyu_company,
  people_count,
  projects_count,
  projects_length,
}) => {
  const company_name = `<span class="highlight-kyu-company">${kyu_company}</span>`;
  const projects = `<span class="highlight-project">${projects_count} ${
    projects_count > 1 ? "projects" : "project"
  }</span>`;
  const people = `<span class="highlight-people">${people_count} ${
    people_count > 1 ? "people" : "person"
  }</span>`;
  const client_company_text = `<span class="highlight-client-company">${client_company}</span>`;
  const average_length = `<span class="highlight-people">${build_average_project_length(
    projects_length
  )}</span>`;

  const first_paragraph = `<p>${company_name} has done ${projects} with ${client_company_text}. ${average_length}</p>`;
  const second_paragaph = `<p>${people} from ${company_name} have worked with his client.</p>`;
  const text = first_paragraph + second_paragaph;

  return text;
};

// Microsoft node card and result page hightlight
const client_company_highlights = ({
  client_company,
  kyu_companies_count,
  people_count,
  kyu_company,
  projects_count,
  projects_length,
}) => {
  const company = `<span class="highlight-client-company">${client_company}</span>`;
  const people_count_text = `<span class="highlight-people">${people_count} people</span>`;
  let kyu_companies_text = `from <span class="highlight-kyu-company">${kyu_companies_count} ${
    kyu_companies_count > 1 ? "companies" : "company"
  }</span>,`;
  const projects_count_text = `<span class="highlight-project">${projects_count} ${
    projects_count > 1 ? "projects" : "project"
  }</span>`;

  if (kyu_companies_count === 1) {
    kyu_companies_text = `at <a href="/member-company/${kyu_company[0].slug}"><span class="highlight-kyu-company">${kyu_company[0].name}</span></a>`;
  }

  const first_paragraph = `<p>${people_count_text} ${kyu_companies_text} have worked with ${company} on ${projects_count_text}.</p>`;
  const second_paragaph = build_average_project_length(projects_length);
  const result = first_paragraph + second_paragaph;

  return result;
};

const build_average_project_length = (average) => {
  let average_project_text = `<p>The average project length is <strong>${
    average || 0
  }</strong>.</p>`;
  return average_project_text;
};

export { client_company_kyu_company_highlights, client_company_highlights };
