import { SkeletonItem } from "app/components/DetailsPages/shared/styled";
import styled from "styled-components";

interface ProjectsDrawerContainerProps {
  showingProjectDrawer?: boolean;
}

export const ProjectsDrawerContainer = styled.div<ProjectsDrawerContainerProps>`
  height: 100%;
  padding: ${({ showingProjectDrawer }) => showingProjectDrawer ? 0 : 32}px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 20.8%, rgba(159, 124, 251, 0.192) 100%), #FFFFFF;
`;

export const ProjectsDrawerFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 32px;
  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  backdrop-filter: blur(100px);
`;

export const ProjectsDrawerSeparator = styled.hr`
  border: 1px solid rgba(57, 43, 98, 0.3);
  width: 100%;
`;

export const ProjectsDrawerFiltersOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

interface ProjectDrawerFilterOptionProps {
  selected?: boolean;
}

export const ProjectDrawerFilterOption = styled.div<ProjectDrawerFilterOptionProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ selected }) => (selected ? "#171C33" : "rgba(23, 28, 51, 0.5)")};

  cursor: pointer;
`;

export const ProjectsDrawerListWrapper = styled.div`
  height: 100%;
  padding-bottom: 100px;
`;

export const ProjectsDrawerListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px 110px 0px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  height: 100%;
  overflow-y: auto;
`;

export const ProjectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  cursor: pointer;
`;

export const ProjectDrawerPill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;

  & > :not(:last-child) {
    margin-right: 5px;
  }

  background: rgba(57, 43, 98, 0.3);
  border-radius: 26px;
  max-width: 250px;

  position: relative;
`;

export const ProjectDrawerText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #392B62;
`;

export const ProjectsDrawerFiltersTitle = styled.h3`
  font-weight: 300;
  font-size: 32px;
  line-height: 42px;
  color: #171C33;
`;

export const ProjectsDrawerFiltersDescription = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #171C33;
`;

export const ProjectUserAvatar = styled.img`
  width: 22px;
  height: 22px;
  object-fit: cover;
  border: 2px solid #FFC229;
  border-radius: 1000px;

  position: absolute;
  right: -30px;
`;

export const ProjectClient = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #5478A1;

  position: absolute;
  right: 14px;
`;

interface ProjectsDrawerFilterDropdownWrapperProps {
  hasSelection?: boolean;
}

export const ProjectsDrawerFilterDropdownWrapper = styled.div<ProjectsDrawerFilterDropdownWrapperProps>`
  display: flex;
  align-items: center;

  .button-container {
    
    .dropdown-title {
      font-weight: ${({ hasSelection }) => (hasSelection ? "700" : "500")};
      font-size: 14px;
      line-height: 18px;

      color: ${({ hasSelection }) => (hasSelection ? "#EB823D" : "#171C33")};
    }
  }
`;

export const ProjectDrawerFilterOptionSkeleton = styled(SkeletonItem)`
  width: 56px;
  height: 18px;
  border-radius: 26px;
`;

interface ProjectSkeletonProps {
  width?: number;
}

export const ProjectSkeleton = styled(SkeletonItem)<ProjectSkeletonProps>`
  width: ${({ width }) => width || 150}px;
  height: 22px;
  border-radius: 26px;
`;