import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  profileDataSelector,
  setAllClients,
  setAllCompanies,
  setAllIndustries,
  setClients,
  setCompanies,
  setIndustries,
} from 'store/reducers/profile-editor/profile-editor-reducer';

import { useProfessionalPriorExperience } from './useProfessionalPriorExperience';

export default function useProfessionalPriorExperienceDrawer() {
  const dispatch = useDispatch();
  const { companies, clients, industries, allIndustries, allCompanies, allClients } =
    useSelector(profileDataSelector);
  const { isLoading, data, refetch } = useProfessionalPriorExperience({ enabled: false });

  useEffect(() => {
    if (allIndustries.length === 0) {
      refetch();
    }
  }, [allIndustries]);

  useEffect(() => {
    if (data) {
      dispatch(setCompanies(data.companies));
      dispatch(setAllCompanies(data.allCompanies));
      dispatch(setClients(data.clients));
      dispatch(setAllClients(data.allClients));
      dispatch(setIndustries(data.industries));
      dispatch(setAllIndustries(data.allIndustries));
    }
  }, [data]);

  return {
    companies,
    clients,
    industries,
    allIndustries,
    allCompanies,
    allClients,
    isLoading,
  };
}
