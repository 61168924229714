import { formatDate } from "utils/date-format";

export const kyu_company_people_highlight = ({
  kyu_company,
  kyu_skills,
  people_count,
  unique_passions_count,
}) => {
  let paragraph = `<p>As of today, <span class="highlight-people">${people_count} ${
    people_count > 1 ? "people" : "person"
  }</span> work at ${kyu_company}.</p>`;

  paragraph += "<p>";
  if (kyu_skills && kyu_skills.length > 0) {
    let kyu_skills_array = kyu_skills.map(
      (skill) => `<span class="highlight-skills">${skill}</span>`
    );

    if (kyu_skills_array.length > 1) {
      paragraph += `People most closely identify with ${kyu_skills_array
        .slice(0, kyu_skills_array.length - 1)
        .join(", ")} and <span class="highlight-skills">${
        kyu_skills_array[kyu_skills_array.length - 1]
      }</span> as areas of expertise. `;
    } else {
      paragraph += `People most closely identify with <span class="highlight-skills">${kyu_skills_array[0]}</span> as area of expertise. `;
    }
  }

  if (unique_passions_count > 0) {
    paragraph += `Outside from the lense of work, people added <span class="highlight-passion">${unique_passions_count} unique passions</span> to their profiles.</p>`;
  }

  return paragraph;
};

export const kyu_company_impact_highlight = ({
  biggest_project,
  industries_count,
  kyu_skills,
  kyu_company,
  longest_project,
  projects_count,
}) => {
  let paragraph = `<p>${kyu_company}’s people have worked on a total of <span class="highlight-project">${projects_count} ${
    projects_count > 1 ? "projects" : "project"
  }</span> distributed across <span class="highlight-industry">${industries_count} ${
    industries_count > 1 ? "diverse industries" : "industry"
  }</span>.</p>`;

  if (biggest_project || longest_project) {
    paragraph += "<p>";

    if (biggest_project) {
      paragraph += `The largest project to date was with <a href="/project/${
        biggest_project.slug
      }"> \
          <span class="highlight-project">${biggest_project.client_company}${
        biggest_project.start_date
          ? " in " + formatDate(biggest_project.start_date, { year: "numeric" })
          : ""
      }</span></a> \
          with ${biggest_project.people_count} ${
        biggest_project.people_count > 1 ? "people" : "person"
      } connected to it. `;
    }

    if (longest_project) {
      if (longest_project.end_date) {
        paragraph += `The longest project to date was with <a href="/project/${
          longest_project.slug
        }"><span class="highlight-project">${
          longest_project.client_company
        } between ${formatDate(longest_project.start_date)} to ${
          longest_project.end_date
            ? formatDate(longest_project.end_date)
            : "now"
        }</span></a>.`;
      } else {
        paragraph += `The longest project to date was with ${longest_project.client_company}.`;
      }
    }
    paragraph += `</p>`;
  }

  if (kyu_skills && kyu_skills.length > 0) {
    let kyu_skills_array = kyu_skills.map(
      (skill) => `<span class="highlight-skills">${skill}</span>`
    );

    if (kyu_skills_array.length > 1) {
      paragraph += `<p>People with ${kyu_skills_array
        .slice(0, -1)
        .join(", ")} and <span class="highlight-skills">${
        kyu_skills_array[kyu_skills_array.length - 1]
      }</span> are most often placed on projects.</p>`;
    } else {
      paragraph += `<p>People with <span class="highlight-skills">${kyu_skills_array[0]}</span> are most often placed on projects.</p>`;
    }
  }

  return paragraph;
};

export const kyu_company_company_highlight = ({}) => {
  let paragraph = `IDEO has done 24 Projects with clients in this industry. The average project length is 3 to 4 months.`;

  return paragraph;
};

export const kyu_company_project_highlight = ({}) => {
  let paragraph = ``;

  return paragraph;
};

export const kyu_company_skill_highlight = ({
  discipline_count,
  kyu_company,
  kyu_skill_people_count,
  kyu_skill_skill_count,
  people_count,
}) => {
  let paragraph = `<p><span class="highlight-people">${people_count} ${
    people_count > 1 ? "people" : "person"
  }</span> from <span class="highlight-kyu-company">${kyu_company}</span> across ${discipline_count} <span class="highlight-skills">${
    discipline_count > 1 ? "disciplines" : "discipline"
  }</span> are linked to this skill.</p>\
          <p>It is also connected to <span class="highlight-skills">${kyu_skill_skill_count} similar ${
    kyu_skill_skill_count > 1 ? "skills" : "skill"
  }</span> in other kyu\ 
          companies which collectively connects <span class="highlight-people">${kyu_skill_people_count} ${
    kyu_skill_people_count > 1 ? "people" : "person"
  }</span>.</p>`;

  return paragraph;
};

export const kyu_company_client_highlight = ({
  client_company,
  initial_date,
  kyu_company,
  projects_count,
  projects_length,
}) => {
  let paragraph = `<p><span class="highlight-client-company">${client_company}</span> has worked with\ 
  <span class="highlight-kyu-company">${kyu_company}</span> since ${formatDate(
    initial_date,
    { year: "numeric" }
  )} across <span class="highlight-project">${projects_count} ${
    projects_count > 1 ? "projects" : "project"
  }</span>. The average project length with <span class="highlight-kyu-company">${kyu_company}</span> is ${projects_length}.</p>`;

  return paragraph;
};

export const kyu_company_passion_highlight = ({
  kyu_company_count,
  people_count,
}) => {
  let paragraph = `<p>Across kyu, <span class="highlight-people">${people_count} ${
    people_count > 1 ? "people" : "person"
  }</span> from <span class="highlight-kyu-company">${kyu_company_count} ${
    kyu_company_count > 1 ? "companies" : "company"
  }</span>,\
    are linked to this passion.</p>`;

  return paragraph;
};

export const kyu_company_collaborative_projects_highlight = ({
  client_companies,
  kyu_companies,
  kyu_company,
}) => {
  let paragraph = `<p>Within the kyu collective, `;

  let kyu_companies_array = [];
  if (kyu_companies.length > 0) {
    kyu_companies_array = kyu_companies.map(
      (company) => `<span class="highlight-kyu-company">${company}</span>`
    );
  }

  if (kyu_companies_array.length > 1) {
    paragraph += `${kyu_company} has done cross-collaboration projects with ${kyu_companies_array
      .slice(0, kyu_companies_array.length - 1)
      .join(", ")} and <span class="highlight-kyu-company">${
      kyu_companies_array[kyu_companies_array.length - 1]
    }</span>.`;
  } else {
    paragraph += `${kyu_company} has done cross-collaboration projects with ${kyu_companies_array[0]}. `;
  }

  let client_companies_array = [];
  if (client_companies.length > 0) {
    client_companies_array = client_companies.map(
      (company) => `<span class="highlight-client-company">${company}</span>`
    );
  }

  if (client_companies_array.length > 1) {
    paragraph += `Together, they have worked with ${client_companies_array
      .slice(0, client_companies_array.length - 1)
      .join(", ")} and <span class="highlight-client-company">${
      client_companies_array[client_companies_array.length - 1]
    }</span>.`;
  } else {
    paragraph += `Together, they have worked with <span class="highlight-client-company">${client_companies_array[0]}</span>.`;
  }

  paragraph += `</p>`;

  return paragraph;
};
