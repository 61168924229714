import {  } from "app/components/DetailsPages/interfaces/client"
import { http } from "./http"

//Params
interface IClientDetailsParams {
  slug: string;
}

//Details
export const getClientDetails = (slug: string) => {
  return http.get<IClientDetails>(`client/${slug}/details`)
}

export const getClientSnapshot = (slug: string) => {
  return http.get<IClientSnapshot>(`client/${slug}/details/snapshot`);
}

export const getClientExpertise = (slug: string) => {
  return http.get<IClientExpertise>(`client/${slug}/details/expertise`);
}

export const getClientProjects = (slug: string, params: IClientProjectsParams) => {
  return http.get<IClientProjects>(`client/${slug}/details/projects`, params);
}

export const getClientMapSnippet = (slug: string) => {
  return http.get<IClientMapSnippet>(`client/${slug}/details/map_snippet`);
}

export const getClientStoriesAndHighlights = (slug: string) => {
  return http.get<IClientStoriesAndHighlights>(`client/${slug}/details/stories_and_highlights`);
}

export const getClientMeetSomeoneNew = (slug: string) => {
  return http.get<IClientMeetSomeoneNew>(`client/${slug}/details/meet_someone_new`);
}

export const getClientPeople = (slug: string) => {
  return http.get<IClientPeople>(`client/${slug}/details/people`);
}
  
export const getClientPriorExperiencePeople = (slug: string) => {
  return http.get<IClientPriorExperiencePeople>(`client/${slug}/details/prior_experience_people`);
}

//Drawers
export const getClientProjectsDrawer = (slug: string, params: IClientProjectsDrawerParams) => {
  return http.get<IClientProjectsDrawer>(`client/${slug}/drawers/projects`, params);
}

export const getClientPeopleDrawer = (slug: string, params: any) => {
  return http.get(`client/${slug}/drawers/people`, params);
}

export const getClientCompaniesDrawer = (slug: string) => {
  return http.get(`client/${slug}/drawers/companies`);
}
