import React from 'react';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getKyuTeam } from 'services/app';

export default function useKyuTeam(
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `kyu-team-footer`,
    async () => {
      const response = await getKyuTeam();
      if (!response.ok) {
        throw new Error(response.originalError?.message);
      }

      return response.data;
    },
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
