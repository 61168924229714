import { IProjectDetailsAboutResponse, IProjectDetailsImpactResponse, IProjectDetailsMapSnippetResponse, IProjectDetailsMeetSomeoneNewResponse, IProjectDetailsPeopleResponse, IProjectDetailsProjectsResponse, IProjectDetailsResponse } from "app/components/DetailsPages/interfaces/project";
import { http } from "./http"

export const getProjectDetails = (slug: string) => {
  return http.get<IProjectDetailsResponse>(`project/${slug}/details`);
}

export const getProjectDetailsAbout = (slug: string) => {
  return http.get<IProjectDetailsAboutResponse>(`project/${slug}/details/about`);
}

export const getProjectDetailsImpact = (slug: string) => {
  return http.get<IProjectDetailsImpactResponse>(`project/${slug}/details/impact`);
}

export const getProjectDetailsMeetSomeoneNew = (slug: string) => {
  return http.get<IProjectDetailsMeetSomeoneNewResponse>(`project/${slug}/details/meet_someone_new`);
}

export const getProjectDetailsPeople = (slug: string) => {
  return http.get<IProjectDetailsPeopleResponse>(`project/${slug}/details/people`);
}

export const getProjectDetailsProjects = (slug: string) => {
  return http.get<IProjectDetailsProjectsResponse>(`project/${slug}/details/projects`);
}

export const getProjectDetailsMapSnippet = (slug: string) => {
  return http.get<IProjectDetailsMapSnippetResponse>(`project/${slug}/details/map_snippet`);
}

interface PeopleDrawerParams {
  passion_uuids?: string[];
  roles_uuids?: string[];
  locations_uuids?: string[];
  kyu_skills_uuids?: string[];
}

export const getProjectDetailsPeopleDrawerList = (slug: string, params: PeopleDrawerParams) => {
  return http.get(`/project/${slug}/drawers/people`, params);
}

interface ProjectDrawerParams {
  companies_uuids: string[];
  clients_uuids: string[];
  industries_uuids: string[];
}

export const getProjectDetailsProjectsDrawer = (slug: string, params: ProjectDrawerParams) => {
  return http.get(`/project/${slug}/drawers/projects`, params);
}