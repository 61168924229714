import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import ProfileEditInput from 'app/components/CommonStyled/ProfileEditInput';
import { CloseIcon } from 'app/components/shared/icons';
import AlarmClock from 'app/components/shared/icons/alarm-clock';
import { Form, Formik, FormikProps } from 'formik';
import useUserData from 'hooks/useUserData';
import { ICollectionParams, createIndustry, postIndustries } from 'services/profile-editor';
import {
  profileDataSelector,
  setIndustries,
  setAllIndustries,
} from 'store/reducers/profile-editor/profile-editor-reducer';
import * as Yup from 'yup';

import { useGetIndustries, usePostIndustries } from '../../hooks/useIndustries';
import useProfessionalPriorExperienceDrawer from '../../hooks/useProfessionalPriorExperienceDrawer';
import ProfileEditorDrawer, { ProfileEditorDrawerRefProps } from '../Drawer';
import { ProfileEditorDrawerTitle } from '../styled';
import {
  ProfileEditorDrawerExperienceNeedsApproval,
  ProfileEditorDrawerExperiencePill,
  ProfileEditorDrawerExperiencePillIconContainer,
  ProfileEditorDrawerExperiencePillsContainer,
  ProfileEditorDrawerExperiencePillText,
  ProfileEditorDrawerExperiencePillTextContainer,
  ProfileEditorDrawerExperienceWrapper,
} from './styled';

interface ProfileEditorDrawerIndustriesProps {
  onClose: () => void;
  companyName?: string;
}

export default function ProfileEditorDrawerIndustries({
  onClose,
  companyName,
}: ProfileEditorDrawerIndustriesProps) {
  const dispatch = useDispatch();
  const { industries, allIndustries } = useProfessionalPriorExperienceDrawer();
  const [industriesCreated, setIndustriesCreated] = useState<any[]>([]);
  const formikRef = useRef<FormikProps<any>>(null);
  const [optionsPicked, setOptionsPicked] = useState<any[]>([]);
  const drawerRef = useRef<ProfileEditorDrawerRefProps>(null);

  useEffect(() => {
    setOptionsPicked(industries.map((industry) => ({ ...industry, label: industry.name })));
    formikRef.current.setFieldValue(
      'collection',
      industries.map((industry) => industry.uuid)
    );
  }, [industries]);

  const options = useMemo(() => {
    return allIndustries.map((industry) => ({
      ...industry,
      label: industry.name,
      value: industry.uuid,
    }));
  }, [allIndustries]);

  //Set options picked for select
  useEffect(() => {
    if (industries && industries.length > 0) {
      setOptionsPicked(
        industries.map((industry) => ({
          value: industry.uuid,
          label: industry.name,
          approved: industry.approved,
        }))
      );
    }
  }, [industries]);

  //Create industries
  const handlePostIndustries = async (params: ICollectionParams) => {
    let newlyCreatedIndustries = [];
    const response = await postIndustries(params);
    if (!response.ok) {
      throw new Error(response.originalError?.message);
    }
    if (industriesCreated.length > 0) {
      newlyCreatedIndustries = await Promise.all(
        industriesCreated.map((industry) => createIndustry({ name: industry.value }))
      );

      newlyCreatedIndustries = newlyCreatedIndustries.map((industry) => ({
        uuid: industry.data.uuid,
        value: industry.data.uuid,
        label: industry.data.name,
        name: industry.data.name,
        isCreated: false,
        approved: false,
      }));
    }

    const existingIndustries = optionsPicked.filter((industry) => !industry.isCreated);

    const allAvailableIndustries = [...allIndustries, ...newlyCreatedIndustries];
    dispatch(setAllIndustries(allAvailableIndustries));
    dispatch(setIndustries([...existingIndustries, ...newlyCreatedIndustries]));
    return response.data;
  };

  const { mutate } = useMutation(handlePostIndustries, drawerRef.current?.handleMutation);

  //Validation
  const industriesValidationSchema = Yup.object().shape({
    collection: Yup.array(),
  });

  //Options changed
  const handleOptionsChanged = (options: any[]) => {
    let newlyCreatedOptions = options.reduce((arr, option) => {
      if (option.isCreated) {
        arr.push({ ...option, approved: false });
      }
      return arr;
    }, []);
    newlyCreatedOptions = [...newlyCreatedOptions, ...industriesCreated];
    setIndustriesCreated(newlyCreatedOptions);
    const alreadyCreatedOptions = options.filter((option) => !option.isCreated);
    const allOptions = [...alreadyCreatedOptions, ...newlyCreatedOptions];
    const uniqOptions = allOptions.filter(
      (option, index, self) => index === self.findIndex((t) => t.value === option.value)
    );

    const optionsToSave = uniqOptions.map((industry) => {
      const existingIndustry = industries.find(
        (industryItem) => industryItem.uuid === industry.value
      );

      if (existingIndustry) {
        return {
          ...existingIndustry,
          ...industry,
        };
      }
      return industry;
    });

    setOptionsPicked(optionsToSave);
  };

  //Submit
  const handleSubmit = (values) => {
    mutate({ collection: values.collection });
  };

  //Remove behavior
  const handleRemoveIndustry = (industry) => {
    const filteredOptions = optionsPicked.filter((option) => option.value !== industry.value);
    if (industry.isCreated) {
      setIndustriesCreated(industriesCreated.filter((option) => option.value !== industry.value));
    }
    formikRef.current.setFieldValue(
      'collection',
      filteredOptions.map((option) => option.value)
    );
  };

  return (
    <Formik
      initialValues={{
        collection: industries.map((industry) => industry.uuid),
      }}
      validationSchema={industriesValidationSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {(props) => (
        <Form>
          <ProfileEditorDrawer
            ref={drawerRef}
            header={<ProfileEditorDrawerIndustriesHeader />}
            needsSaving={props.dirty}
            onClose={onClose}
          >
            <ProfileEditorDrawerExperienceWrapper>
              <ProfileEditInput
                {...props}
                name={'collection'}
                label={`Before ${companyName}, which industries have you worked in?`}
                tooltip={
                  "You can search and select industries from the dropdown menu. If you can’t find the industry you’re looking for, you can create a industry and add it to kyu OS. New industries are reviewed for typos and similarities by the Creative Intelligence team and they'll display on your profile once approved."
                }
                type={'select'}
                placeholder={'Search for industries...'}
                options={options}
                settings={{
                  allowCreate: true,
                }}
                onOptionsChanged={handleOptionsChanged}
              />
              <ProfileEditorDrawerExperiencePillsContainer>
                {optionsPicked &&
                  optionsPicked.length > 0 &&
                  optionsPicked.map((option, index) => (
                    <ProfileEditorDrawerExperiencePill key={option.value}>
                      <ProfileEditorDrawerExperiencePillTextContainer>
                        <ProfileEditorDrawerExperiencePillText>
                          {option.label}
                        </ProfileEditorDrawerExperiencePillText>
                        {option.approved == false && (
                          <ProfileEditorDrawerExperienceNeedsApproval>
                            <AlarmClock width={14} height={14} fill="#A26565" />
                            <span>Pending Review</span>
                          </ProfileEditorDrawerExperienceNeedsApproval>
                        )}
                      </ProfileEditorDrawerExperiencePillTextContainer>
                      <ProfileEditorDrawerExperiencePillIconContainer
                        onClick={() => handleRemoveIndustry(option)}
                      >
                        <CloseIcon width={9} height={9} fill={'var(--color-primary)'} />
                      </ProfileEditorDrawerExperiencePillIconContainer>
                    </ProfileEditorDrawerExperiencePill>
                  ))}
              </ProfileEditorDrawerExperiencePillsContainer>
            </ProfileEditorDrawerExperienceWrapper>
          </ProfileEditorDrawer>
        </Form>
      )}
    </Formik>
  );
}

function ProfileEditorDrawerIndustriesHeader() {
  return <ProfileEditorDrawerTitle>Previous Industries</ProfileEditorDrawerTitle>;
}
