import React from 'react';

const StarsBright = ({ fill = '#171C33', width = 17, height = 18, styles = {} }) => {
  return (
    <svg
      width={width}
      height={height}
      style={styles}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.5L11.2523 5.83716L17 7L11.2523 8.16284L10 13.5L8.74771 8.16284L3 7L8.74771 5.83716L10 0.5Z"
        fill={fill}
      />
      <path
        d="M3.5 9.5L4.12614 12.7844L7 13.5L4.12614 14.2156L3.5 17.5L2.87386 14.2156L0 13.5L2.87386 12.7844L3.5 9.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default StarsBright;
