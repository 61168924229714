import useModelAndSlug from "hooks/useModelAndSlug";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isNavBarOpenSelector } from "store/reducers/navbar-reducer";
import { detailsPages } from "utils/constants";
import BreadcrumbArrow from "../shared/icons/breadcrumb-arrow";
import { BreadcrumbWrapper, BreadcrumbItem } from "./styled";

const PagesBreadcrumb = () => {
  const location = useLocation();
  const { push } = useHistory();
  const isNavBarOpen = useSelector(isNavBarOpenSelector);
  const { model } = useModelAndSlug();

  const isMapPage = location.pathname.endsWith("/map");
  const isInfoPage =
    !!detailsPages.find((p) => location.pathname.startsWith(`/${p}/`)) &&
    !isMapPage;

  const handleDetailsPage = () => {
    if (location.pathname.endsWith("/map")) {
      push(location.pathname.slice(0, location.pathname.length - 4));
    }
  };

  const itemColor = isMapPage || model === "client" ? "light" : "dark";

  return (
    <BreadcrumbWrapper
      mode={itemColor}
      isMapPage={isMapPage}
      isNavBarOpen={isNavBarOpen}
    >
      <BreadcrumbItem mode={itemColor} onClick={() => push("/")}>
        SEARCH
      </BreadcrumbItem>

      {(isInfoPage || isMapPage) && (
        <>
          <BreadcrumbArrow />
          <BreadcrumbItem
            mode={itemColor}
            disabled={isInfoPage}
            isCurrentPage={isInfoPage}
            onClick={handleDetailsPage}
          >
            DETAILS
          </BreadcrumbItem>
        </>
      )}

      {isMapPage && (
        <>
          <BreadcrumbArrow />
          <BreadcrumbItem
            mode={itemColor}
            disabled={isMapPage}
            isCurrentPage={isMapPage}
          >
            MAP
          </BreadcrumbItem>
        </>
      )}
    </BreadcrumbWrapper>
  );
};

export default PagesBreadcrumb;
