import styled from 'styled-components';

interface CompanyLogoWrapperProps {
  size: number;
  borderColor?: string;
}

export const CompanyLogoWrapper = styled.div<CompanyLogoWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid ${({ borderColor }) => borderColor ?? '#5478A1'};
  border-radius: 100%;
  background: #ffffff;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

interface CompanyLogoContainerProps {
  size: number;
  borderColor?: string;
  onClick?: Function;
  backgroundColor?: string;
}

export const CompanyLogoContainer = styled.img<CompanyLogoContainerProps>`
  background: ${({ backgroundColor }) => backgroundColor ?? '#FFFFFF'};
  object-fit: contain;
  border: 2px solid ${({ borderColor }) => borderColor ?? '#5478A1'};
  border-radius: 100%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export const CompanyLogoEmpty = styled.div<CompanyLogoContainerProps>`
  background: ${({ backgroundColor }) => backgroundColor ?? '#FFFFFF'};
  border: 2px solid ${({ borderColor }) => borderColor ?? '#5478A1'};
  border-radius: 100%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;
