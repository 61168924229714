import { createSlice } from '@reduxjs/toolkit';

interface IProfileEditorState {
  isNavigationOpen: boolean;
  currentPage: string;
  profileData: {
    aboutYou: {
      preferredName: string;
      pronunciation: string;
      pronouns: string;
    };
    linkedin: string;
    spotify: string;
    bio: string;
    allCountries: any[];
    countries: any[];
    languages: any[];
    outside_work: string;
    passions: any[];
    allPassions: any[];
    role_description: string;
    skills: any[];
    dream_project: string;
    years_experience: string;
    companies: any[];
    clients: any[];
    industries: any[];
    projects: any[];
  };
}

export const slice = createSlice({
  name: 'profileEditor',
  initialState: {
    isNavigationOpen: false,
    currentPage: 'human',
    switchToPublicProfile: false,
    profileData: {
      aboutYou: {
        preferredName: '',
        pronunciation: '',
        pronouns: '',
      },
      linkedin: '',
      spotify: '',
      bio: '',
      bio_inspo: [],
      allCountries: [],
      countries: [],
      languages: [],
      outside_work: '',
      outside_work_inspo: [],
      passions: [],
      passions_inspo: {},
      allPassions: [],
      role_description: '',
      role_inspo: [],
      skills: [],
      skills_inspo: {},
      allSkills: [],
      dream_project: '',
      dream_project_inspo: [],
      years_experience: '',
      allCompanies: [],
      companies: [],
      allClients: [],
      clients: [],
      allIndustries: [],
      industries: [],
    },
    showLinkedinBeacon: true,
  },
  reducers: {
    setIsNavigationOpen: (state, action: { payload: boolean }) => ({
      ...state,
      isNavigationOpen: action.payload,
    }),
    toggleNavigation: (state) => ({
      ...state,
      isNavigationOpen: !state.isNavigationOpen,
    }),
    setCurrentPage: (state, action: { payload: string }) => ({
      ...state,
      currentPage: action.payload,
    }),
    toggleCurrentPage: (state) => ({
      ...state,
      currentPage: state.currentPage === 'human' ? 'professional' : 'human',
    }),
    setSwitchToPublicProfile: (state, action: { payload: boolean }) => ({
      ...state,
      switchToPublicProfile: action.payload,
    }),
    setAboutYou: (state, action: { payload: any }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        aboutYou: action.payload,
      },
    }),
    setLinkedin: (state, action: { payload: string }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        linkedin: action.payload,
      },
    }),
    setSpotify: (state, action: { payload: string }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        spotify: action.payload,
      },
    }),
    setBio: (state, action: { payload: string }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        bio: action.payload,
      },
    }),
    setBioInspo: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        bio_inspo: action.payload,
      },
    }),
    setCountries: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        countries: action.payload,
      },
    }),
    setAllCountries: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        allCountries: action.payload,
      },
    }),
    setLanguages: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        languages: action.payload,
      },
    }),
    setAllLanguages: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        allLanguages: action.payload,
      },
    }),
    setOutsideWork: (state, action: { payload: string }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        outside_work: action.payload,
      },
    }),
    setOutsideWorkInspo: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        outside_work_inspo: action.payload,
      },
    }),
    setPassions: (state, action: { payload: any[] }) => {
      const sortedPassions = sortObjectsByPriority(action.payload);

      return {
        ...state,
        profileData: {
          ...state.profileData,
          passions: sortedPassions,
        },
      };
    },
    setPassionsInspo: (state, action: { payload: any }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        passions_inspo: action.payload,
      },
    }),
    setAllPassions: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        allPassions: action.payload,
      },
    }),
    setRoleDescription: (state, action: { payload: string }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        role_description: action.payload,
      },
    }),
    setRoleInspo: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        role_inspo: action.payload,
      },
    }),
    setSkills: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        skills: action.payload,
      },
    }),
    setSkillsInspo: (state, action: { payload: any }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        skills_inspo: action.payload,
      },
    }),
    setAllSkills: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        allSkills: action.payload,
      },
    }),
    setDreamProject: (state, action: { payload: string }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        dream_project: action.payload,
      },
    }),
    setDreamProjectInspo: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        dream_project_inspo: action.payload,
      },
    }),
    setYearsExperience: (state, action: { payload: string }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        years_experience: action.payload,
      },
    }),
    setCompanies: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        companies: action.payload,
      },
    }),
    setAllCompanies: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        allCompanies: action.payload,
      },
    }),
    setClients: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        clients: action.payload,
      },
    }),
    setAllClients: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        allClients: action.payload,
      },
    }),
    setIndustries: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        industries: action.payload,
      },
    }),
    setAllIndustries: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        allIndustries: action.payload,
      },
    }),
    setProjects: (state, action: { payload: any[] }) => ({
      ...state,
      profileData: {
        ...state.profileData,
        projects: action.payload,
      },
    }),
    setShowLinkedinBeacon: (state, action: { payload: boolean }) => ({
      ...state,
      showLinkedinBeacon: action.payload,
    }),
  },
});

export const {
  setIsNavigationOpen,
  toggleNavigation,
  setCurrentPage,
  toggleCurrentPage,
  setSwitchToPublicProfile,
  setAboutYou,
  setLinkedin,
  setSpotify,
  setBio,
  setBioInspo,
  setCountries,
  setAllCountries,
  setLanguages,
  setAllLanguages,
  setOutsideWork,
  setOutsideWorkInspo,
  setPassions,
  setPassionsInspo,
  setAllPassions,
  setRoleDescription,
  setRoleInspo,
  setSkills,
  setSkillsInspo,
  setAllSkills,
  setDreamProject,
  setDreamProjectInspo,
  setYearsExperience,
  setCompanies,
  setAllCompanies,
  setClients,
  setAllClients,
  setIndustries,
  setAllIndustries,
  setProjects,
  setShowLinkedinBeacon,
} = slice.actions;

export const isNavigationOpenSelector = (state) => state.profileEditor.isNavigationOpen;
export const currentPageSelector = (state) => state.profileEditor.currentPage;
export const profileDataSelector = (state) => state.profileEditor.profileData;
export const switchToPublicProfileSelector = (state) => state.profileEditor.switchToPublicProfile;
export const showLinkedinBeaconSelector = (state) => state.profileEditor.showLinkedinBeacon;

export default slice.reducer;

function sortObjectsByPriority(arr) {
  const objectsWithDescription = [];
  const objectsWithoutDescription = [];

  arr.forEach((obj) => {
    if (obj.description && obj.description !== null && obj.description !== '') {
      objectsWithDescription.push(obj);
    } else {
      objectsWithoutDescription.push(obj);
    }
  });

  objectsWithDescription.sort((a, b) => a.name.localeCompare(b.name));

  const sortedArray = objectsWithDescription.concat(objectsWithoutDescription);

  return sortedArray;
}
