import React, { useContext, useState, useMemo } from 'react';
import { UseQueryResult } from 'react-query';

import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import { shuffleArray } from 'utils';

import { ICompanyNewPeopleResponse } from '../interfaces/company';
import ComponentError from '../shared/ComponentError/ComponentError';
import { DetailsPageContext } from '../shared/DetailsPageContext';
import { MeetSomeoneNew } from '../shared/MeetSomeoneNew/MeetSomeoneNew';
import {
  CompanyDetailsMeetSomeoneNewComponentWrapper,
  CompanyDetailsNewFaceContainer,
  CompanyDetailsNewFaceName,
  CompanyDetailsNewFacePosition,
  CompanyDetailsNewFacesContainer,
  CompanyDetailsNewFacesSeeMoreContainer,
  CompanyDetailsNewFacesSeeMoreText,
  CompanyDetailsNewFacesSeeMoreValue,
  CompanyDetailsNewFacesSeeMoreWrapper,
  CompanyDetailsNewFacesTitle,
  CompanyDetailsNewFaceTextContainer,
  CompanyDetailsNewPeopleContainer,
  CompanyDetailsNewPeopleTitle,
  CompanyDetailsNewPeopleWrapper,
} from './styled';

interface CompanyDetailsNewPeopleProps {
  query?: UseQueryResult<ICompanyNewPeopleResponse, Error>;
}

export default function CompanyDetailsNewPeople({ query }: CompanyDetailsNewPeopleProps) {
  const { isLoading, isIdle, isError, data } = query;
  const context = useContext(DetailsPageContext);
  const newFaces = useMemo(() => {
    if (data && data.new_faces) {
      return shuffleArray(data?.new_faces?.people).slice(0, 4);
    }
  }, [data]);

  if (isLoading || isIdle) return <div style={{ minHeight: 360 }} />;

  if (isError) return <ComponentError query={query} skeleton={<></>} />;

  return data.meet_someone_new || data.new_faces ? (
    <CompanyDetailsNewPeopleWrapper>
      <CompanyDetailsNewPeopleTitle>People of {context.data.name}</CompanyDetailsNewPeopleTitle>
      <CompanyDetailsNewPeopleContainer>
        {data.new_faces && <NewFaces people={newFaces} companyName={context.data.name} />}
        {data.meet_someone_new && (
          <CompanyDetailsMeetSomeoneNewComponentWrapper>
            <div>
              <CompanyDetailsNewFacesTitle>MEET SOMEONE NEW</CompanyDetailsNewFacesTitle>
              <MeetSomeoneNew people={data.meet_someone_new.people} />
            </div>
          </CompanyDetailsMeetSomeoneNewComponentWrapper>
        )}
      </CompanyDetailsNewPeopleContainer>
    </CompanyDetailsNewPeopleWrapper>
  ) : (
    <></>
  );
}

export function NewFaces({
  people,
  companyName,
  fullTitle,
  avatarSize = 64,
}: {
  people: any[];
  companyName?: string;
  fullTitle?: string;
  avatarSize?: number;
}) {
  const [maxPeople, setMaxPeople] = useState<number>(4);
  const title = fullTitle || `NEW FACES AT ${companyName}`;

  const handleSeeMoreClick = () => {
    setMaxPeople(maxPeople + 4);
  };

  return (
    <CompanyDetailsNewFacesContainer>
      <CompanyDetailsNewFacesTitle>{title}</CompanyDetailsNewFacesTitle>
      {people.slice(0, maxPeople).map((person, index) => (
        <React.Fragment key={index}>
          <CompanyDetailsNewFaceContainer>
            <PersonAvatar
              size={avatarSize}
              avatar={person?.avatar}
              highlighted
              name={person?.full_name.split(' ')[0]}
              lastName={person?.full_name.split(' ')[1]}
              slug={person?.slug}
              openDrawer
            />
            <CompanyDetailsNewFaceTextContainer>
              <CompanyDetailsNewFaceName>{person?.full_name}</CompanyDetailsNewFaceName>
              <CompanyDetailsNewFacePosition>{person?.role_name}</CompanyDetailsNewFacePosition>
            </CompanyDetailsNewFaceTextContainer>
          </CompanyDetailsNewFaceContainer>
        </React.Fragment>
      ))}
      {people?.length > maxPeople ? (
        <CompanyDetailsNewFacesSeeMoreWrapper onClick={handleSeeMoreClick}>
          <CompanyDetailsNewFacesSeeMoreContainer
            shouldAddPadding={people?.length - maxPeople >= 10}
          >
            <CompanyDetailsNewFacesSeeMoreValue>
              +{people?.length - maxPeople}
            </CompanyDetailsNewFacesSeeMoreValue>
          </CompanyDetailsNewFacesSeeMoreContainer>
          <CompanyDetailsNewFacesSeeMoreText>See More</CompanyDetailsNewFacesSeeMoreText>
        </CompanyDetailsNewFacesSeeMoreWrapper>
      ) : null}
    </CompanyDetailsNewFacesContainer>
  );
}
