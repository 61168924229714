import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getOnboarding } from 'services/app';

import { OnboardingResponse } from '../interfaces';

export default function useOnboardingData(
  options?: Omit<
    UseQueryOptions<OnboardingResponse, Error, OnboardingResponse, QueryKey>,
    'queryKey'
  >
) {
  return useQuery<OnboardingResponse, Error>(
    ['onboarding'],
    () =>
      getOnboarding().then((response) => {
        if (!response.ok) {
          throw new Error(response.originalError?.message);
        }
        return response.data;
      }),
    {
      ...options,
      cacheTime: 0,
    }
  );
}
