const project_highlights = ({
  client_company,
  client_company_active_projects_count,
  client_company_slug,
  client_company_projects_count,
  kyu_company,
  kyu_company_slug,
  people_count,
  project_length_in_months,
}) => {
  let paragraph = `<p><span class="highlight-people">${people_count} ${
    people_count > 1 ? "people" : "person"
  }</span> from \
    <a href="/member-company/${kyu_company_slug}"><span class="highlight-kyu-company">${kyu_company}</span></a> \
    worked on this project for <a href="/client/${client_company_slug}"><span class="highlight-client-company">${client_company}</span></a>. `;

  if (project_length_in_months) {
    paragraph += `It was a ${project_length_in_months} month engagement.`;
  }

  paragraph += "</p>";

  if (client_company_active_projects_count > 0) {
    paragraph += `<p>There are <a href="/client/${client_company_slug}"><span class="highlight-project">${client_company_active_projects_count} other active ${
      client_company_active_projects_count > 1 ? "projects" : "project"
    }</span></a> `;
  } else {
    paragraph += `<p>There aren't other active projects `;
  }

  paragraph += `with this client. In total, we’ve done ${client_company_projects_count} ${
    client_company_projects_count > 1 ? "projects" : "project"
  } with <a href="/client/${client_company_slug}"><span class="highlight-client-company">${client_company}</span></a> across the collective.</p>`;

  return paragraph;
};

const project_client_company_highlights = ({ team_active_projects }) => {
  let paragraph = `<p>Nobody in the team has current active projects with this client.</p>`;

  if (team_active_projects.length > 0) {
    let projects_array = team_active_projects.map(
      (project) => `<span class="highlight-project">${project}</span>`
    );
    let projects_description = "";

    if (projects_array.length > 1) {
      projects_description = `${projects_array
        .slice(0, projects_array.length - 1)
        .join(", ")} and ${projects_array[projects_array.length - 1]}`;
    } else {
      projects_description = `${projects_array[0]}`;
    }

    paragraph = `<p>Most recently, people in this group worked on ${projects_description}.</p>`;
  }

  return paragraph;
};

export { project_highlights, project_client_company_highlights };
