import styled from 'styled-components';
import { infiniteRotationAnimation } from 'styles/animations';
import { BREAKPOINTS } from 'styles/media';

export const OnboardingStepFiveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 108px 226px 49px 226px;

  & > :not(:last-child) {
    margin-bottom: 49px;

    ${BREAKPOINTS.large_tablet_landscape} {
      margin-bottom: 54px;
    }
  }

  ${BREAKPOINTS.large_tablet_landscape} {
    padding: 68px 184px 46px 184px;
    flex-direction: column;
  }

  ${BREAKPOINTS.mobile} {
    padding: 36px 24px 56px 24px;
  }
`;

export const OnboardingStepFiveInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 307px;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }

  ${BREAKPOINTS.large_tablet_landscape} {
    max-width: 100%;
  }
`;

export const OnboardingStepFiveInformationTitle = styled.h2`
  font-weight: 400;
  font-size: 38px;
  line-height: 42px;
  color: var(--color-text);

  ${BREAKPOINTS.large_tablet_landscape} {
    text-align: center;
  }

  ${BREAKPOINTS.mobile} {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const OnboardingStepFiveInformationDescription = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: var(--color-text);

  ${BREAKPOINTS.mobile} {
    font-size: 16px;
  }
`;

export const OnboardingStepFiveInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

export const OnboardingStepFiveInformationIconContainer = styled.div`
  min-width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  background: #ffffff;
`;

//PEOPLE
export const OnboardingStepFivePeopleWrapper = styled.div`
  max-width: 572px;

  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }

  ${BREAKPOINTS.large_tablet_landscape} {
    max-width: 100%;
  }
`;

export const OnboardingStepFivePeopleTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > :last-child {
    justify-content: flex-end;
  }

  ${BREAKPOINTS.large_tablet_landscape} {
    flex-direction: column;

    & > :not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const OnboardingStepFivePeopleTopColumn = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const OnboardingStepFivePeopleHint = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: rgba(23, 28, 51, 0.5);
`;

export const OnboardingStepFivePeoplePassionName = styled.h3`
  font-weight: 400;
  font-size: 32px;
  line-height: normal;
  color: var(--color-text);

  ${BREAKPOINTS.mobile} {
    font-size: 24px;
  }
`;

export const OnboardingStepFivePeoplePassionTrend = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: normal;

  max-width: 180px;

  text-align: right;
  color: rgba(23, 28, 51, 0.5);

  & > .highlight {
    font-weight: 700;
    color: var(--color-text);
  }

  ${BREAKPOINTS.large_tablet_landscape} {
    max-width: 100%;
    text-align: start;
  }
`;

export const OnboardingStepFivePeopleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  ${BREAKPOINTS.tablet} {
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }
`;

export const OnboardingStepFivePeopleAddPassionButton = styled.button`
  width: fit-content;
  min-height: 47px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  padding: 8px 48px 8px 40px;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  border-radius: 100px;

  background: var(--color-data-people-background);
  transition: 0.3s background ease;
  &:hover {
    background: var(--color-data-people-background-hover);
  }

  & > svg {
    min-width: 22px;
  }

  & > .spinner-icon {
    ${infiniteRotationAnimation()}
  }
`;

export const OnboardingStepFivePeopleAddPassionButtonText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;

  text-align: center;
  color: var(--color-text);

  & > .highlight {
    font-weight: 700;
  }

  ${BREAKPOINTS.large_tablet_landscape} {
    text-align: start;
  }
`;

export const OnboardingStepFiveMoreInformationContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;

  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: #f5faff;

  ${BREAKPOINTS.mobile} {
    height: 89px;
  }
`;

export const OnboardingStepFiveMoreInformationText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: var(--color-text);

  & > .highlight {
    font-weight: 700;
  }
`;

// PERSON CARD
export const OnboardingStepFivePersonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }

  padding: 16px;

  border-radius: 8px;
  background: #f7f7f7;
`;

export const OnboardingStepFivePersonInformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 3px;
  }
`;

export const OnboardingStepFivePersonName = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: #000000;
`;

export const OnboardingStepFivePersonRoleContainer = styled.div`
  display: inline;
  & > :not(:last-child) {
    margin-right: 4px;
  }

  & > :nth-child(2) {
    transform: translateY(25%);
  }
`;

export const OnboardingStepFivePersonRole = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: #000000;
`;
