import React, { useState, useRef, useEffect } from 'react';
import useOnClickOutside from 'hooks/click-outside';
import { getSearch, getSearchSingle } from 'services/app';
import { CloseIconSmall, SearchIconSmall, CloseIcon } from 'app/components/shared/icons';
import SearchResultBlockType from './components/search-result-block';
import {
  SelectWrapper,
  CustomSelect,
  PillItem,
  Option,
  OptionCreate,
  OptionLoading,
  OptionsWrapper,
  InputWrapper,
  SearchBlock,
  ButtonSearch,
  ButtonRemoveOption,
  CustomSelectWrapper,
  RightElementWrapper,
} from './styled';

function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

interface ISelect {
  placeholder?: string;
  type?: string;
  maxSearchReached?: boolean;
  onValueChange?: any;
  onSubmit?: any;
  preloadOptions?: boolean;
  optionFromParent?: object;
  pillColor?: string;
  rightElement?: React.ReactElement;
}

// TO-DO should be on utils
const valuesSelectedMax = {
  single: 1,
};

const limitCharactersToSearch = {
  'known-group': 3,
  multiple: 3,
  people: 3,
  'skills-more': 1,
  passions: 1,
  kyu_skills: 1,
  single: 3,
};

const SearchMultiple = ({
  maxSearchReached,
  onSubmit,
  onValueChange,
  optionFromParent,
  placeholder,
  preloadOptions,
  type,
  pillColor = 'background-pill',
  rightElement = null,
}: ISelect) => {
  const [preloadedData, setPreloadedData] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [newValueText, setNewValueText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [focusKeyNumber, setFocusKeyNumber] = useState(null);
  const [showNotFound, setShowNotFound] = useState(false);
  const [optionsLocal, setOptionsLocal] = useState([]);
  const [valuesSelected, setValuesSelected] = useState([]);
  const selectRef = useRef(null);
  const inputRef = useRef(null);
  const optionsWrapperRef = useRef(null);
  const itemsEls = useRef(new Array());
  let plusIndex = 0;

  useOnClickOutside(selectRef, () => {
    setFocusKeyNumber(null);
    setMenuIsOpen(false);

    if (type !== 'single') {
      setNewValueText('');
    }
  });

  useEffect(() => {
    if (optionFromParent && optionFromParent.model) {
      const model = optionFromParent.model === 'person' ? 'people' : optionFromParent.model;
      setValuesSelected([
        {
          uuid: optionFromParent.id,
          name: optionFromParent.name,
          slug: optionFromParent.slug,
          key: model,
        },
      ]);

      setNewValueText(optionFromParent.name);
    }
  }, [optionFromParent]);

  useEffect(() => {
    onValueChange(valuesSelected);
  }, [valuesSelected]);

  useEffect(() => {
    const preloadData = async () => {
      setIsLoading(true);
      const loadMoreRequest = await getSearch({ term: '', model: type });
      if (loadMoreRequest.ok) {
        if (!loadMoreRequest.data[type]) {
          setShowNotFound(true);
          setOptionsLocal([]);
          setFocusKeyNumber(null);
        } else {
          setShowNotFound(false);
          setPreloadedData(loadMoreRequest.data[type]);
          setOptionsLocal(loadMoreRequest.data[type]);
        }
        setIsLoading(false);
      }
    };

    if (preloadOptions) {
      preloadData();
    }
  }, []);

  const handleChange = (event) => {
    setNewValueText(event.target.value);
  };

  const isHidden = (el) => {
    if (el) {
      var style = window.getComputedStyle(el);
      return style.display === 'none';
    }
    return false;
  };

  const scrollToEl = (toUp) => {
    const wrapperHeight = optionsWrapperRef.current.clientHeight;
    const scrollTop = optionsWrapperRef.current.scrollTop;
    const itemHeight = 31;

    let countOffset = 0;
    if (focusKeyNumber) {
      for (let i = 0; i < focusKeyNumber; i++) {
        if (!isHidden(itemsEls.current[i])) {
          countOffset = countOffset + 1;
        }
      }
    }

    var viewport = scrollTop + wrapperHeight;
    var elOffset = itemHeight * (countOffset - (toUp ? 1 : -1));

    if (elOffset < scrollTop || elOffset + itemHeight > viewport)
      optionsWrapperRef.current.scrollTop = elOffset;
  };

  const setNextKey = () => {
    let optionsToUseKeys = optionsLocal;
    if (optionsLocal.constructor === Object && Object.keys(optionsLocal).length > 0) {
      optionsToUseKeys = [];
      Object.keys(optionsLocal).map((key) => {
        if (key !== 'search_term' && optionsLocal[key].length > 0) {
          optionsToUseKeys = [...optionsToUseKeys, ...optionsLocal[key]];
        }
      });
    }

    if (!isLoading) {
      if (focusKeyNumber < optionsToUseKeys.length) {
        if (
          focusKeyNumber != null &&
          optionsToUseKeys[focusKeyNumber + 1] &&
          valuesSelected.some((val) => shallowEqual(val, optionsLocal[focusKeyNumber + 1]))
        ) {
          let keyNext = 2;
          while (
            valuesSelected.some((val) =>
              shallowEqual(val, optionsToUseKeys[focusKeyNumber + keyNext])
            )
          ) {
            keyNext++;
          }

          const nextKey = focusKeyNumber + keyNext;

          if (nextKey < optionsToUseKeys.length) {
            setFocusKeyNumber(nextKey);
            scrollToEl(false);
          }
        } else {
          if (focusKeyNumber == null) {
            let i = 0;

            while (isHidden(itemsEls.current[i])) {
              i++;
            }

            setFocusKeyNumber(i);
          } else {
            const nextKey = focusKeyNumber + 1;
            if (nextKey < optionsToUseKeys.length) {
              setFocusKeyNumber(focusKeyNumber + 1);
            }
          }

          scrollToEl(false);
        }
      }
    }
  };

  const setOption = (opt, key) => {
    if (maxSearchReached) {
      return false;
    }

    let maxValuesCount = 6;
    if (valuesSelectedMax[type]) {
      maxValuesCount = valuesSelectedMax[type];
    }

    if (key) {
      opt.key = key;
    }

    if (valuesSelected.length < maxValuesCount) {
      setValuesSelected([...valuesSelected, opt]);
      setNewValueText('');

      if (type === 'single') {
        setMenuIsOpen(false);
        setOptionsLocal([]);
      }
    }

    if ([...valuesSelected, opt].length === maxValuesCount) {
      setMenuIsOpen(false);
    }
  };

  useEffect(() => {
    if (!preloadOptions) {
      plusIndex = 0;

      if (type === 'single') {
        if (
          newValueText.length == 0 ||
          (newValueText.length < limitCharactersToSearch[type] && newValueText !== 'c2')
        ) {
          setShowNotFound(false);
          setIsLoading(false);
          return false;
        }
      } else {
        if (newValueText.length == 0 || newValueText.length < limitCharactersToSearch[type]) {
          setShowNotFound(false);
          setIsLoading(false);
          return false;
        }
      }

      setFocusKeyNumber(null);
      setOptionsLocal([]);
      setIsLoading(true);
      setShowNotFound(false);

      if (!menuIsOpen) {
        setMenuIsOpen(true);
      }

      const timeOutId = setTimeout(async () => {
        if (type !== 'single') {
          const loadMoreRequest = await getSearch({
            term: newValueText,
            model: type,
          });

          if (loadMoreRequest.ok) {
            if (loadMoreRequest.data) {
              if (!loadMoreRequest.data[type]) {
                setShowNotFound(true);
                setOptionsLocal([]);
                setFocusKeyNumber(null);
              } else {
                setOptionsLocal(loadMoreRequest.data[type]);
              }
            } else {
              setShowNotFound(true);
            }
            setIsLoading(false);
          }
        } else {
          const lowercaseTerm = newValueText.toLowerCase();
          const term = ['ato', 'atol', 'atoly', 'atolye'].includes(lowercaseTerm)
            ? lowercaseTerm.replace(/ato/gi, 'atö')
            : lowercaseTerm;

          const loadMoreRequest = await getSearchSingle({ term });
          if (loadMoreRequest.ok) {
            if (
              loadMoreRequest.data.client_companies.length == 0 &&
              loadMoreRequest.data.kyu_companies.length == 0 &&
              loadMoreRequest.data.kyu_skills.length == 0 &&
              loadMoreRequest.data.passions.length == 0 &&
              loadMoreRequest.data.industries.length == 0 &&
              loadMoreRequest.data.projects.length == 0 &&
              loadMoreRequest.data.people.length == 0
            ) {
              setShowNotFound(true);
            }

            setOptionsLocal(loadMoreRequest.data);
            setIsLoading(false);
          }
        }
      }, 750);

      return () => clearTimeout(timeOutId);
    } else {
      let optionsFiltered = [];
      if (newValueText.length > 0) {
        optionsFiltered = preloadedData.filter((opt) => {
          return opt.name.toLowerCase().indexOf(newValueText.toLowerCase()) !== -1;
        });
      } else {
        optionsFiltered = preloadedData;
      }

      if (optionsFiltered.length === 0) {
        setShowNotFound(true);
      } else {
        setShowNotFound(false);
      }

      setOptionsLocal(optionsFiltered);
    }
  }, [newValueText]);

  const handleKeyPress = (event) => {
    let maxValuesCount = 6;
    if (valuesSelectedMax[type]) {
      maxValuesCount = valuesSelectedMax[type];
    }

    if (valuesSelected.length === maxValuesCount && type !== 'single') {
      event.preventDefault();
      return false;
    }

    if (type === 'single' && valuesSelected.length > 0) {
      setValuesSelected([]);
      setOptionsLocal([]);
    }

    if (event.key === 'Tab' || event.keyCode == 9) {
      setFocusKeyNumber(null);
      setMenuIsOpen(false);
      setNewValueText('');
    }

    let optionsToUseKeys = optionsLocal;
    if (optionsLocal.constructor === Object && Object.keys(optionsLocal).length > 0) {
      optionsToUseKeys = [];
      Object.keys(optionsLocal).map(function (key, index) {
        if (key !== 'search_term' && optionsLocal[key].length > 0) {
          const options = optionsLocal[key];
          let optionsToSave = [];
          if (options.length > 0) {
            options.map((item) => {
              item.key = key;
              optionsToSave.push(item);
            });
          }
          optionsToUseKeys = [...optionsToUseKeys, ...optionsToSave];
        }
      });
    }

    if (event.key === 'Enter' || event.keyCode == 13) {
      event.preventDefault();
      if (type === 'single' && valuesSelected.length === 1) {
        return onSubmit();
      }

      if (focusKeyNumber == null) {
      } else {
        if (!valuesSelected.some((val) => shallowEqual(val, optionsToUseKeys[focusKeyNumber]))) {
          setOption(optionsToUseKeys[focusKeyNumber], optionsToUseKeys[focusKeyNumber].key);

          if (type === 'single') {
            setNewValueText(optionsToUseKeys[focusKeyNumber].name);
          }
        }
        setNextKey();
      }
    }

    if (event.keyCode == 38) {
      if (!isLoading) {
        if (focusKeyNumber > 0) {
          if (
            optionsToUseKeys[focusKeyNumber - 1] &&
            valuesSelected.some((val) => shallowEqual(val, optionsToUseKeys[focusKeyNumber - 1]))
          ) {
            let keyPrev = -2;
            while (
              valuesSelected.some((val) =>
                shallowEqual(val, optionsToUseKeys[focusKeyNumber + keyPrev])
              )
            ) {
              keyPrev--;
            }

            scrollToEl(true);
            setFocusKeyNumber(focusKeyNumber + keyPrev);
          } else {
            scrollToEl(true);
            setFocusKeyNumber(focusKeyNumber - 1);
          }
        }
      }
    }

    if (event.keyCode == 40) {
      setNextKey();
    }

    if (event.keyCode == 27) {
      setFocusKeyNumber(null);
      setMenuIsOpen(false);
      setNewValueText('');
    }
  };

  const renderNotFoundMessage = () => {
    switch (type) {
      case 'passions':
        return `'${newValueText}' isn’t coming up with any results. Try another passion?`;
      case 'kyu_skills':
        return `'${newValueText}' isn’t coming up with any results. Try another skill?`;
      default:
        return `${newValueText} not found `;
    }
  };

  const focusOnInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const removeItem = (opt) => {
    const valuesToSave = valuesSelected.filter((item) => item.name != opt.name);
    setValuesSelected(valuesToSave);
  };

  return (
    <SelectWrapper className="search-container" type={type}>
      <CustomSelectWrapper ref={selectRef}>
        <InputWrapper
          enabled={valuesSelected.length > 0}
          isSingleSearch={type === 'single'}
          isOpen={menuIsOpen}
          onClick={focusOnInput}
        >
          {valuesSelected.length > 0 &&
            type !== 'single' &&
            valuesSelected.map((item, key) => (
              <PillItem
                isNew={!item.uuid || item.needs_admin_approval}
                key={key}
                pillColor={pillColor}
              >
                <p className="react-select__multi-value__label">{item.name}</p>
                <button type="button" onClick={() => removeItem(item)}>
                  <CloseIconSmall />
                </button>
              </PillItem>
            ))}

          {!maxSearchReached && type !== 'single' && (
            <input
              type="text"
              ref={inputRef}
              placeholder={placeholder}
              onFocus={() => {
                setMenuIsOpen(true);
              }}
              className="search-multiple-input"
              onKeyDown={handleKeyPress}
              value={newValueText}
              onChange={handleChange}
            />
          )}

          {type === 'single' && (
            <>
              <input
                className="search-multiple-input"
                onChange={handleChange}
                onFocus={() => {
                  setMenuIsOpen(true);
                }}
                onKeyDown={handleKeyPress}
                placeholder={placeholder}
                ref={inputRef}
                type="text"
                value={newValueText}
              />

              {valuesSelected.length > 0 && (
                <ButtonRemoveOption
                  onClick={() => {
                    setValuesSelected([]);
                    setNewValueText('');
                  }}
                >
                  <CloseIcon />
                </ButtonRemoveOption>
              )}

              <ButtonSearch
                disabled={valuesSelected.length === 0}
                onClick={onSubmit}
                enabled={valuesSelected.length > 0}
              >
                <SearchIconSmall />
              </ButtonSearch>
            </>
          )}
        </InputWrapper>
        {rightElement && <RightElementWrapper>{rightElement}</RightElementWrapper>}

        {menuIsOpen && type !== 'single' && valuesSelected.length < 6 && (
          <CustomSelect>
            <OptionsWrapper ref={optionsWrapperRef}>
              {optionsLocal.length > 0 &&
                optionsLocal.map((opt, key) => (
                  <Option
                    isFocus={focusKeyNumber === key}
                    isSelected={valuesSelected.some((val) => shallowEqual(val, opt))}
                    key={key}
                    onClick={() => setOption(opt, '')}
                    ref={(element) => (itemsEls.current[key] = element)}
                    onMouseOver={() => setFocusKeyNumber(key)}
                    type="button"
                  >
                    {opt.name}
                  </Option>
                ))}
              {isLoading && <OptionLoading>Loading...</OptionLoading>}

              {valuesSelected.length > 0 &&
                valuesSelected.length == optionsLocal.length &&
                !preloadOptions && <OptionLoading>Type to search</OptionLoading>}

              {optionsLocal.length == 0 && !isLoading && !preloadOptions && (
                <OptionLoading>Type to search</OptionLoading>
              )}

              {showNotFound && !isLoading && <OptionCreate>{renderNotFoundMessage()}</OptionCreate>}
            </OptionsWrapper>
          </CustomSelect>
        )}

        {menuIsOpen && type === 'single' && valuesSelected.length < 1 && (
          <SearchBlock ref={optionsWrapperRef} isSingleSearch={true}>
            {isLoading && <OptionLoading>Loading...</OptionLoading>}

            {optionsLocal.length == 0 && !isLoading && (
              <OptionLoading>Type to search</OptionLoading>
            )}

            {showNotFound && (
              <OptionCreate style={{ padding: 0 }}>{newValueText} not found</OptionCreate>
            )}

            {Object.keys(optionsLocal).length > 0
              ? Object.keys(optionsLocal).map((key) => {
                  if (key !== 'search_term' && optionsLocal[key].length > 0) {
                    plusIndex = plusIndex + optionsLocal[key].length;
                    return (
                      <SearchResultBlockType
                        countToAdd={plusIndex - optionsLocal[key].length}
                        focusKeyNumber={focusKeyNumber}
                        key={key}
                        keyType={key}
                        onSelect={(opt) => {
                          setOption(opt, key);
                          setMenuIsOpen(false);
                          setNewValueText(opt.name);
                        }}
                        refsChildren={itemsEls}
                        results={optionsLocal}
                      />
                    );
                  }
                })
              : ''}
          </SearchBlock>
        )}
      </CustomSelectWrapper>
    </SelectWrapper>
  );
};

export default SearchMultiple;
