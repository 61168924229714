import React from 'react';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getSearchSingle } from 'services/app';

export default function useSearch(
  term: string,
  options?: Omit<
    UseQueryOptions<Array<ISearchResponse>, Error, Array<ISearchResponse>, QueryKey>,
    'queryKey'
  >
) {
  return useQuery<Array<ISearchResponse>, Error>(
    `search-${term}`,
    async () => {
      const response = await getSearchSingle({ term });
      if (!response.ok) {
        throw new Error(response.originalError?.message);
      }

      return response.data;
    },
    {
      ...options,
      cacheTime: 600000,
    }
  );
}

export interface ISearchResponse {
  uuid: string;
  slug: string;
  name: string;
  info?: string;
  search_ranking?: number;
  datatype: string;
}
