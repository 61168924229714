import styled from 'styled-components';

export const AboutKyuOsWayfindingArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & [data-id='article-published-date'] {
    display: none;
  }
`;
