import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getPassionDrawerData } from 'services/app';

export default function usePassionDrawerData(
  passionSlug: string,
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `passion-${passionSlug}-drawer-data`,
    () =>
      getPassionDrawerData(passionSlug).then((response) => {
        if (!response.ok) {
          throw new Error(response.originalError.message);
        }
        return response.data;
      }),
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
