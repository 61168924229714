import React, { useState } from "react";
import {
  ResultBlock,
  ResultType,
  ResultList,
  ButtonOption,
  LabelContainer,
  LabelRow,
  NameSpan,
  InfoSpan,
} from "./styled";

type SearchResultBlockType = {
  results?: any;
  onSelect?: any;
  countToAdd: number;
  refsChildren?: any;
  keyType: string;
  focusKeyNumber?: any;
};

const SearchComp = ({
  results,
  keyType,
  onSelect,
  refsChildren,
  countToAdd,
  focusKeyNumber,
}: SearchResultBlockType) => {
  const [countListShow, setCountListShow] = useState(3);

  const keys_labels = {
    people: "People",
    companies: "Clients",
    client_companies: "Clients",
    kyu_companies: "Companies",
    kyu_skills: "Skills",
    passions: "Passions",
    industries: "Industries",
    projects: "Projects",
    communities: "Communities",
  };

  return (
    <ResultBlock key={keyType}>
      <ResultType type={keyType}>
        {keys_labels[keyType]} ({results[keyType].length})
      </ResultType>
      <ResultList>
        {results[keyType].slice(0, countListShow).map((value, key) => (
          <ButtonOption
            isFocus={focusKeyNumber === countToAdd + key}
            ref={(element) =>
              (refsChildren.current[countToAdd + key] = element)
            }
            key={value.uuid}
            onClick={() => onSelect(value)}
            type="button"
          >
            <LabelContainer>
              <LabelRow>
                <NameSpan> {value.name} </NameSpan>
                {"info" in value ? (
                  <InfoSpan className="search-result-info">
                    {value.info}
                  </InfoSpan>
                ) : null}
              </LabelRow>
            </LabelContainer>
          </ButtonOption>
        ))}

        {results[keyType].length > 3 && countListShow !== results.length && (
          <button
            type="button"
            className="show-more"
            onClick={() => setCountListShow(results.length)}
          >
            More
          </button>
        )}
      </ResultList>
    </ResultBlock>
  );
};

export default SearchComp;
