import Cookies from 'universal-cookie';


/* eslint-disable no-undef */
export const setToken = (token, expiresIn = 14400) => { 
  const cookies = new Cookies();
  var today = new Date();
  today.setSeconds(today.getSeconds() + expiresIn);
  
  cookies.set('auth-token', token, { expires: today, path: '/' }) 
};

export const setSignUpToken = (token, expiresIn = 1800) => {
  const cookies = new Cookies();
  const today = new Date();
  today.setSeconds(today.getSeconds() + expiresIn);

  cookies.set('sign-up-token', token, { expires: today, path: '/auth' });
}

export const getToken = () => {
  const cookies = new Cookies();
  const JWT = cookies.get('auth-token');
  return JWT
}

export const getSignUpToken = () => {
  const cookies = new Cookies();
  const token = cookies.get('sign-up-token');
  return token;
}

export const clearSignUpToken = () => {
  const cookies = new Cookies();
  cookies.remove('sign-up-token');
}

export const clearToken = () => {
  const cookies = new Cookies();
  cookies.remove('auth-token');
};