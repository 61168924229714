import styled from "styled-components";

interface ClientsDrawerContainerProps {
  showingClientDrawer?: boolean;
}

export const ClientsDrawerContainer = styled.div<ClientsDrawerContainerProps>`
  height: 100%;
  padding: ${({ showingClientDrawer }) => showingClientDrawer ? 0 : 32}px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 17.49%, rgba(150, 215, 217, 0.3) 100%), #FFFFFF;
`;

export const ClientsDrawerFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 32px;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  border-bottom: 1px solid rgba(150, 215, 217, 0.4);
  backdrop-filter: blur(100px);
`;

export const ClientsDrawerFiltersOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

interface ClientDrawerFilterOptionProps {
  selected?: boolean;
}

export const ClientDrawerFilterOption = styled.div<ClientDrawerFilterOptionProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ selected }) => (selected ? "#104D5B" : "#96D7D9")};

  cursor: pointer;
`;

interface ClientsDrawerListContainerProps {
  height: number;
}

export const ClientsDrawerListContainer = styled.div<ClientsDrawerListContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px 110px 0px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  overflow-y: auto;
  height: calc(100% - ${({ height }) => height}px);
`;

export const ClientDrawerPillContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const ClientDrawerPill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  & > :not(:last-child) {
    margin-right: 5px;
  }
  background: rgba(16, 77, 91, 0.3);
  border-radius: 26px;
  cursor: pointer;
`;

export const ClientDrawerText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #104D5B;
`;

export const ClientsDrawerFiltersTitle = styled.h3`
  font-weight: 300;
  font-size: 32px;
  line-height: 42px;
  color: #171C33;
`;

export const ClientsDrawerFiltersDescription = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #171C33;
`;