import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getKyuTeam } from 'services/app';

export default function useKyuExecutiveCommittee(
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    [`kyu-team-executive-committee`],
    () =>
      getKyuTeam /*  */().then((response) => {
        if (!response.ok) {
          throw new Error(response.originalError?.message);
        }

        const people = response.data.executive_committee.map((person) => ({
          ...person,
          role_name: person.title || person.role,
          profile_image: person.avatar,
        }));

        return { people };
      }),
    {
      ...options,
      cacheTime: 600000,
      retry: false,
      staleTime: 0,
    }
  );
}
