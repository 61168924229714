import styled from "styled-components";

interface PassionsDrawerContainerProps {
  showingPassionDrawer?: boolean;
}

export const PassionsDrawerContainer = styled.div<PassionsDrawerContainerProps>`
  height: 100%;
  padding: ${({ showingPassionDrawer }) => showingPassionDrawer ? 0 : 32}px;
  background: linear-gradient(180deg, rgba(249, 237, 234, 0.3) 0.01%, rgba(255, 255, 255, 0.3) 17.21%, rgba(251, 175, 161, 0.3) 99.99%, rgba(251, 175, 161, 0.12) 100%), #FFFFFF;
`;

export const PassionsDrawerFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 32px;
  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  border-bottom: 1px solid rgba(212, 117, 99, 0.3);
  backdrop-filter: blur(100px);
`;

export const PassionsDrawerFiltersOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

interface PassionDrawerFilterOptionProps {
  selected?: boolean;
}

export const PassionDrawerFilterOption = styled.div<PassionDrawerFilterOptionProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ selected }) => (selected ? "#D47563" : "#FBAFA1")};

  cursor: pointer;
`;

export const PassionsDrawerListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px 110px 0px;
  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  height: 100%;
  overflow-y: auto;
`;

export const PassionDrawerPill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;

  & > :not(:last-child) {
    margin-right: 5px;
  }

  background: rgba(212, 117, 99, 0.3);
  border-radius: 26px;

  position: relative;
  cursor: pointer;
`;

export const PassionDrawerText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #D47563;
`;

export const PassionsDrawerFiltersTitle = styled.h3`
  font-weight: 300;
  font-size: 32px;
  line-height: 42px;
  color: #171C33;
`;

export const PassionsDrawerFiltersDescription = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #171C33;
`;

export const PassionUserAvatar = styled.img`
  width: 22px;
  height: 22px;
  object-fit: cover;
  border: 2px solid #FFC229;
  border-radius: 1000px;

  position: absolute;
  right: -30px;
`;