import React from 'react';

export default function ThumbsUpIcon({ width = 25, height = 26, fill = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5058 2.1606C10.673 1.78442 11.046 1.54199 11.4577 1.54199C12.5628 1.54199 13.6226 1.98098 14.404 2.76238C15.1854 3.54378 15.6243 4.60359 15.6243 5.70866V8.83366H20.4732C20.924 8.82949 21.3704 8.9229 21.7817 9.1075C22.195 9.29299 22.563 9.56612 22.8603 9.90797C23.1575 10.2498 23.3769 10.6522 23.5032 11.0872C23.6295 11.5223 23.6597 11.9796 23.5917 12.4275L22.1542 21.8023C22.1542 21.8026 22.1543 21.8021 22.1542 21.8023C22.0411 22.5472 21.6625 23.2269 21.0885 23.7149C20.5158 24.2016 19.787 24.4656 19.0356 24.4587H4.16602C3.33721 24.4587 2.54236 24.1294 1.95631 23.5434C1.37026 22.9573 1.04102 22.1625 1.04102 21.3337V14.042C1.04102 13.2132 1.37026 12.4183 1.95631 11.8323C2.54236 11.2462 3.33721 10.917 4.16602 10.917H6.61406L10.5058 2.1606ZM8.33268 12.1797L12.0908 3.72387C12.4048 3.82402 12.6935 3.99818 12.9308 4.23552C13.3215 4.62622 13.541 5.15612 13.541 5.70866V9.87533C13.541 10.4506 14.0074 10.917 14.5827 10.917H20.4785L20.4903 10.9169C20.6413 10.9152 20.7909 10.9464 20.9286 11.0082C21.0664 11.07 21.1891 11.1611 21.2882 11.275C21.3872 11.389 21.4604 11.5231 21.5025 11.6681C21.5445 11.813 21.5546 11.9653 21.532 12.1144C21.532 12.1146 21.5321 12.1143 21.532 12.1144L20.0945 21.49C20.0568 21.7384 19.9306 21.9648 19.7392 22.1275C19.5478 22.2902 19.304 22.3782 19.0528 22.3754L8.33268 22.3753V12.1797ZM6.24935 22.3753V13.0003H4.16602C3.88975 13.0003 3.6248 13.1101 3.42945 13.3054C3.2341 13.5008 3.12435 13.7657 3.12435 14.042V21.3337C3.12435 21.6099 3.2341 21.8749 3.42945 22.0702C3.6248 22.2656 3.88975 22.3753 4.16602 22.3753H6.24935Z"
        fill={fill}
      />
    </svg>
  );
}
