import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import ProfileEditInput from 'app/components/CommonStyled/ProfileEditInput';
import { CloseIcon } from 'app/components/shared/icons';
import AlarmClock from 'app/components/shared/icons/alarm-clock';
import { Form, Formik, FormikProps } from 'formik';
import { ICollectionParams, postClients } from 'services/profile-editor';
import { setAllClients, setClients } from 'store/reducers/profile-editor/profile-editor-reducer';
import * as Yup from 'yup';

import useProfessionalPriorExperienceDrawer from '../../hooks/useProfessionalPriorExperienceDrawer';
import ProfileEditorDrawer, { ProfileEditorDrawerRefProps } from '../Drawer';
import { ProfileEditorDrawerTitle } from '../styled';
import ProfileEditorDrawerClientsNewClient from './NewClient';
import {
  ProfileEditorDrawerExperienceNeedsApproval,
  ProfileEditorDrawerExperiencePill,
  ProfileEditorDrawerExperiencePillIconContainer,
  ProfileEditorDrawerExperiencePillText,
  ProfileEditorDrawerExperiencePillTextContainer,
  ProfileEditorDrawerExperiencePillsContainer,
  ProfileEditorDrawerExperienceWrapper,
} from './styled';

interface ProfileEditorDrawerClientsProps {
  onClose: () => void;
  companyName?: string;
}

export default function ProfileEditorDrawerClients({
  onClose,
  companyName,
}: Readonly<ProfileEditorDrawerClientsProps>) {
  const dispatch = useDispatch();
  const [showNewClientForm, setShowNewClientForm] = useState<boolean>(false);
  const [currentNewOption, setCurrentNewOption] = useState<any>(null);
  const { clients, allClients } = useProfessionalPriorExperienceDrawer();
  const [clientsCreated, setClientsCreated] = useState<any[]>([]);
  const formikRef = useRef<FormikProps<any>>(null);
  const [optionsPicked, setOptionsPicked] = useState<any[]>([]);
  const drawerRef = useRef<ProfileEditorDrawerRefProps>(null);

  useEffect(() => {
    setOptionsPicked(clients.map((client) => ({ ...client, label: client.name })));
    formikRef.current.setFieldValue(
      'collection',
      clients.map((client) => client.uuid)
    );
  }, [clients]);

  const options = useMemo(() => {
    return allClients.map((client) => ({
      ...client,
      label: client.name,
      value: client.uuid,
    }));
  }, [allClients]);

  //Set options picked for select
  useEffect(() => {
    if (clients && clients.length > 0) {
      setOptionsPicked(
        clients.map((client) => ({
          ...client,
          value: client.uuid,
          label: client.name,
          approved: client.approved,
        }))
      );
    }
  }, [clients]);

  //Create clients
  const handlePostClients = async (params: ICollectionParams) => {
    const response = await postClients({ collection: optionsPicked.map((option) => option.value) });
    if (!response.ok) {
      throw new Error(response.originalError?.message);
    }

    const newlyCreatedClients = optionsPicked.filter((option) => option.isCreated);
    const allAvailableClients = [...allClients, ...newlyCreatedClients];
    dispatch(setAllClients(allAvailableClients));
    dispatch(setClients(optionsPicked));
    return response.data;
  };

  const { mutate } = useMutation(handlePostClients, drawerRef.current?.handleMutation);

  //Validation
  const clientsValidationSchema = Yup.object().shape({
    collection: Yup.array(),
  });

  //Options changed
  const handleOptionsChanged = (options: any[]) => {
    let newlyCreatedOptions = options.reduce((arr, option) => {
      if (option.isCreated) {
        arr.push({ ...option, approved: false });
      }
      return arr;
    }, []);
    newlyCreatedOptions = [...newlyCreatedOptions, ...clientsCreated];
    setClientsCreated(newlyCreatedOptions);
    const alreadyCreatedOptions = options.filter((option) => !option.isCreated);
    const allOptions = [...alreadyCreatedOptions, ...newlyCreatedOptions];
    const uniqOptions = allOptions.filter(
      (option, index, self) => index === self.findIndex((t) => t.value === option.value)
    );

    const optionsToSave = uniqOptions.map((client) => {
      const existingClient = clients.find((clientItem) => clientItem.uuid === client.value);

      if (existingClient) {
        return {
          ...existingClient,
          ...client,
        };
      }
      return { ...client, name: client.label };
    });

    setOptionsPicked(optionsToSave);
  };

  const handleNewOptionPicked = (option: { value: any; label: string; isCreated: boolean }) => {
    setCurrentNewOption(option);
    setShowNewClientForm(true);
  };

  const handleCurrentOptionDeleted = () => {
    setCurrentNewOption(null);
    setShowNewClientForm(false);
  };

  const handleNewClientSubmitted = (client) => {
    setShowNewClientForm(false);
    const finalOptions = [
      ...optionsPicked,
      {
        ...client,
        value: client.uuid,
        isCreated: true,
        label: currentNewOption.label,
        approved: false,
      },
    ];

    setOptionsPicked(finalOptions);
    formikRef.current.setFieldTouched('collection', true);
  };

  //Submit
  const handleSubmit = (values) => {
    mutate({ collection: values.collection });
  };

  //Remove behavior
  const handleRemoveClient = (client) => {
    const filteredOptions = optionsPicked.filter((option) => option.value !== client.value);
    if (client.isCreated) {
      setClientsCreated(clientsCreated.filter((option) => option.value !== client.value));
    }
    formikRef.current.setFieldValue(
      'collection',
      filteredOptions.map((option) => option.value)
    );
  };

  return (
    <Formik
      initialValues={{
        collection: clients.map((client) => client.uuid),
      }}
      validationSchema={clientsValidationSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {(props) => (
        <Form>
          <ProfileEditorDrawer
            ref={drawerRef}
            header={<ProfileEditorDrawerClientsHeader />}
            needsSaving={props.dirty || showNewClientForm || !!props.touched['collection']}
            disableSubmit={showNewClientForm}
            onClose={onClose}
          >
            <ProfileEditorDrawerExperienceWrapper>
              <ProfileEditInput
                {...props}
                name={'collection'}
                label={`Before ${companyName}, which clients have you worked with?`}
                type={'select'}
                placeholder={'Search for clients...'}
                tooltip={
                  "You can search and select clients from the dropdown menu. If you can’t find the client you’re looking for, you can create a client and add it to kyu OS. New clients are reviewed for typos and similarities by the Creative Intelligence team and they'll display on your profile once approved."
                }
                options={options}
                onOptionsChanged={handleOptionsChanged}
                onNewOptionPicked={handleNewOptionPicked}
                onCurrenOptionDeleted={handleCurrentOptionDeleted}
                settings={{
                  allowCreate: true,
                }}
                keepValueOnChange
              />
              {showNewClientForm ? (
                <ProfileEditorDrawerClientsNewClient
                  onNewClientSubmitted={handleNewClientSubmitted}
                />
              ) : null}
              <ProfileEditorDrawerExperiencePillsContainer>
                {optionsPicked &&
                  optionsPicked.length > 0 &&
                  optionsPicked.map((option, index) => (
                    <ProfileEditorDrawerExperiencePill key={option.value}>
                      <ProfileEditorDrawerExperiencePillTextContainer>
                        <ProfileEditorDrawerExperiencePillText>
                          {option.label}
                        </ProfileEditorDrawerExperiencePillText>
                        {option.approved == false && (
                          <ProfileEditorDrawerExperienceNeedsApproval>
                            <AlarmClock width={14} height={14} fill="#A26565" />
                            <span>Pending Review</span>
                          </ProfileEditorDrawerExperienceNeedsApproval>
                        )}
                      </ProfileEditorDrawerExperiencePillTextContainer>
                      <ProfileEditorDrawerExperiencePillIconContainer
                        onClick={() => handleRemoveClient(option)}
                      >
                        <CloseIcon width={9} height={9} fill={'var(--color-primary)'} />
                      </ProfileEditorDrawerExperiencePillIconContainer>
                    </ProfileEditorDrawerExperiencePill>
                  ))}
              </ProfileEditorDrawerExperiencePillsContainer>
            </ProfileEditorDrawerExperienceWrapper>
          </ProfileEditorDrawer>
        </Form>
      )}
    </Formik>
  );
}

function ProfileEditorDrawerClientsHeader() {
  return <ProfileEditorDrawerTitle>Previous Clients</ProfileEditorDrawerTitle>;
}
