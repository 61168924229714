import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getClientsDrawerList } from 'services/app';

export default function useClientsDrawerList(
  companySlug: string,
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `company-${companySlug}-clients-drawer-list`,
    () => getClientsDrawerList(companySlug).then((response) => response.data),
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
