import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsWayfindingActivateProfileMenuWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 156px);
  grid-column-gap: 8px;
  grid-row-gap: 16px;

  form {
    display: none;
  }

  ${BREAKPOINTS.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

//CARD
export const AboutKyuOsWayfindingActivateProfileMenuCard = styled.div`
  width: 156px;
  height: 156px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);

  cursor: pointer;
`;

export const AboutKyuOsWayfindingActivateProfileMenuCardTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;

  color: var(--color-text);
`;

export const AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer = styled.div`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background-color: #f2f2f2;
`;
