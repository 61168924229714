import { IPassionDetailsAtAGlanceResponse, IPassionDetailsCommunitiesResponse, IPassionDetailsConveningsResponse, IPassionDetailsMapSnippetResponse, IPassionDetailsMeetSomeoneNewResponse, IPassionDetailsPeopleDrawerParams, IPassionDetailsPeopleDrawerResponse, IPassionDetailsPeopleFiltersResponse, IPassionDetailsPeopleParams, IPassionDetailsPeopleResponse, IPassionDetailsResponse, IPassionDetailsSimilarPassionsResponse } from "app/components/DetailsPages/interfaces/passion"
import { http } from "./http"

export const getPassionDetails = (slug: string) => {
  return http.get<IPassionDetailsResponse>(`passion/${slug}/details`)
}

export const getPassionAtAGlance = (slug: string) => {
  return http.get<IPassionDetailsAtAGlanceResponse>(`passion/${slug}/details/at_a_glance`);
}

export const getPassionMapSnippet = (slug: string) => {
  return http.get<IPassionDetailsMapSnippetResponse>(`passion/${slug}/details/map_snippet`);
}

export const getSimiliarPassions = (slug: string) => {
  return http.get<IPassionDetailsSimilarPassionsResponse>(`passion/${slug}/details/similar_passions`);
}

export const getPassionConvenings = (slug: string) => {
  return http.get<IPassionDetailsConveningsResponse>(`passion/${slug}/details/convenings`);
}

export const getPassionCommunities = (slug: string) => {
  return http.get<IPassionDetailsCommunitiesResponse>(`passion/${slug}/details/communities`);
}

export const getPassionPeople = (slug: string, params?: IPassionDetailsPeopleParams) => {
  return http.get<IPassionDetailsPeopleResponse>(`passion/${slug}/details/people`, params);
}

export const getPassionPeopleFilters = (slug: string) => {
  return http.get<IPassionDetailsPeopleFiltersResponse>(`passion/${slug}/details/people_filters`);
}

export const getPassionMeetSomeoneNew = (slug: string) => {
  return http.get<IPassionDetailsMeetSomeoneNewResponse>(`passion/${slug}/details/meet_someone_new`);
}

export const getPassionPeopleDrawer = (slug: string, params?: IPassionDetailsPeopleDrawerParams) => {
  return http.get<IPassionDetailsPeopleDrawerResponse>(`passion/${slug}/drawers/people`, params); 
}