import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import {
  getBio,
  getCountries,
  getLanguages,
  getPassions,
  getSkills,
} from 'services/profile-editor';

export function useProfessionalAtAGlance(
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `profile-editor-professional-at-a-glance`,
    async () => {
      const [skills] = await Promise.all([getSkills()]);

      return {
        skills: skills.data.skills || [],
        skills_inspo: skills.data.inspo || {},
        allSkills: skills.data.all || [],
      };
    },
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
