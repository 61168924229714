import React from 'react';

export default function CollapsibleClosedIcon({ width = 60, height = 60, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4962 19.9602C31.4925 19.3232 30.9773 18.8076 30.3404 18.8035C29.6924 18.7994 29.166 19.3254 29.1697 19.9734L29.2204 28.9026L20.2611 28.8484C19.6132 28.8444 19.0869 29.3707 19.0909 30.0186C19.0947 30.6556 19.6101 31.171 20.2471 31.1748L29.2336 31.2292L29.2815 39.6777C29.2851 40.3147 29.8003 40.8303 30.4373 40.8344C31.0852 40.8385 31.6117 40.3125 31.608 39.6645L31.5602 31.2433L40.1936 31.2956C40.8416 31.2995 41.3678 30.7732 41.3639 30.1253C41.36 29.4884 40.8446 28.973 40.2077 28.9691L31.547 28.9167L31.4962 19.9602Z"
        fill={fill}
      />
    </svg>
  );
}
