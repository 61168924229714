import React, { useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';

import {
  ComponentErrorContainer,
  ComponentErrorOverlay,
  ComponentErrorOverlayButton,
  ComponentErrorOverlayTitle,
  ComponentErrorWrapper,
} from './styled';

interface ComponentErrorProps {
  query?: UseQueryResult<any | unknown, Error>;
  skeleton?: React.ReactElement;
  errorMessage?: string;
  afterRefresh?: () => void;
}

export default function ComponentError({
  query = null,
  skeleton = <></>,
  errorMessage = 'Oh no! Something got left behind!',
  afterRefresh = () => {},
}: ComponentErrorProps) {
  const [triggerAnimation, setTriggerAnimation] = useState<boolean>(false);
  const [isBlurred, setIsBlurred] = useState<boolean>(true);

  useEffect(() => {
    if (triggerAnimation) {
      setTimeout(() => {
        setIsBlurred(false);
      }, 300);
    }
  }, [triggerAnimation]);

  const onRefreshClick = () => {
    setTriggerAnimation(true);
    query.refetch();

    if (afterRefresh) afterRefresh();
  };

  return (
    <ComponentErrorWrapper>
      {!triggerAnimation ? (
        <ComponentErrorOverlay>
          <ComponentErrorOverlayTitle>{errorMessage}</ComponentErrorOverlayTitle>
          {query !== null ? (
            <>
              <ComponentErrorOverlayTitle>Check back later or</ComponentErrorOverlayTitle>
              <ComponentErrorOverlayButton onClick={onRefreshClick}>
                Refresh this section
              </ComponentErrorOverlayButton>
            </>
          ) : null}
        </ComponentErrorOverlay>
      ) : null}
      <ComponentErrorContainer isBlurred={isBlurred} triggerAnimation={triggerAnimation}>
        {skeleton}
      </ComponentErrorContainer>
    </ComponentErrorWrapper>
  );
}
