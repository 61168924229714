import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserAvatar from 'app/components/CommonStyled/UserAvatar';
import useOnClickOutside from 'hooks/click-outside';
import useUserData from 'hooks/useUserData';
import { isUserDropdownOpenSelector, setIsUserDropdownOpen } from 'store/reducers/navbar-reducer';

import MenuButtons from '../MenuButtons';
import { SignOutButton } from '../styled';
import { handleSignOut } from '../utils';
import {
  NavbarUserDropdownCTAWrapper,
  NavbarUserDropdownInformationContainer,
  NavbarUserDropdownInformationName,
  NavbarUserDropdownInformationCompany,
  NavbarUserDropdownInformationWrapper,
  NavbarUserDropdownWrapper,
} from './styled';

export default function NavbarUserDropdown() {
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const userData = useUserData();

  useOnClickOutside(wrapperRef, (e) => {
    const button = document.querySelector('#nav-user-dropdown-cta');
    if (button && button.contains(e.target as Node)) return;

    dispatch(setIsUserDropdownOpen(false));
  });

  const handleSignOutClick = () => {
    handleSignOut();
  };

  return (
    <NavbarUserDropdownWrapper ref={wrapperRef}>
      <NavbarUserDropdownInformationWrapper>
        <UserAvatar size={38} />
        <NavbarUserDropdownInformationContainer>
          <NavbarUserDropdownInformationName>
            {userData.first_name} {userData.last_name}
          </NavbarUserDropdownInformationName>
          <NavbarUserDropdownInformationCompany>
            {userData.kyu_company.name}
          </NavbarUserDropdownInformationCompany>
        </NavbarUserDropdownInformationContainer>
      </NavbarUserDropdownInformationWrapper>
      <MenuButtons />
      <SignOutButton onClick={handleSignOutClick}>Sign out</SignOutButton>
    </NavbarUserDropdownWrapper>
  );
}

NavbarUserDropdown.CTA = function NavbarUserDropdownCTAComponent() {
  const dispatch = useDispatch();
  const isUserDropdownOpen = useSelector(isUserDropdownOpenSelector);

  const handleUserAvatarClick = () => {
    dispatch(setIsUserDropdownOpen(!isUserDropdownOpen));
  };

  return (
    <NavbarUserDropdownCTAWrapper onClick={handleUserAvatarClick} id="nav-user-dropdown-cta">
      <UserAvatar size={44} />
    </NavbarUserDropdownCTAWrapper>
  );
};
