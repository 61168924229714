import styled from "styled-components/macro";
import mq, { BREAKPOINTS } from "styles/media";

type BreadcrumbWrapperProp = {
  isMapPage?: boolean;
  isNavBarOpen?: boolean;
  mode: "light" | "dark";
};

type Colors = {
  active: { light: string; dark: string };
  normal: { light: string; dark: string };
};

const colors: Colors = {
  active: {
    light: "#fff",
    dark: "#000",
  },
  normal: {
    light: "rgba(255, 255, 255, 0.5)",
    dark: "rgba(31, 32, 37, 0.5)",
  },
};

export const BreadcrumbWrapper = styled.section<BreadcrumbWrapperProp>`
  left: 0;
  padding: 32px 0 0 32px;
  position: fixed;
  top: 84px;
  transition: all 0.3s ease;
  z-index: 9999;
  svg {
    margin-left: 15px;
  }
  ${({ isMapPage, isNavBarOpen }) =>
    isMapPage &&
    `
    position: absolute;
    max-width: 100%;
    top: ${isNavBarOpen ? "84px" : "0"};
  `}
  ${({ mode }) =>
    `
    > svg {
      path {
        fill: ${colors.normal[mode]};
      }
    }
  `}
  ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

type BreadcrumbItemProp = {
  isCurrentPage?: boolean;
  mode: "light" | "dark";
};

export const BreadcrumbItem = styled.button<BreadcrumbItemProp>`
  ${({ isCurrentPage, mode }) =>
    isCurrentPage
      ? `
  color:  ${colors.active[mode]};
  `
      : `
  color:  ${colors.normal[mode]};
  `}
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 28px;
  margin-left: 15px;
  text-align: center;
  &:first-of-type {
    margin-left: 0;
    svg {
      margin-left: 0px;
    }
  }
`;
