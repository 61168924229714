import { motion } from 'framer-motion';
import styled from 'styled-components';
import { slideFromTopAnimation, slideToTopAnimation } from 'styles/animations';
import { BREAKPOINTS } from 'styles/media';

export const DetailsPageNavBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 32px !important;

  position: sticky;
  top: 64px;

  z-index: 99;

  ${({ theme }) =>
    !theme.isNavVisible &&
    `
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0px 20px 0px rgba(23, 28, 51, 0.25);

    &:before {
      content: '';

      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;

      backdrop-filter: blur(24px);
    }
  `}

  & > :not(:last-child) {
    margin-right: 24px;
  }

  ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

export const DetailsPageNavBarWrapperSkeleton = styled(DetailsPageNavBarWrapper)`
  min-height: 54px;

  background: none;
  box-shadow: none;

  &:before {
    content: none;
  }
`;

export const DetailsPageNavBarLogo = styled.div`
  & > img {
    width: 48px;
    height: 48px;
    border: 2px solid #5478a1;
    border-radius: 1000px;
    background-color: #ffffff;
  }
`;

interface DetailsPageNavBarItemProps {
  isVisible?: boolean;
  model?: string;
  showMore?: boolean;
}

export const DetailsPageNavBarItem = styled.span<DetailsPageNavBarItemProps>`
  display: flex;
  align-items: center;

  gap: 13px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 1.6px;
  text-transform: uppercase;

  padding: 8px 0px 16px 0px;

  &[data-overflowing='true'] {
    visibility: hidden;
  }

  &[data-dropdown-item='true'] {
    color: var(--color-primary);
  }

  color: ${({ isVisible, model, theme }) => {
    if (!theme.isNavVisible) {
      return isVisible ? '#171C33' : 'rgba(31, 32, 37, 0.5)';
    }

    return isVisible
      ? ['project'].includes(model)
        ? '#FFFFFF'
        : '#171C33'
      : ['project'].includes(model)
      ? 'rgba(255, 255, 255, 0.5)'
      : 'rgba(31, 32, 37, 0.5)';
  }};

  ${({ isVisible, model, theme }) =>
    isVisible &&
    `border-bottom: 2px solid ${
      theme.isNavVisible ? (['project'].includes(model) ? '#FFFFFF' : '#171C33') : '#171C33'
    };`}

  white-space: nowrap;

  &[data-ignore-item='true'] {
    & > svg {
      transition: transform 200ms ease-in-out;

      ${({ showMore }) => showMore && `transform: rotate(180deg);`}
    }
  }

  cursor: pointer;
`;

export const DetailsPageNavBarImageSkeleton = styled.div`
  background: rgb(255, 255, 255, 0.5);
  height: 80px;
  width: 80px;
  border-radius: 1000px;
`;

interface DetailsPageNavBarItemSkeletonProps {
  width: number;
}

export const DetailsPageNavBarItemSkeleton = styled.div<DetailsPageNavBarItemSkeletonProps>`
  background: rgb(255, 255, 255, 0.5);
  width: ${({ width }) => width}px;
  height: 16px;
  border-radius: 1000px;
`;

export const DetailsPageNavBarSeparator = styled.hr`
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.05);
  margin: 8px 0px;
`;

interface DetailsPageNavBarAboutContainerProps {
  color?: string;
}

export const DetailsPageNavBarAboutContainer = styled.div<DetailsPageNavBarAboutContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  padding: 8px 0px 16px 0px;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  & > span {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(23, 28, 51, 0.3);
  }
`;

export const MobileDetailsPageNavBarWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 11px 0px;
  position: sticky;
  top: 14px;
  width: 100%;
  z-index: 99999;

  background: #ffffff;
  box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  & > span {
    margin-left: 24px;
  }
`;

interface MobileDetailsPageNavBarOptionsContainerProps {
  isVisible?: boolean;
  triggerClosing?: boolean;
}

export const MobileDetailsPageNavBarOptionsContainer = styled.div<MobileDetailsPageNavBarOptionsContainerProps>`
  ${slideFromTopAnimation}
  ${({ isVisible }) => (isVisible ? 'display: flex;' : 'display: none;')}
  flex-direction: column;
  position: fixed;
  top: 14px;

  padding: 16px;
  z-index: 9999999999;

  background: #ffffff;
  box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: calc(100% - 32px);
  margin-left: 16px;
  ${({ triggerClosing }) => (triggerClosing ? slideToTopAnimation : ``)}

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

interface MobileDetailsPageNavBarOptionsArrowProps {
  up?: boolean;
  top?: number;
  right?: number;
}

export const MobileDetailsPageNavBarOptionsArrow = styled.div<MobileDetailsPageNavBarOptionsArrowProps>`
  position: absolute;
  top: ${({ top }) => top ?? 16}px;
  right: ${({ right }) => right ?? 16}px;

  > svg {
    ${({ up }) => up && 'transform: rotate(180deg);'}
  }
`;

export const MobileDetailsPageNavBarOptionsTitle = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;

  color: rgba(31, 32, 37, 0.5);
  text-transform: uppercase;
`;

export const MobileDetailsPageNavBarOptionsCompanyIcon = styled.img`
  width: 60px;
  height: 60px;

  background: #ffffff;
  border: 2px solid #171c33;
  border-radius: 100%;
`;

export const MobileDetailsPageNavBarOptionsSeparator = styled.hr`
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.05);
`;

interface MobileDetailsPageNavBarOptionsAboutProps {
  color?: string;
}

export const MobileDetailsPageNavBarOptionsAbout = styled.span<MobileDetailsPageNavBarOptionsAboutProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  display: flex;
  align-items: center;

  color: ${({ color }) => color || '#5478A1'};
`;

export const DetailsPageNavBarContainer = styled.div`
  width: 70vw;
  max-width: 1440px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 8px 0px 0px 0px;
  margin: 0 auto;

  & > :not(:last-child) {
    margin-right: 24px;
  }

  position: relative;
`;

export const DetailsPageNavBarItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & > :not(:last-child) {
    margin-right: 24px;
  }

  overflow: hidden;
`;

// MORE MENU
export const DetailsPageNavBarItemsMoreMenuContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  padding: 16px;

  margin-top: 8px;

  border-radius: 16px;
  background: #fff;

  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);

  overflow: hidden;

  z-index: 99;
`;
