import React from 'react';

export default function LightningIcon({ width = 20, height = 22, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 22"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M11 1L1 13H10L9 21L19 9H10L11 1Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
