export const INPUT_MIN_CHARS = 2;

export const dataLabels = {
  people: 'PEOPLE',
  kyu: 'KYU',
  member_companies: 'MEMBER COMPANIES',
  partner_companies: 'PARTNER COMPANIES',
  client_companies: 'CLIENTS',
  kyu_skills: 'SKILLS',
  passions: 'PASSIONS',
  industries: 'INDUSTRIES',
  projects: 'PROJECTS',
  communities: 'COMMUNITIES',
  articles: 'ARTICLES',
  tools: 'TOOLS',
  convenings: 'CONVENINGS',
};

export const colorsLabels = {
  people: '#FFD25F',
  person: '#FFD25F',
  client_companies: '#96D7D9',
  kyu_companies: '#9CCAFF',
  partner_companies: '#9CCAFF',
  member_companies: '#9CCAFF',
  kyu: '#9CCAFF',
  kyu_skills: '#FFA366',
  passions: '#FBAFA1',
  industries: '#CEF29E',
  projects: '#9F7CFB',
  communities: '#D0B2B2',
  articles: '#FFD25F',
  tools: '#FFD25F',
  convenings: '#D0B2B2',
};

export const focusedColors = {
  people: '#F5BC2C',
  person: '#F5BC2C',
  client_companies: '#84BEBF',
  kyu_companies: '#7CACE2',
  partner_companies: '#7CACE2',
  member_companies: '#7CACE2',
  kyu: '#7CACE2',
  kyu_skills: '#E5935C',
  passions: '#EA9080',
  industries: '#B1D384',
  projects: '#886BD6',
  communities: '#A26665',
  articles: '#F5BC2C',
  tools: '#F5BC2C',
  convenings: '#A26665',
};
