import React, { useContext, useState } from 'react';
import { UseQueryResult } from 'react-query';

import SideDrawer from 'app/components/CommonStyled/SideDrawer';
import GlobalSkillDrawer from 'app/components/Drawers/GlobalSkill/GlobalSkillDrawer';
import PeopleDrawer from 'app/components/Drawers/People/PeopleDrawer';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { getCompanyLogoUrl } from 'utils';

import { ICompanySnapshotResponse } from '../interfaces/company';
import { DetailsPageContext } from '../shared/DetailsPageContext';
import CompanyDetailsSnapshotSkeleton from './Skeleton/CompanyDetailsSnapshotSkeleton';
import {
  CompanyDetailsSnapshotWrapper,
  CompanyDetailsSnapshotHighlight,
  CompanyDetailsSnapshotHighlightWrapper,
  CompanyDetailsSnapshotHighlightSkill,
  CompanyDetailsSnapshotHighlightCount,
  CompanyDetailsSnapshotRelatedCompaniesWrapper,
  CompanyDetailsSnapshotRelatedCompaniesTitle,
  CompanyDetailsSnapshotRelatedCompaniesTitleContainer,
  CompanyDetailsSnapshotRelatedCompaniesContainer,
  CompanyDetailsSnapshotRelatedCompaniesImage,
} from './styled';

interface CompanyDetailsSnapshotProps {
  query?: UseQueryResult<ICompanySnapshotResponse, Error>;
}

export default function CompanyDetailsSnapshot({ query }: CompanyDetailsSnapshotProps) {
  const { isLoading, isIdle, data } = query;
  const [showPeopleDrawer, setShowPeopleDrawer] = useState<boolean>(false);
  const context = useContext(DetailsPageContext);
  const dimensions = useWindowDimensions();
  const isMobile = dimensions && dimensions.width < 1024;

  const skillsToText = () => {
    const { kyu_skills } = data.highlight;

    return kyu_skills.map((skill, index) => (
      <SnapshotHighlightSkill skill={skill} skills={kyu_skills} key={index} index={index} />
    ));
  };

  if (isLoading || isIdle) {
    return <CompanyDetailsSnapshotSkeleton />;
  }

  return (
    <CompanyDetailsSnapshotWrapper>
      <CompanyDetailsSnapshotHighlightWrapper>
        <CompanyDetailsSnapshotHighlight>
          As of today,{' '}
          <CompanyDetailsSnapshotHighlightCount onClick={() => setShowPeopleDrawer(true)}>
            {data.highlight.employees_count} people
          </CompanyDetailsSnapshotHighlightCount>{' '}
          work at {context.data.name}.
        </CompanyDetailsSnapshotHighlight>
        {data?.highlight?.kyu_skills?.length > 0 && (
          <CompanyDetailsSnapshotHighlight>
            People most closely identify with {skillsToText()} as areas of expertise.
          </CompanyDetailsSnapshotHighlight>
        )}
      </CompanyDetailsSnapshotHighlightWrapper>
      {data.related_companies && data?.related_companies?.length > 0 && (
        <CompanyDetailsSnapshotRelatedCompaniesWrapper>
          <CompanyDetailsSnapshotRelatedCompaniesTitleContainer>
            <RelatedCompaniesIcon />
            <CompanyDetailsSnapshotRelatedCompaniesTitle>
              RELATED COMPANIES
            </CompanyDetailsSnapshotRelatedCompaniesTitle>
          </CompanyDetailsSnapshotRelatedCompaniesTitleContainer>
          <CompanyDetailsSnapshotRelatedCompaniesContainer>
            {data.related_companies.map((company, index) => (
              <CompanyDetailsSnapshotRelatedCompaniesImage
                key={index}
                src={getCompanyLogoUrl(company.slug, true)}
              />
            ))}
          </CompanyDetailsSnapshotRelatedCompaniesContainer>
        </CompanyDetailsSnapshotRelatedCompaniesWrapper>
      )}
      {showPeopleDrawer && (
        <SideDrawer closeDrawer={() => setShowPeopleDrawer(false)} withClose>
          <PeopleDrawer />
        </SideDrawer>
      )}
    </CompanyDetailsSnapshotWrapper>
  );
}

export function RelatedCompaniesIcon({
  width = 19,
  height = 19,
  fill = '#171C33',
  fillOpacity = 0.5,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.45409 2.02881C2.13346 2.02881 1.87354 2.29464 1.87354 2.62256V7.55525C1.87354 8.25886 2.09457 8.95237 2.51364 9.47986C2.93537 10.0107 3.53635 10.3413 4.19576 10.3413H5.45672V15.3385C5.45672 16.1258 5.76254 16.8809 6.30692 17.4377C6.8513 17.9944 7.58963 18.3072 8.35949 18.3072H13.0039C13.7738 18.3072 14.5121 17.9944 15.0565 17.4377C15.6009 16.8809 15.9067 16.1258 15.9067 15.3385C16.6766 15.3385 17.4149 15.0257 17.9593 14.4689C18.5037 13.9122 18.8095 13.1571 18.8095 12.3697C18.8095 11.5824 18.5037 10.8272 17.9593 10.2705C17.4149 9.71374 16.6766 9.40096 15.9067 9.40096L15.9067 7.61971C15.9067 7.29179 15.6468 7.02596 15.3262 7.02596H11.8084C12.0481 6.6454 12.1784 6.19945 12.1784 5.73975C12.1784 5.10986 11.9338 4.50577 11.4983 4.06037C11.0971 3.65008 10.5643 3.4054 10.0013 3.36938V2.62256C10.0013 2.29464 9.74139 2.02881 9.42076 2.02881H2.45409ZM8.8402 7.02596V3.21631H3.03465V7.55525C3.03465 8.01452 3.1806 8.43625 3.41479 8.73103C3.64631 9.02245 3.93132 9.15381 4.19576 9.15381H5.45672V7.61971C5.45672 7.29179 5.71664 7.02596 6.03727 7.02596H8.8402ZM10.6772 6.57944C10.494 6.76687 10.2558 6.88517 10.0013 6.91794V4.56155C10.2558 4.59432 10.494 4.71263 10.6772 4.90006C10.895 5.12276 11.0173 5.4248 11.0173 5.73975C11.0173 6.05469 10.895 6.35674 10.6772 6.57944ZM14.7456 9.99471V9.9958V14.7436V14.7447V14.7458V15.3385C14.7456 15.8109 14.5621 16.2639 14.2355 16.598C13.9089 16.932 13.4659 17.1197 13.0039 17.1197H8.35949C7.89758 17.1197 7.45458 16.932 7.12795 16.598C6.80132 16.2639 6.61783 15.8109 6.61783 15.3385V8.21346H14.7456V9.99362V9.99471ZM17.1383 13.6292C16.8116 13.9633 16.3686 14.151 15.9067 14.151V10.5885C16.3686 10.5885 16.8116 10.7761 17.1383 11.1102C17.4649 11.4442 17.6484 11.8973 17.6484 12.3697C17.6484 12.8421 17.4649 13.2952 17.1383 13.6292Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
}

function SnapshotHighlightSkill({ skill, skills, index }) {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  return (
    <>
      <CompanyDetailsSnapshotHighlightSkill onClick={() => setShowDrawer(true)}>
        {skill.name}
      </CompanyDetailsSnapshotHighlightSkill>
      <span>
        {index !== skills?.length - 1 ? (index === skills?.length - 2 ? ' and ' : ', ') : ''}
      </span>
      {showDrawer && (
        <SideDrawer closeDrawer={() => setShowDrawer(false)} withClose>
          <GlobalSkillDrawer slug={skill.slug} />
        </SideDrawer>
      )}
    </>
  );
}
