const colorsByType = {
  kyuPage: "#000",
  communities: "#D0B2B2",
  people: "#ffd25f",
  kyu_skills: "#FFA366",
  kyu_companies: "#9CCAFF",
  companies: "#104D5B",
  passions: "#FBAFA1",
  industries: "#CEF29E",
  projects: "#937ECC",
  multiple:
    "linear-gradient(180deg, #EB823D 4.73%, #D47563 36.46%, rgba(57, 43, 98, 0.7) 70.83%, #5478A1 100%)",
  group:
    "linear-gradient(180deg, #EDF2F8 0%, #EDF2F8 38.02%, #D47563 75.52%, #104D5B 100%)",
};

export default colorsByType;
