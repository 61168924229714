import styled from 'styled-components';

export const MobileMenuTilesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

export const MobileMenuTilesContent = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

export const MobileMenuTilesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`;

export const MobileMenuTilesTitle = styled.h6`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.waysToGetInvolvedTitle};
`;