import React from 'react';
import Masonry from 'react-masonry-css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { HOME_DRAWER_HASH } from 'app/components/CommonStyled/GlobalHomeDrawers/constants';
import {
  ClientIcon,
  IndustryIcon,
  PassionIcon,
  SkillIcon,
} from 'app/components/DetailsPages/shared/CommonGround/Icons';
import {
  BrandAssetsIcon,
  CommunityIcon,
  DisciplineIcon,
  Megaphone,
  RightArrow,
  WorldGlobe,
} from 'app/components/shared/icons';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import { setHomeDrawer } from 'store/reducers/global/global-reducer';
import { setAboutKyuOsOpen } from 'store/reducers/onboarding-reducer';
import {
  PEOPLE_FILTER_TYPE,
  setCurrentFilterSubmenuOpen,
  setForceDropdownFilterOpen,
} from 'store/reducers/people/people-reducer';

import {
  AboutKyuOsNetworkToolsWrapper,
  AboutKyuOsNetworkToolsTitle,
  AboutKyuOsNetworkToolsContentColumn,
  AboutKyuOsNetworkToolsContentColumnContainer,
  AboutKyuOsNetworkToolsSubtitle,
  AboutKyuOsNetworkToolsCardWrapper,
  AboutKyuOsNetworkToolsCardContainer,
  AboutKyuOsNetworkToolsCardLeftContainer,
  AboutKyuOsNetworkToolsCardText,
  AboutKyuOsNetworkToolsCardIcon,
  AboutKyuOsNetworkToolsCardWithDescriptionContainer,
  AboutKyuOsNetworkToolsCardDescription,
  AboutKyuOsNetworkToolsCardWithDescriptionWrapper,
} from './styled';

export default function AboutKyuOsNetworkTools() {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const collectiveDirectoryOpenedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_COLLECTIVE_DIRECTORY_OPENED
  );

  const globalDisciplinesDrawerOpenedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_GLOBAL_DISCIPLINE_DRAWER_OPENED
  );

  const globalIndustriesDrawerOpenedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_GLOBAL_INDUSTRY_DRAWER_OPENED
  );

  const globalClientsDrawerOpenedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_GLOBAL_CLIENT_DRAWER_OPENED
  );

  const globalSkillsDrawerOpenedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_GLOBAL_SKILL_DRAWER_OPENED
  );

  const globalPassionsDrawerOpenedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_GLOBAL_PASSION_DRAWER_OPENED
  );

  const resourceLibraryOpenedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_RESOURCE_LIBRARY_OPENED
  );

  const handleFindByLocationClick = () => {
    collectiveDirectoryOpenedBehavioralFunction({
      action_details: 'By location',
    });
    dispatch(setForceDropdownFilterOpen(true));
    dispatch(setCurrentFilterSubmenuOpen(PEOPLE_FILTER_TYPE.LOCATIONS));
    push('/people');

    if (location.pathname.includes('people')) {
      dispatch(setAboutKyuOsOpen(false));
    }
  };

  const handleFindByTopicClick = () => {
    collectiveDirectoryOpenedBehavioralFunction({
      action_details: 'By topic (menu opened)',
    });
    dispatch(setForceDropdownFilterOpen(true));
    dispatch(setCurrentFilterSubmenuOpen(PEOPLE_FILTER_TYPE.SKILLS));
    push('/people');

    if (location.pathname.includes('people')) {
      dispatch(setAboutKyuOsOpen(false));
    }
  };

  const handleDisciplinesClick = () => {
    globalDisciplinesDrawerOpenedBehavioralFunction();
    dispatch(setHomeDrawer({ show: true, model: HOME_DRAWER_HASH.DISCIPLINES }));
  };

  const handleIndustriesClick = () => {
    globalIndustriesDrawerOpenedBehavioralFunction();
    dispatch(setHomeDrawer({ show: true, model: HOME_DRAWER_HASH.INDUSTRIES }));
  };

  const handleClientsClick = () => {
    globalClientsDrawerOpenedBehavioralFunction();
    dispatch(setHomeDrawer({ show: true, model: HOME_DRAWER_HASH.CLIENTS }));
  };

  const handleSkillsClick = () => {
    globalSkillsDrawerOpenedBehavioralFunction();
    dispatch(setHomeDrawer({ show: true, model: HOME_DRAWER_HASH.SKILLS }));
  };

  const handlePassionsClick = () => {
    globalPassionsDrawerOpenedBehavioralFunction();
    dispatch(setHomeDrawer({ show: true, model: HOME_DRAWER_HASH.PASSIONS }));
  };

  const handleSubjectMatterExpertsClick = () => {
    collectiveDirectoryOpenedBehavioralFunction({
      action_details: 'By subject matter experts',
    });
    dispatch(setForceDropdownFilterOpen(true));
    dispatch(setCurrentFilterSubmenuOpen(PEOPLE_FILTER_TYPE.SKILLS));
    push('/people');

    if (location.pathname.includes('people')) {
      dispatch(setAboutKyuOsOpen(false));
    }
  };

  const handleResourceLibraryClick = () => {
    resourceLibraryOpenedBehavioralFunction();
    push('/resources');

    if (location.pathname.includes('resources')) {
      dispatch(setAboutKyuOsOpen(false));
    }
  };

  const handlePromoteServicesClick = () => {
    collectiveDirectoryOpenedBehavioralFunction({
      action_details: 'Promote your service',
    });
    dispatch(setForceDropdownFilterOpen(true));
    push('/people');

    if (location.pathname.includes('people')) {
      dispatch(setAboutKyuOsOpen(false));
    }
  };

  return (
    <AboutKyuOsNetworkToolsWrapper id="tools">
      <AboutKyuOsNetworkToolsTitle>Tools</AboutKyuOsNetworkToolsTitle>
      <Masonry
        breakpointCols={{ default: 2, 610: 1 }}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <AboutKyuOsNetworkToolsContentColumn>
          <AboutKyuOsNetworkToolsSubtitle>MAKE THE RIGHT CONTACTS</AboutKyuOsNetworkToolsSubtitle>
          <AboutKyuOsNetworkToolsContentColumnContainer>
            <AboutKyuOsNetworkTools.Card
              text="Find peers by location"
              onClick={handleFindByLocationClick}
            >
              <AboutKyuOsNetworkToolsCardIcon background="var(--color-data-people-background)">
                <WorldGlobe width={16} height={16} fill="var(--color-data-people-foreground)" />
              </AboutKyuOsNetworkToolsCardIcon>
            </AboutKyuOsNetworkTools.Card>
            <AboutKyuOsNetworkTools.Card
              text="Find peers by topic"
              onClick={handleFindByTopicClick}
            >
              <AboutKyuOsNetworkToolsCardIcon background="var(--color-data-community-background)">
                <CommunityIcon
                  width={16}
                  height={16}
                  fill="var(--color-data-community-foreground)"
                />
              </AboutKyuOsNetworkToolsCardIcon>
            </AboutKyuOsNetworkTools.Card>
          </AboutKyuOsNetworkToolsContentColumnContainer>
        </AboutKyuOsNetworkToolsContentColumn>
        <AboutKyuOsNetworkToolsContentColumn>
          <AboutKyuOsNetworkToolsSubtitle>BROADEN YOUR SEARCH</AboutKyuOsNetworkToolsSubtitle>
          <AboutKyuOsNetworkToolsContentColumnContainer>
            <AboutKyuOsNetworkTools.CardWithDescription
              text="Search by Discipline"
              description="Find people by their primary job function"
              onClick={handleDisciplinesClick}
            >
              <AboutKyuOsNetworkToolsCardIcon background="var(--color-data-company-background)">
                <DisciplineIcon width={23} height={23} />
              </AboutKyuOsNetworkToolsCardIcon>
            </AboutKyuOsNetworkTools.CardWithDescription>
            <AboutKyuOsNetworkTools.CardWithDescription
              text="Search by Industry"
              description="Learn about the industries we do the most work in"
              onClick={handleIndustriesClick}
            >
              <AboutKyuOsNetworkToolsCardIcon background="var(--color-data-industry-background)">
                <IndustryIcon width={36} height={36} fill="var(--color-data-industry-foreground)" />
              </AboutKyuOsNetworkToolsCardIcon>
            </AboutKyuOsNetworkTools.CardWithDescription>
            <AboutKyuOsNetworkTools.CardWithDescription
              text="Search by Client"
              description="Find out which clients our network has worked with"
              onClick={handleClientsClick}
            >
              <AboutKyuOsNetworkToolsCardIcon background="var(--color-data-client-background)">
                <ClientIcon width={26} height={26} fill="var(--color-data-client-foreground)" />
              </AboutKyuOsNetworkToolsCardIcon>
            </AboutKyuOsNetworkTools.CardWithDescription>
            <AboutKyuOsNetworkTools.CardWithDescription
              text="Search by Skill"
              description="Find people by expertise they added to their profiles."
              onClick={handleSkillsClick}
            >
              <AboutKyuOsNetworkToolsCardIcon background="var(--color-data-skill-background)">
                <SkillIcon width={26} height={26} fill="var(--color-data-skill-foreground)" />
              </AboutKyuOsNetworkToolsCardIcon>
            </AboutKyuOsNetworkTools.CardWithDescription>
            <AboutKyuOsNetworkTools.CardWithDescription
              text="Search by Interests"
              description="Find people by the passions they added to their profiles."
              onClick={handlePassionsClick}
            >
              <AboutKyuOsNetworkToolsCardIcon background="var(--color-data-passion-background)">
                <PassionIcon width={26} height={26} fill="var(--color-data-passion-foreground)" />
              </AboutKyuOsNetworkToolsCardIcon>
            </AboutKyuOsNetworkTools.CardWithDescription>
          </AboutKyuOsNetworkToolsContentColumnContainer>
        </AboutKyuOsNetworkToolsContentColumn>
        <AboutKyuOsNetworkToolsContentColumn style={{ paddingTop: 0 }}>
          <AboutKyuOsNetworkToolsSubtitle>
            MAKE THE MOST OF SHARED RESOURCES
          </AboutKyuOsNetworkToolsSubtitle>
          <AboutKyuOsNetworkToolsContentColumnContainer>
            <AboutKyuOsNetworkTools.Card
              text="Reach out to subject matter experts"
              onClick={handleSubjectMatterExpertsClick}
            >
              <AboutKyuOsNetworkToolsCardIcon background="var(--color-data-skill-background)">
                <SkillIcon width={16} height={16} fill="var(--color-data-skill-foreground)" />
              </AboutKyuOsNetworkToolsCardIcon>
            </AboutKyuOsNetworkTools.Card>
            <AboutKyuOsNetworkTools.Card
              text="Access the Resource Library"
              onClick={handleResourceLibraryClick}
            >
              <AboutKyuOsNetworkToolsCardIcon background="rgba(50, 63, 93, 1)">
                <BrandAssetsIcon />
              </AboutKyuOsNetworkToolsCardIcon>
            </AboutKyuOsNetworkTools.Card>
            <AboutKyuOsNetworkTools.Card
              text="Broadcast your event or services to the Collective"
              onClick={handlePromoteServicesClick}
            >
              <AboutKyuOsNetworkToolsCardIcon background="var(--color-data-community-background)">
                <Megaphone width={16} height={16} fill={'var(--color-data-community-foreground)'} />
              </AboutKyuOsNetworkToolsCardIcon>
            </AboutKyuOsNetworkTools.Card>
          </AboutKyuOsNetworkToolsContentColumnContainer>
        </AboutKyuOsNetworkToolsContentColumn>
      </Masonry>
    </AboutKyuOsNetworkToolsWrapper>
  );
}

AboutKyuOsNetworkTools.Card = function AboutKyuOsNetworkToolsCardComponent({
  children,
  text,
  onClick,
}: {
  children: React.ReactNode;
  text?: string;
  onClick?: () => void;
}) {
  const handleClick = () => {
    onClick?.();
  };

  return (
    <AboutKyuOsNetworkToolsCardWrapper onClick={handleClick}>
      <AboutKyuOsNetworkToolsCardContainer>
        <AboutKyuOsNetworkToolsCardLeftContainer>
          {children}
          <AboutKyuOsNetworkToolsCardText>{text}</AboutKyuOsNetworkToolsCardText>
        </AboutKyuOsNetworkToolsCardLeftContainer>
        <RightArrow width={16} height={16} fill="var(--color-primary)" />
      </AboutKyuOsNetworkToolsCardContainer>
    </AboutKyuOsNetworkToolsCardWrapper>
  );
};

AboutKyuOsNetworkTools.CardWithDescription =
  function AboutKyuOsNetworkToolsCardWithDescriptionComponent({
    children,
    text,
    description,
    onClick,
  }: {
    children: React.ReactNode;
    text?: string;
    description?: string;
    onClick?: () => void;
  }) {
    const handleClick = () => {
      onClick?.();
    };

    return (
      <AboutKyuOsNetworkToolsCardWithDescriptionWrapper onClick={handleClick}>
        <AboutKyuOsNetworkToolsCardContainer>
          <AboutKyuOsNetworkToolsCardLeftContainer>
            {children}
            <AboutKyuOsNetworkToolsCardWithDescriptionContainer>
              <AboutKyuOsNetworkToolsCardText>{text}</AboutKyuOsNetworkToolsCardText>
              <AboutKyuOsNetworkToolsCardDescription>
                {description}
              </AboutKyuOsNetworkToolsCardDescription>
            </AboutKyuOsNetworkToolsCardWithDescriptionContainer>
          </AboutKyuOsNetworkToolsCardLeftContainer>
          <RightArrow width={16} height={16} fill="var(--color-primary)" />
        </AboutKyuOsNetworkToolsCardContainer>
      </AboutKyuOsNetworkToolsCardWithDescriptionWrapper>
    );
  };
