import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

import { ContactCTAWrapper } from '../../ContactCTA/styled';
import { ToggleButtonTitle } from '../../ToggleButton/styled';

export const HomeBaseDrawerWrapper = styled.div`
  z-index: 99999;
  height: 100%;
  width: 520px;
  min-height: 100svh;

  position: fixed;
  top: 0px;
  right: 0px;

  background: #ffffff;

  ${BREAKPOINTS.tablet} {
    width: 100%;
  }
`;

export const HomeBaseDrawerContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
`;

interface HomeBaseDrawerContentContainerProps {
  paddingTop: number;
}

export const HomeBaseDrawerContentContainer = styled.div<HomeBaseDrawerContentContainerProps>`
  width: 100%;
  height: 100%;

  padding: calc(${({ paddingTop }) => paddingTop}px + 32px) 32px 189px 32px;
  overflow-y: scroll;

  ${BREAKPOINTS.tablet} {
    padding: calc(${({ paddingTop }) => paddingTop}px + 24px) 32px 189px 24px;
  }
`;

export const HomeBaseDrawerBottomWrapper = styled.div`
  width: 100%;

  position: absolute;
  bottom: 0;
  left: 0;

  background: rgba(23, 28, 51, 0.02);
  z-index: 1;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(50px);
  }

  & ${ContactCTAWrapper} {
    border-radius: 16px 16px 0px 0px;
  }
`;

interface HomeBaseDrawerBottomDataTypeLensesWrapperProps {
  currentModel: string;
}

export const HomeBaseDrawerBottomDataTypeLensesWrapper = styled.div<HomeBaseDrawerBottomDataTypeLensesWrapperProps>`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 4px;
  }

  & > :last-child {
    width: 100%;
  }

  & > * {
    opacity: 0.5;
    transition: 250ms all ease;
    flex: 1;
  }

  &:hover {
    & > div {
      opacity: 0.5;
    }
  }

  &:hover > *:hover {
    opacity: 0.8;
  }

  & > #data-type-len-${({ currentModel }) => currentModel} {
    opacity: 1;
  }
`;

export const HomeBaseDrawerHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const HomeBaseDrawerHeaderTitle = styled.h1`
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  margin-bottom: 10px;

  ${BREAKPOINTS.tablet} {
    font-size: 24px;
  }
`;

export const HomeBaseDrawerHeaderDescription = styled.p`
  opacity: 0.5;
  font-size: 16px;
  margin-bottom: 10px;

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
  }
`;

export const HomeBaseDrawerHeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;

  ${ToggleButtonTitle} {
    font-size: 14px;
    margin-right: 8px;
  }
`;

interface HomeBaseDrawerActionProps {
  selected: boolean;
}
export const HomeBaseDrawerHeaderActionsItem = styled.span<HomeBaseDrawerActionProps>`
  font-size: 14px;
  font-weight: ${({ selected }) => (selected ? 700 : 500)};
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  margin-right: 8px;
  cursor: pointer;
`;

export const HomeBaseDrawerListContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;
export const HomeBaseDrawerTopWrapper = styled.div`
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 48px 24px 24px 24px;

  background: rgba(23, 28, 51, 0.02);
  z-index: 2;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(50px);
  }

  & > div {
    position: relative;
    z-index: 3;
  }

  ${BREAKPOINTS.tablet} {
    padding: 48px 16px 24px 16px;
  }
`;

export const HomeBaseDrawerGlobalDrawersWrapper = styled.div`
  min-height: 100svh;
  height: 100%;
  position: relative;
  z-index: 99999999999;
`;

// SHARED STYLES
export const HomeBaseDrawerTitle = styled.h2`
  font-weight: 400;
  font-size: 30px;
  line-height: 42px;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    font-size: 23px;
  }
`;

export const HomeBaseDrawerSubtitle = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: rgba(23, 28, 51, 0.5);

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
  }
`;

export const HomeBaseDrawerSmallSubtitle = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: rgba(23, 28, 51, 0.5);

  ${BREAKPOINTS.tablet} {
    font-size: 14px;
  }
`;
