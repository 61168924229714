import React, { useEffect, useState } from 'react';

import { sendFeedback } from 'services/app';

import {
  FeedbackSentSection,
  FeedbackBlockForm,
  FeedbackBlockIntro,
  FeedbackBlockContent,
  FeedbackBlockCheckbox,
  FeedbackSubmitButton,
} from './styled';

export default function FeedbackDrawer() {
  const [feedbackError, setFeedbackError] = useState<boolean>(false);
  const [feedbackSending, setFeedbackSending] = useState<boolean>(false);
  const [feedbackSent, setFeedbackSent] = useState<boolean>(false);
  const [consent, setConsent] = useState<boolean>(false);
  const [feedbackMessage, setFeedbackMessage] = useState<string>('');

  useEffect(() => {
    if (feedbackMessage && feedbackError) return setFeedbackError(false);
  }, [feedbackMessage]);

  const handleChange = (event) => setFeedbackMessage(event.target.value);

  const sendFeedbackRequest = async () => {
    setFeedbackSending(true);
    const sendFeedbackCall = await sendFeedback({
      consent,
      feedback_text: feedbackMessage,
    });
    setFeedbackSending(false);
    if (sendFeedbackCall.ok) return setFeedbackSent(true);
    return setFeedbackError(true);
  };

  return (
    <FeedbackBlockContent>
      {!feedbackSent && (
        <>
          <FeedbackBlockIntro>
            <h4>Penny for your thoughts 🧠</h4>
            <p>If you’ve been using kyu OS, we’d love to hear about it - the good and the bad.</p>
          </FeedbackBlockIntro>
          <FeedbackBlockForm showError={feedbackError}>
            <textarea
              placeholder="Dear Creative Intelligence team, "
              onChange={handleChange}
            ></textarea>
            <FeedbackBlockCheckbox>
              <label className="checkbox">
                <span className="checkbox__input">
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={consent}
                    onChange={(e) => setConsent(!consent)}
                  />
                  <span className="checkbox__control"></span>
                </span>
                <span className="radio__label">Include my contact details</span>
              </label>
            </FeedbackBlockCheckbox>

            <FeedbackSubmitButton
              type="button"
              disabled={feedbackMessage.replace(/ /g, '').replace(/\r\n|\r|\n/g, '').length == 0}
              onClick={sendFeedbackRequest}
            >
              {feedbackSending ? <div className="dot-flashing" /> : 'Send'}
            </FeedbackSubmitButton>
            <span className="error-msg">Feedback failed to send, try again please.</span>
          </FeedbackBlockForm>
        </>
      )}

      {feedbackSent && (
        <FeedbackSentSection>
          <svg width="305" height="35" viewBox="0 0 305 35" fill="none">
            <path
              d="M8.02497 29.4994L288.025 15.4994L287.975 14.5006L7.97503 28.5006L8.02497 29.4994Z"
              fill="#9CCAFF"
            />
            <circle cx="6.5" cy="28.5" r="6.5" fill="#171C33" />
            <circle opacity="0.4" cx="290" cy="15" r="15" fill="#FFD25F" />
            <circle cx="290" cy="15" r="9" fill="#FFD25F" />
          </svg>
          <h3>Thank you</h3>
          <p>
            Your feedback has been sent directly to us at kyu! Your contribution is truly
            appreciated.
          </p>
        </FeedbackSentSection>
      )}
    </FeedbackBlockContent>
  );
}
