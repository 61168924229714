import React from 'react';

const SearchIcon = ({ width = 26, height = 26, fill = 'white', fillOpacity = 1 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10.061"
        cy="10.8657"
        r="9.06098"
        stroke={fill}
        strokeWidth="2"
        strokeOpacity={fillOpacity}
      />
      <path
        d="M16.4634 16.6582L25 25.1948"
        stroke={fill}
        strokeWidth="2"
        strokeOpacity={fillOpacity}
      />
    </svg>
  );
};

export default SearchIcon;
