import styled from "styled-components";

interface SkillsDrawerContainerProps {
  showingSkillDrawer?: boolean;
}

export const SkillsDrawerContainer = styled.div<SkillsDrawerContainerProps>`
  height: 100%;
  padding: ${({ showingSkillDrawer }) => showingSkillDrawer ? 0 : 32}px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0.01%, rgba(255, 255, 255, 0.3) 17.44%, rgba(235, 130, 61, 0.3) 99.98%, rgba(255, 163, 102, 0.3) 99.99%), #FFFFFF;
`;

export const SkillsDrawerFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 32px;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  border-bottom: 1px solid rgba(235, 130, 61, 0.3);
  backdrop-filter: blur(100px);
`;

export const SkillsDrawerFiltersOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

interface SkillDrawerFilterOptionProps {
  selected?: boolean;
}

export const SkillDrawerFilterOption = styled.div<SkillDrawerFilterOptionProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ selected }) => (selected ? "#EB823D" : "#FFA366")};

  cursor: pointer;
`;

export const SkillsDrawerListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px 110px 0px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const SkillDrawerPill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;

  & > :not(:last-child) {
    margin-right: 5px;
  }

  background: rgba(235, 130, 61, 0.3);
  border-radius: 26px;

  position: relative;
  cursor: pointer;
`;

export const SkillDrawerText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #EB823D;
`;

export const SkillsDrawerFiltersTitle = styled.h3`
  font-weight: 300;
  font-size: 32px;
  line-height: 42px;
  color: #171C33;
`;

export const SkillsDrawerFiltersDescription = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #171C33;
`;

export const SkillUserAvatar = styled.img`
  width: 22px;
  height: 22px;
  object-fit: cover;
  border: 2px solid #FFC229;
  border-radius: 1000px;

  position: absolute;
  right: -30px;
`;