import React, { useEffect } from 'react';

import {
  sendEmailCopiedBehavioral,
  sendMapOpenBehavioral,
  sendResourceOpenBehavioral,
  sendGetInvolvedClickedBehavioral,
  sendGetInvolvedLinkClickedBehavioral,
  sendBlogPostClickedBehavioral,
  sendAvatarClickedBehavioral,
  sendRegistrationLinkClickedBehavioral,
  sendViewedBlogPostPageBehavioral,
} from './behaviorals-events';
import colorsByType from './colors-by-type';
import { resultPageHighlights, getResultPageTitle, nodeCardsHighlights } from './create-highlights';
import { labelsColors, nodesColors } from './dictionary';
import { getValuesFromQuery } from './query-string';
import typeLabel from './types-labels';

const createMarkup = (html) => ({ __html: html });

function htmlToPlainString(html) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  const text = tempDiv.textContent || tempDiv.innerText || '';
  tempDiv.remove();
  return text;
}

const getCompanyLogoUrl = (slug: string, rounded: boolean = false) => {
  return rounded
    ? `/images/logos/rounded_kyu_companies/${slug}.svg`
    : `/images/logos/kyu_companies/${slug}.svg`;
};

const countryCodeEmoji = (cc) => {
  const codePoints = [...cc.toUpperCase()].map((c) => c.codePointAt() + 127397);
  return String.fromCodePoint(...codePoints);
};

const shuffleArray = (arr) => {
  let currentIndex = arr.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [arr[currentIndex], arr[randomIndex]] = [arr[randomIndex], arr[currentIndex]];
  }

  return arr;
};

const limitText = (string, maxCharacters) => {
  if (string.length > maxCharacters) {
    return string.substring(0, maxCharacters) + '...';
  }
  return string;
};

const ordinal_suffix_of = (n) => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

function stringToSlug(str) {
  if (typeof str === 'string' || str instanceof String) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaeeeeiiiioooouuuunc------';
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  return '';
}

const transformSlugToTitle = (slug) => {
  const title = slug.split('-').join(' ');
  return title;
};

const timeToString = ({ years, months }: { years: number; months: number }) => {
  let value = '';
  let yearString = 'year';

  if (months) {
    value += months >= 9 ? '¾' : months >= 6 ? '½' : months >= 3 ? '¼' : '';
  }

  if ((years === 1 && value !== '') || years !== 1) {
    yearString = 'years';
  }

  return `${years !== 0 ? years : ''} ${value} ${yearString}`;
};

function getOperatingSystem(window) {
  let operatingSystem = 'Not known';
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    operatingSystem = 'Windows OS';
  }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    operatingSystem = 'MacOS';
  }
  if (window.navigator.appVersion.indexOf('X11') !== -1) {
    operatingSystem = 'UNIX OS';
  }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) {
    operatingSystem = 'Linux OS';
  }

  return operatingSystem;
}

function getBrowser(window) {
  let currentBrowser = 'Not known';
  if (window.navigator.userAgent.indexOf('Chrome') !== -1) {
    currentBrowser = 'Google Chrome';
  } else if (window.navigator.userAgent.indexOf('Firefox') !== -1) {
    currentBrowser = 'Mozilla Firefox';
  } else if (window.navigator.userAgent.indexOf('MSIE') !== -1) {
    currentBrowser = 'Internet Exployer';
  } else if (window.navigator.userAgent.indexOf('Edge') !== -1) {
    currentBrowser = 'Edge';
  } else if (window.navigator.userAgent.indexOf('Safari') !== -1) {
    currentBrowser = 'Safari';
  } else if (window.navigator.userAgent.indexOf('Opera') !== -1) {
    currentBrowser = 'Opera';
  } else if (window.navigator.userAgent.indexOf('Opera') !== -1) {
    currentBrowser = 'YaBrowser';
  }

  return currentBrowser;
}

const OS = (window) => getOperatingSystem(window);
const currentBrowser = (window) => getBrowser(window);

const arrayToOxford = (array) => {
  if (array.length === 1) {
    return array[0];
  } else if (array.length === 2) {
    return array.join(' and ');
  } else {
    return array.slice(0, -1).join(', ') + ' and ' + array.slice(-1);
  }
};

const htmlElementsArrayToOxford = (array, useElementsSeparators = false) => {
  if (array.length === 1) {
    return array[0];
  } else if (array.length === 2) {
    if (useElementsSeparators) {
      return (
        <>
          {array[0]}
          <span className="and-separator"> and </span>
          {array[1]}
        </>
      );
    }

    return (
      <>
        {array[0]} and {array[1]}
      </>
    );
  } else {
    let result = [];
    array.forEach((item, index) => {
      if (index < array.length - 2) {
        result.push(
          item,
          !useElementsSeparators ? ', ' : <span className="comma-separator">, </span>
        );
      } else if (index === array.length - 2) {
        result.push(
          item,
          !useElementsSeparators ? ' and ' : <span className="and-separator"> and </span>
        );
      } else {
        result.push(item);
      }
    });
    return result;
  }
};

const clearLocationState = () => {
  if (window.history && window.history.replaceState) {
    window.history.replaceState({}, document.title);
  }
};

const getYears = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 100;
  const years = [];

  for (let i = currentYear; i >= startYear; i--) {
    years.push(i);
  }

  return years;
};

const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

const singularOrPlural = (number, singular, plural) => {
  return number === 1 ? singular : plural;
};
const useScrollToHash = (offset = 64, isLoading = false) => {
  useEffect(() => {
    let timeoutId = null;
    const scrollToHash = () => {
      let hash = window.location.hash;
      if (hash.endsWith('/')) {
        hash = hash.slice(0, -1);
      }
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          const yOffset = -offset;
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }
    };

    if (!isLoading) {
      timeoutId = setTimeout(() => {
        scrollToHash();
      }, 500);
    }

    window.addEventListener('hashchange', () => {
      if (!isLoading) {
        scrollToHash();
      }
    });

    return () => {
      window.removeEventListener('hashchange', scrollToHash);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isLoading, offset]);
};

export {
  colorsByType,
  createMarkup,
  getResultPageTitle,
  getValuesFromQuery,
  labelsColors,
  nodeCardsHighlights,
  nodesColors,
  resultPageHighlights,
  sendEmailCopiedBehavioral,
  sendMapOpenBehavioral,
  sendResourceOpenBehavioral,
  sendGetInvolvedClickedBehavioral,
  sendGetInvolvedLinkClickedBehavioral,
  sendBlogPostClickedBehavioral,
  sendAvatarClickedBehavioral,
  sendRegistrationLinkClickedBehavioral,
  sendViewedBlogPostPageBehavioral,
  typeLabel,
  getCompanyLogoUrl,
  countryCodeEmoji,
  shuffleArray,
  limitText,
  transformSlugToTitle,
  timeToString,
  stringToSlug,
  OS,
  currentBrowser,
  arrayToOxford,
  htmlElementsArrayToOxford,
  clearLocationState,
  getYears,
  ordinal_suffix_of,
  isValidEmail,
  singularOrPlural,
  titleCase,
  useScrollToHash,
  htmlToPlainString,
};
