import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import ProfileEditInput from 'app/components/CommonStyled/ProfileEditInput';
import Calendar from 'app/components/shared/icons/calendar';
import CheckIcon from 'app/components/shared/icons/check-icon';
import dayjs from 'dayjs';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import useOnClickOutside from 'hooks/click-outside';
import { postYearsExperience } from 'services/profile-editor';
import {
  profileDataSelector,
  setYearsExperience,
} from 'store/reducers/profile-editor/profile-editor-reducer';
import { getYears } from 'utils';
import * as Yup from 'yup';

import useDreamProjectDrawer from '../../hooks/useDreamProjectDrawer';
import ProfileEditorDrawer, { ProfileEditorDrawerRefProps } from '../Drawer';
import { ProfileEditorDrawerTitle } from '../styled';
import {
  ProfileEditorDrawerYearsExperienceCustomInputWrapper,
  ProfileEditorDrawerYearsExperienceDropdownIconContainer,
  ProfileEditorDrawerYearsExperienceDropdownOptionContainer,
  ProfileEditorDrawerYearsExperienceDropdownOptionsWrapper,
  ProfileEditorDrawerYearsExperienceDropdownOptionText,
  ProfileEditorDrawerYearsExperienceDropdownTitle,
  ProfileEditorDrawerYearsExperienceDropdownWrapper,
  ProfileEditorDrawerYearsExperienceWrapper,
} from './styled';

interface ProfileEditorDrawerYearsExperienceProps {
  onClose: () => void;
}

export default function ProfileEditorDrawerYearsExperience({
  onClose,
}: ProfileEditorDrawerYearsExperienceProps) {
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<any>>(null);
  const drawerRef = useRef<ProfileEditorDrawerRefProps>(null);
  const { years_experience } = useDreamProjectDrawer();

  useEffect(() => {
    if (years_experience) {
      formikRef.current.setFieldValue('years_experience', years_experience);
    }
  }, [years_experience]);

  const handlePostYearsExperience = async (params: any) => {
    const response = await postYearsExperience(params);
    if (!response.ok) {
      throw new Error(response.originalError?.message);
    }

    dispatch(setYearsExperience(params.value));

    return response.data;
  };

  const { mutate } = useMutation(handlePostYearsExperience, drawerRef.current?.handleMutation);

  const yearsExperienceValidationSchema = Yup.object().shape({
    years_experience: Yup.date()
      .max(new Date(), "Date can't be in the future")
      .required('Required'),
  });

  const handleSubmit = (values) => {
    mutate({ value: values.years_experience });
  };

  return (
    <Formik
      initialValues={{
        years_experience,
      }}
      validationSchema={yearsExperienceValidationSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {(props) => (
        <Form>
          <ProfileEditorDrawer
            ref={drawerRef}
            header={<ProfileEditorDrawerYearsExperienceHeader />}
            needsSaving={props.dirty}
            onClose={onClose}
            disableSubmit={props.values.years_experience === years_experience}
          >
            <ProfileEditorDrawerYearsExperienceWrapper>
              <ProfileEditInput
                {...props}
                name={'years_experience'}
                label={'When did you start your current career trajectory?'}
                tooltip={
                  'This field is for total years of experience, not tenure at your current kyu company. Depending on your journey, this could be when you started your first job, or when you started in your current industry or discipline.'
                }
                type={'custom'}
                customInput={<ProfileEditorDrawerYearsExperienceCustomInput />}
              />
            </ProfileEditorDrawerYearsExperienceWrapper>
          </ProfileEditorDrawer>
        </Form>
      )}
    </Formik>
  );
}

function ProfileEditorDrawerYearsExperienceHeader() {
  return <ProfileEditorDrawerTitle>Years of Experience</ProfileEditorDrawerTitle>;
}

function ProfileEditorDrawerYearsExperienceCustomInput() {
  const formikContext = useFormikContext();
  const { years_experience } = useSelector(profileDataSelector);
  const [pickedYear, setPickedYear] = useState<number>(null);
  const [pickedMonth, setPickedMonth] = useState<number>(null);

  useEffect(() => {
    const year = years_experience ? dayjs(years_experience).year() : null;
    const month = years_experience ? dayjs(years_experience).month() : null;
    setPickedYear(year);
    setPickedMonth(month);
  }, [years_experience]);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const years = getYears();

  const handleMonthPicked = (month: string) => {
    setPickedMonth(months.indexOf(month));
    if (pickedYear && months.indexOf(month) != null) {
      const date = new Date(pickedYear, months.indexOf(month));
      formikContext.setFieldValue('years_experience', date.toISOString());
    }
  };

  const handleYearPicked = (year) => {
    setPickedYear(year);
    if (year && pickedMonth != null) {
      const date = new Date(year, pickedMonth);
      formikContext.setFieldValue('years_experience', date.toISOString());
    }
  };

  return (
    <ProfileEditorDrawerYearsExperienceCustomInputWrapper>
      <input type="hidden" name="years_experience" />
      <ProfileEditorDrawerYearsExperienceDropdown
        title={'Month'}
        options={months}
        currentValue={months[pickedMonth]}
        onOptionPicked={handleMonthPicked}
      />
      <ProfileEditorDrawerYearsExperienceDropdown
        title={'Year'}
        options={years}
        currentValue={pickedYear}
        onOptionPicked={handleYearPicked}
      />
    </ProfileEditorDrawerYearsExperienceCustomInputWrapper>
  );
}

function ProfileEditorDrawerYearsExperienceDropdown({
  title,
  options,
  onOptionPicked,
  currentValue,
}: {
  title: string;
  options: string[];
  onOptionPicked: (option: string) => void;
  currentValue: string;
}) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [currentOption, setCurrentOption] = useState<string>(null);

  useEffect(() => {
    setCurrentOption(currentValue);
  }, [currentValue]);

  useOnClickOutside(dropdownRef, () => setShowOptions(false));

  const handleOptionClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, option) => {
    e.stopPropagation();
    setShowOptions(false);
    setCurrentOption(option);
    onOptionPicked(option);
  };

  return (
    <ProfileEditorDrawerYearsExperienceDropdownWrapper onClick={() => setShowOptions(true)}>
      <ProfileEditorDrawerYearsExperienceDropdownTitle hasValue={!!currentOption}>
        {currentOption ?? title}
      </ProfileEditorDrawerYearsExperienceDropdownTitle>
      <ProfileEditorDrawerYearsExperienceDropdownIconContainer>
        <Calendar width={20} height={22} fill={'var(--color-primary)'} />
      </ProfileEditorDrawerYearsExperienceDropdownIconContainer>
      {showOptions && (
        <ProfileEditorDrawerYearsExperienceDropdownOptionsWrapper ref={dropdownRef}>
          {options.map((option) => (
            <ProfileEditorDrawerYearsExperienceDropdownOptionContainer
              key={option}
              onClick={(e) => handleOptionClicked(e, option)}
            >
              <ProfileEditorDrawerYearsExperienceDropdownOptionText>
                {option}
              </ProfileEditorDrawerYearsExperienceDropdownOptionText>
              {currentOption === option && (
                <CheckIcon
                  width={24}
                  height={24}
                  background={'transparent'}
                  fill={'var(--color-data-people-background-hover)'}
                />
              )}
            </ProfileEditorDrawerYearsExperienceDropdownOptionContainer>
          ))}
        </ProfileEditorDrawerYearsExperienceDropdownOptionsWrapper>
      )}
    </ProfileEditorDrawerYearsExperienceDropdownWrapper>
  );
}
