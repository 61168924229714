import React from 'react';

import { ProfileEditInputProps } from '..';
import {
  ProfileEditInputTextContainer,
  ProfileEditInputTextError,
  ProfileEditInputTextErrorContainer,
  ProfileEditInputTextLimit,
  ProfileEditInputTextWrapper,
} from './styled';

export default function ProfileEditInputText(props: ProfileEditInputProps) {
  const { name, values, textLimit, disabled, customSublabel, touched, textarea } = props;

  return (
    <ProfileEditInputTextWrapper>
      <ProfileEditInputTextContainer
        textarea={textarea || textLimit >= 500}
        {...props}
        rows={props.rows}
        onBlur={props.handleBlur}
        onChange={props.handleChange}
        placeholder={props.placeholder}
        isValid={!(props.getFieldMeta(name).error && !!touched[name])}
        disabled={disabled}
      />
      {customSublabel ? (
        <ProfileEditInputTextLimit>{customSublabel}</ProfileEditInputTextLimit>
      ) : null}
      {!customSublabel && textLimit && !props.getFieldMeta(name).error ? (
        <ProfileEditInputTextLimit>
          {values[name]?.length}/{textLimit}
        </ProfileEditInputTextLimit>
      ) : null}
      {props.getFieldMeta(name).error && !!touched[name] ? (
        <ProfileEditInputTextErrorContainer>
          <ProfileEditInputTextError>{props.getFieldMeta(name).error}</ProfileEditInputTextError>
        </ProfileEditInputTextErrorContainer>
      ) : null}
    </ProfileEditInputTextWrapper>
  );
}
