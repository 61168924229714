import React from 'react';

export default function Clock({ width = 16, height = 16, fill = '#171C33' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M7.5 2.375C4.3934 2.375 1.875 4.8934 1.875 8C1.875 11.1066 4.3934 13.625 7.5 13.625C10.6066 13.625 13.125 11.1066 13.125 8C13.125 4.8934 10.6066 2.375 7.5 2.375ZM0.625 8C0.625 4.20304 3.70304 1.125 7.5 1.125C11.297 1.125 14.375 4.20304 14.375 8C14.375 11.797 11.297 14.875 7.5 14.875C3.70304 14.875 0.625 11.797 0.625 8Z" 
        fill={fill}/>
      <path 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 3.625C7.84518 3.625 8.125 3.90482 8.125 4.25V7.61373L10.2795 8.69098C10.5882 8.84535 10.7134 9.22077 10.559 9.52951C10.4046 9.83824 10.0292 9.96339 9.72049 9.80902L7.22049 8.55902C7.00875 8.45315 6.875 8.23673 6.875 8V4.25C6.875 3.90482 7.15482 3.625 7.5 3.625Z"
        fill={fill}/>
    </svg>
  );
}
