import React, { useState } from 'react';

import {
  ClientIcon,
  CompanyIcon,
  IndustryIcon,
  PassionIcon,
  PeopleIcon,
  SkillIcon,
} from 'app/components/DetailsPages/shared/CommonGround/Icons';
import ProfileEditorDrawerAvatar from 'app/components/ProfileEditor/Drawers/Avatar';
import ProfileEditorDrawerIntroduceYourself from 'app/components/ProfileEditor/Drawers/IntroduceYourself';
import ProfileEditorDrawerLanguages from 'app/components/ProfileEditor/Drawers/Languages';
import ProfileEditorDrawerMyRole from 'app/components/ProfileEditor/Drawers/MyRole';
import ProfileEditorDrawerPassions from 'app/components/ProfileEditor/Drawers/Passions';
import ProfileEditorDrawerClients from 'app/components/ProfileEditor/Drawers/PriorExperience/Clients';
import ProfileEditorDrawerCompanies from 'app/components/ProfileEditor/Drawers/PriorExperience/Companies';
import ProfileEditorDrawerIndustries from 'app/components/ProfileEditor/Drawers/PriorExperience/Industries';
import ProfileEditorDrawerSkills from 'app/components/ProfileEditor/Drawers/Skills';
import ProfileEditorDrawerYearsExperience from 'app/components/ProfileEditor/Drawers/YearsExperience';
import { AddBoldIcon, PersonIcon } from 'app/components/shared/icons';
import AddImage from 'app/components/shared/icons/add-image';
import Calendar from 'app/components/shared/icons/calendar';
import EditPencilIcon from 'app/components/shared/icons/edit-pencil';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import useUserData from 'hooks/useUserData';

import {
  AboutKyuOsWayfindingActivateProfileMenuWrapper,
  AboutKyuOsWayfindingActivateProfileMenuCard,
  AboutKyuOsWayfindingActivateProfileMenuCardTitle,
  AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper,
  AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer,
} from './styled';

export const WAYFINDING_DRAWERS = {
  PROFILE_IMAGE: 'profile-image',
  LANGUAGES: 'languages',
  EXPERIENCE: 'experience',
  ROLE: 'role',
  PRIOR_COMPANIES: 'prior-companies',
  PRIOR_INDUSTRIES: 'prior-industries',
  PRIOR_CLIENTS: 'prior-clients',
  SKILLS: 'skills',
  PASSIONS: 'passions',
  BIO: 'bio',
};

export const wayfindingDrawerToTitle = new Map<
  (typeof WAYFINDING_DRAWERS)[keyof typeof WAYFINDING_DRAWERS],
  string
>([
  [WAYFINDING_DRAWERS.PROFILE_IMAGE, 'PROFILE PHOTO'],
  [WAYFINDING_DRAWERS.LANGUAGES, 'LANGUAGES'],
  [WAYFINDING_DRAWERS.EXPERIENCE, 'YEARS OF EXPERIENCE'],
  [WAYFINDING_DRAWERS.ROLE, 'ABOUT YOUR ROLE'],
  [WAYFINDING_DRAWERS.PRIOR_COMPANIES, 'PRIOR COMPANIES'],
  [WAYFINDING_DRAWERS.PRIOR_INDUSTRIES, 'PRIOR INDUSTRIES'],
  [WAYFINDING_DRAWERS.PRIOR_CLIENTS, 'PRIOR CLIENTS'],
  [WAYFINDING_DRAWERS.SKILLS, 'SKILLS'],
  [WAYFINDING_DRAWERS.PASSIONS, 'PASSIONS'],
  [WAYFINDING_DRAWERS.BIO, 'BIO'],
]);

export type WayfindingActiveProfileMenuDrawersType =
  (typeof WAYFINDING_DRAWERS)[keyof typeof WAYFINDING_DRAWERS];

export default function AboutKyuOsWayfindingActivateProfileMenu() {
  const behavioralAction = useBehavioral(BEHAVIORALS.KYU101_PROFILE_EDIT_DRAWER_OPENED);

  const [drawer, setDrawer] = useState<{
    type: WayfindingActiveProfileMenuDrawersType;
    show: boolean;
  }>({
    type: null,
    show: false,
  });

  const handleCardClick = (type: WayfindingActiveProfileMenuDrawersType) => {
    behavioralAction({ action_details: wayfindingDrawerToTitle.get(type) });
    setDrawer({
      type,
      show: true,
    });
  };

  return (
    <AboutKyuOsWayfindingActivateProfileMenuWrapper>
      <AboutKyuOsWayfindingActivateProfileMenuCard
        onClick={() => handleCardClick(WAYFINDING_DRAWERS.PROFILE_IMAGE)}
      >
        <AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
          <AddImage width={24} height={24} fill="var(--color-data-people-foreground)" />
          <AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
            <EditPencilIcon width={16} height={16} fill="rgba(23, 28, 51, 0.5)" />
          </AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
        </AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
        <AboutKyuOsWayfindingActivateProfileMenuCardTitle>
          PROFILE PHOTO
        </AboutKyuOsWayfindingActivateProfileMenuCardTitle>
      </AboutKyuOsWayfindingActivateProfileMenuCard>
      <AboutKyuOsWayfindingActivateProfileMenuCard
        onClick={() => handleCardClick(WAYFINDING_DRAWERS.LANGUAGES)}
      >
        <AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
          <PeopleIcon width={24} height={24} fill="var(--color-data-people-foreground)" />
          <AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
            <AddBoldIcon fill="rgba(23, 28, 51, 0.5)" />
          </AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
        </AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
        <AboutKyuOsWayfindingActivateProfileMenuCardTitle>
          LANGUAGES
        </AboutKyuOsWayfindingActivateProfileMenuCardTitle>
      </AboutKyuOsWayfindingActivateProfileMenuCard>
      <AboutKyuOsWayfindingActivateProfileMenuCard
        onClick={() => handleCardClick(WAYFINDING_DRAWERS.EXPERIENCE)}
      >
        <AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
          <Calendar width={24} height={24} fill="#5478A1" />
          <AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
            <EditPencilIcon width={16} height={16} fill="rgba(23, 28, 51, 0.5)" />
          </AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
        </AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
        <AboutKyuOsWayfindingActivateProfileMenuCardTitle>
          YEARS OF EXPERIENCE
        </AboutKyuOsWayfindingActivateProfileMenuCardTitle>
      </AboutKyuOsWayfindingActivateProfileMenuCard>
      <AboutKyuOsWayfindingActivateProfileMenuCard
        onClick={() => handleCardClick(WAYFINDING_DRAWERS.ROLE)}
      >
        <AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
          <CompanyIcon width={24} height={24} fill="var(--color-data-company-foreground)" />
          <AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
            <EditPencilIcon width={16} height={16} fill="rgba(23, 28, 51, 0.5)" />
          </AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
        </AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
        <AboutKyuOsWayfindingActivateProfileMenuCardTitle>
          ABOUT YOUR ROLE
        </AboutKyuOsWayfindingActivateProfileMenuCardTitle>
      </AboutKyuOsWayfindingActivateProfileMenuCard>
      <AboutKyuOsWayfindingActivateProfileMenuCard
        onClick={() => handleCardClick(WAYFINDING_DRAWERS.PRIOR_COMPANIES)}
      >
        <AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
          <CompanyIcon width={24} height={24} fill="var(--color-data-company-foreground)" />
          <AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
            <AddBoldIcon fill="rgba(23, 28, 51, 0.5)" />
          </AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
        </AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
        <AboutKyuOsWayfindingActivateProfileMenuCardTitle>
          PRIOR COMPANIES
        </AboutKyuOsWayfindingActivateProfileMenuCardTitle>
      </AboutKyuOsWayfindingActivateProfileMenuCard>
      <AboutKyuOsWayfindingActivateProfileMenuCard
        onClick={() => handleCardClick(WAYFINDING_DRAWERS.PRIOR_INDUSTRIES)}
      >
        <AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
          <IndustryIcon width={24} height={24} fill="var(--color-data-industry-foreground)" />
          <AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
            <AddBoldIcon fill="rgba(23, 28, 51, 0.5)" />
          </AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
        </AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
        <AboutKyuOsWayfindingActivateProfileMenuCardTitle>
          PRIOR INDUSTRIES
        </AboutKyuOsWayfindingActivateProfileMenuCardTitle>
      </AboutKyuOsWayfindingActivateProfileMenuCard>
      <AboutKyuOsWayfindingActivateProfileMenuCard
        onClick={() => handleCardClick(WAYFINDING_DRAWERS.PRIOR_CLIENTS)}
      >
        <AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
          <ClientIcon width={24} height={24} fill="var(--color-data-client-foreground)" />
          <AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
            <AddBoldIcon fill="rgba(23, 28, 51, 0.5)" />
          </AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
        </AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
        <AboutKyuOsWayfindingActivateProfileMenuCardTitle>
          PRIOR CLIENTS
        </AboutKyuOsWayfindingActivateProfileMenuCardTitle>
      </AboutKyuOsWayfindingActivateProfileMenuCard>
      <AboutKyuOsWayfindingActivateProfileMenuCard
        onClick={() => handleCardClick(WAYFINDING_DRAWERS.SKILLS)}
      >
        <AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
          <SkillIcon width={24} height={24} fill="var(--color-data-skill-foreground)" />
          <AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
            <AddBoldIcon fill="rgba(23, 28, 51, 0.5)" />
          </AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
        </AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
        <AboutKyuOsWayfindingActivateProfileMenuCardTitle>
          SKILLS
        </AboutKyuOsWayfindingActivateProfileMenuCardTitle>
      </AboutKyuOsWayfindingActivateProfileMenuCard>
      <AboutKyuOsWayfindingActivateProfileMenuCard
        onClick={() => handleCardClick(WAYFINDING_DRAWERS.PASSIONS)}
      >
        <AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
          <PassionIcon width={24} height={24} fill="var(--color-data-passion-foreground)" />
          <AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
            <AddBoldIcon fill="rgba(23, 28, 51, 0.5)" />
          </AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
        </AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
        <AboutKyuOsWayfindingActivateProfileMenuCardTitle>
          PASSIONS
        </AboutKyuOsWayfindingActivateProfileMenuCardTitle>
      </AboutKyuOsWayfindingActivateProfileMenuCard>
      <AboutKyuOsWayfindingActivateProfileMenuCard
        onClick={() => handleCardClick(WAYFINDING_DRAWERS.BIO)}
      >
        <AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
          <PersonIcon width={24} height={24} fill="var(--color-data-people-foreground)" />
          <AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
            <EditPencilIcon width={16} height={16} fill="rgba(23, 28, 51, 0.5)" />
          </AboutKyuOsWayfindingActivateProfileMenuCardCTAIconContainer>
        </AboutKyuOsWayfindingActivateProfileMenuCardIconsWrapper>
        <AboutKyuOsWayfindingActivateProfileMenuCardTitle>
          BIO
        </AboutKyuOsWayfindingActivateProfileMenuCardTitle>
      </AboutKyuOsWayfindingActivateProfileMenuCard>
      <AboutKyuOsWayfindingActivateProfileMenu.Drawers drawer={drawer} setDrawer={setDrawer} />
    </AboutKyuOsWayfindingActivateProfileMenuWrapper>
  );
}

interface AboutKyuOsWayfindingActivateProfileMenuDrawersProps {
  drawer: {
    type: WayfindingActiveProfileMenuDrawersType;
    show: boolean;
  };
  setDrawer: React.Dispatch<
    React.SetStateAction<{
      type: WayfindingActiveProfileMenuDrawersType;
      show: boolean;
    }>
  >;
}

AboutKyuOsWayfindingActivateProfileMenu.Drawers =
  function AboutKyuOsWayfindingActivateProfileMenuDrawers({
    drawer,
    setDrawer,
  }: AboutKyuOsWayfindingActivateProfileMenuDrawersProps) {
    const userData = useUserData();

    const handleDrawerClose = () => {
      setDrawer({
        type: null,
        show: false,
      });
    };

    const wayfindingDrawerTypeToElement = new Map<
      WayfindingActiveProfileMenuDrawersType,
      JSX.Element
    >([
      [WAYFINDING_DRAWERS.PROFILE_IMAGE, <ProfileEditorDrawerAvatar onClose={handleDrawerClose} />],
      [WAYFINDING_DRAWERS.LANGUAGES, <ProfileEditorDrawerLanguages onClose={handleDrawerClose} />],
      [
        WAYFINDING_DRAWERS.EXPERIENCE,
        <ProfileEditorDrawerYearsExperience onClose={handleDrawerClose} />,
      ],
      [WAYFINDING_DRAWERS.ROLE, <ProfileEditorDrawerMyRole onClose={handleDrawerClose} />],
      [
        WAYFINDING_DRAWERS.PRIOR_COMPANIES,
        <ProfileEditorDrawerCompanies
          onClose={handleDrawerClose}
          companyName={userData.kyu_company?.name}
        />,
      ],
      [
        WAYFINDING_DRAWERS.PRIOR_INDUSTRIES,
        <ProfileEditorDrawerIndustries
          onClose={handleDrawerClose}
          companyName={userData.kyu_company?.name}
        />,
      ],
      [
        WAYFINDING_DRAWERS.PRIOR_CLIENTS,
        <ProfileEditorDrawerClients
          onClose={handleDrawerClose}
          companyName={userData.kyu_company?.name}
        />,
      ],
      [WAYFINDING_DRAWERS.SKILLS, <ProfileEditorDrawerSkills onClose={handleDrawerClose} />],
      [WAYFINDING_DRAWERS.PASSIONS, <ProfileEditorDrawerPassions onClose={handleDrawerClose} />],
      [
        WAYFINDING_DRAWERS.BIO,
        <ProfileEditorDrawerIntroduceYourself onClose={handleDrawerClose} />,
      ],
    ]);

    if (!drawer.show) return null;

    return wayfindingDrawerTypeToElement.has(drawer.type)
      ? wayfindingDrawerTypeToElement.get(drawer.type)
      : null;
  };
