import React from 'react';

export default function ThinkPiecesIcon({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0727 6H16.3636C17.267 6 18 6.66004 18 7.47368V14.8421C18 15.4675 17.7241 16.0672 17.2331 16.5094C16.7421 16.9516 16.0762 17.2 15.3818 17.2H13.2V6H14.0727Z"
        fill="#DEDEDE"
      />
      <path
        d="M2.19995 4.30005C2.19995 3.47188 2.87178 2.80005 3.69995 2.80005H12.7C13.5281 2.80005 14.2 3.47188 14.2 4.30005V5.80005V7.00005V14.8C14.2 16.1688 15.2 16.8 16.8 16.8C16 17.2001 15.4 17.2001 15.4 17.2001H4.59995C3.96343 17.2001 3.35298 16.9472 2.9029 16.4971C2.45281 16.047 2.19995 15.4366 2.19995 14.8V4.30005Z"
        fill="white"
      />
      <path
        d="M9.1999 5.80001C9.1999 5.46864 9.46853 5.20001 9.7999 5.20001H10.9999C11.3313 5.20001 11.5999 5.46864 11.5999 5.80001C11.5999 6.13138 11.3313 6.40001 10.9999 6.40001H9.7999C9.46853 6.40001 9.1999 6.13138 9.1999 5.80001Z"
        fill="#DEDEDE"
      />
      <path
        d="M9.1999 8.20001C9.1999 7.86864 9.46853 7.60001 9.7999 7.60001H10.9999C11.3313 7.60001 11.5999 7.86864 11.5999 8.20001C11.5999 8.53138 11.3313 8.80001 10.9999 8.80001H9.7999C9.46853 8.80001 9.1999 8.53138 9.1999 8.20001Z"
        fill="#DEDEDE"
      />
      <path
        d="M4.3999 10.6C4.3999 10.2686 4.66853 10 4.9999 10H10.9999C11.3313 10 11.5999 10.2686 11.5999 10.6C11.5999 10.9314 11.3313 11.2 10.9999 11.2H4.9999C4.66853 11.2 4.3999 10.9314 4.3999 10.6Z"
        fill="#DEDEDE"
      />
      <path
        d="M4.3999 13C4.3999 12.6686 4.66853 12.4 4.9999 12.4H10.9999C11.3313 12.4 11.5999 12.6686 11.5999 13C11.5999 13.3314 11.3313 13.6 10.9999 13.6H4.9999C4.66853 13.6 4.3999 13.3314 4.3999 13Z"
        fill="#DEDEDE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9999 5.20001C4.66853 5.20001 4.3999 5.46864 4.3999 5.80001V8.20001C4.3999 8.53138 4.66853 8.80001 4.9999 8.80001H7.3999C7.73127 8.80001 7.9999 8.53138 7.9999 8.20001V5.80001C7.9999 5.46864 7.73127 5.20001 7.3999 5.20001H4.9999ZM5.5999 6.40001V7.60001H6.7999V6.40001H5.5999Z"
        fill="#DEDEDE"
      />
    </svg>
  );
}
