import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';

import { Sparkle, Spinner } from 'app/components/shared/icons';
import useIsMobile from 'hooks/useIsMobile';

import useOnboardingControls from '../../hooks/useOnboardingControls';
import usePassionData from '../../hooks/usePassionData';
import {
  OnboardingStepFourEmptySpace,
  OnboardingStepFourInformationContainer,
  OnboardingStepFourParagraph,
  OnboardingStepFourParagraphSkeleton,
  OnboardingStepFourPassion,
  OnboardingStepFourPassionAlreadyAdded,
  OnboardingStepFourPassionName,
  OnboardingStepFourPassionSkeleton,
  OnboardingStepFourPassionsButton,
  OnboardingStepFourPassionsButtonText,
  OnboardingStepFourPassionsContainer,
  OnboardingStepFourPassionsSkeletonContainer,
  OnboardingStepFourPassionsTextListItemEkeleton,
  OnboardingStepFourPassionsTextListSkeleton,
  OnboardingStepFourPassionsTitle,
  OnboardingStepFourPassionsWrapper,
  OnboardingStepFourTitle,
  OnboardingStepFourTitleSkeleton,
  OnboardingStepFourWrapper,
} from './styled';

const breakpointColumnsObj = {
  default: 3,
  610: 2,
};

export default function OnboardingStepFour() {
  const { onboardingQuery, currentPassion, hideNextButton, setCurrentPassion, hidePreviousButton } =
    useOnboardingControls();
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    hideNextButton();
  }, []);

  const handlePassionClick = (passion: any) => {
    setCurrentPassion(passion);
  };

  const handleButtonClick = () => {
    if (currentPassion !== null) {
      setShowSkeleton(true);
      hidePreviousButton();
    }
  };

  if (showSkeleton) return <OnboardingStepFourSkeleton />;

  return (
    <OnboardingStepFourWrapper>
      <OnboardingStepFourInformationContainer>
        <OnboardingStepFourTitle>Less Chat, More Action</OnboardingStepFourTitle>
        <OnboardingStepFourParagraph>
          <p>
            What you see here are some of the most activated ✨passions✨ (or interests) that people
            have added to their kyu OS profile.
          </p>
          <p>
            Enriching your profile creates connections and helps create common ground when meeting
            someone new or collaborating across the collective.
          </p>
        </OnboardingStepFourParagraph>
      </OnboardingStepFourInformationContainer>
      <OnboardingStepFourPassionsWrapper>
        <OnboardingStepFourPassionsTitle>
          Select one of these passions to reveal connected people
        </OnboardingStepFourPassionsTitle>
        <OnboardingStepFourPassionsContainer>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            <OnboardingStepFourEmptySpace />
            {onboardingQuery.data?.passions ? (
              <Passion
                data={onboardingQuery.data?.passions[0]}
                selected={currentPassion?.slug === onboardingQuery.data?.passions[0].slug}
                onClick={handlePassionClick}
              />
            ) : null}
            {!isMobile ? <OnboardingStepFourEmptySpace /> : null}
            {onboardingQuery.data?.passions && onboardingQuery.data?.passions.length > 1
              ? onboardingQuery.data?.passions
                  .slice(1)
                  .map((passion, index) => (
                    <Passion
                      key={index}
                      data={passion}
                      selected={currentPassion?.slug === passion.slug}
                      onClick={handlePassionClick}
                    />
                  ))
              : null}
          </Masonry>
        </OnboardingStepFourPassionsContainer>
        <OnboardingStepFourPassionsButton
          disabled={currentPassion === null}
          onClick={handleButtonClick}
        >
          <Sparkle width={24} height={24} fill="#fff" />
          <OnboardingStepFourPassionsButtonText>
            Show me the magic
          </OnboardingStepFourPassionsButtonText>
        </OnboardingStepFourPassionsButton>
      </OnboardingStepFourPassionsWrapper>
    </OnboardingStepFourWrapper>
  );
}

interface PassionProps {
  data: {
    slug: string;
    name: string;
    shared?: boolean;
  };
  selected: boolean;
  onClick: (data: any) => void;
}

function Passion({ data, selected, onClick }: PassionProps) {
  const handleClick = () => {
    onClick(data);
  };

  return (
    <OnboardingStepFourPassion onClick={handleClick} selected={selected}>
      <OnboardingStepFourPassionName>{data.name}</OnboardingStepFourPassionName>
      {data.shared ? (
        <OnboardingStepFourPassionAlreadyAdded>
          Already added to your profile
        </OnboardingStepFourPassionAlreadyAdded>
      ) : null}
    </OnboardingStepFourPassion>
  );
}

function OnboardingStepFourSkeleton() {
  const { goNextStep, currentPassion, setCurrentPassion, onboardingQuery } =
    useOnboardingControls();
  const [canGoNextStep, setCanGoNextStep] = useState<boolean>(false);
  const { data } = usePassionData(currentPassion?.uuid);

  useEffect(() => {
    if (data) {
      setCurrentPassion({ ...currentPassion, ...data });
      goNextStep();
    }
  }, [data, canGoNextStep]);

  const handleAnimationComplete = () => {
    setCanGoNextStep(true);
  };

  return (
    <OnboardingStepFourWrapper>
      <OnboardingStepFourInformationContainer>
        <OnboardingStepFourTitleSkeleton style={{ width: '169px' }} />
        <OnboardingStepFourTitleSkeleton style={{ width: '191px' }} />
        <OnboardingStepFourParagraphSkeleton style={{ width: '290px' }} />
        <OnboardingStepFourParagraphSkeleton style={{ width: '258px' }} />
      </OnboardingStepFourInformationContainer>
      <OnboardingStepFourPassionsWrapper>
        <OnboardingStepFourPassionsTextListSkeleton onAnimationComplete={handleAnimationComplete}>
          <OnboardingStepFourPassionsTextListItemEkeleton>
            gathering pattern-making energies
          </OnboardingStepFourPassionsTextListItemEkeleton>
          <OnboardingStepFourPassionsTextListItemEkeleton>
            calculating degrees of separation
          </OnboardingStepFourPassionsTextListItemEkeleton>
          <OnboardingStepFourPassionsTextListItemEkeleton>
            tallying up Connection Counts
          </OnboardingStepFourPassionsTextListItemEkeleton>
        </OnboardingStepFourPassionsTextListSkeleton>
        <OnboardingStepFourPassionsSkeletonContainer>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            <OnboardingStepFourEmptySpace />
            <OnboardingStepFourPassionSkeleton />
            <OnboardingStepFourEmptySpace />
            {Array.from(Array(onboardingQuery.data.passions.length - 1)).map((_, index) => (
              <OnboardingStepFourPassionSkeleton key={index} />
            ))}
          </Masonry>
        </OnboardingStepFourPassionsSkeletonContainer>
        <OnboardingStepFourPassionsButton loading>
          <Spinner />
        </OnboardingStepFourPassionsButton>
      </OnboardingStepFourPassionsWrapper>
    </OnboardingStepFourWrapper>
  );
}
