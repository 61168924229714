import React from 'react';

export default function RibbonIcon({ width = 25, height = 26, fill = '#ffffff' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24935 8.83333C6.24935 5.38155 9.04757 2.58333 12.4993 2.58333C15.9511 2.58333 18.7493 5.38155 18.7493 8.83333C18.7493 11.0015 17.6453 12.9118 15.9687 14.0328C15.9092 14.0635 15.8533 14.0998 15.8015 14.1407C14.8434 14.7382 13.7117 15.0833 12.4993 15.0833C9.04757 15.0833 6.24935 12.2851 6.24935 8.83333ZM15.6099 16.5668C14.6488 16.9537 13.5989 17.1667 12.4993 17.1667C11.4002 17.1667 10.3507 16.9539 9.38993 16.5672L8.60814 22.4533L11.9634 20.4401C12.2933 20.2422 12.7054 20.2422 13.0353 20.4401L16.3909 22.4535L15.6099 16.5668ZM7.43632 15.4528C5.44813 13.9298 4.16602 11.5313 4.16602 8.83333C4.16602 4.23096 7.89698 0.5 12.4993 0.5C17.1017 0.5 20.8327 4.23096 20.8327 8.83333C20.8327 11.5308 19.5511 13.9289 17.5635 15.4519L18.7403 24.3213C18.7929 24.7178 18.6138 25.1094 18.2795 25.329C17.9452 25.5486 17.5147 25.5573 17.1717 25.3516L12.4993 22.5481L7.82694 25.3516C7.48395 25.5574 7.05341 25.5486 6.71909 25.329C6.38478 25.1093 6.20575 24.7177 6.25841 24.3212L7.43632 15.4528Z"
        fill={fill}
      />
    </svg>
  );
}
