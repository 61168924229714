import React, { useState } from 'react';

import useKyuTeam from 'app/components/CommonStyled/Footer/useKyuTeam';
import PeopleAvatarContainer from 'app/components/CommonStyled/PeopleAvatarContainer';
import SideDrawer from 'app/components/CommonStyled/SideDrawer';
import FeedbackDrawer from 'app/components/ContactDrawers/FeedbackDrawer';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';

import {
  AboutKyuOsContactCardBottomContainer,
  AboutKyuOsContactCardContainer,
  AboutKyuOsContactCardDescription,
  AboutKyuOsContactCardSeparator,
  AboutKyuOsContactCardsWrapper,
  AboutKyuOsContactCardTitle,
  AboutKyuOsContactDescription,
  AboutKyuOsContactInformationContainer,
  AboutKyuOsContactReportButton,
  AboutKyuOsContactReportButtonText,
  AboutKyuOsContactTitle,
  AboutKyuOsContactWrapper,
} from './styled';

export default function AboutKyuOsContact() {
  const contactFormOpenedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_CONTACT_FORM_OPENED);
  const [showReportAnIssue, setShowReportAnIssue] = useState<boolean>(false);

  const { data, isLoading } = useKyuTeam();

  const handleShowReportAnIssue = () => {
    contactFormOpenedBehavioralFunction();
    setShowReportAnIssue(true);
  };

  return (
    <AboutKyuOsContactWrapper id="contact" data-index="1">
      <AboutKyuOsContactInformationContainer>
        <AboutKyuOsContactTitle>Need to get in touch with someone at kyu?</AboutKyuOsContactTitle>
        <AboutKyuOsContactDescription>
          You can always reach out to the kyu team with your questions, suggestions, feedback and
          anything in between.
        </AboutKyuOsContactDescription>
      </AboutKyuOsContactInformationContainer>
      <AboutKyuOsContactCardsWrapper>
        <AboutKyuOsContactCardContainer>
          <AboutKyuOsContactCardTitle>CREATIVE INTELLIGENCE</AboutKyuOsContactCardTitle>
          <AboutKyuOsContactCardSeparator />
          {!isLoading ? (
            <PeopleAvatarContainer
              people={data.intelligence.map((person) => ({
                avatar: person.avatar,
                name: person.first_name,
                lastName: person.last_name,
                role: person.title ?? person.role,
                openDrawer: true,
                slug: person.slug,
              }))}
              size={32}
            />
          ) : null}
          <AboutKyuOsContactCardBottomContainer>
            <AboutKyuOsContactCardDescription>
              Inspired by what you see and want to contribute to kyu OS?
            </AboutKyuOsContactCardDescription>
          </AboutKyuOsContactCardBottomContainer>
        </AboutKyuOsContactCardContainer>
        <AboutKyuOsContactCardContainer>
          <AboutKyuOsContactCardTitle>COMMUNITY AND EVENTS</AboutKyuOsContactCardTitle>
          <AboutKyuOsContactCardSeparator />
          {!isLoading ? (
            <PeopleAvatarContainer
              people={data.communities.map((person) => ({
                avatar: person.avatar,
                name: person.first_name,
                lastName: person.last_name,
                role: person.title ?? person.role,
                openDrawer: true,
                slug: person.slug,
              }))}
              size={32}
            />
          ) : null}
          <AboutKyuOsContactCardBottomContainer>
            <AboutKyuOsContactCardDescription>
              Get the ball rolling and vet interest in your idea with others on Slack.
            </AboutKyuOsContactCardDescription>
          </AboutKyuOsContactCardBottomContainer>
        </AboutKyuOsContactCardContainer>
        <AboutKyuOsContactCardContainer>
          <AboutKyuOsContactCardTitle>STORYTELLING</AboutKyuOsContactCardTitle>
          <AboutKyuOsContactCardSeparator />
          {!isLoading ? (
            <PeopleAvatarContainer
              people={data.storytelling.map((person) => ({
                avatar: person.avatar,
                name: person.first_name,
                lastName: person.last_name,
                role: person.title ?? person.role,
                openDrawer: true,
                slug: person.slug,
              }))}
              size={32}
            />
          ) : null}
          <AboutKyuOsContactCardBottomContainer>
            <AboutKyuOsContactCardDescription>
              We need your voice. Tell us about an event, learning, or project journey.
            </AboutKyuOsContactCardDescription>
          </AboutKyuOsContactCardBottomContainer>
        </AboutKyuOsContactCardContainer>
        <AboutKyuOsContactCardContainer>
          <AboutKyuOsContactCardTitle>BUSINESS DEVELOPMENT</AboutKyuOsContactCardTitle>
          <AboutKyuOsContactCardSeparator />
          {!isLoading ? (
            <PeopleAvatarContainer
              people={data.business_development.map((person) => ({
                avatar: person.avatar,
                name: person.first_name,
                lastName: person.last_name,
                role: person.title ?? person.role,
                openDrawer: true,
                slug: person.slug,
              }))}
              size={32}
            />
          ) : null}
          <AboutKyuOsContactCardBottomContainer>
            <AboutKyuOsContactCardDescription>
              We need your voice. Tell us about an event, learning, or project journey.
            </AboutKyuOsContactCardDescription>
          </AboutKyuOsContactCardBottomContainer>
        </AboutKyuOsContactCardContainer>
      </AboutKyuOsContactCardsWrapper>
      <AboutKyuOsContactReportButton onClick={handleShowReportAnIssue}>
        <AboutKyuOsContactReportButtonText>GET IN TOUCH</AboutKyuOsContactReportButtonText>
      </AboutKyuOsContactReportButton>
      {showReportAnIssue ? (
        <SideDrawer closeDrawer={() => setShowReportAnIssue(false)} withClose>
          <FeedbackDrawer />
        </SideDrawer>
      ) : null}
    </AboutKyuOsContactWrapper>
  );
}
