import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getKyu101 } from 'services/kyu101';

export default function useKyu101(
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    [`kyu-101`],
    () =>
      getKyu101().then((response) => {
        if (!response.ok) {
          throw new Error(response.originalError?.message);
        }

        return response.data;
      }),
    {
      ...options,
      cacheTime: 600000,
      retry: false,
      staleTime: 0,
    }
  );
}
