export const theme = {
  light: {
    primaryBackground:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.108) 0%, rgba(255, 194, 41, 0.106) 100%), #FFFFFF',
    primaryText: 'var(--color-primary)',
    primaryIcon: 'var(--color-primary)',
    getInTouchText: 'var(--color-primary)',
    thingsInCommonBackground: 'var(--color-data-people-background-secondary)',
    thingsInCommonBackgroundHover: 'rgba(255, 194, 41, 0.25)',
    thingsInCommonText: 'var(--color-primary)',
  },
  dark: {
    primaryBackground: '#1C223F',
    primaryText: '#FFFFFF',
    primaryIcon: '#FFFFFF',
    getInTouchText: 'var(--color-primary)',
    thingsInCommonBackground: 'var(--color-data-people-dark-theme-background)',
    thingsInCommonText: '#FFFFFF',
  },
};
