import React from 'react';

export default function HeartIcon({
  width = 16,
  height = 16,
  fill = '#D47563',
  borderColor = '#D47563',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1253 1.80078C12.2056 1.802 13.2413 2.2317 14.0052 2.9956C14.7691 3.7595 15.1988 4.79523 15.2 5.87556C15.2 8.15664 13.5159 10.1877 11.8538 11.6433C10.1812 13.1081 8.47539 14.0383 8.3305 14.115C8.22887 14.1695 8.11535 14.198 8.00005 14.198C7.88453 14.198 7.7708 14.1694 7.66902 14.1147C7.52024 14.0359 5.81669 13.1062 4.14625 11.6433C2.48417 10.1877 0.800049 8.15687 0.800049 5.87578C0.801267 4.79546 1.23096 3.7595 1.99487 2.9956C2.75877 2.2317 3.7945 1.802 4.87482 1.80078C6.13109 1.80078 7.25061 2.30472 8.00005 3.17501C8.74949 2.30472 9.869 1.80078 11.1253 1.80078ZM8.12856 3.33255C8.08429 3.38499 8.04143 3.43879 8.00005 3.49391C7.95866 3.43879 7.91581 3.38499 7.87154 3.33255C7.16233 2.49248 6.08984 2.00078 4.87505 2.00078C3.84769 2.00194 2.86274 2.41057 2.13629 3.13702C1.40984 3.86347 1.00121 4.84842 1.00005 5.87578C1.00005 10.2508 7.48692 13.792 7.76317 13.9383C7.83598 13.9774 7.91737 13.998 8.00005 13.998C8.08273 13.998 8.16411 13.9774 8.23692 13.9383C8.51317 13.792 15 10.2508 15 5.87578C14.9989 4.84842 14.5903 3.86347 13.8638 3.13702C13.1374 2.41057 12.1524 2.00194 11.125 2.00078C9.91025 2.00078 8.83776 2.49248 8.12856 3.33255Z"
        fill={borderColor}
      />
      <path
        d="M8.12856 3.33255C8.08429 3.38499 8.04143 3.43879 8.00005 3.49391C7.95866 3.43879 7.91581 3.38499 7.87154 3.33255C7.16233 2.49248 6.08984 2.00078 4.87505 2.00078C3.84769 2.00194 2.86274 2.41057 2.13629 3.13702C1.40984 3.86347 1.00121 4.84842 1.00005 5.87578C1.00005 10.2508 7.48692 13.792 7.76317 13.9383C7.83598 13.9774 7.91737 13.998 8.00005 13.998C8.08273 13.998 8.16411 13.9774 8.23692 13.9383C8.51317 13.792 15 10.2508 15 5.87578C14.9989 4.84842 14.5903 3.86347 13.8638 3.13702C13.1374 2.41057 12.1524 2.00194 11.125 2.00078C9.91025 2.00078 8.83776 2.49248 8.12856 3.33255Z"
        fill={fill}
      />
    </svg>
  );
}