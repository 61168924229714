import React from 'react';

export default function MouseIcon({ width = 24, height = 40 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="22"
        height="38"
        rx="11"
        stroke="#171C33"
        strokeOpacity="0.5"
        strokeWidth="2"
      />
      <rect x="11" y="9" width="2" height="8" rx="1" fill="#171C33" fillOpacity="0.5" />
    </svg>
  );
}
