import styled from 'styled-components/macro';
import { shimmerAnimation } from 'styles/animations';

interface SkeletonItemProps {
  animated?: boolean;
}

export const SkeletonItem = styled.div<SkeletonItemProps>`
  background: #eaeaea;

  ${({ animated = true }) => animated && shimmerAnimation()}
  ${({ animated = true }) =>
    animated &&
    `
    background: linear-gradient(
      to right,
      rgba(173, 173, 173, 0.3) 4%,
      rgba(173, 173, 173, 0.5) 25%,
      rgba(173, 173, 173, 0.3) 36%
    );
    background-size: 500px 100%;
  `}
`;

export const SkeletonBackground = styled.div`
  background-color: #f7f7f7;
`;

interface RefProps {
  fitContent?: boolean;
}

export const Ref = styled.div<RefProps>`
  width: ${({ fitContent }) => (fitContent ? 'fit-content' : '100%')};
`;
