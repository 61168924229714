import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const ProgressBarWrapper = styled.div`
  max-height: 44px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > * {
    z-index: 2;
  }

  background: ${({ theme }) => theme.background};
  border-radius: 8px;
  padding: 4px 8px;

  &:before {
    content: '';
    width: ${({ theme }) => theme.progress}%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    border-radius: ${({ theme }) => (theme.progress !== 100 ? '8px 0px 0px 8px' : '8px')};

    background: ${({ theme }) => theme.progressBackground};
    transition: 800ms all ease;

    z-index: 1;
  }

  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);

  ${BREAKPOINTS.mobile} {
    padding: 4px 6px 4px 4px;
  }

  position: relative;
  cursor: pointer;
`;

export const ProgressBarInformationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 6px;
  }
`;

export const ProgressBarCounterContainer = styled.div`
  width: fit-content;
  height: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.5);
  border-radius: 32px;
  padding: 0px 8px;
`;

export const ProgressBarCounter = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: center;

  ${BREAKPOINTS.mobile} {
    font-size: 12px;
    line-height: 16px;
  }

  color: var(--color-text);
`;

export const ProgressBarInformationContainer = styled.div`
  width: max-content;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ProgressBarInformationProgressText = styled.span`
  max-width: 164px;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;

  ${BREAKPOINTS.mobile} {
    max-width: 115px;
    font-size: 12px;
    line-height: 16px;
  }

  color: rgba(23, 28, 51, 0.5);
`;

export const ProgressBarInformationText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  ${BREAKPOINTS.mobile} {
    font-size: 14px;
    line-height: 18px;
  }

  color: var(--color-text);
`;
