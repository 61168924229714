import React from 'react';

const SearchIconSmall = ({ width = 20, height = 20, fill = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="search-icon-small"
    >
      <path
        d="M9.22723 15.409C12.6414 15.409 15.409 12.6414 15.409 9.22723C15.409 5.8131 12.6414 3.04541 9.22723 3.04541C5.8131 3.04541 3.04541 5.8131 3.04541 9.22723C3.04541 12.6414 5.8131 15.409 9.22723 15.409Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9546 16.9546L13.5933 13.5933"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIconSmall;
