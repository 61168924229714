import styled from 'styled-components';
import {
  appearAnimation,
  disappearAnimation,
  slideFromRightAnimation,
  slideFromTopAnimation,
  slideToRightAnimation,
} from 'styles/animations';
import { BREAKPOINTS } from 'styles/media';

type DrawerContentWrapperProps = {
  triggerClosing?: boolean;
};

export const DrawerOverlay = styled.div<DrawerContentWrapperProps>`
  background: rgba(15, 15, 15, 0.15);
  height: 100svh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999998;
  transition: opacity 0.5s ease;
  ${appearAnimation('1')}
  ${({ triggerClosing }) => (triggerClosing ? disappearAnimation('1') : ``)}
`;

export const DrawerContentWrapper = styled.aside<DrawerContentWrapperProps>`
  ${slideFromRightAnimation()};
  min-height: 100svh;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 520px;
  z-index: 99999999;
  > button.close-button {
    position: absolute;
    right: 24px;
    top: 20px;
    z-index: 999999991;
  }
  ${({ triggerClosing }) => (triggerClosing ? slideToRightAnimation() : ``)}
  ${BREAKPOINTS.tablet} {
    width: 100%;
    z-index: 9999999999;
    > button.close-button {
      > svg {
        height: 18px;
        width: 18px;
      }
    }
  }
`;

export const DrawerConfirmDialogContainer = styled.dialog`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px;

  background: #ffffff;
  border: none;
  border-radius: 8px;

  ${appearAnimation('1')};

  z-index: 99999999999;
`;

export const DrawerConfirmDialogButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const DrawerConfirmDialogText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text);
`;

export const DrawerConfirmDialogButtonText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

export const DrawerConfirmDialogButton = styled.button`
  flex: 1;

  padding: 8px 16px;
  border-radius: 8px;
  background: var(--color-primary);
`;

export const DrawerConfirmDialogButtonCancel = styled(DrawerConfirmDialogButton)`
  background: var(--color-error);
`;
