import React from 'react';

export default function BlinkingCursorIcon({ width = 6, height = 20, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 1L3 19M3 1L5 1M3 1L1 1M3 19L5 19M3 19L1 19" stroke={fill} strokeLinecap="round" />
    </svg>
  );
}
