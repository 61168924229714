import React, { useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';

import ProfileEditInput from 'app/components/CommonStyled/ProfileEditInput';
import { Spinner } from 'app/components/shared/icons';
import { Form, Formik, FormikProps } from 'formik';
import { createCompany } from 'services/profile-editor';
import * as Yup from 'yup';

import {
  ProfileEditorDrawerCompaniesNewCompanyButton,
  ProfileEditorDrawerCompaniesNewCompanyButtonTitle,
  ProfileEditorDrawerCompaniesNewCompanyTitle,
  ProfileEditorDrawerCompaniesNewCompanyWrapper,
} from './styled';

interface ProfileEditorDrawerCompaniesNewCompanyProps {
  onNewCompanySubmitted: (company: any) => void;
}

export default function ProfileEditorDrawerCompaniesNewCompany({
  onNewCompanySubmitted,
}: Readonly<ProfileEditorDrawerCompaniesNewCompanyProps>) {
  const formikRef = useRef<FormikProps<any>>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleCreateClient = async (params) => {
    const name = document.querySelector<HTMLInputElement>('.select-input-collection').value;
    const response = await createCompany({ ...params, name });
    return response.data;
  };

  const { mutate } = useMutation(handleCreateClient, {
    onMutate: () => setIsSubmitting(true),
    onSuccess: (data) => {
      setIsSubmitting(false);
      onNewCompanySubmitted(data);
    },
  });
  const handleSubmit = (values) => {
    if (isSubmitting) return;
    mutate(values);
  };

  const NewCompanyValidationSchema = Yup.object().shape({
    description: Yup.string().required('Company description is required'),
    url: Yup.string().url('Invalid URL').required('Company website is required'),
  });

  return (
    <Formik
      initialValues={{
        description: null,
        url: null,
      }}
      validationSchema={NewCompanyValidationSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {(props) => (
        <Form>
          <ProfileEditorDrawerCompaniesNewCompanyWrapper>
            <ProfileEditorDrawerCompaniesNewCompanyTitle>
              You’re the first one to add this company to their profile. The kyu team will need to
              review it before it’s shown on kyu OS.
            </ProfileEditorDrawerCompaniesNewCompanyTitle>
            <ProfileEditInput
              name="description"
              type="text"
              label="Describe this business in a few words."
              placeholder="Dunder Mifflin Paper Company, Inc. is a paper and office supplies wholesale company."
              customSublabel="This is only for review purposes; it will not be shown on kyu OS."
              {...props}
            />
            <ProfileEditInput
              name="url"
              type="text"
              label="Website"
              placeholder="https://www.company.com"
              {...props}
            />
            <ProfileEditorDrawerCompaniesNewCompanyButton
              disabled={
                !props.isValid ||
                !props.values['description'] ||
                !props.values['url'] ||
                isSubmitting
              }
              type="submit"
              onClick={props.submitForm}
            >
              {!isSubmitting ? (
                <ProfileEditorDrawerCompaniesNewCompanyButtonTitle>
                  Submit for review
                </ProfileEditorDrawerCompaniesNewCompanyButtonTitle>
              ) : (
                <Spinner />
              )}
            </ProfileEditorDrawerCompaniesNewCompanyButton>
          </ProfileEditorDrawerCompaniesNewCompanyWrapper>
        </Form>
      )}
    </Formik>
  );
}
