import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactDOM from "react-dom";
import { ScreenSizeAlertOverlay, ScreenSizeAlertContainer } from "./styled-components";
import useWindowDimensions from "hooks/useWindowDimensions";

const desktopViewPaths = ["/searcha"];

export default function ScreenSizeAlert() {
  const location = useLocation();
  const dimensions = useWindowDimensions();
  const [showScreenSizeAlert, setShowScreenSizeAlert] = useState<boolean>(false);

  useEffect(() => {
    const path = location.pathname;
    const isHome = path === "/";
    const isAlertPath = desktopViewPaths.find((p) => path.startsWith(p)) || path.endsWith("/map");

    if (dimensions && dimensions.width < 1024 && !isHome && isAlertPath) {
      setShowScreenSizeAlert(true);
    } else setShowScreenSizeAlert(false);
  }, [dimensions, location]);

  return showScreenSizeAlert
    ? ReactDOM.createPortal(
        <ScreenSizeAlertOverlay>
          <ScreenSizeAlertContainer>
            <h1>Screen size alert!</h1>
            <p>
              In the future, we’re going to support mobile and smaller screen experiences, for now,
              please{" "}
              <b>use a device of at least the size of a tablet in landscape mode (1024 px)</b>
            </p>
            <p>Thank you.</p>
          </ScreenSizeAlertContainer>
        </ScreenSizeAlertOverlay>,
        document.getElementById("root")
      )
    : null;
}
