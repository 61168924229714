import { SkeletonItem } from 'app/components/DetailsPages/shared/styled';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

interface PeopleDrawerContainerProps {
  showingPersonDrawer?: boolean;
}

export const PeopleDrawerContainer = styled.div<PeopleDrawerContainerProps>`
  height: 100%;
  padding: ${({ showingPersonDrawer }) => (showingPersonDrawer ? 0 : 32)}px;
  background: ${({ theme }) =>
    theme.primaryBackground ||
    'linear-gradient(180deg, rgba(255, 255, 255, 0.162) 17%, rgba(255, 194, 41, 0.159) 100%), #FFFFFF'};
`;

export const PeopleDrawerFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > :not(:last-child) {
    margin-bottom: 16px;
  }
  border-bottom: 1px solid rgba(255, 194, 41, 0.15);
`;

interface PeopleDrawerFilterSkeletonProps {
  width: number;
  height: number;
}

export const PeopleDrawerFilterSkeleton = styled(SkeletonItem)<PeopleDrawerFilterSkeletonProps>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

export const PeopleDrawerFiltersTitle = styled.h3`
  font-weight: 300;
  font-size: 32px;
  line-height: 42px;
  color: ${({ theme }) => theme.primaryText};
`;

export const PeopleDrawerFiltersDescription = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.primaryText};
`;

export const PeopleDrawerFilterOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0px;
  & > :not(:last-child) {
    margin-right: 16px;
    margin-bottom: 16px;
  }

  margin-bottom: 32px;
`;

export const PeopleDrawerFilterOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

interface PeopleDrawerListContainerProps {
  filtersHeight: number;
}

export const PeopleDrawerListContainer = styled.div<PeopleDrawerListContainerProps>`
  height: calc(100% - ${({ filtersHeight }) => filtersHeight}px - 142px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  overflow-y: auto;
  position: relative;
`;

export const PeopleDrawerListSortingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  & > :not(:last-child) {
    margin-right: 8px;
  }

  & > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.secondaryText};
  }

  width: 100%;
  padding-right: 16px;
  margin-bottom: 8px;
  position: relative;
`;

export const PeopleDrawerListSorting = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.primaryText};

  display: flex;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  cursor: pointer;
`;

export const PeopleDrawerListSortingOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  position: absolute;
  right: 0px;
  top: -11px;

  background: #ffffff;

  box-shadow: 5px 0px 20px -10px rgba(50, 63, 93, 0.1);
  border-radius: 16px;
  z-index: 1;
`;

interface PeopleDrawerListSortingOptionProps {
  selected?: boolean;
}

export const PeopleDrawerListSortingOption = styled.span<PeopleDrawerListSortingOptionProps>`
  color: background: #171C3380;
  opacity: ${({ selected }) => (selected ? 0.5 : 1)};
  cursor: pointer;
`;

export const PeopleDrawerPill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  & > :not(:last-child) {
    margin-right: 5px;
  }

  background: rgba(255, 194, 41, 0.15);
  border-radius: 26px;
  cursor: pointer;
`;

export const PeopleDrawerPillText = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #ffc229;
`;

export const PersonDrawerConnectionCount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  & > :not(:last-child) {
    margin-right: 10px;
  }

  width: 24px;
  height: 24px;

  background: rgba(255, 194, 41, 0.15);

  border: 2px solid #ffc229;
  border-radius: 1000px;

  & > span {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffc229;
  }

  position: absolute;
  right: 14px;
`;

interface PeopleDrawerFilterDropdownWrapperProps {
  hasSelection?: boolean;
}

export const PeopleDrawerFilterDropdownWrapper = styled.div<PeopleDrawerFilterDropdownWrapperProps>`
  display: flex;
  align-items: center;

  .button-container {
    .dropdown-title {
      font-weight: ${({ hasSelection }) => (hasSelection ? '700' : '500')};
      font-size: 14px;
      line-height: 18px;

      color: ${({ hasSelection, theme }) => (hasSelection ? '#EB823D' : theme.primaryText)};
    }
  }
`;

export const PeopleDrawerClearAll = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #EB823D;
  cursor: pointer;

  margin
`;

//CONTACT CTA
export const PeopleDrawerContactCTAContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;
