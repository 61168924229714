import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getRadarActiveWork } from 'services/app';

import { ActivityFeedActiveWorkResponse } from '../../interfaces';

export default function useRadarActiveWork(
  options?: Omit<
    UseQueryOptions<
      ActivityFeedActiveWorkResponse,
      Error,
      ActivityFeedActiveWorkResponse,
      QueryKey
    >,
    'queryKey'
  >
) {
  return useQuery<ActivityFeedActiveWorkResponse, Error>(
    `radar-active-work`,
    async () => {
      const response = await getRadarActiveWork();
      if (!response.ok) {
        throw new Error(response.originalError?.message);
      }

      return response.data;
    },
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
