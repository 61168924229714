import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface IState {
  scrollToTop?: boolean;
}

export default function useScrollToTop() {
  const location = useLocation();
  const state: IState = location.state;

  useEffect(() => {
    if (state && state.scrollToTop) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [state]);
}
