import React, { useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import ProfileEditInput from 'app/components/CommonStyled/ProfileEditInput';
import { Form, Formik, FormikProps } from 'formik';
import useUserData from 'hooks/useUserData';
import { postBio } from 'services/profile-editor';
import { setBio } from 'store/reducers/profile-editor/profile-editor-reducer';
import { setUserData } from 'store/reducers/user-reducer';
import * as Yup from 'yup';

import useProfileEditorHumanAtAGlanceDrawer from '../../hooks/useProfileEditorHumanAtAGlanceDrawer';
import ProfileEditorDrawer, { ProfileEditorDrawerRefProps } from '../Drawer';
import { ProfileEditorDrawerTitle } from '../styled';
import { ProfileEditorDrawerIntroduceYourselfWrapper } from './styled';

interface ProfileEditorDrawerIntroduceYourselfProps {
  onClose: () => void;
}

export default function ProfileEditorDrawerIntroduceYourself({
  onClose,
}: ProfileEditorDrawerIntroduceYourselfProps) {
  const dispatch = useDispatch();
  const userData = useUserData();
  const formikRef = useRef<FormikProps<any>>(null);
  const drawerRef = useRef<ProfileEditorDrawerRefProps>(null);
  const { bio } = useProfileEditorHumanAtAGlanceDrawer();

  useEffect(() => {
    if (bio) {
      formikRef.current.setFieldValue('bio', bio);
    }
  }, [bio]);

  const handlePostBio = async (params: any) => {
    const response = await postBio(params);
    if (!response.ok) {
      throw new Error(response.originalError?.message);
    }

    dispatch(setBio(params.value));
    dispatch(setUserData({ ...userData, bio: params.value }));

    return response.data;
  };

  const { mutate } = useMutation(handlePostBio, drawerRef.current?.handleMutation);

  const introduceYourselfValidationSchema = Yup.object().shape({
    bio: Yup.string().max(
      500,
      ({ value }) =>
        `${value.length} characters over the limit. Update your bio to fit within the limit to publish it to your profile!`
    ),
  });

  const handleSubmit = (values) => {
    mutate({ value: values.bio });
  };

  return (
    <Formik
      initialValues={{
        bio,
      }}
      validationSchema={introduceYourselfValidationSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {(props) => (
        <Form>
          <ProfileEditorDrawer
            ref={drawerRef}
            header={<ProfileEditorDrawerIntroduceYourselfHeader />}
            needsSaving={props.dirty}
            onClose={onClose}
          >
            <ProfileEditorDrawerIntroduceYourselfWrapper>
              <ProfileEditInput
                {...props}
                name={'bio'}
                label={
                  'Say we’re getting introduced at a gathering for the first time, how would that go?'
                }
                tooltip={
                  'You have other opportunities to share more about your role - this space helps people get to know the human you, job title aside.'
                }
                type={'text'}
                placeholder={`I'm a creative director by day and a rock climber by night...`}
                textLimit={500}
              />
            </ProfileEditorDrawerIntroduceYourselfWrapper>
          </ProfileEditorDrawer>
        </Form>
      )}
    </Formik>
  );
}

function ProfileEditorDrawerIntroduceYourselfHeader() {
  return <ProfileEditorDrawerTitle>Introduce Yourself</ProfileEditorDrawerTitle>;
}
