import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getPassionsDrawerList } from 'services/app';

export default function usePassionsDrawerList(
  companySlug: string,
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `company-${companySlug}-passions-drawer-list`,
    () => getPassionsDrawerList(companySlug).then((response) => response.data),
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
