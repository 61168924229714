import React from 'react';

export default function WarningIcon({ width = 44, height = 38, fill = '#EB3D3D' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 38L22 0L44 38H0ZM6.9 34H37.1L22 8L6.9 34ZM22 32C22.5667 32 23.042 31.808 23.426 31.424C23.8087 31.0413 24 30.5667 24 30C24 29.4333 23.8087 28.9587 23.426 28.576C23.042 28.192 22.5667 28 22 28C21.4333 28 20.9587 28.192 20.576 28.576C20.192 28.9587 20 29.4333 20 30C20 30.5667 20.192 31.0413 20.576 31.424C20.9587 31.808 21.4333 32 22 32ZM20 26H24V16H20V26Z"
        fill={fill}
      />
    </svg>
  );
}
