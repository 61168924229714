import React from 'react';

export default function EventRecordingsIcon({ width = 18, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.200195 5.19994C0.200195 3.43263 1.63288 1.99994 3.4002 1.99994H14.6002C16.3675 1.99994 17.8002 3.43263 17.8002 5.19994V16.3999C17.8002 18.1672 16.3675 19.5999 14.6002 19.5999H3.4002C1.63288 19.5999 0.200195 18.1672 0.200195 16.3999V5.19994Z"
        fill="#F7F7F7"
      />
      <path
        d="M0.200195 5.0117C0.200195 3.34835 1.63288 1.99994 3.4002 1.99994H14.6002C16.3675 1.99994 17.8002 3.34835 17.8002 5.0117V8.39994H0.200195V5.0117Z"
        fill="#D47563"
      />
      <path
        d="M4.63623 1.20002C4.63623 0.758197 5.02696 0.400024 5.50896 0.400024C5.99095 0.400024 6.38169 0.758197 6.38169 1.20002V3.60002C6.38169 4.04185 5.99095 4.40002 5.50896 4.40002C5.02696 4.40002 4.63623 4.04185 4.63623 3.60002V1.20002Z"
        fill="#ADADAD"
      />
      <path
        d="M11.6191 1.20002C11.6191 0.758197 12.0099 0.400024 12.4919 0.400024C12.9739 0.400024 13.3646 0.758197 13.3646 1.20002V3.60002C13.3646 4.04185 12.9739 4.40002 12.4919 4.40002C12.0099 4.40002 11.6191 4.04185 11.6191 3.60002V1.20002Z"
        fill="#ADADAD"
      />
    </svg>
  );
}
