import React from 'react';
import { useDispatch } from 'react-redux';

import HomeRadarActiveWork from 'app/components/Home/HomeRadar/HomeRadarActiveWork';
import {
  HomeRadarActiveWorkAllClientsButton,
  HomeRadarActiveWorkAllClientsButtonText,
} from 'app/components/Home/HomeRadar/HomeRadarActiveWork/HomeRadarActiveWorkClient/styled';
import {
  HomeRadarActiveWorkAllIndustriesButton,
  HomeRadarActiveWorkAllIndustriesButtonText,
} from 'app/components/Home/HomeRadar/HomeRadarActiveWork/HomeRadarActiveWorkIndustry/styled';
import { RightArrow } from 'app/components/shared/icons';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import { setHomeDrawer } from 'store/reducers/global/global-reducer';

import {
  AboutKyuOsNetworkActiveWorkDescription,
  AboutKyuOsNetworkActiveWorkWrapper,
} from './styled';

export default function AboutKyuOsNetworkActiveWork() {
  return (
    <AboutKyuOsNetworkActiveWorkWrapper>
      <AboutKyuOsNetworkActiveWorkDescription>
        Alternatively, you can use the overview of active work on kyu OS to identify who you might
        learn from or partner with.
      </AboutKyuOsNetworkActiveWorkDescription>
      <HomeRadarActiveWork
        limit={3}
        clientComponent={<AboutKyuOsNetworkActiveWork.ClientCTA />}
        industryComponent={<AboutKyuOsNetworkActiveWork.IndustryCTA />}
      />
    </AboutKyuOsNetworkActiveWorkWrapper>
  );
}

AboutKyuOsNetworkActiveWork.ClientCTA = function AboutKyuOsNetworkActiveWorkClientCTA() {
  const clientsDrawerOpenedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_CLIENTS_DRAWER_OPENED
  );
  const dispatch = useDispatch();

  const handleAllClientsClick = () => {
    clientsDrawerOpenedBehavioralFunction();
    dispatch(setHomeDrawer({ show: true, model: 'clients' }));
  };

  return (
    <HomeRadarActiveWorkAllClientsButton onClick={handleAllClientsClick}>
      <HomeRadarActiveWorkAllClientsButtonText>
        See all clients
      </HomeRadarActiveWorkAllClientsButtonText>
      <RightArrow width={16} height={16} fill="var(--color-primary)" />
    </HomeRadarActiveWorkAllClientsButton>
  );
};

AboutKyuOsNetworkActiveWork.IndustryCTA = function AboutKyuOsNetworkActiveWorkIndustryCTA() {
  const industriesDrawerOpenedBehavioralFunction = useBehavioral(
    BEHAVIORALS.KYU101_GLOBAL_INDUSTRIES_DRAWER_OPENED
  );
  const dispatch = useDispatch();

  const handleAllIndustriesClick = () => {
    industriesDrawerOpenedBehavioralFunction();
    dispatch(setHomeDrawer({ show: true, model: 'industries' }));
  };

  return (
    <HomeRadarActiveWorkAllIndustriesButton onClick={handleAllIndustriesClick}>
      <HomeRadarActiveWorkAllIndustriesButtonText>
        See all industries
      </HomeRadarActiveWorkAllIndustriesButtonText>
      <RightArrow width={16} height={16} fill="var(--color-primary)" />
    </HomeRadarActiveWorkAllIndustriesButton>
  );
};
