// Result page highlight for kyu skill
const kyu_skill_highlights = ({
  people_count,
  kyu_companies,
  kyu_companies_count,
  kyu_skill,
  skills_count,
}) => {
  const first_paragraph = `<p><span class="highlight-people">${people_count} ${
    people_count > 1 ? "people" : "person"
  }</span> from <span class="highlight-kyu-company">\
                    ${kyu_companies_count} ${
    kyu_companies_count > 1 ? "companies" : "company"
  }</span> added this skill group to their profiles.</p>`;

  let second_paragaph =
    "<p>Noticeably, this skill group is popular with people that work at ";

  if (people_count > 1) {
    let kyu_companies_array = kyu_companies;
    const companies_array = kyu_companies_array.map((company) => {
      return `<a href="/member-company/${company.slug}"><span class="highlight-kyu-company">${company.name}</span></a>`;
    });

    second_paragaph += `${companies_array
      .slice(0, -1)
      .join(", ")} and <a href="/member-company/${
      kyu_companies_array[kyu_companies_array.length - 1].slug
    }"><span class="highlight-kyu-company">${
      kyu_companies_array[kyu_companies_array.length - 1].name
    }</span></a>.</p>`;
  } else {
    const companies_array = kyu_companies.map((kyu_company) => {
      return `<a href="/member-company/${kyu_company.slug}"><span class="highlight-kyu-company">${kyu_company.name}</span></a>`;
    });

    second_paragaph += `${companies_array[0]}.</p>`;
  }

  const third_paragraph = `<p>Specifically within <span class="highlight-skills">${kyu_skill}</span>, there are \
                    ${skills_count} related skills from the different companies.</p>`;

  return first_paragraph + second_paragaph + third_paragraph;
};

const skill_highlights = ({
  skills_count,
  people_count,
  kyu_skill,
  kyu_company,
}) => {
  let result;

  if (people_count > 0) {
    result = `<span class="highlight-people">${people_count} ${
      people_count > 1 ? "people" : "person"
    }</span> from <span class="highlight-kyu-company"> \
              ${kyu_company}</span> are linked to this skill and is part of the \
              <span class="highlight-skills">${kyu_skill}</span> skill cluster with \
              ${skills_count} similar skills.`;
  } else {
    result = `<span class="highlight-skills">${kyu_skill}</span> is an <span class="highlight-kyu-company">"\
              "${kyu_company}</span> company skill.`;
  }
  return result;
};

export { kyu_skill_highlights, skill_highlights };
