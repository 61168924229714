import styled from 'styled-components';

export const ProfileEditorDrawerExperienceWrapper = styled.div`
  padding: 187px 36px;
`;

export const ProfileEditorDrawerExperienceTitleContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 40px;
  }

  position: relative;
`;

export const ProfileEditorDrawerExperienceTitleIconContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfileEditorDrawerExperienceTitle = styled.h4`
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
`;

export const ProfileEditorDrawerExperienceDataPointsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  margin: 24px 0px 41px 0px;
`;

export const ProfileEditorDrawerExperienceAboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  background: linear-gradient(180deg, rgba(255, 210, 95, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;

  margin-top: 78px;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const ProfileEditorDrawerExperienceAboutText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-text);

`;

// DATA POINT
interface ProfileEditorDrawerExperienceDataPointWrapperProps {
  showContent: boolean;
}

export const ProfileEditorDrawerExperienceDataPointWrapper = styled.div<ProfileEditorDrawerExperienceDataPointWrapperProps>`
  width: 450px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 30px;
  background: #FFFFFF;

  box-shadow: 0px 0px 20px 5px rgba(50,63,93,0.1);
  border-radius: 10px;

  overflow: hidden;
  transition: max-height 200ms ease;
  max-height: ${({ showContent }) => showContent ? '221px' : '72px'};
`;

export const ProfileEditorDrawerExperienceDataPointTopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

export const ProfileEditorDrawerExperienceDataPointContentContainer = styled.div`
  width: 100%;
  transition: max-height 200ms ease-in-out;
  overflow: hidden;

  margin-top: 16px;
`;

export const ProfileEditorDrawerExperienceDataPointTitle = styled.h5`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--color-text);

  & > span {
    font-weight: 700;
  }
`;

export const ProfileEditorDrawerExperienceDataPointInputLabel = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
`;

export const ProfileEditorDrawerExperienceDataPointInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 10px;

  width: 100%;

  background: rgba(173, 173, 173, 0.08);
  border-radius: 8px;

  margin-top: 16px;
`;

export const ProfileEditorDrawerExperienceDataPointInputFieldContainer = styled.div`
  & > :not(:first-child) {
    margin-top: 16px;
  }
`;

export const ProfileEditorDrawerExperiencePillsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  margin-top: 31px;
`;

export const ProfileEditorDrawerExperiencePill = styled.div`
  width: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;

  min-height: 40px;

  background: var(--color-data-people-background);
  border-radius: 50px;

  transition: background 200ms ease-in;

  &:hover {
    background: var(--color-data-people-background-hover);
    padding: 8px 16px 8px 24px;
    & > div:last-child {
      display: flex;
    }
  }

  & > div:last-child {
    display: none;
  }
`;

export const ProfileEditorDrawerExperiencePillIconContainer = styled.div`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 8px;

  cursor: pointer;
`;

export const ProfileEditorDrawerExperiencePillTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProfileEditorDrawerExperiencePillText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: var(--color-text);
`;

export const ProfileEditorDrawerExperienceNeedsApproval = styled.span`
  align-items: center;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    color: #A26565;
    margin-left: 3px;
  }
`;

