import {
  client_company_kyu_company_highlights,
  client_company_highlights,
} from "./client-company";
import { kyu_skill_highlights, skill_highlights } from "./kyu-skill";
import { passion_highlights, passion_kyu_company_highlights } from "./passion";

import {
  known_group_highlights,
  known_group_passions_highlights,
  known_group_kyu_skill_highlights,
  known_group_kyu_company_highlights,
  known_group_project_highlights,
} from "./known-group";

import {
  person_client_company_highlights,
  person_project_highlights,
  person_passion_highlights,
  person_kyu_skill_highlights,
} from "./person";

import {
  madlibs_highlights,
  madlib_kyu_company_highlights,
  madlib_passion_highlights,
  madlib_kyu_skill_highlights,
} from "./madlibs";

import {
  industry_highlights,
  industry_kyu_company_highlights,
} from "./industry";

import {
  project_highlights,
  project_client_company_highlights,
} from "./project";

import {
  kyu_company_impact_highlight,
  kyu_company_people_highlight,
  kyu_company_skill_highlight,
  kyu_company_project_highlight,
  kyu_company_client_highlight,
  kyu_company_passion_highlight,
  kyu_company_collaborative_projects_highlight,
} from "./kyu-company";

export const resultPageHighlights = (type, data) => {
  switch (type) {
    case "passions":
      return passion_highlights({ ...data });
    case "companies":
      return client_company_highlights({ ...data });
    case "client_companies":
      return client_company_highlights({ ...data });
    case "kyu_skills":
      return kyu_skill_highlights({ ...data });
    case "industries":
      return industry_highlights({ ...data });
    case "projects":
      return project_highlights({ ...data });
    case "kyu_companies_people":
      return kyu_company_people_highlight({ ...data });
    case "kyu_company_impact":
      return kyu_company_impact_highlight({ ...data });
    case "kyu_company_collaborative_projects":
      if (data.companyName) {
        return `<p>We don't know of any projects that ${data.companyName} has done alongside other companies in the collective... yet.</p>`;
      } else {
        return kyu_company_collaborative_projects_highlight({ ...data });
      }
    case "known-group":
      return known_group_highlights({
        shared_clients_count: data.shared_clients_count,
        shared_passions_count: data.shared_passions_count,
        shared_skills_count: data.shared_skills_count,
        uniq_clients_count: data.uniq_clients_count,
        uniq_passions_count: data.uniq_passions_count,
        uniq_skills_count: data.uniq_skills_count,
      });
    case "multiple":
      return madlibs_highlights({
        ...data,
      });
    default:
      return;
  }
};

const listNames = (names) => {
  const namesArray =
    names && Array.isArray(names)
      ? names.map((n) => `<strong>${n}</strong>`)
      : [];
  const namesExceptingLastOne = namesArray
    .slice(0, namesArray.length - 1)
    .join(", ");
  return `${namesExceptingLastOne} and <strong>${
    namesArray[namesArray.length - 1]
  }</strong>.`;
};

export const getResultPageTitle = (type, name) => {
  const names = {
    passions: `${name}`,
    people: `Get to know <strong>${name}</strong>`,
    client_companies: `Here's what we found about the client <strong>${name}</strong>`,
    kyu_skills: `Here's what we found about the skill <strong>${name}</strong>`,
    industries: `Here's what we found on <strong>${name}</strong>`,
    kyu_companies: `Here's what we found on <strong>${name}</strong>`,
    multiple: madlibsName(
      name.passions,
      name.kyu_skills,
      name.kyu_companies_for_filtering
    ),
    projects: `Get to know <strong>${name}</strong>`,
    "known-group": `Here's what we found about ${listNames(name)}`,
  };
  return names[type];
};

export const nodeCardsHighlights = (type, graphModel, data) => {
  switch (type) {
    case "passions":
      if (graphModel === "kyu_companies") {
        return kyu_company_passion_highlight({ ...data });
      }

      if (graphModel === "passions") {
        return passion_highlights({
          kyu_companies_count: data.kyu_companies_count,
          kyu_companies: data.kyu_companies,
          people_count: data.people_count,
        });
      }

      if (graphModel === "people") {
        return person_passion_highlights({
          kyu_companies_count: data.kyu_companies_count,
          people_count: data.people_count,
        });
      }

      if (graphModel === "known-group" || graphModel === "projects") {
        return known_group_passions_highlights({ ...data });
      }

      if (graphModel === "multiple") {
        return madlib_passion_highlights({
          ...data,
        });
      }

      return;
      break;

    case "project":
      if (graphModel === "kyu_companies") {
        return kyu_company_project_highlight({ ...data });
      }

      if (graphModel === "people") {
        return person_project_highlights({
          client_name: data.client_name,
          end_date: data.end_date,
          person_first_name: data.person_first_name,
          project_name: data.project_name,
          start_date: data.start_date,
        });
      }

      if (graphModel === "known-group") {
        return known_group_project_highlights({ ...data });
      }

      if (graphModel === "projects") {
        return project_highlights({ ...data });
      }

      return;
      break;

    case "companies":
      if (graphModel === "kyu_companies") {
        return kyu_company_client_highlight({ ...data });
      }

      if (graphModel === "client_companies") {
        return client_company_highlights({ ...data });
      }

      if (graphModel === "people") {
        return person_client_company_highlights({
          client_name: data.client_name,
          person_first_name: data.person_first_name,
          projects_count: data.projects_count,
          start_date: data.start_date,
        });
      }

      if (graphModel === "projects") {
        return project_client_company_highlights({ ...data });
      }

      break;

    case "skill":
      if (graphModel === "kyu_skills") {
        return skill_highlights({
          skills_count: data.skills_count,
          people_count: data.people_count,
          kyu_skill: data.kyu_skill,
          kyu_company: data.kyu_company,
        });
      }

      if (graphModel === "kyu_companies") {
        return kyu_company_skill_highlight({ ...data });
      }

      break;

    case "kyu_skill":
      if (graphModel === "kyu_skills") {
        return kyu_skill_highlights({ ...data });
      }

      if (graphModel === "kyu_company") {
        return kyu_company_skill_highlight({ ...data });
      }

      if (graphModel === "known-group" || graphModel === "projects") {
        return known_group_kyu_skill_highlights({ ...data });
      }

      if (graphModel === "people") {
        return person_kyu_skill_highlights({
          kyu_companies_count: data.kyu_companies_count,
          people_count: data.people_count,
        });
      }

      if (graphModel === "multiple") {
        return madlib_kyu_skill_highlights({
          ...data,
        });
      }

      break;

    case "kyu_company":
      if (graphModel === "kyu_companies") {
        return kyu_company_people_highlight({ ...data });
      }

      if (graphModel === "client_companies") {
        return client_company_kyu_company_highlights({
          client_company: data.client_company,
          kyu_company: data.kyu_company,
          people_count: data.people_count,
          projects_count: data.projects_count,
          projects_length: data.projects_length,
        });
      }

      if (graphModel === "known-group" || graphModel === "projects") {
        return known_group_kyu_company_highlights({ ...data });
      }

      if (graphModel === "passions") {
        return passion_kyu_company_highlights({
          kyu_companies_count: data.kyu_companies_count,
          kyu_companies: data.kyu_company,
          kyu_company_people_count: data.kyu_company_people_count,
          passion: data.passion,
          people_count: data.people_count,
        });
      }

      if (graphModel === "industries") {
        return industry_kyu_company_highlights({ ...data });
      }

      if (graphModel === "multiple") {
        return madlib_kyu_company_highlights({
          ...data,
        });
      }

      break;
    default:
      break;
  }
};

const madlibsName = (passions, kyu_skills, kyu_companies_for_filtering) => {
  if (!passions) {
    passions = [];
  }

  if (!kyu_skills) {
    kyu_skills = [];
  }

  let first_sentence = "Here's who we found ";
  if (passions.length > 0) {
    let passions_array = passions.map(
      (passion) => `<strong>${passion}</strong>`
    );
    let passion_sentence = "passionate about ";
    if (passions_array.length > 1) {
      passion_sentence += `${passions_array
        .slice(0, passions_array.length - 1)
        .join(", ")} and ${passions_array[passions_array.length - 1]}, `;
    } else {
      passion_sentence += `${passions_array[0]}, `;
    }
    first_sentence += passion_sentence;
  }

  if (kyu_skills && kyu_skills.length > 0) {
    let skills_array = kyu_skills.map((skill) => `<strong>${skill}</strong>`);

    let skill_sentence = "";
    passions.length === 0
      ? (skill_sentence = "skilled in ")
      : (skill_sentence = "with skills in ");

    if (skills_array.length > 1) {
      skill_sentence += `${skills_array
        .slice(0, skills_array.length - 1)
        .join(", ")} and ${skills_array[skills_array.length - 1]}, `;
    } else {
      skill_sentence += `${skills_array[0]}, `;
    }
    first_sentence += skill_sentence;
  }

  if (kyu_companies_for_filtering && kyu_companies_for_filtering.length > 0) {
    let companies_array = kyu_companies_for_filtering.map(
      (company) => `<strong>${company}</strong>`
    );

    let company_sentence = "at ";

    if (companies_array.length > 1) {
      company_sentence += `${companies_array
        .slice(0, companies_array.length - 1)
        .join(", ")} or ${companies_array[companies_array.length - 1]}.`;
    } else {
      company_sentence += `${companies_array[0]}.`;
    }

    first_sentence += company_sentence;
  } else {
    first_sentence += "throughout the <strong>Collective.</strong>";
  }

  return first_sentence;
};
