import React from "react";

export default function BreadcrumbArrow() {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.47142 4.52925C5.73177 4.7896 5.73177 5.21171 5.47142 5.47206L1.47142 9.47206C1.21108 9.73241 0.788965 9.73241 0.528616 9.47206C0.268266 9.21171 0.268266 8.7896 0.528616 8.52925L4.05721 5.00065L0.528616 1.47206C0.268267 1.21171 0.268267 0.789596 0.528616 0.529246C0.788966 0.268897 1.21108 0.268897 1.47143 0.529246L5.47142 4.52925Z"
      />
    </svg>
  );
}
