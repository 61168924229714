import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "results",
  initialState: {
    searchInputPills: [],
  },
  reducers: {
    setSearchInputPills: (state, action: { payload: any[] }) => ({
      ...state,
      searchInputPills: action.payload,
    }),
  },
});

export const { setSearchInputPills } = slice.actions;

export const searchInputPillsSelector = (state) =>
  state.results.searchInputPills;

export default slice.reducer;
