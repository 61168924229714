import React, { useMemo, useState } from 'react';

import useKyuTeam from 'app/components/CommonStyled/Footer/useKyuTeam';
import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import SideDrawer from 'app/components/CommonStyled/SideDrawer';
import { LocationIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import GlobalPersonDrawer from 'app/components/Drawers/GlobalPerson/GlobalPersonDrawer';
import { InfoIcon, PersonIcon, SearchIconSmall, Sparkle } from 'app/components/shared/icons';
import Clock from 'app/components/shared/icons/clock';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import useIsMobile from 'hooks/useIsMobile';

import Collapsible from '../Collapsible';
import {
  AboutKyuOsExternalContainer,
  AboutKyuOsExternalDot,
  AboutKyuOsExternalTitle,
  AboutKyuOsExternalTitleContainer,
  AboutKyuOsExternalWrapper,
  AboutKyuOsFAQLink,
  AboutKyuOsFAQParagraph,
  AboutKyuOsFAQTitle,
  AboutKyuOsFAQTitleContainer,
  AboutKyuOsFAQWrapper,
  AboutKyuOsTeamMemberContainer,
  AboutKyuOsTeamMemberInformationContainer,
  AboutKyuOsTeamMemberName,
  AboutKyuOsTeamMemberRole,
  AboutKyuOsTeamWrapper,
  AboutKyuOsTrackContainer,
  AboutKyuOsTrackListItemContainer,
  AboutKyuOsTrackListItemText,
  AboutKyuOsTrackPersonIconContainer,
  AboutKyuOsTrackTitle,
  AboutKyuOsTrackWrapper,
} from './styled';

export default function AboutKyuOsFAQ() {
  const faqClickedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_FAQ_CLICKED);

  const handleCollapsibleBehavioral = () => {
    faqClickedBehavioralFunction();
  };

  return (
    <AboutKyuOsFAQWrapper id="faq" data-index="3">
      <AboutKyuOsFAQTitleContainer>
        <Sparkle width={40} height={40} fill="var(--color-primary)" />
        <AboutKyuOsFAQTitle>Frequently Asked Questions</AboutKyuOsFAQTitle>
      </AboutKyuOsFAQTitleContainer>
      <AboutKyuOsFAQWhoWorksOnKyu />
      <Collapsible
        title={'Where does the OS in kyu OS come from?'}
        behavioralOnExpand={handleCollapsibleBehavioral}
      >
        <AboutKyuOsFAQParagraph>
          Our collective of 23 member companies encompasses a lot of information, experiences and
          (busy) people.
        </AboutKyuOsFAQParagraph>
        <AboutKyuOsFAQParagraph>
          Navigating and engaging with the Collective can be daunting.
        </AboutKyuOsFAQParagraph>
        <AboutKyuOsFAQParagraph>
          An <b>operating system</b> allows component parts to work together. In the same way, kyu
          OS is the point of connection between the ‘pieces’ of the Collective and the people who
          power it.
        </AboutKyuOsFAQParagraph>
      </Collapsible>
      <Collapsible
        title={'Where does all the data come from?'}
        behavioralOnExpand={handleCollapsibleBehavioral}
      >
        <AboutKyuOsFAQParagraph>
          kyu OS is powered by data. On a monthly basis, ‘Data Champions’ in each member company
          pull together lists of the latest hires, alumni, projects, clients and more. While company
          data isn’t quite “real time” just yet, it’s an accurate representation of what’s going on
          at each member company.
        </AboutKyuOsFAQParagraph>
        <AboutKyuOsFAQParagraph>
          Your profile is also a source of rich information. When you add skills, passions and prior
          experience to your profile, this connects you to others that share those interests and
          topics, and all of this is visualized on member company’s page, and throughout kyu OS.
          Every individual across the Collective can update their kyu Profile at anytime on kyu OS.
        </AboutKyuOsFAQParagraph>
        <AboutKyuOsFAQParagraph>
          The accuracy of the data on kyu OS reflects what exists in each member company’s
          information systems. The Creative Intelligence team works with the company's Data
          Champions and audits the data that goes into kyu OS.
        </AboutKyuOsFAQParagraph>
      </Collapsible>
      <Collapsible
        title={'How often is data updated on kyu OS?'}
        behavioralOnExpand={handleCollapsibleBehavioral}
      >
        <AboutKyuOsFAQParagraph>
          The Creative Intelligence team receives member company data on a monthly basis. Sometimes
          things happen and data from a company isn’t updated on this cadence, but we strive and
          push for monthly updates.
        </AboutKyuOsFAQParagraph>
      </Collapsible>
      <AboutKyuOsFAQAmIBeingTracked />
      <AboutKyuOsFAQIsOSAvailableExternally />
    </AboutKyuOsFAQWrapper>
  );
}

export function AboutKyuOsFAQAmIBeingTracked() {
  const isMobile = useIsMobile();
  const faqClickedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_FAQ_CLICKED);

  const handleCollapsibleBehavioral = () => {
    faqClickedBehavioralFunction();
  };

  return (
    <Collapsible
      title={'Is kyu OS usage tracked?'}
      behavioralOnExpand={handleCollapsibleBehavioral}
    >
      <AboutKyuOsFAQParagraph>
        Privacy is important to us. Our analytics are set up in a way that can’t be used to identify
        individuals usage. We use aggregated metrics to uncover patterns and recognize potential new
        features to design and build.
      </AboutKyuOsFAQParagraph>
      <AboutKyuOsFAQParagraph>
        While some data is linked to you, we don’t track you.
      </AboutKyuOsFAQParagraph>
      <AboutKyuOsTrackWrapper>
        <AboutKyuOsTrackContainer>
          <AboutKyuOsTrackPersonIconContainer>
            <PersonIcon width={21} height={21} fill={'rgba(255, 255, 255, 0.5)'} />
          </AboutKyuOsTrackPersonIconContainer>
          <AboutKyuOsTrackTitle>DATA LINKED TO YOU</AboutKyuOsTrackTitle>
          <AboutKyuOsTrackListItemContainer>
            <InfoIcon
              width={isMobile ? 16 : 24}
              height={isMobile ? 16 : 24}
              fill={'var(--color-primary)'}
            />
            <AboutKyuOsTrackListItemText>
              The information you input on your profile
            </AboutKyuOsTrackListItemText>
          </AboutKyuOsTrackListItemContainer>
          <AboutKyuOsTrackListItemContainer>
            <InfoIcon
              width={isMobile ? 16 : 24}
              height={isMobile ? 16 : 24}
              fill={'var(--color-primary)'}
            />
            <AboutKyuOsTrackListItemText>
              Communities and/or convenings you join
            </AboutKyuOsTrackListItemText>
          </AboutKyuOsTrackListItemContainer>
          <AboutKyuOsTrackListItemContainer>
            <InfoIcon
              width={isMobile ? 16 : 24}
              height={isMobile ? 16 : 24}
              fill={'var(--color-primary)'}
            />
            <AboutKyuOsTrackListItemText>
              Articles or artifacts you contribute on
            </AboutKyuOsTrackListItemText>
          </AboutKyuOsTrackListItemContainer>
          <AboutKyuOsTrackListItemContainer>
            <InfoIcon
              width={isMobile ? 16 : 24}
              height={isMobile ? 16 : 24}
              fill={'var(--color-primary)'}
            />
            <AboutKyuOsTrackListItemText>
              Topics or areas you chose to follow
            </AboutKyuOsTrackListItemText>
          </AboutKyuOsTrackListItemContainer>
        </AboutKyuOsTrackContainer>
        <AboutKyuOsTrackContainer>
          <AboutKyuOsTrackPersonIconContainer>
            <PersonIcon width={21} height={21} fill={'rgba(255, 255, 255, 0.5)'} />
          </AboutKyuOsTrackPersonIconContainer>
          <AboutKyuOsTrackTitle>WE DON’T TRACK YOU</AboutKyuOsTrackTitle>
          <AboutKyuOsTrackListItemContainer>
            <Clock
              width={isMobile ? 16 : 24}
              height={isMobile ? 16 : 24}
              fill={'var(--color-primary)'}
            />
            <AboutKyuOsTrackListItemText crossed>Activity history</AboutKyuOsTrackListItemText>
          </AboutKyuOsTrackListItemContainer>
          <AboutKyuOsTrackListItemContainer>
            <SearchIconSmall
              width={isMobile ? 16 : 24}
              height={isMobile ? 16 : 24}
              fill={'var(--color-primary)'}
            />
            <AboutKyuOsTrackListItemText crossed>
              The keywords you search for
            </AboutKyuOsTrackListItemText>
          </AboutKyuOsTrackListItemContainer>
          <AboutKyuOsTrackListItemContainer>
            <LocationIcon
              width={isMobile ? 16 : 24}
              height={isMobile ? 16 : 24}
              fill={'var(--color-primary)'}
            />
            <AboutKyuOsTrackListItemText crossed>
              Where you log in from (IP addresses)
            </AboutKyuOsTrackListItemText>
          </AboutKyuOsTrackListItemContainer>
        </AboutKyuOsTrackContainer>
      </AboutKyuOsTrackWrapper>
      <AboutKyuOsFAQLink href="https://kyu.com/legal/employee-privacy-notice" target="_blank">
        Review our Privacy Policy
      </AboutKyuOsFAQLink>
    </Collapsible>
  );
}

export function AboutKyuOsFAQIsOSAvailableExternally() {
  const faqClickedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_FAQ_CLICKED);

  const handleCollapsibleBehavioral = () => {
    faqClickedBehavioralFunction();
  };

  return (
    <Collapsible
      title={'Am I allowed to share kyu OS externally?'}
      behavioralOnExpand={handleCollapsibleBehavioral}
    >
      <AboutKyuOsFAQParagraph>
        The data in kyu OS is confidential.{' '}
        <AboutKyuOsFAQLink
          href="https://os.kyu.com/files/kyuOS-Engagement-Guidelines.pdf"
          target="_blank"
        >
          (Read full Do's and Don'ts)
        </AboutKyuOsFAQLink>
      </AboutKyuOsFAQParagraph>
      <AboutKyuOsExternalWrapper>
        <AboutKyuOsExternalContainer>
          <AboutKyuOsExternalTitleContainer>
            <AboutKyuOsExternalDot color="var(--color-data-industry-foreground)" />
            <AboutKyuOsExternalTitle>INTERNAL</AboutKyuOsExternalTitle>
          </AboutKyuOsExternalTitleContainer>
          <AboutKyuOsFAQParagraph>
            Always allowed. Feel free to share kyu OS URLs or screenshots with anyone in the
            collective.
          </AboutKyuOsFAQParagraph>
        </AboutKyuOsExternalContainer>
        <AboutKyuOsExternalContainer>
          <AboutKyuOsExternalTitleContainer>
            <AboutKyuOsExternalDot color="var(--color-data-people-foreground)" />
            <AboutKyuOsExternalTitle>CLIENTS</AboutKyuOsExternalTitle>
          </AboutKyuOsExternalTitleContainer>
          <AboutKyuOsFAQParagraph>
            In a pitch or with a current client, live screen sharing, and describing aspects of kyu
            OS, as well as demoing the tool is allowed. Giving access directly to the client is not
            allowed.
          </AboutKyuOsFAQParagraph>
        </AboutKyuOsExternalContainer>
        <AboutKyuOsExternalContainer>
          <AboutKyuOsExternalTitleContainer>
            <AboutKyuOsExternalDot color="var(--color-error)" />
            <AboutKyuOsExternalTitle>EXTERNAL</AboutKyuOsExternalTitle>
          </AboutKyuOsExternalTitleContainer>
          <AboutKyuOsFAQParagraph>
            Sharing externally is never allowed. kyu OS data is confidential, and our{' '}
            <AboutKyuOsFAQLink href="https://kyu.com/legal/employee-privacy-notice" target="_blank">
              Privacy Policy
            </AboutKyuOsFAQLink>{' '}
            does not permit external distribution.
          </AboutKyuOsFAQParagraph>
        </AboutKyuOsExternalContainer>
      </AboutKyuOsExternalWrapper>
    </Collapsible>
  );
}

export function AboutKyuOsFAQWhoWorksOnKyu() {
  const { isLoading, isError, data } = useKyuTeam();
  const faqClickedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_FAQ_CLICKED);
  const [personDrawer, setPersonDrawer] = useState<{ show: boolean; slug: string }>({
    show: false,
    slug: null,
  });

  const people = useMemo(() => {
    if (!data) return [];

    const dataTeam = Object.keys(data).reduce((acc, key) => {
      const team = data[key];
      return [...acc, ...team];
    }, []);

    return dataTeam;
  }, [data]);

  const handleKyuOsTeamMemberClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    slug: string
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setPersonDrawer({ show: true, slug });
  };

  const handlePersonDrawerClose = () => {
    setPersonDrawer({ show: false, slug: null });
  };

  const handleCollapsibleBehavioral = () => {
    faqClickedBehavioralFunction();
  };

  return (
    <Collapsible title={'Who works on kyu OS?'} behavioralOnExpand={handleCollapsibleBehavioral}>
      <AboutKyuOsFAQParagraph>
        This platform is supported by the Creative Intelligence, Editorial, and Business Development
        teams. You can reach out to us on Slack at any time or send us an email at{' '}
        <AboutKyuOsFAQLink href="mailto:os-team@kyu.com">os-team@kyu.com</AboutKyuOsFAQLink>
      </AboutKyuOsFAQParagraph>
      {isLoading || isError ? (
        <></>
      ) : (
        <AboutKyuOsTeamWrapper onClick={(e) => e.stopPropagation()}>
          {people?.map((member) => (
            <AboutKyuOsTeamMemberContainer
              onClick={(e) => handleKyuOsTeamMemberClick(e, member.slug)}
            >
              <PersonAvatar
                highlighted
                key={member?.uuid}
                avatar={member?.avatar}
                name={member?.first_name}
                lastName={member?.last_name}
                size={57}
                slug={member?.slug}
              />
              <AboutKyuOsTeamMemberInformationContainer>
                <AboutKyuOsTeamMemberName>{member?.full_name}</AboutKyuOsTeamMemberName>
                <AboutKyuOsTeamMemberRole>{member?.role}</AboutKyuOsTeamMemberRole>
              </AboutKyuOsTeamMemberInformationContainer>
            </AboutKyuOsTeamMemberContainer>
          ))}
        </AboutKyuOsTeamWrapper>
      )}
      {personDrawer.show && (
        <SideDrawer closeDrawer={handlePersonDrawerClose} withClose>
          <GlobalPersonDrawer slug={personDrawer.slug} />
        </SideDrawer>
      )}
    </Collapsible>
  );
}
