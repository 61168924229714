import React from 'react';

import AboutKyuOsWayfindingActivateProfile from './AboutKyuOsWayfindingActivateProfile';
import AboutKyuOsWayfindingArticle from './AboutKyuOsWayfindingArticle';
import AboutKyuOsWayfindingColleagues from './AboutKyuOsWayfindingColleagues';
import AboutKyuOsWayfindingCompany from './AboutKyuOsWayfindingCompany';
import AboutKyuOsWayfindingMegamenu from './AboutKyuOsWayfindingMegamenu';
import {
  AboutKyuOsWayfindingContainer,
  AboutKyuOsWayfindingTitle,
  AboutKyuOsWayfindingWrapper,
} from './styled';

export default function AboutKyuOsWayfinding() {
  return (
    <AboutKyuOsWayfindingWrapper id="new-to-kyu" data-index="0">
      <AboutKyuOsWayfindingTitle>New to kyu?</AboutKyuOsWayfindingTitle>
      <AboutKyuOsWayfindingContainer>
        <AboutKyuOsWayfindingActivateProfile />
        <AboutKyuOsWayfindingColleagues />
        <AboutKyuOsWayfindingCompany />
        <AboutKyuOsWayfindingMegamenu />
        <AboutKyuOsWayfindingArticle />
      </AboutKyuOsWayfindingContainer>
    </AboutKyuOsWayfindingWrapper>
  );
}
