import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const OnboardingStepThreeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 68px 184px 46px 184px;

  ${BREAKPOINTS.mobile} {
    padding: 36px 24px 32px 24px;
  }

  & > :not(:last-child) {
    margin-bottom: 49px;
  }
`;

export const OnboardingStepThreeTitle = styled.h1`
  font-weight: 400;
  font-size: 38px;
  line-height: 42px;
  text-align: center;

  color: var(--color-text);

  ${BREAKPOINTS.mobile} {
    font-size: 32px;
  }
`;

export const OnboardingStepThreeContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 16px;

    ${BREAKPOINTS.large_tablet_landscape} {
      margin-right: 8px;
    }

    ${BREAKPOINTS.tablet} {
      margin-right: 0px;
      margin-bottom: 16px;
    }
  }

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
  }
`;

export const OnboardingStepThreeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 256px);
  grid-gap: 16px;

  ${BREAKPOINTS.large_tablet_landscape} {
    grid-template-columns: repeat(2, 256px);
  }

  ${BREAKPOINTS.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const OnboardingStepThreeGridContainer = styled.div`
  grid-column: span 3;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  & > div {
    max-width: 256px;

    ${BREAKPOINTS.tablet} {
      max-width: 100%;
    }
  }

  & > :not(:last-child) {
    margin-right: 16px;

    ${BREAKPOINTS.large_tablet_landscape} {
      margin-right: 0px;
      margin-bottom: 16px;
    }
  }

  ${BREAKPOINTS.large_tablet_landscape} {
    grid-column: unset;
  }
`;

export const OnboardingStepThreeParagraph = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    text-align: center;
  }

  ${BREAKPOINTS.mobile} {
    font-size: 16px;
  }
`;

export const OnboardingStepThreeParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  ${BREAKPOINTS.large_tablet_landscape} {
    justify-content: flex-start;
  }
`;

export const OnboardingStepThreeLastParagraphContainer = styled.div`
  grid-column: span 2;

  display: flex;
  flex-direction: column;
  justify-content: center;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

// CARD
export const OnboardingStepThreeCardContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 8px 16px 8px;

  border-radius: 16px;
  background: rgba(255, 255, 255, 0.2);

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  min-height: 258px;
  height: fit-content;

  ${BREAKPOINTS.tablet} {
    flex-direction: row;
    align-items: center;
    min-height: unset;

    & > :not(:last-child) {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
`;

export const OnboardingStepThreeCardThumbnail = styled.img`
  width: 240px;
  height: 120px;
  border-radius: 8px;
  background: #1e2543;

  ${BREAKPOINTS.tablet} {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
`;

export const OnboardingStepThreeCardInformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const OnboardingStepThreeCardTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    text-align: start;
  }
`;

export const OnboardingStepThreeCardDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    text-align: start;
  }

  ${BREAKPOINTS.mobile} {
    font-size: 12px;
  }
`;
