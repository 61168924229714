import styled from 'styled-components/macro';
import { BREAKPOINTS } from 'styles/media';
import { SkeletonBackground, SkeletonItem } from '../../shared/styled';

export const CompanyDetailsHeroWrapper = styled(SkeletonBackground)`
  position: relative;
  min-height: 406px;
  padding-left: 55px;
  display: flex;
  flex-direction: column;

  ${BREAKPOINTS.tablet} {
    width: 100%;
    padding: 24px 16px 16px 16px;
  }
`;

export const CompanyDetailsTitle = styled(SkeletonItem)`
  width: 215px;
  height: 45px;
  margin: 94px 0px 55px 0px;
  border-radius: 1000px;
`;

interface CompanyDetailsBioProps {
  width?: number;
}

export const CompanyDetailsBio = styled(SkeletonItem)<CompanyDetailsBioProps>`
  width: ${({ width }) => width || 345}px;
  height: 22px;
  margin: 0px 0px 8px 0px;
  border-radius: 1000px;

  ${BREAKPOINTS.tablet} {
    width: calc(30% + ${({ width }) => width && width / 10 || 34}px);
  }
`;

export const CompanyDetailsFactContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CompanyDetailsFact = styled(SkeletonItem)`
  width: 111px;
  height: 12px;
  margin: 85px 34px 34px 0px;
  border-radius: 1000px;
`;

export const CompanyDetailsFeatureBlock = styled(SkeletonItem)`
  bottom: -24px;
  right: 48px;
  width: 300px;
  height: 204px;
  position: absolute;

  ${BREAKPOINTS.tablet} {
    display: none;
  }
`;

export const CompanyDetailsSnapshotWrapper = styled.div`
  width: 100%;
  height: 132px;
  display: flex;
  justify-content: space-evenly;

  ${BREAKPOINTS.tablet} {
    flex-direction: column;
    padding: 0px 16px;

    & > :not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const CompanyDetailsSnapshotTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;

  ${BREAKPOINTS.tablet} {
    align-items: flex-start;
  }
`;

interface CompanyDetailsSnapshotTextProps {
  width?: number;
}

export const CompanyDetailsSnapshotText = styled(SkeletonItem)<CompanyDetailsSnapshotTextProps>`
  width: ${({ width }) => width || 461}px;
  height: 21px;
  margin-bottom: 8px;
  border-radius: 1000px;

  ${BREAKPOINTS.tablet} {
    width: calc(30% + ${({ width }) => width && width / 10 || 46}px);
  }
`;

export const CompanyDetailsSnapshotRelatedCompaniesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${BREAKPOINTS.tablet} {
    align-items: flex-start;
  }
`;

export const CompanyDetailsSnapshotRelatedCompaniesText = styled(SkeletonItem)`
  width: 211px;
  height: 21px;
  margin-bottom: 8px;
  border-radius: 1000px;

  ${BREAKPOINTS.tablet} {
    width: 120px;
  }
`;

export const CompanyDetailsSnapshotRelatedCompaniesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  & :not(:first-child) {
    margin-left: -16px;
  }

  ${BREAKPOINTS.tablet} {
    justify-content: flex-start;
  }
`;

export const CompanyDetailsSnapshotRelatedCompany = styled(SkeletonItem)`
  width: 66px;
  height: 66px;
  border-radius: 1000px;

  ${BREAKPOINTS.tablet} {
    width: 32px;
    height: 32px;
`;

export const CompanyDetailsBubbleChartWrapper = styled.div`
  width: 100%;
  height: 398px;
  display: flex;
  flex-direction: column;
`;

export const CompanyDetailsBubbleChartCirclesWrapper = styled.div`
  flex: 1;
  position: relative;
  margin: 0px 48px;
`;

interface CompanyDetailsBubbleChartCircleProps {
  size: number;
  top: number;
  left: number;
}

export const CompanyDetailsBubbleChartCircle = styled(SkeletonItem)<CompanyDetailsBubbleChartCircleProps>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  border-radius: 1000px;
  position: absolute;
`;

export const CompanyDetailsBubbleChartCTA = styled(SkeletonItem)`
  height: 78px;
  margin: auto;
  width: 80%;
`;

export const CompanyDetailsImpactWrapper = styled.div`
  padding: 0px 48px;
  
  ${BREAKPOINTS.tablet} {
    padding: 0px 16px;
  }
`;

export const CompanyDetailsImpactTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const CompanyDetailsImpactTextTitle = styled(SkeletonItem)`
  width: 370px;
  height: 38px;
  border-radius: 100px;
  margin-bottom: 21px;

  ${BREAKPOINTS.tablet} {
    width: 100px;
`;

interface CompanyDetailsImpactTextParagraphProps {
  width: number;
}

export const CompanyDetailsImpactTextParagraph = styled(SkeletonItem)<CompanyDetailsImpactTextParagraphProps>`
  width: ${({ width }) => width}px;
  height: 16px;
  border-radius: 1000px;
`;

export const CompanyDetailsImpactGraphContainer = styled(SkeletonBackground)`
  margin-top: 49px;
  height: 300px;
  border-radius: 8px;
  
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CompanyDetailsImpactGraphBarContainer = styled.div`
  display: flex;
  align-items: flex-end;

  & > :not(:last-child) {
    margin-right: 20px;
  }
`;

interface CompanyDetailsImpactGraphBarProps {
  height: number;
}

export const CompanyDetailsImpactGraphBar = styled(SkeletonItem)<CompanyDetailsImpactGraphBarProps>`
  width: 20px;
  height: ${({ height }) => height}px;
  border-radius: 4px;
`;

export const CompanyDetailsProjectsWrapper = styled.div`
  padding: 0px 48px;
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 32px;
  }

  ${BREAKPOINTS.tablet} {
    padding: 0px 16px;
  }
`;

export const CompanyDetailsProjectsTitle = styled(SkeletonItem)`
  width: 370px;
  height: 32px;
  border-radius: 100px;

  ${BREAKPOINTS.tablet} {
    width: 234px;
  }
`;

export const CompanyDetailsProjectsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > :not(:last-child) {
    margin-right: 24px;
    margin-bottom: 24px;

    ${BREAKPOINTS.mobile} {
      margin-right: 0px;
    }
  }
`;

export const CompanyDetailsProject = styled(SkeletonBackground)`
  width: 285px;
  height: 235px;
  flex: calc(33.33% - 24px);
  border-radius: 8px;

  ${BREAKPOINTS.tablet} {
    width: 100%;
    flex: 45%;
  }

  ${BREAKPOINTS.mobile} {
    width: 100%;
    flex: 100%;
  }
`;