import styled from 'styled-components';

export const MobileMenuWrapper = styled.div`
  min-height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.backgroundMenuColor};
  box-shadow: -4px 0px 20px -4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(24px);

  padding: 24px;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const MobileMenuWaysToGetInvolvedWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const MobileMenuWaysToGetInvolvedContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const MobileMenuWaysToGetInvolvedTitle = styled.h6`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.waysToGetInvolvedTitle};
`;

export const MobileMenuWaysToGetInvolvedLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.waysToGetInvolvedLink};

  cursor: pointer;

  transition: all 200ms ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.waysToGetInvolvedLinkHover};
  }
  text-decoration: none;
`;

// MOBILE PROFILE MENU
export const MobileMenuProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  padding: 0px 16px;
`;

export const MobileMenuProfileContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const MobileMenuProfileItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const MobileMenuProfileItemText = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.color};
`;

export const MobileMenuProfileSignOutButton = styled.button`
  width: fit-content;

  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: ${({ theme }) => theme.signoutButtonMobile};
`;
