import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getCompanyNewPeople } from 'services/app';
import { ICompanyNewPeopleResponse } from '../../interfaces/company';

export default function useCompanyNewPeople(
  slug: string,
  options?: Omit<
    UseQueryOptions<ICompanyNewPeopleResponse, Error, ICompanyNewPeopleResponse, QueryKey>,
    'queryKey'
  >
) {
  return useQuery<ICompanyNewPeopleResponse, Error>(
    `company-${slug}-new-people`,
    () => getCompanyNewPeople(slug).then((response) => response.data),
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
