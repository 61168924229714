import React from 'react';

import VimeoVideo from 'app/components/CommonStyled/VimeoVideo';
import useIsMobile from 'hooks/useIsMobile';

export default function AboutKyuOsVideo() {
  const isMobile = useIsMobile();

  return (
    <VimeoVideo
      src="https://player.vimeo.com/video/876194804"
      height={!isMobile ? 571 : 196}
      width={!isMobile ? 1019 : 350}
    />
  );
}
