import React from "react";
import ReactDOM from "react-dom/client";
import App from "app/App";
import store from "store";
import { Provider } from "react-redux";
import reportWebVitals from "reportWebVitals";
import './application.scss'

const createElementWithId = (type, id) => {
  const element = document.createElement(type);
  element.setAttribute("id", id);
  return element;
};

const root = ReactDOM.createRoot(document.body.appendChild(createElementWithId("div", "root")));

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>);

reportWebVitals();
