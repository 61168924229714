import React, { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import useBehavioral from 'hooks/useBehavioral';
import {
  searchLensesSelector,
  searchOnboardingStepSelector,
  setSearchOnboardingStep,
} from 'store/reducers/user-reducer';

import DataTypeLen from '../../DataTypeLen';
import SideDrawer from '../../SideDrawer';
import { SearchIndexPillsWrapper } from './styled';

const HomeDrawer = React.lazy(() => import('../../HomeDrawers'));

interface SearchIndexPillsProps {
  pills?: any;
  mode?: 'full' | 'compact';
}

function SearchIndexPills({ pills, mode = 'full' }: SearchIndexPillsProps) {
  const dispatch = useDispatch();
  const searchLensClickedBehavioral = useBehavioral('SEARCH_LENS_CLICKED');
  const searchOnboardingStep = useSelector(searchOnboardingStepSelector);
  const searchLenses: any = useSelector(searchLensesSelector);
  const [drawer, setDrawer] = useState<any>({
    show: false,
    model: null,
  });

  const finalPills = useMemo(() => {
    if (pills) return pills;

    if (!searchLenses) {
      return {};
    }

    return {
      people: searchLenses.people,
      companies: searchLenses.members,
      skills: searchLenses.skills,
      disciplines: searchLenses.disciplines || 0,
      passions: searchLenses.passions,
      industries: searchLenses.industries,
      clients: searchLenses.clients,
    };
  }, [searchLenses]);

  const handleDataTypeLenClick = (key: string) => {
    searchLensClickedBehavioral({
      action_details: key,
    });

    if (searchOnboardingStep === 2) {
      dispatch(setSearchOnboardingStep(0));
    }

    setDrawer({ show: true, model: key });
  };

  const handleDrawerClose = () => {
    setDrawer({ show: false, model: null });
  };

  return (
    <SearchIndexPillsWrapper className="search-index-pills-wrapper">
      {Object.keys(finalPills).map(
        (
          key:
            | 'people'
            | 'companies'
            | 'skills'
            | 'disciplines'
            | 'passions'
            | 'industries'
            | 'clients'
        ) => (
          <DataTypeLen
            type={key}
            counter={finalPills[key]}
            mode={mode}
            key={key}
            onClick={() => handleDataTypeLenClick(key)}
          />
        )
      )}
      {drawer.show
        ? createPortal(
            <SideDrawer closeDrawer={handleDrawerClose} withClose>
              <HomeDrawer model={drawer.model} />
            </SideDrawer>,
            document.querySelector('#root')
          )
        : null}
    </SearchIndexPillsWrapper>
  );
}

export default SearchIndexPills;
