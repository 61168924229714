import React, { Suspense, useEffect, useRef } from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { getSearchHighlights, getSearchLenses, getUserData } from 'services/app';
import { getPersonDetailsLikes } from 'services/person';
import {
  authTypeSelector,
  isAuthOpenSelector,
  isNewUserSelector,
  setIsAuthOpen,
} from 'store/reducers/auth-reducer';
import { isDrawerOpenSelector } from 'store/reducers/navbar-reducer';
import {
  setSearchHighlights,
  setSearchLenses,
  setUserData,
  userTokenSelector,
  userDataSelector,
  profileCompletionSelector,
} from 'store/reducers/user-reducer';

import { AppWrapper, GlobalStyle } from '../styles/global-styles';
import ScreenSizeAlert from 'app/components/ScreenSizeAlert';
const Auth = React.lazy(() => import('./components/Auth'));
const Toast = React.lazy(() => import('./components/CommonStyled/Toast'));
const BannerHandler = React.lazy(() => import('./components/BannerHandler'));
const Onboarding = React.lazy(() => import('./components/Onboarding'));
import { LoadingComponent } from './components';
import Routes from './routes';

function App() {
  const dispatch = useDispatch();

  const requestingData = useRef<boolean>(false);

  const token = useSelector(userTokenSelector);
  const isNewUser = useSelector(isNewUserSelector);
  const isAuthOpen = useSelector(isAuthOpenSelector);
  const authType = useSelector(authTypeSelector);
  const userData = useSelector(userDataSelector);
  const profileCompletion = useSelector(profileCompletionSelector);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        retry: false,
      },
    },
  });

  const getDataNotNewUser = async () => {
    const higlightsResponse = await getSearchHighlights();
    if (!higlightsResponse.ok) {
      throw new Error(higlightsResponse.originalError?.message);
    }

    dispatch(setSearchHighlights(higlightsResponse.data));

    const searcbLensesResponse = await getSearchLenses();
    if (!searcbLensesResponse.ok) {
      throw new Error(searcbLensesResponse.originalError?.message);
    }

    dispatch(setSearchLenses(searcbLensesResponse.data));
  };

  const requestUserData = async () => {
    requestingData.current = true;
    const userRes = await getUserData();
    if (userRes.ok) {
      requestingData.current = false;
      dispatch(setUserData(userRes.data));

      getPersonDetailsLikes(userRes.data.slug).then((response) => {
        if (!response.ok) {
          throw new Error(response.originalError.message);
        }

        let finalReactions = Object.values(response.data.reactions).reduce((allReactions, reaction) => [...allReactions, ...reaction.map(r => r.slug)] , []);

        localStorage.setItem('reactions', JSON.stringify(finalReactions));
      });
    }
  };

  const isDrawerOpen = useSelector(isDrawerOpenSelector);

  useEffect(() => {
    if (token) {
      if (!isNewUser) {
        getDataNotNewUser();
      }

      if (!requestingData.current) {
        requestUserData();
      }
    }
  }, [token]);

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      if (
        userData.engagement_type_name === 'Unverified' &&
        !userData.has_seen_verify_screen &&
        authType !== 'MAGIC_LINK'
      ) {
        dispatch(setIsAuthOpen(true));
      }
    }
  }, [userData.engagement_type_name]);

  return (
    <BrowserRouter>
      <HelmetProvider>
        <Helmet>
          <link rel="icon" href="/icons/kyu.ico" type="image/x-icon" />
          <link rel="apple-touch-icon" href="/icons/kyu.ico" type="image/x-icon" />

          <title>kyu OS: Beta</title>
        </Helmet>
        <GlobalStyle onboardingOpen={false} isDrawerOpen={isDrawerOpen} />
        <QueryClientProvider client={queryClient}>
          <Suspense
            fallback={<LoadingComponent idParticles="graph-page-particles" messages={[]} />}
          >
            <AppWrapper>
              <ScreenSizeAlert />
              {!isAuthOpen ? <Routes /> : null}
              {isAuthOpen ? <Auth /> : null}
              <BannerHandler />
            </AppWrapper>
            <Suspense fallback={<div />}>
              <Toast />
            </Suspense>
          </Suspense>
        </QueryClientProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
