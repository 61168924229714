import React from 'react';

export default function DisciplineIcon({ width = 28, height = 28, fill = '#5478A1' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99935 3.33268H21.9993C23.4721 3.33268 24.666 4.52659 24.666 5.99935V21.9993C24.666 22.9864 24.1298 23.8482 23.3327 24.3093V22.9994C23.3327 21.6733 22.7864 20.4015 21.814 19.4638C20.8416 18.5261 19.5227 17.9994 18.1475 17.9994H9.8512C8.476 17.9994 7.15713 18.5261 6.18472 19.4638C5.21231 20.4015 4.66601 21.6733 4.66601 22.9994V24.3093C3.86895 23.8482 3.33268 22.9864 3.33268 21.9993V5.99935C3.33268 4.52659 4.52659 3.33268 5.99935 3.33268ZM6.74009 24.666H21.2586V22.9994C21.2586 22.2037 20.9308 21.4407 20.3474 20.878C19.7639 20.3154 18.9726 19.9994 18.1475 19.9994H9.8512C9.02608 19.9994 8.23476 20.3154 7.65131 20.878C7.06786 21.4407 6.74009 22.2037 6.74009 22.9994V24.666ZM0.666016 5.99935C0.666016 3.05383 3.05383 0.666016 5.99935 0.666016H21.9993C24.9449 0.666016 27.3327 3.05383 27.3327 5.99935V21.9993C27.3327 24.9449 24.9449 27.3327 21.9993 27.3327H5.99935C3.05383 27.3327 0.666016 24.9449 0.666016 21.9993V5.99935ZM13.9994 8.13268C12.232 8.13268 10.7993 9.56537 10.7993 11.3327C10.7993 13.1 12.232 14.5327 13.9994 14.5327C15.7667 14.5327 17.1994 13.1 17.1994 11.3327C17.1994 9.56537 15.7667 8.13268 13.9994 8.13268ZM8.66602 11.3327C8.66602 8.38716 11.0538 5.99935 13.9994 5.99935C16.9449 5.99935 19.3327 8.38716 19.3327 11.3327C19.3327 14.2782 16.9449 16.666 13.9994 16.666C11.0538 16.666 8.66602 14.2782 8.66602 11.3327Z"
        fill={fill}
      />
    </svg>
  );
}
