import React from 'react';

import InfoTooltip from 'app/components/CommonStyled/InfoTooltip';
import {
  ClientIcon,
  CompanyIcon,
  IndustryIcon,
  LocationIcon,
  PassionIcon,
  PeopleIcon,
  ProjectIcon,
  SkillIcon,
} from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { CommunityIcon } from 'app/components/shared/icons';
import Calendar from 'app/components/shared/icons/calendar';
import PersonIcon from 'app/components/shared/icons/person-icon';
import WorldGlobe from 'app/components/shared/icons/world-globe';
import { ThemeProvider } from 'styled-components';

import { ITotalThemeMap, TotalDataType, TotalProps, TotalTitleObject } from './interfaces';
import {
  TotalWithSideIconCounter,
  TotalWithSideIconTitle,
  TotalWithSideIconTopContainer,
  TotalWithSideIconTitleContainer,
  TotalWrapper,
} from './styled';
import { theme as providedTheme } from './theme';

const Total = React.forwardRef<HTMLDivElement, TotalProps>((props, ref) => {
  switch (props.type) {
    case 'minimal':
      return <MinimalTotal {...props} ref={ref} />;
    case 'with_icon':
      return <WithIconTotal {...props} ref={ref} />;
    case 'with_side_icon':
      return <WithSideIconTotal {...props} ref={ref} />;
    default:
      return <React.Fragment />;
  }
});

const MinimalTotal = React.forwardRef<HTMLDivElement, TotalProps>((props, ref) => {
  return <TotalWrapper></TotalWrapper>;
});

const WithIconTotal = React.forwardRef<HTMLDivElement, TotalProps>((props, ref) => {
  return <TotalWrapper></TotalWrapper>;
});

const WithSideIconTotal = React.forwardRef<HTMLDivElement, TotalProps>((props, ref) => {
  const { dataType, title, counter, onClick, theme, tooltip } = props;
  const defaultTitle =
    counter !== 1 ? DataTypeToTitle.get(dataType).plural : DataTypeToTitle.get(dataType).singular;

  return (
    <ThemeProvider theme={providedTheme[theme]}>
      <TotalWrapper ref={ref} data-type={dataType} onClick={onClick}>
        <TotalWithSideIconTopContainer>
          {React.cloneElement(
            DataTypeToIcons.has(dataType) ? DataTypeToIcons.get(dataType) : <React.Fragment />,
            {
              ...DataTypeToIcons.get(dataType)?.props,
              width: 24,
              height: 24,
              fill: DataTypeToFill.get(dataType)[theme],
            }
          )}
          <TotalWithSideIconCounter>{counter}</TotalWithSideIconCounter>
        </TotalWithSideIconTopContainer>
        <TotalWithSideIconTitleContainer>
          <TotalWithSideIconTitle clickable={!!onClick}>
            {title ? (
              title.split(' ').map((word, i) => (
                <span key={i}>
                  {word}
                  <br />
                </span>
              ))
            ) : (
              <span>{defaultTitle}</span>
            )}
          </TotalWithSideIconTitle>

          {!!tooltip && <InfoTooltip size={14} fill={'#171c33'} text={tooltip} />}
        </TotalWithSideIconTitleContainer>
      </TotalWrapper>
    </ThemeProvider>
  );
});

const DataTypeToIcons = new Map<TotalDataType, JSX.Element>([
  [TotalDataType.PEOPLE, <PersonIcon />],
  [TotalDataType.SKILLS, <SkillIcon />],
  [TotalDataType.PASSIONS, <PassionIcon />],
  [TotalDataType.PROJECTS, <ProjectIcon />],
  [TotalDataType.COMPANIES, <CompanyIcon />],
  [TotalDataType.INDUSTRIES, <IndustryIcon />],
  [TotalDataType.COMMUNITIES, <CommunityIcon />],
  [TotalDataType.LANGUAGES, <PeopleIcon />],
  [TotalDataType.COUNTRIES, <WorldGlobe />],
  [TotalDataType.CITIES, <LocationIcon />],
  [TotalDataType.CONVENINGS, <Calendar />],
  [TotalDataType.PRIOR_COMPANIES, <ClientIcon />],
  [TotalDataType.PRIOR_CLIENTS, <ClientIcon />],
  [TotalDataType.PROJECT_CLIENTS, <ClientIcon />],
  [TotalDataType.PROJECT_COMPANIES, <CompanyIcon />],
  [TotalDataType.CLIENTS, <ClientIcon />],
]);

const DataTypeToFill = new Map<TotalDataType, ITotalThemeMap>([
  [
    TotalDataType.PEOPLE,
    { light: 'var(--color-data-people-foreground)', dark: 'var(--color-data-people-background)' },
  ],
  [
    TotalDataType.SKILLS,
    { light: 'var(--color-data-skill-foreground)', dark: 'var(--color-data-skill-background)' },
  ],
  [
    TotalDataType.PASSIONS,
    { light: 'var(--color-data-passion-foreground)', dark: 'var(--color-data-passion-background)' },
  ],
  [
    TotalDataType.PROJECTS,
    { light: 'var(--color-data-project-foreground)', dark: 'var(--color-data-project-background)' },
  ],
  [
    TotalDataType.COMPANIES,
    { light: 'var(--color-data-company-foreground)', dark: 'var(--color-data-company-background)' },
  ],
  [
    TotalDataType.INDUSTRIES,
    {
      light: 'var(--color-data-industry-foreground)',
      dark: 'var(--color-data-industry-background)',
    },
  ],
  [
    TotalDataType.COMMUNITIES,
    {
      light: 'var(--color-data-community-foreground)',
      dark: 'var(--color-data-community-background)',
    },
  ],
  [
    TotalDataType.LANGUAGES,
    { light: 'var(--color-data-people-foreground)', dark: 'var(--color-data-people-background)' },
  ],
  [
    TotalDataType.COUNTRIES,
    { light: 'var(--color-data-people-foreground)', dark: 'var(--color-data-people-background)' },
  ],

  [
    TotalDataType.CITIES,
    { light: 'var(--color-data-people-foreground)', dark: 'var(--color-data-people-background)' },
  ],
  [
    TotalDataType.CONVENINGS,
    {
      light: 'var(--color-data-community-foreground)',
      dark: 'var(--color-data-community-background)',
    },
  ],
  [
    TotalDataType.PRIOR_COMPANIES,
    {
      light: 'var(--color-data-client-foreground)',
      dark: 'var(--color-data-client-background)',
    },
  ],
  [
    TotalDataType.PRIOR_CLIENTS,
    {
      light: 'var(--color-data-client-foreground)',
      dark: 'var(--color-data-client-background)',
    },
  ],
  [
    TotalDataType.PROJECT_CLIENTS,
    {
      light: 'var(--color-data-client-foreground)',
      dark: 'var(--color-data-client-background)',
    },
  ],
  [
    TotalDataType.PROJECT_COMPANIES,
    {
      light: 'var(--color-data-company-foreground)',
      dark: 'var(--color-data-company-background)',
    },
  ],
  [
    TotalDataType.CLIENTS,
    {
      light: 'var(--color-data-client-foreground)',
      dark: 'var(--color-data-client-background)',
    },
  ],
]);

const DataTypeToTitle = new Map<TotalDataType, TotalTitleObject>([
  [TotalDataType.PEOPLE, { singular: 'person', plural: 'people' }],
  [TotalDataType.SKILLS, { singular: 'skill', plural: 'skills' }],
  [TotalDataType.PASSIONS, { singular: 'passion', plural: 'passions' }],
  [TotalDataType.PROJECTS, { singular: 'project', plural: 'projects' }],
  [TotalDataType.COMPANIES, { singular: 'company', plural: 'companies' }],
  [TotalDataType.INDUSTRIES, { singular: 'industry', plural: 'industries' }],
  [TotalDataType.COMMUNITIES, { singular: 'community', plural: 'communities' }],
  [TotalDataType.LANGUAGES, { singular: 'language', plural: 'languages' }],
  [TotalDataType.COUNTRIES, { singular: 'country', plural: 'countries' }],
  [TotalDataType.CITIES, { singular: 'city', plural: 'cities' }],
  [TotalDataType.CONVENINGS, { singular: 'convening', plural: 'convenings' }],
  [
    TotalDataType.PRIOR_COMPANIES,
    { singular: 'previously worked at', plural: 'previously worked at' },
  ],
  [
    TotalDataType.PRIOR_CLIENTS,
    { singular: 'past client experience', plural: 'past client experience' },
  ],
  [
    TotalDataType.PROJECT_CLIENTS,
    { singular: 'client through project', plural: 'clients through projects' },
  ],
  [
    TotalDataType.PROJECT_COMPANIES,
    { singular: 'member company touchpoint', plural: 'member company touchpoint' },
  ],
  [TotalDataType.CLIENTS, { singular: 'client', plural: 'clients' }],
]);

Total.defaultProps = {
  onClick: null,
  theme: 'light',
};

export default Total;
