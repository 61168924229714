import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useUserData from 'hooks/useUserData';
import { updateCollectiveDirectoryOnboarded } from 'services/user';
import {
  IPeopleExclusiveFilters,
  IPeopleFilters,
  setFilters,
  updatePreviousFilters,
} from 'store/reducers/people/people-reducer';
import { setUserData } from 'store/reducers/user-reducer';

import { CollectiveDirectoryPillLabel, CollectiveDirectoryPillWrapper } from './styled';

interface CollectiveDirectoryPillProps {
  filters: IPeopleFilters;
  exclusiveFilters?: IPeopleExclusiveFilters;
  children?: React.ReactNode;
  label: string;
  beforeClick?: (filters?: IPeopleFilters) => void;
}

export default function CollectiveDirectoryPill({
  filters,
  exclusiveFilters,
  children,
  label,
  beforeClick,
}: CollectiveDirectoryPillProps) {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const userData = useUserData();

  const handlePillClick = () => {
    dispatch(setUserData({ ...userData, collective_directory_onboarding: false }));
    updateCollectiveDirectoryOnboarded({ status: false });
    beforeClick?.(filters);
    dispatch(setFilters({ filters, exclusiveFilters }));
    dispatch(updatePreviousFilters({}));
    push('/people');
  };

  return (
    <CollectiveDirectoryPillWrapper onClick={handlePillClick}>
      {React.cloneElement(children as ReactElement, {
        width: 16,
        height: 16,
        fill: 'var(--color-primary)',
      })}
      <CollectiveDirectoryPillLabel>{label}</CollectiveDirectoryPillLabel>
    </CollectiveDirectoryPillWrapper>
  );
}
