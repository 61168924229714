import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getBio, getCountries, getLanguages, getPassions } from 'services/profile-editor';

export function useHumanAtAGlance(
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `profile-editor-human-at-a-glance`,
    async () => {
      const [bio, countries, languages, passions] = await Promise.all([
        getBio(),
        getCountries(),
        getLanguages(),
        getPassions(),
      ]);

      return {
        bio: bio.data.bio || '',
        bio_inspo: bio.data.inspo || [],
        countries: countries.data.countries || [],
        allCountries: countries.data.all || [],
        languages: languages.data.languages || [],
        allLanguages: languages.data.all || [],
        passions: passions.data.passions || [],
        allPassions: passions.data.all || [],
        passions_inspo: passions.data.inspo || [],
      };
    },
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
