import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import ProfileEditInput from 'app/components/CommonStyled/ProfileEditInput';
import { CloseIcon } from 'app/components/shared/icons';
import AlarmClock from 'app/components/shared/icons/alarm-clock';
import { Form, Formik, FormikProps } from 'formik';
import { ICollectionParams, postCompanies } from 'services/profile-editor';
import {
  setAllCompanies,
  setCompanies,
} from 'store/reducers/profile-editor/profile-editor-reducer';
import * as Yup from 'yup';

import useProfessionalPriorExperienceDrawer from '../../hooks/useProfessionalPriorExperienceDrawer';
import ProfileEditorDrawer, { ProfileEditorDrawerRefProps } from '../Drawer';
import { ProfileEditorDrawerTitle } from '../styled';
import ProfileEditorDrawerCompaniesNewCompany from './NewCompany';
import {
  ProfileEditorDrawerExperienceNeedsApproval,
  ProfileEditorDrawerExperiencePill,
  ProfileEditorDrawerExperiencePillIconContainer,
  ProfileEditorDrawerExperiencePillText,
  ProfileEditorDrawerExperiencePillTextContainer,
  ProfileEditorDrawerExperiencePillsContainer,
  ProfileEditorDrawerExperienceWrapper,
} from './styled';

interface ProfileEditorDrawerCompaniesProps {
  onClose: () => void;
  companyName?: string;
}

export default function ProfileEditorDrawerCompanies({
  onClose,
  companyName,
}: Readonly<ProfileEditorDrawerCompaniesProps>) {
  const dispatch = useDispatch();
  const [showNewCompanyForm, setShowNewCompanyForm] = useState<boolean>(false);
  const [currentNewOption, setCurrentNewOption] = useState<any>(null);
  const { companies, allCompanies } = useProfessionalPriorExperienceDrawer();
  const [companiesCreated, setCompaniesCreated] = useState<any[]>([]);
  const formikRef = useRef<FormikProps<any>>(null);
  const [optionsPicked, setOptionsPicked] = useState<any[]>([]);
  const drawerRef = useRef<ProfileEditorDrawerRefProps>(null);

  useEffect(() => {
    setOptionsPicked(companies.map((company) => ({ ...company, label: company.name })));
    formikRef.current.setFieldValue(
      'collection',
      companies.map((company) => company.uuid)
    );
  }, [companies]);

  const options = useMemo(() => {
    return allCompanies.map((company) => ({
      ...company,
      label: company.name,
      value: company.uuid,
    }));
  }, [allCompanies]);

  //Set options picked for select
  useEffect(() => {
    if (companies && companies.length > 0) {
      setOptionsPicked(
        companies.map((company) => ({
          ...company,
          value: company.uuid,
          label: company.name,
          approved: company.approved,
        }))
      );
    }
  }, [companies]);

  //Create companies
  const handlePostCompanies = async (params: ICollectionParams) => {
    const response = await postCompanies({
      collection: optionsPicked.map((option) => option.value),
    });
    if (!response.ok) {
      throw new Error(response.originalError?.message);
    }

    const newlyCreatedCompanies = optionsPicked.filter((option) => option.isCreated);
    const allAvailableCompanies = [...allCompanies, ...newlyCreatedCompanies];
    dispatch(setAllCompanies(allAvailableCompanies));
    dispatch(setCompanies(optionsPicked));
    return response.data;
  };

  const { mutate } = useMutation(handlePostCompanies, drawerRef.current?.handleMutation);

  //Validation
  const companiesValidationSchema = Yup.object().shape({
    collection: Yup.array(),
  });

  //Options changed
  const handleOptionsChanged = (options: any[]) => {
    let newlyCreatedOptions = options.reduce((arr, option) => {
      if (option.isCreated) {
        arr.push({ ...option, approved: false });
      }
      return arr;
    }, []);
    newlyCreatedOptions = [...newlyCreatedOptions, ...companiesCreated];
    setCompaniesCreated(newlyCreatedOptions);
    const alreadyCreatedOptions = options.filter((option) => !option.isCreated);
    const allOptions = [...alreadyCreatedOptions, ...newlyCreatedOptions];
    const uniqOptions = allOptions.filter(
      (option, index, self) => index === self.findIndex((t) => t.value === option.value)
    );

    const optionsToSave = uniqOptions.map((company) => {
      const existingCompany = companies.find((companyItem) => companyItem.uuid === company.value);

      if (existingCompany) {
        return {
          ...existingCompany,
          ...company,
        };
      }
      return { ...company, name: company.label };
    });

    setOptionsPicked(optionsToSave);
  };

  const handleNewOptionPicked = (option: { value: any; label: string; isCreated: boolean }) => {
    setCurrentNewOption(option);
    setShowNewCompanyForm(true);
  };

  const handleCurrentOptionDeleted = () => {
    setCurrentNewOption(null);
    setShowNewCompanyForm(false);
  };

  const handleNewCompanySubmitted = (company) => {
    setShowNewCompanyForm(false);
    setOptionsPicked([
      ...optionsPicked,
      {
        ...company,
        value: company.uuid,
        isCreated: true,
        label: currentNewOption.label,
        approved: false,
      },
    ]);
    formikRef.current.setFieldTouched('collection', true);
  };

  //Submit
  const handleSubmit = (values) => {
    mutate({ collection: values.collection });
  };

  //Remove behavior
  const handleRemoveCompany = (company) => {
    const filteredOptions = optionsPicked.filter((option) => option.value !== company.value);
    if (company.isCreated) {
      setCompaniesCreated(companiesCreated.filter((option) => option.value !== company.value));
    }
    formikRef.current.setFieldValue(
      'collection',
      filteredOptions.map((option) => option.value)
    );
  };

  return (
    <Formik
      initialValues={{
        collection: companies.map((company) => company.uuid),
      }}
      validationSchema={companiesValidationSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {(props) => (
        <Form>
          <ProfileEditorDrawer
            ref={drawerRef}
            header={<ProfileEditorDrawerCompaniesHeader />}
            needsSaving={props.dirty || showNewCompanyForm || !!props.touched['collection']}
            disableSubmit={showNewCompanyForm}
            onClose={onClose}
          >
            <ProfileEditorDrawerExperienceWrapper>
              <ProfileEditInput
                {...props}
                name={'collection'}
                label={`Before ${companyName}, which companies have you worked for?`}
                type={'select'}
                placeholder={'Search for companies...'}
                tooltip={
                  "You can search and select companies from the dropdown menu. If you can’t find the company you’re looking for, you can create a company and add it to kyu OS. New companies are reviewed for typos and similarities by the Creative Intelligence team and they'll display on your profile once approved."
                }
                options={options}
                onOptionsChanged={handleOptionsChanged}
                onNewOptionPicked={handleNewOptionPicked}
                onCurrenOptionDeleted={handleCurrentOptionDeleted}
                settings={{
                  allowCreate: true,
                }}
                keepValueOnChange
              />
              {showNewCompanyForm ? (
                <ProfileEditorDrawerCompaniesNewCompany
                  onNewCompanySubmitted={handleNewCompanySubmitted}
                />
              ) : null}
              <ProfileEditorDrawerExperiencePillsContainer>
                {optionsPicked &&
                  optionsPicked.length > 0 &&
                  optionsPicked.map((option, index) => (
                    <ProfileEditorDrawerExperiencePill key={option.value}>
                      <ProfileEditorDrawerExperiencePillTextContainer>
                        <ProfileEditorDrawerExperiencePillText>
                          {option.label}
                        </ProfileEditorDrawerExperiencePillText>
                        {option.approved == false && (
                          <ProfileEditorDrawerExperienceNeedsApproval>
                            <AlarmClock width={14} height={14} fill="#A26565" />
                            <span>Pending Review</span>
                          </ProfileEditorDrawerExperienceNeedsApproval>
                        )}
                      </ProfileEditorDrawerExperiencePillTextContainer>
                      <ProfileEditorDrawerExperiencePillIconContainer
                        onClick={() => handleRemoveCompany(option)}
                      >
                        <CloseIcon width={9} height={9} fill={'var(--color-primary)'} />
                      </ProfileEditorDrawerExperiencePillIconContainer>
                    </ProfileEditorDrawerExperiencePill>
                  ))}
              </ProfileEditorDrawerExperiencePillsContainer>
            </ProfileEditorDrawerExperienceWrapper>
          </ProfileEditorDrawer>
        </Form>
      )}
    </Formik>
  );
}

function ProfileEditorDrawerCompaniesHeader() {
  return <ProfileEditorDrawerTitle>Previous Companies</ProfileEditorDrawerTitle>;
}
