import React from "react";

export default function first() {
  return (
    <svg
      width="189"
      className="explore-bg"
      height="257"
      viewBox="0 0 189 257"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0743 183.505C17.6872 183.126 19.3049 184.126 19.6874 185.738C20.0699 187.35 19.0724 188.964 17.4595 189.343C15.8466 189.722 14.229 188.722 13.8465 187.11C13.464 185.498 14.4614 183.884 16.0743 183.505Z"
        fill="white"
      />
      <path
        d="M83.3067 80.7672C84.1131 80.5777 84.922 81.0775 85.1132 81.8836C85.3045 82.6896 84.8057 83.4967 83.9993 83.6862C83.1928 83.8757 82.384 83.3759 82.1928 82.5698C82.0015 81.7638 82.5002 80.9567 83.3067 80.7672Z"
        fill="white"
      />
      <path
        d="M1.05081 109.855C1.58845 109.728 2.12766 110.061 2.25516 110.599C2.38267 111.136 2.05019 111.674 1.51255 111.801C0.974905 111.927 0.435701 111.594 0.308196 111.056C0.180692 110.519 0.513172 109.981 1.05081 109.855Z"
        fill="white"
      />
      <path
        d="M73.0508 254.855C73.5885 254.728 74.1277 255.061 74.2552 255.599C74.3827 256.136 74.0502 256.674 73.5125 256.801C72.9749 256.927 72.4357 256.594 72.3082 256.056C72.1807 255.519 72.5132 254.981 73.0508 254.855Z"
        fill="white"
      />
      <path
        d="M51.0508 56.8546C51.5885 56.7282 52.1277 57.0614 52.2552 57.5988C52.3827 58.1362 52.0502 58.6742 51.5125 58.8005C50.9749 58.9269 50.4357 58.5937 50.3082 58.0563C50.1807 57.5189 50.5132 56.9809 51.0508 56.8546Z"
        fill="white"
      />
      <path
        d="M89.0743 0.505122C90.6872 0.12611 92.3049 1.12573 92.6874 2.73783C93.0699 4.34992 92.0724 5.96402 90.4595 6.34303C88.8466 6.72205 87.229 5.72245 86.8465 4.11035C86.464 2.49826 87.4614 0.884135 89.0743 0.505122Z"
        fill="white"
      />
      <path
        d="M112.074 183.505C113.687 183.126 115.305 184.126 115.687 185.738C116.07 187.35 115.072 188.964 113.46 189.343C111.847 189.722 110.229 188.722 109.846 187.11C109.464 185.498 110.461 183.884 112.074 183.505Z"
        fill="white"
      />
      <path
        d="M179.307 80.7672C180.113 80.5777 180.922 81.0775 181.113 81.8836C181.304 82.6896 180.806 83.4967 179.999 83.6862C179.193 83.8757 178.384 83.3759 178.193 82.5698C178.002 81.7638 178.5 80.9567 179.307 80.7672Z"
        fill="white"
      />
      <path
        d="M97.0508 109.855C97.5885 109.728 98.1277 110.061 98.2552 110.599C98.3827 111.136 98.0502 111.674 97.5125 111.801C96.9749 111.927 96.4357 111.594 96.3082 111.056C96.1807 110.519 96.5132 109.981 97.0508 109.855Z"
        fill="white"
      />
      <path
        d="M169.051 254.855C169.588 254.728 170.128 255.061 170.255 255.599C170.383 256.136 170.05 256.674 169.513 256.801C168.975 256.927 168.436 256.594 168.308 256.056C168.181 255.519 168.513 254.981 169.051 254.855Z"
        fill="white"
      />
      <path
        d="M147.051 56.8546C147.588 56.7282 148.128 57.0614 148.255 57.5988C148.383 58.1362 148.05 58.6742 147.513 58.8005C146.975 58.9269 146.436 58.5937 146.308 58.0563C146.181 57.5189 146.513 56.9809 147.051 56.8546Z"
        fill="white"
      />
      <path
        d="M185.074 0.505122C186.687 0.12611 188.305 1.12573 188.687 2.73783C189.07 4.34992 188.072 5.96402 186.46 6.34303C184.847 6.72205 183.229 5.72245 182.846 4.11035C182.464 2.49826 183.461 0.884135 185.074 0.505122Z"
        fill="white"
      />
    </svg>
  );
}
