import { httpNewUser, http } from './http';

export const getUserDataChamps = () => {
  return httpNewUser.get(`person/data_champions`);
};

export interface ICreateNewUserParams {
  first_name: string;
  last_name: string;
  privacy_notice_accepted: boolean;
}

export const createNewUser = (params: ICreateNewUserParams) => {
  return httpNewUser.post(`person`, params);
};

export const updateFeaturedBanner = (params: { status: boolean }) => {
  return http.put(`person/update_featured_banner`, params);
};

export const updateShareBanner = (params: { status: boolean }) => {
  return http.put(`person/update_share_banner`, params);
};

export const updateNavOnboarded = (params: { status: boolean }) => {
  return http.put(`person/update_nav_onboarding`, params);
};

export const updateAvatarOnboarded = (params: { status: boolean }) => {
  return http.put(`person/update_avatar_onboarding`, params);
};

export const updateSearchOnboarded = (params: { status: boolean }) => {
  return http.put(`person/update_search_onboarding`, params);
};

export const updateResourceOnboarded = (params: { status: boolean }) => {
  return http.put(`person/update_resource_onboarding`, params);
};

export const updateCollectiveDirectoryOnboarded = (params: { status: boolean }) => {
  return http.put(`person/update_collective_directory_onboarding`, params);
};

export const updateContactFlowOnboarded = (params: { status: boolean }) => {
  return http.put(`person/update_contact_flow_onboarding`, params);
};

export const updateHasSeenVerifyScreen = (params: { status: boolean }) => {
  return http.put(`/person/update_has_seen_verify_screen`, params);
};

export const updateTeamingOnboarding = (params: { status: boolean }) => {
  return http.put(`/person/update_teaming_onboarding`, params);
};

// PROFILE
export const getPersonProfileCompletion = () => {
  return http.get<any>(`person/profile_completion`);
};

export const getPersonProfileReach = (type?: string, page?: number) => {
  return http.get<any>(`person/profile_reach`, { type, page });
};
