import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useMyProfileCompletion from 'app/components/MyProfile/hooks/useMyProfileCompletion';
import useBehavioral from 'hooks/useBehavioral';
import { setProfileCompletion } from 'store/reducers/user-reducer';
import { ThemeProvider } from 'styled-components';

import InfoTooltip from '../InfoTooltip';
import {
  ProgressBarCounter,
  ProgressBarCounterContainer,
  ProgressBarInformationContainer,
  ProgressBarInformationProgressText,
  ProgressBarInformationWrapper,
  ProgressBarWrapper,
} from './styled';
import { theme } from './theme';

export default function ProgressBar() {
  const dispatch = useDispatch();
  const profileProgressBarClickedBehavioralFunction = useBehavioral(
    'NAV_PROFILE_PROGRESS_BAR_CLICKED'
  );
  const { data, refetch } = useMyProfileCompletion();
  const { push } = useHistory();

  useEffect(() => {
    if (data) {
      dispatch(setProfileCompletion(data.person.completion_percentage));
    }
  }, [data]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'profile_updated') {
        refetch();
      }
    });

    return () => {
      window.removeEventListener('message', (event) => {
        if (event.data === 'profile_updated') {
          refetch();
        }
      });
    };
  }, []);

  const currentProgressState = useMemo<'start' | 'middle' | 'end'>(() => {
    if (data?.person.completion_percentage < 2) {
      return 'start';
    } else if (data?.person.completion_percentage < 50) {
      return 'middle';
    } else {
      return 'end';
    }
  }, [data?.person.completion_percentage]);

  const currentProgressTooltip = useMemo<string>(() => {
    switch (currentProgressState) {
      case 'start':
        return 'Your profile needs some love. Get to 50% completion to activate it.';
      case 'middle':
        return 'Your profile needs some love. Get to 50% completion to activate it.';
      case 'end':
        return `Hooray! Your profile has been  activated! There is still ${
          100 - data?.person.completion_percentage
        }% to go if you’d like to enhance your profile.`;
    }
  }, [currentProgressState, data?.person.completion_percentage]);

  const handleClick = () => {
    profileProgressBarClickedBehavioralFunction();
    push('/my-profile');
  };

  if (!data || data?.person.completion_percentage === 100) {
    return <></>;
  }

  return (
    <ThemeProvider
      theme={{
        progress: data?.person.completion_percentage || 1.5,
        ...theme[currentProgressState],
      }}
    >
      <ProgressBarWrapper onClick={handleClick}>
        <ProgressBarInformationWrapper>
          <ProgressBarCounterContainer>
            <ProgressBarCounter>{data?.person.completion_percentage}%</ProgressBarCounter>
          </ProgressBarCounterContainer>
          <ProgressBarInformationContainer>
            <ProgressBarInformationProgressText>
              {currentProgressState == 'end' ? 'KYU PROFILE ACTIVATED' : 'ACTIVATE YOUR PROFILE'}
            </ProgressBarInformationProgressText>
          </ProgressBarInformationContainer>
        </ProgressBarInformationWrapper>
        <InfoTooltip
          size={16}
          fill={'rgba(23, 28, 51, 0.5)'}
          text={currentProgressTooltip}
          maxWidth="210px"
        />
      </ProgressBarWrapper>
    </ThemeProvider>
  );
}
