import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  ClientIcon,
  CompanyIcon,
  ProjectIcon,
} from 'app/components/DetailsPages/shared/CommonGround/Icons';
import Calendar from 'app/components/shared/icons/calendar';
import PersonIcon from 'app/components/shared/icons/person-icon';
import dayjs from 'dayjs';
import { getCompanyLogoUrl } from 'utils';

import { ClientDrawerPill, ClientDrawerText } from '../Clients/styled';
import GlobalDataTypeDrawer from '../GlobalDataType/GlobalDataTypeDrawer';
import useProjectDrawerData from '../hooks/useProjectDrawerData';
import {
  GlobalProjectDrawerDetail,
  GlobalProjectDrawerDetailsContainer,
  GlobalProjectDrawerDetailText,
  GlobalProjectDrawerHighlightContainer,
  GlobalProjectDrawerHighlightsWrapper,
  GlobalProjectDrawerHighlightTitle,
  GlobalProjectDrawerCompanyLogo,
} from './styled';

interface GlobalProjectDrawerProps {
  slug: string;
  comingFromList?: boolean;
  backToListAction?: () => void;
}

export default function GlobalProjectDrawer({
  slug,
  comingFromList = false,
  backToListAction = () => {},
}: GlobalProjectDrawerProps) {
  const query = useProjectDrawerData(slug);
  const { isLoading, data } = query;
  const { push } = useHistory();

  return (
    <GlobalDataTypeDrawer
      slug={slug}
      query={query}
      isLoading={isLoading}
      background={
        'linear-gradient(180deg, rgba(255, 255, 255, 0.3) 20.8%, rgba(159, 124, 251, 0.192) 100%), #FFFFFF'
      }
      dataType={'project'}
      title={data?.name || ''}
      includesCurrentPerson={data?.includes_current_person || ''}
      icon={<ProjectIcon fill={'#392B62'} />}
      catchphraseHighlightColor={'#392B62'}
      iconBackground={'rgba(57, 43, 98, 0.3)'}
      buttonOnClick={() => push(`/project/${data?.slug}`)}
      buttonBackgroundColor={'#392B62'}
      peopleAtCompany={data?.people_at_company || null}
      comingFromList={comingFromList}
      backToListAction={() => backToListAction()}
      drawerDetails={
        <GlobalProjectDrawerDetailsContainer>
          {data && data?.start_date ? (
            <GlobalProjectDrawerDetail>
              <Calendar width={16} height={16} fill={'#171C33'} />
              <GlobalProjectDrawerDetailText>
                {data?.start_date ? dayjs(data?.start_date).format('MMM YYYY') : 'Unknown'}
                {data?.end_date ? <> - {dayjs(data?.end_date).format('MMM YYYY')}</> : ''}
              </GlobalProjectDrawerDetailText>
            </GlobalProjectDrawerDetail>
          ) : null}
          {data && data?.highlight.people_count ? (
            <GlobalProjectDrawerDetail>
              <PersonIcon width={16} height={16} fill={'#171C33'} />
              <GlobalProjectDrawerDetailText>
                {data?.highlight.people_count}
              </GlobalProjectDrawerDetailText>
            </GlobalProjectDrawerDetail>
          ) : null}
          {data && data?.company.name ? (
            <GlobalProjectDrawerDetail>
              <CompanyIcon width={16} height={16} fill={'#171C33'} />
              <GlobalProjectDrawerDetailText>{data?.company.name}</GlobalProjectDrawerDetailText>
            </GlobalProjectDrawerDetail>
          ) : null}
        </GlobalProjectDrawerDetailsContainer>
      }
    >
      {data && (
        <GlobalProjectDrawerHighlightsWrapper>
          <GlobalProjectDrawerHighlightContainer>
            <GlobalProjectDrawerHighlightTitle>COMPANY</GlobalProjectDrawerHighlightTitle>
            <GlobalProjectDrawerCompanyLogo src={getCompanyLogoUrl(data.company.slug)} />
          </GlobalProjectDrawerHighlightContainer>
          {data && data?.client && (
            <GlobalProjectDrawerHighlightContainer>
              <GlobalProjectDrawerHighlightTitle>CLIENT</GlobalProjectDrawerHighlightTitle>
              <ClientDrawerPill>
                <ClientIcon width={13} height={13} fill={'#104D5B'} />
                <ClientDrawerText>{data?.client.name}</ClientDrawerText>
              </ClientDrawerPill>
            </GlobalProjectDrawerHighlightContainer>
          )}
        </GlobalProjectDrawerHighlightsWrapper>
      )}
    </GlobalDataTypeDrawer>
  );
}
