import React from 'react';

import {
  ActivitesIcon,
  BrandAssetsIcon,
  EventRecordingsIcon,
  MiscellaneousLinkIcon,
  ThinkPiecesIcon,
} from 'app/components/shared/icons';

import { IResource } from './ResourceCard';
import { ResourcesContentFiltersTypeIconContainer } from './ResourcesContent/ResourcesContentFilters/styled';
import { IResourcesFilter } from './ResourcesFilter';

export enum QuickFilter {
  ALL = 'all',
  EVENT_RECORDINGS = 'event recordings',
  ACTIVITIES = 'activities',
  BRAND_ASSETS = 'assets',
  THINK_PIECES = 'thought leadership',
  MISCELLANEOUS = 'miscellaneous',
}

export type QuickFilterType = `${QuickFilter}`;

export const typeToBackground = new Map<QuickFilterType, string>([
  [QuickFilter.ALL, 'var(--color-primary)'],
  [QuickFilter.EVENT_RECORDINGS, '#EABCB3'],
  [QuickFilter.ACTIVITIES, '#FFDF8F'],
  [QuickFilter.BRAND_ASSETS, '#CCE4FF'],
  [QuickFilter.THINK_PIECES, '#7292B6'],
  [QuickFilter.MISCELLANEOUS, '#C4C4C4'],
]);

export const typeToTitle = new Map<QuickFilterType, string>([
  [QuickFilter.ALL, 'All'],
  [QuickFilter.EVENT_RECORDINGS, 'Event Recaps'],
  [QuickFilter.ACTIVITIES, 'Activities'],
  [QuickFilter.BRAND_ASSETS, 'Assets'],
  [QuickFilter.THINK_PIECES, 'Thought Leaderships'],
  [QuickFilter.MISCELLANEOUS, 'Miscellaneous'],
]);

export const typeToSingularTitle = new Map<QuickFilterType, string>([
  [QuickFilter.ALL, 'All'],
  [QuickFilter.EVENT_RECORDINGS, 'Event Recap'],
  [QuickFilter.ACTIVITIES, 'Activity'],
  [QuickFilter.BRAND_ASSETS, 'Asset'],
  [QuickFilter.THINK_PIECES, 'Thought Leadership'],
  [QuickFilter.MISCELLANEOUS, 'Miscellaneous'],
]);

export const typeToIcon = new Map<QuickFilterType, JSX.Element>([
  [QuickFilter.EVENT_RECORDINGS, <EventRecordingsIcon key={0} />],
  [QuickFilter.ACTIVITIES, <ActivitesIcon key={1} />],
  [QuickFilter.BRAND_ASSETS, <BrandAssetsIcon key={2} />],
  [QuickFilter.THINK_PIECES, <ThinkPiecesIcon key={3} width={20} height={20} />],
  [QuickFilter.MISCELLANEOUS, <MiscellaneousLinkIcon key={4} />],
]);

export const typeOptions: IResourcesFilter<QuickFilter>[] = [
  {
    label: 'Activities',
    value: QuickFilter.ACTIVITIES,
    icon: (
      <ResourcesContentFiltersTypeIconContainer type={QuickFilter.ACTIVITIES}>
        {React.cloneElement(typeToIcon.get(QuickFilter.ACTIVITIES), {
          width: 14,
          height: 14,
        })}
      </ResourcesContentFiltersTypeIconContainer>
    ),
  },
  {
    label: 'Assets',
    value: QuickFilter.BRAND_ASSETS,
    icon: (
      <ResourcesContentFiltersTypeIconContainer type={QuickFilter.BRAND_ASSETS}>
        {React.cloneElement(typeToIcon.get(QuickFilter.BRAND_ASSETS), {
          width: 14,
          height: 14,
        })}
      </ResourcesContentFiltersTypeIconContainer>
    ),
  },
  {
    label: 'Event Recaps',
    value: QuickFilter.EVENT_RECORDINGS,
    icon: (
      <ResourcesContentFiltersTypeIconContainer type={QuickFilter.EVENT_RECORDINGS}>
        {React.cloneElement(typeToIcon.get(QuickFilter.EVENT_RECORDINGS), {
          width: 14,
          height: 14,
        })}
      </ResourcesContentFiltersTypeIconContainer>
    ),
  },
  {
    label: 'Miscellaneous',
    value: QuickFilter.MISCELLANEOUS,
    icon: (
      <ResourcesContentFiltersTypeIconContainer type={QuickFilter.MISCELLANEOUS}>
        {React.cloneElement(typeToIcon.get(QuickFilter.MISCELLANEOUS), {
          width: 14,
          height: 14,
        })}
      </ResourcesContentFiltersTypeIconContainer>
    ),
  },
  {
    label: 'Thought Leadership',
    value: QuickFilter.THINK_PIECES,
    icon: (
      <ResourcesContentFiltersTypeIconContainer type={QuickFilter.THINK_PIECES}>
        {React.cloneElement(typeToIcon.get(QuickFilter.THINK_PIECES), {
          width: 14,
          height: 14,
        })}
      </ResourcesContentFiltersTypeIconContainer>
    ),
  },
];
