import React from 'react';

export default function FeedbackIcon({ width = 20, height = 20, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4168 4.16667C6.6404 4.16667 3.75016 6.86815 3.75016 10C3.75016 11.1261 4.11541 12.1822 4.75777 13.0828C4.9188 13.3085 4.95697 13.5996 4.8596 13.8593L4.22352 15.5555L6.70757 15.0587C6.88331 15.0235 7.06569 15.046 7.22767 15.1227C8.22347 15.5943 9.31217 15.8371 10.414 15.8333L10.4168 15.8333C14.1933 15.8333 17.0835 13.1319 17.0835 10C17.0835 6.86815 14.1933 4.16667 10.4168 4.16667ZM2.0835 10C2.0835 5.76852 5.90993 2.5 10.4168 2.5C14.9237 2.5 18.7502 5.76852 18.7502 10C18.7502 14.231 14.9245 17.4993 10.4182 17.5H10.4168V16.6667L10.4197 17.5L10.4182 17.5C9.16234 17.5041 7.92037 17.2468 6.77099 16.7457C6.6849 16.7081 6.856 16.7859 6.77099 16.7457L3.08026 17.4838C2.78356 17.5432 2.47801 17.437 2.282 17.2065C2.08598 16.976 2.03031 16.6574 2.13656 16.3741L3.14916 13.6738C3.06137 13.5507 3.22913 13.8018 3.14916 13.6738C2.47371 12.5922 2.0835 11.3388 2.0835 10Z"
        fill={fill}
      />
    </svg>
  );
}
