import styled from 'styled-components';

interface CountBubbleWrapperProps {
  size: number;
  borderColor: string;
  borderSize: number;
  backgroundColor: string;
  onClick?: Function;
}

export const CountBubbleWrapper = styled.div<CountBubbleWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ size }) => `
    height: ${size}px;
    width: ${size}px;
  `}

  background-color: ${({ backgroundColor }) => backgroundColor};

  border-radius: 100%;
  border: ${({ borderSize, borderColor }) => `${borderSize}px solid ${borderColor}`};
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};
`;

interface CountBubbleNumberProps {
  color: string;
}

export const CountBubbleNumber = styled.span<CountBubbleNumberProps>`
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: ${({ color }) => color};
`;