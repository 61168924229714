import React from 'react';

export default function DownloadIcon({ width = 19, height = 19, fill = '#1C1B1F' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.16237 18.73C1.56171 18.73 1.05115 18.5198 0.630692 18.0993C0.210231 17.6788 0 17.1683 0 16.5676V13.3241H1.80198V16.5676C1.80198 16.6477 1.84202 16.7278 1.92211 16.8079C2.0022 16.888 2.08228 16.928 2.16237 16.928H15.8574C15.9375 16.928 16.0176 16.888 16.0977 16.8079C16.1777 16.7278 16.2178 16.6477 16.2178 16.5676V13.3241H18.0198V16.5676C18.0198 17.1683 17.8095 17.6788 17.3891 18.0993C16.9686 18.5198 16.4581 18.73 15.8574 18.73H2.16237ZM9.00988 14.0749L3.87425 8.93926L5.13563 7.61781L8.1089 10.5911V0.5H9.91087V10.5911L12.8841 7.61781L14.1455 8.93926L9.00988 14.0749Z"
        fill={fill}
      />
    </svg>
  );
}
