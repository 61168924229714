import React from 'react';

export default function DeleteIcon({ width = 17, height = 18, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 18C2.95 18 2.47933 17.8043 2.088 17.413C1.696 17.021 1.5 16.55 1.5 16V3H0.5V1H5.5V0H11.5V1H16.5V3H15.5V16C15.5 16.55 15.3043 17.021 14.913 17.413C14.521 17.8043 14.05 18 13.5 18H3.5ZM13.5 3H3.5V16H13.5V3ZM5.5 14H7.5V5H5.5V14ZM9.5 14H11.5V5H9.5V14Z"
        fill={fill}
      />
    </svg>
  );
}
