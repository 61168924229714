import styled from 'styled-components';

export const MobileMenuSearchWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100%;

  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }

  background: rgba(23, 28, 51, 0.70);
  backdrop-filter: blur(24px);
  z-index: 999999999999999;

  padding: 24px 32px;
`;

export const MobileMenuSearchTitleContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const MobileMenuSearchCloseContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  width: 24px;
  height: 24px;
`;

export const MobileMenuSearchTitle = styled.h4`
  max-width: 254px;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  color: #FFF;
`;
