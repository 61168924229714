import { HTMLMotionProps, motion } from 'framer-motion';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const OnboardingWrapper = styled(motion.div).attrs(
  (): HTMLMotionProps<'div'> => ({
    animate: {
      transform: 'translate(-50%, 0)',
      transition: {
        duration: 0.3,
        ease: 'easeInOut',
        bounce: 0,
      },
    },
    initial: {
      transform: 'translate(-50%, 105%)',
    },
  })
)`
  width: 100%;
  max-width: 1440px;
  height: 737px;
  max-height: 90vh;

  ${BREAKPOINTS.mobile} {
    height: 90svh;
  }

  border-radius: 32px 32px 0px 0px;

  position: fixed;
  bottom: 0;
  left: 50%;
  right: 50%;

  background: var(--color-data-people-background);

  z-index: 99999999;
`;

export const OnboardingBackgroundBlur = styled(motion.div).attrs(
  (): HTMLMotionProps<'div'> => ({
    animate: {
      opacity: [0, 1],
      transition: {
        duration: 0.3,
        ease: 'easeInOut',
        bounce: 0,
      },
    },
  })
)`
  &:after {
    background: rgba(23, 28, 51, 0.3);
    content: '';
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(14px);
    z-index: 99999998;
  }

  position: relative;
`;

interface OnboardingBackgroundProps {
  backgroundPosition: string;
}

const onboardingBackgroundStepMap = new Map<number, string>([
  [1, 'linear-gradient(90deg, #FFF3D5 0%, #FAC877 100%)'],
  [2, 'linear-gradient(90deg, #FAC877 0%, #FDC29E 100%)'],
  [3, 'linear-gradient(90deg, #FDC29E 0%, #F1CDC4 100%)'],
  [4, 'linear-gradient(90deg, #F1CDC4 0%, #CEB0C1 100%)'],
  [5, 'linear-gradient(90deg, #CEB0C1 0%, #C6B4F9 100%)'],
  [6, 'linear-gradient(90deg, #C6B4F9 0%, #A0D3D8 100%)'],
]);

export const OnboardingBackgroundContainer = styled.div<OnboardingBackgroundProps>`
  width: 100%;
  height: 100%;

  position: absolute;
  overflow: hidden;
  border-radius: 32px 32px 0px 0px;

  transition: 0.3s all ease;

  ${({ theme, backgroundPosition }) =>
    !theme.isMobile
      ? `
    &:before {
      content: '';
      background: url(/images/onboarding/onboarding-background.png);
      transform: rotate(-90deg);
      width: 739px;
      height: 1440px;

      position: absolute;
      top: -351px;
      left: 350px;
      bottom: 0px;
      right: 0px;

      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: ${backgroundPosition};
      transition: 0.3s all ease;
    }
  `
      : `
    background: ${onboardingBackgroundStepMap.get(theme.currentStep)};
  `}

  ${BREAKPOINTS.tablet} {
    border-radius: 0px;
  }
`;

export const OnboardingContainer = styled.div`
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.currentStep !== 1 && 'overflow: scroll;'}

  position: relative;
  z-index: 1;
`;

export const OnboardingStepButton = styled(motion.button)`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 80px;

  border-radius: 100%;

  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);

  background: #ffffff;
  transition: 0.3s background ease;

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  cursor: pointer;
  z-index: 1;

  ${BREAKPOINTS.mobile} {
    width: 40px;
    height: 60px;
  }
`;

export const OnboardingPreviousStepButton = styled(OnboardingStepButton).attrs(
  (props): HTMLMotionProps<'button'> => {
    const isMobile = props.theme.isMobile;

    const desktopVariant: HTMLMotionProps<'button'> = {
      variants: {
        initial: {
          top: '-29px',
          right: '50%',
          left: '50%',
          width: '194px',
          height: '194px',
          transform: 'translateX(-50%)',
          borderRadius: '100px',
          transition: {
            duration: 0.3,
            ease: 'easeInOut',
            bounce: 0,
          },
        },
      },
    };

    const mobileVariant: HTMLMotionProps<'button'> = {
      variants: {
        initial: {
          top: '-29px',
          right: '50%',
          left: '50%',
          width: '136px',
          height: '136px',
          transform: 'translateX(-50%)',
          borderRadius: '100px',
          transition: {
            duration: 0.3,
            ease: 'easeInOut',
            bounce: 0,
          },
        },
      },
    };

    return isMobile ? mobileVariant : desktopVariant;
  }
)`
  left: 88px;
  top: 50%;

  & > svg {
    transform: rotate(180deg);
  }

  ${BREAKPOINTS.tablet} {
    left: 58px;
  }

  ${BREAKPOINTS.mobile} {
    left: 0px;
    top: 50%;
    border-radius: 0px 100px 100px 0px;

    ${({ theme }) => theme.currentStep === 6 && 'top: calc(100% - 120px);'}
  }
`;

interface OnboardingNextStepButtonProps {
  mobileMarginLeft?: number;
}

export const OnboardingNextStepButton = styled(OnboardingStepButton).attrs(
  (props): HTMLMotionProps<'button'> => {
    const isMobile = props.theme.isMobile;

    const desktopVariant: HTMLMotionProps<'button'> = {
      variants: {
        initial: {
          bottom: '88px',
          right: '50%',
          left: 'unset',
          width: '174px',
          height: '50px',
          borderRadius: '100px',
          transition: {
            duration: 0.3,
            ease: 'easeInOut',
            bounce: 0,
          },
        },
      },
    };

    const mobileVariant: HTMLMotionProps<'button'> = {
      variants: {
        initial: {
          bottom: '88px',
          right: 'calc(50% - 87px)',
          left: 'unset',
          width: '174px',
          height: '50px',
          borderRadius: '100px',
          transition: {
            duration: 0.3,
            ease: 'easeInOut',
            bounce: 0,
          },
        },
      },
    };

    return isMobile ? mobileVariant : desktopVariant;
  }
)<OnboardingNextStepButtonProps>`
  right: 88px;
  bottom: calc(50% - 80px);

  ${BREAKPOINTS.tablet} {
    right: 58px;
  }

  ${BREAKPOINTS.mobile} {
    right: 0px;
    border-radius: 100px 0px 0px 100px;
    bottom: calc(50% - 60px);
    svg {
      margin-left: ${(props) => props.mobileMarginLeft}px;
    }

    ${({ theme }) => theme.currentStep === 6 && 'bottom: 60px;'}
  }
`;

export const OnboardingStepInformationContainer = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  ${BREAKPOINTS.mobile} {
    display: none;
  }

  width: 123px;
`;

export const OnboardingPreviousStepInformationContainer = styled(
  OnboardingStepInformationContainer
)`
  left: 67px;
  top: calc(50% + 104px);

  ${BREAKPOINTS.tablet} {
    left: 37px;
  }
`;

export const OnboardingNextStepInformationContainer = styled(OnboardingStepInformationContainer)`
  right: 67px;
  top: calc(50% + 104px);

  ${BREAKPOINTS.tablet} {
    right: 37px;
  }

  ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

export const OnboardingStepTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: rgba(23, 28, 51, 0.5);
`;

export const OnboardingStepSubtitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: var(--color-text);

  text-align: center;
  max-width: 100px;
`;

export const OnboardingCloseButton = styled.button`
  position: absolute;
  top: 32px;
  right: 32px;

  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  background: rgba(255, 255, 255, 0.5);

  cursor: pointer;
  z-index: 2;

  ${BREAKPOINTS.mobile} {
    top: 10px;
    right: 16px;
  }
`;
