import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { withHighlight } from 'app/hocs/withHighlight';
import {
  searchHighlightsSelector,
  searchOnboardingStepSelector,
  setSearchOnboardingStep,
} from 'store/reducers/user-reducer';

import { SearchContext } from '../SearchContext';
import useSearch from '../hooks/useSearch';
import {
  SearchListItem,
  SearchListItemContainer,
  SearchListItemInfo,
  SearchListWrapper,
} from './styled';

interface IItem {
  uuid: string;
  slug: string;
  name: string;
  info?: string;
  search_ranking?: number;
  datatype: string;
  highlighted?: boolean;
}

interface IHighlightedItem {
  uuid: string;
  slug: string;
  name: string;
  type: string;
  shared: boolean;
}

const SearchList = () => {
  const context = useContext(SearchContext);
  const searchQuery = useSearch(context.inputValue, { enabled: false });
  const { isLoading, isError, data } = searchQuery;

  useEffect(() => {
    context.setIsLoading(isLoading);
    if (data) {
      context.setSearchResultsAmount(data.length);
    }
  }, [isLoading]);

  useEffect(() => {
    if (context.inputValue && context.inputValue.length > 0) {
      searchQuery.refetch();
    }
  }, [context.inputValue]);

  const inputHasValue = useMemo(() => {
    return context.inputValue && context.inputValue.length > 0;
  }, [context.inputValue]);

  if (!inputHasValue) return <SearchListHighlightedSearches />;

  return (
    <SearchListWrapper>
      {!isLoading &&
        !isError &&
        data?.map((item: IItem) => <ListItem key={item?.uuid} item={item} withBold />)}
    </SearchListWrapper>
  );
};

function SearchListHighlightedSearches() {
  const highlightedSearches = useSelector(searchHighlightsSelector) as any[];
  return (
    <SearchListWrapper>
      {highlightedSearches.slice(0, 5).map((item: IHighlightedItem) => (
        <ListItem key={item.uuid} item={{ ...item, datatype: item.type, highlighted: true }} />
      ))}
    </SearchListWrapper>
  );
}

interface ListItemProps {
  item: IItem;
  withBold?: boolean;
}

function ListItem({ item, withBold = false }: ListItemProps) {
  const context = useContext(SearchContext);
  const dispatch = useDispatch();
  const searchOnboardingStep = useSelector(searchOnboardingStepSelector);
  const { push } = useHistory();
  const typeToRoute = new Map<string, string>([
    ['passions', '/passion'],
    ['companies', '/member-company'],
    ['member_companies', '/member-company'],
    ['industries', '/industry'],
    ['kyuskills', '/skill'],
    ['kyu_skills', '/skill'],
    ['people', '/person'],
    ['convenings', '/convening'],
    ['projects', '/project'],
    ['tools', '/tools'],
    ['articles', '/articles'],
    ['clients', '/client'],
    ['industries', '/industry'],
    ['communities', '/community'],
    ['resources', '/resources'],
    ['disciplines', '/discipline'],
    ['kyu', ''],
  ]);

  const handleItemClick = (item: IItem) => {
    if (typeToRoute.has(item.datatype)) {
      push(
        `${typeToRoute.get(item.datatype)}/${item.slug}`,
        item.highlighted
          ? {
              behavioralQueryType: 'highlighted-search',
            }
          : {
              behavioralQueryType: 'search',
            }
      );
    }

    if (context.onResultClick) {
      context.onResultClick(item);
    }

    if (searchOnboardingStep === 1) {
      dispatch(setSearchOnboardingStep(2));
    }

    context.setIsSubmenuOpen(false);
  };

  if (withBold) {
    const highlighted = highlightMatch(context.inputValue, item.name);

    return (
      <SearchListItemContainer>
        <SearchListItem onClick={() => handleItemClick(item)}>
          <span dangerouslySetInnerHTML={{ __html: highlighted.text }} />
        </SearchListItem>
        <SearchListItemInfo>{item.info}</SearchListItemInfo>
      </SearchListItemContainer>
    );
  }

  return (
    <SearchListItemContainer>
      <SearchListItem onClick={() => handleItemClick(item)}>{item.name}</SearchListItem>
      <SearchListItemInfo>{item.info}</SearchListItemInfo>
    </SearchListItemContainer>
  );
}

function highlightMatch(searchTerm, text) {
  const escapedTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(escapedTerm, 'gi');
  text = text.replace(regex, (m) => '<span class="highlighted">' + m + '</span>');
  return { text };
}

export default withHighlight(SearchList, 'search-list');
