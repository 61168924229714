import styled from "styled-components";

export const PublicationDefaultButton = styled.button`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;

  margin-top: 32px;

  border: 1px solid var(--color-primary);
  transition: all 250ms linear;

  & > :not(:last-child) {
    margin-right: 18px;
  }

  & > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--color-primary);

    transition: all 250ms linear;
  }
`;