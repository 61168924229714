import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CountBubble from 'app/components/CommonStyled/CountBubble';
import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import { useResourceClick } from 'app/components/Resources/hooks/useResourceClick';
import { FullHeartIcon } from 'app/components/shared/icons';
import dayjs from 'dayjs';
import useBehavioral from 'hooks/useBehavioral';
import useIsLiked from 'hooks/useIsLiked';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { ToolsColumn, ToolsRow } from '../../Layout/styled';
import { IRelatedEntityCollection, IToolRelatedArticleOrTool } from '../../interfaces/tools';
import { EditorialH3 } from '../../styled';
import PublicationButton from '../PublicationButton';
import PublicationDescription from '../PublicationDescription';
import {
  ArticleIndexPublicationCollectionName,
  ArticleIndexPublicationCollectionsContainer,
  ArticleIndexPublicationTopInformationContainer,
  PublicationContributorsWrapper,
  PublicationImage,
  PublicationImageContainer,
  PublicationImagePeopleContainer,
  PublicationInformationContainer,
  PublicationLikesContainer,
  PublicationLikesText,
  PublicationTitle,
  PublicationType,
  PublicationWrapper,
} from './styled';

type PublicationType =
  | 'full-size'
  | 'half-size-small'
  | 'half-size-big'
  | 'horizontal'
  | 'vertical'
  | 'mobile'
  | 'mobile-full-size'
  | 'article-index';

interface PublicationProps {
  publication: IToolRelatedArticleOrTool;
  type?: PublicationType;
  buttonClick?: () => void;
  forceUseThumbnail?: boolean;
  onClickBehavioral?: () => void;
}

const typeToTitle = new Map<string, string>([
  ['tools', 'TOOL'],
  ['articles', 'ARTICLE'],
  ['communities', 'COMMUNITY'],
  ['resources', 'RESOURCE'],
  ['convenings', 'CONVENING'],
]);

const typeToUrlModel = new Map<string, string>([
  ['tools', 'tools'],
  ['articles', 'articles'],
  ['communities', 'community'],
  ['resources', 'resources'],
  ['convenings', 'kin'],
]);

export default function Publication({
  publication,
  type,
  forceUseThumbnail = false,
  onClickBehavioral = null,
}: PublicationProps) {
  const { push } = useHistory();
  const handleResourceClick = useResourceClick();
  const dimensions = useWindowDimensions();
  const isMobile = dimensions && dimensions.width <= 1024;
  const continueExploringClickBehavioral = useBehavioral('CONTINUE_EXPLORING_CLICKED');

  publication = useMemo(() => {
    return {
      ...publication,
      header_image: forceUseThumbnail ? publication.thumbnail_image : publication.header_image,
    };
  }, [publication, forceUseThumbnail]);

  const handleButtonClick = () => {
    if (onClickBehavioral) {
      onClickBehavioral();
    } else {
      continueExploringClickBehavioral({
        object_uuid: publication.uuid,
        object_type: publication.type,
      });
    }

    if (publication.type === 'resources') {
      handleResourceClick({
        slug: publication.slug,
        go_to_detail_page: publication.go_to_detail_page,
        resource_type_name: publication.resource_type_name,
        link: publication.link,
        behavioralAction: () => {
          continueExploringClickBehavioral({
            object_uuid: publication.uuid,
            object_type: publication.type,
          });
        },
      });
    } else {
      push({
        pathname: `/${typeToUrlModel.get(publication.type)}/${publication.slug}`,
        state: { scrollToTop: true },
      });
    }
  };

  if (isMobile) {
    if (type === 'full-size' || type === 'mobile-full-size') {
      return (
        <MobileFullSizePublication publication={publication} buttonClick={handleButtonClick} />
      );
    }

    if (type === 'article-index') {
      return (
        <ArticleIndexMobilePublication publication={publication} buttonClick={handleButtonClick} />
      );
    }

    return <MobilePublication publication={publication} buttonClick={handleButtonClick} />;
  }

  switch (type) {
    case 'full-size':
      return <FullSizePublication publication={publication} buttonClick={handleButtonClick} />;
    case 'half-size-small':
      return <HalfSizeSmallPublication publication={publication} buttonClick={handleButtonClick} />;
    case 'half-size-big':
      return <HalfSizeBigPublication publication={publication} buttonClick={handleButtonClick} />;
    case 'horizontal':
      return <HorizontalPublication publication={publication} buttonClick={handleButtonClick} />;
    case 'article-index':
      return <ArticleIndexPublication publication={publication} buttonClick={handleButtonClick} />;
    case 'mobile-full-size':
      return (
        <MobileFullSizePublication publication={publication} buttonClick={handleButtonClick} />
      );
    default:
      return (
        <PublicationWrapper type={'full-size'}>
          <PublicationImageContainer>
            <PublicationImage id="publication-image" src={publication.header_image} />
          </PublicationImageContainer>
          <PublicationInformationContainer padding={'120px 0px'}>
            <PublicationType>{typeToTitle.get(publication.type)}</PublicationType>
            <PublicationTitle>{publication.name}</PublicationTitle>
            <PublicationDescription
              type={publication.type}
              description={publication.short_description}
            />
            <PublicationButton type={publication.type} />
          </PublicationInformationContainer>
        </PublicationWrapper>
      );
  }
}

function FullSizePublication({ publication, buttonClick }: PublicationProps) {
  const [height, setHeight] = useState<number>(0);

  const informationRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const hasContributors = useMemo(() => {
    return publication.contributors && publication.contributors.length > 0;
  }, [publication.contributors]);

  return (
    <PublicationWrapper hasContributors={hasContributors} onClick={buttonClick} type={'full-size'}>
      <ToolsRow gridTemplateColumns="repeat(7, 1fr) 0px repeat(4, 1fr)">
        {publication.header_image ? (
          <ToolsColumn size={7}>
            <PublicationImageContainer>
              <PublicationImage
                id="publication-image"
                src={publication.header_image}
                height={`${height}px`}
              />
              <PublicationImagePeopleContainer>
                {publication.contributors_more_count ? (
                  <CountBubble
                    color={'#FFFFFF'}
                    size={88}
                    number={
                      publication.contributors_more_count > 10
                        ? '+9'
                        : `${publication.contributors_more_count}`
                    }
                  />
                ) : null}
                {publication.contributors &&
                  publication.contributors.map((contributor, index) => (
                    <PersonAvatar
                      key={contributor.slug}
                      size={88}
                      avatar={contributor.avatar}
                      name={contributor.first_name}
                      lastName={contributor.last_name}
                      highlighted
                    />
                  ))}
              </PublicationImagePeopleContainer>
            </PublicationImageContainer>
          </ToolsColumn>
        ) : null}
        {publication.header_image ? <ToolsColumn size={1} /> : null}
        <ToolsColumn size={publication.header_image ? 4 : 12}>
          <PublicationInformationContainer ref={informationRef} padding={'120px 0px'}>
            <PublicationType>{typeToTitle.get(publication.type)}</PublicationType>
            <EditorialH3>{publication.name}</EditorialH3>
            <PublicationDescription
              type={publication.type}
              description={publication.short_description}
              fontSize={32}
              lineHeight={'42px'}
            />
            <PublicationLikes publication={publication} />
            <PublicationButton type={publication.type} />
          </PublicationInformationContainer>
        </ToolsColumn>
      </ToolsRow>
    </PublicationWrapper>
  );
}

function HalfSizeSmallPublication({ publication, buttonClick }: PublicationProps) {
  const hasContributors = useMemo(() => {
    return publication.contributors && publication.contributors.length > 0;
  }, [publication.contributors]);

  return (
    <PublicationWrapper
      hasContributors={hasContributors}
      onClick={buttonClick}
      type={'half-size-small'}
    >
      {publication.header_image ? (
        <PublicationImageContainer>
          <PublicationImage id="publication-image" src={publication.header_image} maxHeight={380} />
          <PublicationImagePeopleContainer>
            {publication.contributors_more_count ? (
              <CountBubble
                color={'#FFFFFF'}
                size={88}
                number={
                  publication.contributors_more_count > 10
                    ? '+9'
                    : `${publication.contributors_more_count}`
                }
              />
            ) : null}
            {publication.contributors &&
              publication.contributors.map((contributor, index) => (
                <PersonAvatar
                  size={88}
                  avatar={contributor.avatar}
                  name={contributor.first_name}
                  lastName={contributor.last_name}
                  highlighted
                />
              ))}
          </PublicationImagePeopleContainer>
        </PublicationImageContainer>
      ) : null}
      <PublicationInformationContainer
        padding={publication.header_image ? '29px 0px 0px 0px' : '0px'}
      >
        <PublicationType>{typeToTitle.get(publication.type)}</PublicationType>
        <PublicationTitle>{publication.name}</PublicationTitle>
        <PublicationDescription
          type={publication.type}
          description={publication.short_description}
        />
        <PublicationLikes publication={publication} />
        <PublicationButton type={publication.type} />
      </PublicationInformationContainer>
    </PublicationWrapper>
  );
}

function HalfSizeBigPublication({ publication, buttonClick }: PublicationProps) {
  const hasContributors = useMemo(() => {
    return publication.contributors && publication.contributors.length > 0;
  }, [publication.contributors]);

  return (
    <PublicationWrapper
      hasContributors={hasContributors}
      onClick={buttonClick}
      type={'half-size-big'}
    >
      {publication.header_image ? (
        <PublicationImageContainer>
          <PublicationImage id="publication-image" src={publication.header_image} maxHeight={650} />
          <PublicationImagePeopleContainer>
            {publication.contributors_more_count ? (
              <CountBubble
                color={'#FFFFFF'}
                size={88}
                number={
                  publication.contributors_more_count > 10
                    ? '+9'
                    : `${publication.contributors_more_count}`
                }
              />
            ) : null}
            {publication.contributors &&
              publication.contributors.map((contributor, index) => (
                <PersonAvatar
                  size={88}
                  avatar={contributor.avatar}
                  name={contributor.first_name}
                  lastName={contributor.last_name}
                  highlighted
                />
              ))}
          </PublicationImagePeopleContainer>
        </PublicationImageContainer>
      ) : null}
      <PublicationInformationContainer
        padding={publication.header_image ? '37px 0px 0px 0px' : '0px'}
      >
        <PublicationType>{typeToTitle.get(publication.type)}</PublicationType>
        <PublicationTitle fontSize={70} lineHeight={'91px'}>
          {publication.name}
        </PublicationTitle>
        <PublicationDescription
          type={publication.type}
          description={publication.short_description}
          fontSize={32}
          lineHeight={'42px'}
        />
        <PublicationLikes publication={publication} />
        <PublicationButton type={publication.type} />
      </PublicationInformationContainer>
    </PublicationWrapper>
  );
}

function HorizontalPublication({ publication, buttonClick }: PublicationProps) {
  const { push } = useHistory();
  const [height, setHeight] = useState<number>(0);

  const informationRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const hasContributors = useMemo(() => {
    return publication.contributors && publication.contributors.length > 0;
  }, [publication.contributors]);

  return (
    <PublicationWrapper hasContributors={hasContributors} onClick={buttonClick} type={'horizontal'}>
      <ToolsRow>
        {publication.header_image ? (
          <ToolsColumn size={3}>
            <PublicationImageContainer>
              <PublicationImage
                id="publication-image"
                src={publication.header_image}
                height={`${height}px`}
              />
              <PublicationImagePeopleContainer>
                {publication.contributors_more_count ? (
                  <CountBubble
                    color={'#FFFFFF'}
                    size={88}
                    number={
                      publication.contributors_more_count > 10
                        ? '+9'
                        : `${publication.contributors_more_count}`
                    }
                  />
                ) : null}
                {publication.contributors &&
                  publication.contributors.map((contributor, index) => (
                    <PersonAvatar
                      size={88}
                      avatar={contributor.avatar}
                      name={contributor.first_name}
                      lastName={contributor.last_name}
                      highlighted
                    />
                  ))}
              </PublicationImagePeopleContainer>
            </PublicationImageContainer>
          </ToolsColumn>
        ) : null}
        <ToolsColumn size={publication.header_image ? 9 : 12}>
          <PublicationInformationContainer ref={informationRef} padding={'60px 0px'}>
            <PublicationType>{typeToTitle.get(publication.type)}</PublicationType>
            <PublicationTitle>{publication.name}</PublicationTitle>
            <PublicationDescription
              type={publication.type}
              description={publication.short_description}
            />
            <PublicationLikes publication={publication} />
            <PublicationButton type={publication.type} />
          </PublicationInformationContainer>
        </ToolsColumn>
      </ToolsRow>
    </PublicationWrapper>
  );
}

function MobileFullSizePublication({ publication, buttonClick }: PublicationProps) {
  const [height, setHeight] = useState<number>(0);

  const informationRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const hasContributors = useMemo(() => {
    return publication.contributors && publication.contributors?.length > 0;
  }, [publication.contributors]);

  return (
    <PublicationWrapper
      hasContributors={hasContributors}
      type={'mobile-full-size'}
      onClick={buttonClick}
    >
      <ToolsRow>
        <ToolsColumn size={12}>
          <PublicationImageContainer>
            <PublicationImage
              id="publication-image"
              src={publication.header_image || '/images/article-fallback-image.jpg'}
              maxHeight={height}
            />
          </PublicationImageContainer>
        </ToolsColumn>
      </ToolsRow>
      <ToolsRow>
        <ToolsColumn size={9}>
          <PublicationInformationContainer ref={informationRef} padding={'21px 0px'}>
            <PublicationType className="publication-type">
              {typeToTitle.get(publication.type)}
            </PublicationType>
            <PublicationTitle className="publication-title" fontSize={24} lineHeight={'100%'}>
              {publication.name}
            </PublicationTitle>
            <PublicationDescription
              type={publication.type}
              description={publication.short_description}
            />
            <PublicationContributorsWrapper className="publication-contributors">
              {publication.contributors_more_count ? (
                <CountBubble
                  color={'#FFC229'}
                  size={22}
                  number={
                    publication.contributors_more_count > 10
                      ? '+9'
                      : `${publication.contributors_more_count}`
                  }
                />
              ) : null}
              {publication.show_contributors &&
                publication.contributors &&
                publication.contributors?.map((contributor, index) => (
                  <PersonAvatar
                    size={22}
                    avatar={contributor.avatar}
                    name={contributor.first_name}
                    lastName={contributor.last_name}
                    highlighted
                  />
                ))}
            </PublicationContributorsWrapper>
            <PublicationLikes publication={publication} />
          </PublicationInformationContainer>
        </ToolsColumn>
      </ToolsRow>
    </PublicationWrapper>
  );
}

function MobilePublication({ publication, buttonClick }: PublicationProps) {
  const [height, setHeight] = useState<number>(0);

  const informationRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const hasContributors = useMemo(() => {
    return publication.contributors && publication.contributors.length > 0;
  }, [publication.contributors]);

  return (
    <PublicationWrapper hasContributors={hasContributors} type={'mobile'} onClick={buttonClick}>
      <ToolsRow>
        {publication.header_image ? (
          <ToolsColumn size={6}>
            <PublicationImageContainer>
              <PublicationImage
                id="publication-image"
                src={publication.header_image}
                height={`${height}px`}
              />
            </PublicationImageContainer>
          </ToolsColumn>
        ) : null}
        {publication.header_image ? <ToolsColumn size={1} /> : null}
        <ToolsColumn size={publication.header_image ? 5 : 12}>
          <PublicationInformationContainer ref={informationRef} padding={'24px 0px'} spaceBetween>
            <PublicationType>{typeToTitle.get(publication.type)}</PublicationType>
            <PublicationTitle>{publication.name}</PublicationTitle>
            <PublicationContributorsWrapper>
              {publication.contributors_more_count ? (
                <CountBubble
                  color={'#FFC229'}
                  size={22}
                  number={
                    publication.contributors_more_count > 10
                      ? '+9'
                      : `${publication.contributors_more_count}`
                  }
                />
              ) : null}
              {publication.contributors &&
                publication.contributors.map((contributor, index) => (
                  <PersonAvatar
                    size={22}
                    avatar={contributor.avatar}
                    name={contributor.first_name}
                    lastName={contributor.last_name}
                    highlighted
                  />
                ))}
            </PublicationContributorsWrapper>
            <PublicationLikes publication={publication} />
          </PublicationInformationContainer>
        </ToolsColumn>
      </ToolsRow>
    </PublicationWrapper>
  );
}

function ArticleIndexPublication({ publication, buttonClick }: PublicationProps) {
  const [imageHeight, setImageHeight] = useState<number>(0);
  const { push } = useHistory();
  const informationContainerRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        setImageHeight(node.getBoundingClientRect().height);
      }
    },
    [publication]
  );

  return (
    <PublicationWrapper type={'article-index'} onClick={buttonClick}>
      <PublicationImageContainer>
        <PublicationImage
          id="publication-image"
          src={publication.thumbnail_image || `/images/article-fallback-image.jpg`}
          style={{ height: `${imageHeight}px` }}
        />
      </PublicationImageContainer>
      <PublicationInformationContainer padding={'79px 0px'} ref={informationContainerRef}>
        <ArticleIndexPublicationTopInformationContainer>
          <ArticleIndexPublicationCollectionName data-id="article-published-date">
            {dayjs(publication.published_date).format('MMM D, YYYY')}
          </ArticleIndexPublicationCollectionName>
        </ArticleIndexPublicationTopInformationContainer>
        <PublicationTitle>{publication.name}</PublicationTitle>
        <PublicationDescription
          type={publication.type}
          description={publication.short_description}
        />
        <PublicationButton type={publication.type} />
      </PublicationInformationContainer>
    </PublicationWrapper>
  );
}

function ArticleIndexMobilePublication({ publication, buttonClick }: PublicationProps) {
  const { push } = useHistory();
  const [height, setHeight] = useState<number>(0);

  const informationRef = useCallback(
    (node) => {
      if (node !== null) {
        setHeight(node.getBoundingClientRect().height);
      }
    },
    [publication]
  );

  const hasContributors = useMemo(() => {
    return publication.contributors && publication.contributors.length > 0;
  }, [publication.contributors]);

  const handleCollectionClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    collection: IRelatedEntityCollection
  ) => {
    e.stopPropagation();
    push(`/collection/${collection.slug}`);
  };

  return (
    <PublicationWrapper
      hasContributors={hasContributors}
      type={'article-index'}
      onClick={buttonClick}
    >
      <PublicationImageContainer>
        <PublicationImage
          id="publication-image"
          src={publication.thumbnail_image || `/images/article-fallback-image.jpg`}
          height={`${height}px`}
        />
      </PublicationImageContainer>
      <PublicationInformationContainer
        className="information-container"
        ref={informationRef}
        padding={'24px 0px'}
        spaceBetween
      >
        <PublicationTitle>{publication.name}</PublicationTitle>
        <ArticleIndexPublicationCollectionName>
          {dayjs(publication.published_date).format('MMM D, YYYY')}
        </ArticleIndexPublicationCollectionName>
      </PublicationInformationContainer>
    </PublicationWrapper>
  );
}

function PublicationLikes({ publication }: { publication: IToolRelatedArticleOrTool }) {
  const isLiked = useIsLiked(publication.slug, publication.likes?.people.uuids);

  if (publication.likes?.count === 0) return <></>;

  return (
    <PublicationLikesContainer>
      <FullHeartIcon
        width={16}
        height={16}
        fill={isLiked ? '#D47563' : 'none'}
        border={isLiked ? '#D47563' : 'rgba(23, 28, 51, 0.5)'}
      />
      {publication.likes?.count !== 0 ? (
        <PublicationLikesText>{publication.likes?.count}</PublicationLikesText>
      ) : null}
    </PublicationLikesContainer>
  );
}
