import styled from 'styled-components';
import { blinkingAnimation, typingAnimation } from 'styles/animations';
import { BREAKPOINTS } from 'styles/media';

export const SearchInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  position: relative;
`;

export const SearchInputIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 44px;
  height: 44px;
  border-radius: 100%;

  background: var(--color-data-alumni-background);

  ${BREAKPOINTS.tablet} {
    width: 34px;
    height: 34px;
  }
`;

interface InputProps {
  hideCaret?: boolean;
}

export const Input = styled.input<InputProps>`
  flex: 1;

  font-weight: 500;
  font-size: 14px;
  color: var(--color-text);

  ${({ hideCaret }) =>
    hideCaret &&
    `
    caret-color: transparent;
  `}

  ${BREAKPOINTS.tablet} {
    font-size: 16px;
  }

  &::placeholder {
    color: var(--color-data-alumni-background);
  }

  background: none;
`;

// INPUT PLACEHOLDER
export const InputPlaceholder = styled.span`
  flex: 1;
  position: absolute;
  top: 33%;
  left: 0px;

  display: flex;
  flex-direction: row;

  font-weight: 500;
  font-size: 14px;
  color: var(--color-data-alumni-background);
`;

export const InputPlaceholderContainer = styled.div`
  display: flex;
  flex-direction: row;

  & > :last-child {
    transform: translateY(-3px);
    ${blinkingAnimation()}
  }
`;

export const InputPlaceholderTextNoAnimation = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: var(--color-data-alumni-background);
`;

export const InputPlaceholderText = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: var(--color-data-alumni-background);

  display: flex;
  flex-direction: row;

  & > svg {
    margin: 0px 4px;
    min-width: 14px;
    min-height: 14px;
  }

  overflow: hidden;
  white-space: nowrap;
  ${typingAnimation()}
`;
