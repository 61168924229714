import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowDownIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { DetailsPageNavBarItem } from 'app/components/DetailsPages/shared/NavBar/styled';
import { OnboardingContainer } from 'app/components/Onboarding/styled';
import { AnimatePresence } from 'framer-motion';
import useOnClickOutside from 'hooks/click-outside';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import useWindowsResizeWithRef from 'hooks/useWindowsResizeWithRef';
import {
  isNavbarMoreOpenSelector,
  isNavbarVisibleSelector,
  setIsNavbarMoreOpen,
} from 'store/reducers/detail-page/detail-page-reducer';
import { aboutKyuOsOpenSelector, setAboutKyuOsOpen } from 'store/reducers/onboarding-reducer';
import { ThemeProvider } from 'styled-components';

import {
  AboutKyuOsTOCWrapper,
  AboutKyuOsTOCItem,
  AboutKyuOsTOCContainer,
  AboutKyuOsTOCItemsContainer,
  AboutKyuOsTOCItemsMoreMenuContainer,
} from './styled';

interface AboutKyuOsTOCProps {
  sections: any;
  container?: Window | Element;
  fromOnboarding?: boolean;
}

const nearestIndex = (
  currentPosition,
  sectionPositionArray,
  startIndex,
  endIndex,
  container: Document | Element
) => {
  if (startIndex === endIndex) return startIndex;
  const innerHeight = container !== document ? container.clientHeight : window.innerHeight;
  const scrollY = container !== document ? container.scrollTop : window.scrollY;

  const bottom =
    Math.ceil(innerHeight + scrollY) >=
    (container !== document ? container.scrollHeight : document.documentElement.scrollHeight);

  if (bottom) return endIndex;
  else if (startIndex === endIndex - 1) {
    if (
      Math.abs(sectionPositionArray[startIndex].ref?.offsetTop - currentPosition) <
      Math.abs(sectionPositionArray[endIndex].ref?.offsetTop - currentPosition)
    )
      return startIndex;
    else return endIndex;
  } else {
    const nextNearest = ~~((startIndex + endIndex) / 2);
    const a = Math.abs(sectionPositionArray[nextNearest].ref?.offsetTop - currentPosition);
    const b = Math.abs(sectionPositionArray[nextNearest + 1].ref?.offsetTop - currentPosition);

    if (a < b) {
      return nearestIndex(
        currentPosition,
        sectionPositionArray,
        startIndex,
        nextNearest,
        container
      );
    } else {
      return nearestIndex(currentPosition, sectionPositionArray, nextNearest, endIndex, container);
    }
  }
};

export default function AboutKyuOsTOC({
  sections,
  container = window,
  fromOnboarding,
}: AboutKyuOsTOCProps) {
  const dispatch = useDispatch();
  const isNavVisible = useSelector(isNavbarVisibleSelector);
  const showMore = useSelector(isNavbarMoreOpenSelector);
  const isAboutKyuOsOpen = useSelector(aboutKyuOsOpenSelector);
  const [hiddenElements, setHiddenElements] = useState<number>(0);
  const [indexVisible, setIndexVisible] = useState<number>(0);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });

  const navBarWrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside({ current: popperElement }, () => {
    dispatch(setIsNavbarMoreOpen(false));
  });

  useEffect(() => {
    if (sections.length > 0) {
      const handleScroll = (e) => {
        const filteredItems = sections.map((section) => ({
          ref: document.querySelector(`#${section.hash}`) as HTMLElement,
        }));

        const index = nearestIndex(
          container !== window ? container.scrollTop : window.scrollY,
          filteredItems,
          0,
          filteredItems.length - 1,
          container !== window ? container : document
        );
        setIndexVisible(index);
      };

      if (container === window) {
        document.addEventListener('scroll', handleScroll);
      } else if (container) {
        container.addEventListener('scroll', handleScroll);
      }
      return () => {
        if (container === window) {
          document.removeEventListener('scroll', handleScroll);
        } else if (container) {
          container.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [sections, container]);

  const itemsContainerRef = useWindowsResizeWithRef((node) => {
    const containerSize = node.getBoundingClientRect();
    const moreButtonWidth = (node.lastChild as HTMLElement).getBoundingClientRect().width;

    const children = Array.from(node.children).filter(
      (child) => child.getAttribute('data-ignore-item') !== 'true'
    );

    const overflowChildren = children.filter(
      (child) => child.getBoundingClientRect().right > containerSize.right - moreButtonWidth - 24
    );

    if (
      overflowChildren.length === 1 &&
      overflowChildren[0].getBoundingClientRect().right < containerSize.right
    ) {
      setHiddenElements(0);
    } else if (overflowChildren.length !== hiddenElements) {
      setHiddenElements(overflowChildren.length);
    } else if (overflowChildren.length === 0) {
      setHiddenElements(0);
    }
  }, []);

  useEffect(() => {
    if (fromOnboarding && !isAboutKyuOsOpen) {
      setIndexVisible(null);
    }
  }, [isAboutKyuOsOpen, fromOnboarding]);

  const handleMoreClick = () => {
    dispatch(setIsNavbarMoreOpen(!showMore));
  };

  return (
    <ThemeProvider theme={{ isNavVisible: fromOnboarding ? true : isNavVisible }}>
      <AboutKyuOsTOCWrapper ref={navBarWrapperRef}>
        <AboutKyuOsTOCContainer>
          <AboutKyuOsTOCItemsContainer
            ref={itemsContainerRef}
            style={{ visibility: 'hidden', position: 'absolute', left: 0, right: 63 }}
          >
            {sections.map((s, index) => (
              <AboutKyuOsItem
                fromOnboarding={fromOnboarding}
                item={s}
                isInView={indexVisible === index}
                key={s.hash}
                container={container}
              />
            ))}
            <AboutKyuOsTOCItem data-ignore-item="true">MORE</AboutKyuOsTOCItem>
          </AboutKyuOsTOCItemsContainer>
          <AboutKyuOsTOCItemsContainer>
            {sections.slice(0, sections.length - hiddenElements).map((s, index) => (
              <AboutKyuOsItem
                fromOnboarding={fromOnboarding}
                item={s}
                isInView={indexVisible === index}
                key={s.hash}
                container={container}
              />
            ))}
            {hiddenElements !== 0 ? (
              <AboutKyuOsTOCItem
                data-ignore-item="true"
                ref={setReferenceElement}
                onClick={handleMoreClick}
                showMore={showMore}
              >
                MORE
                <ArrowDownIcon fill={''} width={14} height={8} />
              </AboutKyuOsTOCItem>
            ) : null}
          </AboutKyuOsTOCItemsContainer>
          {ReactDOM.createPortal(
            <AnimatePresence>
              {showMore ? (
                <AboutKyuOsTOCItemsMoreMenuContainer
                  initial={{ maxHeight: 0 }}
                  animate={{ maxHeight: 500 }}
                  exit={{ maxHeight: 0 }}
                  ref={setPopperElement}
                  style={{ ...styles.popper }}
                  {...attributes.popper}
                >
                  {sections.slice(sections.length - hiddenElements).map((item, index) => (
                    <AboutKyuOsItem
                      fromOnboarding={fromOnboarding}
                      key={index}
                      item={item}
                      isInView={indexVisible === index}
                      dropdownItem
                      container={container}
                    />
                  ))}
                </AboutKyuOsTOCItemsMoreMenuContainer>
              ) : null}
            </AnimatePresence>,
            document.getElementById('root')
          )}
        </AboutKyuOsTOCContainer>
      </AboutKyuOsTOCWrapper>
    </ThemeProvider>
  );
}

function AboutKyuOsItem({
  fromOnboarding = false,
  item,
  isInView,
  dropdownItem = false,
  container = window,
}: {
  item: any;
  isInView: boolean;
  dropdownItem?: boolean;
  container?: any;
  fromOnboarding?: boolean;
}) {
  const tocClickedBehavioralAction = useBehavioral(BEHAVIORALS.KYU101_TOC_CLICKED);
  const dispatch = useDispatch();
  const isAboutKyuOsOpen = useSelector(aboutKyuOsOpenSelector);
  const offsetTop = container !== window ? 130 : 134;

  const goToSectionInsideOnboardingFromClose = (element: HTMLElement) => {
    const onboardingContainer = document.querySelector('#about-kyu-os-onboarding');
    onboardingContainer.scrollTop = 1;

    setTimeout(() => {
      onboardingContainer.scrollTo({
        top: element.getBoundingClientRect().top + 1 - offsetTop,
        behavior: 'smooth',
      });
    }, 1000);
  };

  const handleClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    tocClickedBehavioralAction({ action_details: item.title });
    const element = document.getElementById(item.hash);

    if (fromOnboarding && !isAboutKyuOsOpen) {
      dispatch(setAboutKyuOsOpen(true));
      goToSectionInsideOnboardingFromClose(element);
      return;
    }

    if (element) {
      if (fromOnboarding) {
        const onboardingContainer = document.querySelector('#about-kyu-os-onboarding');
        onboardingContainer.scrollTo({
          top: element.getBoundingClientRect().top + onboardingContainer.scrollTop - offsetTop,
          behavior: 'smooth',
        });
      } else {
        container.scrollTo({
          top: element.getBoundingClientRect().top + container.scrollY - offsetTop,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <DetailsPageNavBarItem
      isVisible={isInView}
      data-dropdown-item={dropdownItem}
      onClick={handleClick}
    >
      {item.title}
    </DetailsPageNavBarItem>
  );
}
