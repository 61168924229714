import React from 'react';

export default function NewsIcon({ width = 24, height = 25, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 5.375C2.25 4.33979 3.08979 3.5 4.125 3.5H15.375C16.4102 3.5 17.25 4.33979 17.25 5.375V7.25H19.875C20.9102 7.25 21.75 8.08979 21.75 9.125V18.5C21.75 19.2956 21.4339 20.0587 20.8713 20.6213C20.3087 21.1839 19.5457 21.5 18.75 21.5H5.25C4.45435 21.5 3.69129 21.1839 3.12868 20.6213C2.56607 20.0587 2.25 19.2956 2.25 18.5V5.375ZM16.1519 20H5.25C4.85218 20 4.47064 19.842 4.18934 19.5607C3.90804 19.2794 3.75 18.8978 3.75 18.5V5.375C3.75 5.16821 3.91821 5 4.125 5H15.375C15.5818 5 15.75 5.16821 15.75 5.375V18.5C15.75 19.0312 15.8909 19.5478 16.1519 20ZM17.25 8.75V18.5C17.25 18.8978 17.408 19.2794 17.6893 19.5607C17.9706 19.842 18.3522 20 18.75 20C19.1478 20 19.5294 19.842 19.8107 19.5607C20.092 19.2794 20.25 18.8978 20.25 18.5V9.125C20.25 8.91821 20.0818 8.75 19.875 8.75H17.25ZM5.25 8C5.25 7.58579 5.58579 7.25 6 7.25H9C9.41421 7.25 9.75 7.58579 9.75 8V11C9.75 11.4142 9.41421 11.75 9 11.75H6C5.58579 11.75 5.25 11.4142 5.25 11V8ZM6.75 8.75V10.25H8.25V8.75H6.75ZM11.25 8C11.25 7.58579 11.5858 7.25 12 7.25H13.5C13.9142 7.25 14.25 7.58579 14.25 8C14.25 8.41421 13.9142 8.75 13.5 8.75H12C11.5858 8.75 11.25 8.41421 11.25 8ZM11.25 11C11.25 10.5858 11.5858 10.25 12 10.25H13.5C13.9142 10.25 14.25 10.5858 14.25 11C14.25 11.4142 13.9142 11.75 13.5 11.75H12C11.5858 11.75 11.25 11.4142 11.25 11ZM5.25 14C5.25 13.5858 5.58579 13.25 6 13.25H13.5C13.9142 13.25 14.25 13.5858 14.25 14C14.25 14.4142 13.9142 14.75 13.5 14.75H6C5.58579 14.75 5.25 14.4142 5.25 14ZM5.25 17C5.25 16.5858 5.58579 16.25 6 16.25H13.5C13.9142 16.25 14.25 16.5858 14.25 17C14.25 17.4142 13.9142 17.75 13.5 17.75H6C5.58579 17.75 5.25 17.4142 5.25 17Z"
        fill={fill}
      />
    </svg>
  );
}
