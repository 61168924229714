import styled from "styled-components";
import { slideFromRightAnimation, slideToRightAnimation } from "styles/animations";
import { BREAKPOINTS } from "styles/media";

interface GlobalDrawerContainerProps {
  background?: string;
  comingFromList?: boolean;
  triggerClosing?: boolean;
}

export const GlobalDrawerContainer = styled.div<GlobalDrawerContainerProps>`
  ${({ comingFromList }) => (comingFromList ? slideFromRightAnimation : ``)}
  height: 100%;
  padding: 64px 48px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  ${({ background }) =>
    background && `background: ${background};`
  }}

  ${({ triggerClosing }) => (triggerClosing ? slideToRightAnimation : ``)}
`;

export const GlobalDrawerMidSection = styled.div`
  margin-top: 48px;
  margin-bottom: 24px;
`