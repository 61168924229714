import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import {
  CommonGroundSkeletonContainer,
  CommonGroundSkeletonText,
  CommonGroundSkeletonTextLine,
  CommonGroundSkeletonTextWrapper,
  CommonGroundSkeletonTotal,
} from './styled';

export default function CommonGroundSkeleton() {
  const isMobile = useIsMobile();
  return (
    <CommonGroundSkeletonContainer>
      <CommonGroundSkeletonTotal />
      <CommonGroundSkeletonTextWrapper>
        <CommonGroundSkeletonTextLine>
          <CommonGroundSkeletonText width={isMobile ? 125 / 2 : 125} />
          <CommonGroundSkeletonText width={isMobile ? 125 / 2 : 125} />
          <CommonGroundSkeletonText width={isMobile ? 125 / 2 : 125} />
        </CommonGroundSkeletonTextLine>
        <CommonGroundSkeletonTextLine>
          <CommonGroundSkeletonText width={isMobile ? 204 / 2 : 204} />
          <CommonGroundSkeletonText width={isMobile ? 121 / 2 : 121} />
        </CommonGroundSkeletonTextLine>
        <CommonGroundSkeletonTextLine>
          <CommonGroundSkeletonText width={isMobile ? 167 / 2 : 167} />
          <CommonGroundSkeletonText width={isMobile ? 167 / 2 : 167} />
        </CommonGroundSkeletonTextLine>
        <CommonGroundSkeletonTextLine>
          <CommonGroundSkeletonText width={isMobile ? 191 / 2 : 191} />
          <CommonGroundSkeletonText width={isMobile ? 191 / 2 : 191} />
        </CommonGroundSkeletonTextLine>
        <CommonGroundSkeletonTextLine>
          <CommonGroundSkeletonText width={isMobile ? 163 / 2 : 163} />
          <CommonGroundSkeletonText width={isMobile ? 163 / 2 : 163} />
        </CommonGroundSkeletonTextLine>
      </CommonGroundSkeletonTextWrapper>
    </CommonGroundSkeletonContainer>
  );
}
