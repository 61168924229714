interface ICompany {
  gridColumnStart: number;
  gridColumnEnd: number;
  name: string;
  slug: string;
}

export const desktopCompanies: ICompany[] = [
  {
    name: 'Lexington',
    slug: 'lexington',
    gridColumnStart: 3,
    gridColumnEnd: 4,
  },
  {
    name: 'IDEO',
    slug: 'ideo',
    gridColumnStart: 5,
    gridColumnEnd: 6,
  },
  {
    name: 'Sylvain',
    slug: 'sylvain',
    gridColumnStart: 7,
    gridColumnEnd: 8,
  },
  {
    name: 'Rich Talent Group',
    slug: 'rich-talent-group',
    gridColumnStart: 9,
    gridColumnEnd: 10,
  },
  {
    name: 'BEworks',
    slug: 'beworks',
    gridColumnStart: 2,
    gridColumnEnd: 3,
  },
  {
    name: 'Godfrey Dadich Partners',
    slug: 'godfrey-dadich-partners',
    gridColumnStart: 4,
    gridColumnEnd: 5,
  },
  {
    name: 'SYPartners',
    slug: 'sypartners',
    gridColumnStart: 6,
    gridColumnEnd: 7,
  },
  {
    name: 'Napkyn',
    slug: 'napkyn',
    gridColumnStart: 8,
    gridColumnEnd: 9,
  },
  {
    name: 'Ghost Note',
    slug: 'ghost-note',
    gridColumnStart: 10,
    gridColumnEnd: 11,
  },
  {
    name: 'Digital Kitchen',
    slug: 'digital-kitchen',
    gridColumnStart: 1,
    gridColumnEnd: 2,
  },
  {
    name: 'Gehl',
    slug: 'gehl',
    gridColumnStart: 3,
    gridColumnEnd: 4,
  },
  {
    name: 'Kepler',
    slug: 'kepler',
    gridColumnStart: 5,
    gridColumnEnd: 6,
  },
  {
    name: 'Redpeak',
    slug: 'redpeak',
    gridColumnStart: 7,
    gridColumnEnd: 8,
  },
  {
    name: 'Public Digital',
    slug: 'public-digital',
    gridColumnStart: 9,
    gridColumnEnd: 10,
  },
  {
    name: 'Yard',
    slug: 'yard',
    gridColumnStart: 11,
    gridColumnEnd: 12,
  },
  {
    name: 'Atolye',
    slug: 'atolye',
    gridColumnStart: 2,
    gridColumnEnd: 3,
  },
  {
    name: 'C2',
    slug: 'c2',
    gridColumnStart: 4,
    gridColumnEnd: 5,
  },
  {
    name: 'SidLee',
    slug: 'sid-lee',
    gridColumnStart: 6,
    gridColumnEnd: 7,
  },
  {
    name: 'Bimm',
    slug: 'bimm',
    gridColumnStart: 8,
    gridColumnEnd: 9,
  },
  {
    name: 'Haigo',
    slug: 'haigo',
    gridColumnStart: 10,
    gridColumnEnd: 11,
  },
  {
    name: 'Upstatement',
    slug: 'upstatement',
    gridColumnStart: 5,
    gridColumnEnd: 6,
  },
  {
    name: 'Neol',
    slug: 'neol',
    gridColumnStart: 7,
    gridColumnEnd: 8,
  },
]

export const mobileCompanies: ICompany[] = [
  {
    name: 'IDEO',
    slug: 'ideo',
    gridColumnStart: 3,
    gridColumnEnd: 4,
  },
  {
    name: 'Sylvain',
    slug: 'sylvain',
    gridColumnStart: 5,
    gridColumnEnd: 6,
  },
  {
    name: 'Godfrey Dadich Partners',
    slug: 'godfrey-dadich-partners',
    gridColumnStart: 2,
    gridColumnEnd: 3,
  },
  {
    name: 'SidLee',
    slug: 'sid-lee',
    gridColumnStart: 4,
    gridColumnEnd: 5,
  },
  {
    name: 'Napkyn',
    slug: 'napkyn',
    gridColumnStart: 6,
    gridColumnEnd: 7,
  },
  {
    name: 'Rich Talent Group',
    slug: 'rich-talent-group',
    gridColumnStart: 1,
    gridColumnEnd: 2,
  },
  {
    name: 'Kepler',
    slug: 'kepler',
    gridColumnStart: 3,
    gridColumnEnd: 4,
  },
  {
    name: 'Redpeak',
    slug: 'redpeak',
    gridColumnStart: 5,
    gridColumnEnd: 6,
  },
  {
    name: 'Public Digital',
    slug: 'public-digital',
    gridColumnStart: 7,
    gridColumnEnd: 8,
  },
  {
    name: 'C2',
    slug: 'c2',
    gridColumnStart: 2,
    gridColumnEnd: 3,
  },
  {
    name: 'Atolye',
    slug: 'atolye',
    gridColumnStart: 4,
    gridColumnEnd: 5,
  },
  {
    name: 'Bimm',
    slug: 'bimm',
    gridColumnStart: 6,
    gridColumnEnd: 7,
  },
  {
    name: 'Gehl',
    slug: 'gehl',
    gridColumnStart: 1,
    gridColumnEnd: 2,
  },
  {
    name: 'Lexington',
    slug: 'lexington',
    gridColumnStart: 3,
    gridColumnEnd: 4,
  },
  {
    name: 'Yard',
    slug: 'yard',
    gridColumnStart: 5,
    gridColumnEnd: 6,
  },
  {
    name: 'Ghost Note',
    slug: 'ghost-note',
    gridColumnStart: 7,
    gridColumnEnd: 8,
  },
  {
    name: 'BEworks',
    slug: 'beworks',
    gridColumnStart: 2,
    gridColumnEnd: 3,
  },
  {
    name: 'Digital Kitchen',
    slug: 'digital-kitchen',
    gridColumnStart: 4,
    gridColumnEnd: 5,
  },
  {
    name: 'Haigo',
    slug: 'haigo',
    gridColumnStart: 6,
    gridColumnEnd: 7,
  },
  {
    name: 'Upstatement',
    slug: 'upstatement',
    gridColumnStart: 1,
    gridColumnEnd: 2,
  },
  {
    name: 'SYPartners',
    slug: 'sypartners',
    gridColumnStart: 3,
    gridColumnEnd: 4,
  },
  {
    name: 'NEOL',
    slug: 'neol',
    gridColumnStart: 7,
    gridColumnEnd: 8,
  },
];

export const unclickableCompaniesSlugs: string[] = ['bimm', 'digital-kitchen', 'haigo', 'yard'];