import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { getIndustriesDrawerList, getSkillsDrawerList } from 'services/app';

export default function useIndustriesDrawerList(
  companySlug: string,
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    `company-${companySlug}-industries-drawer-list`,
    () => getIndustriesDrawerList(companySlug).then((response) => response.data),
    {
      ...options,
      cacheTime: 600000,
    }
  );
}
