import React from 'react';

import {
  OnboardingStepThreeCardContainer,
  OnboardingStepThreeCardDescription,
  OnboardingStepThreeCardInformationContainer,
  OnboardingStepThreeCardThumbnail,
  OnboardingStepThreeCardTitle,
  OnboardingStepThreeContainer,
  OnboardingStepThreeGrid,
  OnboardingStepThreeGridContainer,
  OnboardingStepThreeLastParagraphContainer,
  OnboardingStepThreeParagraph,
  OnboardingStepThreeParagraphContainer,
  OnboardingStepThreeTitle,
  OnboardingStepThreeWrapper,
} from './styled';

export default function OnboardingStepThree() {
  const cards = [
    {
      image: '/images/onboarding/introduce-yourself.jpg',
      title: 'Introduce Yourself',
      description:
        'Sharing who you are and your work on your kyu OS profile invites connection with others.',
    },
    {
      image: '/images/onboarding/gather-around-topic.jpg',
      title: 'Host a meetup',
      description:
        'You can use kyu OS to convene others in your domain (or open to all) on topics that inspire you or even to share what’s in your portfolio.',
    },
    {
      image: '/images/onboarding/knowledge-holders.jpg',
      title: 'Look up knowledge holders',
      description:
        'You can find people based on skills, passions, previous client work and account holders for client-facing projects. Just search by topic.',
    },
    {
      image: '/images/onboarding/the-well.jpg',
      title: 'Read the latest stories & news',
      description:
        'Every month we publish some of the most inspiring stories from around the collective in The Well. Keep an eye on your inbox for new stories.',
    },
    {
      image: '/images/onboarding/map.jpg',
      title: 'Research our clients',
      description:
        'Find a wealth of information on our clients, industries, and projects. Start with kyu OS when doing your reaserch.',
    },
  ];

  return (
    <OnboardingStepThreeWrapper>
      <OnboardingStepThreeTitle>
        That brings us to the platform you’re on, kyu OS
      </OnboardingStepThreeTitle>
      <OnboardingStepThreeContainer>
        <OnboardingStepThreeParagraphContainer>
          <OnboardingStepThreeParagraph>
            You can unlock opportunities for yourself and for your company through this platform.
          </OnboardingStepThreeParagraph>
          <OnboardingStepThreeParagraph>
            What’s more — it is available exclusively to us.
          </OnboardingStepThreeParagraph>
          <OnboardingStepThreeParagraph>
            It’s an unprecedented partnership between all the member companies and one of the most
            unique things about this collective.
          </OnboardingStepThreeParagraph>
          <OnboardingStepThreeParagraph>
            These are the five most important things you should know:
          </OnboardingStepThreeParagraph>
        </OnboardingStepThreeParagraphContainer>
        <OnboardingStepThreeGrid>
          {cards.slice(0, 3).map((card, index) => (
            <OnboardingStepThreeCard card={card} key={index} />
          ))}
          <OnboardingStepThreeGridContainer>
            {cards.slice(3).map((card, index) => (
              <OnboardingStepThreeCard card={card} key={index} />
            ))}
          </OnboardingStepThreeGridContainer>
        </OnboardingStepThreeGrid>
      </OnboardingStepThreeContainer>
    </OnboardingStepThreeWrapper>
  );
}

interface OnboardingStepThreeCardProps {
  card: {
    image: string;
    title: string;
    description: string;
  };
}

function OnboardingStepThreeCard({ card }: OnboardingStepThreeCardProps) {
  return (
    <OnboardingStepThreeCardContainer>
      <OnboardingStepThreeCardThumbnail src={card.image} loading="lazy" />
      <OnboardingStepThreeCardInformationContainer>
        <OnboardingStepThreeCardTitle>{card.title}</OnboardingStepThreeCardTitle>
        <OnboardingStepThreeCardDescription>{card.description}</OnboardingStepThreeCardDescription>
      </OnboardingStepThreeCardInformationContainer>
    </OnboardingStepThreeCardContainer>
  );
}
