import React from 'react';

export default function CompassIcon({ width = 16, height = 17, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.0013 8.5C2.0013 5.18629 4.68759 2.5 8.0013 2.5C11.315 2.5 14.0013 5.18629 14.0013 8.5C14.0013 11.8137 11.315 14.5 8.0013 14.5C4.68759 14.5 2.0013 11.8137 2.0013 8.5ZM8.0013 1.16667C3.95121 1.16667 0.667969 4.44991 0.667969 8.5C0.667969 12.5501 3.95121 15.8333 8.0013 15.8333C12.0514 15.8333 15.3346 12.5501 15.3346 8.5C15.3346 4.44991 12.0514 1.16667 8.0013 1.16667ZM11.4604 5.88416C11.5403 5.6446 11.4779 5.38049 11.2994 5.20193C11.1208 5.02338 10.8567 4.96103 10.6172 5.04088L6.37715 6.45422C6.17808 6.52057 6.02187 6.67678 5.95552 6.87585L4.54218 11.1159C4.46233 11.3554 4.52468 11.6195 4.70324 11.7981C4.88179 11.9766 5.1459 12.039 5.38546 11.9591L9.62546 10.5458C9.82453 10.4794 9.98074 10.3232 10.0471 10.1242L11.4604 5.88416ZM6.22873 10.2726L7.11502 7.61372L9.77388 6.72743L8.88759 9.38629L6.22873 10.2726Z"
        fill={fill}
      />
    </svg>
  );
}
