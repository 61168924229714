import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit';

export interface ResourceSubmissionState {
  showResourceSubmissionModal: boolean;
  formData: any;
  isError: boolean;
  isSuccess: boolean;
  isFormDirty: boolean;
}

export const slice = createSlice<
  ResourceSubmissionState,
  SliceCaseReducers<ResourceSubmissionState>
>({
  name: 'resourceSubmission',
  initialState: {
    showResourceSubmissionModal: false,
    formData: null,
    isError: false,
    isSuccess: false,
    isFormDirty: false,
  },
  reducers: {
    setShowResourceSubmissionModal: (state, action: { payload: boolean }) => ({
      ...state,
      showResourceSubmissionModal: action.payload,
    }),
    setFormData: (state, action: { payload: any }) => ({
      ...state,
      formData: action.payload,
    }),
    setIsSuccess: (state, action: { payload: boolean }) => ({
      ...state,
      isSuccess: action.payload,
    }),
    setIsError: (state, action: { payload: boolean }) => ({
      ...state,
      isError: action.payload,
    }),
    setIsFormDirty: (state, action: { payload: boolean }) => ({
      ...state,
      isFormDirty: action.payload,
    }),
  },
});

export const {
  setShowResourceSubmissionModal,
  setFormData,
  setIsSuccess,
  setIsError,
  setIsFormDirty,
} = slice.actions;

export const showResourceSubmissionModalSelector = (state: {
  resourceSubmission: ResourceSubmissionState;
}) => state.resourceSubmission.showResourceSubmissionModal;

export const formDataSelector = (state: { resourceSubmission: ResourceSubmissionState }) =>
  state.resourceSubmission.formData;
export const isSuccessSelector = (state: { resourceSubmission: ResourceSubmissionState }) =>
  state.resourceSubmission.isSuccess;
export const isErrorSelector = (state: { resourceSubmission: ResourceSubmissionState }) =>
  state.resourceSubmission.isError;
export const isFormDirtySelector = (state: { resourceSubmission: ResourceSubmissionState }) =>
  state.resourceSubmission.isFormDirty;

export default slice.reducer;
