import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "kin",
  initialState: {
    allLocations: [],
    selectedLocations: [],
  },
  reducers: {
    setSelectedLocations: (state, action: { payload: string[] }) => ({
      ...state,
      selectedLocations: action.payload,
    }),
    setAllLocations: (state, action: { payload: string[] }) => ({
      ...state,
      allLocations: action.payload,
    }),
  },
});

export const { setAllLocations, setSelectedLocations } = slice.actions;

export const allLocationsSelector = (state) => state.kin.allLocations;
export const selectedLocationsSelector = (state) => state.kin.selectedLocations;

export default slice.reducer;
