const breakpoints: {
  [k: string]: { min?: number; max?: number };
} = {
  xs: {
    min: 0,
    max: 699,
  },
  sm: {
    min: 700,
    max: 899,
  },
  md: {
    min: 900,
    max: 1199,
  },
  lg: {
    min: 1200,
    max: 1799,
  },
  xl: {
    min: 1800,
  },
  minXs: {
    min: 480,
  },
  minSm: {
    min: 681,
  },
  minMd: {
    min: 1025,
  },
  minXmd: {
    min: 1140,
  },
  minXXmd: {
    min: 1240,
  },
  minLg: {
    min: 1441,
  },
};

type Breakpoint = {
  readonly [P in keyof typeof breakpoints]: string;
};

const mq = Object.keys(breakpoints).reduce(
  (bps, bp) => ({
    ...bps,
    [bp]: `@media only screen
    ${breakpoints[bp].min ? ` and (min-width: ${breakpoints[bp].min}px)` : ""}
    ${breakpoints[bp].max ? ` and (max-width: ${breakpoints[bp].max}px)` : ""}
  `,
  }),
  {}
) as Breakpoint;

const ULTRA_LARGE_SCREEN_BREAKPOINT = '2560px';
const LARGE_SCREEN_BREAKPOINT = '1440px';
const NORMAL_SCREEN_BREAKPOINT = '1200px';
const LARGE_TABLET_LANDSCAPE = '1380px';
const TABLET_BREAKPOINT = "1024px";
const SMALL_TABLET_BREAKPOINT = "820px";
const MOBILE_BREAKPOINT = "768px";

export const BREAKPOINTS = {
  mobile: `@media (max-width: ${MOBILE_BREAKPOINT})`,
  tablet: `@media (max-width: ${TABLET_BREAKPOINT})`,
  small_tablet: `@media (max-width: ${SMALL_TABLET_BREAKPOINT})`,
  large_tablet_landscape:`@media (max-width: ${LARGE_TABLET_LANDSCAPE})`,
  large: `@media (max-width: ${LARGE_SCREEN_BREAKPOINT})`,
  ultra_large: `@media (max-width: ${ULTRA_LARGE_SCREEN_BREAKPOINT})`,
  normal: `@media (max-width: ${NORMAL_SCREEN_BREAKPOINT})`,
};

export const ONLY_IOS = `@supports (-webkit-touch-callout: none)`;

export default mq;
