import {
  putBehavioralEmailCopied,
  putBehavioralMapOpen,
  putBehavioralResultsCard,
  putBehavioralResourceOpen,
  putBehavioralGetInvolvedClicked,
  putBehavioralGetInvolvedLinkClicked,
  putBehavioralBlogPostClicked,
  putBehavioralAvatarClicked,
  putBehavioralRegistrationLinkClicked,
  putBehavioralViewedBlogPostPage,
  putBehavioralGlobalMapOpen,
} from 'services/behaviourals';

export const sendEmailCopiedBehavioral = async (behavioral_id) => {
  await putBehavioralEmailCopied({ behavioral_id });
};

export const sendMapOpenBehavioral = async (behavioral_id) => {
  await putBehavioralMapOpen({ behavioral_id });
};

export const sendGlobalMapOpenBehavioral = async (behavioral_id, action_details) => {
  await putBehavioralGlobalMapOpen({ behavioral_id, action_details });
};

export const sendResultsCardBehavioral = async (behavioral_id, card_type) => {
  await putBehavioralResultsCard({ behavioral_id, card_type });
};

export const sendResourceOpenBehavioral = async (behavioral_id, resource_id) => {
  await putBehavioralResourceOpen({ behavioral_id, resource_id });
};

export const sendGetInvolvedClickedBehavioral = async (behavioral_id) => {
  await putBehavioralGetInvolvedClicked({ behavioral_id });
};

export const sendGetInvolvedLinkClickedBehavioral = async (behavioral_id, link_clicked) => {
  await putBehavioralGetInvolvedLinkClicked({ behavioral_id, link_clicked });
};

export const sendBlogPostClickedBehavioral = async (behavioral_id, action_details) => {
  await putBehavioralBlogPostClicked({ behavioral_id, action_details });
};

export const sendAvatarClickedBehavioral = async (behavioral_id, person_id) => {
  await putBehavioralAvatarClicked({ behavioral_id, person_id });
};

export const sendRegistrationLinkClickedBehavioral = async (behavioral_id, convening_id) => {
  await putBehavioralRegistrationLinkClicked({ behavioral_id, convening_id });
};

export const sendViewedBlogPostPageBehavioral = async (behavioral_id, action_details) => {
  await putBehavioralViewedBlogPostPage({ behavioral_id, action_details });
};
