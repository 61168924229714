import React, { useContext, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useSelector } from 'react-redux';

import PersonAvatar from 'app/components/CommonStyled/PersonAvatar';
import ToggleButton from 'app/components/CommonStyled/ToggleButton';
import { PassionIcon } from 'app/components/DetailsPages/shared/CommonGround/Icons';
import { DetailsPageContext } from 'app/components/DetailsPages/shared/DetailsPageContext';
import { User } from 'interfaces';
import { userDataSelector } from 'store/reducers/user-reducer';

import GlobalPassionDrawer from '../GlobalPassion/GlobalPassionDrawer';
import {
  PassionDrawerFilterOption,
  PassionDrawerPill,
  PassionDrawerText,
  PassionsDrawerContainer,
  PassionsDrawerFiltersContainer,
  PassionsDrawerFiltersDescription,
  PassionsDrawerFiltersOptionsContainer,
  PassionsDrawerFiltersTitle,
  PassionsDrawerListContainer,
  PassionUserAvatar,
} from './styled';

interface PassionsDrawerProps {
  passionsQuery?: UseQueryResult<any, Error>;
}

export default function PassionsDrawer({ passionsQuery }: PassionsDrawerProps) {
  const userData: User = useSelector(userDataSelector);

  const { isLoading, isIdle, data } = passionsQuery;
  const context = useContext(DetailsPageContext);
  const [filterSelected, setFilterSelected] = useState<string>('A-C');
  const [filterIncludeUser, setFilterIncludeUser] = useState<boolean>(false);
  const [showingPassionDrawer, setShowingPassionDrawer] = useState<boolean>(false);
  const [passionDrawerSlug, setPassionDrawerSlug] = useState<string>('');

  return (
    <PassionsDrawerContainer showingPassionDrawer={showingPassionDrawer}>
      {showingPassionDrawer ? (
        <GlobalPassionDrawer
          slug={passionDrawerSlug}
          comingFromList
          backToListAction={() => setShowingPassionDrawer(false)}
        />
      ) : (
        <>
          <PassionsDrawerFiltersContainer>
            <PassionsDrawerFiltersTitle>
              Passions At {context.data.name || userData.kyu_company?.name}
            </PassionsDrawerFiltersTitle>
            <PassionsDrawerFiltersDescription>
              These passions have been added by people at{' '}
              {context.data.name || userData.kyu_company?.name} to their profiles.
            </PassionsDrawerFiltersDescription>
            {!isLoading && !isIdle ? (
              <PassionsDrawerFiltersOptionsContainer>
                {Object.keys(data.passions).map((filterOption, index) => (
                  <PassionDrawerFilterOption
                    key={index}
                    selected={filterSelected === filterOption}
                    onClick={() => setFilterSelected(filterOption)}
                  >
                    {filterOption}
                  </PassionDrawerFilterOption>
                ))}
              </PassionsDrawerFiltersOptionsContainer>
            ) : (
              <></>
            )}
          </PassionsDrawerFiltersContainer>
          <PassionsDrawerListContainer>
            {!isLoading && !isIdle && data.any_includes_current_person && (
              <ToggleButton
                label={'Only what I share'}
                labelColor={'rgba(23, 28, 51, 0.5)'}
                onChange={(checked) => setFilterIncludeUser(checked)}
                checked={filterIncludeUser}
              />
            )}
            {!isLoading && !isIdle ? (
              <>
                {data.passions[filterSelected]
                  .filter((passion) => {
                    if (filterIncludeUser) {
                      return passion.includes_current_person;
                    }

                    return true;
                  })
                  .map((passion, index) => (
                    <PassionPill
                      key={index}
                      passion={passion}
                      userData={userData}
                      setPassionDrawerSlug={setPassionDrawerSlug}
                      setShowingPassionDrawer={setShowingPassionDrawer}
                    />
                  ))}
              </>
            ) : (
              <></>
            )}
          </PassionsDrawerListContainer>
        </>
      )}
    </PassionsDrawerContainer>
  );
}

function PassionPill({ passion, userData, setPassionDrawerSlug, setShowingPassionDrawer }) {
  return (
    <PassionDrawerPill
      onClick={() => {
        setPassionDrawerSlug(passion.slug);
        setShowingPassionDrawer(true);
      }}
    >
      <PassionIcon width={13} height={13} fill={'#D47563'} />
      <PassionDrawerText>{passion.name}</PassionDrawerText>
      {passion.includes_current_person ? (
        <PersonAvatar
          size={22}
          name={userData.first_name}
          lastName={userData.last_name}
          avatar={userData.profile_image}
          highlighted
          extraStyle={{
            position: 'absolute',
            right: '-30px',
          }}
        />
      ) : null}
    </PassionDrawerPill>
  );
}
