import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';
import { PillText } from '../DataTypePill/styled';

export const StoryCardImageWrapper = styled.div`
  position: relative;
`;

export const StoryCardImageContainer = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.imageHeight};

  border-radius: 32px 32px 0px 0px;

  overflow: hidden;
`;

export const StoryCardNoImageContainer = styled.div`
  width: 100%;
  height: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 32px 32px 0px 0px;
  background: var(--color-primary);
`;

export const StoryCardNoImageText = styled.h2`
  font-weight: bold;
  font-size: 56px;
  line-height: 12px;
  text-align: center;
  color: #FFFFFF;
`;

export const StoryCardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  transition: transform 150ms ease-in-out;
`;

export const StoryCardCompanyContainer = styled.div`
  position: absolute;
  right: 16px;
  bottom: -8px;
  z-index: 2;
`;

export const StoryCardContentWrapper = styled.div`
  width: 100%;
  flex: 1;

  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;

  border-radius: 0px 0px 32px 32px;

  &:before {
    content: '';
    width: 100%;
    height: 100%;

    z-index: 1;

    position: absolute;
    top: 0;
    left: 0;

    border-radius: 0px 0px 32px 32px;

    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(24px);

    transition: background 150ms ease-in-out;
  }
`;

export const StoryCardContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  z-index: 2;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const StoryCardType = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;

  color: rgba(23, 28, 51, 0.50);
`;

export const StoryCardTitle = styled.h6`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--color-text);

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

interface StoryCardDescriptionProps {
  type?: string;
}

export const StoryCardDescription = styled.p<StoryCardDescriptionProps>`
  font-weight: 400;
  font-size: 12px;
  line-height: normal;

  color: var(--color-text);

  ${({ type }) => type === 'event_host' && `
    ${BREAKPOINTS.tablet} {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  `}
`;

export const StoryCardButton = styled.button`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 8px 8px 16px;
  border-radius: 8px;

  background: ${({ theme }) => theme.button.background};

  &:hover {
    background: ${({ theme }) => theme.button.hover.background};

    & > span {
      color: ${({ theme }) => theme.button.hover.color};
    }
  }

  & > svg {
    transform: rotate(270deg);
    > path {
      fill: ${({ theme }) => theme.button.color};
    }
  }

  z-index: 2;
`;

export const StoryCardButtonText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;

  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.button.color};
`;

export const StoryCardEventHostBottomContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const StoryCardLatestContributionContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  z-index: 1;

  ${PillText} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const StoryCardLatestContributionTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: rgba(23, 28, 51, 0.50);
`;

export const StoryCardEventHostInformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  z-index: 1;
`;

export const StoryCardEventHostInformationName = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    font-size: 16px;
  }
`;

export const StoryCardEventHostInformationCompanyName = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: rgba(23, 28, 51, 0.50);

  ${BREAKPOINTS.mobile} {
    font-size: 12px;
  }
`;

interface StoryCardWrapperProps {
  background?: string;
}

export const StoryCardWrapper = styled.div<StoryCardWrapperProps>`
  min-width: ${({ theme }) => theme.width};
  max-width: ${({ theme }) => theme.width};
  min-height: ${({ theme }) => theme.height};

  display: flex;
  flex-direction: column;

  border-radius: 32px;
  background: ${({ background }) => background || '#FFFFFF'};
  background-position: center;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.10);

  &:hover {
    ${StoryCardImage} {
      transform: scale(1.2);
    }

    ${StoryCardContentWrapper}:before {
      background: rgba(255, 255, 255, 0.75);
    }
  }

  ${BREAKPOINTS.tablet} {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    height: 420px;
    min-height: 420px;
    max-height: 420px;
  }

  cursor: pointer !important;
`;

// MOBILE MAP BUTTON
export const StoryCardMobileMapButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 8px 8px 16px;

  border-radius: 16px;
  background: var(--light-theme-text-secondary, rgba(23, 28, 51, 0.50));

  & > :not(:last-child) {
    margin-right: 8px;
  }

  & > svg {
    min-width: 16px;
    min-height: 16px;
  }

  z-index: 1;
`;

export const StoryCardMobileMapButtonText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: #FFFFFF;

  text-align: start;
`;