import styled from 'styled-components';

export const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

//MINIMAL

//WITH ICON

//WITH SIDE ICON
export const TotalWithSideIconTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const TotalWithSideIconCounter = styled.span`
  font-weight: 400;
  font-size: 38px;
  line-height: 42px;
  text-align: center;

  color: ${({ theme }) => theme.primaryText};
`;

interface TotalWithSideIconTitleProps {
  clickable: boolean;
}

export const TotalWithSideIconTitle = styled.p<TotalWithSideIconTitleProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryText};
  opacity: 0.5;

  ${({ clickable }) => clickable && `
    &:hover {
      opacity: 1;
    }
  `}

`;

export const TotalWithSideIconTitleContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  div {
    position: absolute;
    right: -20px;
    bottom: 3px;
  }
`;
