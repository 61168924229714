import React from 'react';

import Tippy from '@tippyjs/react';
import useIsMobile from 'hooks/useIsMobile';
import useTippy from 'hooks/useTippy';
import { followCursor } from 'tippy.js';

import { TooltipText, TooltipTitle, TooltipWrapper } from './styled';

interface TooltipProps {
  title?: string;
  text: string;
  maxWidth?: string;
  children: JSX.Element;
  disableInMobile?: boolean;
  hideOnClick?: boolean;
  hide?: boolean;
}

export default function Tooltip({
  title,
  text,
  maxWidth,
  children,
  disableInMobile = false,
  hideOnClick = true,
  hide = false,
}: TooltipProps) {
  const tippyInstance = useTippy();
  const isMobile = useIsMobile();

  if (!title && !text) {
    return <>{children}</>;
  }

  if (hide) return children;

  if (disableInMobile && isMobile) return children;

  return (
    <>
      <Tippy
        popperOptions={{
          placement: 'bottom',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [8, 8],
              },
            },
          ],
        }}
        hideOnClick={hideOnClick}
        zIndex={999999999999999}
        followCursor
        plugins={[followCursor]}
        placement="bottom-start"
        animation={false}
        trigger="mouseenter"
        onShow={(instance) => {
          tippyInstance.current = instance;
        }}
        onHide={(instance) => {
          tippyInstance.current = null;
        }}
        render={(attrs) => (
          <TooltipWrapper maxWidth={maxWidth}>
            {title ? <TooltipTitle>{title}</TooltipTitle> : null}
            <TooltipText>{text}</TooltipText>
          </TooltipWrapper>
        )}
      >
        {children}
      </Tippy>
    </>
  );
}
