import styled from "styled-components";
import { BREAKPOINTS } from 'styles/media';

interface IndustriesDrawerContainerProps {
  showingIndustryDrawer?: boolean;
}

export const IndustriesDrawerContainer = styled.div<IndustriesDrawerContainerProps>`
  height: 100%;
  padding: ${({ showingIndustryDrawer }) => showingIndustryDrawer ? 0 : 32}px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 17.49%, rgba(206, 242, 158, 0.3) 100%), #FFFFFF;
`;

export const IndustriesDrawerFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 32px;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  border-bottom: 1px solid rgba(206, 242, 158, 0.4);
  backdrop-filter: blur(100px);
`;

export const IndustriesDrawerFiltersOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > *:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

interface IndustryDrawerFilterOptionProps {
  selected?: boolean;
}

export const IndustryDrawerFilterOption = styled.div<IndustryDrawerFilterOptionProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ selected }) => (selected ? "#D47563" : "#FBAFA1")};

  cursor: pointer;
`;

export const IndustriesDrawerListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px 110px 0px;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }

  height: 100%;
  overflow-y: auto;
  position: relative;
`;

export const IndustryDrawerPill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;

  & > :not(:last-child) {
    margin-right: 5px;
  }

  max-width: 80%;
  ${BREAKPOINTS.mobile} {
    max-width: 70%;
  }
  background: rgba(141, 167, 108, 0.3);
  border-radius: 26px;

  cursor: pointer;
`;

export const IndustryDrawerText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #8DA76C;
`;

export const IndustriesDrawerFiltersTitle = styled.h3`
  font-weight: 300;
  font-size: 32px;
  line-height: 42px;
  color: #171C33;
`;

export const IndustriesDrawerFiltersDescription = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #171C33;
`;

export const IndustryUserAvatar = styled.img`
  width: 22px;
  height: 22px;
  object-fit: cover;
  border: 2px solid #FFC229;
  border-radius: 1000px;

  position: absolute;
  right: -30px;
`;

export const IndustryPercentagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 4px;
  }

  position: absolute;
  right: 0px;
`;

export const IndustryPercentage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 4px;
  }
`;

interface IndustryPercentageTextProps {
  color: string;
}

export const IndustryPercentageText = styled.span<IndustryPercentageTextProps>`
  color: ${({ color }) => color};
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
`;

export const IndustriesDrawerSortingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  & > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: rgba(23, 28, 51, 0.5);
  }

  & > .sorting {
    display: flex;
    color: #171C33;
    cursor: pointer;
  }

  margin-bottom: 8px;
`;
