import React from 'react';

export default function ClearIcon({ width = 16, height = 17, onClick = (e) => {} }) {
  return (
    <svg
      onClick={onClick}
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
    >
      <circle cx="8" cy="8.5" r="6" fill="#EB823D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3566 10.8566C10.617 10.5962 10.617 10.1741 10.3566 9.91376L8.9424 8.49953L10.3566 7.08535C10.6169 6.825 10.6169 6.40289 10.3566 6.14254C10.0962 5.88219 9.67411 5.88219 9.41376 6.14254L7.99959 7.55672L6.5854 6.14253C6.32505 5.88218 5.90294 5.88218 5.64259 6.14253C5.38224 6.40288 5.38224 6.82499 5.64259 7.08534L7.05678 8.49953L5.64253 9.91378C5.38218 10.1741 5.38218 10.5962 5.64253 10.8566C5.90288 11.1169 6.32499 11.1169 6.58534 10.8566L7.99959 9.44234L9.41383 10.8566C9.67418 11.1169 10.0963 11.1169 10.3566 10.8566Z"
        fill="white"
      />
    </svg>
  );
}
