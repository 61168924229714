import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const AboutKyuOsNetworkWrapper = styled.section`
  max-width: 1000px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 32px;

  background: rgba(255, 255, 255, 0.4);
  border-radius: 20px;

  padding: 50px 15px 18px 15px;

  & > :last-child {
    margin-top: 80px;
  }
`;

export const AboutKyuOsNetworkTitle = styled.h1`
  font-weight: 400;
  font-size: 64px;
  line-height: normal;

  ${BREAKPOINTS.tablet} {
    font-size: 38px;
  }

  color: var(--color-text);
`;
