import styled from 'styled-components';

interface InfoTooltipWrapperProps {
  maxWidth?: string;
}

export const InfoTooltipWrapper = styled.div<InfoTooltipWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  background: #000000;
  border-radius: 8px;

  max-width: ${({ maxWidth }) => maxWidth || '300px'};
  z-index: 99999999;
`;

export const InfoTooltipText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
`;

export const InfoTooltipTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
`;