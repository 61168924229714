import {
  IDisciplineDetailsMeetSomeoneNewResponse,
  IDisciplineDetailsResponse,
} from 'app/components/DetailsPages/interfaces/discipline';
import { IMapResponse } from 'app/components/Map/interfaces/map';

import { http } from './http';

//DETAILS
export const getDisciplineDetails = (slug: string) => {
  return http.get<IDisciplineDetailsResponse>(`discipline/${slug}/details`);
};

export const getDisciplineDetailsCommonGround = (slug: string) => {
  return http.get(`/discipline/${slug}/details/common_ground`);
};

export const getDisciplineDetailsMapSnippet = (slug: string) => {
  return http.get(`/discipline/${slug}/details/map_snippet`);
};

export const getDisciplineDetailsMeetSomeoneNew = (slug: string) => {
  return http.get<IDisciplineDetailsMeetSomeoneNewResponse>(
    `/discipline/${slug}/details/meet_someone_new`
  );
};

export const getDisciplineDetailsPeople = (slug: string, params?: { companyFilter?: string }) => {
  return http.get(`/discipline/${slug}/details/people`, params);
};

export const getDisciplinePeopleFilters = (slug: string) => {
  return http.get(`/discipline/${slug}/details/people_filters`);
};

//DRAWERS
export const getDisciplinePeopleDrawer = (slug: string, params?: any) => {
  return http.get(`/discipline/${slug}/drawers/people`, params);
};

export const getDisciplineCompaniesDrawer = (slug: string) => {
  return http.get<any>(`discipline/${slug}/drawers/companies`);
};

export const getDisciplineProjectsDrawer = (slug: string, params: any) => {
  return http.get(`discipline/${slug}/drawers/projects`, params);
};

//MAP
export const getDisciplineMap = (slug: string) => {
  return http.get<IMapResponse>(`discipline/${slug}/maps`);
};

export const getDisciplineMapInfo = (slug: string) => {
  return http.get(`discipline/${slug}/maps/nodes`);
};
