import styled from 'styled-components';
import { slideFromRightAnimation, slideToRightAnimation } from 'styles/animations';
import { BREAKPOINTS } from 'styles/media';

interface GlobalDrawerContainerProps {
  background?: string;
  comingFromList?: boolean;
  triggerClosing?: boolean;
}

export const GlobalDrawerContainer = styled.div<GlobalDrawerContainerProps>`
  ${({ comingFromList }) => (comingFromList ? slideFromRightAnimation : ``)}
  height: 100%;
  min-height: 100svh;
  padding: 64px 48px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;

  & > :not(:last-child) {
    margin-bottom: 48px;
  }
  ${({ background }) => background && `background: ${background};`}}
  ${({ triggerClosing }) => (triggerClosing ? slideToRightAnimation : ``)}

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    overflow-y: scroll;
  }
`;

export const GlobalDrawerTopSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
`;

export const GlobalDrawerMidSection = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 142px;
  }

  ${BREAKPOINTS.tablet} {
    & > :not(:last-child) {
      margin-bottom: 76px;
    }
  }
`;

export const GlobalDrawerTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const GlobalDrawerDataType = styled.h5`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(23, 28, 51, 0.5);

  display: flex;
  & > :not(:last-child) {
    margin-right: 8px;
  }

  & > svg {
    cursor: pointer;
  }
`;

export const GlobalDrawerDataName = styled.h3`
  font-weight: 300;
  font-size: 32px;
  line-height: 42px;
  color: #171c33;
`;

export const GlobalDrawerIncludesPerson = styled.span`
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: rgba(23, 28, 51, 0.5);

  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

interface GlobalDrawerIncludesPersonActionContainerProps {
  includes: boolean;
}

export const GlobalDrawerIncludesPersonActionContainer = styled.div<GlobalDrawerIncludesPersonActionContainerProps>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  & > :not(:last-child) {
    margin-right: 2px;
  }

  & > span {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;

    color: ${({ includes }) => (includes ? 'rgba(23, 28, 51, 0.5)' : '#5478A1')};
  }

  cursor: pointer;
`;

export const GlobalDrawerIncludesPersonContainer = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

interface GlobalDrawerIncludesPersonActionAvatarProps {
  includes: boolean;
}

export const GlobalDrawerIncludesPersonActionAvatar = styled.img<GlobalDrawerIncludesPersonActionAvatarProps>`
  width: 22px;
  height: 22px;

  border-radius: 1000px;

  border: 2px solid ${({ includes }) => (includes ? '#FFC229' : '#ADADAD')};
`;

interface GlobalDrawerIconContainerProps {
  background?: string;
}

export const GlobalDrawerIconContainer = styled.div<GlobalDrawerIconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  border-radius: 100%;
  ${({ background }) => background && ` background: ${background};`}
`;

export const GlobalDrawerDetailsContainer = styled.div`
  width: 100%;
  margin-top: 49px;
`;

interface GlobalDrawerBottomSectionProps {
  type?: string;
}

export const GlobalDrawerBottomSection = styled.div<GlobalDrawerBottomSectionProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  ${({ type }) =>
    type === 'industry'
      ? `
  ${BREAKPOINTS.mobile} {
    margin-top: 100px;
  }
  `
      : ``}
`;

interface GlobalDrawerCatchphraseProps {
  highlightColor: string;
}

export const GlobalDrawerCatchphrase = styled.h4<GlobalDrawerCatchphraseProps>`
  font-weight: 300;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: rgba(23, 28, 51, 0.5);

  & > span {
    color: ${({ highlightColor }) => highlightColor};
  }
`;

interface GlobalDrawerButtonProps {
  backgroundColor: string;
}

export const GlobalDrawerButton = styled.button<GlobalDrawerButtonProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 48px;
  border-radius: 100px;
  color: #ffffff;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const GlobalDrawerPeopleAtCompanyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const GlobalDrawerPeopleAvatarsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  padding: 0px;

  & > *:not(:last-child) {
    margin-left: -8px;
  }
`;

export const GlobalDrawerPersonAtCompanyCounter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  & > :not(:last-child) {
    margin-right: 10px;
  }

  width: 32px;
  height: 32px;

  background: rgba(255, 194, 41, 0.15);
  border: 2px solid #ffc229;
  border-radius: 1000px;

  & > span {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #ffc229;
  }
`;

export const GlobalDrawerPersonAtCompanyAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 1000px;
  border: 2px solid #ffc229;
  object-fit: cover;
`;

export const GlobalDrawerPeopleAtCompanyText = styled.h6`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5478a1;
`;

export const GlobalDrawerBasicInformationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;
