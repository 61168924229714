import React from 'react';

export default function PulseIcon({ width = 12, height = 17, fill = 'black' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.4112" cy="15.0263" r="1.47368" fill={fill} />
      <circle cx="10.1998" cy="14.8158" r="1.68421" fill={fill} />
      <path
        d="M0.101562 16.5V0.499968H4.8025C7.75018 0.499963 8.57098 1.50868 9.15116 2.74154C8.6356 2.62946 7.67445 2.58463 5.59826 2.58463H2.32556V8.98584H5.59826C7.67445 8.98584 8.59077 8.94101 9.10633 8.85135C8.064 10.3644 7.02166 11.1826 4.72405 11.1826H2.32556V16.5H0.101562ZM9.10633 8.85135C9.47402 8.34699 9.65552 6.88361 9.65552 5.76282C9.65552 4.68687 9.52036 3.52609 9.15116 2.74154C10.6978 3.05536 11.8971 4.28338 11.8971 5.76282C11.8971 7.24226 10.6754 8.53753 9.10633 8.85135Z"
        fill={fill}
      />
    </svg>
  );
}
