import { RefCallback, useCallback, useEffect, useRef } from 'react';

export default function useWindowsResizeWithRef<T = Element>(
  callback: (entry?: T) => void,
  deps: any[] = [],
  delay: number = 0
): RefCallback<T> {
  const timeoutId = useRef<number | null>(null);

  const ref: RefCallback<T> = useCallback((el: T) => {
    if (el) {
      const handleResize = () => {
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
        }

        timeoutId.current = window.setTimeout(() => {
          callback(el);
        }, delay);
      };

      handleResize();

      window.addEventListener('resize', handleResize);
    }
  }, deps);

  useEffect(() => {
    return () => {
      const handleResize = () => {
        // Do nothing
      };

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      window.removeEventListener('resize', handleResize);
    };
  }, deps);

  return ref;
}
