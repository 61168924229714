import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { detailsPages } from "utils/constants";

export default function useModelAndSlug() {
  const [model, setModel] = useState<string>("");
  const [slug, setSlug] = useState<string>("");
  const { push } = useHistory();
  const location = useLocation();

  const goToDetailsPage = (toModel, toSlug) => {
    // currently sending currentUrl as state in order to be used in goBack details page button
    const currentUrl = `${location.pathname}${location.search}`;
    if (toModel !== "skills-passions")
      return push({ pathname: `/${toModel}/${toSlug}`, state: currentUrl });

    if (typeof toSlug === "string")
      return push({ pathname: `/${toModel}/${toSlug}`, state: currentUrl });

    const { passions, skills, companies } = toSlug;
    const complexSlug = `${
      passions && passions.length > 0 ? `&passions=${passions.join(";")}` : ""
    }${skills && skills.length > 0 ? `&skills=${skills.join(";")}` : ""}${
      companies && companies.length > 0
        ? `&companies=${companies.join(";")}`
        : ""
    }`;
    return push({
      pathname: `/${toModel}/${complexSlug.slice(1)}`,
      state: currentUrl,
    });
  };

  useEffect(() => {
    const currentModel: string = detailsPages.find((p) =>
      location.pathname.startsWith(`/${p}/`)
    );
    if (!!currentModel) {
      setModel(currentModel);
      const wholeSlug = location.pathname.slice(`/${currentModel}/`.length);
      if (wholeSlug.endsWith("/map"))
        return setSlug(wholeSlug.slice(0, wholeSlug.length - 4));
      return setSlug(wholeSlug);
    }
  }, [location.pathname]);

  return { goToDetailsPage, model, slug };
}
