import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

import { getPassionData } from 'services/app';

export default function usePassionData(
  uuid: string,
  options?: Omit<UseQueryOptions<any, Error, any, QueryKey>, 'queryKey'>
) {
  return useQuery<any, Error>(
    ['onboarding-passion-data', uuid],
    () =>
      getPassionData(uuid).then((response) => {
        if (!response.ok) {
          throw new Error(response.originalError?.message);
        }
        return response.data;
      }),
    {
      ...options,
      cacheTime: 0,
    }
  );
}
