import React from 'react';

export default function FullCalendarIcon({ width = 39, height = 41, fill = '#171C33' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 39 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3096_11448)">
        <path
          d="M10.3359 15.5293C10.3359 13.6883 11.8283 12.196 13.6693 12.196H25.3359C27.1769 12.196 28.6693 13.6883 28.6693 15.5293V27.196C28.6693 29.0369 27.1769 30.5293 25.3359 30.5293H13.6693C11.8283 30.5293 10.3359 29.0369 10.3359 27.196V15.5293Z"
          fill="#F7F7F7"
        />
        <path
          d="M10.3359 15.5293C10.3359 13.6883 11.8283 12.196 13.6693 12.196H25.3359C27.1769 12.196 28.6693 13.6883 28.6693 15.5293V19.2793H10.3359V15.5293Z"
          fill="#D47563"
        />
        <path
          d="M15.3359 11.3626C15.3359 10.9024 15.709 10.5293 16.1693 10.5293C16.6295 10.5293 17.0026 10.9024 17.0026 11.3626V13.8626C17.0026 14.3229 16.6295 14.696 16.1693 14.696C15.709 14.696 15.3359 14.3229 15.3359 13.8626V11.3626Z"
          fill="#ADADAD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5859 13.8626V11.3626C16.5859 11.1325 16.3994 10.946 16.1693 10.946C15.9392 10.946 15.7526 11.1325 15.7526 11.3626V13.8626C15.7526 14.0927 15.9392 14.2793 16.1693 14.2793C16.3994 14.2793 16.5859 14.0927 16.5859 13.8626ZM16.1693 10.5293C15.709 10.5293 15.3359 10.9024 15.3359 11.3626V13.8626C15.3359 14.3229 15.709 14.696 16.1693 14.696C16.6295 14.696 17.0026 14.3229 17.0026 13.8626V11.3626C17.0026 10.9024 16.6295 10.5293 16.1693 10.5293Z"
          fill="#ADADAD"
        />
        <path
          d="M22 11.3626C22 10.9024 22.3731 10.5293 22.8333 10.5293C23.2936 10.5293 23.6667 10.9024 23.6667 11.3626V13.8626C23.6667 14.3229 23.2936 14.696 22.8333 14.696C22.3731 14.696 22 14.3229 22 13.8626V11.3626Z"
          fill="#ADADAD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.25 13.8626V11.3626C23.25 11.1325 23.0635 10.946 22.8333 10.946C22.6032 10.946 22.4167 11.1325 22.4167 11.3626V13.8626C22.4167 14.0927 22.6032 14.2793 22.8333 14.2793C23.0635 14.2793 23.25 14.0927 23.25 13.8626ZM22.8333 10.5293C22.3731 10.5293 22 10.9024 22 11.3626V13.8626C22 14.3229 22.3731 14.696 22.8333 14.696C23.2936 14.696 23.6667 14.3229 23.6667 13.8626V11.3626C23.6667 10.9024 23.2936 10.5293 22.8333 10.5293Z"
          fill="#ADADAD"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3096_11448"
          x="0.335938"
          y="0.529297"
          width="38.3359"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3096_11448" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3096_11448"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
