import React from 'react';

export default function Sparkle({
  width = 24,
  height = 25,
  fill = '#5478A1',
  singleSparkle = false,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!singleSparkle ? (
        <path
          d="M14.1177 0.5L15.8856 8.03482L24.0001 9.67647L15.8856 11.3181L14.1177 18.8529L12.3498 11.3181L4.23535 9.67647L12.3498 8.03482L14.1177 0.5Z"
          fill={fill}
        />
      ) : null}
      <path
        d="M4.94118 13.2051L5.82514 17.8419L9.88235 18.8521L5.82514 19.8624L4.94118 24.4992L4.05721 19.8624L0 18.8521L4.05721 17.8419L4.94118 13.2051Z"
        fill={fill}
      />
    </svg>
  );
}
