import React from 'react';

export default function Spinner({ width = 24, height = 24, fill = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="spinner-icon"
    >
      <circle cx="12" cy="12" r="8.5" stroke={fill} strokeOpacity="0.3" strokeWidth="3" />
      <path
        d="M12 3.5C13.1162 3.5 14.2215 3.71986 15.2528 4.14702C16.2841 4.57419 17.2211 5.20029 18.0104 5.98959C18.7997 6.77889 19.4258 7.71592 19.853 8.74719C20.2801 9.77846 20.5 10.8838 20.5 12"
        stroke={fill}
        strokeWidth="3"
      />
    </svg>
  );
}
