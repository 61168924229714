import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  ClientIcon,
  CompanyIcon,
  ProjectIcon,
} from 'app/components/DetailsPages/shared/CommonGround/Icons';
import PersonIcon from 'app/components/shared/icons/person-icon';

import GlobalDataTypeDrawer from '../GlobalDataType/GlobalDataTypeDrawer';
import useCompanyDrawerData from '../hooks/useCompanyDrawerData';
import GlobalCompanyDrawerNoDetailPage from './GlobalCompanyDrawerNoDetailPage';
import {
  GlobalCompanyDrawerBioText,
  GlobalCompanyDrawerCounter,
  GlobalCompanyDrawerCounterContainer,
  GlobalCompanyDrawerCounterIconContainer,
  GlobalCompanyDrawerCountersWrapper,
  GlobalCompanyDrawerCounterText,
  GlobalCompanyDrawerHighlightText,
  GlobalCompanyDrawerHighlightTextContainer,
} from './styled';

const companiesWithoutDetailPages = ['digital-kitchen'];

interface GlobalCompanyDrawerProps {
  slug: string;
  comingFromList?: boolean;
  backToListAction?: () => void;
}

export default function GlobalCompanyDrawer({
  slug,
  comingFromList = false,
  backToListAction = () => {},
}: GlobalCompanyDrawerProps) {
  const query = useCompanyDrawerData(slug);
  const { isLoading, data } = query;
  const { push } = useHistory();

  const handleButtonClick = () => {
    if (slug === 'kyu') return push('/kyu');

    push(`/member-company/${slug}`);
  };

  if (companiesWithoutDetailPages.includes(slug)) {
    return <GlobalCompanyDrawerNoDetailPage slug={slug} />;
  }

  return (
    <GlobalDataTypeDrawer
      slug={slug}
      query={query}
      isLoading={isLoading}
      background={
        'linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(156, 202, 255, 0.3) 100%), #FFFFFF'
      }
      dataType={!data?.includes_current_person ? 'kyu company' : 'your company'}
      title={data?.name || ''}
      includesCurrentPerson={data?.includes_current_person || false}
      icon={<CompanyIcon fill={'#5478A1'} />}
      catchphraseHighlightColor={'#5478A1'}
      iconBackground={'#9CCAFF'}
      buttonOnClick={handleButtonClick}
      buttonBackgroundColor={'#5478A1'}
      peopleAtCompany={data?.people_at_company || null}
      comingFromList={comingFromList}
      backToListAction={() => backToListAction()}
    >
      {data?.bio ? <GlobalCompanyDrawerBioText>{data.bio}</GlobalCompanyDrawerBioText> : null}
      {data?.highlight && (
        <GlobalCompanyDrawerHighlightTextContainer>
          <GlobalCompanyDrawerHighlightText>
            As of today,{' '}
            <span className="people">
              {data?.highlight.people_count}{' '}
              {data?.highlight.people_count !== 1 ? 'people' : 'person'}
            </span>{' '}
            work at {data.name}.
          </GlobalCompanyDrawerHighlightText>
          {data?.highlight.kyu_skills && data?.highlight.kyu_skills.length > 0 ? (
            <GlobalCompanyDrawerHighlightText>
              People most closely identify with{' '}
              {data?.highlight.kyu_skills.map((skill, index) => (
                <React.Fragment key={index}>
                  <span className="kyu_skill">{skill}</span>
                  {index !== data?.highlight.kyu_skills.length - 1
                    ? index === data?.highlight.kyu_skills.length - 2
                      ? ' and '
                      : ', '
                    : ''}
                </React.Fragment>
              ))}{' '}
              as {data?.highlight.kyu_skills !== 1 ? 'areas' : 'area'} of expertise.
            </GlobalCompanyDrawerHighlightText>
          ) : null}
        </GlobalCompanyDrawerHighlightTextContainer>
      )}
      <GlobalCompanyDrawerCountersWrapper>
        {data?.counters.people_count ? (
          <GlobalCompanyDrawerCounterContainer>
            <GlobalCompanyDrawerCounterIconContainer>
              <PersonIcon width={24} height={24} fill={'#FFC229'} />
              <GlobalCompanyDrawerCounter>{data.counters.people_count}</GlobalCompanyDrawerCounter>
            </GlobalCompanyDrawerCounterIconContainer>
            <GlobalCompanyDrawerCounterText>
              {data.counters.people_count !== 1 ? 'people' : 'person'}
            </GlobalCompanyDrawerCounterText>
          </GlobalCompanyDrawerCounterContainer>
        ) : null}
        {data?.counters.clients_count ? (
          <GlobalCompanyDrawerCounterContainer>
            <GlobalCompanyDrawerCounterIconContainer>
              <ClientIcon width={24} height={24} fill={'#5478A1'} />
              <GlobalCompanyDrawerCounter>{data.counters.clients_count}</GlobalCompanyDrawerCounter>
            </GlobalCompanyDrawerCounterIconContainer>
            <GlobalCompanyDrawerCounterText>
              {data.counters.clients_count !== 1 ? 'clients' : 'client'}
            </GlobalCompanyDrawerCounterText>
          </GlobalCompanyDrawerCounterContainer>
        ) : null}
        {data?.counters.projects_count ? (
          <GlobalCompanyDrawerCounterContainer>
            <GlobalCompanyDrawerCounterIconContainer>
              <ProjectIcon width={24} height={24} fill={'#392B62'} />
              <GlobalCompanyDrawerCounter>
                {data.counters.projects_count}
              </GlobalCompanyDrawerCounter>
            </GlobalCompanyDrawerCounterIconContainer>
            <GlobalCompanyDrawerCounterText>
              {data.counters.projects_count !== 1 ? 'projects' : 'project'}
            </GlobalCompanyDrawerCounterText>
          </GlobalCompanyDrawerCounterContainer>
        ) : null}
      </GlobalCompanyDrawerCountersWrapper>
    </GlobalDataTypeDrawer>
  );
}
