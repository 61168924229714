import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

interface EditorialTextProps {
  fontStyle?: 'normal' | 'italic';
  color?: string;
  textAlign?: string;
}


export const EditorialH1 = styled.h1<EditorialTextProps>`
  font-family: 'adobe-caslon-pro' !important;
  font-style: ${({ fontStyle }) => fontStyle ?? 'normal'};
  font-weight: 400;
  font-size: 116px;
  line-height: 100%;
  color: ${({ color }) => color ?? 'var(--color-text)'};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  ${BREAKPOINTS.mobile} {
    font-size: 56px;
    line-height: 115%;
  }
`;

export const EditorialH2 = styled.h2<EditorialTextProps>`
  font-family: 'adobe-caslon-pro' !important;
  font-style: ${({ fontStyle }) => fontStyle ?? 'normal'};
  font-weight: 400;
  font-size: 92px;
  line-height: 115%;
  color: ${({ color }) => color ?? 'var(--color-text)'};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  ${BREAKPOINTS.mobile} {
    font-size: 44px;
    line-height: 115%;
  }
`;

export const EditorialH3 = styled.h3<EditorialTextProps>`
  font-family: 'adobe-caslon-pro' !important;
  font-style: ${({ fontStyle }) => fontStyle ?? 'normal'};
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  color: ${({ color }) => color ?? 'var(--color-text)'};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  ${BREAKPOINTS.mobile} {
    font-size: 28px;
    line-height: 140%;
  }
`;

export const EditorialH4 = styled.h4<EditorialTextProps>`
  font-family: 'adobe-caslon-pro' !important;
  font-style: ${({ fontStyle }) => fontStyle ?? 'normal'};
  font-weight: 400;
  font-size: 32px;
  line-height: 140%;
  color: ${({ color }) => color ?? 'var(--color-text)'};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  ${BREAKPOINTS.mobile} {
    font-size: 24px;
    line-height: 100%;
  }
`;

export const EditorialH5 = styled.h5<EditorialTextProps>`
  font-family: 'adobe-caslon-pro' !important;
  font-style: ${({ fontStyle }) => fontStyle ?? 'normal'};
  font-weight: 400;
  font-size: 28px;
  line-height: 115%;
  color: ${({ color }) => color ?? 'var(--color-text)'};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  ${BREAKPOINTS.mobile} {
    font-size: 20px;
    line-height: 100%;
  }
`;

export const EditorialH6 = styled.h6<EditorialTextProps>`
  font-family: 'adobe-caslon-pro' !important;
  font-style: ${({ fontStyle }) => fontStyle ?? 'normal'};
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  color: ${({ color }) => color ?? 'var(--color-text)'};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  ${BREAKPOINTS.mobile} {
    font-size: 20px;
    line-height: 140%;
  }
`;

export const EditorialP = styled.p<EditorialTextProps>`
  font-family: 'GT Walsheim Pro' !important;
  font-style: ${({ fontStyle }) => fontStyle ?? 'normal'};
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  color: ${({ color }) => color ?? 'var(--color-text)'};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  ${BREAKPOINTS.mobile} {
    font-size: 18px;
    line-height: 140%;
  }
`

export const TheWellWrapper = styled.div`
  padding-top: 172px;
  background-color: #F7F5F5;

  & > :not(:last-child):not(.collection-container) {
    margin-bottom: 88px;

    ${BREAKPOINTS.mobile} {
      margin-bottom: 48px;
    }
  }

  & .collection-container {
    margin-bottom: 88px;

    ${BREAKPOINTS.mobile} {
      margin-bottom: 24px;
    }
  }

  ${BREAKPOINTS.mobile} {
    padding-top: 48px;
  }
`;

interface TheWellContainerProps {
  noPadding?: boolean;
  marginToCollection?: boolean;
}

export const TheWellContainer = styled.div<TheWellContainerProps>`
  ${({ noPadding }) => noPadding && `padding: 0px !important;`}
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 3.9%;

  ${BREAKPOINTS.normal} {
    padding: 0 6.3%;
  }

  ${BREAKPOINTS.tablet} {
    padding: 0 3.9%;
  }

  ${BREAKPOINTS.mobile} {
    padding: 0 3.6%;
  }

  ${({ marginToCollection }) => marginToCollection && `
    & > div > :not(:first-child) {
      padding-right: 20px;
    }
  `}
`;

export const TheWellTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

export const TheWellInnerTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  & > :not(:last-child) {
    margin-bottom: 36px;

    ${BREAKPOINTS.mobile} {
      margin-bottom: 16px;
    }
  }
`;

export const TheWellTitle = styled.h1`
  font-family: 'EB Garamond', sans-serif !important;
  font-style: normal;
  font-weight: 300;
  font-size: 174px;
  line-height: 100%;
  color: var(--color-text);

  ${BREAKPOINTS.mobile} {
    font-family: 'DM Sans';
    font-weight: 500;
    font-size: 48px;
    line-height: 63px;
  }
`;

interface TheWellSubtitleProps {
  italic?: boolean;
}

export const TheWellSubtitle = styled(EditorialH6)`
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    font-family: 'EB Garamond' !important;
    padding-right: unset;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    align-self: center;
    max-width: 318px;
  }
`;

export const TheWellContentSubtitle = styled(EditorialH5)`
  font-style: italic;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;

  text-align: center;

  color: #1F2025;

  ${BREAKPOINTS.tablet} {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const EditorialHeaderImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditorialHeaderImage = styled.img`
  width: 100%;
  max-width: 1440px;
  object-fit: cover;
`;

export const TheWellContributorsContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-evenly;
  align-items: flex-start;

  padding: 0px 28px;

  & > div {
    flex-basis: 21%;
  }

  & > :nth-child(n+5) {
    margin-top: 139px;
  }

  ${BREAKPOINTS.tablet} {
    max-width: unset;
    flex-direction: column;
    justify-content: flex-start;

    & > :not(:last-child) {
      margin-bottom: 36px;
    }

    & > :nth-child(n+4) {
      margin-top: 0px;
    }
  }
`;

// ARTICLES INDEX CTA
export const TheWellArticlesIndexCTAWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background: #F1EFEF;

  ${BREAKPOINTS.mobile} {
    margin-bottom: 88px !important;
  }

  transition: background 200ms ease-in-out;
  &:hover {
    background: var(--color-primary);

    & h4 {
      color: #FFFFFF;
    }
  }

  cursor: pointer;
`;

export const TheWellArticlesIndexCTAContainer = styled.div`
  width: 100%;
  max-width: 1440px;

  margin: 0 auto;
  padding 27px 172px;

  ${BREAKPOINTS.mobile} {
    padding: 14px 24px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TheWellArticlesIndexCTATextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const TheWellArticlesIndexCTATitle = styled.h4`
  font-family: 'adobe-caslon-pro' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 140%;

  transition: color 200ms ease-in-out;

  ${BREAKPOINTS.mobile} {
    font-size: 20px;
    line-height: 140%;
  }

  color: var(--color-text);
`;

export const TheWellArticlesIndexCTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px 6px 20px;
  background: #F1EFEF;

  border: 1px solid var(--color-primary);

  ${BREAKPOINTS.mobile} {
    border: none;
    padding: 0px;
  }

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

export const TheWellArticlesIndexCTAButtonText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: var(--color-text);
`;

export const TheWellArticlesIndexCTAButtonIconContainer = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
`;