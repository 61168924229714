interface DateTimeFormatOptions {
  localeMatcher?: "lookup" | "best fit";
  weekday?: "long" | "short" | "narrow";
  era?: "long" | "short" | "narrow";
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
  day?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  second?: "numeric" | "2-digit";
  timeZoneName?: "long" | "short";
}

export const formatDate = (
  date: string,
  options: DateTimeFormatOptions = { year: "numeric", month: "long" }
): string => {
  const [y, m]: string[] = date.split("-");
  return new Date(parseInt(y), parseInt(m) - 1).toLocaleDateString(
    "en",
    options
  );
};
