import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

import { QuickFilterType, typeToBackground } from '../../constants';

export const ResourcesContentFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;

  max-width: calc(100% - 75px);
  width: 100%;

  position: relative;

  ${BREAKPOINTS.mobile} {
    gap: 16px;
    max-width: 100%;
  }
`;

interface ResourcesContentFiltersContainerProps {
  limitWidth?: boolean;
}

export const ResourcesContentFiltersContainer = styled.div<ResourcesContentFiltersContainerProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;

  position: relative;

  @media (max-width: 1250px) {
    & > :nth-child(5) > :last-child {
      left: unset;
      right: 0px;
    }
  }

  @media (max-width: 1100px) {
    & > :nth-child(4) > :last-child {
      left: unset;
      right: 0px;
    }

    & > :nth-child(5) > :last-child {
      left: 0px;
      right: unset;
    }
  }
  ${BREAKPOINTS.mobile} {
    gap: 16px;

    ${({ limitWidth }) =>
      limitWidth
        ? `
      max-width: calc(100% - 75px);
    `
        : `
      & > :nth-child(3n) > :last-child {
        left: unset;
        right: 0px;
      }

      & > :nth-child(4) > :last-child {
      left: 0px;
      right: unset;
    }

    `}
  }


`;

interface ResourcesContentFiltersTypeIconContainerProps {
  type: QuickFilterType;
}

export const ResourcesContentFiltersTypeIconContainer = styled.div<ResourcesContentFiltersTypeIconContainerProps>`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ type }) => typeToBackground.get(type)};
  border-radius: 100%;
`;

export const ResourcesContentFiltersTopicsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

// CLEAR ALL
export const ResourcesContentFiltersClearAllContainer = styled.div`
  position: absolute;
  right: -75px;
  top: 0;

  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 4px;
  }

  ${BREAKPOINTS.mobile} {
    right: 0px;
  }

  cursor: pointer;
`;

export const ResourcesContentFiltersClearAllText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  text-align: right;
  color: rgba(23, 28, 51, 0.5);
`;
