import { ISkillDetailsAtAGlanceResponse, ISkillDetailsCommunitiesResponse, ISkillDetailsConveningsResponse, ISkillDetailsMapSnippetResponse, ISkillDetailsMeetSomeoneNewResponse, ISkillDetailsPeopleDrawerParams, ISkillDetailsPeopleDrawerResponse, ISkillDetailsPeopleFiltersResponse, ISkillDetailsPeopleParams, ISkillDetailsPeopleResponse, ISkillDetailsResponse, ISkillRelatedSkillsResponse } from "app/components/DetailsPages/interfaces/skill"
import { http } from "./http"

export const getSkillDetails = (slug: string) => {
  return http.get<ISkillDetailsResponse>(`kyu_skill/${slug}/details`)
}

export const getSkillAtAGlance = (slug: string) => {
  return http.get<ISkillDetailsAtAGlanceResponse>(`kyu_skill/${slug}/details/at_a_glance`);
}

export const getSkillMapSnippet = (slug: string) => {
  return http.get<ISkillDetailsMapSnippetResponse>(`kyu_skill/${slug}/details/map_snippet`);
}

export const getSkillConvenings = (slug: string) => {
  return http.get<ISkillDetailsConveningsResponse>(`kyu_skill/${slug}/details/convenings`);
}

export const getSkillCommunities = (slug: string) => {
  return http.get<ISkillDetailsCommunitiesResponse>(`kyu_skill/${slug}/details/communities`);
}

export const getSkillPeople = (slug: string, params?: ISkillDetailsPeopleParams) => {
  return http.get<ISkillDetailsPeopleResponse>(`kyu_skill/${slug}/details/people`, params);
}

export const getSkillPeopleFilters = (slug: string) => {
  return http.get<ISkillDetailsPeopleFiltersResponse>(`kyu_skill/${slug}/details/people_filters`);
}

export const getSkillMeetSomeoneNew = (slug: string) => {
  return http.get<ISkillDetailsMeetSomeoneNewResponse>(`kyu_skill/${slug}/details/meet_someone_new`);
}
  
export const getSkillPeopleDrawer = (slug: string, params?: ISkillDetailsPeopleDrawerParams) => {
  return http.get<ISkillDetailsPeopleDrawerResponse>(`kyu_skill/${slug}/drawers/people`, params); 
}

export const getSkillRelatedSkills = (slug: string) => {
  return http.get<ISkillRelatedSkillsResponse>(`kyu_skill/${slug}/drawers/related_skills`);
}