import { SkeletonItem } from 'app/components/DetailsPages/shared/styled';
import { HTMLMotionProps, motion } from 'framer-motion';
import styled from 'styled-components';
import { infiniteRotationAnimation } from 'styles/animations';
import { BREAKPOINTS } from 'styles/media';

export const OnboardingStepFourWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 118px 200px 129px 200px;

  ${BREAKPOINTS.large_tablet_landscape} {
    flex-direction: column;
    padding: 68px 184px 46px 184px;

    & > :not(:last-child) {
      margin-bottom: 48px;
    }
  }

  ${BREAKPOINTS.mobile} {
    padding: 36px 24px 54px 24px;
  }
`;

export const OnboardingStepFourTitle = styled.h2`
  font-weight: 400;
  line-height: 42px;
  font-size: 38px;
  color: var(--color-text);

  max-width: 267px;

  ${BREAKPOINTS.large_tablet_landscape} {
    max-width: unset;
    text-align: center;
  }
`;

export const OnboardingStepFourParagraph = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: var(--color-text);

  p {
    margin-bottom: 16px;
  }

  ${BREAKPOINTS.large_tablet_landscape} {
    text-align: center;
  }

  ${BREAKPOINTS.mobile} {
    font-size: 16px;
  }
`;

export const OnboardingStepFourInformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;

    ${BREAKPOINTS.mobile} {
      margin-bottom: 32px;
    }
  }

  max-width: 290px;

  ${BREAKPOINTS.large_tablet_landscape} {
    max-width: unset;
  }
`;

// PASSIONS
export const OnboardingStepFourPassionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > :not(:last-child) {
    margin-bottom: 32px;
  }

  position: relative;
`;

export const OnboardingStepFourPassionsTitle = styled.h4`
  font-weight: 400;
  font-size: 24px;
  line-height: normal;
  text-align: center;
  color: var(--color-text);

  max-width: 395px;
`;

export const OnboardingStepFourPassionsContainer = styled.div`
  & > .my-masonry-grid {
    display: flex;

    > :not(:last-child) {
      margin-right: 8px;
    }

    > .my-masonry-grid_column {
      min-width: 211px;
      max-width: 211px;

      ${BREAKPOINTS.large_tablet_landscape} {
        min-width: 159px;
        max-width: 159px;
      }

      > :not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
`;

export const OnboardingStepFourEmptySpace = styled.div`
  width: 100%;
  height: 31px;

  ${BREAKPOINTS.mobile} {
    height: 20px;
  }
`;

interface OnboardingStepFourPassionProps {
  selected: boolean;
}

export const OnboardingStepFourPassion = styled.div<OnboardingStepFourPassionProps>`
  width: 100%;
  min-height: 70px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 8px 20px;

  background: rgba(255, 255, 255, ${({ selected }) => (selected ? '1' : '0.5')});
  border-radius: 8px;

  ${BREAKPOINTS.large_tablet_landscape} {
    height: fit-content;
  }

  cursor: pointer;
`;

export const OnboardingStepFourPassionName = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  color: var(--color-text);
`;

export const OnboardingStepFourPassionAlreadyAdded = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  color: rgba(23, 28, 51, 0.5);
`;

interface OnboardingStepFourPassionsButtonProps {
  disabled?: boolean;
  loading?: boolean;
}

export const OnboardingStepFourPassionsButton = styled.button<OnboardingStepFourPassionsButtonProps>`
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  padding: 16px 48px 16px 40px;
  border-radius: 100px;

  background: ${({ disabled }) =>
    disabled ? 'var(--color-data-alumni-background)' : 'var(--color-primary)'};

  transition: 0.3s background ease;
  &:hover {
    ${({ disabled }) => !disabled && 'background: #323F5D;'}
  }

  & > .spinner-icon {
    ${infiniteRotationAnimation()}
  }
`;

export const OnboardingStepFourPassionsButtonText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: #ffffff;
`;

// SKELETON
export const OnboardingStepFourTitleSkeleton = styled(SkeletonItem)`
  height: 30px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.1);
`;

export const OnboardingStepFourParagraphSkeleton = styled(SkeletonItem)`
  height: 12px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.1);
`;

export const OnboardingStepFourPassionSkeleton = styled(SkeletonItem)`
  height: 70px;

  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
`;

export const OnboardingStepFourPassionsSkeletonContainer = styled(
  OnboardingStepFourPassionsContainer
)`
  padding-top: 94px;
`;

export const OnboardingStepFourPassionsTextListSkeleton = styled(motion.div).attrs(
  (): HTMLMotionProps<'div'> => ({
    animate: 'show',
    variants: {
      show: {
        transition: {
          staggerChildren: 1,
        },
      },
    },
  })
)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);

  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 7px;
  }
`;

export const OnboardingStepFourPassionsTextListItemEkeleton = styled(motion.span).attrs(
  (): HTMLMotionProps<'span'> => ({
    variants: {
      show: {
        color: ['rgba(23, 28, 51, 0.3)', 'rgba(23, 28, 51, 1)', 'rgba(23, 28, 51, 0.3)'],
        transition: {
          duration: 1,
          times: [0, 0.5, 1],
        },
        transitionEnd: {
          color: 'rgba(23, 28, 51, 0.3)',
        },
      },
    },
  })
)`
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: rgba(23, 28, 51, 0.3);
`;
