import React from 'react';

export default function AbstractIcon({ width = 22, height = 22, fill = '#D1B2B2' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.865365 12.0333C3.41056 12.0362 5.85069 13.0485 7.65042 14.8483C9.45015 16.648 10.4625 19.0881 10.4654 21.6333C10.4654 21.7748 10.5216 21.9104 10.6216 22.0104C10.7216 22.1105 10.8572 22.1666 10.9987 22.1666C11.1401 22.1666 11.2758 22.1105 11.3758 22.0104C11.4758 21.9104 11.532 21.7748 11.532 21.6333C11.5349 19.0881 12.5472 16.648 14.347 14.8483C16.1467 13.0485 18.5868 12.0362 21.132 12.0333C21.2735 12.0333 21.4091 11.9771 21.5092 11.8771C21.6092 11.7771 21.6654 11.6414 21.6654 11.5C21.6654 11.3585 21.6092 11.2229 21.5092 11.1229C21.4091 11.0228 21.2735 10.9666 21.132 10.9666C18.5868 10.9638 16.1467 9.95143 14.347 8.1517C12.5472 6.35198 11.5349 3.91184 11.532 1.36665C11.532 1.2252 11.4758 1.08954 11.3758 0.989524C11.2758 0.889505 11.1401 0.833313 10.9987 0.833313C10.8572 0.833313 10.7216 0.889505 10.6216 0.989524C10.5216 1.08954 10.4654 1.2252 10.4654 1.36665C10.4625 3.91184 9.45015 6.35198 7.65042 8.1517C5.85069 9.95143 3.41056 10.9638 0.865365 10.9666C0.723916 10.9666 0.58826 11.0228 0.488241 11.1229C0.388221 11.2229 0.332031 11.3585 0.332031 11.5C0.332031 11.6414 0.388221 11.7771 0.488241 11.8771C0.58826 11.9771 0.723916 12.0333 0.865365 12.0333Z"
        fill={fill}
      />
    </svg>
  );
}
