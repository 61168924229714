const industry_highlights = ({
  active_clients_count,
  clients_count,
  kyu_companies_count,
  kyu_companies_with_most_projects,
  people_count,
  projects_count,
}) => {
  let companies_description = `<span class="highlight-kyu-company">${kyu_companies_count} ${
    kyu_companies_count > 1 ? "companies" : "company"
  }</span>`;

  let first_paragraph = `<p><span class="highlight-people">${people_count} ${
    people_count > 1 ? "people" : "person"
  }</span> from ${companies_description} have connections to this industry.</p>`;

  let second_paragraph = `<p>There are <span class="highlight-client-company">${active_clients_count} active ${
    active_clients_count > 1 ? "clients" : "client"
  }</span> within this industry and, in total, \
  we have worked with <span class="highlight-client-company">${clients_count} ${
    clients_count > 1 ? "clients" : "client"
  }</span> on <span class="highlight-project">${projects_count} ${
    projects_count > 1 ? "projects" : "project"
  }</span>.</p>`;

  let third_paragraph = "";

  if (
    kyu_companies_with_most_projects &&
    kyu_companies_with_most_projects.length > 0
  ) {
    let kyu_companies_array = kyu_companies_with_most_projects.map(
      (company) => `<span class="highlight-kyu-company">${company}</span>`
    );
    if (kyu_companies_array.length > 1) {
      third_paragraph += `<p>Overall, ${kyu_companies_array
        .slice(0, kyu_companies_array.length - 1)
        .join(", ")} and ${
        kyu_companies_array[kyu_companies_array.length - 1]
      } have the most projects connected to this industry.</p>`;
    } else {
      third_paragraph += `<p>Overall, ${
        kyu_companies_array[kyu_companies_array.length - 1]
      } have the most projects connected to this industry.</p>`;
    }
  }

  return first_paragraph + second_paragraph + third_paragraph;
};

const industry_kyu_company_highlights = ({
  kyu_company,
  people_count,
  projects_count,
  projects_length,
}) => {
  let paragraph = `<p><span class="highlight-kyu-company">${kyu_company}</span> has done <span class="highlight-project">${projects_count} ${
    projects_count > 1 ? "projects" : "project"
  }</span> with clients in this industry.\
    The average project length is ${projects_length}.</p>\
    <p>In total, <span class="highlight-people">${people_count} ${
    people_count > 1 ? "people" : "person"
  }</span> at ${kyu_company} are connected to this industry.</p>`;

  return paragraph;
};

export { industry_highlights, industry_kyu_company_highlights };
