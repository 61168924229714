import styled from 'styled-components';

export const MobileMenuProfileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 6px;
  }

  position: relative;
`;

export const MobileMenuProfileInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileMenuProfileInformationName = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  color: var(--color-text);
`;

export const MobileMenuProfileInformationCompany = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: rgba(23, 28, 51, 0.5);
`;

export const MobileMenuProfileRightContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 24px;
  }
`;

export const MobileMenuProfileRightIconContainer = styled.div`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileMenuProfileBackText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  color: rgba(23, 28, 51, 0.5);
`;
