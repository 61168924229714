import styled from 'styled-components';

interface WithHighlightWrapperProps {
  isHighlighted: boolean;
}

export const WithHighlightWrapper = styled.div<WithHighlightWrapperProps>`
  ${({ isHighlighted }) => isHighlighted && `
    width: calc(100% + 36px);
    padding: 36px;
    border-radius: 36px;

    border: 2px solid var(--color-data-skill-background, #FFA366);
    background: rgba(255, 255, 255, 0.01);
    box-shadow: 0px 0px 100px 0px var(--color-data-skill-background, #FFA366);
  `}
`;