import React, { useState } from 'react';

import { RightArrow } from 'app/components/shared/icons';
import { motion } from 'framer-motion';
import useUserData from 'hooks/useUserData';

import useOnboardingControls from '../../hooks/useOnboardingControls';
import {
  OnboardingStepOneContainer,
  OnboardingStepOneKyuLogoContainer,
  OnboardingStepOneKyuLogoDescription,
  OnboardingStepOneNextButton,
  OnboardingStepOneNextButtonText,
  OnboardingStepOneSubtitle,
  OnboardingStepOneTitle,
  OnboardingStepOneWrapper,
} from './styled';

export default function OnboardingStepOne() {
  const { goNextStep } = useOnboardingControls();
  const userData = useUserData();

  const [currentVariant, setCurrentVariant] = useState('initial');
  const handleButtonClick = () => {
    setCurrentVariant(currentVariant === 'initial' ? 'next' : 'initial');
  };

  return (
    <OnboardingStepOneWrapper>
      <KyuLogo currentVariant={currentVariant} />
      <OnboardingStepOneContainer>
        <OnboardingStepOneKyuLogoDescription>
          Our Intelligence System Designed to Mobilize the Community
        </OnboardingStepOneKyuLogoDescription>
        <OnboardingStepOneTitle>
          {!userData.onboarding_started ? `We’re glad you’re here.` : `Welcome back.`}
        </OnboardingStepOneTitle>
        {!userData.onboarding_started ? (
          <React.Fragment>
            <OnboardingStepOneSubtitle>There's so much waiting for you to discover.</OnboardingStepOneSubtitle>
            <OnboardingStepOneSubtitle>
              We have a quick 101 that explains what kyu OS is. 
            </OnboardingStepOneSubtitle>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <OnboardingStepOneSubtitle>
              There’s so much more waiting for you to discover.
            </OnboardingStepOneSubtitle>
            <OnboardingStepOneSubtitle>Come with us on this quick tour.</OnboardingStepOneSubtitle>
          </React.Fragment>
        )}
      </OnboardingStepOneContainer>
      <OnboardingStepOneNextButton
        onClick={handleButtonClick}
        animate={currentVariant}
        onAnimationComplete={() => {
          if (currentVariant === 'next') {
            goNextStep();
          }
        }}
      >
        {currentVariant !== 'next' ? (
          <>
            <OnboardingStepOneNextButtonText>Let’s start</OnboardingStepOneNextButtonText>
            <RightArrow fill="var(--color-primary)" height={16} width={16} />
          </>
        ) : null}
      </OnboardingStepOneNextButton>
    </OnboardingStepOneWrapper>
  );
}

function KyuLogo({ currentVariant }) {
  return (
    <OnboardingStepOneKyuLogoContainer animate={currentVariant}>
      <motion.div
        animate={currentVariant}
        variants={{
          next: {
            display: 'none',
          },
        }}
      >
        <svg
          width="130"
          height="41"
          viewBox="0 0 130 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 31.1383V0.5H3.44317V18.9255L11.7641 10.0319H15.9861L6.96831 19.5638L17.1338 31.1383H12.7479L3.44317 20.2021V31.1383H0Z"
            fill="#323F5D"
          />
          <path
            d="M22.8686 40.5L27.9104 29.0957H26.7217L18.6466 10.0319H22.3767L29.0991 26.4574L36.2314 10.0319H39.7975L26.4757 40.5H22.8686Z"
            fill="#323F5D"
          />
          <path
            d="M50.8511 31.6489C48.4463 31.6489 46.5334 30.8972 45.1124 29.3936C43.6915 27.8617 42.981 25.5213 42.981 22.3723V10.0319H46.4241V21.9894C46.4241 26.3865 48.1594 28.5851 51.6299 28.5851C53.4061 28.5851 54.8681 27.9326 56.0158 26.6277C57.1909 25.2943 57.7784 23.4078 57.7784 20.9681V10.0319H61.2215V31.1383H58.1063L57.8604 27.3511C57.2318 28.6844 56.2891 29.734 55.032 30.5C53.8023 31.266 52.4087 31.6489 50.8511 31.6489Z"
            fill="#323F5D"
          />
          <path
            d="M91.1408 31.6489C88.2715 31.6489 85.7574 30.9965 83.5986 29.6915C81.4671 28.3865 79.7865 26.5851 78.5568 24.2872C77.3544 21.961 76.7533 19.2801 76.7533 16.2447C76.7533 13.2092 77.3544 10.5426 78.5568 8.24468C79.7865 5.91844 81.4671 4.10284 83.5986 2.79787C85.7574 1.49291 88.2715 0.840426 91.1408 0.840426C93.9827 0.840426 96.4831 1.49291 98.642 2.79787C100.801 4.10284 102.481 5.91844 103.684 8.24468C104.886 10.5426 105.487 13.2092 105.487 16.2447C105.487 19.2801 104.886 21.961 103.684 24.2872C102.481 26.5851 100.801 28.3865 98.642 29.6915C96.4831 30.9965 93.9827 31.6489 91.1408 31.6489ZM91.1408 26.7553C93.8734 26.7553 96.0459 25.8191 97.6582 23.9468C99.2978 22.0745 100.118 19.5071 100.118 16.2447C100.118 12.9823 99.2978 10.4149 97.6582 8.54255C96.0459 6.67021 93.8734 5.73404 91.1408 5.73404C88.4081 5.73404 86.222 6.67021 84.5824 8.54255C82.9428 10.4149 82.123 12.9823 82.123 16.2447C82.123 19.5071 82.9428 22.0745 84.5824 23.9468C86.222 25.8191 88.4081 26.7553 91.1408 26.7553Z"
            fill="#323F5D"
          />
          <path
            d="M119.916 31.6489C117.812 31.6489 115.954 31.2801 114.342 30.5426C112.73 29.7766 111.459 28.6986 110.53 27.3085C109.601 25.8901 109.122 24.1738 109.095 22.1596H114.629C114.683 23.5496 115.162 24.7269 116.063 25.6915C116.992 26.6277 118.263 27.0957 119.875 27.0957C121.269 27.0957 122.376 26.7553 123.196 26.0745C124.015 25.3652 124.425 24.4291 124.425 23.266C124.425 22.0461 124.056 21.0957 123.319 20.4149C122.608 19.734 121.652 19.1809 120.449 18.7553C119.247 18.3298 117.963 17.8759 116.596 17.3936C114.383 16.5993 112.689 15.578 111.513 14.3298C110.366 13.0816 109.792 11.422 109.792 9.35106C109.765 7.5922 110.161 6.08865 110.981 4.84043C111.828 3.56383 112.975 2.58511 114.424 1.90425C115.872 1.19504 117.539 0.840426 119.425 0.840426C121.337 0.840426 123.018 1.19504 124.466 1.90425C125.942 2.61347 127.09 3.60638 127.91 4.88298C128.757 6.15957 129.208 7.6773 129.262 9.43617H123.647C123.619 8.38652 123.223 7.46454 122.458 6.67021C121.72 5.84752 120.682 5.43617 119.343 5.43617C118.195 5.4078 117.225 5.70567 116.432 6.32979C115.667 6.92553 115.285 7.80496 115.285 8.96808C115.285 9.96099 115.585 10.7553 116.186 11.3511C116.788 11.9184 117.607 12.4007 118.646 12.7979C119.684 13.195 120.873 13.6206 122.212 14.0745C123.633 14.5851 124.931 15.1809 126.106 15.8617C127.281 16.5426 128.224 17.4504 128.934 18.5851C129.645 19.6915 130 21.1241 130 22.883C130 24.4433 129.617 25.8901 128.852 27.2234C128.087 28.5567 126.953 29.6348 125.45 30.4574C123.947 31.2518 122.103 31.6489 119.916 31.6489Z"
            fill="#323F5D"
          />
        </svg>
      </motion.div>
    </OnboardingStepOneKyuLogoContainer>
  );
}
