export const detailsPages = ['people-group', 'skills-passions'];

export const newDetailsPages = [
  'member-company',
  'passion',
  'skill',
  'client',
  'person',
  'project',
  'industry',
  'discipline',
];

export const mapModels = [
  'member-company',
  'passion',
  'skill',
  'client',
  'person',
  'project',
  'industry',
  'collective',
  'community',
  'discipline',
];

export const mapModelForBehavioral = {
  person: 'people',
  industry: 'industries',
  'member-company': 'kyu_companies',
  passion: 'passions',
  skill: 'kyu_skills',
  project: 'projects',
  client: 'client_companies',
};

export const mapModelForBehavioralAction = {
  person: 'Person',
  industry: 'Industry',
  'member-company': 'Company',
  passion: 'Passion',
  skill: 'kyu_skill',
  project: 'Project',
  client: 'Company',
  discipline: 'Discipline',
};

export const sectionsToTitles = {
  at_a_glance: 'SUMMARY',
  map: 'NETWORK MAP',
  stories_and_highlights: 'NEWS + RESOURCES',
  impact: `INDUSTRIES AND PROJECTS`,
  people: 'EMPLOYEES',
};

export const clientSectionsToTitles = {
  snapshot: 'SUMMARY',
  expertise: 'TYPES OF WORK',
  projects: 'PROJECT LIST',
  stories_and_highlights: 'NEWS + RESOURCES',
  prior_experience_people: 'PRIOR CONNECTIONS',
  people: 'PEOPLE',
};

export const industrySectionsToTitles = {
  snapshot: 'SUMMARY',
  expertise: 'TYPES OF WORK',
  clients: 'CLIENTS',
  map_snippet: 'NETWORK MAP',
  convenings: 'CONVENINGS',
  stories_and_highlights: 'NEWS + RESOURCES',
  projects: 'PROJECT LIST',
  people: 'PEOPLE',
  prior_experience_people: 'PRIOR CONNECTIONS',
  related_industries: 'RELATED INDUSTRIES',
};

export const passionSectionsToTitles = {
  at_a_glance: 'SUMMARY',
  map_snippet: 'NETWORK MAP',
  people: 'PEOPLE',
  similar_passions: 'RELATED',
  convenings: 'EVENTS',
};

export const skillSectionsToTitles = {
  at_a_glance: 'SUMMARY',
  map_snippet: 'NETWORK MAP',
  people: 'PEOPLE',
  similar_passions: 'RELATED',
  convenings: 'EVENTS',
};

export const projectSectionsToTitles = {
  about: `DESCRIPTION`,
  impact: `NEWS + ASSETS`,
  people: `PROJECT TEAM`,
  projects: `RELATED PROJECTS`,
  map_snippet: 'NETWORK MAP',
};

export const disciplineSectionsToTitles = {
  map_snippet: 'NETWORK MAP',
  common_ground: `SUMMARY`,
  meet_someone_new: `PEOPLE`,
  about: `INFO`,
};

export const newModelsToOld = {
  person: 'people',
  industry: 'industries',
  'member-company': 'kyu_companies',
  passion: 'passions',
  skill: 'kyu_skills',
  project: 'projects',
  client: 'client_companies',
  'people-group': 'people',
  'skills-passions': 'multiple',
  discipline: 'discipline',
};

export const oldModelsToNew = {
  people: 'person',
  passions: 'passion',
  industries: 'industry',
  kyu_companies: 'member-company',
  partner_companies: 'member-company',
  member_companies: 'member-company',
  client_companies: 'client',
  companies: 'client',
  projects: 'project',
  kyu_skills: 'skill',
  kyuskills: 'skill',
  skills: 'skill',
  communities: 'community',
  kyu: 'kyu',
  clients: 'client',
  languages: 'language',
  cities: 'city',
  articles: 'articles',
  tools: 'tools',
  convenings: 'convening',
};

export const pageTitles = {
  client: 'Client',
  industry: 'Industry',
  'member-company': 'Company',
  passion: 'Passion',
  'people-group': 'Results - Known Group',
  person: 'Person',
  project: 'Project',
  skill: 'Skill',
  discipline: 'Discipline',
  'skills-passions': 'Results - Skills x Passions',
};

export const pluralModelToSingular = {
  clients: 'client',
  companies: 'company',
};

export const behavioralObjectTypeCorrectionMap = new Map<string, string>([
  ['member_companies', 'companies'],
  ['clients', 'companies'],
  ['client', 'companies'],
]);
