interface IThemeProperties {
  width: string;
  height: string;
  imageHeight: string;
  button: {
    background: string;
    color: string;
    hover: {
      background: string;
      color: string;
    }
  },
}

interface ITheme {
  [key: string]: IThemeProperties;
}

export const theme: ITheme = {
  community: {
    width: '240px',
    height: '420px',
    imageHeight: '134px',
    button: {
      background: 'var(--color-data-community-background)',
      color: 'var(--color-text)',
      hover: {
        background: 'var(--color-data-community-background-hover)',
        color: 'var(--color-text)',
      }
    }
  },
  company: {
    width: '240px',
    height: '420px',
    imageHeight: '134px',
    button: {
      background: 'var(--color-data-company-background)',
      color: 'var(--color-text)',
      hover: {
        background: 'var(--color-data-company-background-hover)',
        color: 'var(--color-text)',
      }
    }
  },
  person: {
    width: '240px',
    height: '420px',
    imageHeight: '134px',
    button: {
      background: 'var(--color-data-people-background)',
      color: 'var(--color-text)',
      hover: {
        background: 'var(--color-data-people-background-hover)',
        color: 'var(--color-text)',
      }
    }
  },
  convening: {
    width: '240px',
    height: '420px',
    imageHeight: '134px',
    button: {
      background: 'var(--color-data-community-background)',
      color: 'var(--color-text)',
      hover: {
        background: 'var(--color-data-community-background-hover)',
        color: 'var(--color-text)',
      }
    }
  },
  resource: {
    width: '240px',
    height: '420px',
    imageHeight: '134px',
    button: {
      background: 'rgba(23, 28, 51, 0.50)',
      color: 'rgba(255, 255, 255, 1)',
      hover: {
        background: 'var(--color-text)',
        color: 'rgba(255, 255, 255, 1)',
      }
    }
  },
  passion: {
    width: '240px',
    height: '420px',
    imageHeight: '134px',
    button: {
      background: 'var(--color-data-passion-background)',
      color: 'var(--color-text)',
      hover: {
        background: 'var(--color-data-passion-background-hover)',
        color: 'var(--color-text)',
      }
    }
  },
  article: {
    width: '240px',
    height: '420px',
    imageHeight: '134px',
    button: {
      background: 'rgba(23, 28, 51, 0.50)',
      color: 'rgba(255, 255, 255, 1)',
      hover: {
        background: 'var(--color-text)',
        color: 'rgba(255, 255, 255, 1)',
      }
    }
  },
  collection: {
    width: '240px',
    height: '420px',
    imageHeight: '134px',
    button: {
      background: 'rgba(23, 28, 51, 0.50)',
      color: 'rgba(255, 255, 255, 1)',
      hover: {
        background: 'var(--color-text)',
        color: 'rgba(255, 255, 255, 1)',
      }
    }
  },
  event_host: {
    width: '300px',
    height: '500px',
    imageHeight: '200px',
    button: {
      background: '#FFD600',
      color: 'var(--color-text)',
      hover: {
        background: '#FFD600',
        color: 'var(--color-text)',
      }
    }
  },
  map: {
    width: '300px',
    height: '500px',
    imageHeight: '200px',
    button: {
      background: 'rgba(23, 28, 51, 0.50)',
      color: 'rgba(255, 255, 255, 1)',
      hover: {
        background: 'var(--color-text)',
        color: 'rgba(255, 255, 255, 1)',
      }
    }
  },
}