import styled from 'styled-components';
import { BREAKPOINTS } from 'styles/media';

export const OnboardingStepTwoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 68px 200px 0px 200px;

  ${BREAKPOINTS.mobile} {
    padding: 36px 24px 32px 24px;
  }

  & > :not(:last-child) {
    margin-bottom: 43px;

    ${BREAKPOINTS.mobile} {
      margin-bottom: 32px;
    }
  }
`;

export const OnboardingStepTwoTopRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 54px;

  ${BREAKPOINTS.tablet} {
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    justify-content: center;
    align-items: center;

    & > :first-child {
      order: 2;
    }

    & > :nth-child(2) {
      order: 1;
    }
  }
`;

export const OnboardingStepTwoBottomRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 54px;

  align-items: center;

  ${BREAKPOINTS.tablet} {
    grid-template-columns: 1fr;
    grid-row-gap: 77px;
  }
`;

export const OnboardingStepTwoBottomRowParagraphContainer = styled.div`
  max-width: 454px;
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const OnboardingStepTwoTitle = styled.h1`
  font-weight: 400;
  font-size: 38px;
  line-height: 42px;
  text-align: center;

  color: var(--color-text);

  ${BREAKPOINTS.mobile} {
    font-size: 32px;
    line-height: 36px;
  }
`;

export const OnboardingStepTwoParagraph = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: var(--color-text);

  ${BREAKPOINTS.tablet} {
    margin-top: 20px;
  }

  ${BREAKPOINTS.mobile} {
    font-size: 16px;
  }
`;

export const OnboardingStepTwoCollectiveContainer = styled.div`
  max-width: 454px;

  & > div {
    grid-template-columns: repeat(11, 31px);
    grid-auto-rows: 18px;

    ${BREAKPOINTS.tablet} {
      grid-template-columns: repeat(7, 44px);
      grid-auto-rows: 25px;
    }
  }
`;

export const OnboardingStepTwoKyuTeamWrapper = styled.div`
  max-height: 400px;

  border-radius: 8px;
  background: linear-gradient(104deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.1);

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
  }

  & > div:not(:first-child) {
    z-index: 1;
  }

  ${BREAKPOINTS.tablet} {
    max-height: unset;
  }

  position: relative;
`;

export const OnboardingStepTwoKyuTeamContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  padding: 24px;

  overflow-y: scroll;
  position: relative;

  max-height: 400px;

  ${BREAKPOINTS.large_tablet_landscape} {
    grid-template-columns: 1fr;
  }

  ${BREAKPOINTS.tablet} {
    max-height: unset;
    overflow-y: unset;
  }

  z-index: 2;
`;

export const OnboardingStepTwoKyuTeamIdentifierContainer = styled.div`
  position: absolute;
  top: 34px;
  right: -145px;
  display: flex;
  flex-direction: column;

  ${BREAKPOINTS.tablet} {
    top: unset;
    bottom: -60px;
    right: -15px;

    & > svg {
      transform: rotate(38deg);
    }
  }
`;

export const OnboardingStepTwoKyuTeamIdentifierText = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;

  color: rgba(23, 28, 51, 0.5);

  max-width: 108px;
  margin-left: 25px;

  ${BREAKPOINTS.tablet} {
    transform: rotate(-15deg);
    margin-left: 0px;
  }
`;

export const OnboardingStepTwoKyuTeamMemberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 16px;
  }

  cursor: pointer;
`;

export const OnboardingStepTwoKyuTeamMemberContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OnboardingStepTwoKyuTeamMemberName = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  color: var(--color-text);
`;

export const OnboardingStepTwoKyuTeamMemberRole = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;

  color: rgba(23, 28, 51, 0.5);
`;
