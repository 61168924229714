import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import AboutKyuOsContact from 'app/components/AboutKyuOs/AboutKyuOsContact';
import AboutKyuOsFAQ from 'app/components/AboutKyuOs/AboutKyuOsFAQ';
import AboutKyuOsHero from 'app/components/AboutKyuOs/AboutKyuOsHero';
import AboutKyuOsIntroduction from 'app/components/AboutKyuOs/AboutKyuOsIntroduction';
import AboutKyuOsKeepScrolling from 'app/components/AboutKyuOs/AboutKyuOsKeepScrolling';
import AboutKyuOsMap from 'app/components/AboutKyuOs/AboutKyuOsMap';
import AboutKyuOsNetwork from 'app/components/AboutKyuOs/AboutKyuOsNetwork';
import AboutKyuOsVideo from 'app/components/AboutKyuOs/AboutKyuOsVideo';
import AboutKyuOsWayfinding from 'app/components/AboutKyuOs/AboutKyuOsWayfinding';
import {
  AboutKyuOsContentWrapper,
  AboutKyuOsMainContainer,
} from 'app/components/AboutKyuOs/styled';
import StickyNav from 'app/components/CommonStyled/StickyNav';
import { on } from 'events';
import { AnimatePresence, useInView } from 'framer-motion';
import useOnClickOutside from 'hooks/click-outside';
import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import useGetBehavioral from 'hooks/useGetBehavioral';
import useIsMobile from 'hooks/useIsMobile';
import useUserData from 'hooks/useUserData';
import { updateOnboardingStarted } from 'services/onboarding';
import { setIsNavbarVisible } from 'store/reducers/detail-page/detail-page-reducer';
import { historySelector } from 'store/reducers/history/history-reducer';
import { setIsDrawerOpen } from 'store/reducers/navbar-reducer';
import { aboutKyuOsOpenSelector, setAboutKyuOsOpen } from 'store/reducers/onboarding-reducer';
import { setUserData, behavioralIdSelector, setBehavioralId } from 'store/reducers/user-reducer';
import { ThemeProvider } from 'styled-components';

import AboutKyuOsOnboardingBanner from './AboutKyuOsBanner';
import {
  AboutKyuOsOnboardingBannerContainer,
  AboutKyuOsOnboardingBeaconText,
  AboutKyuOsOnboardingBeaconWrapper,
  AboutKyuOsOnboardingContainer,
  AboutKyuOsOnboardingOverlay,
  AboutKyuOsOnboardingWrapper,
} from './styled';

export default function AboutKyuOsOnboarding() {
  const userData = useUserData();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const navbarRef = useRef<any>(null);
  const wrapperNodeRef = useRef<HTMLDivElement>(null);
  const isAboutKyuOsOpen = useSelector(aboutKyuOsOpenSelector);
  const historyEntries = useSelector(historySelector);
  const isInView = useInView(navbarRef, { once: false });
  const [previousBehavioral, setPreviousBehavioral] = useState<string>(null);
  const [newBehavioralId, setNewBehavioralId] = useState<string>(null);
  const newBehavioral = useGetBehavioral(
    { entryPointType: 'kyu101_modal', enabled: false, ignoreAlreadyFetched: false },
    [previousBehavioral]
  );
  const behavioralId: string = useSelector(behavioralIdSelector);
  const modalDismissedBehavioralFunction = useBehavioral(BEHAVIORALS.KYU101_MODAL_DISMISSED);

  useOnClickOutside(wrapperNodeRef, () => {
    const sideDrawer = document.querySelector('[data-type="sidedrawer"]');
    if (sideDrawer) return;

    if (isAboutKyuOsOpen) {
      modalDismissedBehavioralFunction();
      dispatch(setAboutKyuOsOpen(false));
    }
  });

  useEffect(() => {
    if (isAboutKyuOsOpen && !previousBehavioral) {
      setPreviousBehavioral(behavioralId);
      newBehavioral({
        onBehavioralCreated: (behavioralId: string) => {
          setNewBehavioralId(behavioralId);
        },
      });
    } else if (!isAboutKyuOsOpen && previousBehavioral) {
      dispatch(setBehavioralId(previousBehavioral));
      setPreviousBehavioral(null);
    } else if (isAboutKyuOsOpen && newBehavioralId && newBehavioralId !== behavioralId) {
      dispatch(setBehavioralId(newBehavioralId));
    }
  }, [isAboutKyuOsOpen, previousBehavioral, behavioralId, newBehavioralId]);

  const [isTopScroll, setIsTopScroll] = useState<boolean>(true);
  const wrapperRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      wrapperNodeRef.current = node;
      node.addEventListener('scroll', () => {
        if (node.scrollTop === 0) {
          setIsTopScroll(true);
        } else if (isTopScroll) {
          setIsTopScroll(false);
        }
      });
    }

    return () => {
      if (node !== null) {
        node.removeEventListener('scroll', () => {
          if (node.scrollTop === 0) {
            setIsTopScroll(true);
          } else if (isTopScroll) {
            setIsTopScroll(false);
          }
        });
      }
    };
  }, []);

  useEffect(() => {
    dispatch(setIsDrawerOpen(isAboutKyuOsOpen));
  }, [isAboutKyuOsOpen]);

  const variants = isMobile
    ? {
        hidden: {
          top: 'calc(100% - 67px)',
        },
        show: {
          top: '64px',
        },
        showTopScroll: {
          top: '10vh',
        },
      }
    : {
        hidden: {
          top: 'calc(100% - 67px)',
        },
        show: {
          top: '64px',
        },
        showTopScroll: {
          top: 'calc(100% - 604px)',
        },
      };

  const navSections = [
    { title: 'NEW TO KYU', hash: 'new-to-kyu' },
    { title: 'CONTACT KYU', hash: 'contact' },
    { title: 'NETWORKING', hash: 'networking' },
    { title: 'FAQ', hash: 'faq' },
  ];

  useEffect(() => {
    if (
      historyEntries.length > 1 &&
      historyEntries[historyEntries.length - 2]?.pathname !== window.location.pathname
    ) {
      dispatch(setAboutKyuOsOpen(false));
      dispatch(setIsNavbarVisible(false));
    }
  }, [historyEntries]);

  useEffect(() => {
    dispatch(setIsNavbarVisible(isInView));
  }, [isInView]);

  if (window.location.pathname === '/kyu-101' || userData.onboarded || userData.featured_banner)
    return null;

  return (
    <ThemeProvider theme={{ isAboutKyuOsOpen }}>
      <AboutKyuOsOnboardingWrapper
        variants={variants}
        initial="hidden"
        animate={!isAboutKyuOsOpen ? 'hidden' : isTopScroll ? 'showTopScroll' : 'show'}
        transition={{ duration: 0.5 }}
        ref={wrapperRef}
        id="about-kyu-os-onboarding"
      >
        {!isAboutKyuOsOpen && !userData.featured_banner ? <AboutKyuOsOnboarding.Beacon /> : null}
        <AboutKyuOsOnboardingContainer>
          <div style={{ position: 'relative', margin: 0 }}>
            <div ref={navbarRef} style={{ position: 'absolute', top: 0 }} />
          </div>
          <AboutKyuOsOnboardingBannerContainer>
            <AboutKyuOsOnboardingBanner />
          </AboutKyuOsOnboardingBannerContainer>
          {isMobile ? (
            <StickyNav
              mode="light"
              sections={navSections}
              position={'fixed'}
              topDistance={'16px'}
              containerRef={wrapperNodeRef}
              ignoreTopCondition
            />
          ) : null}
          <AboutKyuOsHero />
          <AboutKyuOsKeepScrolling />
          <AboutKyuOsMap />
          <AboutKyuOsMainContainer>
            <AboutKyuOsContentWrapper>
              <AboutKyuOsIntroduction />
              <AboutKyuOsVideo />
              <AboutKyuOsWayfinding />
              <AboutKyuOsContact />
              <AboutKyuOsNetwork />
              <AboutKyuOsFAQ />
            </AboutKyuOsContentWrapper>
          </AboutKyuOsMainContainer>
        </AboutKyuOsOnboardingContainer>
      </AboutKyuOsOnboardingWrapper>
      {ReactDOM.createPortal(
        <AnimatePresence>
          {isAboutKyuOsOpen ? (
            <AboutKyuOsOnboardingOverlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          ) : null}
        </AnimatePresence>,
        document.querySelector('#root')
      )}
    </ThemeProvider>
  );
}

AboutKyuOsOnboarding.Beacon = function AboutKyuOsOnboardingBeacon() {
  const dispatch = useDispatch();
  const userData = useUserData();

  const show = useMemo(() => {
    return !userData.onboarding_started;
  }, [userData]);

  const handleAnimationEnd = () => {
    dispatch(setUserData({ ...userData, onboarding_started: true }));
    updateOnboardingStarted({ onboarding_started: true });
  };

  return ReactDOM.createPortal(
    <AnimatePresence>
      {show ? (
        <AboutKyuOsOnboardingBeaconWrapper
          animate={{ boxShadow: 'rgb(255, 163, 102) 0px 0px 100px 0px' }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
          transition={{
            repeat: 6,
            repeatType: 'mirror',
            duration: 0.75,
          }}
          onAnimationComplete={handleAnimationEnd}
        >
          <AboutKyuOsOnboardingBeaconText>
            👇 We’ll leave this here. Or you can find it again from the main menu.
          </AboutKyuOsOnboardingBeaconText>
        </AboutKyuOsOnboardingBeaconWrapper>
      ) : null}
    </AnimatePresence>,
    document.querySelector('#root')
  );
};
