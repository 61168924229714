import styled from 'styled-components';

export const CollectiveDirectoryPillWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  padding: 4px 8px;

  border-radius: 26px;
  border: 1px solid #323f5d;

  &:hover {
    background: var(--color-primary);

    & span {
      color: #ffffff;
    }

    & path {
      fill: #ffffff;
    }

    & .search-icon-small path {
      fill: var(--color-primary);
      stroke: #ffffff;
    }
  }

  cursor: pointer;
`;

export const CollectiveDirectoryPillLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;

  color: #323f5d;
`;
