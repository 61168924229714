import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useBehavioral, { BEHAVIORALS } from 'hooks/useBehavioral';
import useWindowDimensions from 'hooks/useWindowDimensions';
import {
  isFeedbackDrawerOpenSelector,
  isNavMenuOpenSelector,
  isSpotlightOpenSelector,
  isUserDropdownOpenSelector,
  setIsDrawerOpen,
  setIsFeedbackDrawerOpen,
  setIsNavBarOpen,
  setIsNavbarMenuOpen,
} from 'store/reducers/navbar-reducer';
import { ThemeProvider } from 'styled-components';

import SideDrawer from '../CommonStyled/SideDrawer';
import FeedbackDrawer from '../ContactDrawers/FeedbackDrawer';
import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';

const lightModePages = ['/kyu', '/collective', '/my-profile', '/network-map'];

export default function NavBar() {
  const location = useLocation();
  const dispatch = useDispatch();

  const isFeedbackDrawerOpen = useSelector(isFeedbackDrawerOpenSelector);
  const navOpenedBehavioralFunction = useBehavioral(BEHAVIORALS.NAV_OPENED);
  const navSpotlightOpenedBehavioralFunction = useBehavioral(BEHAVIORALS.NAV_SPOTLIGHT_OPENED);
  const navProfileMenuOpenedBehavioralFunction = useBehavioral(BEHAVIORALS.NAV_PROFILE_MENU_OPENED);

  const isMenuOpen = useSelector(isNavMenuOpenSelector);
  const isSpotlightOpen = useSelector(isSpotlightOpenSelector);
  const isUserDropdownOpen = useSelector(isUserDropdownOpenSelector);

  const dimensions = useWindowDimensions();
  const isMobile = dimensions && dimensions.width < 1024;

  const isMapPage = /\/map\/*/.test(location.pathname);
  const abstractMapPage = /\/network-map\/*/.test(location.pathname);
  const isToolPage = location.pathname.endsWith('/embed');
  const isTogglableNavbar =
    !isMobile &&
    (isMapPage || isToolPage || location.pathname === '/collective' || abstractMapPage);
  const isLightModePage = !!lightModePages.find((p) => {
    if (location.pathname.startsWith('/kyu/resources')) {
      return false;
    }

    if (location.pathname === '/kyu-101') return false;

    if (location.pathname.startsWith('/my-profile/edit')) {
      return false;
    }
    return location.pathname.startsWith(p);
  });

  const mode = isLightModePage || isMapPage ? 'light' : 'dark';

  useEffect(() => {
    if (isMenuOpen) {
      navOpenedBehavioralFunction();
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (isSpotlightOpen) {
      navSpotlightOpenedBehavioralFunction();
    }
  }, [isSpotlightOpen]);

  useEffect(() => {
    if (isUserDropdownOpen) {
      navProfileMenuOpenedBehavioralFunction();
    }
  }, [isUserDropdownOpen]);

  useEffect(() => {
    dispatch(setIsNavbarMenuOpen(isMenuOpen));
  }, [isMenuOpen]);

  useEffect(() => {
    dispatch(setIsNavBarOpen(isTogglableNavbar ? false : true));
  }, [isTogglableNavbar]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const theme = {
    light: {
      color: '#fff',
      companyIconColor: '#9CCAFF',
      arrowColor: 'rgba(255, 255, 255, 0.5)',
      signOutColor: 'rgba(255, 255, 255, 0.5)',
      hoverItemColor: 'rgba(0, 0, 0, 0.15)',
      companyNameColor: 'rgba(255, 255, 255, 0.5)',
      divider: 'rgba(255, 255, 255, 0.3)',
      backgroundMenuColor: 'rgba(255, 255, 255, 0.40)',
      waysToGetInvolvedTitle: '#FFFFFF',
      waysToGetInvolvedLink: 'rgba(255, 255, 255, 0.50)',
      waysToGetInvolvedLinkHover: 'rgba(255, 255, 255, 1)',
      signoutButtonMobile: 'rgba(255, 255, 255, 0.50)',
      navButtonBackground: 'rgba(255, 255, 255, 0.40)',
      navBarTextColor: '#fff',
      navBarIconColor: '#fff',
      sectionTitleColor: 'rgba(255, 255, 255, 0.50)',
      mode: 'light',
    },
    dark: {
      color: '#171C33',
      companyIconColor: '#5478A1',
      arrowColor: 'rgba(31, 32, 37, 0.5)',
      signOutColor: 'rgba(31, 32, 37, 0.5)',
      hoverItemColor: 'rgba(0, 0, 0, 0.08)',
      companyNameColor: 'rgba(0, 0, 0, 0.5)',
      divider: 'rgba(0, 0, 0, 0.3)',
      backgroundMenuColor: 'rgba(23, 28, 51, 0.10)',
      waysToGetInvolvedTitle: 'var(--color-text)',
      waysToGetInvolvedLink: 'rgba(23, 28, 51, 0.50)',
      waysToGetInvolvedLinkHover: 'rgba(23, 28, 51, 1)',
      signoutButtonMobile: 'rgba(23, 28, 51, 0.50)',
      navButtonBackground: 'rgba(0, 0, 0, 0.10)',
      navBarTextColor: 'var(--color-text)',
      navBarIconColor: 'var(--color-primary)',
      sectionTitleColor: 'rgba(23, 28, 51, 0.50)',
      mode: 'dark',
    },
  };

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'drawers:close') {
        dispatch(setIsNavbarMenuOpen(false));
      }
    });

    return () => {
      window.removeEventListener('message', (event) => {
        if (event.data === 'drawers:close') {
          dispatch(setIsNavbarMenuOpen(false));
        }
      });
    };
  }, []);

  return (
    <ThemeProvider theme={theme[mode]}>
      {isMobile ? <NavbarMobile /> : <NavbarDesktop />}
      {isFeedbackDrawerOpen ? (
        <SideDrawer closeDrawer={() => dispatch(setIsFeedbackDrawerOpen(false))} withClose={true}>
          <FeedbackDrawer />
        </SideDrawer>
      ) : null}
    </ThemeProvider>
  );
}
